import _ from "lodash";
import React, { PureComponent, ReactNode } from "react";
import ReactDOM from "react-dom";
import withCommonEvents from "../../../shared/hoc/with-common-events";
import { KMainFunctions } from "../../../shared/utilty/main-functions";
import { CommonProps } from "../common-props";

interface FooterProps {
  children?: ReactNode;
  style?: React.CSSProperties;
}

interface FooterState {}

class Footer extends PureComponent<FooterProps & CommonProps, FooterState> {
  constructor(props: FooterProps) {
    super(props);
    this.state = {};
  }

  componentDidMount(): void {
    const node = ReactDOM.findDOMNode(this) as Element;
    KMainFunctions.handleAlignments(node, this.props.style);
  }

  getStyleProp = () => {
    let style: any = {};

    if (this.props.style) {
      style = _.clone(this.props.style);
    }

    return style;
  };

  getProps = () => {
    const props: any = _.clone(this.props);
    if (props.style) {
      delete props.style;
    }
    return props;
  };

  render(): ReactNode {
    return (
      <>
        <div {...this.getProps()} style={{ ...this.getStyleProp() }}>
          {this.props.children}
        </div>
      </>
    );
  }
}

const footer = withCommonEvents(Footer);
export { footer as Footer };
