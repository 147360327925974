import { DatePicker } from "antd/es";
import { Guid } from "guid-typescript";
import _ from "lodash";
import moment from "moment";
import { PickerSharedProps } from "rc-picker/es/Picker";
import { PureComponent, ReactNode } from "react";
import withCommonEvents from "../../../shared/hoc/with-common-events";
import { CommonProps } from "../common-props";
import { DashboardState } from "../kuika-cl-model-runtimes";

declare let window: any;

interface IYearPickerProps extends PickerSharedProps<any> {
  value?: number;
  defaultValue?: number;
  onChange?: (value: number) => void;
  style?: any;
  placeholder?: string;
  widthType?: "fill" | "content";
  className?: string;
  minYear?: number;
  maxYear?: number;
}

interface IYearPickerState {
  uniqueKey: Guid;
  isOpen: boolean;
}

class YearPicker extends PureComponent<IYearPickerProps & CommonProps, IYearPickerState> {
  private memoizedDynamicCssResult = "";

  constructor(props: IYearPickerProps) {
    super(props);
    this.state = {
      uniqueKey: Guid.create(),
      isOpen: false
    };
  }

  componentDidMount = () => {
    this.setDynamicStyle();
  };

  setDynamicStyle = () => {
    const uniquekey = this.state.uniqueKey?.toString();
    if (!uniquekey) {
      return;
    }
    const isDesignTime = window.kuika?.isDesignTime;
    if (this.memoizedDynamicCssResult !== "" && !isDesignTime) {
      return this.memoizedDynamicCssResult;
    }
    const dynamic_style = document.getElementById("dynamic_style");
    if (dynamic_style && dynamic_style.innerHTML?.indexOf(uniquekey) === -1) {
      const generatedCss = this.getDynamicCss();
      dynamic_style.innerHTML = `${dynamic_style.innerHTML}
        ${generatedCss}`;
      this.memoizedDynamicCssResult = generatedCss;
    }
  };

  getDynamicCss = (): string => {
    const className: string = this.getClassName();
    if (!className || className.length === 0) {
      return "";
    }

    let result = "";
    if (this.props.style?.color?.length > 0) {
      result +=
        `.${className.trim()} .ant-picker-input>input {
          ` +
        `color: ${this.props.style?.color}
        }`;
    }

    if (this.props.style?.color || this.props.style?.fontFamily || this.props.style?.fontWeight) {
      result += `
          .${className.trim()} .ant-picker-input>input {
            `;
      if (this.props.style?.color) result += `color: ${this.props.style?.color} !important;`;
      if (this.props.style?.fontSize) result += `font-size: ${this.props.style?.fontSize} !important;`;
      if (this.props.style?.fontFamily) result += `font-family: ${this.props.style?.fontFamily} !important;`;
      if (this.props.style?.fontWeight) result += `font-weight: ${this.props.style?.fontWeight} !important;`;
      result += `;
      }`;
    }

    if (this.props.style?.fontSize || this.props.style?.fontFamily || this.props.style?.fontWeight) {
      result += `
          .${className.trim()} .ant-picker-input>input::-webkit-input-placeholder {
            `;
      if (this.props.style?.fontSize) result += `font-size: ${this.props.style?.fontSize} !important;`;
      if (this.props.style?.fontFamily) result += `font-family: ${this.props.style?.fontFamily} !important;`;
      if (this.props.style?.fontWeight) result += `font-weight: ${this.props.style?.fontWeight} !important;`;
      result += `;
      }`;
    }

    if (this.props.style?.backgroundColor?.length > 0 || this.props.style?.color?.length > 0) {
      result += `
          .${className.trim()} .ant-picker-clear {
            `;
      if (this.props.style?.backgroundColor?.length > 0)
        result += `background: ${this.props.style?.backgroundColor} !important;`;
      if (this.props.style?.color?.length > 0) result += `color: ${this.props.style?.color} !important;`;
      result += `;
      }`;
    }
    return result;
  };

  getClassName = () => {
    let result = "";
    if (this.props.className) {
      result = this.props.className;
    }
    if (!this.state.uniqueKey) {
      return result;
    }
    result = `${result} kyearpicker_${this.state.uniqueKey.toString().substring(0, 8)}`;
    return result;
  };

  getWidth() {
    if (this.props.widthType && this.props.widthType == "content") {
      return "max-content";
    }
    return "100%";
  }

  handleChange = (value: any, dateString) => {
    if (this.props.onChange) {
      const val = parseInt(dateString);
      this.props.onChange(val);
    }
  };

  getProps = () => {
    const props: any = _.clone(this.props);
    let style: any = _.clone(this.props.style);
    if (props.defaultValue) {
      delete props.defaultValue;
    }
    if (props.disabledDate) {
      delete props.disabledDate;
    }
    if (props.value) {
      delete props.value;
    }
    if (_.isString(props.value) && props.value.length === 0) {
      delete props.value;
    }
    if (props.display) {
      delete props.display;
    }
    if (!style) {
      style = {
        width: this.getWidth()
      };
    } else if (!style.width) {
      style.width = this.getWidth();
    }
    if (style.display == "block") {
      style.display = "flex";
    } else if (style.display == "inline") {
      style.display = "inline-flex";
    }
    props.style = style;
    return props;
  };

  handleKeydown = (e: any) => {
    const charCode = e.which ?? e.keyCode;
    if (charCode === 38 && this.state.isOpen) {
      if (this.props.onChange) {
        const val = parseInt(e.target.value) + 1;
        this.props.onChange(val);
      }
    }
    if (charCode === 40) {
      if (this.props.onChange) {
        const val = parseInt(e.target.value) - 1;
        this.props.onChange(val);
      }
    }
    if (charCode === 9) {
      this.handleChange(e.target.value, e.target.value);
      this.setState({ isOpen: false });
      e.preventDefault();
      e.stopPropagation();
      return false;
    }
    if (charCode === 9 && !this.state.isOpen) {
      if (this.props.onKeyDown) {
        this.props.onKeyDown(e, e.preventDefault());
      }
      return false;
    }
    if (
      charCode > 31 &&
      (charCode > 57 || charCode < 48) &&
      (charCode > 105 || charCode < 96) &&
      charCode !== 8 &&
      charCode !== 46 &&
      charCode !== 186
    ) {
      e.preventDefault();
      e.stopPropagation();
      return false;
    }
  };

  handleOpenChange = (isOpen: boolean) => {
    if (window?.kuika?.dashboardState !== DashboardState.design) {
      this.setState({ isOpen });
    }
  };

  disabledDate = (current) => {
    const yearStart = this.props.minYear ?? 1900;
    const yearEnd = this.props.maxYear ?? 2100;
    const yearStartDate = moment(yearStart, "YYYY");
    const yearEndDate = moment(yearEnd, "YYYY");
    if (yearStartDate || yearEndDate) {
      return !(yearStartDate.isSameOrBefore(current) && yearEndDate.isAfter(current));
    }
  };

  getGenericValue = () => {
    let result: any = {};
    if (this.props.value) {
      result = { value: this.convertTomoment(this.props.value) };
    }
    return result;
  };

  convertTomoment = (value: number) => {
    return moment(value, "YYYY");
  };

  render(): ReactNode {
    return (
      <>
        <DatePicker
          picker="year"
          {...this.getProps()}
          disabledDate={this.disabledDate}
          open={this.state.isOpen}
          onOpenChange={this.handleOpenChange}
          {...this.getGenericValue()}
          className={this.getClassName()}
          onChange={(e, f) => this.handleChange(e, f)}
          onKeyDown={(e) => this.handleKeydown(e)}
        ></DatePicker>
      </>
    );
  }
}
const yearPicker = withCommonEvents(YearPicker);
export { yearPicker as YearPicker };
