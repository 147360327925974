export interface IImportMappedExcelColumnMapDefinition {
  isEnabled: boolean;
  source: string;
  alias: string;
  primaryKey: string | null;
}

export interface IImportMappedExcelColumnMap extends IImportMappedExcelColumnMapDefinition {
  target?: string;
  defaultValue?: string;
}

export interface IImportMappedExcelRequest {
  datasourceId: string;
  tableName: string;
  primaryKey: string;
  mappedColumns: IImportMappedExcelColumnMap[];
  columns: string[];
  data: object[];
  language: string;
}

export enum ImportMappedExcelStatus {
  Success,
  Error,
  PartialSuccess,
  Queued
}

export interface IImportMappedExcelResponse {
  status: ImportMappedExcelStatus;
  errorCount: number;
  successCount: number;
  totalCount: number;
  rowsInserted: number;
  rowsUpdated: number;
  erroredRows: object[];
  rowsWithValidationErrors: object[];
}
