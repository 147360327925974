import { Input } from "antd/es";
import { TextAreaProps } from "antd/es/input/TextArea";
import { PureComponent, ReactNode } from "react";
import { CommonProps } from "../../../components/web-components/common/common-props";
import withCommonEvents from "../../../shared/hoc/with-common-events";

interface IKTextareaProps extends TextAreaProps {
  value?: string;
  allowClear?: boolean;
  autoSize?: boolean;
  bordered?: boolean;
  showCount?: boolean;
}

interface IKTextareaState {}

class KTextarea extends PureComponent<IKTextareaProps & CommonProps, IKTextareaState> {
  constructor(props: IKTextareaProps) {
    super(props);
    this.state = {};
  }

  render(): ReactNode {
    return (
      <>
        <Input.TextArea {...this.props} />
      </>
    );
  }
}

const kTextarea = withCommonEvents(KTextarea);
export { kTextarea as KTextarea };
