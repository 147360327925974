const screenInputData = {
  ProtokolAltBilesenF: [{"name":"ProtokolBilesenId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"ProtokolId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"ProtokolAltBilesenId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	AltBilesenTestler: [{"name":"AltBilesenId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	TestF2: [{"name":"TestId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	CheckupReport: [{"name":"ShowDownload","type":"Boolean","cardinality":1,"isExpandedPanel":null},{"name":"CheckupId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	RandevuSelfService2: [{"name":"HizmetId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"DavetiyeId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"RandevuId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"DanisanKisiId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	EkTestGecmisi: [{"name":"TestId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"KisiId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	SinifYeniOgrenciSezon: [{"name":"role","type":"String","cardinality":1,"isExpandedPanel":null},{"name":"KisiId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"kurumID","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"Kirilim1ID","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	NormDetay: [{"name":"NormId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"DetayId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	TestF: [{"name":"TestId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	CheckupDonemiForm: [{"name":"Id","type":"Guid","cardinality":1,"isExpandedPanel":"false"}],
	ParQFormcopy: [{"name":"KisiId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"FormId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"NewEditView","type":"String","cardinality":1,"isExpandedPanel":null}],
	ParQForm: [{"name":"KisiId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"FormId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	BasvuruKurumDetay: [{"name":"Id","type":"Guid","cardinality":1,"isExpandedPanel":"false"}],
	PersonConnectedList: [{"name":"role","type":"String","cardinality":1,"isExpandedPanel":null},{"name":"KisiId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"kurumID","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	Classification: [{"name":"SiniflandirmaId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	RandevuFromEgitmen2: [{"name":"DavetiyeId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"EgitmenId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"HizmetId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"RandevuId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"DanisanKisiId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	KurumTipiForm: [{"name":"Id","type":"Guid","cardinality":1,"isExpandedPanel":"false"}],
	KrediYukle: [{"name":"KimeHesapId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	ServiceF: [{"name":"Id","type":"Guid","cardinality":1,"isExpandedPanel":"false"}],
	RandevuSelfService3: [{"name":"HizmetId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"MerkezId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"RandevuId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"DavetiyeId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"DanisanKisiId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	StatisticsFitskorDetay: [{"name":"Kirilim2ID","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"ProtokolId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	SinifYeniOgrenci: [{"name":"role","type":"String","cardinality":1,"isExpandedPanel":null},{"name":"KisiId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"kurumID","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"Kirilim2ID","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	CheckupReportcopycopy: [{"name":"ShowDownload","type":"Boolean","cardinality":1,"isExpandedPanel":null},{"name":"CheckupId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	MesaiSaatiForm: [{"name":"ProfileId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"Gun","type":"String","cardinality":1,"isExpandedPanel":null},{"name":"MesaiSaatId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	ProtokolAltBilesenF2: [{"name":"ProtokolBilesenId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"ProtokolId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"ProtokolAltBilesenId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	Protocol2: [{"name":"Id","type":"Guid","cardinality":1,"isExpandedPanel":"false"}],
	BilesenAltBilesenleriList: [{"name":"ShowDownload","type":"Boolean","cardinality":1,"isExpandedPanel":null},{"name":"BilesenId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	Personcopy: [{"name":"kisiId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"role","type":"String","cardinality":1,"isExpandedPanel":null},{"name":"Kirilim2Id","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"kurumID","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	ProtocolBilesenF2: [{"name":"ProtokolId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"ProtokolBilesenId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	TakvimKapat: [{"name":"Date","type":"DateTime","cardinality":1,"isExpandedPanel":null},{"name":"DateIncrement","type":"Int32","cardinality":1,"isExpandedPanel":null},{"name":"Saat","type":"String","cardinality":1,"isExpandedPanel":null}],
	CheckupReportcopy: [{"name":"ShowDownload","type":"Boolean","cardinality":1,"isExpandedPanel":null},{"name":"CheckupId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	PopulasyonForm: [{"name":"Id","type":"Guid","cardinality":1,"isExpandedPanel":"false"}],
	Invitation: [{"name":"ShowDownload","type":"Boolean","cardinality":1,"isExpandedPanel":null},{"name":"DavetiyeId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	Profiles: [{"name":"KisiId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	Composant: [{"name":"BilesenId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	EgitmenAyarlari: [{"name":"ProfileId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	CheckupF: [{"name":"ShowDownload","type":"Boolean","cardinality":1,"isExpandedPanel":null},{"name":"CheckupId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"ProtokolId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"KisiId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"RandevuId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	DashboardBilesenDetay: [{"name":"Kirilim2ID","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"ProtokolId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	BasvuruEgitmenDetay: [{"name":"Id","type":"Guid","cardinality":1,"isExpandedPanel":"false"}],
	StatisticsDetay: [{"name":"Kirilim2ID","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"bilesensira","type":"String","cardinality":1,"isExpandedPanel":null},{"name":"ProtokolId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	NormDetayList: [{"name":"NormId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	RandevuFromEgitmen: [{"name":"DavetiyeId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"EgitmenId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"RandevuId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"DanisanKisiId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	RandevuSelfService: [{"name":"DavetiyeId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"RandevuId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"DanisanKisiId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	PersonCard: [{"name":"kisiId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	OrganisationCard: [{"name":"KurumId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	NormDetayKopyala: [{"name":"NormId","type":"Int32","cardinality":1,"isExpandedPanel":null},{"name":"KopyalananYas","type":"Int32","cardinality":1,"isExpandedPanel":null}],
	SlotGenerator: [{"name":"KisiId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	Organisation: [{"name":"Id","type":"Guid","cardinality":1,"isExpandedPanel":"false"}],
	SinifinOgrencileri: [{"name":"Kirilim2Id","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	Persons: [{"name":"role","type":"String","cardinality":1,"isExpandedPanel":null}],
	TopluOlcumListSinif: [{"name":"Kirilim2ID","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"KurumId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"CheckupDonemiID","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"Kirilim1ID","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	TestDeneme2Test: [{"name":"TestDenemeId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	Organisations: [{"name":"role","type":"String","cardinality":1,"isExpandedPanel":null}],
	Profile: [{"name":"ProfileId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"KisiId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	RandevuSlot: [{"name":"RandevuId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"EgitmenId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"HizmetId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"MerkezId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"DavetiyeId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"DanisanKisiId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	Dashboard: [{"name":"kisiId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	SiniflandirmaSeviye: [{"name":"SeviyeId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"SinifId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	MesaiSaatiForm2: [{"name":"ProfileId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"MesaiSaatId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	ProtokolBilesenF: [{"name":"ProtokolBilesenId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"ProtokolId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	Person: [{"name":"kisiId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"role","type":"String","cardinality":1,"isExpandedPanel":null},{"name":"Kirilim2Id","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"kurumID","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"profilID","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"Ne","type":"String","cardinality":1,"isExpandedPanel":null}],
	ProtokolBilesenList: [{"name":"ProtokolId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	TopluCheckupForm: [{"name":"Kirilim2ID","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"Kirilim1ID","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"KurumId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"CheckupDonemiID","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"TopluOlcumId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	Basvurular: [{"name":"ShowDownload","type":"Boolean","cardinality":1,"isExpandedPanel":null},{"name":"CheckupId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	RandevuFromMerkez: [{"name":"DavetiyeId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"MerkezId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"RandevuId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"DanisanKisiId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	OgretmenSiniflari: [{"name":"KisiId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	PersonConnected: [{"name":"KisiId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"role","type":"String","cardinality":1,"isExpandedPanel":null},{"name":"profilID","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	AltBilesenForm: [{"name":"AltBilesenId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"BilesenId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	EgitmenAyarlaricopy: [{"name":"ProfileId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	RandevuFromMerkez2: [{"name":"DavetiyeId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"RandevuId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"HizmetId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"MerkezId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"DanisanKisiId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	SiniflandirmaSeviyeList: [{"name":"SinifId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	CheckupPasta: [{"name":"ShowDownload","type":"Boolean","cardinality":1,"isExpandedPanel":null},{"name":"CheckupId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	EkTestF: [{"name":"KisiId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"EkTEstId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	Kirilim1Form: [{"name":"Id","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	ProtokolAltBilesenler: [{"name":"ProtokolId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"ProtokolBilesenId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	checkupRaporu: [{"name":"ShowDownload","type":"Boolean","cardinality":1,"isExpandedPanel":null},{"name":"CheckupId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	Kirilim2Form: [{"name":"Id","type":"Guid","cardinality":1,"isExpandedPanel":"false"}],
	RandevuSummary: [{"name":"RandevuId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	Protocol: [{"name":"Id","type":"Guid","cardinality":1,"isExpandedPanel":"false"}],
	KrediTransferi: [{"name":"KimdenHesapId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	BasvuruMerkezDetay: [{"name":"Id","type":"Guid","cardinality":1,"isExpandedPanel":"false"}],
	Norm: [{"name":"NormId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	DashboardKatilimDetay: [{"name":"Kirilim2ID","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"Kirilim1ID","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"ProtokolId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	TestDenemeDegiskenF: [{"name":"DegiskenId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"TestId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	TestDegisken: [{"name":"DegiskenId","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"TestId","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	Protocol3: [{"name":"Id","type":"Guid","cardinality":1,"isExpandedPanel":"false"}]
};

export class ScreenInputHelper {
  public static getScreenInputValue(screenName: string, inputName: string, value: string): any {
    switch (this.getScreenInputType(screenName, inputName)) {
      case "String":
        return value;
      case "Boolean":
        return value.toLowerCase() === "true";
      // TODO
    }

    return value;
  }

  private static getScreenInputType(screenName: string, inputName: string): string {
    const data = screenInputData[screenName]?.find((x) => x.Name === inputName);
    return data?.TypeName ? data.TypeName : "";
  }
}
