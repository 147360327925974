import { SelectProps } from "antd/es/select";
import _ from "lodash";
import { PureComponent, ReactNode } from "react";
import ReactDOM from "react-dom";
import { KSelectBox } from "../../../kuika";
import { CommonProps } from "../common/common-props";

export interface IAutoComplateProps extends SelectProps<any> {
  datatextfield?: string;
  datavaluefield?: any;
  onChange?: (value: any) => void;
}

interface IAutoComplateState {}

class AutoComplete extends PureComponent<IAutoComplateProps & CommonProps, IAutoComplateState> {
  constructor(props: IAutoComplateProps) {
    super(props);
  }

  componentDidMount = () => {
    this.handleComponentStyling();
  };

  componentDidUpdate = () => {
    this.handleComponentStyling();
  };

  handleComponentStyling = () => {
    if (!this.props.style || this.props.visibility === "hidden") return;
    const node = ReactDOM.findDOMNode(this) as Element;
    const item = node.querySelector(".ant-select-selector") as HTMLElement;
    const placeholder = node.querySelector(".ant-select-selection-placeholder") as HTMLElement;
    const searchInput = node.querySelector(".ant-select-selection-search") as HTMLElement;
    const selectionItem = node.querySelector(".ant-select-selection-item") as HTMLElement;
    searchInput?.children[0]?.setAttribute("focusid", this.props.id);
    let style: React.CSSProperties = _.clone(this.props.style);

    if (style?.minHeight) {
      this.handleMinHeight(item);
    }

    if (style?.maxHeight) {
      this.handleMaxHeight(item);
    }

    if (item && item.style && style.paddingLeft === 8) {
      item.style.paddingLeft = "12px";
    }

    this.handlePadding(searchInput, style, placeholder, selectionItem);
  };

  handleMinHeight = (item: HTMLElement) => {
    item.style.minHeight = this.props.style!.minHeight as any;
  };

  handleMaxHeight = (item: HTMLElement) => {
    item.style.maxHeight = this.props.style!.maxHeight as any;
  };

  handlePadding = (
    item: HTMLElement,
    style: React.CSSProperties,
    placeholder: HTMLElement,
    selectionItem: HTMLElement
  ) => {
    item.style.paddingLeft = `${(style.paddingLeft as number) - 12}px`;
    item.style.paddingRight = `${style.paddingRight as number}px`;
    item.style.paddingTop = `${(style.paddingTop as number) / 2}px`;
    item.style.paddingBottom = `${(style.paddingBottom as number) / 2}px`;
    if (placeholder && placeholder.style && selectionItem && selectionItem.style) {
      placeholder.style.paddingLeft = "0px";
      selectionItem.style.paddingLeft = "0px";
    }
  };

  getProps = () => {
    let props: any = {};
    props = _.clone(this.props);

    if (props.value == "") {
      delete props.value;
    }
    return props;
  };

  render(): ReactNode {
    return (
      <>
        <KSelectBox {...this.getProps()} searchable={true}></KSelectBox>
      </>
    );
  }
}

const autoComplete = AutoComplete;
export { autoComplete as AutoComplete };
