import { GoogleMap, GoogleMapProps, LoadScript, Marker } from "@react-google-maps/api";
import { PureComponent, ReactNode } from "react";
import { CommonProps } from "../../../components/web-components/common/common-props";
import withCommonEvents from "../../../shared/hoc/with-common-events";

interface IKLocationPickerProps extends GoogleMapProps {
  lat: number;
  long: number;
  googleMapsApiKey: string;
}

interface IKLocationPickerState {
  lat: number;
  long: number;
}

const containerStyle = {
  width: "100%",
  height: "400px"
};

class KLocationPicker extends PureComponent<IKLocationPickerProps & CommonProps, IKLocationPickerState> {
  constructor(props: IKLocationPickerProps) {
    super(props);
    this.state = {
      lat: this.props.lat,
      long: this.props.long
    };
  }

  dragEnd = (val: any) => {
    const { latLng } = val;
    const lat = latLng.lat();
    const lng = latLng.lng();
    this.setState({ lat, long: lng });
  };

  render(): ReactNode {
    const center = {
      lat: this.state.lat,
      lng: this.state.long
    };

    const position = {
      lat: this.state?.lat,
      lng: this.state?.long
    };

    return (
      <>
        <h3>Lat: {this.state.lat}</h3>
        <h3>Long: {this.state.long}</h3>
        <LoadScript googleMapsApiKey={this.props.googleMapsApiKey}>
          <GoogleMap {...this.props} mapContainerStyle={containerStyle} center={center} zoom={10}>
            <Marker draggable position={position} onDragEnd={this.dragEnd} />
          </GoogleMap>
        </LoadScript>
      </>
    );
  }
}

const kLocationPicker = withCommonEvents(KLocationPicker);
export { kLocationPicker as KLocationPicker };
