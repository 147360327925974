import * as React from "react";
import { Persons_ScreenBase } from "./persons-base";
import { Spacer, KPassword, KSteps, KStep, KLabel, KNumericbox, KButton, KToggle, KCheckbox, KMonthPicker, KDatatimePicker, KRating, KSelectBox, KMultiSelect, KTextarea, KTextbox, KFileDownload, KFilePicker, KLanguageSelect, KLocationSelect, KLocationDisplay, KLocationPicker, KSignature, KIcon, KMDEditor, KMDViewer, KRadio, KRadioGroup, KCalendar, KIFrame, KAvatarMenu, KAvatarMenuItem, KSpace, KSlider, KRadioButton, KThumbnail } from "../../kuika";
import { MfeContainer, TextArea, WebView, Icon, Label, Signature, HorizontalStack, VerticalStack, LanguageSelector, MDViewer, QRRenderer, BarcodeRenderer, Divider, MDEditor, Button, Rating, Percent, Currency, NumberInput, BarcodeInput, BarcodeInput, Password, TextInput, Image, Email, AreaChart, BarChart, DonutChart, GaugeChart, LineChart, PieChart, Header, Footer, Switch, Url, CountDown, ProgressCircle, ProgressBar, CollapsePanel, Collapse, Slider, KAreaChart, KBarChart, KDonutChart, KGaugeChart, KLineChart, KPieChart, KFooter, KHeader, KRow, KCol, KTab, KTabItem, KTable, KTableHeader, KTableRow, KTableColumn, KGallery, KPagination, KDrawer, KModal, KVideo, KCarousel, KFlexGrid, KPanel, KDropDown, KDropDownItem, KContentMenu, KContentMenuItem, GoogleMaps, GoogleMapsMarker, GoogleMapsMarkerPopup, KContentMenuPanel, KBadge, Badge, RangeSlider, Checkbox, Chat, RangeDate, RangeDateTime, Stopwatch, PlacesAutocompleteInput, CronMaker, SingleSelect, MultiSelect, AutoComplete, StripeCard, PaymentElementStripe, CountUp, Tree, KDatePicker, YearPicker, Tooltip, Calendar, LayoutHeader, LayoutFooter, LayoutLeft, LayoutRight, LocationPicker, Timeline, Stepper, StepperItem, Menu, MenuItem, SubMenu, RadialChart, MixedChart, MixedChartLineItem, MixedChartPointItem, ChartLabel, KanbanBoard, ProcessAutomation, RadioGroup, BigFileUpload, PivotTable, ColorPicker } from "../../components/web-components";
import { Drawer, Form, Spin, Typography } from "antd/es";
import { withGoogleLogin } from "../../shared/hoc/with-google-login";
import { withHistory } from "../../shared/hoc/with-history";
import { withForm } from "../../shared/hoc/with-form";
import { withScreenInput } from "../../shared/hoc/with-screen-input";
import { withContext } from "../../shared/hoc/with-context";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { showPhotoTake, hidePhotoTake, showStripeDrawer, hideStripeDrawer,showIyzicoDrawer, hideIyzicoDrawer, showImportMappedExcelModal, hideImportMappedExcelModal } from "../../redux/modals/actions";
import { AppState} from "../../redux/root-reducer";
import { connect } from "react-redux";
import moment from "moment";
import {Master_Screen} from "../master/master";

import { Person_Screen } from "../person/person";
import { PersonConnected_Screen } from "../personconnected/personconnected";
import { OgretmenSiniflari_Screen } from "../ogretmensiniflari/ogretmensiniflari";
import { Profiles_Screen } from "../profiles/profiles";
import { EgitmenAyarlari_Screen } from "../egitmenayarlari/egitmenayarlari";


declare let window: any;
const { Title, Paragraph, Text, Link } = Typography;
const menuMap = [

];

class Persons_Screen extends Persons_ScreenBase
{
    render() {
        if (this.state.isPageVisible === false) {return (<></>);}
        return (
            <>
              <Master_Screen form={this.props.form} initialValues={{persons_22832_value: this.props.screenInputs.role ?? this.props.screenInputs.role,
							persons_133816_value: this.props.screenInputs.role ?? this.props.screenInputs.role,
							persons_416063_value: undefined
}} screenInputs={this.props.screenInputs} setMasterPageInitLoaded={this.setMasterPageInitLoaded} setMasterPageRecurringLoaded={this.setMasterPageRecurringLoaded} isMasterPageInitLoaded={this.state.isMasterPageInitLoaded} isMasterPageRecurringLoaded={this.state.isMasterPageRecurringLoaded} >
                <Spin spinning={this.state.spinnerCount > 0}>
                    
                        <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
<div id="persons_body" style={{"backgroundColor":"rgba(244, 244, 244, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","display":"block","overflow":"auto","minHeight":"calc(100vh - 60px - 60px - 0px - 0px)","maxHeight":"calc(100vh - 60px - 60px - 0px - 0px)","height":"calc(100vh - 60px - 60px - 0px - 0px)"} as any}>
<KRow id="331958" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="44993" xs={4} sm={4} md={4} lg={4} xl={4} xxl={4} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"center","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="737045" value={this.state.RolePerKurumTipi?.at?.(0)?.label ?? undefined} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":400,"fontSize":"18pt","color":"rgba(10, 33, 64, 1)","textTransform":"uppercase"} as any}></Label>
</KCol>

<KCol id="775216" xs={20} sm={20} md={20} lg={20} xl={20} xxl={20} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"center","textAlign":"-webkit-right","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<HorizontalStack id="169931" direction="horizontal" size={12} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Button id="30960" onClick={ (e?: any) => { if(e && e.stopPropagation) e.stopPropagation();this.PersonsComponent_30960_onClick()} } showCursorPointer visibility={this.state.isComp30960Visible} label={ReactSystemFunctions.translate(this.ml, 30960, "label",this.defaultML)} size="middle" loading={false} ghost={false} block={false} htmlType="button" iconPosition="left" danger={false} style={{"borderTopLeftRadius":8,"borderTopRightRadius":8,"borderBottomRightRadius":8,"borderBottomLeftRadius":8,"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"textAlign":"-webkit-center","color":"rgba(24, 144, 255, 1)","textDecoration":"underline"} as any}></Button>

<Button id="724766" onClick={ (e?: any) => { if(e && e.stopPropagation) e.stopPropagation();this.PersonsComponent_724766_onClick()} } showCursorPointer visibility={this.state.isComp724766Visible} label={ReactSystemFunctions.translate(this.ml, 724766, "label",this.defaultML)} size="middle" loading={false} ghost={false} block={false} htmlType="button" iconPosition="left" danger={false} style={{"borderTopLeftRadius":8,"borderTopRightRadius":8,"borderBottomRightRadius":8,"borderBottomLeftRadius":8,"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"textAlign":"-webkit-center","color":"rgba(24, 144, 255, 1)","textDecoration":"underline"} as any}></Button>

<Button id="3236" onClick={ (e?: any) => { if(e && e.stopPropagation) e.stopPropagation();this.PersonsComponent_3236_onClick()} } showCursorPointer visibility={this.state.isComp3236Visible} condition={(rowData) => (ReactSystemFunctions.isEqualTo(this.state.KisilerYetki[0]?.excelUploadandSample?.toString(), "1"))} label={ReactSystemFunctions.translate(this.ml, 3236, "label",this.defaultML)} size="middle" loading={false} ghost={false} block={false} htmlType="button" iconPosition="left" danger={false} style={{"borderTopLeftRadius":8,"borderTopRightRadius":8,"borderBottomRightRadius":8,"borderBottomLeftRadius":8,"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"backgroundColor":"rgba(0, 190, 214, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":8,"paddingRight":16,"paddingBottom":8,"paddingLeft":16,"width":"100px","alignItems":"center","textAlign":"-webkit-center","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"16px","color":"rgba(255, 255, 255, 1)","lineHeight":"22px"} as any}></Button>

<Button id="222810" onClick={ (e?: any) => { if(e && e.stopPropagation) e.stopPropagation();this.PersonsComponent_222810_onClick()} } showCursorPointer visibility={this.state.isComp222810Visible} condition={(rowData) => ReactSystemFunctions.isEqualTo(this.state.KisilerYetki[0]?.excelUploadandSample?.toString(), "1")} label={ReactSystemFunctions.translate(this.ml, 222810, "label",this.defaultML)} size="middle" loading={false} ghost={false} block={false} htmlType="button" iconPosition="left" danger={false} style={{"borderTopLeftRadius":8,"borderTopRightRadius":8,"borderBottomRightRadius":8,"borderBottomLeftRadius":8,"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"backgroundColor":"rgba(0, 190, 214, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":8,"paddingRight":16,"paddingBottom":8,"paddingLeft":16,"width":"100px","alignItems":"center","textAlign":"-webkit-center","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"16px","color":"rgba(255, 255, 255, 1)","lineHeight":"22px"} as any}></Button>

<Button id="396447" onClick={ (e?: any) => { if(e && e.stopPropagation) e.stopPropagation();this.PersonsComponent_396447_onClick()} } showCursorPointer visibility={this.state.isComp396447Visible} condition={(rowData) => ReactSystemFunctions.isEqualTo(this.state.KisilerYetki[0]?.ogrenciExcelUploadandSample?.toString(), "1")} label={ReactSystemFunctions.translate(this.ml, 396447, "label",this.defaultML)} size="middle" loading={false} ghost={false} block={false} htmlType="button" iconPosition="left" danger={false} style={{"borderTopLeftRadius":8,"borderTopRightRadius":8,"borderBottomRightRadius":8,"borderBottomLeftRadius":8,"backgroundColor":"rgba(0, 190, 214, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":8,"paddingRight":16,"paddingBottom":8,"paddingLeft":16,"alignItems":"center","textAlign":"-webkit-center","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"16px","color":"rgba(255, 255, 255, 1)","lineHeight":"22px"} as any}></Button>

<Button id="230104" onClick={ (e?: any) => { if(e && e.stopPropagation) e.stopPropagation();this.PersonsComponent_230104_onClick()} } showCursorPointer visibility={this.state.isComp230104Visible} condition={(rowData) => ReactSystemFunctions.isEqualTo(this.state.KisilerYetki[0]?.ogrenciExcelUploadandSample?.toString(), "1")} label={ReactSystemFunctions.translate(this.ml, 230104, "label",this.defaultML)} size="middle" loading={false} ghost={false} block={false} htmlType="button" iconPosition="left" danger={false} style={{"borderTopLeftRadius":8,"borderTopRightRadius":8,"borderBottomRightRadius":8,"borderBottomLeftRadius":8,"backgroundColor":"rgba(0, 190, 214, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":8,"paddingRight":16,"paddingBottom":8,"paddingLeft":16,"alignItems":"center","textAlign":"-webkit-center","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"16px","color":"rgba(255, 255, 255, 1)","lineHeight":"22px"} as any}></Button>

<Form.Item className='kFormItem' name='persons_22832_value' >
<KSelectBox id="22832" onChange={ (e?: any) => { if(e && e.stopPropagation) e.stopPropagation();this.PersonsComponent_22832_onChange()} } visibility={this.state.isComp22832Visible} condition={(rowData) => (ReactSystemFunctions.isEmpty(this.props.screenInputs.role, ""))} kuikaRef={this.persons_22832_value_kuikaSelectBoxRef} options={this.state.Rol} placeholder={ReactSystemFunctions.translate(this.ml, 22832, "placeholder",this.defaultML)} allowClear={true} autoClearSearchValue={true} showSearch={false} widthType="fill" containsNullItem={false} sortBy="none" datavaluefield='val' datatextfield='txt' style={{"borderTopLeftRadius":8,"borderTopRightRadius":8,"borderBottomRightRadius":8,"borderBottomLeftRadius":8,"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"width":"200px","alignItems":"center","textAlign":"-webkit-left","display":"block","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></KSelectBox>
</Form.Item>

<Form.Item className='kFormItem' name='persons_133816_value' >
<KSelectBox id="133816" onChange={ (e?: any) => { if(e && e.stopPropagation) e.stopPropagation();this.PersonsComponent_133816_onChange()} } visibility={this.state.isComp133816Visible} condition={(rowData) => (ReactSystemFunctions.isEqualTo(this.state.IsSinifExisting[0]?.isSinifExisting?.toString(), "1") && ReactSystemFunctions.isEqualTo(this.state.KisilerYetki[0]?.sinifFiltre?.toString(), "1"))} kuikaRef={this.persons_133816_value_kuikaSelectBoxRef} options={this.state.AktifKirilim1AltKirilim2leri} placeholder={this.state.KurumTipiSelectById?.at?.(0)?.kirilim2Filtresi ?? undefined} allowClear={true} autoClearSearchValue={true} showSearch={false} widthType="fill" containsNullItem={false} sortBy="none" datavaluefield='id' datatextfield='tanim' style={{"borderTopLeftRadius":8,"borderTopRightRadius":8,"borderBottomRightRadius":8,"borderBottomLeftRadius":8,"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"width":"200px","alignItems":"center","textAlign":"-webkit-left","display":"block","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></KSelectBox>
</Form.Item>

<Form.Item className='kFormItem' name='persons_416063_value' >
<TextInput id="416063" onPressEnter={ (e?: any) => { if(e && e.stopPropagation) e.stopPropagation();this.PersonsComponent_416063_onPressEnter()} } visibility={this.state.isComp416063Visible} condition={(rowData) => (ReactSystemFunctions.isEqualTo(this.state.KisilerYetki[0]?.arama?.toString(), "1"))} placeholder={ReactSystemFunctions.translate(this.ml, 416063, "placeholder",this.defaultML)} allowClear={false} bordered={true} disabled={false} type="text" iconColor="rgba(0, 0, 0, 1)" formatter="None" isCharOnly={false} autoComplete={true} style={{"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(255, 255, 255, 1)","borderStyle":"solid","paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"width":"200px","alignItems":"center","textAlign":"-webkit-left","display":"block","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></TextInput>
</Form.Item>

<Button id="814413" onClick={ (e?: any) => { if(e && e.stopPropagation) e.stopPropagation();this.PersonsComponent_814413_onClick()} } showCursorPointer visibility={this.state.isComp814413Visible} condition={(rowData) => (ReactSystemFunctions.isEqualTo(this.state.KisilerYetki[0]?.yeniButton?.toString(), "1"))} label={this.state.RoleToCountLabel?.at?.(0)?.yeniLabel ?? undefined} size="middle" loading={false} ghost={false} block={false} htmlType="button" iconPosition="left" danger={false} style={{"borderTopLeftRadius":8,"borderTopRightRadius":8,"borderBottomRightRadius":8,"borderBottomLeftRadius":8,"backgroundColor":"rgba(0, 190, 214, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":8,"paddingRight":16,"paddingBottom":8,"paddingLeft":16,"alignItems":"center","textAlign":"-webkit-center","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"16px","color":"rgba(255, 255, 255, 1)","lineHeight":"22px"} as any}></Button>
</HorizontalStack>
</KCol>
</KRow>

<KRow id="720564" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="769800" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderTopLeftRadius":8,"borderTopRightRadius":8,"borderBottomRightRadius":8,"borderBottomLeftRadius":8,"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KTable id="399769" kuikaRef={this.persons_399769_value_kuikaTableRef} form={this.props.form} dataSource={this.state.KisiSearch} size="middle" bordered={true} loading={false} transformedOnMobileResolution={false} showHeader={true} fixedHeader={false} editable={false} tableWidthMode="fit" globalSearch={false} searchable={false} sorter={false} filtering={false} pagination={false} pageSize={10} showSizeChanger={false} columnChooser={false} resizableColumns={false} striped={true} stripedColor="rgba(0, 190, 214, 0.1)" insertRowActive={false} insertRowPosition="top" showNoDataFound={true} nodatafoundmessage={ReactSystemFunctions.translate(this.ml, 399769, "nodatafoundmessage",this.defaultML)} multiSelect={false} export={false} editableAlwaysActive={false} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KTableHeader id="500856" hideOnMobileResolution={false} textDirection="Default" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"center","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":400,"fontSize":"12px","color":"rgba(10, 33, 64, 1)","lineHeight":"20px"} as any}></KTableHeader>

<KTableRow id="489075" hoverFontColor="red" hoverBgColor="#F5F5F5" style={{"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","backgroundColor":"rgba(255, 255, 255, 1)","paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"height":"30px","alignItems":"center","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KTableColumn id="608005" visibility={this.state.isComp608005Visible} condition={(rowData) => (ReactSystemFunctions.isEqualTo(this.state.IsSinifExisting[0]?.isSinifExisting?.toString(), "1"))} title={this.state.MyContext?.at?.(0)?.kirilim2Adi ?? undefined} alwaysVisibleOnMobileResolution={false} hideFiltering={false} hideSorting={false} hideSearch={false} footerOptions="none" style={{"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"center","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="999699" value="[datafield:kirilim2]" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</KTableColumn>

<KTableColumn id="771759" visibility={this.state.isComp771759Visible} condition={(rowData) => (ReactSystemFunctions.isEqualTo(this.state.KisilerYetki[0]?.okulNumarasiColumn?.toString(), "1"))} title={ReactSystemFunctions.translate(this.ml, 771759, "title",this.defaultML)} alwaysVisibleOnMobileResolution={false} hideFiltering={false} hideSorting={false} hideSearch={false} footerOptions="none" style={{"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"center","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="85766" value="[datafield:okulNumarasi]" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</KTableColumn>

<KTableColumn id="695026" title={ReactSystemFunctions.translate(this.ml, 695026, "title",this.defaultML)} alwaysVisibleOnMobileResolution={false} hideFiltering={false} hideSorting={false} hideSearch={false} footerOptions="none" style={{"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"center","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="600713" value="[datafield:adi]" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</KTableColumn>

<KTableColumn id="8904678" title={ReactSystemFunctions.translate(this.ml, 8904678, "title",this.defaultML)} alwaysVisibleOnMobileResolution={false} hideFiltering={false} hideSorting={false} hideSearch={false} footerOptions="none" style={{"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"center","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="3886180" value="[datafield:soyadi]" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</KTableColumn>

<KTableColumn id="338727" title={ReactSystemFunctions.translate(this.ml, 338727, "title",this.defaultML)} alwaysVisibleOnMobileResolution={false} hideFiltering={false} hideSorting={false} hideSearch={false} footerOptions="none" style={{"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"center","textAlign":"-webkit-center","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="350167" value="[datafield:yas]" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</KTableColumn>

<KTableColumn id="607282" title={ReactSystemFunctions.translate(this.ml, 607282, "title",this.defaultML)} alwaysVisibleOnMobileResolution={false} hideFiltering={false} hideSorting={false} hideSearch={false} footerOptions="none" style={{"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"center","textAlign":"-webkit-center","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="628289" value="[datafield:cinsiyet]" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</KTableColumn>

<KTableColumn id="62810" visibility={this.state.isComp62810Visible} condition={(rowData) => (ReactSystemFunctions.isEqualTo(this.state.KisilerYetki[0]?.okulBransColumn?.toString(), "1"))} title={ReactSystemFunctions.translate(this.ml, 62810, "title",this.defaultML)} alwaysVisibleOnMobileResolution={false} hideFiltering={false} hideSorting={false} hideSearch={false} footerOptions="none" style={{"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"center","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="254529" value="[datafield:okulBrans]" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"width":"73px","alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</KTableColumn>

<KTableColumn id="9622778" visibility={this.state.isComp9622778Visible} condition={(rowData) => (ReactSystemFunctions.isEqualTo(this.state.KisilerYetki[0]?.epostaColumn?.toString(), "1"))} title={ReactSystemFunctions.translate(this.ml, 9622778, "title",this.defaultML)} alwaysVisibleOnMobileResolution={false} hideFiltering={false} hideSorting={false} hideSearch={false} footerOptions="none" style={{"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"center","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="8081341" value="[datafield:eposta]" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</KTableColumn>

<KTableColumn id="3001244" visibility={this.state.isComp3001244Visible} condition={(rowData) => ReactSystemFunctions.isEqualTo(this.state.KisilerYetki[0]?.telefonNumarasi?.toString(), "1")} title={ReactSystemFunctions.translate(this.ml, 3001244, "title",this.defaultML)} alwaysVisibleOnMobileResolution={false} hideFiltering={false} hideSorting={false} hideSearch={false} footerOptions="none" style={{"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"center","textAlign":"-webkit-center","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="2747075" value="[datafield:telefon]" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</KTableColumn>

<KTableColumn id="588607" visibility={this.state.isComp588607Visible} condition={(rowData) => (ReactSystemFunctions.isEqualTo(this.state.KisilerYetki[0]?.freelanceColumn?.toString(), "1"))} title={ReactSystemFunctions.translate(this.ml, 588607, "title",this.defaultML)} alwaysVisibleOnMobileResolution={false} hideFiltering={false} hideSorting={false} hideSearch={false} footerOptions="none" style={{"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"width":"80px","alignItems":"center","textAlign":"-webkit-center","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Icon id="532054" onClick={ (e?: any) => { if(e && e.stopPropagation) e.stopPropagation();this.PersonsComponent_532054_onClick()} } showCursorPointer visibility={this.state.isComp532054Visible} condition={(rowData) => ReactSystemFunctions.isEqualTo(rowData.freelanceMi, "true")} iconName="check" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"20px","color":"rgba(155, 155, 155, 1)","letterSpacing":"1px"} as any}></Icon>

<Icon id="602948" onClick={ (e?: any) => { if(e && e.stopPropagation) e.stopPropagation();this.PersonsComponent_602948_onClick()} } showCursorPointer visibility={this.state.isComp602948Visible} condition={(rowData) => ReactSystemFunctions.isNotEqualTo(rowData.freelanceMi, "true")} iconName="close" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"20px","color":"rgba(155, 155, 155, 1)","letterSpacing":"1px"} as any}></Icon>
</KTableColumn>

<KTableColumn id="5477140" visibility={this.state.isComp5477140Visible} condition={(rowData) => ReactSystemFunctions.isEqualTo(this.state.KisilerYetki[0]?.velileriColumn?.toString(), "1")} title={ReactSystemFunctions.translate(this.ml, 5477140, "title",this.defaultML)} alwaysVisibleOnMobileResolution={false} hideFiltering={false} hideSorting={false} hideSearch={false} footerOptions="none" style={{"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"width":"80px","alignItems":"center","textAlign":"-webkit-center","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<HorizontalStack id="724709" direction="horizontal" size={4} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="984657" value="[datafield:veliCount]" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"width":"20px","alignItems":"center","textAlign":"-webkit-center","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>

<Icon id="527161" onClick={ (e?: any) => { if(e && e.stopPropagation) e.stopPropagation();this.PersonsComponent_527161_onClick()} } showCursorPointer iconName="arrow_forward_ios" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"20px","color":"rgba(155, 155, 155, 1)","letterSpacing":"1px"} as any}></Icon>
</HorizontalStack>
</KTableColumn>

<KTableColumn id="928945" visibility={this.state.isComp928945Visible} condition={(rowData) => ReactSystemFunctions.isEqualTo(this.state.KisilerYetki[0]?.checkuplarColumn?.toString(), "1")} title={ReactSystemFunctions.translate(this.ml, 928945, "title",this.defaultML)} alwaysVisibleOnMobileResolution={false} hideFiltering={false} hideSorting={false} hideSearch={false} footerOptions="none" style={{"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"width":"80px","alignItems":"center","textAlign":"-webkit-center","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<HorizontalStack id="568869" direction="horizontal" size={4} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="191502" value="[datafield:checkupAdedi]" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"width":"20px","alignItems":"center","textAlign":"-webkit-center","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>

<Icon id="298698" onClick={ (e?: any) => { if(e && e.stopPropagation) e.stopPropagation();this.PersonsComponent_298698_onClick()} } showCursorPointer iconName="arrow_forward_ios" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"20px","color":"rgba(155, 155, 155, 1)","letterSpacing":"1px"} as any}></Icon>
</HorizontalStack>
</KTableColumn>

<KTableColumn id="247909" visibility={this.state.isComp247909Visible} condition={(rowData) => ReactSystemFunctions.isEqualTo(this.state.KisilerYetki[0]?.velisiOlduguOgrenciColumn?.toString(), "1")} title={this.state.KurumTipiSelectById?.at?.(0)?.velisioldugu ?? undefined} alwaysVisibleOnMobileResolution={false} hideFiltering={false} hideSorting={false} hideSearch={false} footerOptions="none" style={{"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"width":"80px","alignItems":"center","textAlign":"-webkit-center","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<HorizontalStack id="793002" direction="horizontal" size={4} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="125813" value="[datafield:ogrenciCount]" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"width":"20px","alignItems":"center","textAlign":"-webkit-center","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>

<Icon id="645540" onClick={ (e?: any) => { if(e && e.stopPropagation) e.stopPropagation();this.PersonsComponent_645540_onClick()} } showCursorPointer iconName="arrow_forward_ios" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"20px","color":"rgba(155, 155, 155, 1)","letterSpacing":"1px"} as any}></Icon>
</HorizontalStack>
</KTableColumn>

<KTableColumn id="637427" visibility={this.state.isComp637427Visible} condition={(rowData) => ReactSystemFunctions.isEqualTo(this.state.KisilerYetki[0]?.siniflariColumn?.toString(), "1")} title={this.state.KurumTipiSelectById?.at?.(0)?.kirilim2lari ?? undefined} alwaysVisibleOnMobileResolution={false} hideFiltering={false} hideSorting={false} hideSearch={false} footerOptions="none" style={{"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"width":"80px","alignItems":"center","textAlign":"-webkit-center","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<HorizontalStack id="779863" direction="horizontal" size={4} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="174819" value="[datafield:kirilim2Adedi]" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"width":"20px","alignItems":"center","textAlign":"-webkit-center","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>

<Icon id="627973" onClick={ (e?: any) => { if(e && e.stopPropagation) e.stopPropagation();this.PersonsComponent_627973_onClick()} } showCursorPointer iconName="arrow_forward_ios" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"20px","color":"rgba(155, 155, 155, 1)","letterSpacing":"1px"} as any}></Icon>
</HorizontalStack>
</KTableColumn>

<KTableColumn id="724202" visibility={this.state.isComp724202Visible} condition={(rowData) => ReactSystemFunctions.isEqualTo(this.state.KisilerYetki[0]?.profilColumn?.toString(), "1")} title={ReactSystemFunctions.translate(this.ml, 724202, "title",this.defaultML)} alwaysVisibleOnMobileResolution={false} hideFiltering={false} hideSorting={false} hideSearch={false} footerOptions="none" style={{"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"width":"80px","alignItems":"center","textAlign":"-webkit-center","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<HorizontalStack id="450049" direction="horizontal" size={4} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="835173" value="[datafield:profilSayisi]" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>

<Icon id="626422" onClick={ (e?: any) => { if(e && e.stopPropagation) e.stopPropagation();this.PersonsComponent_626422_onClick()} } showCursorPointer iconName="arrow_forward_ios" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"20px","color":"rgba(155, 155, 155, 1)","letterSpacing":"1px"} as any}></Icon>
</HorizontalStack>
</KTableColumn>

<KTableColumn id="307279" visibility={this.state.isComp307279Visible} condition={(rowData) => (ReactSystemFunctions.isEqualTo(this.state.KisilerYetki[0]?.kullaniciColumn?.toString(), "1"))} title={ReactSystemFunctions.translate(this.ml, 307279, "title",this.defaultML)} alwaysVisibleOnMobileResolution={false} hideFiltering={false} hideSorting={false} hideSearch={false} footerOptions="none" style={{"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"center","textAlign":"-webkit-center","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Icon id="603478" visibility={this.state.isComp603478Visible} condition={(rowData) => (ReactSystemFunctions.isNotEmpty(rowData.kUserId, ""))} iconName="check" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"20px","color":"rgba(0, 190, 214, 1)","letterSpacing":"1px"} as any}></Icon>

<Icon id="71769" onClick={ (e?: any) => { if(e && e.stopPropagation) e.stopPropagation();this.PersonsComponent_71769_onClick()} } showCursorPointer visibility={this.state.isComp71769Visible} condition={(rowData) => (ReactSystemFunctions.isEmpty(rowData.kUserId, ""))} iconName="person_add" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"20px","color":"rgba(0, 190, 214, 1)","letterSpacing":"1px"} as any}></Icon>
</KTableColumn>

<KTableColumn id="810485" visibility={this.state.isComp810485Visible} condition={(rowData) => ReactSystemFunctions.isEqualTo(this.state.KisilerYetki[0]?.mailGonderColumn?.toString(), "1")} title={ReactSystemFunctions.translate(this.ml, 810485, "title",this.defaultML)} alwaysVisibleOnMobileResolution={false} hideFiltering={false} hideSorting={false} hideSearch={false} footerOptions="none" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"center","textAlign":"-webkit-center","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Icon id="74440" visibility={this.state.isComp74440Visible} condition={(rowData) => (ReactSystemFunctions.isEqualTo(rowData.bildirimDurum, "3"))} iconName="check" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"20px","color":"rgba(0, 190, 214, 1)","letterSpacing":"1px"} as any}></Icon>

<Icon id="956466" onClick={ (e?: any) => { if(e && e.stopPropagation) e.stopPropagation();this.PersonsComponent_956466_onClick()} } showCursorPointer visibility={this.state.isComp956466Visible} condition={(rowData) => (ReactSystemFunctions.isEqualTo(rowData.bildirimDurum, "2"))} iconName="attach_email" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"20px","color":"rgba(0, 190, 214, 1)","letterSpacing":"1px"} as any}></Icon>

<VerticalStack id="295192" direction="vertical" size={12} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Icon id="775302" onClick={ (e?: any) => { if(e && e.stopPropagation) e.stopPropagation();this.PersonsComponent_775302_onClick()} } showCursorPointer visibility={this.state.isComp775302Visible} condition={(rowData) => (ReactSystemFunctions.isEqualTo(rowData.bildirimDurum, "1"))} iconName="contact_mail" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"20px","color":"rgba(0, 190, 214, 1)","letterSpacing":"1px"} as any}></Icon>
</VerticalStack>
</KTableColumn>

<KTableColumn id="792509" title={ReactSystemFunctions.translate(this.ml, 792509, "title",this.defaultML)} alwaysVisibleOnMobileResolution={false} hideFiltering={false} hideSorting={false} hideSearch={false} footerOptions="none" style={{"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"width":"160px","maxWidth":"160px","alignItems":"center","textAlign":"-webkit-center","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KRow id="912082" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="792012" xs={6} sm={6} md={6} lg={6} xl={6} xxl={6} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"alignItems":"center","textAlign":"-webkit-center","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Icon id="248772" onClick={ (e?: any) => { if(e && e.stopPropagation) e.stopPropagation();this.PersonsComponent_248772_onClick()} } showCursorPointer visibility={this.state.isComp248772Visible} condition={(rowData) => (ReactSystemFunctions.isEqualTo(rowData.scheduleDegistirilebilirMi, "1"))} iconName="schedule" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"20px","color":"rgba(155, 155, 155, 1)","letterSpacing":"1px"} as any}></Icon>
</KCol>

<KCol id="892303" visibility={this.state.isComp892303Visible} condition={(rowData) => ReactSystemFunctions.isEqualTo(this.state.KisilerYetki[0]?.gozIcon?.toString(), "1")} xs={6} sm={6} md={6} lg={6} xl={6} xxl={6} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingRight":10,"paddingLeft":10,"alignItems":"center","textAlign":"-webkit-center","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Icon id="401940" onClick={ (e?: any) => { if(e && e.stopPropagation) e.stopPropagation();this.PersonsComponent_401940_onClick()} } showCursorPointer iconName="remove_red_eye" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"20px","color":"rgba(155, 155, 155, 1)","letterSpacing":"1px"} as any}></Icon>
</KCol>

<KCol id="660911" visibility={this.state.isComp660911Visible} condition={(rowData) => ReactSystemFunctions.isEqualTo(this.state.KisilerYetki[0]?.editIcon?.toString(), "1")} xs={6} sm={6} md={6} lg={6} xl={6} xxl={6} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingRight":10,"paddingLeft":10,"alignItems":"center","textAlign":"-webkit-center","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Icon id="860665" onClick={ (e?: any) => { if(e && e.stopPropagation) e.stopPropagation();this.PersonsComponent_860665_onClick()} } showCursorPointer iconName="create" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"20px","color":"rgba(155, 155, 155, 1)","letterSpacing":"1px"} as any}></Icon>
</KCol>

<KCol id="175914" visibility={this.state.isComp175914Visible} condition={(rowData) => ReactSystemFunctions.isEqualTo(this.state.KisilerYetki[0]?.deleteIcon?.toString(), "1")} xs={6} sm={6} md={6} lg={6} xl={6} xxl={6} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"alignItems":"center","textAlign":"-webkit-center","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Icon id="505853" onClick={ (e?: any) => { if(e && e.stopPropagation) e.stopPropagation();this.PersonsComponent_505853_onClick()} } showCursorPointer iconName="delete" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"20px","color":"rgba(155, 155, 155, 1)","letterSpacing":"1px"} as any}></Icon>
</KCol>
</KRow>
</KTableColumn>
</KTableRow>
</KTable>

<KPagination id="172599" onChange={ (e?: any) => { if(e && e.stopPropagation) e.stopPropagation();this.PersonsComponent_172599_onChange()} } kuikaRef={this.persons_172599_value_kuikaPaginationRef} total={this.state.KisiSearch?.at?.(0)?.personCount ?? undefined} pageSize={10} defaultCurrent={1} current={1} defaultPageSize={10} disabled={false} hideOnSinglePage={true} showLessItems={false} showQuickJumper={false} showSizeChanger={false} options={[{'value':"Item 1"},{'value':"Item 2"}]} showFirstPageButton={true} showLastPageButton={true} showNumbers={false} showText={false} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-right","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></KPagination>
</KCol>
</KRow>

<KDrawer id="256479" placement="right" onClose={() => { this.setState({NavVisible256479: false}, () => { this.callCallbackFunction(256479) })}} visible={this.state.NavVisible256479} width="450px" push={true} children={this.state.NavVisible256479 && <Person_Screen onClose={() => { this.setState({ NavVisible256479: false }, () => { this.callCallbackFunction(256479) }) }} screenInputs={window.kuika.navigationScreenInputs} />} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></KDrawer>

<KDrawer id="189029" placement="right" onClose={() => { this.setState({NavVisible189029: false}, () => { this.callCallbackFunction(189029) })}} visible={this.state.NavVisible189029} width="700px" height="100vh" push={true} children={this.state.NavVisible189029 && <PersonConnected_Screen onClose={() => { this.setState({ NavVisible189029: false }, () => { this.callCallbackFunction(189029) }) }} screenInputs={window.kuika.navigationScreenInputs} />} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></KDrawer>

<KDrawer id="584409" placement="right" onClose={() => { this.setState({NavVisible584409: false}, () => { this.callCallbackFunction(584409) })}} visible={this.state.NavVisible584409} width="380px" height="100vh" push={true} children={this.state.NavVisible584409 && <Person_Screen onClose={() => { this.setState({ NavVisible584409: false }, () => { this.callCallbackFunction(584409) }) }} screenInputs={window.kuika.navigationScreenInputs} />} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></KDrawer>

<KDrawer id="544097" placement="right" onClose={() => { this.setState({NavVisible544097: false}, () => { this.callCallbackFunction(544097) })}} visible={this.state.NavVisible544097} width="975px" height="100vh" push={true} children={this.state.NavVisible544097 && <EgitmenAyarlari_Screen onClose={() => { this.setState({ NavVisible544097: false }, () => { this.callCallbackFunction(544097) }) }} screenInputs={window.kuika.navigationScreenInputs} />} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></KDrawer>

<KDrawer id="207805" placement="right" onClose={() => { this.setState({NavVisible207805: false}, () => { this.callCallbackFunction(207805) })}} visible={this.state.NavVisible207805} width="850px" height="100vh" push={true} children={this.state.NavVisible207805 && <Profiles_Screen onClose={() => { this.setState({ NavVisible207805: false }, () => { this.callCallbackFunction(207805) }) }} screenInputs={window.kuika.navigationScreenInputs} />} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></KDrawer>

<KDrawer id="552664" placement="right" onClose={() => { this.setState({NavVisible552664: false}, () => { this.callCallbackFunction(552664) })}} visible={this.state.NavVisible552664} width="700px" height="100vh" push={true} children={this.state.NavVisible552664 && <PersonConnected_Screen onClose={() => { this.setState({ NavVisible552664: false }, () => { this.callCallbackFunction(552664) }) }} screenInputs={window.kuika.navigationScreenInputs} />} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></KDrawer>

<KDrawer id="870237" placement="right" onClose={() => { this.setState({NavVisible870237: false}, () => { this.callCallbackFunction(870237) })}} visible={this.state.NavVisible870237} width="380px" height="100vh" push={true} children={this.state.NavVisible870237 && <OgretmenSiniflari_Screen onClose={() => { this.setState({ NavVisible870237: false }, () => { this.callCallbackFunction(870237) }) }} screenInputs={window.kuika.navigationScreenInputs} />} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></KDrawer>
</div>

<Footer id="persons_footer" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KRow id="943815" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="458902" xs={8} sm={8} md={8} lg={8} xl={8} xxl={8} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"center","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<HorizontalStack id="372787" direction="horizontal" size={4} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="123046" value={this.state.KisiSearch?.at?.(0)?.personCount ?? undefined} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>

<Label id="264407" value={this.state.RoleToCountLabel?.at?.(0)?.label ?? undefined} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>

<Label id="71408" value={ReactSystemFunctions.translate(this.ml, 71408, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</HorizontalStack>
</KCol>

<KCol id="826325" xs={8} sm={8} md={8} lg={8} xl={8} xxl={8} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></KCol>

<KCol id="24208" xs={8} sm={8} md={8} lg={8} xl={8} xxl={8} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></KCol>
</KRow>
</Footer>
</div>
                    
                </Spin>
              </Master_Screen>
            </>
        );
    }
}

const mapStateToProps = (state: AppState) => {
    return {
        spinnerCount: state.spinnerReducer.spinnerCount,
        photoTakeVisible: state.photoTakeReducer.visible,
        isImportMappedExcelModalVisible: state.importMappedExcelModalReducer.visible
    };
  }
  const mapDispatchToProps = dispatch => ({
    showPhotoTake: () => dispatch(showPhotoTake()),
    hidePhotoTake: () => dispatch(hidePhotoTake()),
    showStripeDrawer: (data,callback,that,future) => dispatch(showStripeDrawer(data,callback,that,future)),
    hideStripeDrawer: (data,callback,that) => dispatch(hideStripeDrawer(data,callback,that)),
    showIyzicoDrawer: (data,callback,that) => dispatch(showIyzicoDrawer(data,callback,that)),
    hideIyzicoDrawer: (data,callback,that) => dispatch(hideIyzicoDrawer(data,callback,that)),
    showImportMappedExcelModal: (dsId, tableName, primaryKey, columnMapping) => dispatch(showImportMappedExcelModal(dsId, tableName, primaryKey, columnMapping)),
    hideImportMappedExcelModal: () => dispatch(hideImportMappedExcelModal())
  });
const tmp = withGoogleLogin(withContext(withScreenInput(withForm(withHistory(connect(mapStateToProps, mapDispatchToProps)(Persons_Screen))))));
export { tmp as Persons_Screen };
//export default tmp;
//export { tmp as Persons_Screen };

