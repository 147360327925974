import MDEditor, { MDEditorProps } from "@uiw/react-md-editor";
import { PureComponent, ReactNode } from "react";
import ReactDOM from "react-dom";
import { CommonProps } from "../../components/web-components/common/common-props";
import withCommonEvents from "../../shared/hoc/with-common-events";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import "./style.css";

const backendUrl = KuikaAppManager.GetBackendUrl();

type IKMDViewerProps = MDEditorProps;

interface IKMDViewerState {}

export interface ITempBase64 {}

class KMDViewer extends PureComponent<IKMDViewerProps & CommonProps, IKMDViewerState> {
  constructor(props: IKMDViewerProps) {
    super(props);
    this.state = {};
  }

  handleAnchors = () => {
    const node = ReactDOM.findDOMNode(this) as Element;
    const anchors = node.getElementsByTagName("a") as any;
    for (const element of anchors) {
      element.target = "_blank";
    }
  };

  getValue = () => {
    if (this.props.value) {
      const value = this.props.value.replaceAll("{KUIKARESOURCES/}", `${backendUrl}/resource/runtime/byid?resourceId=`);
      return value;
    }
  };

  componentDidUpdate() {
    this.handleAnchors();
  }

  componentDidMount() {
    this.handleAnchors();
  }

  render(): ReactNode {
    return (
      <>
        <div className="kuika_mdViewer">
          <MDEditor {...this.props} value={this.getValue()} hideToolbar={true} preview="preview"></MDEditor>
        </div>
      </>
    );
  }
}

const kMDViewer = withCommonEvents(KMDViewer);
export { kMDViewer as KMDViewer };
