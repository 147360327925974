import { Radio } from "antd/es";
import { RadioProps } from "antd/es/radio";
import _ from "lodash";
import { PureComponent, ReactNode } from "react";
import { CommonProps } from "../../../components/web-components/common/common-props";
import withCommonEvents from "../../../shared/hoc/with-common-events";

interface IKRadioProps extends RadioProps {
  label: string;
}

interface IKRadioState {}

class KRadio extends PureComponent<IKRadioProps & CommonProps, IKRadioState> {
  constructor(props: IKRadioProps) {
    super(props);
    this.state = {};
  }

  getProps = () => {
    const props: any = _.clone(this.props);
    return props;
  };

  render(): ReactNode {
    return (
      <>
        <Radio {...this.getProps()}>{this.props.label} </Radio>
      </>
    );
  }
}

const kRadio = withCommonEvents(KRadio);
export { kRadio as KRadio };
