import { Guid } from "guid-typescript";
import { PureComponent } from "react";
import withCommonEvents from "../../../../shared/hoc/with-common-events";
import { CommonProps } from "../../common/common-props";

interface MixedChartPointItemProps {
  type: "point";
  datasource: any;
  color: any;
  size: number;
}

interface MixedChartPointItemState {
  uniqueKey: Guid;
}

class MixedChartPointItem extends PureComponent<MixedChartPointItemProps & CommonProps, MixedChartPointItemState> {
  constructor(props: MixedChartPointItemProps) {
    super(props);
    this.state = {
      uniqueKey: Guid.create()
    };
  }
}

const mixedChartPointItem = withCommonEvents(MixedChartPointItem);
export { mixedChartPointItem as MixedChartPointItem };
