import React from "react";
import Axios, { AxiosResponse } from "axios";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { KNavigator } from "../../shared/hoc/with-history";
import { KContext } from "../../shared/hoc/with-context";
import { useLocation } from "react-router-dom";
import { FormInstance } from "antd/es/form";
import { Guid } from "guid-typescript";
import Swal from "sweetalert2";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import moment from "moment";
import { IValidationData } from "../../models/dto/validation-data";
import { IStripePayment } from "../../models/dto/stripe-payment";
import { IUserInfoDto } from "../../models/dto/user-info.dto";
import { UserService } from "../../services/user-service";
import _ from "lodash";
import mqtt, { MqttClient } from "mqtt";
import { IMqttResult } from "../../models/dto/mqtt.dto";

declare let window: any;

export interface IProtokolBilesenList_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

export interface IProtokolBilesenList_ScreenState {
    isSpinnerVisible: boolean;
    isPageVisible: boolean;
	isLeftMenuVisible: boolean;
    spinnerCount: number;
    selectedLanguage: string;
    isPageInitLoaded: false;
    isPageRecurringLoaded: false;
	NAVIGATE: any;
	NAVIGATE_dummy: any;
	ProtokolSelectById: any[];
	ProtokolSelectById_dummy: any[];
	ProtokolBilesenleri: any[];
	ProtokolBilesenleri_dummy: any[];
	SaveRecord: number;
	SaveRecord_dummy: number;
	DeleteRecord: number;
	DeleteRecord_dummy: number;
	Notify: boolean;
	Notify_dummy: boolean;


}

export class ProtokolBilesenList_ScreenBase extends React.PureComponent<IProtokolBilesenList_ScreenProps, any> {
	protokolbilesenlist_399769_value_kuikaTableRef: React.RefObject<any>;
    ml=[{"Id":"423ed54a-d6f3-fccb-7d3e-72c7b24c8b7a","Name":"tr_TR","ShortName":"Turkish","IsDefault":true,"LanguagePhrases":[]},{"Id":"74503767-9450-4cae-9db5-729d038bcb36","Name":"en_US","ShortName":"English","LanguagePhrases":[]},{"Id":"7c06d7c8-db07-4997-9114-b5d5635a869e","Name":"FixedValues","ShortName":"FixedValues","LanguagePhrases":[{"Id":923160,"PropertyName":"value","Value":"PROTOKOL BİLEŞENLERİ"},{"Id":814413,"PropertyName":"label","Value":"YENİ"},{"Id":536516,"PropertyName":"value","Value":"Protokol"},{"Id":399769,"PropertyName":"nodatafoundmessage","Value":"Veri Bulunamadı"},{"Id":91769,"PropertyName":"title","Value":"Sıra"},{"Id":629897,"PropertyName":"value","Value":"[datafield:sira]"},{"Id":8107049,"PropertyName":"title","Value":"Bileşen"},{"Id":8143247,"PropertyName":"value","Value":"[datafield:bilesen]"},{"Id":140826,"PropertyName":"title","Value":"Sınıflandırma"},{"Id":878247,"PropertyName":"value","Value":"[datafield:sinif]"},{"Id":78918,"PropertyName":"title","Value":"Pay"},{"Id":660133,"PropertyName":"value","Value":"[datafield:pay]"},{"Id":82595,"PropertyName":"title","Value":"Alt Bileşen"},{"Id":70766,"PropertyName":"value","Value":"[datafield:altbilesenadetleri]"}]}]
    defaultML = "tr_TR"
    
    screenHash = ""
    
    constructor(props) {
        super(props);
		this.protokolbilesenlist_399769_value_kuikaTableRef = React.createRef();

        this.state = {
            isSpinnerVisible: false,
            isPageVisible: false,
            isLeftMenuVisible: false,
            spinnerCount: 0,
            selectedLanguage: "",
            isPageInitLoaded: false,
            isPageRecurringLoaded: false,
            	        NAVIGATE: "",
	        ProtokolSelectById: [],
	        ProtokolBilesenleri: [],
	        SaveRecord: false,
	        DeleteRecord: false,
	        Notify: false,

            
        }

        this.state.isPageVisible = true;
        
    }

    async componentDidMount() {

        if (KuikaAppManager.isPageInitPrevented()) {
            KuikaAppManager.calculateAndSetBodyHeight("protokolbilesenlist", "");
            return;
        }
        
        ReactSystemFunctions.validateToken()
        if (true){
            await this.ProtokolBilesenListPageInit();
        }
        
        
        
        KuikaAppManager.calculateAndSetBodyHeight("protokolbilesenlist", "");
    }

    componentWillUnmount() {
        
        
        
    }

    

        
    componentDidUpdate= async (prevProps, prevState) => {
		KuikaAppManager.calculateAndSetBodyHeight("protokolbilesenlist", ""    );
        if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
			await this.ProtokolBilesenListPageInit();
		}

        
	}

    fillFormInitValues() {
        this.props.form.setFieldsValue({
});

    }

      setPageInitLoaded = (value: boolean) => {
        this.setState({ isPageInitLoaded: value });
      }

      setPageRecurringLoaded = (value: boolean) => {
        this.setState({ isPageRecurringLoaded: value });
      }

      clearFieldValidation = (changedValues: any) => {
        const fieldName = Object.keys(changedValues)[0];
        if (fieldName) {
          this.props.form.setFields([
            {
              name: fieldName,
              errors: []
            }
          ]);
        }
      };

      

      

    ProtokolBilesenListPageInit = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }

        let localVar = {
			Id_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.ProtokolId ?? this.props.screenInputs.protokolid, "Guid"),
			protokolId_1: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.ProtokolId ?? this.props.screenInputs.protokolid, "Guid")
        }

	KuikaAppManager.showSpinner(this);
	
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "ProtokolBilesenList/ProtokolBilesenListPageInit", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

		stateVars.ProtokolSelectById = result?.data.protokolSelectById;
		formVars.protokolbilesenlist_662169_value = ReactSystemFunctions.toString(this, (stateVars.ProtokolSelectById?.length > 0 ? stateVars.ProtokolSelectById[0].tanim : this.state.ProtokolSelectById?.length > 0 ? this.state.ProtokolSelectById[0].tanim : null));
		stateVars.ProtokolBilesenleri = result?.data.protokolBilesenleri;
		
		this.props.form.setFieldsValue(formVars);
		
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.ProtokolBilesenListPageInit1_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
	ProtokolBilesenListPageInit1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }

			formVars.protokolbilesenlist_662169_value = ReactSystemFunctions.toString(this, (stateVars.ProtokolSelectById?.length > 0 ? stateVars.ProtokolSelectById[0].tanim : this.state.ProtokolSelectById?.length > 0 ? this.state.ProtokolSelectById[0].tanim : null));

			
			stateVars.dataSource_399769 = this.state.ProtokolBilesenleri;

	this.props.form.setFieldsValue(formVars);
	this.setState(stateVars);
	KuikaAppManager.hideSpinner(this);

            this.setPageInitLoaded(true);
            return isErrorOccurred;
        }




		ProtokolBilesenListComponent_814413_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
KuikaAppManager.showSpinner(this);

					KuikaAppManager.prepareForNavigation();
						KuikaAppManager.addToPageInputVariables("ProtokolBilesenF", "ProtokolBilesenId", Guid.create().toString());
						KuikaAppManager.addToPageInputVariables("ProtokolBilesenF", "ProtokolId", this.props.screenInputs.ProtokolId ?? this.props.screenInputs.protokolid);

				stateVars.NAVIGATE = await ReactSystemFunctions.navigate(this, undefined, "ProtokolBilesenList", "ProtokolBilesenF", "", "7d969ddf-5801-4eb6-b92b-12be35bdd565", "486227", null, "right", null, "600px", "vh", true);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }


		ProtokolBilesenListComponent_866792_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
KuikaAppManager.showSpinner(this);

					KuikaAppManager.prepareForNavigation();
						KuikaAppManager.addToPageInputVariables("ProtokolAltBilesenler", "ProtokolId", this.props.screenInputs.ProtokolId ?? this.props.screenInputs.protokolid);
						KuikaAppManager.addToPageInputVariables("ProtokolAltBilesenler", "ProtokolBilesenId", ReactSystemFunctions.value(this, "protokolbilesenlist_399769_value", "protokolBilesenId"));

				stateVars.NAVIGATE = await ReactSystemFunctions.navigate(this, undefined, "ProtokolBilesenList", "ProtokolAltBilesenler", "", "7d969ddf-5801-4eb6-b92b-12be35bdd565", "136112", null, "right", null, "600px", "vh", true);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }


		ProtokolBilesenListComponent_860665_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
KuikaAppManager.showSpinner(this);

					KuikaAppManager.prepareForNavigation();
						KuikaAppManager.addToPageInputVariables("ProtokolBilesenF", "ProtokolBilesenId", ReactSystemFunctions.value(this, "protokolbilesenlist_399769_value", "protokolBilesenId"));
						KuikaAppManager.addToPageInputVariables("ProtokolBilesenF", "ProtokolId", this.props.screenInputs.ProtokolId ?? this.props.screenInputs.protokolid);

				stateVars.NAVIGATE = await ReactSystemFunctions.navigate(this, undefined, "ProtokolBilesenList", "ProtokolBilesenF", "", "7d969ddf-5801-4eb6-b92b-12be35bdd565", "256479", null, "right", null, "600px", "", true);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }


    ProtokolBilesenListComponent_505853_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				ProtokolBilesenId_0: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
				ProtokolBilesenId_0_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, "protokolbilesenlist_399769_value", "protokolBilesenId"), "Guid")
        }

		KuikaAppManager.showSpinner(this);
if (ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, "protokolbilesenlist_399769_value", "altBilesenAdetleri"), "0")) {
            
            stateVars.Confirm = await Swal.fire({
                title: ReactSystemFunctions.translateCustomActions(this.ml,"c897b878_ffca_a808_bc1e_ef019e44188c_confirmation",this.defaultML,"Bileşen silinecektir onaylıyor musunuz?" ),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: localStorage.getItem("ml") === 'tr_TR' ? 'Onayla' : localStorage.getItem("ml") === 'fr_FR' ? 'Confirmer' : 'Confirm',
                cancelButtonText: localStorage.getItem("ml") === 'tr_TR' ? 'Vazgeç' : localStorage.getItem("ml") === 'fr_FR' ? 'Annuler' : 'Cancel',
                customClass: {
                  title: 'swal2-font-poppins',
                }
            })

            if (stateVars.Confirm.isConfirmed)
            {
                		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "ProtokolBilesenList/ProtokolBilesenListComponent_505853_onClick", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.SaveRecord = result?.data.saveRecord_1;
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.ProtokolBilesenListComponent_505853_onClick1_();
                  resolve();
                });
          })

            }
        } else {
            
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.ProtokolBilesenListComponent_505853_onClick1_();
                  resolve();
                });
          })
        }


        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
    ProtokolBilesenListComponent_505853_onClick1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				Id_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, "protokolbilesenlist_399769_value", "protokolBilesenId"), "Guid")
        }

if (ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, "protokolbilesenlist_399769_value", "altBilesenAdetleri"), "0")) {
            		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "ProtokolBilesenList/ProtokolBilesenListComponent_505853_onClick1_", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.DeleteRecord = result?.data.deleteRecord_1;
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.ProtokolBilesenListComponent_505853_onClick2_();
                  resolve();
                });
          })

        } else {
            
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.ProtokolBilesenListComponent_505853_onClick2_();
                  resolve();
                });
          })
        }


        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
		ProtokolBilesenListComponent_505853_onClick2_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
if (ReactSystemFunctions.isNotEqualTo(ReactSystemFunctions.value(this, "protokolbilesenlist_399769_value", "altBilesenAdetleri"), "0")) {
            
				stateVars.Notify = await ReactSystemFunctions.notify(this, ReactSystemFunctions.translateCustomActions(this.ml,"c714ad81_d8f4_bff9_bfc3_eeae98745aba_notify",this.defaultML,"Alt bileşenleri kaldırmadan bileşen silinemez!" ), "danger", "bottom-right", 5, null, null, null);

		this.props.form.setFieldsValue(formVars);
		
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.ProtokolBilesenListComponent_505853_onClick3_();
                  resolve();
                });
          })

        } else {
            
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.ProtokolBilesenListComponent_505853_onClick3_();
                  resolve();
                });
          })
        }

            
            return isErrorOccurred;
        }
    ProtokolBilesenListComponent_505853_onClick3_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				protokolId_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.ProtokolId ?? this.props.screenInputs.protokolid, "Guid")
        }

		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "ProtokolBilesenList/ProtokolBilesenListComponent_505853_onClick3_", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.ProtokolBilesenleri = result?.data.protokolBilesenleri;
			
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.ProtokolBilesenListComponent_505853_onClick4_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
		ProtokolBilesenListComponent_505853_onClick4_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }

				
				stateVars.dataSource_399769 = this.state.ProtokolBilesenleri;

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }











    async callCallbackFunction(diId) {
        let isErrorOccurred = false;
		let pageInitCallerNavDiIDs = [486227, 136112, 256479] as number[];
		if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
			await this.ProtokolBilesenListPageInit();
		}

    }
}
