import withCommonEvents from "../../../shared/hoc/with-common-events";
import { BaseTable, ITableProps } from "./base-table";
import { CarouselBodyWrapperProps } from "./body/carousel/carousel-body-wrapper";
import { CarouselItemProps } from "./body/carousel/carousel-item";

class Carousel extends BaseTable<CarouselItemProps & CarouselBodyWrapperProps, any> {
  constructor(props: ITableProps & CarouselItemProps & CarouselBodyWrapperProps) {
    super(props);
  }

  public static defaultProps = {
    mode: "carousel",
    showHeader: false,
    designClicked: false
  };
}

const carousel = withCommonEvents(Carousel);
export { carousel as Carousel };
