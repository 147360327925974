import { Tooltip } from "antd/es";
import _ from "lodash";
import { PureComponent, ReactNode } from "react";
import { CommonProps } from "../../../components/web-components/common/common-props";
import withCommonEvents from "../../../shared/hoc/with-common-events";

interface IKIconProps {
  iconName: string;
  style: any;
  hoverText?: string;
  rounded?: boolean;
}

interface IKIconState {}

class KIcon extends PureComponent<IKIconProps & CommonProps, IKIconState> {
  constructor(props: IKIconProps) {
    super(props);
    this.state = {};
  }

  getProps = () => {
    let result: any = {};
    if (this.props && this.props.style) {
      result = _.clone(this.props);
      if (result && result.style) {
        delete result.style;
      }
    }
    return result;
  };

  getStyleProp = () => {
    let style: any = {};

    if (this.props.style) {
      style = _.clone(this.props.style);
      if (this.props.style?.fontFamily) {
        delete style.fontFamily;
      }
    }
    if (this.props.showCursorPointer) {
      style.cursor = "pointer";
    }
    style.verticalAlign = "middle";
    return style;
  };

  render(): ReactNode {
    if (this.props.hoverText) {
      return (
        <>
          <Tooltip title={this.props.hoverText}>
            <i style={this.getStyleProp()} {...this.getProps()} className="material-icons md-48">
              {this.props.iconName}
            </i>
          </Tooltip>
        </>
      );
    }
    if (this.props.rounded) {
      <i style={this.getStyleProp()} {...this.getProps()} className="material-icons-round md-48">
        {this.props.iconName}
      </i>;
    }
    return (
      <>
        <i style={this.getStyleProp()} {...this.getProps()} className="material-icons md-48">
          {this.props.iconName}
        </i>
      </>
    );
  }
}

const kIcon = withCommonEvents(KIcon);
export { kIcon as KIcon };
