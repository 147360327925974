import { Popover, Space } from "antd/es";
import { KComponentFunctions } from "../../../../../shared/utilty/component-functions";
import { Icon } from "../../../icon/icon";
import { TableRow, TableRowProps, TableRowState } from "../table/table-row";

declare let window: any;
export interface IMarker {
  label?: string;
  img?: string;
  icon?: string;
  lat: number;
  lng: number;
}

export interface ICoords {
  latitude: number;
  longitude: number;
  accuracy?: number;
}

export interface GoogleMapsItemProps extends TableRowProps {
  maps: any;
  map: any;
  value?: ICoords;
  lat: number;
  lng: number;
  marker: IMarker;
  rowPopoverRenderer?: any;
  designClicked?: boolean;
  onClickDesign?: (value: boolean) => void;
  openDrawer: (content: any | undefined) => void;
  markerDetailView?: MarkerDetailView;
  selectedLocationColor?: string;
  selectedLocationIcon?: string;
}

export enum MarkerDetailView {
  Popup = 1,
  SidePanel = 2,
  Drawer = 3
}

export enum MarkerDetailPosition {
  Bottom = 1,
  Top = 2,
  Right = 3,
  Left = 4
}

interface GoogleMapsItemState extends TableRowState {}

export class GoogleMapsItem extends TableRow<GoogleMapsItemProps, GoogleMapsItemState> {
  constructor(props: GoogleMapsItemProps) {
    super(props);
    this.state = { mouseOver: false };
  }

  public static defaultProps = {
    designClicked: true
  };

  handleRowClick = (e: any) => {
    if (
      this.props.rowComponent &&
      this.props.rowComponent.props.onClick &&
      this.props.rowIndex !== undefined &&
      this.props.rowData
    ) {
      if (KComponentFunctions.isButtonElement(e.target) === true) {
        return;
      }
      if (KComponentFunctions.isImgElement(e.target) === true) {
        return;
      }
      this.props.rowComponent.props.onClick(e, this.props.rowIndex, this.props.rowData);
    }
  };

  renderItem = () => {
    let markerElement = this.props.children;
    const { value, lat, lng, selectedLocationColor, selectedLocationIcon } = this.props;
    if (!!value && !!selectedLocationColor && !!selectedLocationIcon) {
      if (value.latitude === lat && value.longitude === lng) {
        markerElement = <Icon iconName={selectedLocationIcon} style={{ color: selectedLocationColor }} />;
      }
    }
    return (
      <Space>
        <div
          {...this.props}
          style={{ cursor: "pointer" }}
          onClick={this.handleRowClick}
          onMouseOver={this.handleMouseOver}
          onMouseOut={this.handleMouseOut}
        >
          {markerElement}
        </div>
      </Space>
    );
  };

  handleClickChange = () => {
    let toogle = false;
    if (this?.props?.designClicked !== undefined) {
      toogle = !this.props.designClicked;
      if (this.props.onClickDesign) {
        this.props.onClickDesign(toogle);
      }
    }
  };

  componentDidMount = () => {
    const content = this.getContent();

    const isDesignTime = window.kuika?.isDesignTime;
    if (isDesignTime === true) {
      this.props.openDrawer(content);
    }
  };

  getContent(): JSX.Element | undefined {
    if (this.props?.rowPopoverRenderer === undefined) return undefined;

    return <div>{this.props.rowPopoverRenderer(this.props.value, this.props.rowData, this.props.rowIndex)}</div>;
  }

  render() {
    const isDesignTime = window.kuika?.isDesignTime;
    const content = this.getContent();
    return (
      <div>
        {isDesignTime === true || this.props.markerDetailView === MarkerDetailView.Popup ? (
          <div>
            {isDesignTime === true ? ( // Render Popover Mock Layout with Content on Design Time
              <div>
                <Popover
                  placement="topLeft"
                  content="You can right click here to hide or show the marker popup."
                  arrowPointAtCenter
                >
                  <div onContextMenu={this.handleClickChange}>{this.renderItem()}</div>
                </Popover>
                <div
                  style={{
                    backgroundColor: "#fff",
                    padding: "12px 16px",
                    transform: "translateY(16px)",
                    position: "relative",
                    boxShadow:
                      "0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%)",
                    borderRadius: 2
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      top: "1.51471863px",
                      left: 16,
                      width: 6,
                      height: 6,
                      backgroundColor: "#fff",
                      transform: "translateY(-4.24264069px) rotate(45deg)",
                      boxShadow: "-2px -2px 5px rgb(0 0 0 / 6%)"
                    }}
                  ></div>
                  {content}
                </div>
              </div>
            ) : content !== undefined && content?.props?.children?.props?.children?.props?.children !== undefined ? (
              <Popover content={content} title="" trigger="click">
                {this.renderItem()}
              </Popover>
            ) : (
              this.renderItem()
            )}
          </div>
        ) : (
          <div>
            {isDesignTime === true ? (
              <Popover
                placement="topLeft"
                content="You can right click here to hide or show the marker popup."
                arrowPointAtCenter
              >
                <div
                  onContextMenu={this.handleClickChange}
                  onClickCapture={() => {
                    this.props.openDrawer(content);
                  }}
                >
                  {this.renderItem()}
                </div>
              </Popover>
            ) : content !== undefined && content?.props?.children?.props?.children?.props?.children !== undefined ? (
              <div
                onClick={() => {
                  this.props.openDrawer(content);
                }}
              >
                {this.renderItem()}
              </div>
            ) : (
              this.renderItem()
            )}
          </div>
        )}
      </div>
    );
  }
}
