import { Tooltip } from "antd/es";
import _ from "lodash";
import { PureComponent, ReactNode } from "react";
import withCommonEvents from "../../../shared/hoc/with-common-events";
import { CommonProps } from "../common-props";
import { DashboardState } from "../kuika-cl-model-runtimes";

declare let window: any;

interface IconProps {
  iconName: string;
  style: any;
  hoverText?: string;
  rounded?: boolean;
}

interface IconState {}

class Icon extends PureComponent<IconProps & CommonProps, IconState> {
  constructor(props: IconProps) {
    super(props);
    this.state = {};
  }

  getProps = () => {
    let result: any = {};
    if (this.props && this.props.style) {
      result = _.clone(this.props);
      if (result && result.style) {
        delete result.style;
      }
    }
    return result;
  };

  getStyleProp = () => {
    let style: any = {};

    if (this.props.style) {
      style = _.clone(this.props.style);
      if (this.props.style?.fontFamily) {
        delete style.fontFamily;
      }
    }
    if (this.props.showCursorPointer) {
      style.cursor = "pointer";
    }
    if (style.display !== "block") {
      style.display = "inline-block";
    }
    style.verticalAlign = "middle";

    if (window?.kuika?.dashboardState === DashboardState.reportDesigner) {
      style.height = "100%";
    }
    return style;
  };

  render(): ReactNode {
    if (this.props.hoverText) {
      return (
        <>
          <Tooltip color={"white"} title={this.props.hoverText}>
            <i style={this.getStyleProp()} {...this.getProps()} className="material-icons md-48">
              {this.props.iconName}
            </i>
          </Tooltip>
        </>
      );
    }
    if (this.props.rounded) {
      <i style={this.getStyleProp()} {...this.getProps()} className="material-icons-round md-48">
        {this.props.iconName}
      </i>;
    }
    return (
      <>
        <i style={this.getStyleProp()} {...this.getProps()} className="material-icons md-48">
          {this.props.iconName}
        </i>
      </>
    );
  }
}

const icon = withCommonEvents(Icon);
export { icon as Icon };
