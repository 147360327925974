import React from "react";
import Axios, { AxiosResponse } from "axios";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { KNavigator } from "../../shared/hoc/with-history";
import { KContext } from "../../shared/hoc/with-context";
import { useLocation } from "react-router-dom";
import { FormInstance } from "antd/es/form";
import { Guid } from "guid-typescript";
import Swal from "sweetalert2";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import moment from "moment";
import { IValidationData } from "../../models/dto/validation-data";
import { IStripePayment } from "../../models/dto/stripe-payment";
import { IUserInfoDto } from "../../models/dto/user-info.dto";
import { UserService } from "../../services/user-service";
import _ from "lodash";
import mqtt, { MqttClient } from "mqtt";
import { IMqttResult } from "../../models/dto/mqtt.dto";

declare let window: any;

export interface IMesaiSaatiForm2_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

export interface IMesaiSaatiForm2_ScreenState {
    isSpinnerVisible: boolean;
    isPageVisible: boolean;
	isLeftMenuVisible: boolean;
    spinnerCount: number;
    selectedLanguage: string;
    isPageInitLoaded: false;
    isPageRecurringLoaded: false;
	GoBack: any;
	GoBack_dummy: any;
	MesaiSaatById: any[];
	MesaiSaatById_dummy: any[];
	SaveRecord: number;
	SaveRecord_dummy: number;


}

export class MesaiSaatiForm2_ScreenBase extends React.PureComponent<IMesaiSaatiForm2_ScreenProps, any> {
	mesaisaatiform2_30879_value_kuikaSelectBoxRef: React.RefObject<any>;
	mesaisaatiform2_805834_value_kuikaDatetimeRef: React.RefObject<any>;
	mesaisaatiform2_53768_value_kuikaDatetimeRef: React.RefObject<any>;
    ml=[{"Id":"423ed54a-d6f3-fccb-7d3e-72c7b24c8b7a","Name":"tr_TR","ShortName":"Turkish","IsDefault":true,"LanguagePhrases":[]},{"Id":"74503767-9450-4cae-9db5-729d038bcb36","Name":"en_US","ShortName":"English","LanguagePhrases":[]},{"Id":"39c3da9a-c1f0-4341-bc77-b2789d9ef94a","Name":"FixedValues","ShortName":"FixedValues","LanguagePhrases":[{"Id":274050,"PropertyName":"value","Value":"Mesai Saati"},{"Id":577659,"PropertyName":"value","Value":"Gün"},{"Id":30879,"PropertyName":"placeholder","Value":"Seçiniz..."},{"Id":604877,"PropertyName":"value","Value":"Başlangıç"},{"Id":805834,"PropertyName":"placeholder","Value":"08:00"},{"Id":479381,"PropertyName":"value","Value":"Bitiş"},{"Id":53768,"PropertyName":"placeholder","Value":"23:00"},{"Id":633468,"PropertyName":"label","Value":"Kaydet"}]}]
    defaultML = "tr_TR"
    
    screenHash = ""
    
    constructor(props) {
        super(props);
		this.mesaisaatiform2_30879_value_kuikaSelectBoxRef = React.createRef();
		this.mesaisaatiform2_805834_value_kuikaDatetimeRef = React.createRef();
		this.mesaisaatiform2_53768_value_kuikaDatetimeRef = React.createRef();

        this.state = {
            isSpinnerVisible: false,
            isPageVisible: false,
            isLeftMenuVisible: false,
            spinnerCount: 0,
            selectedLanguage: "",
            isPageInitLoaded: false,
            isPageRecurringLoaded: false,
            	        GoBack: "",
	        MesaiSaatById: [],
	        SaveRecord: false,
	        dataSource_30879: [{"key":"D1","text":"Pazartesi"},{"key":"D2","text":"Salı"},{"key":"D3","text":"Çarşamba"},{"key":"D4","text":"Perşembe"},{"key":"D5","text":"Cuma"},{"key":"D6","text":"Cumartesi"},{"key":"D7","text":"Pazar"}],

            
        }

        this.state.isPageVisible = true;
        
    }

    async componentDidMount() {

        if (KuikaAppManager.isPageInitPrevented()) {
            KuikaAppManager.calculateAndSetBodyHeight("mesaisaatiform2", "");
            return;
        }
        
        ReactSystemFunctions.validateToken()
        if (true){
            await this.MesaiSaatiForm2PageInit();
        }
        
        
        
        KuikaAppManager.calculateAndSetBodyHeight("mesaisaatiform2", "");
    }

    componentWillUnmount() {
        
        
        
    }

    

        
    componentDidUpdate= async (prevProps, prevState) => {
		KuikaAppManager.calculateAndSetBodyHeight("mesaisaatiform2", ""    );
        if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
			await this.MesaiSaatiForm2PageInit();
		}

        
	}

    fillFormInitValues() {
        this.props.form.setFieldsValue({
mesaisaatiform2_30879_value: this.state.MesaiSaatById?.at?.(0)?.gun ?? undefined,
mesaisaatiform2_805834_value: this.state.MesaiSaatById?.at?.(0)?.baslangic ?? undefined,
mesaisaatiform2_53768_value: this.state.MesaiSaatById?.at?.(0)?.bitis ?? undefined
});

    }

      setPageInitLoaded = (value: boolean) => {
        this.setState({ isPageInitLoaded: value });
      }

      setPageRecurringLoaded = (value: boolean) => {
        this.setState({ isPageRecurringLoaded: value });
      }

      clearFieldValidation = (changedValues: any) => {
        const fieldName = Object.keys(changedValues)[0];
        if (fieldName) {
          this.props.form.setFields([
            {
              name: fieldName,
              errors: []
            }
          ]);
        }
      };

      

      

    MesaiSaatiForm2PageInit = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }

        let localVar = {
			Id_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.MesaiSaatId ?? this.props.screenInputs.mesaisaatid, "Guid")
        }

	KuikaAppManager.showSpinner(this);
	
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "MesaiSaatiForm2/MesaiSaatiForm2PageInit", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

		stateVars.MesaiSaatById = result?.data.mesaiSaatById;
		formVars.mesaisaatiform2_30879_value = (stateVars.MesaiSaatById?.length > 0 ? stateVars.MesaiSaatById[0].gun : this.state.MesaiSaatById?.length > 0 ? this.state.MesaiSaatById[0].gun : null);
		formVars.mesaisaatiform2_805834_value = ReactSystemFunctions.value(this, (stateVars.MesaiSaatById?.length > 0 ? stateVars.MesaiSaatById[0].baslangic : this.state.MesaiSaatById?.length > 0 ? this.state.MesaiSaatById[0].baslangic : null));
		formVars.mesaisaatiform2_53768_value = ReactSystemFunctions.value(this, (stateVars.MesaiSaatById?.length > 0 ? stateVars.MesaiSaatById[0].bitis : this.state.MesaiSaatById?.length > 0 ? this.state.MesaiSaatById[0].bitis : null));
		this.props.form.setFieldsValue(formVars);
		
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.MesaiSaatiForm2PageInit1_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
	MesaiSaatiForm2PageInit1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }

			formVars.mesaisaatiform2_30879_value = ReactSystemFunctions.toString(this, (stateVars.MesaiSaatById?.length > 0 ? stateVars.MesaiSaatById[0].gun : this.state.MesaiSaatById?.length > 0 ? this.state.MesaiSaatById[0].gun : null));

			formVars.mesaisaatiform2_805834_value = ReactSystemFunctions.value(this, (stateVars.MesaiSaatById?.length > 0 ? stateVars.MesaiSaatById[0].baslangic : this.state.MesaiSaatById?.length > 0 ? this.state.MesaiSaatById[0].baslangic : null));

			formVars.mesaisaatiform2_53768_value = ReactSystemFunctions.value(this, (stateVars.MesaiSaatById?.length > 0 ? stateVars.MesaiSaatById[0].bitis : this.state.MesaiSaatById?.length > 0 ? this.state.MesaiSaatById[0].bitis : null));


	this.props.form.setFieldsValue(formVars);
	this.setState(stateVars);
	KuikaAppManager.hideSpinner(this);

            this.setPageInitLoaded(true);
            return isErrorOccurred;
        }




		MesaiSaatiForm2Component_858937_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
KuikaAppManager.showSpinner(this);

				stateVars.GoBack = await ReactSystemFunctions.goBack(this);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }


    MesaiSaatiForm2Component_633468_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;

			validations.push({ 
				isValid: ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "mesaisaatiform2_53768_value", "value", "", "", "")), null),
				message: "*",
				formName: "mesaisaatiform2_53768_value",
				condition: true
			} as IValidationData);

validations.forEach((validation) => {
            if (validation.message === "Geçerli bir mail adresi giriniz.") {
                validation.message = ReactSystemFunctions.getEmailValidationMessage()
            }
        })
			validations.push({ 
				isValid: ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "mesaisaatiform2_805834_value", "value", "", "", "")), null),
				message: "*",
				formName: "mesaisaatiform2_805834_value",
				condition: true
			} as IValidationData);

validations.forEach((validation) => {
            if (validation.message === "Geçerli bir mail adresi giriniz.") {
                validation.message = ReactSystemFunctions.getEmailValidationMessage()
            }
        })

		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				Id_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.MesaiSaatId ?? this.props.screenInputs.mesaisaatid, "Guid"),
				Gun_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "mesaisaatiform2_30879_value", "value", "", "key", "")), "string"),
				Bitis_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "mesaisaatiform2_53768_value", "value", "", "", "")), "Date"),
				Baslangic_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "mesaisaatiform2_805834_value", "value", "", "", "")), "Date"),
				ProfileId_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.ProfileId ?? this.props.screenInputs.profileid, "Guid")
        }

		KuikaAppManager.showSpinner(this);
		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "MesaiSaatiForm2/MesaiSaatiForm2Component_633468_onClick", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.SaveRecord = result?.data.saveRecord_1;
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.MesaiSaatiForm2Component_633468_onClick1_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
		MesaiSaatiForm2Component_633468_onClick1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }

				stateVars.GoBack = await ReactSystemFunctions.goBack(this);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }






    async callCallbackFunction(diId) {
        let isErrorOccurred = false;

    }
}
