import React from "react";
import Axios, { AxiosResponse } from "axios";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { KNavigator } from "../../shared/hoc/with-history";
import { KContext } from "../../shared/hoc/with-context";
import { useLocation } from "react-router-dom";
import { FormInstance } from "antd/es/form";
import { Guid } from "guid-typescript";
import Swal from "sweetalert2";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import moment from "moment";
import { IValidationData } from "../../models/dto/validation-data";
import { IStripePayment } from "../../models/dto/stripe-payment";
import { IUserInfoDto } from "../../models/dto/user-info.dto";
import { UserService } from "../../services/user-service";
import _ from "lodash";
import mqtt, { MqttClient } from "mqtt";
import { IMqttResult } from "../../models/dto/mqtt.dto";

declare let window: any;

export interface IComposant_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

export interface IComposant_ScreenState {
    isSpinnerVisible: boolean;
    isPageVisible: boolean;
	isLeftMenuVisible: boolean;
    spinnerCount: number;
    selectedLanguage: string;
    isPageInitLoaded: false;
    isPageRecurringLoaded: false;
	GoBack: any;
	GoBack_dummy: any;
	SiniflandirmaSelectAll: any[];
	SiniflandirmaSelectAll_dummy: any[];
	BilesenSelectById: any[];
	BilesenSelectById_dummy: any[];
	SaveRecord: number;
	SaveRecord_dummy: number;


}

export class Composant_ScreenBase extends React.PureComponent<IComposant_ScreenProps, any> {
	composant_940154_value_kuikaSelectBoxRef: React.RefObject<any>;
	composant_931622_value_kuikaSelectBoxRef: React.RefObject<any>;
    ml=[{"Id":"74503767-9450-4cae-9db5-729d038bcb36","Name":"en_US","ShortName":"English","LanguagePhrases":[{"Id":796540,"PropertyName":"value","Value":"Fitness Component","TagName":"lblHead_value"},{"Id":6210065,"PropertyName":"value","Value":"Component description","TagName":"lblTanim_value"},{"Id":3517934,"PropertyName":"placeholder","Value":"Type here...","TagName":"txtTanim_placeholder"},{"Id":994507,"PropertyName":"value","Value":"Classification","TagName":"Label1_0_value"},{"Id":940154,"PropertyName":"placeholder","Value":"Choose...","TagName":"selSiniflandirma_placeholder"},{"Id":326167,"PropertyName":"value","Value":"Prescription Component","TagName":"Label1_0_value"},{"Id":931622,"PropertyName":"placeholder","Value":"Choose...","TagName":"selReceteBileseni_placeholder"},{"Id":9544,"PropertyName":"label","Value":"SAVE","TagName":"btnSave_label"}]},{"Id":"423ed54a-d6f3-fccb-7d3e-72c7b24c8b7a","Name":"tr_TR","ShortName":"Turkish","LanguagePhrases":[]},{"Id":"e18be637-98a8-41cb-906b-9b458b76824f","Name":"FixedValues","ShortName":"FixedValues","LanguagePhrases":[{"Id":796540,"PropertyName":"value","Value":"Fitness Bileşeni"},{"Id":6210065,"PropertyName":"value","Value":"Bileşen Tanımı"},{"Id":3517934,"PropertyName":"placeholder","Value":"Type here..."},{"Id":994507,"PropertyName":"value","Value":"Sınıflandırma"},{"Id":940154,"PropertyName":"placeholder","Value":"Seçiniz..."},{"Id":326167,"PropertyName":"value","Value":"Reçete Bileşeni"},{"Id":931622,"PropertyName":"placeholder","Value":"Seçiniz..."},{"Id":9544,"PropertyName":"label","Value":"KAYDET"}]}]
    defaultML = "tr_TR"
    
    screenHash = ""
    
    constructor(props) {
        super(props);
		this.composant_940154_value_kuikaSelectBoxRef = React.createRef();
		this.composant_931622_value_kuikaSelectBoxRef = React.createRef();

        this.state = {
            isSpinnerVisible: false,
            isPageVisible: false,
            isLeftMenuVisible: false,
            spinnerCount: 0,
            selectedLanguage: "",
            isPageInitLoaded: false,
            isPageRecurringLoaded: false,
            	        GoBack: "",
	        SiniflandirmaSelectAll: [],
	        BilesenSelectById: [],
	        SaveRecord: false,
	        dataSource_931622: [{"key":"Kuvvet","text":"Kuvvet"},{"key":"KalpGucu","text":"Kalp Gücü"},{"key":"Esneklik","text":"Esneklik"}],

            
        }

        this.state.isPageVisible = true;
        
    }

    async componentDidMount() {

        if (KuikaAppManager.isPageInitPrevented()) {
            KuikaAppManager.calculateAndSetBodyHeight("composant", "");
            return;
        }
        
        ReactSystemFunctions.validateToken()
        if (true){
            await this.ComposantPageInit();
        }
        
        
        
        KuikaAppManager.calculateAndSetBodyHeight("composant", "");
    }

    componentWillUnmount() {
        
        
        
    }

    

        
    componentDidUpdate= async (prevProps, prevState) => {
		KuikaAppManager.calculateAndSetBodyHeight("composant", ""    );
        if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
			await this.ComposantPageInit();
		}

        
	}

    fillFormInitValues() {
        this.props.form.setFieldsValue({
composant_3517934_value: this.state.BilesenSelectById?.at?.(0)?.tanim ?? undefined,
composant_940154_value: this.state.BilesenSelectById?.at?.(0)?.siniflandirmaId ?? undefined,
composant_931622_value: this.state.BilesenSelectById?.at?.(0)?.receteBileseni ?? undefined
});

    }

      setPageInitLoaded = (value: boolean) => {
        this.setState({ isPageInitLoaded: value });
      }

      setPageRecurringLoaded = (value: boolean) => {
        this.setState({ isPageRecurringLoaded: value });
      }

      clearFieldValidation = (changedValues: any) => {
        const fieldName = Object.keys(changedValues)[0];
        if (fieldName) {
          this.props.form.setFields([
            {
              name: fieldName,
              errors: []
            }
          ]);
        }
      };

      

      

    ComposantPageInit = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }

        let localVar = {
			bilesenId_1: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.BilesenId ?? this.props.screenInputs.bilesenid, "Guid")
        }

	KuikaAppManager.showSpinner(this);
	
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "Composant/ComposantPageInit", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

		stateVars.SiniflandirmaSelectAll = result?.data.siniflandirmaSelectAll;
		
		formVars.composant_940154_value = (stateVars.BilesenSelectById?.length > 0 ? stateVars.BilesenSelectById[0].siniflandirmaId : this.state.BilesenSelectById?.length > 0 ? this.state.BilesenSelectById[0].siniflandirmaId : null);
		formVars.composant_940154_options = stateVars.SiniflandirmaSelectAll;
		stateVars.BilesenSelectById = result?.data.bilesenSelectById;
		formVars.composant_3517934_value = ReactSystemFunctions.toString(this, (stateVars.BilesenSelectById?.length > 0 ? stateVars.BilesenSelectById[0].tanim : this.state.BilesenSelectById?.length > 0 ? this.state.BilesenSelectById[0].tanim : null));
		formVars.composant_940154_value = (stateVars.BilesenSelectById?.length > 0 ? stateVars.BilesenSelectById[0].siniflandirmaId : this.state.BilesenSelectById?.length > 0 ? this.state.BilesenSelectById[0].siniflandirmaId : null);
		formVars.composant_940154_options = stateVars.SiniflandirmaSelectAll;
		formVars.composant_931622_value = (stateVars.BilesenSelectById?.length > 0 ? stateVars.BilesenSelectById[0].receteBileseni : this.state.BilesenSelectById?.length > 0 ? this.state.BilesenSelectById[0].receteBileseni : null);
		this.props.form.setFieldsValue(formVars);
		
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.ComposantPageInit1_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
	ComposantPageInit1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }

			formVars.composant_3517934_value = ReactSystemFunctions.toString(this, (stateVars.BilesenSelectById?.length > 0 ? stateVars.BilesenSelectById[0].tanim : this.state.BilesenSelectById?.length > 0 ? this.state.BilesenSelectById[0].tanim : null));

			formVars.composant_940154_value = ReactSystemFunctions.toString(this, (stateVars.BilesenSelectById?.length > 0 ? stateVars.BilesenSelectById[0].siniflandirmaId : this.state.BilesenSelectById?.length > 0 ? this.state.BilesenSelectById[0].siniflandirmaId : null));

			
			stateVars.dataSource_940154 = this.state.SiniflandirmaSelectAll;
			stateVars.dataSource_940154 = this.state.SiniflandirmaSelectAll;
			formVars.composant_931622_value = ReactSystemFunctions.toString(this, (stateVars.BilesenSelectById?.length > 0 ? stateVars.BilesenSelectById[0].receteBileseni : this.state.BilesenSelectById?.length > 0 ? this.state.BilesenSelectById[0].receteBileseni : null));


	this.props.form.setFieldsValue(formVars);
	this.setState(stateVars);
	KuikaAppManager.hideSpinner(this);

            this.setPageInitLoaded(true);
            return isErrorOccurred;
        }




		ComposantComponent_968274_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
KuikaAppManager.showSpinner(this);

				stateVars.GoBack = await ReactSystemFunctions.goBack(this);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }


    ComposantComponent_9544_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;

			validations.push({ 
				isValid: ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "composant_3517934_value", "value", "", "", "")), null),
				message: "*",
				formName: "composant_3517934_value",
				condition: true
			} as IValidationData);

validations.forEach((validation) => {
            if (validation.message === "Geçerli bir mail adresi giriniz.") {
                validation.message = ReactSystemFunctions.getEmailValidationMessage()
            }
        })
			validations.push({ 
				isValid: ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "composant_940154_value", "value", "SiniflandirmaSelectAll", "id", "")), null),
				message: "*",
				formName: "composant_940154_value",
				condition: true
			} as IValidationData);

validations.forEach((validation) => {
            if (validation.message === "Geçerli bir mail adresi giriniz.") {
                validation.message = ReactSystemFunctions.getEmailValidationMessage()
            }
        })
			validations.push({ 
				isValid: ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "composant_931622_value", "value", "", "key", "")), null),
				message: "*",
				formName: "composant_931622_value",
				condition: true
			} as IValidationData);

validations.forEach((validation) => {
            if (validation.message === "Geçerli bir mail adresi giriniz.") {
                validation.message = ReactSystemFunctions.getEmailValidationMessage()
            }
        })

		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				Id_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.BilesenId ?? this.props.screenInputs.bilesenid, "Guid"),
				Tanim_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "composant_3517934_value", "value", "", "", "")), "string"),
				SiniflandirmaId_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "composant_940154_value", "value", "SiniflandirmaSelectAll", "id", "id")), "Guid"),
				ReceteBileseni_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "composant_931622_value", "value", "", "key", "")), "string")
        }

		KuikaAppManager.showSpinner(this);
		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "Composant/ComposantComponent_9544_onClick", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.SaveRecord = result?.data.saveRecord_1;
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.ComposantComponent_9544_onClick1_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
		ComposantComponent_9544_onClick1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }

				stateVars.GoBack = await ReactSystemFunctions.goBack(this);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }






    async callCallbackFunction(diId) {
        let isErrorOccurred = false;

    }
}
