import "animate.css/animate.min.css";
// import "antd/dist/antd.min.css";
import { GoogleOAuthProvider } from "@react-oauth/google";
import Axios from "axios";
import React from "react";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { setInterval } from "worker-timers";
import KuikaAuthApp from "./app-types/kuika-auth-app";
import LocalStorageService from "./auth/local-storage-service";
import { IRouterType } from "./routes/router";
import { ApplicationService } from "./services/application-service";
import HelperService from "./services/helper-service";
import { UserService } from "./services/user-service";
import { KuikaAppManager } from "./shared/utilty/kuika-app-manager";
import "./styles/components/badge.scss";
import "./styles/components/divider.scss";
import "./styles/components/kanban-board.scss";
import "./styles/fonts.scss";
import "./styles/form.scss";
import "./styles/general.scss";
import "./styles/registration.scss";
// bootstrapper-imports-placeholder

export type IAuthType = "MSAL" | "Kuika";
export type IAppMode = "WebComponent" | "WebSite";

export interface IBootStrapperProps {
  authType: IAuthType;
  routerType?: IRouterType;
  tagName?: string;
  external_access_token?: string;
  external_refresh_token?: string;
  external_user?: string;
  appMode?: IAppMode;
}

declare let window: any;

export interface IBootstrapperState {
  // session-service-state-placeholder
  isMounted: boolean;
  remainingSessionTime: number;
}

export class AppBootstrapper extends React.Component<IBootStrapperProps, IBootstrapperState> {
  // session-service-declaration-placeholder

  constructor(props: IBootStrapperProps) {
    super(props);
    this.state = {
      isMounted: false,
      // Initial value is set to 99999999999 to prevent the session warning modal from showing up on the first render.
      remainingSessionTime: 99999999999
    };
  }

  getProps = () => {
    if (this.props == undefined) return "";
    return JSON.stringify(this.props);
  };

  webComponentAttached() {
    console.log(`attachedCallback:${this.getProps()}`);
  }

  webComponentConnected() {
    console.log(`connectedCallback:${this.getProps()}`);
  }

  webComponentDisconnected() {
    console.log(`disconnectedCallback:${this.getProps()}`);
  }

  webComponentAttributeChanged() {
    console.log(`attributeChangedCallback:${this.getProps()}`);
  }

  webComponentAdopted() {
    console.log(`adoptedCallback:${this.getProps()}`);
  }

  checkIsTokenExpired = async () => {
    if (!localStorage.getItem("tokenString") && !localStorage.getItem("refreshToken")) {
      return;
    }

    const isSessionValid: boolean = await UserService.GetIsTokenValid();

    if (isSessionValid) {
      return;
    }

    LocalStorageService.clearToken();
    HelperService.gotoLogin();
  };

  async componentDidMount(): Promise<void> {
    // CODE GENERATION FLAGS!!!
    const IS_SAVE_APPLICATION_ENABLED: boolean = true;
    const IS_EXPIRE_OLD_SESSIONS_ENABLED: boolean = false;

    if (this.props.appMode === "WebSite" && IS_SAVE_APPLICATION_ENABLED) {
      await this.handleRequestSaveApplication();
      await ApplicationService.saveAllApplicationsToSessionStorage();
      ApplicationService.injectPreConnectLinks();
    }

    this.setKuikaInfo();

    this.setState({ isMounted: true }, () => {
      localStorage.removeItem("isGoogleLoginInProgress");
      localStorage.removeItem("isGoogleLoginAborted");
    });

    // session-service-initialization-placeholder

    Axios.defaults.headers.ClientSecret = HelperService.getClientSecret();

    if (IS_EXPIRE_OLD_SESSIONS_ENABLED) {
      this.checkIsTokenExpired();
      setInterval(async () => {
        this.checkIsTokenExpired();
      }, 10000);
    }
  }

  componentWillUnmount() {
    // session-service-dispose-placeholder
  }

  setKuikaInfo() {
    if (this.props === undefined) {
      return;
    }
    if (!window.kuika) {
      window.kuika = {};
    }
    window.kuika.info = {};
    const appName = "scoreBoard";
    const workspace = "meMap2";
    const webComponentTag = "me-map2-score-board";
    const frontEndUrl = KuikaAppManager.GetFrontEndSourceUrl();
    const backEndUrl = KuikaAppManager.GetBackendUrl(true);
    const { authType } = this.props;
    const html = String.raw`
      <html>
        <head>
        <script src='%PUBLIC_URL%/assets/kuika-mfe_1_0_0.js'></script>
        <script>
        CreateKuikaMfeApp({frontEndUrl:'${frontEndUrl}'});
        </script>
        </head>
        <body>
          <me-map2-score-board></me-map2-score-board>
        </body>
      </html>`;
    window.kuika.info.workspace = workspace;
    window.kuika.info.applicationName = appName;
    window.kuika.info.webComponentTag = webComponentTag;
    window.kuika.info.frontEndUrl = frontEndUrl;
    window.kuika.info.backEndUrl = backEndUrl;
    window.kuika.info.authType = authType;
    window.kuika.html = html;
    window.kuika.getModule = () => {
      console.log(window.kuika.html);
    };
  }

  handleRequestSaveApplication = async () => {
    const SAVE_APP_REQUEST_KEY = "SAVE_APP_REQUESTED";

    const isRequestedBefore: string = sessionStorage.getItem(SAVE_APP_REQUEST_KEY);

    if (isRequestedBefore) {
      return;
    }

    try {
      await ApplicationService.saveApplication();
      sessionStorage.setItem(SAVE_APP_REQUEST_KEY, "true");
    } catch (e) {
      setTimeout(this.handleRequestSaveApplication.bind(this), 10000);
    }
  };

  // session-timeout-methods-placeholder

  updateRemainingSessionTime = (remainingSessionTime: number) => {
    this.setState({ remainingSessionTime });
  };

  public render() {
    return (
      <GoogleOAuthProvider clientId="948495596316-cssed34v3s9qs7nc44e5b4csdba16kum.apps.googleusercontent.com">
        <KuikaAuthApp
          authType={this.props.authType}
          routerType={this.props.routerType}
          appMode={this.props.appMode}
          external_user={this.props.external_user}
          external_access_token={this.props.external_access_token}
          external_refresh_token={this.props.external_refresh_token}
        />
        {/* session-service-modal-placeholder */}
      </GoogleOAuthProvider>
    );
  }
}

export default AppBootstrapper;
