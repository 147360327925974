import { Button } from "antd/es";
import * as React from "react";
import { UserService } from "../../services/user-service";
import { KNavigator, withHistory } from "../../shared/hoc/with-history";

export interface IDashboardState {}

export interface IDashboardProps {
  navigator: KNavigator;
}

class Dashboard extends React.PureComponent<IDashboardProps, IDashboardState> {
  render() {
    return (
      <>
        <div>Dashboard Screen</div>
        <Button onClick={() => UserService.Logout()}>Logout</Button>
      </>
    );
  }
}

const dashboard = withHistory(Dashboard);
export { dashboard as Dashboard };
