import { Drawer as AntDrawer } from "antd/es";
import { DrawerProps as AntdDrawerProps } from "antd/es/drawer";
import { PureComponent, ReactNode } from "react";
import withCommonEvents from "../../../shared/hoc/with-common-events";
import { CommonProps } from "../common/common-props";
import "./style.css";

interface DrawerProps extends AntdDrawerProps {
  width?: string;
  height?: string;
  placement: any;
}

interface DrawerState {}

class Drawer extends PureComponent<DrawerProps & CommonProps, DrawerState> {
  constructor(props: DrawerProps) {
    super(props);
    this.state = {};
  }

  render(): ReactNode {
    let height = this.props.placement === "left" || this.props.placement === "right" ? "100vh" : "30vh";
    if (this.props.height) {
      height = this.props.height;
    }
    return (
      <>
        <AntDrawer bodyStyle={{ height }} headerStyle={{ display: "none" }} {...this.props}></AntDrawer>
      </>
    );
  }
}

const drawer = withCommonEvents(Drawer);
export { drawer as Drawer };
