import _ from "lodash";
import React, { PureComponent, ReactNode } from "react";
import Timer from "react-compound-timerv2";
import withCommonEvents from "../../../shared/hoc/with-common-events";
import { CommonProps } from "../common-props";
import "./stopwatch.scss";

interface StopwatchProps {
  style?: React.CSSProperties;
  onStart?: () => void;
  onStop?: () => void;
  onPause?: () => void;
  onResume?: () => void;
}

interface StopwatchState {
  value: number;
}

class Stopwatch extends PureComponent<StopwatchProps & CommonProps, StopwatchState> {
  timerId: NodeJS.Timeout | null = null;

  constructor(props: StopwatchProps) {
    super(props);
    this.state = {
      value: 0
    };
  }

  startTimer = () => {
    if (this.timerId === null) {
      this.timerId = setInterval(() => {
        this.setState((prevState) => ({
          value: prevState.value + 1
        }));
      }, 1000);
    }
    if (this.state.value === 0 && this.props.onStart) {
      this.props.onStart();
    }
  };

  stopTimer = () => {
    if (this.timerId !== null) {
      clearInterval(this.timerId);
      this.timerId = null;
    }
  };

  onStart = () => {
    this.startTimer();
  };

  onReset = () => {
    this.setState({ value: 0 });
    this.stopTimer();
  };

  onPause = () => {
    this.stopTimer();
  };

  onResume = () => {
    this.startTimer();
  };

  getValue = () => {
    const { value } = this.state;
    return value;
  };

  getStyleProp = () => {
    let style: any = {};

    if (this.props.style) {
      style = _.clone(this.props.style);
    }
    style.display = "inline-block";

    return style;
  };

  getTextStyles = () => {
    const style = this.getStyleProp();
    const baseStyle: any = {
      fontSize: style.fontSize || 40,
      fontWeight: style.fontWeight || 400,
      fontFamily: style.fontFamily || "Helvetica"
    };

    if (style.lineHeight) {
      baseStyle.lineHeight = style.lineHeight;
    }

    if (style.letterSpacing) {
      baseStyle.letterSpacing = style.letterSpacing;
    }

    if (style.textDecoration) {
      baseStyle.textDecoration = style.textDecoration;
    }

    if (style.fontStyle) {
      baseStyle.fontStyle = style.fontStyle;
    }

    if (style.textTransform) {
      baseStyle.textTransform = style.textTransform;
    }

    return {
      hours: {
        ...baseStyle,
        fontSize: style.fontSize || 40,
        fontWeight: style.fontWeight || 400
      },
      minutes: {
        ...baseStyle,
        fontWeight: style.fontWeight || 400
      },
      seconds: {
        ...baseStyle,
        fontWeight: style.fontWeight || 300
      }
    };
  };

  render(): ReactNode {
    const style = this.getStyleProp();
    const textStyles = this.getTextStyles();
    return (
      <Timer
        initialTime={this.state.value}
        startImmediately={false}
        formatValue={(value) => `${value < 10 ? `0${value}` : value} `}
        onStart={this.onStart}
        onReset={this.onReset}
        onPause={this.onPause}
        onResume={this.onResume}
      >
        {({ resume, pause, reset }) => (
          <div style={style}>
            <div className="kuika__stopwatch__container">
              <div className="kuika__stopwatch">
                <span className="hours" style={textStyles.hours}>
                  <Timer.Hours />
                </span>
                <div>:</div>
                <span className="minutes" style={textStyles.minutes}>
                  <Timer.Minutes />
                </span>
                <div>:</div>
                <span className="seconds" style={textStyles.seconds}>
                  <Timer.Seconds />
                </span>
              </div>
              <div className="controls">
                <div
                  className="stop"
                  onClick={() => {
                    reset();
                    pause();
                    this.props.onStop && this.props.onStop();
                  }}
                >
                  <span style={{ backgroundColor: `#cc3300` }}></span>
                </div>
                {this.timerId === null ? (
                  <div className="playpause">
                    <span
                      className="play"
                      onClick={() => {
                        resume();
                        if (this.state.value > 0) this.props.onResume && this.props.onResume();
                      }}
                      style={{
                        borderLeft: `25px solid #339900`
                      }}
                    ></span>
                  </div>
                ) : (
                  <div className="playpause">
                    <span
                      className="pause"
                      onClick={() => {
                        pause();
                        this.props.onPause && this.props.onPause();
                      }}
                      style={{
                        borderRight: `5px solid #ffcc00`,
                        borderLeft: `5px solid #ffcc00`
                      }}
                    ></span>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </Timer>
    );
  }
}

const stopwatch = withCommonEvents(Stopwatch);
export { stopwatch as Stopwatch };
