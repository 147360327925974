import Axios, { AxiosResponse } from "axios";
import { IImportMappedExcelRequest, IImportMappedExcelResponse } from "../models/dto/excel";
import { KuikaAppManager } from "../shared/utilty/kuika-app-manager";

class ExcelService {
  private static readonly BACKEND_URL: string = KuikaAppManager.GetBackendUrl();

  public static async ImportMappedExcel(
    request: IImportMappedExcelRequest
  ): Promise<AxiosResponse<IImportMappedExcelResponse>> {
    return Axios.post<IImportMappedExcelRequest, AxiosResponse<IImportMappedExcelResponse>>(
      `${ExcelService.BACKEND_URL}/excel/import`,
      request
    );
  }
}

export default ExcelService;
