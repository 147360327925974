import { Chart as ChartJS, registerables } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import DoughnutLabel from "chartjs-plugin-doughnutlabel-v3";
import _ from "lodash";
import React, { ReactNode } from "react";
import { Doughnut } from "react-chartjs-2";
import withCommonEvents from "../../../../shared/hoc/with-common-events";
import { CommonProps } from "../../common-props";

declare let window: Window & { kuika: any };

ChartJS.register(...registerables);
ChartJS.register(DoughnutLabel);

export interface RadialChartProps {
  dataSource: any[];
  style?: React.CSSProperties;
  dataslicetextfield?: string;
  dataslicepointfield?: string;
  dataslicepercentagefield?: string;
  dataslicelabelfield?: string;
  datalevelpercentagefield?: string;
  datalevelcolorfield?: string;
  dataleveltextfield?: string;
  chartText?: string;
  options?: any[];
  levels?: any[];
  lineColor?: string;
  radialLineColor?: string;
  lineWidth?: number;
  backgroundColor?: string;
  chartTextColor?: string;
  children?: any;
}

export interface RadialChartState {}

class GradientDoughnut extends React.PureComponent<RadialChartProps & CommonProps, RadialChartState> {
  private static readonly slicesDashboardData = [
    {
      label: "Slice 1",
      text: "First Slice",
      percent: 40,
      point: 100
    },
    {
      label: "Slice 2",
      text: "Second Slice",
      percent: 25,
      point: 30
    },
    {
      label: "Slice 3",
      text: "Third Slice",
      percent: 15,
      point: 50
    },
    {
      label: "Slice 3",
      text: "Third Slice",
      percent: 15,
      point: 60
    },
    {
      label: "Slice 3",
      text: "Third Slice",
      percent: 15,
      point: 70
    },
    {
      label: "Slice 3",
      text: "Third Slice",
      percent: 15,
      point: 76
    }
  ];

  private static readonly levelsDashboardData = [
    { levelPercent: 10, color: "#A6341F", text: "Level 1" },
    { levelPercent: 20, color: "#BD4538", text: "Level 2" },
    { levelPercent: 30, color: "#D08346", text: "Level 3" },
    { levelPercent: 40, color: "#E4BF59", text: "Level 4" },
    { levelPercent: 50, color: "#FBF461", text: "Level 5" },
    { levelPercent: 60, color: "#EEFF94", text: "Level 6" },
    { levelPercent: 70, color: "#B8CD5F", text: "Level 7" },
    { levelPercent: 80, color: "#76AD5B", text: "Level 8" },
    { levelPercent: 90, color: "#488450", text: "Level 9" },
    { levelPercent: 100, color: "#33603C", text: "Level 10" }
  ];

  private canvasId: string = `radial-chart-${Math.floor(Math.random() * Math.floor(Math.random() * Date.now()))}`;

  componentDidMount(): void {
    this.setDynamicStyle();
    ChartJS.unregister(ChartDataLabels);
  }

  componentDidUpdate(): void {
    this.setDynamicStyle();
    ChartJS.unregister(ChartDataLabels);
  }

  getHeight(): string | undefined {
    if (this.props.style && this.props.style?.height) {
      return this.props.style.height.toString();
    }

    return undefined;
  }

  getMinHeight(): string | undefined {
    if (this.props.style && this.props.style?.minHeight) {
      return this.props.style.minHeight.toString();
    }
    return undefined;
  }

  getMaxHeight(): string | undefined {
    if (this.props.style && this.props.style?.maxHeight) {
      return this.props.style.maxHeight.toString();
    }

    return undefined;
  }

  getWidth(): string | undefined {
    if (this.props.style && this.props.style?.width) {
      return this.props.style.width.toString();
    }

    return undefined;
  }

  getMinWidth(): string | undefined {
    if (this.props.style && this.props.style?.minWidth) {
      return this.props.style.minWidth.toString();
    }

    return undefined;
  }

  getMaxWidth(): string | undefined {
    if (this.props.style && this.props.style?.maxWidth) {
      return this.props.style.maxWidth.toString();
    }

    return undefined;
  }

  getNumbersOfStyleProperties = (val: any) => {
    let numb: any = val?.match(/\d/g);
    numb = numb?.join("");
    return parseInt(numb);
  };

  getDynamicCss = (): string => {
    const className: string = this.canvasId;
    if (!className || className.length === 0 || window.kuika.isDesignTime) {
      return "";
    }
    let result = "";

    const { width, height } = this.props.style;
    const reelWidth = this.getNumbersOfStyleProperties(width) - 50;
    const reelHeight = this.getNumbersOfStyleProperties(height) - 50;
    result += `.${className} {
        width: ${reelWidth}px !important;
        height: ${reelHeight}px !important;
          min-width: ${reelWidth}px !important;
          left: ${width ? "200px" : 0} !important;
        }`;
    return result;
  };

  setDynamicStyle = () => {
    const dynamic_style = document.getElementById("dynamic_style");
    if (dynamic_style && dynamic_style.innerHTML?.indexOf(this.canvasId) === -1) {
      const generatedCss = this.getDynamicCss();
      dynamic_style.innerHTML = `${dynamic_style.innerHTML} 
        ${generatedCss}`;
    }
  };

  drawLines = {
    id: "drawLines",
    afterDatasetDraw: (chart) => {
      chart.getDatasetMeta(0).data.forEach((datapoint, i) => {
        chart.ctx.beginPath();

        const innerStartAngle = chart.getDatasetMeta(chart.data.datasets.length - 1).data[i].startAngle;
        var lineOuterRadius = datapoint.outerRadius;

        var targetX = datapoint.x + lineOuterRadius * Math.cos(datapoint.startAngle) * 100;
        var targetY = datapoint.y + lineOuterRadius * Math.sin(datapoint.startAngle) * 100;

        var startX =
          chart.getDatasetMeta(chart.data.datasets.length - 1).data[i].x +
          chart.getDatasetMeta(chart.data.datasets.length - 1).data[i].innerRadius * Math.cos(innerStartAngle);
        var startY =
          chart.getDatasetMeta(chart.data.datasets.length - 1).data[i].y +
          chart.getDatasetMeta(chart.data.datasets.length - 1).data[i].innerRadius * Math.sin(innerStartAngle);

        chart.ctx.font = "16px Arial";
        chart.ctx.strokeStyle = this.props.lineColor;
        chart.ctx.lineWidth = this.props.lineWidth ?? 2;
        chart.ctx.moveTo(startX, startY);
        chart.ctx.lineTo(targetX, targetY);
        chart.ctx.stroke();
      });
    }
  };

  constructor(props: RadialChartProps) {
    super(props);
    this.state = {};
  }

  getProps(): RadialChartProps {
    const props: RadialChartProps = { ...this.props };
    return props;
  }

  static lowercaseKeys(obj) {
    return Object.keys(obj).reduce((accumulator, key) => {
      accumulator[key.toLowerCase()] = obj[key];
      return accumulator;
    }, {});
  }

  private static convertLevelOptionsToLevelsData = (levels, perc, color, text) => {
    const isDesignTime = window.kuika?.isDesignTime;
    const _levels = _.cloneDeep(levels);
    const source = window.kuika?.isDesignTime ? GradientDoughnut.levelsDashboardData : _levels ?? [];
    const length = source?.length;
    const hasSourcesMapped = source?.find((item) => item.isMainLevel);
    if (isDesignTime) {
      if (hasSourcesMapped) return source;
      source?.forEach((item, index) => {
        item.isMainLevel = true;
      });
      source?.sort((a, b) => {
        return b.levelPercent - a.levelPercent;
      });
      source?.forEach((item, index) => {
        if (index === length - 1) return;
        if (index >= 0) {
          for (let i = 1; i <= 9; i++) {
            source.push({
              levelPercent:
                item.levelPercent + (source[index + 1].levelPercent - source[index].levelPercent) * (i / 10),
              color: item.color,
              text: item.text
            });
          }
        }
      });
      source?.sort((a, b) => {
        return b.levelPercent - a.levelPercent;
      });
      return source;
    }
    if (!hasSourcesMapped) {
      source?.sort((a, b) => {
        const optionLowercaseFirst = this.lowercaseKeys(a);
        const optionLowercaseSecond = this.lowercaseKeys(b);
        return optionLowercaseFirst[perc] - optionLowercaseSecond[perc];
      });
      source?.forEach((item, index) => {
        item.isMainLevel = true;
      });
      source?.forEach((item, index) => {
        const optionLowercase = this.lowercaseKeys(item);
        const levelPercentKey = optionLowercase[perc];
        const colorKey = optionLowercase[color];
        const textKey = optionLowercase[text];
        const nextItemOptionLowercase = this.lowercaseKeys(source[index + 1]);
        if (index === length - 1) return;
        if (index >= 0) {
          for (let i = 1; i <= 9; i++) {
            source.push({
              levelPercent: levelPercentKey + (nextItemOptionLowercase[perc] - levelPercentKey) * (i / 10),
              color: colorKey,
              text: textKey
            });
          }
        }
      });
      source?.sort((a, b) => {
        const optionLowercaseFirst = this.lowercaseKeys(a);
        const optionLowercaseSecond = this.lowercaseKeys(b);
        return optionLowercaseFirst[perc] - optionLowercaseSecond[perc];
      });
    }
    const items = source?.map((item) => {
      const optionLowercase = this.lowercaseKeys(item);
      return {
        levelPercent: optionLowercase[perc] ?? item.levelPercent,
        color: optionLowercase[color] ?? item.color,
        text: optionLowercase[text] ?? item.text,
        isMainLevel: item.isMainLevel
      };
    });
    items.filter((item) => {
      const optionLowercase = this.lowercaseKeys(item);
      if (optionLowercase[perc] != null && optionLowercase[color] != null && !isDesignTime) return true;
      return false;
    });
    return items;
  };

  getGradient = (chart, levels) => {
    const ctx = chart.ctx;
    const chartArea = chart.chartArea;
    if (
      !chartArea ||
      (chart.chartArea.left + chart.chartArea.right - chart.width * 0.12 * 2.5) / 2 < 0 ||
      (chart.chartArea.left + chart.chartArea.right - chart.width * 0.12 * 2.5) / 2 < 0
    ) {
      return null;
    }
    const gradient = ctx.createRadialGradient(
      (chart.chartArea.left + chart.chartArea.right) / 2,
      (chart.chartArea.top + chart.chartArea.bottom) / 2,
      (chart.width * 0.88 * 0.12) / 2,
      (chart.chartArea.left + chart.chartArea.right) / 2,
      (chart.chartArea.top + chart.chartArea.bottom) / 2,
      (chart.chartArea.left + chart.chartArea.right - chart.width * 0.11 * 2.5) / 2
    );
    if (gradient) {
      const maxLevelPercent = Math.max(...levels.map((level) => level.levelPercent));
      for (const level of levels) {
        if (
          level.color != null &&
          level.color != undefined &&
          level.color != "" &&
          level.isMainLevel &&
          !(level.levelPercent === 100 || level.levelPercent === 101)
        ) {
          gradient.addColorStop(level.levelPercent / maxLevelPercent, level.color);
        }
      }
    }
    return gradient;
  };

  private static convertOptionsToSlicesData = (options, label, text, percentage, point) => {
    const _options = _.cloneDeep(options);
    const source = window.kuika?.isDesignTime ? GradientDoughnut.slicesDashboardData : _options ?? [];
    if (window.kuika?.isDesignTime) return source;
    const items = source?.map((item) => {
      let optionLowercase = GradientDoughnut.lowercaseKeys(item);
      if (optionLowercase[percentage] != null && optionLowercase[percentage]) {
        return {
          label: optionLowercase[label],
          text: optionLowercase[text],
          percent: optionLowercase[percentage],
          point: optionLowercase[point] != null ? optionLowercase[point] : 0
        };
      }
    });
    return items.filter(function (item) {
      if (item) return true;
      return false;
    });
  };

  render(): ReactNode {
    const slices = GradientDoughnut.convertOptionsToSlicesData(
      this.props.options ?? [],
      this.props.dataslicelabelfield?.toLowerCase(),
      this.props.dataslicetextfield?.toLowerCase(),
      this.props.dataslicepercentagefield?.toLowerCase(),
      this.props.dataslicepointfield?.toLowerCase()
    );

    const levels = GradientDoughnut.convertLevelOptionsToLevelsData(
      this.props.levels ?? [],
      this.props.datalevelpercentagefield?.toLowerCase(),
      this.props.datalevelcolorfield?.toLowerCase(),
      this.props.dataleveltextfield?.toLowerCase()
    );

    const slicePercents = slices?.map((slice, index) => {
      if (slice) return slice.percent;
    });
    const mappedLevels = levels
      ?.filter(function (level) {
        // if (level.levelPercent > 100) {
        //   if (!slices.find((s) => s.point > 100)) return false;
        // }
        if (level.levelPercent != null && level.color != null) return true;

        return false;
      })
      .map((level, index) => {
        return {
          data: slicePercents,
          backgroundColor: (context) => {
            const chart = context.chart;
            const { chartArea } = chart;
            if (!chartArea) {
              return null;
            }
            const gradient = this.getGradient(chart, levels);
            if (slices) {
              return slices.map((slice: any, sIndex: any) => {
                if (levels.length - index <= slice.point) {
                  return gradient;
                }
                return this.props.backgroundColor ?? "#EBEBEB";
              });
            }
            return this.props.backgroundColor ?? "#EBEBEB";
          },
          borderWidth: (context) => {
            const chart = context.chart;
            const { chartArea } = chart;
            if (!chartArea) {
              return null;
            }
            const tenthLevelIndex = levels.findIndex((l) => l.levelPercent === 100);
            const totalLevelIndex = levels.length - 1;
            if (slices) {
              return slices.map((slice: any, sIndex: any) => {
                if (index === totalLevelIndex - tenthLevelIndex || index === 1) {
                  return 2;
                }
                return 1;
              });
            }
            return 1;
          },
          borderColor: (context) => {
            const chart = context.chart;
            const { chartArea } = chart;
            if (!chartArea) {
              return null;
            }
            const gradient = this.getGradient(chart, levels);
            const tenthLevelIndex = levels.findIndex((l) => l.levelPercent === 100);
            const totalLevelIndex = levels.length - 1;
            if (slices) {
              return slices.map((slice: any, sIndex: any) => {
                if (index === totalLevelIndex - tenthLevelIndex) {
                  return "#7f7f7f";
                }
                if (index < totalLevelIndex - tenthLevelIndex && level.isMainLevel) {
                  if (levels.length - index <= slice.point) return gradient;
                  return this.props.backgroundColor ?? "#FFF";
                }
                if (levels.length - index <= slice.point) {
                  return gradient;
                }
                if (index === 0) {
                  return this.props.backgroundColor ?? "#FFF";
                }
                if (level.isMainLevel) {
                  return this.props.radialLineColor ?? this.props.backgroundColor ?? "#FFF";
                }
                return this.props.backgroundColor ?? "#FFF";
              });
            }
            // if (level.levelPercent > 100) {
            //   return null;
            // }
            return this.props.backgroundColor ?? "#FFF";
          },
          sliceText: null,
          dataLabels: slicePercents.map((data: any, index: any) => {
            return `${Math.floor(data / 10) * 10}%`;
          })
        };
      });

    const data = {
      datasets: mappedLevels
    };

    let labels;
    if (this.props.children?.length > 1) {
      labels =
        this.props.children?.map((child: any) => {
          return {
            text: child.props?.text ?? "",
            font: {
              size: this.getNumbersOfStyleProperties(child.props.style?.fontSize) ?? "30",
              weight: child.props.style?.fontWeight ?? "medium",
              family: child.props.style?.fontFamily ?? "Arial"
            },
            color: child.props.style?.color ?? "black"
          };
        }) ?? [];
    } else if (this.props.children) {
      labels = [
        {
          text: this.props.children?.props?.text ?? "",
          font: {
            size: this.getNumbersOfStyleProperties(this.props.children?.props?.style?.fontSize) ?? "30",
            weight: this.props.children?.props?.style?.fontWeight ?? "medium",
            family: this.props.children?.props?.style?.fontFamily ?? "Arial"
          },
          color: this.props.children?.props?.style?.color ?? "black"
        }
      ];
    }

    const options: any = {
      cutout: "20%",
      hover: { mode: null },
      responsive: true,
      mainAspectRatio: false,
      plugins: {
        tooltip: {
          callbacks: {
            label(ctx) {
              return `${slices[ctx.dataIndex].label}`;
            }
          }
        },
        doughnutLabel: {
          labels
        }
      },
      layout: {
        padding(ctx) {
          return ctx.chart.width * 0.12;
        }
      }
    };

    const plugins: any = [this.drawLines];
    return (
      <Doughnut
        id={this.canvasId}
        {...this.getProps()}
        options={options}
        className={this.canvasId}
        data={data}
        plugins={plugins}
        style={{
          height: "unset",
          width: this.getWidth(),
          display: "flex",
          alignContent: "center",
          justifyContent: "center",
          flexWrap: "wrap",
          borderRadius: "50%",
          position: "absolute",
          top: 0,
          left: 0
        }}
      />
    );
  }
}

const gradientDoughnut = withCommonEvents(GradientDoughnut);
export { gradientDoughnut as GradientDoughnut };
