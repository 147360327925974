import { PureComponent, ReactNode } from "react";
import withCommonEvents from "../../../shared/hoc/with-common-events";
import { CommonProps } from "../common/common-props";

interface TabItemProps {
  children?: ReactNode;
}

interface TabItemState {}

class StepperItem extends PureComponent<TabItemProps & CommonProps, TabItemState> {
  constructor(props: TabItemProps) {
    super(props);
    this.state = {};
  }

  render(): ReactNode {
    return <div {...this.props}> {this.props.children} </div>;
  }
}

const stepperItem = withCommonEvents(StepperItem);
export { stepperItem as StepperItem };
