import { Guid } from "guid-typescript";
import _ from "lodash";
import React, { PureComponent, ReactNode } from "react";
import Slider, { Settings } from "react-slick";
import "./carousel.scss";

export interface CarouselBodyWrapperProps extends Settings {
  children?: ReactNode;
  style?: any;
  setCurrentSlideNumber?: (val: number) => void;
  onChange?: () => void;
  startingIndex?: number;
  hoverBgColor?: string;
  hoverFontColor?: string;
}

interface CarouselBodyWrapperState {
  uniqueKey: Guid;
  mouseOver: boolean;
}

declare let window: Window & { kuika: any };

export class CarouselBodyWrapper extends PureComponent<CarouselBodyWrapperProps, CarouselBodyWrapperState> {
  private memoizedDynamicCssResult = "";

  private sliderRef;

  constructor(props: CarouselBodyWrapperProps) {
    super(props);
    this.sliderRef = React.createRef();
    this.state = {
      uniqueKey: Guid.create(),
      mouseOver: false
    };
  }

  componentDidUpdate(prevState: any) {
    if (prevState.mouseOver !== this.state.mouseOver) this.setDynamicStyle();
  }

  componentDidMount = () => {
    if (this.props.startingIndex && this.props.startingIndex > 0) {
      this.sliderRef.current.slickGoTo(this.props.startingIndex, true);
    }
    const carousel: HTMLCollectionOf<Element> = document?.getElementsByClassName("kuika-carousel__vertical");
    const parent = carousel[0]?.parentElement;
    if (parent && parent.style) {
      parent.style.paddingBottom = "0px";
      parent.style.paddingRight = "25px";
    }
    this.setDynamicStyle();
  };

  getDynamicCss = (): string => {
    const className: string = this.getClassName();
    if (!className || className.length === 0) {
      return "";
    }
    let result = "";
    const { hoverBgColor, hoverFontColor } = this.props;
    if (this.state.mouseOver === true) {
      if (hoverBgColor) {
        result += `.${className.trim()}:hover {
            background-color: ${hoverBgColor} !important;
            transition: background-color 0.5s ease;
          }`;
      }
      if (hoverFontColor) {
        result += `.${className.trim()}:hover label, .${className.trim()}:hover span {
            color: ${hoverFontColor} !important;
          }`;
      }
    }
    return result;
  };

  setDynamicStyle = () => {
    const uniquekey = this.state.uniqueKey?.toString();
    if (!uniquekey) {
      return;
    }
    const isDesignTime = window.kuika?.isDesignTime;
    if (this.memoizedDynamicCssResult !== "" && !isDesignTime) {
      return this.memoizedDynamicCssResult;
    }
    const dynamic_style = document.getElementById("dynamic_style");
    if (dynamic_style && dynamic_style.innerHTML?.indexOf(uniquekey) === -1) {
      const generatedCss = this.getDynamicCss();
      dynamic_style.innerHTML = `${dynamic_style.innerHTML}
        ${generatedCss}`;
      this.memoizedDynamicCssResult = generatedCss;
    }
  };

  getClassName = () => {
    let result = "";
    if (!this.state.uniqueKey) {
      return result;
    }
    result = `kcarousel_${this.state.uniqueKey.toString().substring(0, 8)}`;
    return result;
  };

  getProps = () => {
    const props: any = _.clone(this.props);

    if (props.slidesToShow && props.slidesToShow > 0) {
      if (props.children && props.children[1] && props.children[1].length >= 0) {
        if (props.children[1].length < props.slidesToShow) {
          props.slidesToShow = props.children[1].length;
        }
      }
    }
    if (!this.props.children || this.props.children[1].length === undefined) props.slidesToShow = 1;

    if (props.vertical) {
      props.className += " kuika-carousel__vertical";
    } else {
      props.className += " kuika-carousel__horizontal";
    }
    return props;
  };

  handleMouseOver = () => {
    this.setState({
      mouseOver: true
    });
  };

  handleMouseOut = () => {
    this.setState({
      mouseOver: false
    });
  };

  getPaddingBottom = () => {
    if (this.props.style && this.props.style?.paddingBottom) {
      return 25 + Number.parseInt(this.props.style?.paddingBottom.toString());
    }
    return 25;
  };

  handleAfterChange = (nextSlide: number) => {
    if (this.props.setCurrentSlideNumber) {
      this.props.setCurrentSlideNumber(nextSlide);
    }
    if (this.props.onChange) {
      this.props.onChange();
    }
  };

  render(): ReactNode {
    return (
      <>
        <div
          className={this.getClassName()}
          style={{ ...this.props.style, paddingBottom: this.getPaddingBottom() }}
          onMouseOver={this.handleMouseOver}
          onMouseOut={this.handleMouseOut}
        >
          <Slider ref={this.sliderRef} {...this.getProps()} afterChange={this.handleAfterChange} />
        </div>
      </>
    );
  }
}
