import React from "react";
import Axios, { AxiosResponse } from "axios";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { KNavigator } from "../../shared/hoc/with-history";
import { KContext } from "../../shared/hoc/with-context";
import { useLocation } from "react-router-dom";
import { FormInstance } from "antd/es/form";
import { Guid } from "guid-typescript";
import Swal from "sweetalert2";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import moment from "moment";
import { IValidationData } from "../../models/dto/validation-data";
import { IStripePayment } from "../../models/dto/stripe-payment";
import { IUserInfoDto } from "../../models/dto/user-info.dto";
import { UserService } from "../../services/user-service";
import _ from "lodash";
import mqtt, { MqttClient } from "mqtt";
import { IMqttResult } from "../../models/dto/mqtt.dto";

declare let window: any;

export interface IKirilim2Form_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

export interface IKirilim2Form_ScreenState {
    isSpinnerVisible: boolean;
    isPageVisible: boolean;
	isLeftMenuVisible: boolean;
    spinnerCount: number;
    selectedLanguage: string;
    isPageInitLoaded: false;
    isPageRecurringLoaded: false;
	GoBack: any;
	GoBack_dummy: any;
	MyContext: any[];
	MyContext_dummy: any[];
	Kirilim2SelectById: any[];
	Kirilim2SelectById_dummy: any[];
	Kirilim1SelectAll: any[];
	Kirilim1SelectAll_dummy: any[];
	KurumSelectAll: any[];
	KurumSelectAll_dummy: any[];
	SaveRecord: number;
	SaveRecord_dummy: number;


}

export class Kirilim2Form_ScreenBase extends React.PureComponent<IKirilim2Form_ScreenProps, any> {

    ml=[{"Id":"74503767-9450-4cae-9db5-729d038bcb36","Name":"en_US","ShortName":"English","LanguagePhrases":[{"Id":909426,"PropertyName":"value","Value":"Form","TagName":"Label2_value"},{"Id":7461580,"PropertyName":"value","Value":"Affiliated with","TagName":"lblKirilim1_value"},{"Id":3564764,"PropertyName":"placeholder","Value":"Choose one","TagName":"selKirilim1_placeholder"},{"Id":4386705,"PropertyName":"value","Value":"Description","TagName":"lblTanim_value"},{"Id":2945859,"PropertyName":"placeholder","Value":"Type here","TagName":"txtTanim_placeholder"},{"Id":9544,"PropertyName":"label","Value":"Save","TagName":"btnSave_label"}]},{"Id":"423ed54a-d6f3-fccb-7d3e-72c7b24c8b7a","Name":"tr_TR","ShortName":"Turkish","LanguagePhrases":[]},{"Id":"208dd23b-cca8-4fec-bf16-2d2ec8fdae7d","Name":"FixedValues","ShortName":"FixedValues","LanguagePhrases":[{"Id":909426,"PropertyName":"value","Value":"Formu"},{"Id":4386705,"PropertyName":"value","Value":"Tanım"},{"Id":2945859,"PropertyName":"placeholder","Value":"Yazınız..."},{"Id":9544,"PropertyName":"label","Value":"Kaydet"}]}]
    defaultML = "tr_TR"
    
    screenHash = ""
    
    constructor(props) {
        super(props);


        this.state = {
            isSpinnerVisible: false,
            isPageVisible: false,
            isLeftMenuVisible: false,
            spinnerCount: 0,
            selectedLanguage: "",
            isPageInitLoaded: false,
            isPageRecurringLoaded: false,
            	        GoBack: "",
	        MyContext: [],
	        Kirilim2SelectById: [],
	        Kirilim1SelectAll: [],
	        KurumSelectAll: [],
	        SaveRecord: false,

            
        }

        this.state.isPageVisible = true;
        
    }

    async componentDidMount() {

        if (KuikaAppManager.isPageInitPrevented()) {
            KuikaAppManager.calculateAndSetBodyHeight("kirilim2form", "");
            return;
        }
        
        ReactSystemFunctions.validateToken()
        if (true){
            await this.Kirilim2FormPageInit();
        }
        
        
        
        KuikaAppManager.calculateAndSetBodyHeight("kirilim2form", "");
    }

    componentWillUnmount() {
        
        
        
    }

    

        
    componentDidUpdate= async (prevProps, prevState) => {
		KuikaAppManager.calculateAndSetBodyHeight("kirilim2form", ""    );
        if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
			await this.Kirilim2FormPageInit();
		}

        
	}

    fillFormInitValues() {
        this.props.form.setFieldsValue({
kirilim2form_2945859_value: this.state.Kirilim2SelectById?.at?.(0)?.tanim ?? undefined
});

    }

      setPageInitLoaded = (value: boolean) => {
        this.setState({ isPageInitLoaded: value });
      }

      setPageRecurringLoaded = (value: boolean) => {
        this.setState({ isPageRecurringLoaded: value });
      }

      clearFieldValidation = (changedValues: any) => {
        const fieldName = Object.keys(changedValues)[0];
        if (fieldName) {
          this.props.form.setFields([
            {
              name: fieldName,
              errors: []
            }
          ]);
        }
      };

      

      

    Kirilim2FormPageInit = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }

        let localVar = {
			Id_1: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.Id ?? this.props.screenInputs.id, "Guid")
        }

	KuikaAppManager.showSpinner(this);
	
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "Kirilim2Form/Kirilim2FormPageInit", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

		stateVars.MyContext = result?.data.myContext;
		formVars.kirilim2form_796540_value = ReactSystemFunctions.toString(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].kirilim2Adi : this.state.MyContext?.length > 0 ? this.state.MyContext[0].kirilim2Adi : null));
		stateVars.Kirilim2SelectById = result?.data.kirilim2SelectById;
		formVars.kirilim2form_2945859_value = ReactSystemFunctions.toString(this, (stateVars.Kirilim2SelectById?.length > 0 ? stateVars.Kirilim2SelectById[0].tanim : this.state.Kirilim2SelectById?.length > 0 ? this.state.Kirilim2SelectById[0].tanim : null));
		stateVars.Kirilim1SelectAll = result?.data.kirilim1SelectAll;
		stateVars.KurumSelectAll = result?.data.kurumSelectAll;
		this.props.form.setFieldsValue(formVars);
		
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.Kirilim2FormPageInit1_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
	Kirilim2FormPageInit1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }

			formVars.kirilim2form_796540_value = ReactSystemFunctions.toString(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].kirilim2Adi : this.state.MyContext?.length > 0 ? this.state.MyContext[0].kirilim2Adi : null));

			formVars.kirilim2form_2945859_value = ReactSystemFunctions.toString(this, (stateVars.Kirilim2SelectById?.length > 0 ? stateVars.Kirilim2SelectById[0].tanim : this.state.Kirilim2SelectById?.length > 0 ? this.state.Kirilim2SelectById[0].tanim : null));


	this.props.form.setFieldsValue(formVars);
	this.setState(stateVars);
	KuikaAppManager.hideSpinner(this);

            this.setPageInitLoaded(true);
            return isErrorOccurred;
        }




		Kirilim2FormComponent_968274_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
KuikaAppManager.showSpinner(this);

				stateVars.GoBack = await ReactSystemFunctions.goBack(this);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }


    Kirilim2FormComponent_9544_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;

			validations.push({ 
				isValid: ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "kirilim2form_2945859_value", "value", "", "", "")), null),
				message: "*",
				formName: "kirilim2form_2945859_value",
				condition: true
			} as IValidationData);

validations.forEach((validation) => {
            if (validation.message === "Geçerli bir mail adresi giriniz.") {
                validation.message = ReactSystemFunctions.getEmailValidationMessage()
            }
        })

		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				Id_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.Id ?? this.props.screenInputs.id, "Guid"),
				KurumId_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].kurumId : this.state.MyContext?.length > 0 ? this.state.MyContext[0].kurumId : null)), "Guid"),
				Kirilim1_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].kirilim1Id : this.state.MyContext?.length > 0 ? this.state.MyContext[0].kirilim1Id : null)), "Guid"),
				Tanim_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "kirilim2form_2945859_value", "value", "", "", "")), "string")
        }

		KuikaAppManager.showSpinner(this);
		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "Kirilim2Form/Kirilim2FormComponent_9544_onClick", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.SaveRecord = result?.data.saveRecord_1;
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.Kirilim2FormComponent_9544_onClick1_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
		Kirilim2FormComponent_9544_onClick1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }

				stateVars.GoBack = await ReactSystemFunctions.goBack(this);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }






    async callCallbackFunction(diId) {
        let isErrorOccurred = false;

    }
}
