import React from "react";
import Axios, { AxiosResponse } from "axios";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { KNavigator } from "../../shared/hoc/with-history";
import { KContext } from "../../shared/hoc/with-context";
import { useLocation } from "react-router-dom";
import { FormInstance } from "antd/es/form";
import { Guid } from "guid-typescript";
import Swal from "sweetalert2";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import moment from "moment";
import { IValidationData } from "../../models/dto/validation-data";
import { IStripePayment } from "../../models/dto/stripe-payment";
import { IUserInfoDto } from "../../models/dto/user-info.dto";
import { UserService } from "../../services/user-service";
import _ from "lodash";
import mqtt, { MqttClient } from "mqtt";
import { IMqttResult } from "../../models/dto/mqtt.dto";

declare let window: any;

export interface IRandevuSelfService2_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

export interface IRandevuSelfService2_ScreenState {
    isSpinnerVisible: boolean;
    isPageVisible: boolean;
	isLeftMenuVisible: boolean;
    spinnerCount: number;
    selectedLanguage: string;
    isPageInitLoaded: false;
    isPageRecurringLoaded: false;
	GoBack: any;
	GoBack_dummy: any;
	MyContext: any[];
	MyContext_dummy: any[];
	SehirAll: any[];
	SehirAll_dummy: any[];
	HizmetById: any[];
	HizmetById_dummy: any[];
	RandevuMerkezler: any[];
	RandevuMerkezler_dummy: any[];
	ChangeEnabledOf: string;
	ChangeEnabledOf_dummy: string;
	NAVIGATE: any;
	NAVIGATE_dummy: any;
	isComp341303Enabled: 'enabled' | 'disabled';
	isComp328086Enabled: 'enabled' | 'disabled';


}

export class RandevuSelfService2_ScreenBase extends React.PureComponent<IRandevuSelfService2_ScreenProps, any> {
	randevuselfservice2_957356_value_kuikaSelectBoxRef: React.RefObject<any>;
	randevuselfservice2_115554_value_kuikaFlexGridRef: React.RefObject<any>;
    ml=[{"Id":"423ed54a-d6f3-fccb-7d3e-72c7b24c8b7a","Name":"tr_TR","ShortName":"Turkish","IsDefault":true,"LanguagePhrases":[]},{"Id":"74503767-9450-4cae-9db5-729d038bcb36","Name":"en_US","ShortName":"English","LanguagePhrases":[]},{"Id":"1f6a5d87-4859-4000-a341-86bd8278b9b5","Name":"FixedValues","ShortName":"FixedValues","LanguagePhrases":[{"Id":796540,"PropertyName":"value","Value":"Randevu"},{"Id":449268,"PropertyName":"value","Value":"Hizmet"},{"Id":998318,"PropertyName":"value","Value":"Süre"},{"Id":854115,"PropertyName":"value","Value":"dk"},{"Id":857648,"PropertyName":"value","Value":"Randevu almak istediğiniz merkezi seçiniz..."},{"Id":957356,"PropertyName":"placeholder","Value":"Seçiniz..."},{"Id":115554,"PropertyName":"nodatafoundmessage","Value":"No data found."},{"Id":415392,"PropertyName":"value","Value":"[datafield:unvani]"},{"Id":738262,"PropertyName":"value","Value":"[datafield:acikadres]"},{"Id":341303,"PropertyName":"label","Value":"İLERİ"}]}]
    defaultML = "tr_TR"
    
    screenHash = ""
    
    constructor(props) {
        super(props);
		this.randevuselfservice2_957356_value_kuikaSelectBoxRef = React.createRef();
		this.randevuselfservice2_115554_value_kuikaFlexGridRef = React.createRef();

        this.state = {
            isSpinnerVisible: false,
            isPageVisible: false,
            isLeftMenuVisible: false,
            spinnerCount: 0,
            selectedLanguage: "",
            isPageInitLoaded: false,
            isPageRecurringLoaded: false,
            	        GoBack: "",
	        MyContext: [],
	        SehirAll: [],
	        HizmetById: [],
	        RandevuMerkezler: [],
	        ChangeEnabledOf: "",
	        NAVIGATE: "",
	        isComp341303Enabled: 'enabled',
	        isComp328086Enabled: 'disabled',

            
        }

        this.state.isPageVisible = true;
        
    }

    async componentDidMount() {

        if (KuikaAppManager.isPageInitPrevented()) {
            KuikaAppManager.calculateAndSetBodyHeight("randevuselfservice2", "");
            return;
        }
        
        ReactSystemFunctions.validateToken()
        if (true){
            await this.RandevuSelfService2PageInit();
        }
        
        
        
        KuikaAppManager.calculateAndSetBodyHeight("randevuselfservice2", "");
    }

    componentWillUnmount() {
        
        
        
    }

    

        
    componentDidUpdate= async (prevProps, prevState) => {
		KuikaAppManager.calculateAndSetBodyHeight("randevuselfservice2", ""    );
        if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
			await this.RandevuSelfService2PageInit();
		}

        
	}

    fillFormInitValues() {
        this.props.form.setFieldsValue({
randevuselfservice2_752961_value: this.state.HizmetById?.at?.(0)?.image ?? undefined
});

    }

      setPageInitLoaded = (value: boolean) => {
        this.setState({ isPageInitLoaded: value });
      }

      setPageRecurringLoaded = (value: boolean) => {
        this.setState({ isPageRecurringLoaded: value });
      }

      clearFieldValidation = (changedValues: any) => {
        const fieldName = Object.keys(changedValues)[0];
        if (fieldName) {
          this.props.form.setFields([
            {
              name: fieldName,
              errors: []
            }
          ]);
        }
      };

      

      

    RandevuSelfService2PageInit = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }

        let localVar = {
			Id_2: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.HizmetId ?? this.props.screenInputs.hizmetid, "Guid"),
			davetId_3: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
			seciliHizmetId_3: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.HizmetId ?? this.props.screenInputs.hizmetid, "Guid"),
			seciliMerkezId_3: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
			seciliEgitmenId_3: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
			sehirId_3: ReactSystemFunctions.convertToTypeByName(null, "Guid")
        }

	KuikaAppManager.showSpinner(this);
	
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "RandevuSelfService2/RandevuSelfService2PageInit", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

		stateVars.MyContext = result?.data.myContext;
		stateVars.SehirAll = result?.data.sehirAll;
		
		formVars.randevuselfservice2_957356_options = stateVars.SehirAll;
		stateVars.HizmetById = result?.data.hizmetById;
		formVars.randevuselfservice2_752961_value = ReactSystemFunctions.toString(this, (stateVars.HizmetById?.length > 0 ? stateVars.HizmetById[0].image : this.state.HizmetById?.length > 0 ? this.state.HizmetById[0].image : null));
		formVars.randevuselfservice2_305650_value = ReactSystemFunctions.toString(this, (stateVars.HizmetById?.length > 0 ? stateVars.HizmetById[0].title : this.state.HizmetById?.length > 0 ? this.state.HizmetById[0].title : null));
		formVars.randevuselfservice2_437705_value = ReactSystemFunctions.toString(this, (stateVars.HizmetById?.length > 0 ? stateVars.HizmetById[0].aciklama : this.state.HizmetById?.length > 0 ? this.state.HizmetById[0].aciklama : null));
		formVars.randevuselfservice2_588503_value = ReactSystemFunctions.toString(this, (stateVars.HizmetById?.length > 0 ? stateVars.HizmetById[0].sure : this.state.HizmetById?.length > 0 ? this.state.HizmetById[0].sure : null));
		stateVars.RandevuMerkezler = result?.data.randevuMerkezler;
		
		this.props.form.setFieldsValue(formVars);
		
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.RandevuSelfService2PageInit1_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
	RandevuSelfService2PageInit1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }

			stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, 'isComp341303Enabled', "disabled");

	this.props.form.setFieldsValue(formVars);
	
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.RandevuSelfService2PageInit2_();
                  resolve();
                });
          })

            
            return isErrorOccurred;
        }
	RandevuSelfService2PageInit2_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.value(this, (stateVars.RandevuMerkezler?.length > 0 ? stateVars.RandevuMerkezler[0].secilenMerkezId : this.state.RandevuMerkezler?.length > 0 ? this.state.RandevuMerkezler[0].secilenMerkezId : null)), null)) {
            
			stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, 'isComp341303Enabled', "enabled");

	this.props.form.setFieldsValue(formVars);
	
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.RandevuSelfService2PageInit3_();
                  resolve();
                });
          })

        } else {
            
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.RandevuSelfService2PageInit3_();
                  resolve();
                });
          })
        }

            
            return isErrorOccurred;
        }
	RandevuSelfService2PageInit3_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }

			formVars.randevuselfservice2_752961_value = ReactSystemFunctions.toString(this, (stateVars.HizmetById?.length > 0 ? stateVars.HizmetById[0].image : this.state.HizmetById?.length > 0 ? this.state.HizmetById[0].image : null));

			formVars.randevuselfservice2_305650_value = ReactSystemFunctions.toString(this, (stateVars.HizmetById?.length > 0 ? stateVars.HizmetById[0].title : this.state.HizmetById?.length > 0 ? this.state.HizmetById[0].title : null));

			formVars.randevuselfservice2_437705_value = ReactSystemFunctions.toString(this, (stateVars.HizmetById?.length > 0 ? stateVars.HizmetById[0].aciklama : this.state.HizmetById?.length > 0 ? this.state.HizmetById[0].aciklama : null));

			formVars.randevuselfservice2_588503_value = ReactSystemFunctions.toString(this, (stateVars.HizmetById?.length > 0 ? stateVars.HizmetById[0].sure : this.state.HizmetById?.length > 0 ? this.state.HizmetById[0].sure : null));

			
			stateVars.dataSource_957356 = this.state.SehirAll;
			stateVars.dataSource_957356 = this.state.SehirAll;
			
			stateVars.dataSource_115554 = this.state.RandevuMerkezler;

	this.props.form.setFieldsValue(formVars);
	this.setState(stateVars);
	KuikaAppManager.hideSpinner(this);

            this.setPageInitLoaded(true);
            return isErrorOccurred;
        }






		RandevuSelfService2Component_968274_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
KuikaAppManager.showSpinner(this);

				stateVars.GoBack = await ReactSystemFunctions.goBack(this);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }


    RandevuSelfService2Component_957356_onChange = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				davetId_0: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
				seciliHizmetId_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.HizmetId ?? this.props.screenInputs.hizmetid, "Guid"),
				seciliMerkezId_0: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
				seciliEgitmenId_0: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
				sehirId_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "randevuselfservice2_957356_value", "value", "SehirAll", "id", "id")), "Guid")
        }

		KuikaAppManager.showSpinner(this);
		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "RandevuSelfService2/RandevuSelfService2Component_957356_onChange", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.RandevuMerkezler = result?.data.randevuMerkezler;
			
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.RandevuSelfService2Component_957356_onChange1_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
		RandevuSelfService2Component_957356_onChange1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }

				
				stateVars.dataSource_115554 = this.state.RandevuMerkezler;

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }




    RandevuSelfService2Component_746730_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				davetId_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.DavetiyeId ?? this.props.screenInputs.davetiyeid, "Guid"),
				seciliHizmetId_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.HizmetId ?? this.props.screenInputs.hizmetid, "Guid"),
				seciliMerkezId_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, "randevuselfservice2_115554_value", "id"), "Guid"),
				seciliEgitmenId_0: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
				sehirId_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "randevuselfservice2_957356_value", "value", "SehirAll", "id", "id")), "Guid")
        }

		KuikaAppManager.showSpinner(this);
		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "RandevuSelfService2/RandevuSelfService2Component_746730_onClick", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.RandevuMerkezler = result?.data.randevuMerkezler;
			
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.RandevuSelfService2Component_746730_onClick1_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
		RandevuSelfService2Component_746730_onClick1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }

				stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, 'isComp341303Enabled', "disabled");

		this.props.form.setFieldsValue(formVars);
		
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.RandevuSelfService2Component_746730_onClick2_();
                  resolve();
                });
          })

            
            return isErrorOccurred;
        }
		RandevuSelfService2Component_746730_onClick2_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.value(this, (stateVars.RandevuMerkezler?.length > 0 ? stateVars.RandevuMerkezler[0].secilenMerkezId : this.state.RandevuMerkezler?.length > 0 ? this.state.RandevuMerkezler[0].secilenMerkezId : null)), null)) {
            
				stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, 'isComp341303Enabled', "enabled");

		this.props.form.setFieldsValue(formVars);
		
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.RandevuSelfService2Component_746730_onClick3_();
                  resolve();
                });
          })

        } else {
            
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.RandevuSelfService2Component_746730_onClick3_();
                  resolve();
                });
          })
        }

            
            return isErrorOccurred;
        }
		RandevuSelfService2Component_746730_onClick3_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }

				
				stateVars.dataSource_115554 = this.state.RandevuMerkezler;

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }






		RandevuSelfService2Component_341303_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
KuikaAppManager.showSpinner(this);

					KuikaAppManager.prepareForNavigation();
						KuikaAppManager.addToPageInputVariables("RandevuSelfService3", "HizmetId", this.props.screenInputs.HizmetId ?? this.props.screenInputs.hizmetid);
						KuikaAppManager.addToPageInputVariables("RandevuSelfService3", "MerkezId", ReactSystemFunctions.value(this, (stateVars.RandevuMerkezler?.length > 0 ? stateVars.RandevuMerkezler[0].secilenMerkezId : this.state.RandevuMerkezler?.length > 0 ? this.state.RandevuMerkezler[0].secilenMerkezId : null)));
						KuikaAppManager.addToPageInputVariables("RandevuSelfService3", "RandevuId", this.props.screenInputs.RandevuId ?? this.props.screenInputs.randevuid);
						KuikaAppManager.addToPageInputVariables("RandevuSelfService3", "DavetiyeId", this.props.screenInputs.DavetiyeId ?? this.props.screenInputs.davetiyeid);
						KuikaAppManager.addToPageInputVariables("RandevuSelfService3", "DanisanKisiId", this.props.screenInputs.DanisanKisiId ?? this.props.screenInputs.danisankisiid);

				stateVars.NAVIGATE = await ReactSystemFunctions.navigate(this, this.RandevuSelfService2Component_341303_onClick1_, "RandevuSelfService2", "RandevuSelfService3", "", "7d969ddf-5801-4eb6-b92b-12be35bdd565", "183991", null, "right", null, "80vw", "100vh", true);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);

            
            return isErrorOccurred;
        }
		RandevuSelfService2Component_341303_onClick1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }

				stateVars.GoBack = await ReactSystemFunctions.goBack(this);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }





    async callCallbackFunction(diId) {
        let isErrorOccurred = false;
		let pageInitCallerNavDiIDs = [183991] as number[];
		if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
			await this.RandevuSelfService2PageInit();
		}
		if (diId == 183991) {
			isErrorOccurred = await this.RandevuSelfService2Component_341303_onClick1_();
            if (isErrorOccurred) return true;
		}

    }
}
