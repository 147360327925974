import { Input } from "antd/es";
import { InputProps } from "antd/es/input";
import _ from "lodash";
import { PureComponent, ReactNode } from "react";
import ReactDOM from "react-dom";
import withCommonEvents from "../../../shared/hoc/with-common-events";
import { CommonProps } from "../common-props";
import { Icon } from "../icon/icon";

interface TextboxProps extends InputProps {
  prefix?: string;
  suffix?: string;
  iconColor?: string;
}

interface TextboxState {}

class Url extends PureComponent<TextboxProps & CommonProps, TextboxState> {
  constructor(props: TextboxProps) {
    super(props);
    this.state = {};
  }

  handleAffixStyling = () => {
    const node = ReactDOM.findDOMNode(this) as Element;
    const input = node.getElementsByTagName("input")[0];
    if (!input) {
      return;
    }
    if (this.props.style?.backgroundColor) {
      input.style.backgroundColor = this.props.style?.backgroundColor as any;
    }
    if (this.props.style?.color) {
      input.style.color = this.props.style?.color as any;
    }
  };

  getProps = () => {
    const props: any = _.clone(this.props);
    const style: any = _.clone(this.props.style);

    if (style.display === "block") {
      style.display = "flex";
    } else if (style.display === "inline") {
      style.display = "inline-flex";
    }
    if (props.prefix && props.prefix.length > 0) {
      props.prefix = <Icon style={{ color: props.iconColor }} iconName={this.props.prefix as string}></Icon>;
    } else if (props.prefix) {
      delete props.prefix;
    }

    if (props.suffix && props.suffix.length > 0) {
      props.suffix = <Icon style={{ color: props.iconColor }} iconName={this.props.suffix as string}></Icon>;
    } else if (props.suffix) {
      delete props.suffix;
    }
    if (props.iconColor) {
      delete props.iconColor;
    }

    if (props.value && style.textTransform === "lowercase") {
      props.value = props.value.toString().toLowerCase();
    } else if (props.value && style.textTransform === "uppercase") {
      props.value = props.value.toString().toUpperCase();
    }
    props.style = style;

    return props;
  };

  componentDidMount() {
    if (
      this.props.style &&
      (this.props.style?.backgroundColor || this.props.style?.color) &&
      (this.props.prefix || this.props.suffix)
    ) {
      this.handleAffixStyling();
    }
  }

  render(): ReactNode {
    return (
      <>
        <Input {...this.getProps()}></Input>
      </>
    );
  }
}

const url = withCommonEvents(Url);
export { url as Url };
