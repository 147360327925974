import { ArcElement, Chart, registerables } from "chart.js";
import { CSSProperties, PureComponent, ReactNode } from "react";
import { Doughnut } from "react-chartjs-2";
import withCommonEvents from "../../../../shared/hoc/with-common-events";
import { CommonProps } from "../../common-props";

interface GaugeChartProps extends CommonProps {
  max: number;
  value: number;
  colors: string[];
  style?: CSSProperties;
  hidePercentage?: boolean;
}

interface GaugeChartState {}

class GaugeChart extends PureComponent<GaugeChartProps, GaugeChartState> {
  constructor(props: GaugeChartProps) {
    super(props);
    this.state = {};
    Chart.register(...registerables);
    Chart.register(ArcElement);
  }

  getPercent = (): number => {
    let result: number = 0;
    if (this.props.max > 0 && this.props.value > 0) {
      result = this.props.value / this.props.max;
    }
    return result;
  };

  getData = () => {
    const value = this.getPercent() * 100;
    let restAvailable = 100 - value;

    if (restAvailable < 0) {
      restAvailable = 0;
    }
    return {
      datasets: [
        {
          data: [value, restAvailable],
          backgroundColor: this.props.colors,
          borderWidth: 1,
          circumference: 180,
          rotation: 270,
          cutout: "85%"
        }
      ]
    };
  };

  getOptions = () => ({
    legend: {
      display: false
    },
    plugins: {
      tooltip: {
        enabled: false
      },
      datalabels: {
        display: false
      }
    },
    maintainAspectRatio: false,
    responsive: true
  });

  getGaugePercentagePlugin = () => {
    const { fontStyle, fontFamily, fontWeight, fontSize, color } = this.getStyle();

    return {
      id: "gaugePercentage",
      afterDraw(chart) {
        const { ctx, data } = chart;
        const meta = chart.getDatasetMeta(0);
        const firstValue = meta.data[0];
        const chartWidth = chart.width || chart.canvas.clientWidth;
        const chartHeight = chart.height || chart.canvas.clientHeight;
        const value = data.datasets[0].data[0];

        const textWeight = fontWeight || "normal";
        const textStyle = fontStyle || "normal";
        const textFamily = fontFamily || "system-ui";
        const textSize = fontSize || Math.min(chartWidth, chartHeight) * 0.095;

        ctx.fillStyle = color || "black";
        ctx.font = `${textStyle} ${textWeight} ${textSize} ${textFamily}`;
        ctx.textAlign = "center";
        const textxMetrics = ctx.measureText(`${value}%`);
        const actualTextHeight = textxMetrics.actualBoundingBoxAscent + textxMetrics.actualBoundingBoxDescent;
        ctx.fillText(`${value}%`, firstValue.x, firstValue.y - actualTextHeight / 2);
      }
    };
  };

  getStyle = (): CSSProperties => {
    const style = { ...(this.props.style || {}) };

    if (style.display === "inline") {
      style.display = "inline-block";
    }

    style.position = "relative";

    return style;
  };

  render(): ReactNode {
    const plugins = [];
    if (!this.props.hidePercentage) plugins.push(this.getGaugePercentagePlugin());

    return (
      <div style={this.getStyle()}>
        <Doughnut data={this.getData()} options={this.getOptions()} plugins={plugins} />
      </div>
    );
  }
}

const gaugeChart = withCommonEvents(GaugeChart);
export { gaugeChart as GaugeChart };
