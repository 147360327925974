import React from "react";
import Axios, { AxiosResponse } from "axios";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { KNavigator } from "../../shared/hoc/with-history";
import { KContext } from "../../shared/hoc/with-context";
import { useLocation } from "react-router-dom";
import { FormInstance } from "antd/es/form";
import { Guid } from "guid-typescript";
import Swal from "sweetalert2";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import moment from "moment";
import { IValidationData } from "../../models/dto/validation-data";
import { IStripePayment } from "../../models/dto/stripe-payment";
import { IUserInfoDto } from "../../models/dto/user-info.dto";
import { UserService } from "../../services/user-service";
import _ from "lodash";
import mqtt, { MqttClient } from "mqtt";
import { IMqttResult } from "../../models/dto/mqtt.dto";

declare let window: any;

export interface IKirilim2List_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

export interface IKirilim2List_ScreenState {
    isSpinnerVisible: boolean;
    isPageVisible: boolean;
	isLeftMenuVisible: boolean;
    spinnerCount: number;
    selectedLanguage: string;
    isPageInitLoaded: false;
    isPageRecurringLoaded: false;
	MyContext: any[];
	MyContext_dummy: any[];
	Kirilim2Count: any[];
	Kirilim2Count_dummy: any[];
	Kirilim2Search: any[];
	Kirilim2Search_dummy: any[];
	KurumTipiSelectById: any[];
	KurumTipiSelectById_dummy: any[];
	SetValueOf: string;
	SetValueOf_dummy: string;
	Notify: boolean;
	Notify_dummy: boolean;
	NAVIGATE: any;
	NAVIGATE_dummy: any;
	EmptySheet2: number;
	EmptySheet2_dummy: number;
	UploadFile: any;
	UploadFile_dummy: any;
	ImportExcelBase64: string;
	ImportExcelBase64_dummy: string;
	Sheet2Select: any[];
	Sheet2Select_dummy: any[];
	ImportDanisanFromSheet2: number;
	ImportDanisanFromSheet2_dummy: number;
	SampleExcel2: any[];
	SampleExcel2_dummy: any[];
	ExportExcel: any;
	ExportExcel_dummy: any;
	DeleteKirilim2: number;
	DeleteKirilim2_dummy: number;
	isComp814413Visible: 'visible' | 'hidden';
	isComp444885Visible: 'visible' | 'hidden';
	isComp792509Visible: 'visible' | 'hidden';

isMasterPageInitLoaded?: boolean;
  isMasterPageRecurringLoaded?: boolean;
}

export class Kirilim2List_ScreenBase extends React.PureComponent<IKirilim2List_ScreenProps, any> {
	kirilim2list_399769_value_kuikaTableRef: React.RefObject<any>;
	kirilim2list_172599_value_kuikaPaginationRef: React.RefObject<any>;
    ml=[{"Id":"74503767-9450-4cae-9db5-729d038bcb36","Name":"en_US","ShortName":"English","LanguagePhrases":[{"Id":363039,"PropertyName":"value","Value":"LIST","TagName":"Label1_value"},{"Id":416063,"PropertyName":"placeholder","Value":"Search...","TagName":"txtSearch_placeholder"},{"Id":814413,"PropertyName":"label","Value":"NEW","TagName":"btnNew_label"},{"Id":399769,"PropertyName":"nodatafoundmessage","Value":"No data found.","TagName":"tblKirilim21_nodatafoundmessage"},{"Id":2229937,"PropertyName":"title","Value":"Affiliated with","TagName":"clmKirilim1_title"},{"Id":3518158,"PropertyName":"value","Value":"[datafield:kirilim1adi]","TagName":"lblKirilim1_value"},{"Id":9772580,"PropertyName":"title","Value":"Description","TagName":"clmTanim_title"},{"Id":2241228,"PropertyName":"value","Value":"[datafield:tanim]","TagName":"lblTanim_value"},{"Id":97111372,"PropertyName":"fe852656-41cb-0d69-f310-feba936ad060_confirmation","Value":"Are you sure you want to delete this record?","TagName":"Kirilim2DeleteById_confirmation","IsConfirmation":true}]},{"Id":"423ed54a-d6f3-fccb-7d3e-72c7b24c8b7a","Name":"tr_TR","ShortName":"Turkish","LanguagePhrases":[]},{"Id":"494f6dc6-23db-4dad-9c2a-f25c777f7fe9","Name":"FixedValues","ShortName":"FixedValues","LanguagePhrases":[{"Id":363039,"PropertyName":"value","Value":"Listesi"},{"Id":416063,"PropertyName":"placeholder","Value":"Arama..."},{"Id":399769,"PropertyName":"nodatafoundmessage","Value":"No data found."},{"Id":3518158,"PropertyName":"value","Value":"[datafield:tanim]"},{"Id":41583,"PropertyName":"value","Value":"[datafield:ogrenciadedi]"},{"Id":444885,"PropertyName":"title","Value":"Toplu Kayıt"},{"Id":968629,"PropertyName":"value","Value":"Upload"},{"Id":741417,"PropertyName":"value","Value":"Example"},{"Id":837047,"PropertyName":"title","Value":"Toplu Check-Up"},{"Id":871482,"PropertyName":"label","Value":"Σ"}]}]
    defaultML = "tr_TR"
    
    screenHash = ""
    
    constructor(props) {
        super(props);
		this.kirilim2list_399769_value_kuikaTableRef = React.createRef();
		this.kirilim2list_172599_value_kuikaPaginationRef = React.createRef();

        this.state = {
            isSpinnerVisible: false,
            isPageVisible: false,
            isLeftMenuVisible: false,
            spinnerCount: 0,
            selectedLanguage: "",
            isPageInitLoaded: false,
            isPageRecurringLoaded: false,
            	        MyContext: [],
	        Kirilim2Count: [],
	        Kirilim2Search: [],
	        KurumTipiSelectById: [],
	        SetValueOf: "",
	        Notify: false,
	        NAVIGATE: "",
	        EmptySheet2: 0,
	        UploadFile: "",
	        ImportExcelBase64: "",
	        Sheet2Select: [],
	        ImportDanisanFromSheet2: 0,
	        SampleExcel2: [],
	        ExportExcel: "",
	        DeleteKirilim2: 0,
	        isComp814413Visible: 'hidden',
	        isComp444885Visible: 'hidden',
	        isComp792509Visible: 'hidden',

            isMasterPageInitLoaded: false,
  isMasterPageRecurringLoaded: false,
        }

        this.state.isPageVisible = true;
        
    }

    async componentDidMount() {

        if (KuikaAppManager.isPageInitPrevented()) {
            KuikaAppManager.calculateAndSetBodyHeight("kirilim2list", "master_screen");
            return;
        }
        
        ReactSystemFunctions.validateToken()
        if (false){
            await this.Kirilim2ListPageInit();
        }
        
        
        
        KuikaAppManager.calculateAndSetBodyHeight("kirilim2list", "master_screen");
    }

    componentWillUnmount() {
        
        
        
    }

    

        
    componentDidUpdate= async (prevProps, prevState) => {
		KuikaAppManager.calculateAndSetBodyHeight("kirilim2list", "master_screen"    );
        if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
			await this.Kirilim2ListPageInit();
		}

        		if (prevState.isMasterPageInitLoaded !== this.state.isMasterPageInitLoaded && this.state.isMasterPageInitLoaded === true) {await this.Kirilim2ListPageInit();}

	}

    fillFormInitValues() {
        this.props.form.setFieldsValue({
});

    }

      setPageInitLoaded = (value: boolean) => {
        this.setState({ isPageInitLoaded: value });
      }

      setPageRecurringLoaded = (value: boolean) => {
        this.setState({ isPageRecurringLoaded: value });
      }

      clearFieldValidation = (changedValues: any) => {
        const fieldName = Object.keys(changedValues)[0];
        if (fieldName) {
          this.props.form.setFields([
            {
              name: fieldName,
              errors: []
            }
          ]);
        }
      };

      

        setMasterPageInitLoaded = (value: boolean) => {
    this.setState({ isMasterPageInitLoaded: value });
  }

  setMasterPageRecurringLoaded = (value: boolean) => {
    this.setState({ isMasterPageRecurringLoaded: value });
  }

    Kirilim2ListPageInit = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }

        let localVar = {
			searchFor_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "kirilim2list_416063_value", "value", "", "", "")), "string"),
			searchFor_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "kirilim2list_416063_value", "value", "", "", "")), "string"),
			currentpage_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "kirilim2list_172599_value", "current", "", "", "")), "number"),
			pagesize_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "kirilim2list_172599_value", "pageSize", "", "", "")), "number")
        }

	KuikaAppManager.showSpinner(this);
	
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "Kirilim2List/Kirilim2ListPageInit", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

		stateVars.MyContext = result?.data.myContext;
		formVars.kirilim2list_923160_value = ReactSystemFunctions.toString(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].kirilim2Adi : this.state.MyContext?.length > 0 ? this.state.MyContext[0].kirilim2Adi : null));
		stateVars.isComp814413Visible = (((ReactSystemFunctions.isNotEqualTo(ReactSystemFunctions.toString(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].rolename : this.state.MyContext?.length > 0 ? this.state.MyContext[0].rolename : null)), "Rol_Instructor"))) === true ? "visible" : "hidden")
		stateVars.isComp792509Visible = ((((ReactSystemFunctions.isNotEqualTo(ReactSystemFunctions.toString(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].rolename : this.state.MyContext?.length > 0 ? this.state.MyContext[0].rolename : null)), "Rol_Instructor") || ReactSystemFunctions.isNotEqualTo(ReactSystemFunctions.toString(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].kurumTipiKod : this.state.MyContext?.length > 0 ? this.state.MyContext[0].kurumTipiKod : null)), "KT_School")))) === true ? "visible" : "hidden")
		stateVars.Kirilim2Count = result?.data.kirilim2Count;
		stateVars.Kirilim2Search = result?.data.kirilim2Search;
		
		stateVars.KurumTipiSelectById = result?.data.kurumTipiSelectById;
		formVars.kirilim2list_814413_label = ReactSystemFunctions.toString(this, (stateVars.KurumTipiSelectById?.length > 0 ? stateVars.KurumTipiSelectById[0].yenikirilim2 : this.state.KurumTipiSelectById?.length > 0 ? this.state.KurumTipiSelectById[0].yenikirilim2 : null));
		this.props.form.setFieldsValue(formVars);
		
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.Kirilim2ListPageInit1_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
	Kirilim2ListPageInit1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }

			formVars.kirilim2list_923160_value = ReactSystemFunctions.toString(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].kirilim2Adi : this.state.MyContext?.length > 0 ? this.state.MyContext[0].kirilim2Adi : null));

			formVars.kirilim2list_814413_label = ReactSystemFunctions.toString(this, (stateVars.KurumTipiSelectById?.length > 0 ? stateVars.KurumTipiSelectById[0].yenikirilim2 : this.state.KurumTipiSelectById?.length > 0 ? this.state.KurumTipiSelectById[0].yenikirilim2 : null));

			stateVars.isComp814413Visible = (((ReactSystemFunctions.isNotEqualTo(ReactSystemFunctions.toString(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].rolename : this.state.MyContext?.length > 0 ? this.state.MyContext[0].rolename : null)), "Rol_Instructor"))) === true ? "visible" : "hidden")
			
			stateVars.dataSource_399769 = this.state.Kirilim2Search;
			formVars.kirilim2list_2229937_title = ReactSystemFunctions.toString(this, (stateVars.KurumTipiSelectById?.length > 0 ? stateVars.KurumTipiSelectById[0].kirilim2adi : this.state.KurumTipiSelectById?.length > 0 ? this.state.KurumTipiSelectById[0].kirilim2adi : null));

			formVars.kirilim2list_424506_title = ReactSystemFunctions.toString(this, (stateVars.KurumTipiSelectById?.length > 0 ? stateVars.KurumTipiSelectById[0].checkupOzneAdi : this.state.KurumTipiSelectById?.length > 0 ? this.state.KurumTipiSelectById[0].checkupOzneAdi : null));

			stateVars.isComp792509Visible = ((((ReactSystemFunctions.isNotEqualTo(ReactSystemFunctions.toString(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].rolename : this.state.MyContext?.length > 0 ? this.state.MyContext[0].rolename : null)), "Rol_Instructor") || ReactSystemFunctions.isNotEqualTo(ReactSystemFunctions.toString(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].kurumTipiKod : this.state.MyContext?.length > 0 ? this.state.MyContext[0].kurumTipiKod : null)), "KT_School")))) === true ? "visible" : "hidden")
			formVars.kirilim2list_172599_total = ReactSystemFunctions.value(this, (stateVars.Kirilim2Count?.length > 0 ? stateVars.Kirilim2Count[0].count : this.state.Kirilim2Count?.length > 0 ? this.state.Kirilim2Count[0].count : null));


	this.props.form.setFieldsValue(formVars);
	this.setState(stateVars);
	KuikaAppManager.hideSpinner(this);

            this.setPageInitLoaded(true);
            return isErrorOccurred;
        }




		Kirilim2ListComponent_416063_onPressEnter = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
KuikaAppManager.showSpinner(this);

				stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "kirilim2list_172599_value", 1, "current");

		this.props.form.setFieldsValue(formVars);
		
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.Kirilim2ListComponent_416063_onPressEnter1_();
                  resolve();
                });
          })

            
            return isErrorOccurred;
        }
    Kirilim2ListComponent_416063_onPressEnter1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
		KuikaAppManager.increaseServerRequestCount();


        let localVar = {
				searchFor_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "kirilim2list_416063_value", "value", "", "", "")), "string"),
				kurumId_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].kurumId : this.state.MyContext?.length > 0 ? this.state.MyContext[0].kurumId : null)), "Guid"),
				searchFor_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "kirilim2list_416063_value", "value", "", "", "")), "string"),
				currentpage_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "kirilim2list_172599_value", "current", "", "", "")), "number"),
				pagesize_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "kirilim2list_172599_value", "pageSize", "", "", "")), "number"),
				rolename_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].rolename : this.state.MyContext?.length > 0 ? this.state.MyContext[0].rolename : null)), "string"),
				profilId_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].lastChosenProfileId : this.state.MyContext?.length > 0 ? this.state.MyContext[0].lastChosenProfileId : null)), "Guid")
        }

		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "Kirilim2List/Kirilim2ListComponent_416063_onPressEnter1_", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.Kirilim2Count = result?.data.kirilim2Count;
			stateVars.Kirilim2Search = result?.data.kirilim2Search;
			
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.Kirilim2ListComponent_416063_onPressEnter2_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
		Kirilim2ListComponent_416063_onPressEnter2_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }

				
				stateVars.dataSource_399769 = this.state.Kirilim2Search;
				formVars.kirilim2list_172599_total = ReactSystemFunctions.value(this, (stateVars.Kirilim2Count?.length > 0 ? stateVars.Kirilim2Count[0].count : this.state.Kirilim2Count?.length > 0 ? this.state.Kirilim2Count[0].count : null));


		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }





		Kirilim2ListComponent_416063_onBlur = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
KuikaAppManager.showSpinner(this);

				stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "kirilim2list_172599_value", 1, "current");

		this.props.form.setFieldsValue(formVars);
		
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.Kirilim2ListComponent_416063_onBlur1_();
                  resolve();
                });
          })

            
            return isErrorOccurred;
        }
    Kirilim2ListComponent_416063_onBlur1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
		KuikaAppManager.increaseServerRequestCount();


        let localVar = {
				searchFor_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "kirilim2list_416063_value", "value", "", "", "")), "string"),
				kurumId_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].kurumId : this.state.MyContext?.length > 0 ? this.state.MyContext[0].kurumId : null)), "Guid"),
				searchFor_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "kirilim2list_416063_value", "value", "", "", "")), "string"),
				currentpage_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "kirilim2list_172599_value", "current", "", "", "")), "number"),
				pagesize_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "kirilim2list_172599_value", "pageSize", "", "", "")), "number"),
				rolename_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].rolename : this.state.MyContext?.length > 0 ? this.state.MyContext[0].rolename : null)), "string"),
				profilId_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].lastChosenProfileId : this.state.MyContext?.length > 0 ? this.state.MyContext[0].lastChosenProfileId : null)), "Guid")
        }

		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "Kirilim2List/Kirilim2ListComponent_416063_onBlur1_", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.Kirilim2Count = result?.data.kirilim2Count;
			stateVars.Kirilim2Search = result?.data.kirilim2Search;
			
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.Kirilim2ListComponent_416063_onBlur2_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
		Kirilim2ListComponent_416063_onBlur2_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }

				
				stateVars.dataSource_399769 = this.state.Kirilim2Search;
				formVars.kirilim2list_172599_total = ReactSystemFunctions.value(this, (stateVars.Kirilim2Count?.length > 0 ? stateVars.Kirilim2Count[0].count : this.state.Kirilim2Count?.length > 0 ? this.state.Kirilim2Count[0].count : null));


		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }





		Kirilim2ListComponent_814413_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
if (ReactSystemFunctions.isEmpty(ReactSystemFunctions.value(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].kirilim1Id : this.state.MyContext?.length > 0 ? this.state.MyContext[0].kirilim1Id : null)), null)) {
            KuikaAppManager.showSpinner(this);

				stateVars.Notify = await ReactSystemFunctions.notify(this, ReactSystemFunctions.translateCustomActions(this.ml,"70f41a89_39fc_eedc_72be_3ac73f75f9a6_notify",this.defaultML,"Aktif sezon olmadan yeni kayıt oluşturulamaz!" ), "danger", "bottom-right", 0, "", "", 0);

		this.props.form.setFieldsValue(formVars);
		
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.Kirilim2ListComponent_814413_onClick1_();
                  resolve();
                });
          })

        } else {
            
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.Kirilim2ListComponent_814413_onClick1_();
                  resolve();
                });
          })
        }

            
            return isErrorOccurred;
        }
		Kirilim2ListComponent_814413_onClick1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
if ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.value(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].kirilim1Id : this.state.MyContext?.length > 0 ? this.state.MyContext[0].kirilim1Id : null)), null))) {
            
					KuikaAppManager.prepareForNavigation();
						KuikaAppManager.addToPageInputVariables("Kirilim2Form", "Id", Guid.create().toString());

				stateVars.NAVIGATE = await ReactSystemFunctions.navigate(this, undefined, "Kirilim2List", "Kirilim2Form", "", "7d969ddf-5801-4eb6-b92b-12be35bdd565", "486227", null, "right", null, "450px", "vh", true);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

        } else {
            KuikaAppManager.hideSpinner(this);
        }

            
            return isErrorOccurred;
        }



		Kirilim2ListComponent_575480_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
KuikaAppManager.showSpinner(this);

					KuikaAppManager.prepareForNavigation();
						KuikaAppManager.addToPageInputVariables("SinifinOgrencileri", "Kirilim2Id", ReactSystemFunctions.value(this, "kirilim2list_399769_value", "id"));

				stateVars.NAVIGATE = await ReactSystemFunctions.navigate(this, undefined, "Kirilim2List", "SinifinOgrencileri", "", "7d969ddf-5801-4eb6-b92b-12be35bdd565", "111009", null, "right", null, "800px", "100vh", true);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }


    Kirilim2ListComponent_968629_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
        }

		KuikaAppManager.showSpinner(this);
		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "Kirilim2List/Kirilim2ListComponent_968629_onClick", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.EmptySheet2 = result?.data.emptySheet2;
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.Kirilim2ListComponent_968629_onClick1_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
		Kirilim2ListComponent_968629_onClick1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }

				stateVars.UploadFile = (await ReactSystemFunctions.uploadFileWithInfo() as any).data;

		this.props.form.setFieldsValue(formVars);
		
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.Kirilim2ListComponent_968629_onClick2_();
                  resolve();
                });
          })

            
            return isErrorOccurred;
        }
    Kirilim2ListComponent_968629_onClick2_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
		KuikaAppManager.increaseServerRequestCount();


        let localVar = {
				excelBase64_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, this.state.UploadFile?.data), "string"),
				tableName_0: ReactSystemFunctions.convertToTypeByName("sheet2", "string"),
				primaryKeyName_0: ReactSystemFunctions.convertToTypeByName("", "string"),
				profileId_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].lastChosenProfileId : this.state.MyContext?.length > 0 ? this.state.MyContext[0].lastChosenProfileId : null)), "Guid"),
				kurumId_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].kurumId : this.state.MyContext?.length > 0 ? this.state.MyContext[0].kurumId : null)), "Guid"),
				kirilim1Id_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].kirilim1Id : this.state.MyContext?.length > 0 ? this.state.MyContext[0].kirilim1Id : null)), "Guid"),
				kurumId_3: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].kurumId : this.state.MyContext?.length > 0 ? this.state.MyContext[0].kurumId : null)), "Guid"),
				searchFor_3: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "kirilim2list_416063_value", "value", "", "", "")), "string"),
				currentpage_3: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "kirilim2list_172599_value", "current", "", "", "")), "number"),
				pagesize_3: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "kirilim2list_172599_value", "pageSize", "", "", "")), "number"),
				rolename_3: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].rolename : this.state.MyContext?.length > 0 ? this.state.MyContext[0].rolename : null)), "string"),
				profilId_3: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].lastChosenProfileId : this.state.MyContext?.length > 0 ? this.state.MyContext[0].lastChosenProfileId : null)), "Guid")
        }

		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "Kirilim2List/Kirilim2ListComponent_968629_onClick2_", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.ImportExcelBase64 = result?.data.importExcelBase64;
			stateVars.Sheet2Select = result?.data.sheet2Select;
			stateVars.ImportDanisanFromSheet2 = result?.data.importDanisanFromSheet2;
			stateVars.Kirilim2Search = result?.data.kirilim2Search;
			
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.Kirilim2ListComponent_968629_onClick3_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
		Kirilim2ListComponent_968629_onClick3_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }

				
				stateVars.dataSource_399769 = this.state.Kirilim2Search;

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }







    Kirilim2ListComponent_741417_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				header_1: ReactSystemFunctions.convertToTypeByName("", "string"),
				footer_1: ReactSystemFunctions.convertToTypeByName("", "string")
        }

		KuikaAppManager.showSpinner(this);
		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "Kirilim2List/Kirilim2ListComponent_741417_onClick", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.SampleExcel2 = result?.data.sampleExcel2;
			stateVars.ExportExcel = result?.data.exportExcel;
			ReactSystemFunctions.downloadFile(result?.data?.exportExcel);
			this.props.form.setFieldsValue(formVars);
			this.setState(stateVars);
			KuikaAppManager.hideSpinner(this);



        this.setPageInitLoaded(true);
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }



		Kirilim2ListComponent_871482_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
KuikaAppManager.showSpinner(this);

					KuikaAppManager.prepareForNavigation();
						KuikaAppManager.addToPageInputVariables("TopluOlcumListSinif", "Kirilim2ID", ReactSystemFunctions.value(this, "kirilim2list_399769_value", "id"));
						KuikaAppManager.addToPageInputVariables("TopluOlcumListSinif", "KurumId", ReactSystemFunctions.value(this, "kirilim2list_399769_value", "kurumId"));
						KuikaAppManager.addToPageInputVariables("TopluOlcumListSinif", "CheckupDonemiID", ReactSystemFunctions.value(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].checkupDonemiId : this.state.MyContext?.length > 0 ? this.state.MyContext[0].checkupDonemiId : null)));
						KuikaAppManager.addToPageInputVariables("TopluOlcumListSinif", "Kirilim1ID", ReactSystemFunctions.value(this, "kirilim2list_399769_value", "kirilim1"));

				stateVars.NAVIGATE = await ReactSystemFunctions.navigate(this, undefined, "Kirilim2List", "TopluOlcumListSinif", "", "7d969ddf-5801-4eb6-b92b-12be35bdd565", "628113", null, "right", null, "460px", "100vh", true);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }


		Kirilim2ListComponent_860665_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
KuikaAppManager.showSpinner(this);

					KuikaAppManager.prepareForNavigation();
						KuikaAppManager.addToPageInputVariables("Kirilim2Form", "Id", ReactSystemFunctions.value(this, "kirilim2list_399769_value", "id"));

				stateVars.NAVIGATE = await ReactSystemFunctions.navigate(this, undefined, "Kirilim2List", "Kirilim2Form", "", "7d969ddf-5801-4eb6-b92b-12be35bdd565", "256479", null, "right", null, "450px", "", true);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }


    Kirilim2ListComponent_505853_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
		KuikaAppManager.increaseServerRequestCount();


        let localVar = {
				Kirilim2Id_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, "kirilim2list_399769_value", "id"), "Guid"),
				searchFor_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "kirilim2list_416063_value", "value", "", "", "")), "string"),
				kurumId_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].kurumId : this.state.MyContext?.length > 0 ? this.state.MyContext[0].kurumId : null)), "Guid"),
				searchFor_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "kirilim2list_416063_value", "value", "", "", "")), "string"),
				currentpage_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "kirilim2list_172599_value", "current", "", "", "")), "number"),
				pagesize_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "kirilim2list_172599_value", "pageSize", "", "", "")), "number"),
				rolename_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].rolename : this.state.MyContext?.length > 0 ? this.state.MyContext[0].rolename : null)), "string"),
				profilId_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].lastChosenProfileId : this.state.MyContext?.length > 0 ? this.state.MyContext[0].lastChosenProfileId : null)), "Guid")
        }

		KuikaAppManager.showSpinner(this);

            stateVars.Confirm = await Swal.fire({
                title: ReactSystemFunctions.translateCustomActions(this.ml,"fe852656_41cb_0d69_f310_feba936ad060_confirmation",this.defaultML,"Are you sure you want to delete this record?" ),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: localStorage.getItem("ml") === 'tr_TR' ? 'Onayla' : localStorage.getItem("ml") === 'fr_FR' ? 'Confirmer' : 'Confirm',
                cancelButtonText: localStorage.getItem("ml") === 'tr_TR' ? 'Vazgeç' : localStorage.getItem("ml") === 'fr_FR' ? 'Annuler' : 'Cancel',
                customClass: {
                  title: 'swal2-font-poppins',
                }
            })

            if (stateVars.Confirm.isConfirmed)
            {
                		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "Kirilim2List/Kirilim2ListComponent_505853_onClick", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.DeleteKirilim2 = result?.data.deleteKirilim2;
			stateVars.Kirilim2Count = result?.data.kirilim2Count;
			stateVars.Kirilim2Search = result?.data.kirilim2Search;
			
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.Kirilim2ListComponent_505853_onClick1_();
                  resolve();
                });
          })

            }


        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
		Kirilim2ListComponent_505853_onClick1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }

				
				stateVars.dataSource_399769 = this.state.Kirilim2Search;
				formVars.kirilim2list_172599_total = ReactSystemFunctions.value(this, (stateVars.Kirilim2Count?.length > 0 ? stateVars.Kirilim2Count[0].count : this.state.Kirilim2Count?.length > 0 ? this.state.Kirilim2Count[0].count : null));


		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }




    Kirilim2ListComponent_172599_onChange = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				searchFor_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "kirilim2list_416063_value", "value", "", "", "")), "string"),
				kurumId_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].kurumId : this.state.MyContext?.length > 0 ? this.state.MyContext[0].kurumId : null)), "Guid"),
				searchFor_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "kirilim2list_416063_value", "value", "", "", "")), "string"),
				currentpage_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "kirilim2list_172599_value", "current", "", "", "")), "number"),
				pagesize_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "kirilim2list_172599_value", "pageSize", "", "", "")), "number"),
				rolename_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].rolename : this.state.MyContext?.length > 0 ? this.state.MyContext[0].rolename : null)), "string"),
				profilId_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].lastChosenProfileId : this.state.MyContext?.length > 0 ? this.state.MyContext[0].lastChosenProfileId : null)), "Guid")
        }

		KuikaAppManager.showSpinner(this);
		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "Kirilim2List/Kirilim2ListComponent_172599_onChange", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.Kirilim2Count = result?.data.kirilim2Count;
			stateVars.Kirilim2Search = result?.data.kirilim2Search;
			
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.Kirilim2ListComponent_172599_onChange1_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
		Kirilim2ListComponent_172599_onChange1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }

				
				stateVars.dataSource_399769 = this.state.Kirilim2Search;
				formVars.kirilim2list_172599_total = ReactSystemFunctions.value(this, (stateVars.Kirilim2Count?.length > 0 ? stateVars.Kirilim2Count[0].count : this.state.Kirilim2Count?.length > 0 ? this.state.Kirilim2Count[0].count : null));


		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }






    async callCallbackFunction(diId) {
        let isErrorOccurred = false;
		let pageInitCallerNavDiIDs = [486227, 111009, 628113, 256479] as number[];
		if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
			await this.Kirilim2ListPageInit();
		}

    }
}
