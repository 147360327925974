import Axios, { AxiosResponse } from "axios";
import { KuikaAppManager } from "../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../shared/utilty/main-functions";

const backendUrl = KuikaAppManager.GetBackendUrl();

export class ImageLibraryService {
  public static async GetById(id: string): Promise<AxiosResponse<string>> {
    try {
      const res = await Axios.get<null, AxiosResponse>(`${backendUrl}/resource/byid`, {
        params: {
          resourceId: id
        }
        // Commented due to caching is blocking image change after updating image on drawer
        // headers: {
        //   "cache-control": "max-age=31536000"
        // }
      });
      return res;
    } catch (error: any) {
      KMainFunctions.exceptionHandler(error);
      return error;
    }
  }

  public static async SaveResource(resource: any): Promise<AxiosResponse<string>> {
    try {
      const res = await Axios.post<null, AxiosResponse>(`${backendUrl}/resource`, resource);
      return res;
    } catch (error: any) {
      KMainFunctions.exceptionHandler(error);
      return error;
    }
  }

  public static async RuntimeGetById(resource: string): Promise<AxiosResponse<string>> {
    try {
      const res = await Axios.get<null, AxiosResponse>(`${backendUrl}/resource/runtime/byid`, {
        params: {
          resource
        }
      });
      return res;
    } catch (error: any) {
      KMainFunctions.exceptionHandler(error);
      return error;
    }
  }
}
