import { Checkbox } from "antd/es";
import { CheckboxProps } from "antd/es/checkbox";
import { Guid } from "guid-typescript";
import _ from "lodash";
import { PureComponent, ReactNode } from "react";
import { CommonProps } from "../../../components/web-components/common/common-props";
import withCommonEvents from "../../../shared/hoc/with-common-events";

declare let window: Window & { kuika: any };

interface IKCheckboxProps extends Omit<CheckboxProps, "onChange"> {
  onChange?: (value?: boolean) => void;
  boxColor?: string;
  boxColorOnChecked?: string;
  checkIconColor?: string;
  label?: string;
}

interface IKCheckboxState {
  uniqueKey: Guid;
}

class KCheckbox extends PureComponent<IKCheckboxProps & CommonProps, IKCheckboxState> {
  private memoizedDynamicCssResult = "";

  constructor(props: IKCheckboxProps) {
    super(props);
    this.state = {
      uniqueKey: Guid.create()
    };
    const isDesignTime = window.kuika?.isDesignTime;
    if (!isDesignTime) {
    }
  }

  componentDidMount = () => {
    this.setDynamicStyle();
  };

  getChecked = () => {
    const value = this.props.checked ?? this.props.value;
    if (value === undefined || value === null) return false;
    if (value !== undefined) {
      if (value.toString() === "") return false;
      if (value.toString() === "1") return true;
      if (value.toString() === "0") return false;
      if (value.toString() === "true") return true;
      if (value.toString() === "false") return false;
    }
  };

  setDynamicStyle = () => {
    const uniquekey = this.state.uniqueKey?.toString();
    if (!uniquekey) {
      return;
    }
    const isDesignTime = window.kuika?.isDesignTime;
    if (this.memoizedDynamicCssResult !== "" && !isDesignTime) {
      return this.memoizedDynamicCssResult;
    }
    const dynamic_style = document.getElementById("dynamic_style");
    if (dynamic_style && dynamic_style.innerHTML?.indexOf(uniquekey) === -1) {
      const generatedCss = this.getDynamicCss();
      dynamic_style.innerHTML = `${dynamic_style.innerHTML}
        ${generatedCss}`;
      this.memoizedDynamicCssResult = generatedCss;
    }
  };

  getUniqueClassName = () => {
    let result = "";

    if (!this.state.uniqueKey) {
      return result;
    }
    result = `k_checkbox_${this.state.uniqueKey.toString().substring(0, 8)}`;
    return result;
  };

  getDynamicCss() {
    const className: string = this.getUniqueClassName();
    let { boxColor, boxColorOnChecked, checkIconColor } = this.props;
    if (!className || className.length === 0) {
      return "";
    }

    boxColor = boxColor || "#fffff";
    boxColorOnChecked = boxColorOnChecked || "#246dff";
    checkIconColor = checkIconColor || "#246dff";

    let result = "";
    result += `.k_checkbox.${className.trim()} .ant-checkbox .ant-checkbox-inner {
          background-color: ${boxColor} !important;
          border-color: ${boxColorOnChecked};

        }`;

    result += `.k_checkbox.${className.trim()} .ant-checkbox-checked::after {
          border-color: ${boxColorOnChecked} !important;
        }`;

    result += `.k_checkbox.${className.trim()} .ant-checkbox-checked .ant-checkbox-inner {
          background-color: ${boxColorOnChecked} !important;
        }`;

    result += `.k_checkbox.${className.trim()} .ant-checkbox .ant-checkbox-inner::after {
         border-color: ${checkIconColor} !important;
        }`;

    return result;
  }

  getProps = () => {
    const style = _.cloneDeep(this.props.style);

    if (style.display === "block") {
      style.display = "flex";
    } else {
      style.display = "inline-flex";
    }

    switch (style.textAlign as string) {
      case "-webkit-right": {
        style.justifyContent = "flex-end";
        break;
      }
      case "-webkit-center": {
        style.justifyContent = "center";
        break;
      }
      case "-webkit-left":
      default: {
        style.justifyContent = "flex-start";
        break;
      }
    }

    return {
      className: `k_checkbox ${this.getUniqueClassName()}`,
      style
    };
  };

  render(): ReactNode {
    return (
      <div {...this.getProps()}>
        <Checkbox
          onChange={(e) => {
            if (this.props.onChange) this.props.onChange(e.target.checked);
          }}
          checked={this.getChecked()}
        >
          {(this.props.label ?? this.props.children) || undefined}
        </Checkbox>
      </div>
    );
  }
}

const kCheckbox = withCommonEvents(KCheckbox);
export { kCheckbox as KCheckbox };
