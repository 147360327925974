import React, { PureComponent, ReactNode } from "react";
import ReactBarcode from "react-barcode";
import ReactDOM from "react-dom";
import withCommonEvents from "../../../shared/hoc/with-common-events";
import { DashboardState } from "../kuika-cl-model-runtimes";

declare let window: any;
interface BarcodeRendererProps {
  value: string;
  style: React.CSSProperties;
  format: string;
}

interface BarcodeRendererState {}

class BarcodeRenderer extends PureComponent<BarcodeRendererProps & BarcodeRendererState> {
  constructor(props: BarcodeRendererProps) {
    super(props);
    this.state = {};
  }

  componentDidMount(): void {
    const barcodeRenderer = ReactDOM.findDOMNode(this) as Element;
    const svg = barcodeRenderer.querySelector("svg");

    if (!svg || window?.kuika?.dashboardState !== DashboardState.reportDesigner) {
      return;
    }

    const { style } = this.props;

    const setAttribute = (name: string, value: string) => {
      svg.setAttribute(name, value);
      barcodeRenderer.setAttribute(name, value);
    };

    if (barcodeRenderer.parentElement?.classList.contains("kuika_absoluteDesignTableColumnChild")) {
      setAttribute("width", style.width ? style.width.toString() : "100%");
      setAttribute("height", style.height ? style.height.toString() : "96px");
    } else {
      setAttribute("width", "100%");
      setAttribute("height", "100%");
    }
  }

  getNumbersOfStyleProperties = (val: any) => {
    let numb: any = val?.match(/\d/g);
    numb = numb?.join("");
    return numb;
  };

  getFormat = (): any => {
    let { format } = this.props;
    if (
      window.kuika?.dashboardState === DashboardState.design ||
      window.kuika?.dashboardState === DashboardState.reportDesigner
    ) {
      format = "CODE128";
      return format;
    }
    return format;
  };

  getValue = (): any => {
    let { value } = this.props;
    if (window.kuika.isDesignTime) {
      value = "123456789";
      return value;
    }
    return value;
  };

  renderBarcodeSvg = (value: string, style: React.CSSProperties, ignoreWidthHeight: boolean) => {
    return (
      <ReactBarcode
        value={this.getValue()}
        format={this.getFormat()}
        width={ignoreWidthHeight ? undefined : style.width ? this.getNumbersOfStyleProperties(style.width) / 100 : 2}
        height={ignoreWidthHeight ? undefined : this.getNumbersOfStyleProperties(style.height)}
        font={style.fontFamily}
        fontOptions={style.fontWeight as string}
        textAlign={style.textAlign}
        fontSize={this.getNumbersOfStyleProperties(style.fontSize)}
        background={style.backgroundColor}
        lineColor={style.color}
        margin={style.padding as number}
        marginLeft={style.paddingLeft as number}
        marginRight={style.paddingRight as number}
        marginTop={style.paddingTop as number}
        marginBottom={style.paddingBottom as number}
      />
    );
  };

  render(): ReactNode {
    const { value, style } = this.props;
    if (window.kuika?.dashboardState === DashboardState.reportDesigner) {
      return <div className="kuika_barcode-renderer">{this.renderBarcodeSvg(value, style, true)}</div>;
    }
    return <>{this.renderBarcodeSvg(value, style, false)}</>;
  }
}

const barcodeRenderer = withCommonEvents(BarcodeRenderer);
export { barcodeRenderer as BarcodeRenderer };
