import React from "react";
import Axios, { AxiosResponse } from "axios";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { KNavigator } from "../../shared/hoc/with-history";
import { KContext } from "../../shared/hoc/with-context";
import { useLocation } from "react-router-dom";
import { FormInstance } from "antd/es/form";
import { Guid } from "guid-typescript";
import Swal from "sweetalert2";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import moment from "moment";
import { IValidationData } from "../../models/dto/validation-data";
import { IStripePayment } from "../../models/dto/stripe-payment";
import { IUserInfoDto } from "../../models/dto/user-info.dto";
import { UserService } from "../../services/user-service";
import _ from "lodash";
import mqtt, { MqttClient } from "mqtt";
import { IMqttResult } from "../../models/dto/mqtt.dto";

declare let window: any;

export interface IRandevuSlot_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

export interface IRandevuSlot_ScreenState {
    isSpinnerVisible: boolean;
    isPageVisible: boolean;
	isLeftMenuVisible: boolean;
    spinnerCount: number;
    selectedLanguage: string;
    isPageInitLoaded: false;
    isPageRecurringLoaded: false;
	GoBack: any;
	GoBack_dummy: any;
	EgitmenById: any[];
	EgitmenById_dummy: any[];
	MerkezById: any[];
	MerkezById_dummy: any[];
	HizmetById: any[];
	HizmetById_dummy: any[];
	KisiById: any[];
	KisiById_dummy: any[];
	CalendarDate: any[];
	CalendarDate_dummy: any[];
	ProfilByKisiAndKurum: any[];
	ProfilByKisiAndKurum_dummy: any[];
	Gunler: any[];
	Gunler_dummy: any[];
	Slotlar: any[];
	Slotlar_dummy: any[];
	EnsureSlots: number;
	EnsureSlots_dummy: number;
	ChangeEnabledOf: string;
	ChangeEnabledOf_dummy: string;
	SetValueOf: string;
	SetValueOf_dummy: string;
	EgitmenMusaitSlotlar: any[];
	EgitmenMusaitSlotlar_dummy: any[];
	SaveRecord: number;
	SaveRecord_dummy: number;
	RandevuById: any[];
	RandevuById_dummy: any[];
	SendMail: boolean;
	SendMail_dummy: boolean;
	isComp974339Visible: 'visible' | 'hidden';
	isComp624330Visible: 'visible' | 'hidden';
	isComp219668Visible: 'visible' | 'hidden';
	isComp66646Visible: 'visible' | 'hidden';
	isComp508934Visible: 'visible' | 'hidden';
	isComp341303Enabled: 'enabled' | 'disabled';


}

export class RandevuSlot_ScreenBase extends React.PureComponent<IRandevuSlot_ScreenProps, any> {
	randevuslot_771304_value_kuikaGalleryViewRef: React.RefObject<any>;
	randevuslot_631137_value_kuikaFlexGridRef: React.RefObject<any>;
	randevuslot_508934_value_kuikaDateRef: React.RefObject<any>;
    ml=[{"Id":"423ed54a-d6f3-fccb-7d3e-72c7b24c8b7a","Name":"tr_TR","ShortName":"Turkish","IsDefault":true,"LanguagePhrases":[]},{"Id":"74503767-9450-4cae-9db5-729d038bcb36","Name":"en_US","ShortName":"English","LanguagePhrases":[]},{"Id":"3581fb86-d09c-41b4-9466-6e7af60ae05d","Name":"FixedValues","ShortName":"FixedValues","LanguagePhrases":[{"Id":796540,"PropertyName":"value","Value":"Randevu"},{"Id":561211,"PropertyName":"value","Value":"Hizmet"},{"Id":582819,"PropertyName":"value","Value":"Süre"},{"Id":489099,"PropertyName":"value","Value":"dk"},{"Id":941093,"PropertyName":"value","Value":"Eğitmen"},{"Id":404525,"PropertyName":"value","Value":"Yaş"},{"Id":110606,"PropertyName":"value","Value":"Merkez"},{"Id":666341,"PropertyName":"value","Value":"Randevu zamanını seçiniz..."},{"Id":453497,"PropertyName":"value","Value":"Pts"},{"Id":549666,"PropertyName":"value","Value":"Sal"},{"Id":538078,"PropertyName":"value","Value":"Çar"},{"Id":67644,"PropertyName":"value","Value":"Per"},{"Id":512077,"PropertyName":"value","Value":"Cum"},{"Id":379917,"PropertyName":"value","Value":"Cts"},{"Id":536196,"PropertyName":"value","Value":"Paz"},{"Id":771304,"PropertyName":"nodatafoundmessage","Value":"No data found."},{"Id":974339,"PropertyName":"value","Value":"[datafield:celltxt]"},{"Id":624330,"PropertyName":"value","Value":"[datafield:celltxt]"},{"Id":245775,"PropertyName":"value","Value":"Randevu saatini seçiniz."},{"Id":631137,"PropertyName":"nodatafoundmessage","Value":"Gün seçiniz."},{"Id":690931,"PropertyName":"value","Value":"[datafield:randevusaati]"},{"Id":15256,"PropertyName":"value","Value":"[datafield:randevusaati]"},{"Id":508934,"PropertyName":"placeholder","Value":"Select Date..."},{"Id":341303,"PropertyName":"label","Value":"TAMAMLA"}]}]
    defaultML = "tr_TR"
    
    screenHash = ""
    
    constructor(props) {
        super(props);
		this.randevuslot_771304_value_kuikaGalleryViewRef = React.createRef();
		this.randevuslot_631137_value_kuikaFlexGridRef = React.createRef();
		this.randevuslot_508934_value_kuikaDateRef = React.createRef();

        this.state = {
            isSpinnerVisible: false,
            isPageVisible: false,
            isLeftMenuVisible: false,
            spinnerCount: 0,
            selectedLanguage: "",
            isPageInitLoaded: false,
            isPageRecurringLoaded: false,
            	        GoBack: "",
	        EgitmenById: [],
	        MerkezById: [],
	        HizmetById: [],
	        KisiById: [],
	        CalendarDate: [],
	        ProfilByKisiAndKurum: [],
	        Gunler: [],
	        Slotlar: [],
	        EnsureSlots: 0,
	        ChangeEnabledOf: "",
	        SetValueOf: "",
	        EgitmenMusaitSlotlar: [],
	        SaveRecord: false,
	        RandevuById: [],
	        SendMail: false,
	        isComp974339Visible: 'hidden',
	        isComp624330Visible: 'hidden',
	        isComp219668Visible: 'hidden',
	        isComp66646Visible: 'hidden',
	        isComp508934Visible: 'hidden',
	        isComp341303Enabled: 'enabled',

            
        }

        this.state.isPageVisible = true;
        
    }

    async componentDidMount() {

        if (KuikaAppManager.isPageInitPrevented()) {
            KuikaAppManager.calculateAndSetBodyHeight("randevuslot", "");
            return;
        }
        
        ReactSystemFunctions.validateToken()
        if (true){
            await this.RandevuSlotPageInit();
        }
        
        
        
        KuikaAppManager.calculateAndSetBodyHeight("randevuslot", "");
    }

    componentWillUnmount() {
        
        
        
    }

    

        
    componentDidUpdate= async (prevProps, prevState) => {
		KuikaAppManager.calculateAndSetBodyHeight("randevuslot", ""    );
        if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
			await this.RandevuSlotPageInit();
		}

        
	}

    fillFormInitValues() {
        this.props.form.setFieldsValue({
randevuslot_905830_value: this.state.HizmetById?.at?.(0)?.image ?? undefined,
randevuslot_237710_value: this.state.EgitmenById?.at?.(0)?.resim ?? undefined,
randevuslot_804001_value: this.state.EgitmenById?.at?.(0)?.rate ?? undefined,
randevuslot_52143_value: this.state.MerkezById?.at?.(0)?.logo ?? undefined,
randevuslot_204211_value: this.state.MerkezById?.at?.(0)?.rate ?? undefined
});

    }

      setPageInitLoaded = (value: boolean) => {
        this.setState({ isPageInitLoaded: value });
      }

      setPageRecurringLoaded = (value: boolean) => {
        this.setState({ isPageRecurringLoaded: value });
      }

      clearFieldValidation = (changedValues: any) => {
        const fieldName = Object.keys(changedValues)[0];
        if (fieldName) {
          this.props.form.setFields([
            {
              name: fieldName,
              errors: []
            }
          ]);
        }
      };

      

      

    RandevuSlotPageInit = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }

        let localVar = {
			kisiId_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.EgitmenId ?? this.props.screenInputs.egitmenid, "Guid"),
			merkezId_1: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.MerkezId ?? this.props.screenInputs.merkezid, "Guid"),
			Id_2: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.HizmetId ?? this.props.screenInputs.hizmetid, "Guid"),
			Id_3: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.DanisanKisiId ?? this.props.screenInputs.danisankisiid, "Guid"),
			datee_4: ReactSystemFunctions.convertToTypeByName(moment().format("YYYY-MM-DDTHH:mm:ss"), "Date"),
			addDays_4: ReactSystemFunctions.convertToTypeByName(0, "number"),
			kisiId_5: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.EgitmenId ?? this.props.screenInputs.egitmenid, "Guid"),
			kurumId_5: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.MerkezId ?? this.props.screenInputs.merkezid, "Guid"),
			seciliGun_6: ReactSystemFunctions.convertToTypeByName(null, "number"),
			randevuId_7: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.RandevuId ?? this.props.screenInputs.randevuid, "Guid")
        }

	KuikaAppManager.showSpinner(this);
	
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "RandevuSlot/RandevuSlotPageInit", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

		stateVars.EgitmenById = result?.data.egitmenById;
		formVars.randevuslot_237710_value = ReactSystemFunctions.toString(this, (stateVars.EgitmenById?.length > 0 ? stateVars.EgitmenById[0].resim : this.state.EgitmenById?.length > 0 ? this.state.EgitmenById[0].resim : null));
		formVars.randevuslot_237710_placeholderimage = "";
		formVars.randevuslot_51787_value = ReactSystemFunctions.toString(this, (stateVars.EgitmenById?.length > 0 ? stateVars.EgitmenById[0].fullname : this.state.EgitmenById?.length > 0 ? this.state.EgitmenById[0].fullname : null));
		formVars.randevuslot_527942_value = ReactSystemFunctions.toString(this, (stateVars.EgitmenById?.length > 0 ? stateVars.EgitmenById[0].unvan : this.state.EgitmenById?.length > 0 ? this.state.EgitmenById[0].unvan : null));
		formVars.randevuslot_356614_value = ReactSystemFunctions.toString(this, (stateVars.EgitmenById?.length > 0 ? stateVars.EgitmenById[0].yas : this.state.EgitmenById?.length > 0 ? this.state.EgitmenById[0].yas : null));
		stateVars.MerkezById = result?.data.merkezById;
		formVars.randevuslot_52143_value = ReactSystemFunctions.toString(this, (stateVars.MerkezById?.length > 0 ? stateVars.MerkezById[0].logo : this.state.MerkezById?.length > 0 ? this.state.MerkezById[0].logo : null));
		formVars.randevuslot_52143_placeholderimage = "";
		formVars.randevuslot_964169_value = ReactSystemFunctions.toString(this, (stateVars.MerkezById?.length > 0 ? stateVars.MerkezById[0].unvani : this.state.MerkezById?.length > 0 ? this.state.MerkezById[0].unvani : null));
		formVars.randevuslot_635037_value = ReactSystemFunctions.toString(this, (stateVars.MerkezById?.length > 0 ? stateVars.MerkezById[0].acikAdres : this.state.MerkezById?.length > 0 ? this.state.MerkezById[0].acikAdres : null));
		stateVars.HizmetById = result?.data.hizmetById;
		formVars.randevuslot_905830_value = ReactSystemFunctions.toString(this, (stateVars.HizmetById?.length > 0 ? stateVars.HizmetById[0].image : this.state.HizmetById?.length > 0 ? this.state.HizmetById[0].image : null));
		formVars.randevuslot_174736_value = ReactSystemFunctions.toString(this, (stateVars.HizmetById?.length > 0 ? stateVars.HizmetById[0].title : this.state.HizmetById?.length > 0 ? this.state.HizmetById[0].title : null));
		formVars.randevuslot_883874_value = ReactSystemFunctions.toString(this, (stateVars.HizmetById?.length > 0 ? stateVars.HizmetById[0].aciklama : this.state.HizmetById?.length > 0 ? this.state.HizmetById[0].aciklama : null));
		formVars.randevuslot_348950_value = ReactSystemFunctions.toString(this, (stateVars.HizmetById?.length > 0 ? stateVars.HizmetById[0].sure : this.state.HizmetById?.length > 0 ? this.state.HizmetById[0].sure : null));
		stateVars.KisiById = result?.data.kisiById;
		stateVars.CalendarDate = result?.data.calendarDate;
		formVars.randevuslot_734085_value = ReactSystemFunctions.toString(this, (stateVars.CalendarDate?.length > 0 ? stateVars.CalendarDate[0].calendarMonth : this.state.CalendarDate?.length > 0 ? this.state.CalendarDate[0].calendarMonth : null));
		stateVars.ProfilByKisiAndKurum = result?.data.profilByKisiAndKurum;
		stateVars.Gunler = result?.data.gunler;
		
		stateVars.Slotlar = result?.data.slotlar;
		stateVars.EnsureSlots = result?.data.ensureSlots;
		this.props.form.setFieldsValue(formVars);
		
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.RandevuSlotPageInit1_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
	RandevuSlotPageInit1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }

			stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, 'isComp341303Enabled', "disabled");
			stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "randevuslot_508934_value", ReactSystemFunctions.value(this, (stateVars.CalendarDate?.length > 0 ? stateVars.CalendarDate[0].datee : this.state.CalendarDate?.length > 0 ? this.state.CalendarDate[0].datee : null)), null);
			formVars.randevuslot_905830_value = ReactSystemFunctions.toString(this, (stateVars.HizmetById?.length > 0 ? stateVars.HizmetById[0].image : this.state.HizmetById?.length > 0 ? this.state.HizmetById[0].image : null));

			formVars.randevuslot_174736_value = ReactSystemFunctions.toString(this, (stateVars.HizmetById?.length > 0 ? stateVars.HizmetById[0].title : this.state.HizmetById?.length > 0 ? this.state.HizmetById[0].title : null));

			formVars.randevuslot_883874_value = ReactSystemFunctions.toString(this, (stateVars.HizmetById?.length > 0 ? stateVars.HizmetById[0].aciklama : this.state.HizmetById?.length > 0 ? this.state.HizmetById[0].aciklama : null));

			formVars.randevuslot_348950_value = ReactSystemFunctions.toString(this, (stateVars.HizmetById?.length > 0 ? stateVars.HizmetById[0].sure : this.state.HizmetById?.length > 0 ? this.state.HizmetById[0].sure : null));

			formVars.randevuslot_237710_value = ReactSystemFunctions.toString(this, (stateVars.EgitmenById?.length > 0 ? stateVars.EgitmenById[0].resim : this.state.EgitmenById?.length > 0 ? this.state.EgitmenById[0].resim : null));

			formVars.randevuslot_51787_value = ReactSystemFunctions.toString(this, (stateVars.EgitmenById?.length > 0 ? stateVars.EgitmenById[0].fullname : this.state.EgitmenById?.length > 0 ? this.state.EgitmenById[0].fullname : null));

			formVars.randevuslot_527942_value = ReactSystemFunctions.toString(this, (stateVars.EgitmenById?.length > 0 ? stateVars.EgitmenById[0].unvan : this.state.EgitmenById?.length > 0 ? this.state.EgitmenById[0].unvan : null));

			formVars.randevuslot_356614_value = ReactSystemFunctions.toString(this, (stateVars.EgitmenById?.length > 0 ? stateVars.EgitmenById[0].yas : this.state.EgitmenById?.length > 0 ? this.state.EgitmenById[0].yas : null));

			formVars.randevuslot_804001_value = ReactSystemFunctions.value(this, (stateVars.EgitmenById?.length > 0 ? stateVars.EgitmenById[0].rate : this.state.EgitmenById?.length > 0 ? this.state.EgitmenById[0].rate : null));

			formVars.randevuslot_52143_value = ReactSystemFunctions.toString(this, (stateVars.MerkezById?.length > 0 ? stateVars.MerkezById[0].logo : this.state.MerkezById?.length > 0 ? this.state.MerkezById[0].logo : null));

			formVars.randevuslot_964169_value = ReactSystemFunctions.toString(this, (stateVars.MerkezById?.length > 0 ? stateVars.MerkezById[0].unvani : this.state.MerkezById?.length > 0 ? this.state.MerkezById[0].unvani : null));

			formVars.randevuslot_635037_value = ReactSystemFunctions.toString(this, (stateVars.MerkezById?.length > 0 ? stateVars.MerkezById[0].acikAdres : this.state.MerkezById?.length > 0 ? this.state.MerkezById[0].acikAdres : null));

			formVars.randevuslot_204211_value = ReactSystemFunctions.value(this, (stateVars.MerkezById?.length > 0 ? stateVars.MerkezById[0].rate : this.state.MerkezById?.length > 0 ? this.state.MerkezById[0].rate : null));

			formVars.randevuslot_734085_value = ReactSystemFunctions.toString(this, (stateVars.CalendarDate?.length > 0 ? stateVars.CalendarDate[0].calendarMonth : this.state.CalendarDate?.length > 0 ? this.state.CalendarDate[0].calendarMonth : null));

			
			stateVars.dataSource_771304 = this.state.Gunler;
			stateVars.isComp974339Visible = ((ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, "randevuslot_771304_value", "isDisabled"), "1")) === true ? "visible" : "hidden")
			stateVars.isComp624330Visible = ((ReactSystemFunctions.isNotEqualTo(ReactSystemFunctions.value(this, "randevuslot_771304_value", "isDisabled"), "1")) === true ? "visible" : "hidden")
			stateVars.isComp219668Visible = ((ReactSystemFunctions.isNotEqualTo(ReactSystemFunctions.value(this, "randevuslot_631137_value", "statu"), "0")) === true ? "visible" : "hidden")
			stateVars.isComp66646Visible = ((ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, "randevuslot_631137_value", "statu"), "0")) === true ? "visible" : "hidden")

	this.props.form.setFieldsValue(formVars);
	this.setState(stateVars);
	KuikaAppManager.hideSpinner(this);

            this.setPageInitLoaded(true);
            return isErrorOccurred;
        }




		RandevuSlotComponent_968274_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
KuikaAppManager.showSpinner(this);

				stateVars.GoBack = await ReactSystemFunctions.goBack(this);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }


    RandevuSlotComponent_714619_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				datee_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "randevuslot_508934_value", "value", "", "", "")), "Date"),
				addDays_0: ReactSystemFunctions.convertToTypeByName(-1, "number"),
				profileId_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.ProfilByKisiAndKurum?.length > 0 ? stateVars.ProfilByKisiAndKurum[0].profilId : this.state.ProfilByKisiAndKurum?.length > 0 ? this.state.ProfilByKisiAndKurum[0].profilId : null)), "Guid"),
				seciliGun_1: ReactSystemFunctions.convertToTypeByName(null, "number")
        }

		KuikaAppManager.showSpinner(this);
		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "RandevuSlot/RandevuSlotComponent_714619_onClick", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.CalendarDate = result?.data.calendarDate;
			formVars.randevuslot_734085_value = ReactSystemFunctions.toString(this, (stateVars.CalendarDate?.length > 0 ? stateVars.CalendarDate[0].calendarMonth : this.state.CalendarDate?.length > 0 ? this.state.CalendarDate[0].calendarMonth : null));
			stateVars.Gunler = result?.data.gunler;
			
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.RandevuSlotComponent_714619_onClick1_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
		RandevuSlotComponent_714619_onClick1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }

				stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "randevuslot_508934_value", ReactSystemFunctions.value(this, (stateVars.CalendarDate?.length > 0 ? stateVars.CalendarDate[0].datee : this.state.CalendarDate?.length > 0 ? this.state.CalendarDate[0].datee : null)), null);
				formVars.randevuslot_734085_value = ReactSystemFunctions.toString(this, (stateVars.CalendarDate?.length > 0 ? stateVars.CalendarDate[0].calendarMonth : this.state.CalendarDate?.length > 0 ? this.state.CalendarDate[0].calendarMonth : null));

				
				stateVars.dataSource_771304 = this.state.Gunler;

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }




    RandevuSlotComponent_265614_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				datee_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "randevuslot_508934_value", "value", "", "", "")), "Date"),
				addDays_0: ReactSystemFunctions.convertToTypeByName(1, "number"),
				profileId_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.ProfilByKisiAndKurum?.length > 0 ? stateVars.ProfilByKisiAndKurum[0].profilId : this.state.ProfilByKisiAndKurum?.length > 0 ? this.state.ProfilByKisiAndKurum[0].profilId : null)), "Guid"),
				seciliGun_1: ReactSystemFunctions.convertToTypeByName(null, "number")
        }

		KuikaAppManager.showSpinner(this);
		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "RandevuSlot/RandevuSlotComponent_265614_onClick", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.CalendarDate = result?.data.calendarDate;
			formVars.randevuslot_734085_value = ReactSystemFunctions.toString(this, (stateVars.CalendarDate?.length > 0 ? stateVars.CalendarDate[0].calendarMonth : this.state.CalendarDate?.length > 0 ? this.state.CalendarDate[0].calendarMonth : null));
			stateVars.Gunler = result?.data.gunler;
			
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.RandevuSlotComponent_265614_onClick1_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
		RandevuSlotComponent_265614_onClick1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }

				stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "randevuslot_508934_value", ReactSystemFunctions.value(this, (stateVars.CalendarDate?.length > 0 ? stateVars.CalendarDate[0].datee : this.state.CalendarDate?.length > 0 ? this.state.CalendarDate[0].datee : null)), null);
				formVars.randevuslot_734085_value = ReactSystemFunctions.toString(this, (stateVars.CalendarDate?.length > 0 ? stateVars.CalendarDate[0].calendarMonth : this.state.CalendarDate?.length > 0 ? this.state.CalendarDate[0].calendarMonth : null));

				
				stateVars.dataSource_771304 = this.state.Gunler;

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }




    RandevuSlotComponent_146203_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				profileId_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.ProfilByKisiAndKurum?.length > 0 ? stateVars.ProfilByKisiAndKurum[0].profilId : this.state.ProfilByKisiAndKurum?.length > 0 ? this.state.ProfilByKisiAndKurum[0].profilId : null)), "Guid"),
				seciliGun_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, "randevuslot_771304_value", "cellTxt"), "number"),
				seciliAy_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.CalendarDate?.length > 0 ? stateVars.CalendarDate[0].month : this.state.CalendarDate?.length > 0 ? this.state.CalendarDate[0].month : null)), "number"),
				seciliYil_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.CalendarDate?.length > 0 ? stateVars.CalendarDate[0].year : this.state.CalendarDate?.length > 0 ? this.state.CalendarDate[0].year : null)), "number")
        }

		KuikaAppManager.showSpinner(this);
if (ReactSystemFunctions.isNotEqualTo(ReactSystemFunctions.value(this, "randevuslot_771304_value", "isDisabled"), "1")) {
            		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "RandevuSlot/RandevuSlotComponent_146203_onClick", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.Gunler = result?.data.gunler;
			
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.RandevuSlotComponent_146203_onClick1_();
                  resolve();
                });
          })

        } else {
            
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.RandevuSlotComponent_146203_onClick1_();
                  resolve();
                });
          })
        }


        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
		RandevuSlotComponent_146203_onClick1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }

				stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, 'isComp341303Enabled', "disabled");

		this.props.form.setFieldsValue(formVars);
		
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.RandevuSlotComponent_146203_onClick2_();
                  resolve();
                });
          })

            
            return isErrorOccurred;
        }
    RandevuSlotComponent_146203_onClick2_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				profileId_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.ProfilByKisiAndKurum?.length > 0 ? stateVars.ProfilByKisiAndKurum[0].profilId : this.state.ProfilByKisiAndKurum?.length > 0 ? this.state.ProfilByKisiAndKurum[0].profilId : null)), "Guid"),
				seciliRandevuSaati_0: ReactSystemFunctions.convertToTypeByName(null, "string"),
				date_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.Gunler?.length > 0 ? stateVars.Gunler[0].seciliTarih : this.state.Gunler?.length > 0 ? this.state.Gunler[0].seciliTarih : null)), "Date")
        }

if (ReactSystemFunctions.isNotEqualTo(ReactSystemFunctions.value(this, "randevuslot_771304_value", "isDisabled"), "1")) {
            		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "RandevuSlot/RandevuSlotComponent_146203_onClick2_", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.EgitmenMusaitSlotlar = result?.data.egitmenMusaitSlotlar;
			
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.RandevuSlotComponent_146203_onClick3_();
                  resolve();
                });
          })

        } else {
            
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.RandevuSlotComponent_146203_onClick3_();
                  resolve();
                });
          })
        }


        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
		RandevuSlotComponent_146203_onClick3_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
if (ReactSystemFunctions.isNotEqualTo(ReactSystemFunctions.value(this, "randevuslot_771304_value", "isDisabled"), "1")) {
            
				

		this.props.form.setFieldsValue(formVars);
		
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.RandevuSlotComponent_146203_onClick4_();
                  resolve();
                });
          })

        } else {
            
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.RandevuSlotComponent_146203_onClick4_();
                  resolve();
                });
          })
        }

            
            return isErrorOccurred;
        }
		RandevuSlotComponent_146203_onClick4_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
if (ReactSystemFunctions.isNotEqualTo(ReactSystemFunctions.value(this, "randevuslot_771304_value", "isDisabled"), "1")) {
            
				stateVars.dataSource_771304 = this.state.Gunler;

		this.props.form.setFieldsValue(formVars);
		
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.RandevuSlotComponent_146203_onClick5_();
                  resolve();
                });
          })

        } else {
            
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.RandevuSlotComponent_146203_onClick5_();
                  resolve();
                });
          })
        }

            
            return isErrorOccurred;
        }
		RandevuSlotComponent_146203_onClick5_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
if (ReactSystemFunctions.isNotEqualTo(ReactSystemFunctions.value(this, "randevuslot_771304_value", "isDisabled"), "1")) {
            
				

		this.props.form.setFieldsValue(formVars);
		
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.RandevuSlotComponent_146203_onClick6_();
                  resolve();
                });
          })

        } else {
            
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.RandevuSlotComponent_146203_onClick6_();
                  resolve();
                });
          })
        }

            
            return isErrorOccurred;
        }
		RandevuSlotComponent_146203_onClick6_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
if (ReactSystemFunctions.isNotEqualTo(ReactSystemFunctions.value(this, "randevuslot_771304_value", "isDisabled"), "1")) {
            
				stateVars.dataSource_631137 = this.state.EgitmenMusaitSlotlar;

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

        } else {
            KuikaAppManager.hideSpinner(this);
        }

            
            return isErrorOccurred;
        }










    RandevuSlotComponent_624330_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				profileId_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.ProfilByKisiAndKurum?.length > 0 ? stateVars.ProfilByKisiAndKurum[0].profilId : this.state.ProfilByKisiAndKurum?.length > 0 ? this.state.ProfilByKisiAndKurum[0].profilId : null)), "Guid"),
				seciliGun_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, "randevuslot_771304_value", "cellTxt"), "number"),
				seciliAy_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.CalendarDate?.length > 0 ? stateVars.CalendarDate[0].month : this.state.CalendarDate?.length > 0 ? this.state.CalendarDate[0].month : null)), "number"),
				seciliYil_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.CalendarDate?.length > 0 ? stateVars.CalendarDate[0].year : this.state.CalendarDate?.length > 0 ? this.state.CalendarDate[0].year : null)), "number")
        }

		KuikaAppManager.showSpinner(this);
if (ReactSystemFunctions.isNotEqualTo(ReactSystemFunctions.value(this, "randevuslot_771304_value", "isDisabled"), "1")) {
            		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "RandevuSlot/RandevuSlotComponent_624330_onClick", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.Gunler = result?.data.gunler;
			
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.RandevuSlotComponent_624330_onClick1_();
                  resolve();
                });
          })

        } else {
            
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.RandevuSlotComponent_624330_onClick1_();
                  resolve();
                });
          })
        }


        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
		RandevuSlotComponent_624330_onClick1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }

				stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, 'isComp341303Enabled', "disabled");

		this.props.form.setFieldsValue(formVars);
		
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.RandevuSlotComponent_624330_onClick2_();
                  resolve();
                });
          })

            
            return isErrorOccurred;
        }
    RandevuSlotComponent_624330_onClick2_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				profileId_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.ProfilByKisiAndKurum?.length > 0 ? stateVars.ProfilByKisiAndKurum[0].profilId : this.state.ProfilByKisiAndKurum?.length > 0 ? this.state.ProfilByKisiAndKurum[0].profilId : null)), "Guid"),
				date_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.Gunler?.length > 0 ? stateVars.Gunler[0].seciliTarih : this.state.Gunler?.length > 0 ? this.state.Gunler[0].seciliTarih : null)), "Date")
        }

if (ReactSystemFunctions.isNotEqualTo(ReactSystemFunctions.value(this, "randevuslot_771304_value", "isDisabled"), "1")) {
            		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "RandevuSlot/RandevuSlotComponent_624330_onClick2_", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.EnsureSlots = result?.data.ensureSlots;
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.RandevuSlotComponent_624330_onClick3_();
                  resolve();
                });
          })

        } else {
            
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.RandevuSlotComponent_624330_onClick3_();
                  resolve();
                });
          })
        }


        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
    RandevuSlotComponent_624330_onClick3_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				profileId_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.ProfilByKisiAndKurum?.length > 0 ? stateVars.ProfilByKisiAndKurum[0].profilId : this.state.ProfilByKisiAndKurum?.length > 0 ? this.state.ProfilByKisiAndKurum[0].profilId : null)), "Guid"),
				seciliRandevuSaati_0: ReactSystemFunctions.convertToTypeByName(null, "string"),
				date_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.Gunler?.length > 0 ? stateVars.Gunler[0].seciliTarih : this.state.Gunler?.length > 0 ? this.state.Gunler[0].seciliTarih : null)), "Date")
        }

if (ReactSystemFunctions.isNotEqualTo(ReactSystemFunctions.value(this, "randevuslot_771304_value", "isDisabled"), "1")) {
            		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "RandevuSlot/RandevuSlotComponent_624330_onClick3_", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.EgitmenMusaitSlotlar = result?.data.egitmenMusaitSlotlar;
			
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.RandevuSlotComponent_624330_onClick4_();
                  resolve();
                });
          })

        } else {
            
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.RandevuSlotComponent_624330_onClick4_();
                  resolve();
                });
          })
        }


        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
		RandevuSlotComponent_624330_onClick4_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
if (ReactSystemFunctions.isNotEqualTo(ReactSystemFunctions.value(this, "randevuslot_771304_value", "isDisabled"), "1")) {
            
				

		this.props.form.setFieldsValue(formVars);
		
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.RandevuSlotComponent_624330_onClick5_();
                  resolve();
                });
          })

        } else {
            
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.RandevuSlotComponent_624330_onClick5_();
                  resolve();
                });
          })
        }

            
            return isErrorOccurred;
        }
		RandevuSlotComponent_624330_onClick5_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
if (ReactSystemFunctions.isNotEqualTo(ReactSystemFunctions.value(this, "randevuslot_771304_value", "isDisabled"), "1")) {
            
				stateVars.dataSource_771304 = this.state.Gunler;

		this.props.form.setFieldsValue(formVars);
		
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.RandevuSlotComponent_624330_onClick6_();
                  resolve();
                });
          })

        } else {
            
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.RandevuSlotComponent_624330_onClick6_();
                  resolve();
                });
          })
        }

            
            return isErrorOccurred;
        }
		RandevuSlotComponent_624330_onClick6_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
if (ReactSystemFunctions.isNotEqualTo(ReactSystemFunctions.value(this, "randevuslot_771304_value", "isDisabled"), "1")) {
            
				

		this.props.form.setFieldsValue(formVars);
		
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.RandevuSlotComponent_624330_onClick7_();
                  resolve();
                });
          })

        } else {
            
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.RandevuSlotComponent_624330_onClick7_();
                  resolve();
                });
          })
        }

            
            return isErrorOccurred;
        }
		RandevuSlotComponent_624330_onClick7_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
if (ReactSystemFunctions.isNotEqualTo(ReactSystemFunctions.value(this, "randevuslot_771304_value", "isDisabled"), "1")) {
            
				stateVars.dataSource_631137 = this.state.EgitmenMusaitSlotlar;

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

        } else {
            KuikaAppManager.hideSpinner(this);
        }

            
            return isErrorOccurred;
        }












    RandevuSlotComponent_66646_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				profileId_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.ProfilByKisiAndKurum?.length > 0 ? stateVars.ProfilByKisiAndKurum[0].profilId : this.state.ProfilByKisiAndKurum?.length > 0 ? this.state.ProfilByKisiAndKurum[0].profilId : null)), "Guid"),
				seciliRandevuSaati_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, "randevuslot_631137_value", "randevuSaati"), "string"),
				date_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.Gunler?.length > 0 ? stateVars.Gunler[0].seciliTarih : this.state.Gunler?.length > 0 ? this.state.Gunler[0].seciliTarih : null)), "Date")
        }

		KuikaAppManager.showSpinner(this);
		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "RandevuSlot/RandevuSlotComponent_66646_onClick", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.EgitmenMusaitSlotlar = result?.data.egitmenMusaitSlotlar;
			
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.RandevuSlotComponent_66646_onClick1_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
		RandevuSlotComponent_66646_onClick1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }

				stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, 'isComp341303Enabled', "enabled");
				
				stateVars.dataSource_631137 = this.state.EgitmenMusaitSlotlar;

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }




    RandevuSlotComponent_387679_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				profileId_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.ProfilByKisiAndKurum?.length > 0 ? stateVars.ProfilByKisiAndKurum[0].profilId : this.state.ProfilByKisiAndKurum?.length > 0 ? this.state.ProfilByKisiAndKurum[0].profilId : null)), "Guid"),
				seciliGun_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.CalendarDate?.length > 0 ? stateVars.CalendarDate[0].day : this.state.CalendarDate?.length > 0 ? this.state.CalendarDate[0].day : null)), "number"),
				seciliAy_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.CalendarDate?.length > 0 ? stateVars.CalendarDate[0].month : this.state.CalendarDate?.length > 0 ? this.state.CalendarDate[0].month : null)), "number"),
				seciliYil_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.CalendarDate?.length > 0 ? stateVars.CalendarDate[0].year : this.state.CalendarDate?.length > 0 ? this.state.CalendarDate[0].year : null)), "number")
        }

		KuikaAppManager.showSpinner(this);
		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "RandevuSlot/RandevuSlotComponent_387679_onClick", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.Gunler = result?.data.gunler;
			
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.RandevuSlotComponent_387679_onClick1_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
		RandevuSlotComponent_387679_onClick1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }

				
				stateVars.dataSource_771304 = this.state.Gunler;

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }




    RandevuSlotComponent_341303_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				Id_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.RandevuId ?? this.props.screenInputs.randevuid, "Guid"),
				DanisanKisiId_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.DanisanKisiId ?? this.props.screenInputs.danisankisiid, "Guid"),
				DavetiyeId_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.DavetiyeId ?? this.props.screenInputs.davetiyeid, "Guid"),
				RandevuAy_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.CalendarDate?.length > 0 ? stateVars.CalendarDate[0].month : this.state.CalendarDate?.length > 0 ? this.state.CalendarDate[0].month : null)), "number"),
				RandevuGun_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.Gunler?.length > 0 ? stateVars.Gunler[0].seciliGun : this.state.Gunler?.length > 0 ? this.state.Gunler[0].seciliGun : null)), "number"),
				RandevuYil_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.CalendarDate?.length > 0 ? stateVars.CalendarDate[0].year : this.state.CalendarDate?.length > 0 ? this.state.CalendarDate[0].year : null)), "number"),
				SecilenEgitmenId_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.EgitmenId ?? this.props.screenInputs.egitmenid, "Guid"),
				SecilenHizmetId_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.HizmetId ?? this.props.screenInputs.hizmetid, "Guid"),
				SecilenMerkezId_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.MerkezId ?? this.props.screenInputs.merkezid, "Guid"),
				RandevuSaat_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, (stateVars.EgitmenMusaitSlotlar?.length > 0 ? stateVars.EgitmenMusaitSlotlar[0].seciliRandevuSaati : this.state.EgitmenMusaitSlotlar?.length > 0 ? this.state.EgitmenMusaitSlotlar[0].seciliRandevuSaati : null)), "string"),
				randevuId_1: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.RandevuId ?? this.props.screenInputs.randevuid, "Guid"),
				templateId_2: ReactSystemFunctions.convertToTypeByName("79d10d08-1205-4c4b-9e63-72d333666848", "string"),
				receivers_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
				subject_2: ReactSystemFunctions.convertToTypeByName("Yeni MeMap randevunuz var", "string"),
				sender_2: ReactSystemFunctions.convertToTypeByName("", "string"),
				cc_2: ReactSystemFunctions.convertToTypeByName("", "string"),
				bcc_2: ReactSystemFunctions.convertToTypeByName("", "string"),
				attachmentFileName_2: ReactSystemFunctions.convertToTypeByName("", "string"),
				attachmentBase64_2: ReactSystemFunctions.convertToTypeByName("", "string"),
				language_2: ReactSystemFunctions.convertToTypeByName("", "string"),
				merkezAdi_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, (stateVars.MerkezById?.length > 0 ? stateVars.MerkezById[0].unvani : this.state.MerkezById?.length > 0 ? this.state.MerkezById[0].unvani : null)), "string"),
				randevuTarihi_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, (stateVars.Gunler?.length > 0 ? stateVars.Gunler[0].mailTarih : this.state.Gunler?.length > 0 ? this.state.Gunler[0].mailTarih : null)), "string"),
				hizmetAdi_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, (stateVars.HizmetById?.length > 0 ? stateVars.HizmetById[0].title : this.state.HizmetById?.length > 0 ? this.state.HizmetById[0].title : null)), "string"),
				egitmenAdi_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, (stateVars.EgitmenById?.length > 0 ? stateVars.EgitmenById[0].fullname : this.state.EgitmenById?.length > 0 ? this.state.EgitmenById[0].fullname : null)), "string"),
				RandevuGunu_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, (stateVars.Gunler?.length > 0 ? stateVars.Gunler[0].mailGun : this.state.Gunler?.length > 0 ? this.state.Gunler[0].mailGun : null)), "string"),
				templateId_3: ReactSystemFunctions.convertToTypeByName("3b2f7d1c-b5b0-4474-86a0-07e22cc27320", "string"),
				receivers_3: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, (stateVars.EgitmenById?.length > 0 ? stateVars.EgitmenById[0].eposta : this.state.EgitmenById?.length > 0 ? this.state.EgitmenById[0].eposta : null)), "string"),
				subject_3: ReactSystemFunctions.convertToTypeByName("Yeni randevunuz var", "string"),
				sender_3: ReactSystemFunctions.convertToTypeByName("", "string"),
				cc_3: ReactSystemFunctions.convertToTypeByName("", "string"),
				bcc_3: ReactSystemFunctions.convertToTypeByName("", "string"),
				attachmentFileName_3: ReactSystemFunctions.convertToTypeByName("", "string"),
				attachmentBase64_3: ReactSystemFunctions.convertToTypeByName("", "string"),
				language_3: ReactSystemFunctions.convertToTypeByName("", "string"),
				uyeAdi_3: ReactSystemFunctions.convertToTypeByName("", "string"),
				hizmetAdi_3: ReactSystemFunctions.convertToTypeByName("", "string"),
				merkezAdi_3: ReactSystemFunctions.convertToTypeByName("", "string"),
				randevuTarihi_3: ReactSystemFunctions.convertToTypeByName("", "string"),
				randevuSaati_3: ReactSystemFunctions.convertToTypeByName("", "string"),
				templateId_4: ReactSystemFunctions.convertToTypeByName("8fdab7a7-c255-49f0-a865-bfc3184a4371", "string"),
				receivers_4: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, (stateVars.MerkezById?.length > 0 ? stateVars.MerkezById[0].kurumEposta : this.state.MerkezById?.length > 0 ? this.state.MerkezById[0].kurumEposta : null)), "string"),
				subject_4: ReactSystemFunctions.convertToTypeByName("Yeni bir randevu var", "string"),
				sender_4: ReactSystemFunctions.convertToTypeByName("", "string"),
				cc_4: ReactSystemFunctions.convertToTypeByName("", "string"),
				bcc_4: ReactSystemFunctions.convertToTypeByName("", "string"),
				attachmentFileName_4: ReactSystemFunctions.convertToTypeByName("", "string"),
				attachmentBase64_4: ReactSystemFunctions.convertToTypeByName("", "string"),
				language_4: ReactSystemFunctions.convertToTypeByName("", "string"),
				uyeAdi_4: ReactSystemFunctions.convertToTypeByName("", "string"),
				hizmetAdi_4: ReactSystemFunctions.convertToTypeByName("", "string"),
				egitmenAdi_4: ReactSystemFunctions.convertToTypeByName("", "string"),
				randevuTarihi_4: ReactSystemFunctions.convertToTypeByName("", "string"),
				randevuSaati_4: ReactSystemFunctions.convertToTypeByName("", "string")
        }

		KuikaAppManager.showSpinner(this);
		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "RandevuSlot/RandevuSlotComponent_341303_onClick", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.SaveRecord = result?.data.saveRecord_1;
			stateVars.RandevuById = result?.data.randevuById;
			stateVars.SendMail = result?.data.sendMail;
			stateVars.SendMail = result?.data.sendMail;
			stateVars.SendMail = result?.data.sendMail;
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.RandevuSlotComponent_341303_onClick1_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
		RandevuSlotComponent_341303_onClick1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }

				stateVars.GoBack = await ReactSystemFunctions.goBack(this);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }






    async callCallbackFunction(diId) {
        let isErrorOccurred = false;

    }
}
