import { Checkbox as AntdCheckbox } from "antd/es";
import { CheckboxProps as AntdCheckboxProps, CheckboxChangeEvent } from "antd/es/checkbox";
import { Guid } from "guid-typescript";
import _ from "lodash";
import React, { PureComponent, ReactNode } from "react";
import ReactDOM from "react-dom";
import withCommonEvents from "../../../shared/hoc/with-common-events";
import { CommonProps } from "../common-props";

interface CheckboxProps extends AntdCheckboxProps {
  onChange: any;
  fillColorOnChecked?: string;
  value: any;
  defaultValue?: any;
}

interface CheckboxState {
  isControlled: boolean;
  uniqueKey: Guid;
  checked: boolean;
}
declare let window: any;

class Checkbox extends PureComponent<CheckboxProps & CommonProps, CheckboxState> {
  /**
   * This flag is used to prevent onChange event to be triggered twice.
   * onChange event is triggered twice because of the following scenario:
   * 1. User clicks on the checkbox
   * 2. onChange event is triggered
   * 3. onChange event changes the state
   * 4. componentDidUpdate is triggered (which is expected because we want onChange to be triggered when value changed from outside also)
   *
   * Important: This flag should be set to false after being set to true.
   * Avoid scenarios or code paths where this flag is set to true and not set to false.
   */

  private triggeredByClick: boolean = false;

  constructor(props: CheckboxProps) {
    super(props);

    const isControlled: boolean = props.value !== undefined;

    this.state = {
      isControlled,
      uniqueKey: Guid.create(),
      checked: isControlled ? this.getChecked(props.value) : this.getChecked(props.defaultValue)
    };
  }

  componentDidMount() {
    const css = this.getDynamicCss();
    if (css && css !== "") {
      this.setDynamicStyle();
    }
    this.handleComponentStyling();
  }

  componentDidUpdate(prevProps: Readonly<CheckboxProps & CommonProps>) {
    this.handleComponentStyling();

    if (this.props.value !== prevProps.value) {
      this.setState({ checked: this.getChecked(this.props.value) });
    }

    if (!this.state.isControlled && this.props.value !== undefined) {
      this.setState({ isControlled: true });
    }

    if (
      !this.triggeredByClick &&
      this.props.onChange &&
      this.getChecked(this.props.value) !== this.getChecked(prevProps.value)
    ) {
      this.props.onChange(this.getChecked(this.props.value));
    }
  }

  getChecked = (value: string | null | undefined) => {
    if (value === undefined || value === null) return Boolean(this.props.defaultValue);

    if (value.toString() === "") return false;
    if (value.toString() === "1") return true;
    if (value.toString() === "0") return false;
    if (value.toString().toLowerCase() === "true") return true;
    if (value.toString().toLowerCase() === "false") return false;

    return false;
  };

  onChange = async (e: CheckboxChangeEvent) => {
    e.stopPropagation();

    this.triggeredByClick = true;

    this.setState({ checked: e.target.checked }, async () => {
      await this.props.onChange?.(this.state.checked);
      this.triggeredByClick = false;
    });
  };

  setDynamicStyle = () => {
    const uniquekey = this.state.uniqueKey?.toString();
    if (!uniquekey) {
      return;
    }
    const dynamic_style = document.getElementById("dynamic_style");
    if (dynamic_style && dynamic_style.innerHTML?.indexOf(uniquekey) === -1) {
      const generatedCss = this.getDynamicCss();
      dynamic_style.innerHTML = `${dynamic_style.innerHTML}
        ${generatedCss}`;
    }
  };

  getDynamicCss() {
    const { style } = this.props;

    const checkboxAfterStyles = this.getCheckboxAfterStyles(style);
    const className: string = this.getClassName();

    if (!className || className.length === 0) {
      return "";
    }
    let result = "";
    if (checkboxAfterStyles) {
      result += `.${className.trim()} .ant-checkbox-checked:after {
        ${checkboxAfterStyles}
      }`;
    }

    if (style.backgroundColor && style.backgroundColor != "rgba(255, 255, 255, 1)") {
      result += `.${className.trim()} .ant-checkbox-inner  {
        background-color: ${style.backgroundColor || "#fff"} !important;
      }`;
    }
    if (style.color !== "rgba(255, 255, 255, 1)") {
      result += `.${className.trim()} .ant-checkbox-inner:after  {
        border-color: ${style.color || "#fff"} !important;
      }`;
    }

    return result;
  }

  // DEPRECATED But can be used in future
  getCheckedFillStyles(fillColorOnChecked: string) {
    let result = "";

    if (fillColorOnChecked) {
      result += `background-color: ${fillColorOnChecked} !important;`;
    }

    return result;
  }

  getCheckboxAfterStyles(style: React.CSSProperties) {
    let result = "";

    if (style.borderColor && style.borderColor !== "rgba(218, 218, 218, 1)") {
      result += `border-color: ${style.borderColor} !important;`;
    }
    if (style.borderStyle && style.borderStyle !== "solid") {
      result += `border-style: ${style.borderStyle} !important`;
    }
    if (style.borderTopLeftRadius) {
      result += `border-top-left-radius: ${style.borderTopLeftRadius.toString()} + 'px !important;`;
    }
    if (style.borderTopRightRadius) {
      result += `border-top-right-radius: ${style.borderTopRightRadius.toString()} + 'px !important;`;
    }
    if (style.borderBottomLeftRadius) {
      result += `border-bottom-left-radius: ${style.borderBottomLeftRadius.toString()} + 'px !important;`;
    }
    if (style.borderBottomRightRadius) {
      result += `border-bottom-right-radius: ${style.borderBottomRightRadius.toString()} + 'px !important;`;
    }

    return result;
  }

  getClassName = () => {
    let result = "";
    if (this.props.className) {
      result = this.props.className;
    }
    if (!this.state.uniqueKey) {
      return result;
    }
    result = `${result} kcheckbox_${this.state.uniqueKey.toString().substring(0, 8)}`;
    return result;
  };

  handleComponentStyling() {
    if (!this.props.style) return;
    const { style } = this.props;

    const node = ReactDOM.findDOMNode(this) as Element;
    const checkboxInnerElement = node.querySelector(".ant-checkbox-inner") as HTMLElement;

    this.handleBorderStyles(checkboxInnerElement, style);
    this.handleShadowStyles(checkboxInnerElement, style);
    this.handleLayoutStyles(checkboxInnerElement, style);
  }

  handleBorderStyles(item: HTMLElement, style: React.CSSProperties) {
    if (style.borderColor) {
      item.style.borderColor = style.borderColor;
    }
    if (style.borderStyle) {
      item.style.borderStyle = style.borderStyle;
    }
    if (style.borderTopWidth) {
      item.style.borderTopWidth = `${style.borderTopWidth.toString()}px`;
    }
    if (style.borderBottomWidth) {
      item.style.borderBottomWidth = `${style.borderBottomWidth.toString()}px`;
    }
    if (style.borderLeftWidth) {
      item.style.borderLeftWidth = `${style.borderLeftWidth.toString()}px`;
    }
    if (style.borderRightWidth) {
      item.style.borderRightWidth = `${style.borderRightWidth.toString()}px`;
    }
    if (style.borderTopLeftRadius) {
      item.style.borderTopLeftRadius = `${style.borderTopLeftRadius.toString()}px`;
    }
    if (style.borderTopRightRadius) {
      item.style.borderTopRightRadius = `${style.borderTopRightRadius.toString()}px`;
    }
    if (style.borderBottomLeftRadius) {
      item.style.borderBottomLeftRadius = `${style.borderBottomLeftRadius.toString()}px`;
    }
    if (style.borderBottomRightRadius) {
      item.style.borderBottomRightRadius = `${style.borderBottomRightRadius.toString()}px`;
    }
  }

  handleShadowStyles(item: HTMLElement, style: React.CSSProperties) {
    if (style.boxShadow) {
      item.style.boxShadow = style.boxShadow;
    }
  }

  handleLayoutStyles(item: HTMLElement, style: React.CSSProperties) {
    if (style.width) {
      item.style.width = `${style.width}`;
    }

    if (style.height) {
      item.style.height = `${style.height}`;
    }
  }

  getProps = () => {
    const props: any = _.clone(this.props);
    const style: any = _.clone(this.props.style);

    const borderStyleKeysToIgnore: string[] = [
      "borderColor",
      "borderStyle",
      "borderTopWidth",
      "borderBottomWidth",
      "borderLeftWidth",
      "borderRightWidth",
      "borderTopRightRadius",
      "borderTopLeftRadius",
      "borderBottomLeftRadius",
      "borderBottomRightRadius"
    ];
    const fillStyleKeysToIgnore: string[] = ["backgroundColor"];
    const shadowKeysToIgnore: string[] = ["boxShadow"];
    const layoutKeysToIgnore: string[] = ["width", "height"];
    const stylesToBeDeletedFromProps = [
      ...borderStyleKeysToIgnore,
      ...fillStyleKeysToIgnore,
      ...shadowKeysToIgnore,
      ...layoutKeysToIgnore
    ];

    stylesToBeDeletedFromProps.forEach((key) => {
      if (style[key]) delete style[key];
    });

    if (style.display == "block") {
      style.display = "flex";
    } else if (style.display == "inline") {
      style.display = "inline-flex";
    }
    props.style = style;
    if (!props.children || props.children == "") {
      delete props.children;
    }
    return props;
  };

  render(): ReactNode {
    return (
      <AntdCheckbox
        className={`k-checkbox-component${this.getClassName()}`}
        {...this.getProps()}
        checked={this.state.isControlled ? this.getChecked(this.props.value) : this.state.checked}
        defaultChecked={this.getChecked(this.props.defaultValue)}
        onChange={this.onChange}
      />
    );
  }
}

const checkbox = withCommonEvents(Checkbox);
export { checkbox as Checkbox };
