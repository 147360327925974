import { Popover } from "antd/es";
import { CSSProperties, PureComponent } from "react";
import { ChromePicker } from "react-color";
import withCommonEvents from "../../../shared/hoc/with-common-events";
import { CommonProps } from "../common-props";
import "./color-picker.scss";

export interface IColorPickerProps {
  value: string;
  style?: CSSProperties;
  onChange?: (value?: string) => void;
}
export interface IColorPickerState {}

class ColorPicker extends PureComponent<IColorPickerProps & CommonProps, IColorPickerState> {
  constructor(props: IColorPickerProps) {
    super(props);
    this.state = {};
  }

  handleColorPickerOnChange = (value?: string) => {
    if (this.props.onChange) {
      this.props.onChange(value);
    }
  };

  getStyle = (): CSSProperties => {
    const style = { ...this.props.style };
    if (style.display === "block") style.display = "flex";
    else style.display = "inline-flex";

    return style;
  };

  render() {
    const { value } = this.props;
    return (
      <>
        <Popover
          trigger={"click"}
          content={
            <ChromePicker
              styles={{}}
              color={value}
              onChange={(value, e) => {
                e.preventDefault();
                e.stopPropagation();
                this.handleColorPickerOnChange(
                  `rgba(${value.rgb.r}, ${value.rgb.g}, ${value.rgb.b}, ${value.rgb.a ?? "1"})`
                );
              }}
            />
          }
        >
          <div className="kuika-color-picker" style={this.getStyle()}>
            <div
              className="kuika-color-picker__inner"
              style={{
                backgroundColor: value,
                flex: 1,
                width: "inherit",
                height: "inherit",
                minWidth: "inherit",
                minHeight: "inherit"
              }}
            ></div>
          </div>
        </Popover>
      </>
      // <AntdColorPicker
      //   value={value}
      //   // format="hex"
      //   onFormatChange={(format) => {
      //     this.currentFormat = format;
      //   }}
      //   onChange={(value) => {
      //     switch (this.currentFormat) {
      //       case "hex":
      //         this.handleColorPickerOnChange(value.toHexString());
      //         break;
      //       case "rgb":
      //         this.handleColorPickerOnChange(value.toRgbString());
      //         break;
      //       case "hsb":
      //         this.handleColorPickerOnChange(value.toHsbString());
      //         break;
      //     }
      //   }}
      //   onChangeComplete={() => {
      //     this.handleColorPickerOnChange(value);
      //   }}
      //   allowClear
      //   onClear={() => {
      //     this.handleColorPickerOnChange();
      //   }}
      // />
    );
  }
}

const colorPicker = withCommonEvents(ColorPicker);
export { colorPicker as ColorPicker };
