import { ChartData, Chart as ChartJS, ChartTypeRegistry, LayoutPosition, registerables } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import DoughnutLabel from "chartjs-plugin-doughnutlabel-v3";
import _ from "lodash";
import React, { ReactNode } from "react";
import { Chart } from "react-chartjs-2";
import withCommonEvents from "../../../shared/hoc/with-common-events";
import { KMainFunctions } from "../../../shared/utilty/main-functions";
import { CommonProps } from "../common-props";

declare let window: Window & { kuika: any };

export interface BaseChartProps {
  dataSource: any[];
  mode: keyof ChartTypeRegistry;
  leftAxis: boolean;
  rightAxis: boolean;
  beginAtZero: boolean;
  legend: LayoutPosition;
  dataLabelKeyName?: string;
  dataDisplayLabelKeyName?: string;
  dataTooltipFooterDataLabel?: string;
  firstDataFieldKeyName?: string;
  firstDataBgColorKeyName?: string;
  secondDataFieldKeyName?: string;
  secondDataBgColorKeyName?: string;
  thirdDataFieldKeyName?: string;
  thirdDataBgColorKeyName?: string;
  fill?: boolean;
  style?: React.CSSProperties;
  maxYAxisValue?: number;
  centerText?: string;
  children?: any;
  showDataLabels?: boolean;
  dataLabelFormatter?: string;
}

export interface BaseChartState {}

export type ChartDataType = ChartData<
  "bar" | "line" | "doughnut" | "radar" | "pie" | "bubble" | "polarArea" | "scatter"
>;

class BaseChart extends React.PureComponent<BaseChartProps & CommonProps, BaseChartState> {
  private memoizedDynamicCssResult = "";

  private canvasId: string = `bar-chart-${Math.floor(Math.random() * Math.floor(Math.random() * Date.now()))}`;

  private colorMap: { [key: string]: string } = {};

  constructor(props: BaseChartProps) {
    super(props);
    this.state = {};
    ChartJS.register(...registerables);
    if (this.props.showDataLabels) {
      ChartJS.register(ChartDataLabels);
    }
    if (this.props.children) {
      ChartJS.register(DoughnutLabel);
    }
  }

  componentDidMount(): void {
    this.setDynamicStyle();
  }

  componentDidUpdate(prevProps: any): void {
    this.setDynamicStyle();
  }

  getProps(): BaseChartProps {
    const props: BaseChartProps = { ...this.props };

    if (props.leftAxis) {
      delete props.leftAxis;
    }

    if (props.rightAxis) {
      delete props.leftAxis;
    }

    return props;
  }

  getNumbersOfStyleProperties = (val: any) => {
    let numb: any = val?.match(/\d/g);
    numb = numb?.join("");
    return numb;
  };

  getRandomColor(key: string): string {
    if (this.colorMap[key] && this.props.mode !== "pie" && this.props.mode !== "doughnut") {
      return this.colorMap[key];
    }

    const MAX_RGB_VALUE = 255;
    const red: number = Math.floor(Math.random() * MAX_RGB_VALUE);
    const green: number = Math.floor(Math.random() * MAX_RGB_VALUE);
    const blue: number = Math.floor(Math.random() * MAX_RGB_VALUE);

    const color = `rgb(${red}, ${green}, ${blue})`;
    this.colorMap[key] = color;
    return color;
  }

  getHeight(): string | undefined {
    if (this.props.style && this.props.style?.height) {
      return this.props.style.height.toString();
    }

    return undefined;
  }

  getMinHeight(): string | undefined {
    if (this.props.style && this.props.style?.minHeight) {
      return this.props.style.minHeight.toString();
    }
    return undefined;
  }

  getMaxHeight(): string | undefined {
    if (this.props.style && this.props.style?.maxHeight) {
      return this.props.style.maxHeight.toString();
    }

    return undefined;
  }

  getWidth(): string | undefined {
    if (this.props.style && this.props.style?.width) {
      return this.props.style.width.toString();
    }

    return undefined;
  }

  getMinWidth(): string | undefined {
    if (this.props.style && this.props.style?.minWidth) {
      return this.props.style.minWidth.toString();
    }

    return undefined;
  }

  getMaxWidth(): string | undefined {
    if (this.props.style && this.props.style?.maxWidth) {
      return this.props.style.maxWidth.toString();
    }

    return undefined;
  }

  getPaddingLeft(): string | undefined {
    if (this.props.style && this.props.style?.paddingLeft) {
      return `${this.props.style.paddingLeft.toString()}px`;
    }

    return undefined;
  }

  getPaddingTop(): string | undefined {
    if (this.props.style && this.props.style?.paddingTop) {
      return `${this.props.style.paddingTop.toString()}px`;
    }

    return undefined;
  }

  getPaddingRight(): string | undefined {
    if (this.props.style && this.props.style?.paddingRight) {
      return `${this.props.style.paddingRight.toString()}px`;
    }

    return undefined;
  }

  getPaddingBottom(): string | undefined {
    if (this.props.style && this.props.style?.paddingBottom) {
      return `${this.props.style.paddingBottom.toString()}px`;
    }

    return undefined;
  }

  getData(): ChartDataType {
    let labels: string[] = [];
    let displayLabels: string[] = [];
    let tooltipFooterLabels: string[] = [];
    const dataSets: any[] = [];
    const fill: boolean = this.props.fill ?? false;

    if (window.kuika?.dashboardState === 1) {
      return {
        labels: ["Label 0", "Label 1", "Label 2", "Label 3", "Label 4", "Label 5"],
        datasets: [
          {
            label: "1",
            data: [24, 67, 49, 13, 92, 75],
            backgroundColor: [
              "rgba(17,115,236,0.7)",
              "rgba(43,11,91,0.8)",
              "rgba(146,253,0,0.7)",
              "rgba(35,1,236,0.8)",
              "rgba(204,245,160,0.7)",
              "rgba(193,2,152,0.9)"
            ],
            fill
          }
        ]
      };
    }

    if (!this.props.dataSource || this.props.dataSource.length === 0) {
      return {
        datasets: []
      };
    }

    if (this.props.dataDisplayLabelKeyName && this.props.dataDisplayLabelKeyName.length > 0) {
      labels = this.props.dataSource?.map((x) => x[KMainFunctions.toCamelCase(this.props.dataDisplayLabelKeyName)]);
    } else {
      labels = this.props.dataSource?.map((x, idx) => {
        return `Label ${idx}`;
      });
    }

    if (this.props.firstDataFieldKeyName) {
      displayLabels = this.props.dataSource?.map((x, idx) => {
        return x[KMainFunctions.toCamelCase(this.props.firstDataFieldKeyName)];
      });
    } else if (this.props.dataLabelKeyName && this.props.dataLabelKeyName.length > 0) {
      displayLabels = this.props.dataSource?.map((x) => x[KMainFunctions.toCamelCase(this.props.dataLabelKeyName)]);
    } else {
      displayLabels = this.props.dataSource?.map((x, idx) => {
        return `${idx}`;
      });
    }

    const tooltipTitleLabels = this.props.dataSource?.map(
      (x) => x[KMainFunctions.toCamelCase(this.props.dataLabelKeyName)]
    );

    if (this.props.dataTooltipFooterDataLabel && this.props.dataTooltipFooterDataLabel.length > 0) {
      tooltipFooterLabels = this.props.dataSource?.map(
        (x) => x[KMainFunctions.toCamelCase(this.props.dataTooltipFooterDataLabel)]
      );
    } else {
      tooltipFooterLabels = this.props.dataSource?.map((x, idx) => {
        return `${idx}`;
      });
    }

    if (this.props.firstDataFieldKeyName && this.props.firstDataFieldKeyName?.length > 0) {
      let bgColor: string[] = [];
      if (this.props.firstDataBgColorKeyName && this.props.firstDataBgColorKeyName.length > 0) {
        bgColor = this.props.dataSource?.map((x) => x[KMainFunctions.toCamelCase(this.props.firstDataBgColorKeyName)]);
      } else {
        bgColor = this.props.dataSource?.map((x) => this.getRandomColor(this.props.firstDataFieldKeyName!));
      }

      const data: any[] = this.props.dataSource?.map(
        (x) => x[KMainFunctions.toCamelCase(this.props.firstDataFieldKeyName)]
      );

      dataSets.push({
        label: this.props.firstDataFieldKeyName || "1",
        data,
        backgroundColor: bgColor,
        fill,
        displayLabels,
        tooltipFooterLabels,
        tooltipTitleLabels
      });
    }

    if (this.props.secondDataFieldKeyName && this.props.secondDataFieldKeyName?.length > 0) {
      let bgColor: string[] = [];

      if (this.props.secondDataBgColorKeyName && this.props.secondDataBgColorKeyName.length > 0) {
        bgColor = this.props.dataSource?.map((x) => x[KMainFunctions.toCamelCase(this.props.secondDataBgColorKeyName)]);
      } else {
        bgColor = this.props.dataSource?.map((x) => this.getRandomColor(this.props.secondDataFieldKeyName!));
      }

      const data: any[] = this.props.dataSource?.map(
        (x) => x[KMainFunctions.toCamelCase(this.props.secondDataFieldKeyName)]
      );

      dataSets.push({
        label: this.props.secondDataFieldKeyName || "2",
        data,
        backgroundColor: bgColor,
        fill,
        displayLabels,
        tooltipFooterLabels
      });
    }

    if (this.props.thirdDataFieldKeyName && this.props.thirdDataFieldKeyName?.length > 0) {
      let bgColor: string[] = [];

      if (this.props.thirdDataBgColorKeyName && this.props.thirdDataBgColorKeyName.length > 0) {
        bgColor = this.props.dataSource?.map((x) => x[KMainFunctions.toCamelCase(this.props.thirdDataBgColorKeyName)]);
      } else {
        bgColor = this.props.dataSource?.map((x) => this.getRandomColor(this.props.thirdDataFieldKeyName!));
      }

      const data: any[] = this.props.dataSource?.map(
        (x) => x[KMainFunctions.toCamelCase(this.props.thirdDataFieldKeyName)]
      );

      dataSets.push({
        label: this.props.thirdDataFieldKeyName || "3",
        data,
        backgroundColor: bgColor,
        fill,
        displayLabels,
        tooltipFooterLabels
      });
    }

    return {
      labels,
      datasets: dataSets
    };
  }

  getDynamicCss = (): string => {
    const className: string = this.canvasId;
    if (!className || className.length === 0) {
      return "";
    }
    let result = "";

    const { height, maxHeight, minHeight, width, minWidth, maxWidth, display } = this.props.style;
    if ((height && !maxHeight && !minHeight) || minHeight < height || maxHeight > height) {
      result += `.${className} {
          height: unset !important;
          max-height: ${height} !important;
          min-height: ${height} !important;
        }`;
    }
    if ((width && !maxWidth && !minWidth) || minWidth < width || maxWidth > width) {
      result += `.${className} {
          width: unset !important;
          max-width: ${width} !important;
          min-width: ${width} !important;
        }`;
    }
    return result;
  };

  setDynamicStyle = () => {
    const isDesignTime = window.kuika?.isDesignTime;
    if (this.memoizedDynamicCssResult !== "" && !isDesignTime) {
      return this.memoizedDynamicCssResult;
    }
    const dynamic_style = document.getElementById("dynamic_style");
    if (dynamic_style && dynamic_style.innerHTML?.indexOf(this.canvasId) === -1) {
      const generatedCss = this.getDynamicCss();
      dynamic_style.innerHTML = `${dynamic_style.innerHTML}
        ${generatedCss}`;
      this.memoizedDynamicCssResult = generatedCss;
    }
  };

  getFormattedValue(value) {
    let { dataLabelFormatter: formatter } = this.props;
    if (formatter && value && value?.toString()?.trim() !== "") {
      switch (formatter) {
        case "money":
          if (Number.isNaN(Number(value))) {
            return "0.00";
          }

          return new Intl.NumberFormat("tr-TR", {
            style: "currency",
            currency: "TRY",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })
            .format(Number(value))
            .replace("₺", "");

        case "money-var-1":
          if (Number.isNaN(Number(value))) {
            return "0.00";
          }

          return new Intl.NumberFormat("tr-TR", {
            style: "currency",
            currency: "TRY",
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
          })
            .format(Number(value))
            .replace("₺", "");

        case "money-tl":
          if (Number.isNaN(Number(value))) {
            return "₺0.00";
          }

          return new Intl.NumberFormat("tr-TR", {
            style: "currency",
            currency: "TRY",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }).format(Number(value));

        case "percent":
          return `${value}%`;

        case "fractional-2":
          const number2 = Number(value);
          return isNaN(number2) ? value : number2.toFixed(2);

        case "fractional-5":
          const number5 = Number(value);
          return isNaN(number5) ? value : number5.toFixed(5);
        default:
          return value;
      }
    } else {
      if (value && typeof value === "string") {
        const kuikaCulture = localStorage.getItem("ml");
        const isLangTR = kuikaCulture?.toLowerCase() === "tr_tr";

        if (this.props.style?.textTransform === "lowercase" && (value.includes("İ") || value.includes("I"))) {
          if (isLangTR) {
            value = value.replace(/I/g, "ı");
          }
          value = value.replace(/İ/g, "i");
        } else if (this.props.style?.textTransform === "uppercase" && (value.includes("i") || value.includes("ı"))) {
          if (isLangTR) {
            value = value.replace(/i/g, "İ");
          }
          value = value.replace(/ı/g, "I");
        } else if (this.props?.style.textTransform === "capitalize") {
          if (isLangTR && value[0] === "i") {
            value = value.replace("i", "İ");
          } else if (value[0] === "ı") {
            value = value.replace("ı", "I");
          }
        }
      }

      return value?.toString();
    }
  }

  render(): ReactNode {
    const data = this.getData();
    let labels;
    if (this.props.children?.length > 1) {
      labels =
        this.props.children?.map((child: any) => {
          return {
            text: child.props?.text ?? "",
            font: {
              size: this.getNumbersOfStyleProperties(child.props.style?.fontSize) ?? "14",
              weight: child.props.style?.fontWeight ?? "medium",
              family: child.props.style?.fontFamily ?? "Arial"
            },
            color: child.props.style?.color ?? "black"
          };
        }) ?? [];
    } else if (this.props.children) {
      labels = [
        {
          text: this.props.children?.props?.text ?? "",
          font: {
            size: this.getNumbersOfStyleProperties(this.props.children?.props?.style?.fontSize) ?? "14",
            weight: this.props.children?.props?.style?.fontWeight ?? "medium",
            family: this.props.children?.props?.style?.fontFamily ?? "Arial"
          },
          color: this.props.children?.props?.style?.color ?? "black"
        }
      ];
    }

    const maxYValue = Math.max(...data?.datasets?.map((x: any) => Math.max(...x?.data)));
    const options: any = {
      plugins: {
        legend: {
          position: this.props.legend,
          display:
            typeof this.props.legend === "string" &&
            this.props.legend &&
            ["top", "right", "bottom", "left"].indexOf(this.props.legend) > -1
        },
        doughnutLabel: {
          labels
        },
        ChartDataLabels,
        tooltip: {
          callbacks: window.kuika?.isDesignTime
            ? undefined
            : {
                title: this.props.dataLabelKeyName
                  ? (context: any) => {
                      if (context[0]?.dataset?.tooltipTitleLabels) {
                        return context[0].dataset.tooltipTitleLabels[context[0].dataIndex];
                      }
                      return context.label;
                    }
                  : undefined,
                label: this.props.dataTooltipFooterDataLabel
                  ? (context: any) => {
                      if (context?.dataset?.tooltipFooterLabels) {
                        return null;
                      }
                      return context.label;
                    }
                  : undefined,
                footer: this.props.dataTooltipFooterDataLabel
                  ? (context: any) => {
                      if (context[0]?.dataset?.tooltipFooterLabels) {
                        return context[0].dataset.tooltipFooterLabels[context[0].dataIndex];
                      }
                      return null;
                    }
                  : undefined
              }
        },
        datalabels: {
          formatter: (value: any, context: any) => {
            if (!this.props.showDataLabels) return null;
            if (window.kuika?.isDesignTime) return value;
            const index = context.dataIndex;
            return this.getFormattedValue(context.dataset.displayLabels[index]);
          },
          color: this.props.style?.color ?? "black",
          font: {
            size: this.props.style?.fontSize ?? 14,
            weight: this.props.style?.fontWeight ?? "bold",
            family: this.props.style?.fontFamily ?? "Arial"
          }
        }
      },
      scales: {
        y: {
          display: this.props.leftAxis,
          beginAtZero: this.props.beginAtZero,
          max: this.props.maxYAxisValue ?? maxYValue
        },
        x: {
          display: this.props.rightAxis,
          beginAtZero: this.props.beginAtZero
        }
      },
      responsive: true
    };

    return (
      <Chart
        id={this.canvasId}
        type={this.props.mode}
        {...this.getProps()}
        options={_.cloneDeep(options)}
        className={this.canvasId}
        data={data}
        style={{
          height: this.getHeight(),
          minHeight: this.getMinHeight(),
          maxHeight: this.getMaxHeight(),
          width: this.getWidth(),
          minWidth: this.getMinWidth(),
          maxWidth: this.getMaxWidth(),
          paddingLeft: this.getPaddingLeft(),
          paddingRight: this.getPaddingRight(),
          paddingTop: this.getPaddingTop(),
          paddingBottom: this.getPaddingBottom()
        }}
      />
    );
  }
}
const baseChart = withCommonEvents(BaseChart);
export { baseChart as BaseChart };
