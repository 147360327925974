import QRViewer from "qrcode.react";
import React, { PureComponent, ReactNode } from "react";
import withCommonEvents from "../../../shared/hoc/with-common-events";
import { DashboardState } from "../kuika-cl-model-runtimes";

declare let window: any;
const designValue = "https://www.kuika.com";

interface QRRendererProps {
  value: string;
  image: string;
  style: React.CSSProperties;
}

interface QRRendererState {}

class QRRenderer extends PureComponent<QRRendererProps & QRRendererState> {
  constructor(props: QRRendererProps) {
    super(props);
    this.state = {};
  }

  render(): ReactNode {
    const { value, image, style } = this.props;

    return (
      <>
        <QRViewer
          style={{
            ...style,
            verticalAlign: "middle",
            height: window.kuika?.dashboardState === DashboardState.reportDesigner ? "100%" : style.height
          }}
          value={
            window.kuika?.dashboardState === DashboardState.design ||
            window.kuika?.dashboardState === DashboardState.reportDesigner
              ? designValue
              : value ?? ""
          }
          renderAs="canvas"
        />
      </>
    );
  }
}

const qrRenderer = withCommonEvents(QRRenderer);
export { qrRenderer as QRRenderer };
