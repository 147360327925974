import { DownOutlined } from "@ant-design/icons";
import { Button, Dropdown, Menu } from "antd/es";
import _ from "lodash";
import React, { PureComponent, ReactNode } from "react";
import withCommonEvents from "../../../../shared/hoc/with-common-events";
import { CommonProps } from "../../common/common-props";
import { DropDownItem } from "./dropdown-item";

interface DropDownProps {
  children?: ReactNode;
  onMenuClick?: () => void;
  onButtonClick?: () => void;
  value?: string;
  label: string;
  style: any;
  widthType?: "fill" | "content";
}

interface DropDownState {}

class DropDown extends PureComponent<DropDownProps & CommonProps, DropDownState> {
  constructor(props: DropDownProps) {
    super(props);
    this.state = {};
  }

  getWidth() {
    if (this.props.widthType && this.props.widthType == "content") {
      return "max-content";
    }
    return "100%";
  }

  handleButtonClick = (e: any) => {
    if (this.props.onButtonClick) {
      this.props.onButtonClick();
    }
  };

  handleMenuClick = (e: any) => {
    if (this.props.onMenuClick) {
      this.props.onMenuClick();
    }
  };

  getProps = () => {
    const props: any = _.clone(this.props);
    let style: any = _.clone(this.props.style);
    if (!style) {
      style = {
        width: this.getWidth()
      };
    } else if (!style.width) {
      style.width = this.getWidth();
    }
    style.whiteSpace = "initial";
    style.overflow = "hidden";
    props.style = style;
    return props;
  };

  render(): ReactNode {
    const menu = (
      <Menu>
        {React.Children.map(this.props.children, (child: any, i) => {
          if (child?.props) {
            return <DropDownItem {...child.props}>{this.props.label}</DropDownItem>;
          }
        })}
      </Menu>
    );

    return (
      <div>
        <Dropdown overlay={menu}>
          <Button {...this.getProps()} onClick={this.handleButtonClick}>
            {this.props.label}
            <span style={{ float: "right", marginLeft: "10px" }}>
              <DownOutlined />
            </span>
          </Button>
        </Dropdown>
      </div>
    );
  }
}

const dropDown = withCommonEvents(DropDown);
export { dropDown as DropDown };
