import { Input } from "antd/es";
import { TextAreaRef } from "antd/es/input/TextArea";
import { CSSProperties, Component, KeyboardEvent, createRef } from "react";
import { FrameMessageSender } from "../../components/web-components/kuika-cl-model-runtimes";

declare let window: any;

const withDoubleClickEdit = <P, S>(WrappedComponent, propertyName: string) => {
  interface IDoubleClickEditElementState {
    isEditEnabled: boolean;
  }

  class DoubleClickEditElement<P, S> extends Component<P, IDoubleClickEditElementState> {
    editInput = createRef<TextAreaRef>();

    designer: FrameMessageSender;

    constructor(props: any) {
      super(props);
      this.designer = new FrameMessageSender();

      this.state = {
        isEditEnabled: false
      };
    }

    getProps = () => {
      return { ...this.props } as any;
    };

    getInputStyles = () => {
      let inputStyles: CSSProperties = {
        lineHeight: "unset",
        overflowWrap: "normal"
      };
      const { style } = this.getProps();
      if (style) {
        inputStyles = {
          ...inputStyles,
          ...style
        };
      }

      return inputStyles;
    };

    handleElementDoubleClick = (e) => {
      if (e?.detail !== 2) return;

      this.setState({ isEditEnabled: true }, () => {
        this.editInput.current.focus();
        this.editInput.current.resizableTextArea.textArea.select();
      });
    };

    handleInputOnBlur = (shouldSave: boolean = true) => {
      if (shouldSave) {
        const props = this.getProps();

        this.designer.propertyChangedMessage({
          kuikaId: props.kuikaid,
          propertyName,
          value: this.editInput.current.resizableTextArea.textArea.value,
          shouldClosePropertyExpand: true
        });
      }
      this.setState({ isEditEnabled: false });
    };

    handleInputOnKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
      switch (e.key) {
        case "Enter": {
          e.preventDefault();
          this.editInput.current.blur();
          break;
        }
        case "Escape": {
          this.handleInputOnBlur(false);
          break;
        }
      }
    };

    render() {
      if (!window.kuika?.isDesignTime) return <WrappedComponent<P & S> {...(this.props as P)} />;

      if (this.state.isEditEnabled)
        return (
          <Input.TextArea
            defaultValue={this.getProps()[propertyName]}
            ref={this.editInput}
            onKeyDown={this.handleInputOnKeyDown}
            onBlur={(e) => this.handleInputOnBlur(e.target.value !== this.getProps()[propertyName])}
            bordered={false}
            style={this.getInputStyles()}
            autoSize={true}
            draggable={false}
          />
        );

      return <WrappedComponent<P & S> {...(this.getProps() as P)} onClick={this.handleElementDoubleClick} />;
    }
  }

  return DoubleClickEditElement;
};

export default withDoubleClickEdit;
