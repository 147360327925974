import { DatePicker } from "antd/es";
import { Guid } from "guid-typescript";
import _ from "lodash";
import moment from "moment";
import { PureComponent, ReactNode } from "react";
import withCommonEvents from "../../../shared/hoc/with-common-events";
import { CommonProps } from "../common-props";
import { DashboardState } from "../kuika-cl-model-runtimes";

const { RangePicker } = DatePicker;
declare let window: any;

interface DateRangeProps {
  style: any;
  onChange: any;
  id?: string;
  startValue: any;
  endValue: any;
  value: DateRangeValue;
  startDatePlaceholder?: string;
  endDatePlaceholder?: string;
}

interface DateRangeState {
  startValue: any;
  endValue: any;
  uniqueKey: Guid;
  isOpen: boolean;
}

interface DateRangeValue {
  startValue: any;
  endValue: any;
}

class RangeDate extends PureComponent<DateRangeProps & CommonProps, DateRangeState> {
  private memoizedDynamicCssResult = "";

  constructor(props: DateRangeProps) {
    super(props);
    this.state = {
      startValue: props.value?.startValue,
      endValue: props.value?.endValue,
      uniqueKey: Guid.create(),
      isOpen: false
    };
  }

  static getDerivedStateFromProps(nextProps: DateRangeProps & CommonProps, prevState: DateRangeState) {
    if (window.kuika?.dashboardState === 1) {
      // Designer
      const startValue = nextProps.startValue ? nextProps.startValue : prevState.startValue;
      const endValue = nextProps.endValue ? nextProps.endValue : prevState.startValue;
      return { startValue, endValue } as DateRangeState;
    }
    const result = {} as DateRangeState;
    result.startValue = nextProps.value?.startValue;
    result.endValue = nextProps.value?.endValue;
    return result;
  }

  componentDidMount() {
    this.setDynamicStyle();
  }

  setDynamicStyle = () => {
    const uniquekey = this.state.uniqueKey?.toString();
    if (!uniquekey) {
      return;
    }
    const isDesignTime = window.kuika?.isDesignTime;
    if (this.memoizedDynamicCssResult !== "" && !isDesignTime) {
      return this.memoizedDynamicCssResult;
    }
    const dynamic_style = document.getElementById("dynamic_style");
    if (dynamic_style && dynamic_style.innerHTML?.indexOf(uniquekey) === -1) {
      const generatedCss = this.getDynamicCss();
      dynamic_style.innerHTML = `${dynamic_style.innerHTML}
        ${generatedCss}`;
      this.memoizedDynamicCssResult = generatedCss;
    }
  };

  getDynamicCss = (): string => {
    const className: string = this.getClassName();
    if (!className || className.length === 0) {
      return "";
    }

    let result = "";
    if (this.props.style?.color || this.props.style?.fontFamily || this.props.style?.fontWeight) {
      result += `
          .${className.trim()} .ant-picker-input>input {
            `;
      if (this.props.style?.color) result += `color: ${this.props.style?.color} !important;`;
      if (this.props.style?.fontSize) result += `font-size: ${this.props.style?.fontSize} !important;`;
      if (this.props.style?.fontFamily) result += `font-family: ${this.props.style?.fontFamily} !important;`;
      if (this.props.style?.fontWeight) result += `font-weight: ${this.props.style?.fontWeight} !important;`;
      result += `;
      }`;
    }

    if (this.props.style?.fontSize || this.props.style?.fontFamily || this.props.style?.fontWeight) {
      result += `
          .${className.trim()} .ant-picker-input>input::-webkit-input-placeholder {
            `;
      if (this.props.style?.fontSize) result += `font-size: ${this.props.style?.fontSize} !important;`;
      if (this.props.style?.fontFamily) result += `font-family: ${this.props.style?.fontFamily} !important;`;
      if (this.props.style?.fontWeight) result += `font-weight: ${this.props.style?.fontWeight} !important;`;
      result += `;
      }`;
    }

    if (this.props.style?.backgroundColor?.length > 0 || this.props.style?.color?.length > 0) {
      result += `
          .${className.trim()} .ant-picker-clear {
            `;
      if (this.props.style?.backgroundColor?.length > 0)
        result += `background: ${this.props.style?.backgroundColor} !important;`;
      if (this.props.style?.color?.length > 0) result += `color: ${this.props.style?.color} !important;`;
      result += `;
      }`;
    }
    return result;
  };

  getClassName = () => {
    let result = "";
    if (this.props.className) {
      result = this.props.className;
    }
    if (!this.state.uniqueKey) {
      return result;
    }
    result = `${result} kdatepicker_${this.state.uniqueKey.toString().substring(0, 8)}`;
    return result;
  };

  convertToString = (value: any) => {
    if (value) {
      return value.format(this.getFormat());
    }
    return value;
  };

  getFormat = () => {
    return "YYYY-MM-DD";
  };

  onChange = (value: [any, any]) => {
    if (this.props.onChange) {
      if (!value) {
        if (value === null) {
          this.props.onChange({
            startValue: null,
            endValue: null
          });
        }
        return null;
      }
      this.props.onChange({
        startValue: this.convertToString(value[0]),
        endValue: this.convertToString(value[1])
      });
    }
  };

  convertTomoment = (value: string) => {
    const parsedValue = moment(value);

    if (parsedValue.isValid()) {
      return parsedValue;
    }
    const dateObj = new Date(value);
    if (!isNaN(dateObj.getTime())) {
      return moment(dateObj);
    }

    return null;
  };

  getValue = () => {
    const startValue = this.state.startValue ? this.convertTomoment(this.state.startValue) : null;
    const endValue = this.state.endValue ? this.convertTomoment(this.state.endValue) : null;

    return [startValue, endValue];
  };

  handleStyles(style) {
    if (style.display) {
      if (style.display === "inline") {
        style.display = "inline-flex";
      } else if (style.display === "block") {
        style.display = "flex";
      }
    }

    return style;
  }

  getProps = () => {
    let props: any = {};
    if (this.props) {
      props = _.clone(this.props);
    }
    if (props.startValue) {
      delete props.startValue;
    }
    if (props.endValue) {
      delete props.endValue;
    }
    if (props.value) {
      delete props.value;
    }
    if (props.style) {
      props.style = this.handleStyles(props.style);
    }
    delete props.range;
    return props;
  };

  handleOpenChange = (isOpen: boolean) => {
    if (window?.kuika?.dashboardState !== DashboardState.design) {
      this.setState({ isOpen });
    }
  };

  render(): ReactNode {
    return (
      <>
        <RangePicker
          {...this.getProps()}
          value={this.getValue()}
          onChange={this.onChange}
          className={this.getClassName()}
          open={this.state.isOpen}
          onOpenChange={this.handleOpenChange}
          placeholder={[this.props.startDatePlaceholder, this.props.endDatePlaceholder]}
        />
      </>
    );
  }
}

const rangeDate = withCommonEvents(RangeDate);
export { rangeDate as RangeDate };
