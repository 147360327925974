import { ReactNode } from "react";
import withCommonEvents from "../../../shared/hoc/with-common-events";
import { ProgressBase } from "./base/progress-base";

class ProgressBar extends ProgressBase<any, any> {
  constructor(props: any) {
    super(props);
  }

  getBackgroundImage = () => {
    const { firstGradientColor, secondGradientColor, thirdGradientColor } = this.props;
    if (
      firstGradientColor &&
      firstGradientColor !== "" &&
      secondGradientColor &&
      secondGradientColor !== "" &&
      thirdGradientColor &&
      thirdGradientColor !== ""
    ) {
      return `linear-gradient(to right, ${firstGradientColor} 0%, ${secondGradientColor} 50%, ${thirdGradientColor} 100%)`;
    }
    if (firstGradientColor && firstGradientColor !== "" && secondGradientColor && secondGradientColor !== "") {
      return `linear-gradient(to right, ${firstGradientColor} 0%, ${secondGradientColor} 100%)`;
    }
  };

  getBackground = () => {
    return this.props.style?.backgroundColor && this.props.style?.backgroundColor !== ""
      ? this.props.style?.backgroundColor
      : "#246eff";
  };

  getSizeDimension = () => {
    const { size } = this.props;
    switch (size) {
      case "large":
        return 24;
      case "medium":
        return 16;
      default:
        return 8;
    }
  };

  getSizeBorderRadius = () => {
    const { size } = this.props;
    switch (size) {
      case "large":
        return 12;
      case "medium":
        return 8;
      default:
        return 4;
    }
  };

  getInnerBorderRadius = () => {
    const { percent, size } = this.props;
    switch (size) {
      case "large":
        return percent === 0 ? 12 : "0 12px 12px 0";
      case "medium":
        return percent === 0 ? 8 : "0 8px 8px 0";
      default:
        return percent === 0 ? 4 : "0 4px 4px 0";
    }
  };

  getTextStyle = () => {
    const { style } = this.props;
    return {
      color: style?.color,
      fontSize: style?.fontSize,
      fontWeight: style?.fontWeight,
      fontFamily: style?.fontFamily,
      lineHeight: style?.lineHeight,
      letterSpacing: style?.letterSpacing,
      textDecoration: style?.textDecoration,
      textTransform: style?.textTransform
    };
  };

  getStyle = () => {
    const { style, firstGradientColor, secondGradientColor, thirdGradientColor, size } = this.props;
    const newStyle = { ...style };
    if (firstGradientColor && firstGradientColor !== "" && secondGradientColor && secondGradientColor !== "") {
      newStyle.backgroundImage = this.getBackgroundImage();
    } else {
      newStyle.backgroundColor = this.getBackground();
    }
    newStyle.position = "relative";
    newStyle.width = this.props.style?.width ?? "100%";
    newStyle.height = this.getSizeDimension();
    newStyle.borderRadius = this.getSizeBorderRadius();
    return newStyle;
  };

  getPercent = () => {
    const { percent } = this.props;
    if (this.props.value) {
      return this.props.value.percent !== undefined ? this.props.value.percent : this.props.value;
    }
    return percent;
  };

  render(): ReactNode {
    return (
      <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
        <div
          style={{
            ...this.getStyle()
          }}
        >
          <div
            style={{
              position: "absolute",
              right: this.props.percent === 0 ? 0 : "-2px",
              top: 0,
              bottom: 0,
              width: `calc(100% - ${this.getPercent()}%)`,
              background: "#e9e9e9",
              borderRadius: this.getInnerBorderRadius()
            }}
          ></div>
        </div>
        {!this.props.hidePercentage &&
          this.props.percent !== null &&
          this.props.percent !== undefined &&
          (this.props.percentageDirection === "left" ? (
            <span style={this.getTextStyle()}>%{this.getPercent()}</span>
          ) : (
            <span style={this.getTextStyle()}>{this.getPercent()}%</span>
          ))}
      </div>
    );
  }
}

const progressBar = withCommonEvents(ProgressBar);
export { progressBar as ProgressBar };
