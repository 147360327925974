import _ from "lodash";
import React, { PureComponent, ReactNode } from "react";
import withCommonEvents from "../../../shared/hoc/with-common-events";
import { CommonProps } from "../common-props";

declare let window: any;
interface WebViewProps {
  style?: any;
  url?: string;
}

interface WebViewState {
  url?: string;
}

class WebView extends PureComponent<WebViewProps & CommonProps, WebViewState> {
  constructor(props: WebViewProps) {
    super(props);
    this.state = {
      url: undefined
    };
  }

  getMockStyle = () => {
    const style = { width: "100%", height: "200px" } as React.CSSProperties;
    if (this.props?.style?.width) {
      style.width = this.props.style?.width;
    }
    if (this.props?.style?.height) {
      style.height = this.props.style?.height;
    }
    return style;
  };

  getProps = () => {
    const props: any = _.clone(this.props);
    if (props?.url) {
      if (
        !props.url?.toLocaleLowerCase()?.trim()?.startsWith("data") &&
        !props.url?.toLocaleLowerCase()?.trim()?.startsWith("info") &&
        !props.url?.toLocaleLowerCase()?.trim()?.startsWith("http://") &&
        !props.url?.toLocaleLowerCase()?.trim()?.startsWith("https://")
      ) {
        props.url = this.state.url && this.state.url !== "" ? this.state.url : `https://${props.url}`;
      }

      if (props.url?.toLocaleLowerCase()?.trim()?.startsWith("data")) {
        const typeFromUrl: string = props.url.split(",")[0].split(":")[1].split(";")[0];
        const base64: string = props.url.split(",")[1];
        const byteCharacters: string = atob(base64);
        const byteNumbers: number[] = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray: Uint8Array = new Uint8Array(byteNumbers);
        const blob: Blob = new Blob([byteArray], { type: typeFromUrl });
        props.url = URL.createObjectURL(blob);
      }
    }
    props.src = this.state.url && this.state.url !== "" ? this.state.url : props.url;
    if (!props?.style?.width) {
      props.style.width = "100%";
    }
    return props;
  };

  getUrl = () => {
    return this.state?.url;
  };

  setUrl = (url: string) => {
    this.setState({ url });
  };

  render(): ReactNode {
    const isDesignTime = window.kuika?.isDesignTime;
    if (isDesignTime === true) {
      return <div style={this.getMockStyle()} className="webviewMock"></div>;
    }
    return (
      <>
        <iframe {...this.getProps()}></iframe>
      </>
    );
  }
}

const kIframe = withCommonEvents(WebView);
export { kIframe as WebView };
