import { Progress } from "antd/es";
import _ from "lodash";
import { PureComponent, ReactNode } from "react";
import ReactDOM from "react-dom";
import withCommonEvents from "../../../../shared/hoc/with-common-events";
import { CommonProps } from "../../common-props";

export interface ProgressBaseProps {
  percent: number;
  percentageDirection?: "left" | "right";
  style?: any;
  size?: "small" | "medium" | "large";
  hidePercentage?: boolean;
}

interface ProgressBaseState {}

class ProgressBase extends PureComponent<ProgressBaseProps & CommonProps, ProgressBaseState> {
  constructor(props: ProgressBaseProps) {
    super(props);
    this.state = {};
  }

  componentDidMount(): void {
    if (this.props.size === "medium" || this.props.size === "large") {
      this.handleHeight();
    }
    const node = ReactDOM.findDOMNode(this) as Element;
    const text = node?.querySelector(".ant-progress-text") as HTMLElement;
    if (this.props.style?.color && text?.style) {
      text.style.color = this.props.style.color;
    }
  }

  getProps = () => {
    const props: any = _.clone(this.props);
    const { style } = props;
    props.format = (percent: number) => {
      return props.percentageDirection === "left" ? `%${percent}` : `${percent}%`;
    };

    if (props.value) {
      props.percent = props.value.percent !== undefined ? props.value.percent : props.value;
    }
    if (style?.backgroundColor?.length > 0) {
      props.strokeColor = style.backgroundColor;
    }
    if (props.firstGradientColor && props.secondGradientColor && props.thirdGradientColor) {
      let gradientColors: any = {
        "0%": props.firstGradientColor,
        "50%": props.secondGradientColor,
        "100%": props.thirdGradientColor
      };
      if (this.props.percent <= 25) {
        gradientColors = {
          "0%": props.firstGradientColor,
          "75%": props.firstGradientColor,
          "100": props.secondGradientColor
        };
      } else if (this.props.percent <= 50) {
        gradientColors = {
          "0%": props.firstGradientColor,
          "50%": props.firstGradientColor,
          "75%": props.secondGradientColor
        };
      } else if (this.props.percent <= 75) {
        gradientColors = {
          "0%": props.firstGradientColor,
          "50%": props.firstGradientColor,
          "90%": props.secondGradientColor
        };
      } else {
        gradientColors = {
          "0%": props.firstGradientColor,
          "50%": props.secondGradientColor,
          "100%": props.thirdGradientColor
        };
      }
      props.strokeColor = gradientColors;
    } else if (props.firstGradientColor && props.secondGradientColor) {
      props.strokeColor = { from: props.firstGradientColor, to: props.secondGradientColor };
    }
    delete style.backgroundColor;

    props.style = style;
    return props;
  };

  handleHeight = () => {
    const node = ReactDOM.findDOMNode(this) as Element;
    const outer = node.querySelector(".ant-progress-outer") as HTMLElement;
    const inner = node.querySelector(".ant-progress-inner") as HTMLElement;
    const progressBg = node.querySelector(".ant-progress-bg") as HTMLElement;
    const text = node.querySelector(".ant-progress-text") as HTMLElement;
    if (this.props.size === "medium") {
      outer.style.height = "16px";
      inner.style.height = "16px";
      inner.style.display = "flex";
      inner.style.alignItems = "center";
      progressBg.style.height = "16px";
      text.style.height = "1.5em";
    } else if (this.props.size === "large") {
      outer.style.height = "24px";
      inner.style.height = "24px";
      inner.style.display = "flex";
      inner.style.alignItems = "center";
      progressBg.style.height = "24px";
      text.style.height = "2em";
    }
  };

  getNumbersOfStyleProperties = (val: any) => {
    let numb: any = val?.match(/\d/g);
    numb = numb?.join("");
    return numb;
  };

  render(): ReactNode {
    return (
      <>
        <Progress
          {...this.getProps()}
          size={[300, 50]}
          width={this.getNumbersOfStyleProperties(this.props.style.width)}
          height={100}
        />
      </>
    );
  }
}

const progressBase = withCommonEvents(ProgressBase);
export { progressBase as ProgressBase };
