import { Row as AntdRow } from "antd/es";
import { Gutter } from "antd/es/grid/row";
import { RowProps as AndRowProps } from "antd/es/row";
import _ from "lodash";
import { PureComponent, ReactNode } from "react";
import withCommonEvents from "../../../shared/hoc/with-common-events";
import { CommonProps } from "../common/common-props";

interface RowProps extends AndRowProps {
  horizontalGutter?: number;
  verticalGutter?: number;
}

interface RowState {}

class Row extends PureComponent<RowProps & CommonProps, RowState> {
  constructor(props: RowProps) {
    super(props);
    this.state = {};
  }

  getStyle = () => {
    let style: any = {};

    if (this.props.style) {
      style = _.clone(this.props.style);
    }

    if (this.props.showCursorPointer) {
      style.cursor = "pointer";
    }
    style.alignItems = style.alignItems ?? "inherit";
    style.display = "flex";
    return style;
  };

  getGutter = (): any => {
    if (this.props.horizontalGutter || this.props.verticalGutter) {
      let horizontalGutter: Gutter = 0;
      let verticalGutter: Gutter = 0;
      if (this.props.horizontalGutter) {
        horizontalGutter = _.clone(this.props.horizontalGutter);
      }
      if (this.props.verticalGutter) {
        verticalGutter = _.clone(this.props.verticalGutter);
      }
      return [horizontalGutter, verticalGutter];
    }
    return this.props.gutter;
  };

  render(): ReactNode {
    return (
      <>
        <AntdRow {...this.props} gutter={this.getGutter()} style={this.getStyle()}></AntdRow>
      </>
    );
  }
}

const row = withCommonEvents(Row);
export { row as Row };
