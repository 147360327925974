import _ from "lodash";
import { PureComponent, ReactNode } from "react";

export interface TableBodyWrapperProps {
  style?: any;
}

interface TableBodyWrapperState {}

export class TableBodyWrapper extends PureComponent<TableBodyWrapperProps, TableBodyWrapperState> {
  constructor(props: TableBodyWrapperProps) {
    super(props);
    this.state = {};
  }

  getProps = () => {
    const props: any = _.clone(this.props);
    if (props?.style?.display) {
      delete props.style.display;
    }
    return props;
  };

  render(): ReactNode {
    return <tbody {...this.getProps()} />;
  }
}
