import { ReCron } from "@sbzen/re-cron";
import { Input } from "antd/es";
import React, { PureComponent, ReactNode } from "react";
import withCommonEvents from "../../../shared/hoc/with-common-events";
import { CommonProps } from "../common-props";
import "./cron-maker.scss";

interface CronMakerProps {
  style?: React.CSSProperties;
}

interface CronMakerState {
  cronValue: string;
}

class CronMaker extends PureComponent<CronMakerProps & CommonProps, CronMakerState> {
  constructor(props: CronMakerProps) {
    super(props);
    this.state = {
      cronValue: "0,1,2 2/4 6/2 ? 2-7 SUN,MON 2019/1"
    };
  }

  handleChange = (cronValue: string) => {
    this.setState({ cronValue });
  };

  render(): ReactNode {
    return (
      <div style={this.props.style} className="kuika__cron-maker">
        <Input
          className="form-control mb-1 cron-maker__input k_Regular-G1-Left"
          readOnly
          value={this.state.cronValue}
        />
        <ReCron value={this.state.cronValue} onChange={(e) => this.handleChange(e)}></ReCron>
      </div>
    );
  }
}

const cronMaker = withCommonEvents(CronMaker);
export { cronMaker as CronMaker };
