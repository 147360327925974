export enum DashboardState {
  design = 1,
  locked = 5,
  readOnly = 6,
  dataDiagramScheme = 9,
  workFlow = 11,
  configManager = 12,
  notificationTemplate = 13,
  scheduledJobs = 14,
  apiManagement = 15,
  zendesk = 16,
  reportDesigner = 17,
  runSettings = 18
}

export const toCamelCase = (str: string): string => {
  if (!str) return "";
  return str.charAt(0).toLowerCase() + str.substr(1);
};

export interface IGoogleMapsValue {
  latitude: number;
  longitude: number;
}

export interface IPropertyChangedMessage {
  propertyName: string;
  value: any;
  kuikaId: string;
  shouldClosePropertyExpand?: boolean;
}

export enum FrameMessageType {
  selectItemMessage = 4,
  cloneItemMessage = 6,
  dragDropByCreateItemMessage = 7,
  dragDropByMoveItemMessage = 8,
  addChildItemMessage = 9,
  exceptionHandlingMessage = 12,
  removeItemMessage = 14,
  changeDashboardStateMessage = 20,
  genereatePNGToDownloadResultMessage = 23,
  updateGeneratedCodesToDownloadMessage = 24,
  changePositionMessage = 27,
  propertyChangedMessage = 28,
  copyItemMessage = 29,
  pasteItemMessage = 30,
  pasteItemStyleMessage = 31,
  getClipboardValue = 32,
  undoMessage = 33,
  redoMessage = 34,
  saveScreenMessage = 35,
  setNewScreenModalVisibilityMessage = 36,
  setActiveLeftPanelByClickMessage = 37,
  setActiveRightPanelByClickMessage = 38,
  showActionPopup = 39,
  injectDisplayItemToTargetDisplayItemMessage = 40,
  showWorkflowBusinessObjectPopup = 41
}

declare let window: any;
export class FrameMessageSender {
  send = (messageType: FrameMessageType, message?: IPropertyChangedMessage) => {
    const messageId: string = (Math.floor(Math.random() * 10000) + 1).toString();
    // console.log(">>>>>> Sent Message From Frame To Designer (" + FrameMessageType[messageType].toString()+", Message ID:"  + messageId + ", " + new Date().getSeconds() + "-" + new Date().getMilliseconds() + " )");
    // console.log(message);
    // console.log(">>>>>>");
    if (!message || !messageType) {
      return;
    }
    const event = {
      iframeMessage: { messageType, message, messageId }
    };
    window.parent.window.postMessage(event, "*");
  };

  propertyChangedMessage = (message: IPropertyChangedMessage) => {
    this.send(FrameMessageType.propertyChangedMessage, message);
  };
}
