import withCommonEvents from "../../../../shared/hoc/with-common-events";
import { BaseChart, BaseChartProps } from "../base-chart";

class PieChart extends BaseChart<any, any> {
  constructor(props: BaseChartProps) {
    super(props);
  }

  public static defaultProps = {
    mode: "pie"
  };
}

const pieChart = withCommonEvents(PieChart);
export { pieChart as PieChart };
