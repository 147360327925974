import { GoogleMap, GoogleMapProps, LoadScript, Marker } from "@react-google-maps/api";
import { PureComponent, ReactNode } from "react";
import { CommonProps } from "../../../components/web-components/common/common-props";
import withCommonEvents from "../../../shared/hoc/with-common-events";

interface IKLocationDisplayProps extends GoogleMapProps {
  lat?: number;
  long?: number;
}

interface IKLocationDisplayState {}

const containerStyle = {
  width: "100%",
  height: "400px"
};

class KLocationDisplay extends PureComponent<IKLocationDisplayProps & CommonProps, IKLocationDisplayState> {
  constructor(props: IKLocationDisplayProps) {
    super(props);
    this.state = {};
  }

  render(): ReactNode {
    const center = {
      lat: this.props?.lat,
      lng: this.props?.long
    };

    const position = {
      lat: this.props?.lat,
      lng: this.props?.long
    };

    return (
      <LoadScript googleMapsApiKey="AIzaSyDcjGfnhC-XuRy1av7aD981H-VjGu0UQ2w">
        <GoogleMap {...this.props} mapContainerStyle={containerStyle} center={center} zoom={10}>
          <Marker position={position} />
        </GoogleMap>
      </LoadScript>
    );
  }
}

const kLocationDisplay = withCommonEvents(KLocationDisplay);
export { kLocationDisplay as KLocationDisplay };
