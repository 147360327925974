import React from "react";
import Axios, { AxiosResponse } from "axios";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { KNavigator } from "../../shared/hoc/with-history";
import { KContext } from "../../shared/hoc/with-context";
import { useLocation } from "react-router-dom";
import { FormInstance } from "antd/es/form";
import { Guid } from "guid-typescript";
import Swal from "sweetalert2";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import moment from "moment";
import { IValidationData } from "../../models/dto/validation-data";
import { IStripePayment } from "../../models/dto/stripe-payment";
import { IUserInfoDto } from "../../models/dto/user-info.dto";
import { UserService } from "../../services/user-service";
import _ from "lodash";
import mqtt, { MqttClient } from "mqtt";
import { IMqttResult } from "../../models/dto/mqtt.dto";

declare let window: any;

export interface IPerson_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

export interface IPerson_ScreenState {
    isSpinnerVisible: boolean;
    isPageVisible: boolean;
	isLeftMenuVisible: boolean;
    spinnerCount: number;
    selectedLanguage: string;
    isPageInitLoaded: false;
    isPageRecurringLoaded: false;
	GoBack: any;
	GoBack_dummy: any;
	MyContext: any[];
	MyContext_dummy: any[];
	SehirAll: any[];
	SehirAll_dummy: any[];
	SelectOkulBransAll: any[];
	SelectOkulBransAll_dummy: any[];
	IsSinifExisting: any[];
	IsSinifExisting_dummy: any[];
	KisiSelectById: any[];
	KisiSelectById_dummy: any[];
	KUserSelectAll: any[];
	KUserSelectAll_dummy: any[];
	AktifKirilim1AltKirilim2leri: any[];
	AktifKirilim1AltKirilim2leri_dummy: any[];
	ChangeVisibilityOf: string;
	ChangeVisibilityOf_dummy: string;
	IfThenElse: boolean;
	IfThenElse_dummy: boolean;
	ProfileIdByRoleAndUser: any[];
	ProfileIdByRoleAndUser_dummy: any[];
	KurumIDbyKisiIDRole: any[];
	KurumIDbyKisiIDRole_dummy: any[];
	SaveRecord: number;
	SaveRecord_dummy: number;
	SaveUser: any;
	SaveUser_dummy: any;
	TokenOfUser: any[];
	TokenOfUser_dummy: any[];
	BaseUrl: any[];
	BaseUrl_dummy: any[];
	SendMail: boolean;
	SendMail_dummy: boolean;
	Notify: boolean;
	Notify_dummy: boolean;
	ManageIsOrtagi: number;
	ManageIsOrtagi_dummy: number;
	VeliBilgilendirmeDelete: number;
	VeliBilgilendirmeDelete_dummy: number;
	TriggerEvent: string;
	TriggerEvent_dummy: string;
	KUserByEposta: any[];
	KUserByEposta_dummy: any[];
	KisiSaveIdFinder: any[];
	KisiSaveIdFinder_dummy: any[];
	DummySave: any[];
	DummySave_dummy: any[];
	KullaniciMailLogoUrl: any[];
	KullaniciMailLogoUrl_dummy: any[];
	ProfileHesapSave: number;
	ProfileHesapSave_dummy: number;
	isComp903413Visible: 'visible' | 'hidden';
	isComp885066Visible: 'visible' | 'hidden';
	isComp999046Visible: 'visible' | 'hidden';
	isComp534531Visible: 'visible' | 'hidden';
	isComp803451Visible: 'visible' | 'hidden';
	isComp880066Visible: 'visible' | 'hidden';
	isComp732015Visible: 'visible' | 'hidden';


}

export class Person_ScreenBase extends React.PureComponent<IPerson_ScreenProps, any> {
	person_15520_value_kuikaSelectBoxRef: React.RefObject<any>;
	person_770374_value_kuikaDateRef: React.RefObject<any>;
	person_460914_value_kuikaSelectBoxRef: React.RefObject<any>;
	person_360533_value_kuikaSelectBoxRef: React.RefObject<any>;
	person_410656_value_kuikaMultiSelectRef: React.RefObject<any>;
    ml=[{"Id":"423ed54a-d6f3-fccb-7d3e-72c7b24c8b7a","Name":"tr_TR","ShortName":"Turkish","IsDefault":true,"LanguagePhrases":[]},{"Id":"74503767-9450-4cae-9db5-729d038bcb36","Name":"en_US","ShortName":"English","LanguagePhrases":[]},{"Id":"41753727-3ac0-411c-b3bf-25f9df77c2da","Name":"FixedValues","ShortName":"FixedValues","LanguagePhrases":[{"Id":796540,"PropertyName":"value","Value":"Kişi Formu"},{"Id":1919321,"PropertyName":"value","Value":"Adı"},{"Id":2575579,"PropertyName":"placeholder","Value":"Seçiniz..."},{"Id":9303234,"PropertyName":"value","Value":"Soyadı"},{"Id":1642114,"PropertyName":"placeholder","Value":"Seçiniz..."},{"Id":677202,"PropertyName":"value","Value":"Cinsiyet"},{"Id":15520,"PropertyName":"placeholder","Value":"Seçiniz..."},{"Id":821510,"PropertyName":"value","Value":"Doğum Tarihi"},{"Id":770374,"PropertyName":"placeholder","Value":"Seçiniz..."},{"Id":3716883,"PropertyName":"value","Value":"E-posta Adresi"},{"Id":704424,"PropertyName":"placeholder","Value":"Seçiniz..."},{"Id":3044861,"PropertyName":"value","Value":"Telefonu"},{"Id":8847930,"PropertyName":"placeholder","Value":"Seçiniz..."},{"Id":60672,"PropertyName":"value","Value":"Şehir"},{"Id":460914,"PropertyName":"placeholder","Value":"Seçiniz..."},{"Id":360533,"PropertyName":"placeholder","Value":"Seçiniz..."},{"Id":366690,"PropertyName":"value","Value":"Okul Numarası"},{"Id":385822,"PropertyName":"placeholder","Value":"Seçiniz..."},{"Id":397166,"PropertyName":"value","Value":"Branş"},{"Id":410656,"PropertyName":"placeholder","Value":"Choose..."},{"Id":880066,"PropertyName":"label","Value":"Button text"},{"Id":315533,"PropertyName":"value","Value":"Kullanıcı Oluştur"},{"Id":803451,"PropertyName":"label","Value":"Mail sıfırla"},{"Id":9544,"PropertyName":"label","Value":"KAYDET"}]}]
    defaultML = "tr_TR"
    
    screenHash = ""
    
    constructor(props) {
        super(props);
		this.person_15520_value_kuikaSelectBoxRef = React.createRef();
		this.person_770374_value_kuikaDateRef = React.createRef();
		this.person_460914_value_kuikaSelectBoxRef = React.createRef();
		this.person_360533_value_kuikaSelectBoxRef = React.createRef();
		this.person_410656_value_kuikaMultiSelectRef = React.createRef();

        this.state = {
            isSpinnerVisible: false,
            isPageVisible: false,
            isLeftMenuVisible: false,
            spinnerCount: 0,
            selectedLanguage: "",
            isPageInitLoaded: false,
            isPageRecurringLoaded: false,
            	        GoBack: "",
	        MyContext: [],
	        SehirAll: [],
	        SelectOkulBransAll: [],
	        IsSinifExisting: [],
	        KisiSelectById: [],
	        KUserSelectAll: [],
	        AktifKirilim1AltKirilim2leri: [],
	        IfThenElse: false,
	        ProfileIdByRoleAndUser: [],
	        KurumIDbyKisiIDRole: [],
	        SaveRecord: false,
	        VeliBilgilendirmeDelete: 0,
	        TriggerEvent: "",
	        KUserByEposta: [],
	        DummySave: [],
	        ManageIsOrtagi: 0,
	        dataSource_15520: [{"key":"K","text":"Kız"},{"key":"E","text":"Erkek"}],
	        isComp903413Visible: 'hidden',
	        isComp885066Visible: 'hidden',
	        isComp999046Visible: 'visible',
	        isComp534531Visible: 'hidden',
	        isComp803451Visible: 'hidden',
	        isComp880066Visible: 'hidden',
	        isComp732015Visible: 'hidden',

            
        }

        this.state.isPageVisible = true;
        
    }

    async componentDidMount() {

        if (KuikaAppManager.isPageInitPrevented()) {
            KuikaAppManager.calculateAndSetBodyHeight("person", "");
            return;
        }
        
        ReactSystemFunctions.validateToken()
        if (true){
            await this.PersonPageInit();
        }
        
        
        
        KuikaAppManager.calculateAndSetBodyHeight("person", "");
    }

    componentWillUnmount() {
        
        
        
    }

    

        
    componentDidUpdate= async (prevProps, prevState) => {
		KuikaAppManager.calculateAndSetBodyHeight("person", ""    );
        if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
			await this.PersonPageInit();
		}

        
	}

    fillFormInitValues() {
        this.props.form.setFieldsValue({
person_2575579_value: this.state.KisiSelectById?.at?.(0)?.adi ?? undefined,
person_1642114_value: this.state.KisiSelectById?.at?.(0)?.soyadi ?? undefined,
person_15520_value: this.state.KisiSelectById?.at?.(0)?.cinsiyet ?? undefined,
person_770374_value: this.state.KisiSelectById?.at?.(0)?.dogumTarihi ?? undefined,
person_704424_value: this.state.KisiSelectById?.at?.(0)?.eposta ?? undefined,
person_8847930_value: this.state.KisiSelectById?.at?.(0)?.telefon ?? undefined,
person_460914_value: this.state.KisiSelectById?.at?.(0)?.sehirId ?? undefined,
person_360533_value: this.state.KisiSelectById?.at?.(0)?.mevcutKirilim2Id ?? undefined,
person_385822_value: this.state.KisiSelectById?.at?.(0)?.okulNumarasi ?? undefined,
person_410656_value: this.state.KisiSelectById?.at?.(0)?.okulBrans ?? undefined,
person_409657_value: false
});

    }

      setPageInitLoaded = (value: boolean) => {
        this.setState({ isPageInitLoaded: value });
      }

      setPageRecurringLoaded = (value: boolean) => {
        this.setState({ isPageRecurringLoaded: value });
      }

      clearFieldValidation = (changedValues: any) => {
        const fieldName = Object.keys(changedValues)[0];
        if (fieldName) {
          this.props.form.setFields([
            {
              name: fieldName,
              errors: []
            }
          ]);
        }
      };

      

      

    PersonPageInit = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }

        let localVar = {
			rolename_3: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.role ?? this.props.screenInputs.role, "string"),
			kisiId_4: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.kisiId ?? this.props.screenInputs.kisiid, "Guid")
        }

	KuikaAppManager.showSpinner(this);
	
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "Person/PersonPageInit", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

		stateVars.MyContext = result?.data.myContext;
		formVars.person_519225_value = ReactSystemFunctions.toString(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].kirilim2Adi : this.state.MyContext?.length > 0 ? this.state.MyContext[0].kirilim2Adi : null));
		stateVars.SehirAll = result?.data.sehirAll;
		
		formVars.person_460914_value = (stateVars.KisiSelectById?.length > 0 ? stateVars.KisiSelectById[0].sehirId : this.state.KisiSelectById?.length > 0 ? this.state.KisiSelectById[0].sehirId : null);
		formVars.person_460914_options = stateVars.SehirAll;
		stateVars.SelectOkulBransAll = result?.data.selectOkulBransAll;
		
		formVars.person_410656_value = (stateVars.KisiSelectById?.length > 0 ? stateVars.KisiSelectById[0].okulBrans : this.state.KisiSelectById?.length > 0 ? this.state.KisiSelectById[0].okulBrans : null);
		formVars.person_410656_options = stateVars.SelectOkulBransAll;
		stateVars.IsSinifExisting = result?.data.isSinifExisting;
		stateVars.KisiSelectById = result?.data.kisiSelectById;
		formVars.person_2575579_value = ReactSystemFunctions.toString(this, (stateVars.KisiSelectById?.length > 0 ? stateVars.KisiSelectById[0].adi : this.state.KisiSelectById?.length > 0 ? this.state.KisiSelectById[0].adi : null));
		formVars.person_1642114_value = ReactSystemFunctions.toString(this, (stateVars.KisiSelectById?.length > 0 ? stateVars.KisiSelectById[0].soyadi : this.state.KisiSelectById?.length > 0 ? this.state.KisiSelectById[0].soyadi : null));
		formVars.person_15520_value = (stateVars.KisiSelectById?.length > 0 ? stateVars.KisiSelectById[0].cinsiyet : this.state.KisiSelectById?.length > 0 ? this.state.KisiSelectById[0].cinsiyet : null);
		formVars.person_770374_value = ReactSystemFunctions.value(this, (stateVars.KisiSelectById?.length > 0 ? stateVars.KisiSelectById[0].dogumTarihi : this.state.KisiSelectById?.length > 0 ? this.state.KisiSelectById[0].dogumTarihi : null));
		formVars.person_704424_value = ReactSystemFunctions.toString(this, (stateVars.KisiSelectById?.length > 0 ? stateVars.KisiSelectById[0].eposta : this.state.KisiSelectById?.length > 0 ? this.state.KisiSelectById[0].eposta : null));
		formVars.person_8847930_value = ReactSystemFunctions.toString(this, (stateVars.KisiSelectById?.length > 0 ? stateVars.KisiSelectById[0].telefon : this.state.KisiSelectById?.length > 0 ? this.state.KisiSelectById[0].telefon : null));
		formVars.person_460914_value = (stateVars.KisiSelectById?.length > 0 ? stateVars.KisiSelectById[0].sehirId : this.state.KisiSelectById?.length > 0 ? this.state.KisiSelectById[0].sehirId : null);
		formVars.person_460914_options = stateVars.SehirAll;
		formVars.person_360533_value = (stateVars.KisiSelectById?.length > 0 ? stateVars.KisiSelectById[0].mevcutKirilim2Id : this.state.KisiSelectById?.length > 0 ? this.state.KisiSelectById[0].mevcutKirilim2Id : null);
		formVars.person_360533_options = stateVars.AktifKirilim1AltKirilim2leri;
		formVars.person_385822_value = ReactSystemFunctions.toString(this, (stateVars.KisiSelectById?.length > 0 ? stateVars.KisiSelectById[0].okulNumarasi : this.state.KisiSelectById?.length > 0 ? this.state.KisiSelectById[0].okulNumarasi : null));
		formVars.person_410656_value = (stateVars.KisiSelectById?.length > 0 ? stateVars.KisiSelectById[0].okulBrans : this.state.KisiSelectById?.length > 0 ? this.state.KisiSelectById[0].okulBrans : null);
		formVars.person_410656_options = stateVars.SelectOkulBransAll;
		stateVars.isComp732015Visible = ((ReactSystemFunctions.isEmpty(ReactSystemFunctions.value(this, (stateVars.KisiSelectById?.length > 0 ? stateVars.KisiSelectById[0].id : this.state.KisiSelectById?.length > 0 ? this.state.KisiSelectById[0].id : null)), null)) === true ? "visible" : "hidden")
		stateVars.KUserSelectAll = result?.data.kUserSelectAll;
		stateVars.AktifKirilim1AltKirilim2leri = result?.data.aktifKirilim1AltKirilim2leri;
		
		formVars.person_360533_value = (stateVars.KisiSelectById?.length > 0 ? stateVars.KisiSelectById[0].mevcutKirilim2Id : this.state.KisiSelectById?.length > 0 ? this.state.KisiSelectById[0].mevcutKirilim2Id : null);
		formVars.person_360533_options = stateVars.AktifKirilim1AltKirilim2leri;
		this.props.form.setFieldsValue(formVars);
		
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.PersonPageInit1_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
	PersonPageInit_if1 = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
KuikaAppManager.showSpinner(this);


	this.props.form.setFieldsValue(formVars);
	
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.PersonPageInit_if11_();
                  resolve();
                });
          })

            
            return isErrorOccurred;
        }
	PersonPageInit_if1f1 = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
KuikaAppManager.showSpinner(this);

			stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, 'isComp903413Visible', "visible");
			stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, 'isComp885066Visible', "visible");
			stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, 'isComp999046Visible', "hidden");

	this.props.form.setFieldsValue(formVars);
	this.setState(stateVars);

            this.setPageInitLoaded(true);
            return isErrorOccurred;
        }



	PersonPageInit_if11_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
if ((ReactSystemFunctions.isEqualTo(this.props.screenInputs.role ?? this.props.screenInputs.role, "Rol_Client"))) {
            isErrorOccurred = await this.PersonPageInit_if1f1();
                if (isErrorOccurred) return true;
        } else {
            
        }


	this.props.form.setFieldsValue(formVars);
	this.setState(stateVars);

            this.setPageInitLoaded(true);
            return isErrorOccurred;
        }




	PersonPageInit1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
if ((ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].kurumTipiKod : this.state.MyContext?.length > 0 ? this.state.MyContext[0].kurumTipiKod : null)), "KT_School"))) {
            isErrorOccurred = await this.PersonPageInit_if1();
                if (isErrorOccurred) return true;
        } else {
            
        }


	this.props.form.setFieldsValue(formVars);
	
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.PersonPageInit2_();
                  resolve();
                });
          })

            
            return isErrorOccurred;
        }
	PersonPageInit_if2 = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
KuikaAppManager.showSpinner(this);


	this.props.form.setFieldsValue(formVars);
	
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.PersonPageInit_if21_();
                  resolve();
                });
          })

            
            return isErrorOccurred;
        }
	PersonPageInit_if2f1 = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
KuikaAppManager.showSpinner(this);

			stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, 'isComp534531Visible', "visible");

	this.props.form.setFieldsValue(formVars);
	this.setState(stateVars);

            this.setPageInitLoaded(true);
            return isErrorOccurred;
        }



	PersonPageInit_if21_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
if ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.AktifKirilim1AltKirilim2leri?.length > 0 ? stateVars.AktifKirilim1AltKirilim2leri[0].tanim : this.state.AktifKirilim1AltKirilim2leri?.length > 0 ? this.state.AktifKirilim1AltKirilim2leri[0].tanim : null)), null))) {
            isErrorOccurred = await this.PersonPageInit_if2f1();
                if (isErrorOccurred) return true;
        } else {
            
        }


	this.props.form.setFieldsValue(formVars);
	this.setState(stateVars);

            this.setPageInitLoaded(true);
            return isErrorOccurred;
        }




	PersonPageInit2_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
if ((ReactSystemFunctions.isEqualTo(this.props.screenInputs.role ?? this.props.screenInputs.role, "Rol_Client"))) {
            isErrorOccurred = await this.PersonPageInit_if2();
                if (isErrorOccurred) return true;
        } else {
            
        }


	this.props.form.setFieldsValue(formVars);
	
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.PersonPageInit3_();
                  resolve();
                });
          })

            
            return isErrorOccurred;
        }
	PersonPageInit_if3 = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
KuikaAppManager.showSpinner(this);


	this.props.form.setFieldsValue(formVars);
	
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.PersonPageInit_if31_();
                  resolve();
                });
          })

            
            return isErrorOccurred;
        }
	PersonPageInit_if3f1 = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
KuikaAppManager.showSpinner(this);


	this.props.form.setFieldsValue(formVars);
	
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.PersonPageInit_if3f11_();
                  resolve();
                });
          })

            
            return isErrorOccurred;
        }
	PersonPageInit_if3f1f1 = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
KuikaAppManager.showSpinner(this);

			stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, 'isComp803451Visible', "visible");

	this.props.form.setFieldsValue(formVars);
	this.setState(stateVars);

            this.setPageInitLoaded(true);
            return isErrorOccurred;
        }



	PersonPageInit_if3f11_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
if (ReactSystemFunctions.isEqualTo(this.props.screenInputs.role ?? this.props.screenInputs.role, "Rol_Observer")) {
            isErrorOccurred = await this.PersonPageInit_if3f1f1();
                if (isErrorOccurred) return true;
        } else {
            
        }


	this.props.form.setFieldsValue(formVars);
	this.setState(stateVars);

            this.setPageInitLoaded(true);
            return isErrorOccurred;
        }




	PersonPageInit_if31_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
if ((ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].kurumTipiKod : this.state.MyContext?.length > 0 ? this.state.MyContext[0].kurumTipiKod : null)), "KT_School"))) {
            isErrorOccurred = await this.PersonPageInit_if3f1();
                if (isErrorOccurred) return true;
        } else {
            
        }


	this.props.form.setFieldsValue(formVars);
	this.setState(stateVars);

            this.setPageInitLoaded(true);
            return isErrorOccurred;
        }




	PersonPageInit3_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
if ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.value(this, (stateVars.KisiSelectById?.length > 0 ? stateVars.KisiSelectById[0].id : this.state.KisiSelectById?.length > 0 ? this.state.KisiSelectById[0].id : null)), null))) {
            isErrorOccurred = await this.PersonPageInit_if3();
                if (isErrorOccurred) return true;
        } else {
            
        }


	this.props.form.setFieldsValue(formVars);
	
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.PersonPageInit4_();
                  resolve();
                });
          })

            
            return isErrorOccurred;
        }
	PersonPageInit_if4 = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
KuikaAppManager.showSpinner(this);


	this.props.form.setFieldsValue(formVars);
	
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.PersonPageInit_if41_();
                  resolve();
                });
          })

            
            return isErrorOccurred;
        }
	PersonPageInit_if4f1 = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
KuikaAppManager.showSpinner(this);

			stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, 'isComp534531Visible', "visible");
			stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, 'isComp903413Visible', "hidden");
			stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, 'isComp885066Visible', "hidden");

	this.props.form.setFieldsValue(formVars);
	this.setState(stateVars);

            this.setPageInitLoaded(true);
            return isErrorOccurred;
        }



	PersonPageInit_if41_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
if ((ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].kurumTipiKod : this.state.MyContext?.length > 0 ? this.state.MyContext[0].kurumTipiKod : null)), "Kt_Club"))) {
            isErrorOccurred = await this.PersonPageInit_if4f1();
                if (isErrorOccurred) return true;
        } else {
            
        }


	this.props.form.setFieldsValue(formVars);
	this.setState(stateVars);

            this.setPageInitLoaded(true);
            return isErrorOccurred;
        }




	PersonPageInit4_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
if ((ReactSystemFunctions.isEqualTo(this.props.screenInputs.role ?? this.props.screenInputs.role, "Rol_Client"))) {
            isErrorOccurred = await this.PersonPageInit_if4();
                if (isErrorOccurred) return true;
        } else {
            
        }

			formVars.person_2575579_value = ReactSystemFunctions.toString(this, (stateVars.KisiSelectById?.length > 0 ? stateVars.KisiSelectById[0].adi : this.state.KisiSelectById?.length > 0 ? this.state.KisiSelectById[0].adi : null));

			formVars.person_1642114_value = ReactSystemFunctions.toString(this, (stateVars.KisiSelectById?.length > 0 ? stateVars.KisiSelectById[0].soyadi : this.state.KisiSelectById?.length > 0 ? this.state.KisiSelectById[0].soyadi : null));

			formVars.person_15520_value = ReactSystemFunctions.toString(this, (stateVars.KisiSelectById?.length > 0 ? stateVars.KisiSelectById[0].cinsiyet : this.state.KisiSelectById?.length > 0 ? this.state.KisiSelectById[0].cinsiyet : null));

			formVars.person_770374_value = ReactSystemFunctions.value(this, (stateVars.KisiSelectById?.length > 0 ? stateVars.KisiSelectById[0].dogumTarihi : this.state.KisiSelectById?.length > 0 ? this.state.KisiSelectById[0].dogumTarihi : null));

			formVars.person_704424_value = ReactSystemFunctions.toString(this, (stateVars.KisiSelectById?.length > 0 ? stateVars.KisiSelectById[0].eposta : this.state.KisiSelectById?.length > 0 ? this.state.KisiSelectById[0].eposta : null));

			formVars.person_8847930_value = ReactSystemFunctions.toString(this, (stateVars.KisiSelectById?.length > 0 ? stateVars.KisiSelectById[0].telefon : this.state.KisiSelectById?.length > 0 ? this.state.KisiSelectById[0].telefon : null));

			formVars.person_460914_value = ReactSystemFunctions.toString(this, (stateVars.KisiSelectById?.length > 0 ? stateVars.KisiSelectById[0].sehirId : this.state.KisiSelectById?.length > 0 ? this.state.KisiSelectById[0].sehirId : null));

			
			stateVars.dataSource_460914 = this.state.SehirAll;
			stateVars.dataSource_460914 = this.state.SehirAll;
			formVars.person_519225_value = ReactSystemFunctions.toString(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].kirilim2Adi : this.state.MyContext?.length > 0 ? this.state.MyContext[0].kirilim2Adi : null));

			formVars.person_360533_value = ReactSystemFunctions.toString(this, (stateVars.KisiSelectById?.length > 0 ? stateVars.KisiSelectById[0].mevcutKirilim2Id : this.state.KisiSelectById?.length > 0 ? this.state.KisiSelectById[0].mevcutKirilim2Id : null));

			
			stateVars.dataSource_360533 = this.state.AktifKirilim1AltKirilim2leri;
			stateVars.dataSource_360533 = this.state.AktifKirilim1AltKirilim2leri;
			formVars.person_385822_value = ReactSystemFunctions.toString(this, (stateVars.KisiSelectById?.length > 0 ? stateVars.KisiSelectById[0].okulNumarasi : this.state.KisiSelectById?.length > 0 ? this.state.KisiSelectById[0].okulNumarasi : null));

			formVars.person_410656_value = ReactSystemFunctions.toString(this, (stateVars.KisiSelectById?.length > 0 ? stateVars.KisiSelectById[0].okulBrans : this.state.KisiSelectById?.length > 0 ? this.state.KisiSelectById[0].okulBrans : null));

			
			stateVars.dataSource_410656 = this.state.SelectOkulBransAll;
			stateVars.dataSource_410656 = this.state.SelectOkulBransAll;
			stateVars.isComp732015Visible = ((ReactSystemFunctions.isEmpty(ReactSystemFunctions.value(this, (stateVars.KisiSelectById?.length > 0 ? stateVars.KisiSelectById[0].id : this.state.KisiSelectById?.length > 0 ? this.state.KisiSelectById[0].id : null)), null)) === true ? "visible" : "hidden")

	this.props.form.setFieldsValue(formVars);
	this.setState(stateVars);
	KuikaAppManager.hideSpinner(this);

            this.setPageInitLoaded(true);
            return isErrorOccurred;
        }







		PersonComponent_968274_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
KuikaAppManager.showSpinner(this);

				stateVars.GoBack = await ReactSystemFunctions.goBack(this);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }


    PersonComponent_880066_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;

			validations.push({ 
				isValid: (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "person_2575579_value", "value", "", "", "")), null)),
				message: "*",
				formName: "person_2575579_value",
				condition: true
			} as IValidationData);

validations.forEach((validation) => {
            if (validation.message === "Geçerli bir mail adresi giriniz.") {
                validation.message = ReactSystemFunctions.getEmailValidationMessage()
            }
        })
			validations.push({ 
				isValid: (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "person_704424_value", "value", "", "", "")), null)),
				message: "*",
				formName: "person_704424_value",
				condition: (ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "person_409657_value", "value", "", "", "")), true))
			} as IValidationData);

validations.forEach((validation) => {
            if (validation.message === "Geçerli bir mail adresi giriniz.") {
                validation.message = ReactSystemFunctions.getEmailValidationMessage()
            }
        })
			validations.push({ 
				isValid: (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "person_1642114_value", "value", "", "", "")), null)),
				message: "*",
				formName: "person_1642114_value",
				condition: true
			} as IValidationData);

validations.forEach((validation) => {
            if (validation.message === "Geçerli bir mail adresi giriniz.") {
                validation.message = ReactSystemFunctions.getEmailValidationMessage()
            }
        })
			validations.push({ 
				isValid: (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "person_15520_value", "value", "", "key", "")), null)),
				message: "This field is required",
				formName: "person_15520_value",
				condition: true
			} as IValidationData);

validations.forEach((validation) => {
            if (validation.message === "Geçerli bir mail adresi giriniz.") {
                validation.message = ReactSystemFunctions.getEmailValidationMessage()
            }
        })

		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				role_1: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.role ?? this.props.screenInputs.role, "string"),
				kisiId_1: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.kisiId ?? this.props.screenInputs.kisiid, "Guid"),
				KisiId_2: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.kisiId ?? this.props.screenInputs.kisiid, "Guid"),
				role_2: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.role ?? this.props.screenInputs.role, "string"),
				Id_3: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.kisiId ?? this.props.screenInputs.kisiid, "Guid"),
				Adi_3: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "person_2575579_value", "value", "", "", "")), "string"),
				Eposta_3: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "person_704424_value", "value", "", "", "")), "string"),
				Soyadi_3: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "person_1642114_value", "value", "", "", "")), "string"),
				Telefon_3: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "person_8847930_value", "value", "", "", "")), "string"),
				Cinsiyet_3: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "person_15520_value", "value", "", "key", "")), "string"),
				DogumTarihi_3: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "person_770374_value", "value", "", "", "")), "Date"),
				MevcutKirilim2Id_3: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "person_360533_value", "value", "AktifKirilim1AltKirilim2leri", "id", "id")), "Guid"),
				SehirId_3: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "person_460914_value", "value", "SehirAll", "id", "id")), "Guid"),
				OkulNumarasi_3: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "person_385822_value", "value", "", "", "")), "string")
        }

		KuikaAppManager.showSpinner(this);
		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "Person/PersonComponent_880066_onClick", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.MyContext = result?.data.myContext;
			formVars.person_519225_value = ReactSystemFunctions.toString(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].kirilim2Adi : this.state.MyContext?.length > 0 ? this.state.MyContext[0].kirilim2Adi : null));
			stateVars.ProfileIdByRoleAndUser = result?.data.profileIdByRoleAndUser;
			stateVars.KurumIDbyKisiIDRole = result?.data.kurumIDbyKisiIDRole;
			stateVars.SaveRecord = result?.data.saveRecord_1;
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.PersonComponent_880066_onClick1_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
    PersonComponent_880066_onClick_if1 = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;

			validations.push({ 
				isValid: (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "person_704424_value", "value", "", "", "")), null)),
				message: "*",
				formName: "person_704424_value",
				condition: (ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "person_409657_value", "value", "", "", "")), true))
			} as IValidationData);

validations.forEach((validation) => {
            if (validation.message === "Geçerli bir mail adresi giriniz.") {
                validation.message = ReactSystemFunctions.getEmailValidationMessage()
            }
        })
			validations.push({ 
				isValid: (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "person_2575579_value", "value", "", "", "")), null)),
				message: "*",
				formName: "person_2575579_value",
				condition: true
			} as IValidationData);

validations.forEach((validation) => {
            if (validation.message === "Geçerli bir mail adresi giriniz.") {
                validation.message = ReactSystemFunctions.getEmailValidationMessage()
            }
        })
			validations.push({ 
				isValid: (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "person_1642114_value", "value", "", "", "")), null)),
				message: "*",
				formName: "person_1642114_value",
				condition: true
			} as IValidationData);

validations.forEach((validation) => {
            if (validation.message === "Geçerli bir mail adresi giriniz.") {
                validation.message = ReactSystemFunctions.getEmailValidationMessage()
            }
        })
			validations.push({ 
				isValid: (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "person_15520_value", "value", "", "key", "")), null)),
				message: "This field is required",
				formName: "person_15520_value",
				condition: true
			} as IValidationData);

validations.forEach((validation) => {
            if (validation.message === "Geçerli bir mail adresi giriniz.") {
                validation.message = ReactSystemFunctions.getEmailValidationMessage()
            }
        })

		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				userName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "person_704424_value", "value", "", "", "")), "string"),
				firstName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "person_2575579_value", "value", "", "", "")), "string"),
				lastName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "person_1642114_value", "value", "", "", "")), "string"),
				roleNames_0: ReactSystemFunctions.convertToTypeByName(null, "string"),
				startingScreenId_0: ReactSystemFunctions.convertToTypeByName("94593018-6663-47a8-b1c3-9e3cff619781", "Guid"),
				phoneNumber_0: ReactSystemFunctions.convertToTypeByName(null, "string"),
				dontSendEmail_0: ReactSystemFunctions.convertToTypeByName(true, "boolean"),
				twoFactorAuthenticationType_0: ReactSystemFunctions.convertToTypeByName("", "number"),
				Adi_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "person_2575579_value", "value", "", "", "")), "string"),
				Eposta_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "person_704424_value", "value", "", "", "")), "string"),
				Soyadi_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "person_1642114_value", "value", "", "", "")), "string"),
				Telefon_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "person_8847930_value", "value", "", "", "")), "string"),
				Cinsiyet_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "person_15520_value", "value", "", "key", "")), "string"),
				DogumTarihi_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "person_770374_value", "value", "", "", "")), "Date"),
				MevcutKirilim2Id_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "person_360533_value", "value", "AktifKirilim1AltKirilim2leri", "id", "id")), "Guid"),
				SehirId_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "person_460914_value", "value", "SehirAll", "id", "id")), "Guid"),
				CreatedByProfileId_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].lastChosenProfileId : this.state.MyContext?.length > 0 ? this.state.MyContext[0].lastChosenProfileId : null)), "Guid"),
				OkulNumarasi_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "person_385822_value", "value", "", "", "")), "string"),
				Id_1: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.kisiId ?? this.props.screenInputs.kisiid, "Guid"),
				templateId_4: ReactSystemFunctions.convertToTypeByName("77e1b837-fbfa-41b4-b5a3-baf6dcff9dfb", "string"),
				receivers_4: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "person_704424_value", "value", "", "", "")), "string"),
				subject_4: ReactSystemFunctions.convertToTypeByName("MeMap Platformuna Davet Edildiniz", "string"),
				sender_4: ReactSystemFunctions.convertToTypeByName("", "string"),
				cc_4: ReactSystemFunctions.convertToTypeByName("", "string"),
				bcc_4: ReactSystemFunctions.convertToTypeByName("", "string"),
				attachmentFileName_4: ReactSystemFunctions.convertToTypeByName("", "string"),
				attachmentBase64_4: ReactSystemFunctions.convertToTypeByName("", "string"),
				language_4: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].lang : this.state.MyContext?.length > 0 ? this.state.MyContext[0].lang : null)), "string"),
				providerEmail_4: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "person_704424_value", "value", "", "", "")), "string"),
				kurumlogo_4: ReactSystemFunctions.convertToTypeByName("", "string"),
				ad_4: ReactSystemFunctions.convertToTypeByName("", "string"),
				soyad_4: ReactSystemFunctions.convertToTypeByName("", "string")
        }

		KuikaAppManager.showSpinner(this);
		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "Person/PersonComponent_880066_onClick_if1", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.SaveUser = result?.data.saveUser;
			stateVars.SaveRecord = result?.data.saveRecord_2;
			stateVars.TokenOfUser = result?.data.tokenOfUser;
			stateVars.BaseUrl = result?.data.baseUrl;
			stateVars.SendMail = result?.data.sendMail;
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.PersonComponent_880066_onClick_if11_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
		PersonComponent_880066_onClick_if11_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }

				stateVars.Notify = await ReactSystemFunctions.notify(this, ReactSystemFunctions.translateCustomActions(this.ml,"00389d4c_8a20_2af3_be85_5dbfec1daf85_notify",this.defaultML,"kullaniciifelseilksonra" ), "default", "bottom-right", 0, "", "", 0);

		this.props.form.setFieldsValue(formVars);
		
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.PersonComponent_880066_onClick_if12_();
                  resolve();
                });
          })

            
            return isErrorOccurred;
        }
    PersonComponent_880066_onClick_if1f2 = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				Id_0: ReactSystemFunctions.convertToTypeByName(Guid.create().toString(), "Guid"),
				KurumId_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.kurumID ?? this.props.screenInputs.kurumid, "Guid"),
				Rolename_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.role ?? this.props.screenInputs.role, "string"),
				KisiId_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.kisiId ?? this.props.screenInputs.kisiid, "Guid"),
				Kirilim2Ids_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.Kirilim2Id ?? this.props.screenInputs.kirilim2id, "string")
        }

		KuikaAppManager.showSpinner(this);
		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "Person/PersonComponent_880066_onClick_if1f2", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.SaveRecord = result?.data.saveRecord_3;
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.PersonComponent_880066_onClick_if1f21_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
		PersonComponent_880066_onClick_if1f21_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }

				stateVars.Notify = await ReactSystemFunctions.notify(this, ReactSystemFunctions.translateCustomActions(this.ml,"8d3d2409_e4f1_cef6_4d48_1bd06350525e_notify",this.defaultML,"notifyprofilifelsilk" ), "default", "bottom-right", 0, "", "", 0);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);

            
            return isErrorOccurred;
        }




    PersonComponent_880066_onClick_if1e2 = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				Id_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.ProfileIdByRoleAndUser?.length > 0 ? stateVars.ProfileIdByRoleAndUser[0].profilId : this.state.ProfileIdByRoleAndUser?.length > 0 ? this.state.ProfileIdByRoleAndUser[0].profilId : null)), "Guid"),
				KurumId_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.kurumID ?? this.props.screenInputs.kurumid, "Guid"),
				Rolename_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.role ?? this.props.screenInputs.role, "string"),
				KisiId_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.kisiId ?? this.props.screenInputs.kisiid, "Guid"),
				Kirilim2Ids_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.Kirilim2Id ?? this.props.screenInputs.kirilim2id, "string")
        }

		KuikaAppManager.showSpinner(this);
		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "Person/PersonComponent_880066_onClick_if1e2", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.SaveRecord = result?.data.saveRecord_4;
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.PersonComponent_880066_onClick_if1e21_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
		PersonComponent_880066_onClick_if1e21_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }

				stateVars.Notify = await ReactSystemFunctions.notify(this, ReactSystemFunctions.translateCustomActions(this.ml,"09020044_d3c3_b601_70c5_154c9a164589_notify",this.defaultML,"notifyprofilifelsonra" ), "default", "bottom-right", 0, "", "", 0);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);

            
            return isErrorOccurred;
        }




		PersonComponent_880066_onClick_if12_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
if ((ReactSystemFunctions.isEmpty(ReactSystemFunctions.value(this, (stateVars.ProfileIdByRoleAndUser?.length > 0 ? stateVars.ProfileIdByRoleAndUser[0].profilId : this.state.ProfileIdByRoleAndUser?.length > 0 ? this.state.ProfileIdByRoleAndUser[0].profilId : null)), null))) {
            isErrorOccurred = await this.PersonComponent_880066_onClick_if1f2();
                if (isErrorOccurred) return true;
        } else {
            isErrorOccurred = await this.PersonComponent_880066_onClick_if1e2();
                if (isErrorOccurred) return true;
        }

				stateVars.Notify = await ReactSystemFunctions.notify(this, ReactSystemFunctions.translateCustomActions(this.ml,"47b51e61_ccca_6b6c_9127_229eb2a1160d_notify",this.defaultML,"notifyprofilifelsesonra" ), "default", "bottom-right", 0, "", "", 0);

		this.props.form.setFieldsValue(formVars);
		
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.PersonComponent_880066_onClick_if13_();
                  resolve();
                });
          })

            
            return isErrorOccurred;
        }
    PersonComponent_880066_onClick_if13_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				kurumId_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].kurumId : this.state.MyContext?.length > 0 ? this.state.MyContext[0].kurumId : null)), "Guid")
        }

		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "Person/PersonComponent_880066_onClick_if13_", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.ManageIsOrtagi = result?.data.manageIsOrtagi;
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.PersonComponent_880066_onClick_if14_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
		PersonComponent_880066_onClick_if14_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }

				stateVars.GoBack = await ReactSystemFunctions.goBack(this);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);

            
            return isErrorOccurred;
        }








    PersonComponent_880066_onClick_else1 = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;

			validations.push({ 
				isValid: (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "person_2575579_value", "value", "", "", "")), null)),
				message: "*",
				formName: "person_2575579_value",
				condition: true
			} as IValidationData);

validations.forEach((validation) => {
            if (validation.message === "Geçerli bir mail adresi giriniz.") {
                validation.message = ReactSystemFunctions.getEmailValidationMessage()
            }
        })
			validations.push({ 
				isValid: (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "person_704424_value", "value", "", "", "")), null)),
				message: "*",
				formName: "person_704424_value",
				condition: (ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "person_409657_value", "value", "", "", "")), true))
			} as IValidationData);

validations.forEach((validation) => {
            if (validation.message === "Geçerli bir mail adresi giriniz.") {
                validation.message = ReactSystemFunctions.getEmailValidationMessage()
            }
        })
			validations.push({ 
				isValid: (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "person_1642114_value", "value", "", "", "")), null)),
				message: "*",
				formName: "person_1642114_value",
				condition: true
			} as IValidationData);

validations.forEach((validation) => {
            if (validation.message === "Geçerli bir mail adresi giriniz.") {
                validation.message = ReactSystemFunctions.getEmailValidationMessage()
            }
        })
			validations.push({ 
				isValid: (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "person_15520_value", "value", "", "key", "")), null)),
				message: "This field is required",
				formName: "person_15520_value",
				condition: true
			} as IValidationData);

validations.forEach((validation) => {
            if (validation.message === "Geçerli bir mail adresi giriniz.") {
                validation.message = ReactSystemFunctions.getEmailValidationMessage()
            }
        })

		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				Id_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.kisiId ?? this.props.screenInputs.kisiid, "Guid"),
				Adi_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "person_2575579_value", "value", "", "", "")), "string"),
				Eposta_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "person_704424_value", "value", "", "", "")), "string"),
				Soyadi_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "person_1642114_value", "value", "", "", "")), "string"),
				Telefon_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "person_8847930_value", "value", "", "", "")), "string"),
				Cinsiyet_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "person_15520_value", "value", "", "key", "")), "string"),
				DogumTarihi_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "person_770374_value", "value", "", "", "")), "Date"),
				MevcutKirilim2Id_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "person_360533_value", "value", "AktifKirilim1AltKirilim2leri", "id", "id")), "Guid"),
				SehirId_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "person_460914_value", "value", "SehirAll", "id", "id")), "Guid"),
				CreatedByProfileId_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].lastChosenProfileId : this.state.MyContext?.length > 0 ? this.state.MyContext[0].lastChosenProfileId : null)), "Guid"),
				OkulNumarasi_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "person_385822_value", "value", "", "", "")), "string")
        }

		KuikaAppManager.showSpinner(this);
		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "Person/PersonComponent_880066_onClick_else1", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.SaveRecord = result?.data.saveRecord_5;
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.PersonComponent_880066_onClick_else11_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
    PersonComponent_880066_onClick_else1f1 = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				Id_0: ReactSystemFunctions.convertToTypeByName(Guid.create().toString(), "Guid"),
				KurumId_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.kurumID ?? this.props.screenInputs.kurumid, "Guid"),
				Rolename_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.role ?? this.props.screenInputs.role, "string"),
				KisiId_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.kisiId ?? this.props.screenInputs.kisiid, "Guid"),
				Kirilim2Ids_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.Kirilim2Id ?? this.props.screenInputs.kirilim2id, "string")
        }

		KuikaAppManager.showSpinner(this);
		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "Person/PersonComponent_880066_onClick_else1f1", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.SaveRecord = result?.data.saveRecord_6;
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.PersonComponent_880066_onClick_else1f11_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
		PersonComponent_880066_onClick_else1f11_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }

				stateVars.Notify = await ReactSystemFunctions.notify(this, ReactSystemFunctions.translateCustomActions(this.ml,"8d3d2409_e4f1_cef6_4d48_1bd06350525e_notify",this.defaultML,"notifyprofilifelsilk" ), "default", "bottom-right", 0, "", "", 0);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);

            
            return isErrorOccurred;
        }




    PersonComponent_880066_onClick_else1e1 = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				Id_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.ProfileIdByRoleAndUser?.length > 0 ? stateVars.ProfileIdByRoleAndUser[0].profilId : this.state.ProfileIdByRoleAndUser?.length > 0 ? this.state.ProfileIdByRoleAndUser[0].profilId : null)), "Guid"),
				KurumId_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.kurumID ?? this.props.screenInputs.kurumid, "Guid"),
				Rolename_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.role ?? this.props.screenInputs.role, "string"),
				KisiId_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.kisiId ?? this.props.screenInputs.kisiid, "Guid"),
				Kirilim2Ids_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.Kirilim2Id ?? this.props.screenInputs.kirilim2id, "string")
        }

		KuikaAppManager.showSpinner(this);
		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "Person/PersonComponent_880066_onClick_else1e1", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.SaveRecord = result?.data.saveRecord_7;
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.PersonComponent_880066_onClick_else1e11_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
		PersonComponent_880066_onClick_else1e11_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }

				stateVars.Notify = await ReactSystemFunctions.notify(this, ReactSystemFunctions.translateCustomActions(this.ml,"09020044_d3c3_b601_70c5_154c9a164589_notify",this.defaultML,"notifyprofilifelsonra" ), "default", "bottom-right", 0, "", "", 0);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);

            
            return isErrorOccurred;
        }




		PersonComponent_880066_onClick_else11_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
if ((ReactSystemFunctions.isEmpty(ReactSystemFunctions.value(this, (stateVars.ProfileIdByRoleAndUser?.length > 0 ? stateVars.ProfileIdByRoleAndUser[0].profilId : this.state.ProfileIdByRoleAndUser?.length > 0 ? this.state.ProfileIdByRoleAndUser[0].profilId : null)), null))) {
            isErrorOccurred = await this.PersonComponent_880066_onClick_else1f1();
                if (isErrorOccurred) return true;
        } else {
            isErrorOccurred = await this.PersonComponent_880066_onClick_else1e1();
                if (isErrorOccurred) return true;
        }

				stateVars.Notify = await ReactSystemFunctions.notify(this, ReactSystemFunctions.translateCustomActions(this.ml,"d78544a5_48dd_d5af_e941_0f09bf6adbdc_notify",this.defaultML,"notifyprofilifelsesonra" ), "default", "bottom-right", 0, "", "", 0);

		this.props.form.setFieldsValue(formVars);
		
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.PersonComponent_880066_onClick_else12_();
                  resolve();
                });
          })

            
            return isErrorOccurred;
        }
    PersonComponent_880066_onClick_else12_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				kurumId_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].kurumId : this.state.MyContext?.length > 0 ? this.state.MyContext[0].kurumId : null)), "Guid")
        }

		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "Person/PersonComponent_880066_onClick_else12_", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.ManageIsOrtagi = result?.data.manageIsOrtagi;
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.PersonComponent_880066_onClick_else13_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
		PersonComponent_880066_onClick_else13_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }

				stateVars.GoBack = await ReactSystemFunctions.goBack(this);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);

            
            return isErrorOccurred;
        }







		PersonComponent_880066_onClick1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
if ((ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "person_409657_value", "value", "", "", "")), true))) {
            isErrorOccurred = await this.PersonComponent_880066_onClick_if1();
                if (isErrorOccurred) return true;
        } else {
            isErrorOccurred = await this.PersonComponent_880066_onClick_else1();
                if (isErrorOccurred) return true;
        }


		this.props.form.setFieldsValue(formVars);
		
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.PersonComponent_880066_onClick2_();
                  resolve();
                });
          })

            
            return isErrorOccurred;
        }
    PersonComponent_880066_onClick2_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				Id_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.ProfileIdByRoleAndUser?.length > 0 ? stateVars.ProfileIdByRoleAndUser[0].profilId : this.state.ProfileIdByRoleAndUser?.length > 0 ? this.state.ProfileIdByRoleAndUser[0].profilId : null)), "Guid"),
				KurumId_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.kurumID ?? this.props.screenInputs.kurumid, "Guid"),
				Rolename_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.role ?? this.props.screenInputs.role, "string"),
				KisiId_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.kisiId ?? this.props.screenInputs.kisiid, "Guid"),
				Kirilim2Ids_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "person_360533_value", "value", "AktifKirilim1AltKirilim2leri", "id", "id")), "string")
        }


            stateVars.Confirm = await Swal.fire({
                title: ReactSystemFunctions.translateCustomActions(this.ml,"e3efd758_9c04_a21a_1c7d_31fd87452d09_confirmation",this.defaultML,"Confirm profilupdate kullanşcyok" ),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: localStorage.getItem("ml") === 'tr_TR' ? 'Onayla' : localStorage.getItem("ml") === 'fr_FR' ? 'Confirmer' : 'Confirm',
                cancelButtonText: localStorage.getItem("ml") === 'tr_TR' ? 'Vazgeç' : localStorage.getItem("ml") === 'fr_FR' ? 'Annuler' : 'Cancel',
                customClass: {
                  title: 'swal2-font-poppins',
                }
            })

            if (stateVars.Confirm.isConfirmed)
            {
                		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "Person/PersonComponent_880066_onClick2_", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.SaveRecord = result?.data.saveRecord_8;
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.PersonComponent_880066_onClick3_();
                  resolve();
                });
          })

            }


        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
    PersonComponent_880066_onClick3_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				Id_0: ReactSystemFunctions.convertToTypeByName(Guid.create().toString(), "Guid"),
				KurumId_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.kurumID ?? this.props.screenInputs.kurumid, "Guid"),
				Rolename_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.role ?? this.props.screenInputs.role, "string"),
				KisiId_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.kisiId ?? this.props.screenInputs.kisiid, "Guid"),
				Kirilim2Ids_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "person_360533_value", "value", "AktifKirilim1AltKirilim2leri", "id", "id")), "string")
        }


            stateVars.Confirm = await Swal.fire({
                title: ReactSystemFunctions.translateCustomActions(this.ml,"af182845_29d9_fc66_85f0_d57dfb596829_confirmation",this.defaultML,"Confirm new profil&nbsp;kullanşcyok" ),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: localStorage.getItem("ml") === 'tr_TR' ? 'Onayla' : localStorage.getItem("ml") === 'fr_FR' ? 'Confirmer' : 'Confirm',
                cancelButtonText: localStorage.getItem("ml") === 'tr_TR' ? 'Vazgeç' : localStorage.getItem("ml") === 'fr_FR' ? 'Annuler' : 'Cancel',
                customClass: {
                  title: 'swal2-font-poppins',
                }
            })

            if (stateVars.Confirm.isConfirmed)
            {
                		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "Person/PersonComponent_880066_onClick3_", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.SaveRecord = result?.data.saveRecord_9;
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.PersonComponent_880066_onClick4_();
                  resolve();
                });
          })

            }


        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
		PersonComponent_880066_onClick4_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }

				formVars.person_519225_value = ReactSystemFunctions.toString(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].kirilim2Adi : this.state.MyContext?.length > 0 ? this.state.MyContext[0].kirilim2Adi : null));


		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }









    PersonComponent_803451_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				velikisiid_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.kisiId ?? this.props.screenInputs.kisiid, "Guid")
        }

		KuikaAppManager.showSpinner(this);
		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "Person/PersonComponent_803451_onClick", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.VeliBilgilendirmeDelete = result?.data.veliBilgilendirmeDelete;
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.PersonComponent_803451_onClick1_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
		PersonComponent_803451_onClick1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }

				await this.PersonPageInit();
				

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }




    PersonComponent_9544_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;

			validations.push({ 
				isValid: (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "person_704424_value", "value", "", "", "")), null)),
				message: "*",
				formName: "person_704424_value",
				condition: (ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "person_409657_value", "value", "", "", "")), true))
			} as IValidationData);

validations.forEach((validation) => {
            if (validation.message === "Geçerli bir mail adresi giriniz.") {
                validation.message = ReactSystemFunctions.getEmailValidationMessage()
            }
        })

		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				Email_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "person_704424_value", "value", "", "", "")), "string")
        }

		KuikaAppManager.showSpinner(this);
		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "Person/PersonComponent_9544_onClick", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.MyContext = result?.data.myContext;
			formVars.person_519225_value = ReactSystemFunctions.toString(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].kirilim2Adi : this.state.MyContext?.length > 0 ? this.state.MyContext[0].kirilim2Adi : null));
			stateVars.KUserByEposta = result?.data.kUserByEposta;
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.PersonComponent_9544_onClick1_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
    PersonComponent_9544_onClick_if1 = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;

			validations.push({ 
				isValid: (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "person_704424_value", "value", "", "", "")), null)),
				message: "*",
				formName: "person_704424_value",
				condition: (ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "person_409657_value", "value", "", "", "")), true))
			} as IValidationData);

validations.forEach((validation) => {
            if (validation.message === "Geçerli bir mail adresi giriniz.") {
                validation.message = ReactSystemFunctions.getEmailValidationMessage()
            }
        })

		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				email_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "person_704424_value", "value", "", "", "")), "string"),
				kisiid_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.kisiId ?? this.props.screenInputs.kisiid, "Guid")
        }

		KuikaAppManager.showSpinner(this);
		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "Person/PersonComponent_9544_onClick_if1", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.KisiSaveIdFinder = result?.data.kisiSaveIdFinder;
			this.props.form.setFieldsValue(formVars);
			this.setState(stateVars);
			KuikaAppManager.hideSpinner(this);



        this.setPageInitLoaded(true);
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }



    PersonComponent_9544_onClick_else1 = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				email_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, (stateVars.KisiSelectById?.length > 0 ? stateVars.KisiSelectById[0].eposta : this.state.KisiSelectById?.length > 0 ? this.state.KisiSelectById[0].eposta : null)), "string"),
				kisiid_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.kisiId ?? this.props.screenInputs.kisiid, "Guid")
        }

		KuikaAppManager.showSpinner(this);
		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "Person/PersonComponent_9544_onClick_else1", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.KisiSaveIdFinder = result?.data.kisiSaveIdFinder;
			this.props.form.setFieldsValue(formVars);
			this.setState(stateVars);
			KuikaAppManager.hideSpinner(this);



        this.setPageInitLoaded(true);
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }



		PersonComponent_9544_onClick1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
if ((ReactSystemFunctions.isEmpty(ReactSystemFunctions.value(this, (stateVars.KisiSelectById?.length > 0 ? stateVars.KisiSelectById[0].id : this.state.KisiSelectById?.length > 0 ? this.state.KisiSelectById[0].id : null)), null))) {
            isErrorOccurred = await this.PersonComponent_9544_onClick_if1();
                if (isErrorOccurred) return true;
        } else {
            isErrorOccurred = await this.PersonComponent_9544_onClick_else1();
                if (isErrorOccurred) return true;
        }


		this.props.form.setFieldsValue(formVars);
		
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.PersonComponent_9544_onClick2_();
                  resolve();
                });
          })

            
            return isErrorOccurred;
        }
    PersonComponent_9544_onClick2_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;

			validations.push({ 
				isValid: (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "person_2575579_value", "value", "", "", "")), null)),
				message: "*",
				formName: "person_2575579_value",
				condition: true
			} as IValidationData);

validations.forEach((validation) => {
            if (validation.message === "Geçerli bir mail adresi giriniz.") {
                validation.message = ReactSystemFunctions.getEmailValidationMessage()
            }
        })
			validations.push({ 
				isValid: (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "person_1642114_value", "value", "", "", "")), null)),
				message: "*",
				formName: "person_1642114_value",
				condition: true
			} as IValidationData);

validations.forEach((validation) => {
            if (validation.message === "Geçerli bir mail adresi giriniz.") {
                validation.message = ReactSystemFunctions.getEmailValidationMessage()
            }
        })
			validations.push({ 
				isValid: (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "person_15520_value", "value", "", "key", "")), null)),
				message: "This field is required",
				formName: "person_15520_value",
				condition: true
			} as IValidationData);

validations.forEach((validation) => {
            if (validation.message === "Geçerli bir mail adresi giriniz.") {
                validation.message = ReactSystemFunctions.getEmailValidationMessage()
            }
        })

		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				role_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.role ?? this.props.screenInputs.role, "string"),
				kisiId_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.kisiId ?? this.props.screenInputs.kisiid, "Guid"),
				KisiId_1: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.kisiId ?? this.props.screenInputs.kisiid, "Guid"),
				role_1: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.role ?? this.props.screenInputs.role, "string"),
				prm1_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "person_2575579_value", "value", "", "", "")), "string"),
				prm2_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "person_1642114_value", "value", "", "", "")), "string"),
				prm3_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "person_15520_value", "value", "", "key", "")), "string"),
				prm1_3: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "person_460914_value", "value", "SehirAll", "id", "tanim")), "string"),
				prm2_3: ReactSystemFunctions.convertToTypeByName("", "string"),
				prm3_3: ReactSystemFunctions.convertToTypeByName("", "string")
        }

		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "Person/PersonComponent_9544_onClick2_", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.ProfileIdByRoleAndUser = result?.data.profileIdByRoleAndUser;
			stateVars.KurumIDbyKisiIDRole = result?.data.kurumIDbyKisiIDRole;
			stateVars.DummySave = result?.data.dummySave;
			stateVars.DummySave = result?.data.dummySave;
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.PersonComponent_9544_onClick3_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
    PersonComponent_9544_onClick3_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;

			validations.push({ 
				isValid: (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "person_704424_value", "value", "", "", "")), null)),
				message: "*",
				formName: "person_704424_value",
				condition: (ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "person_409657_value", "value", "", "", "")), true))
			} as IValidationData);

validations.forEach((validation) => {
            if (validation.message === "Geçerli bir mail adresi giriniz.") {
                validation.message = ReactSystemFunctions.getEmailValidationMessage()
            }
        })

		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				prm1_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "person_704424_value", "value", "", "", "")), "string"),
				prm2_0: ReactSystemFunctions.convertToTypeByName("", "string"),
				prm3_0: ReactSystemFunctions.convertToTypeByName("", "string")
        }

if ((ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "person_409657_value", "value", "", "", "")), true))) {
            		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "Person/PersonComponent_9544_onClick3_", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.DummySave = result?.data.dummySave;
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.PersonComponent_9544_onClick4_();
                  resolve();
                });
          })

        } else {
            
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.PersonComponent_9544_onClick4_();
                  resolve();
                });
          })
        }


        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
    PersonComponent_9544_onClick4_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				prm1_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "person_360533_value", "value", "AktifKirilim1AltKirilim2leri", "id", "tanim")), "string"),
				prm2_0: ReactSystemFunctions.convertToTypeByName("", "string"),
				prm3_0: ReactSystemFunctions.convertToTypeByName("", "string")
        }

if ((ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, (stateVars.IsSinifExisting?.length > 0 ? stateVars.IsSinifExisting[0].isSinifExisting : this.state.IsSinifExisting?.length > 0 ? this.state.IsSinifExisting[0].isSinifExisting : null)), "1"))) {
            		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "Person/PersonComponent_9544_onClick4_", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.DummySave = result?.data.dummySave;
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.PersonComponent_9544_onClick5_();
                  resolve();
                });
          })

        } else {
            
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.PersonComponent_9544_onClick5_();
                  resolve();
                });
          })
        }


        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
    PersonComponent_9544_onClick_if5 = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;

			validations.push({ 
				isValid: (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "person_704424_value", "value", "", "", "")), null)),
				message: "*",
				formName: "person_704424_value",
				condition: (ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "person_409657_value", "value", "", "", "")), true))
			} as IValidationData);

validations.forEach((validation) => {
            if (validation.message === "Geçerli bir mail adresi giriniz.") {
                validation.message = ReactSystemFunctions.getEmailValidationMessage()
            }
        })
			validations.push({ 
				isValid: (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "person_2575579_value", "value", "", "", "")), null)),
				message: "*",
				formName: "person_2575579_value",
				condition: true
			} as IValidationData);

validations.forEach((validation) => {
            if (validation.message === "Geçerli bir mail adresi giriniz.") {
                validation.message = ReactSystemFunctions.getEmailValidationMessage()
            }
        })
			validations.push({ 
				isValid: (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "person_1642114_value", "value", "", "", "")), null)),
				message: "*",
				formName: "person_1642114_value",
				condition: true
			} as IValidationData);

validations.forEach((validation) => {
            if (validation.message === "Geçerli bir mail adresi giriniz.") {
                validation.message = ReactSystemFunctions.getEmailValidationMessage()
            }
        })
			validations.push({ 
				isValid: (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "person_15520_value", "value", "", "key", "")), null)),
				message: "This field is required",
				formName: "person_15520_value",
				condition: true
			} as IValidationData);

validations.forEach((validation) => {
            if (validation.message === "Geçerli bir mail adresi giriniz.") {
                validation.message = ReactSystemFunctions.getEmailValidationMessage()
            }
        })

		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				userName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "person_704424_value", "value", "", "", "")), "string"),
				firstName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "person_2575579_value", "value", "", "", "")), "string"),
				lastName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "person_1642114_value", "value", "", "", "")), "string"),
				roleNames_0: ReactSystemFunctions.convertToTypeByName(null, "string"),
				startingScreenId_0: ReactSystemFunctions.convertToTypeByName("94593018-6663-47a8-b1c3-9e3cff619781", "Guid"),
				phoneNumber_0: ReactSystemFunctions.convertToTypeByName(null, "string"),
				dontSendEmail_0: ReactSystemFunctions.convertToTypeByName(true, "boolean"),
				twoFactorAuthenticationType_0: ReactSystemFunctions.convertToTypeByName("", "number"),
				Adi_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "person_2575579_value", "value", "", "", "")), "string"),
				Eposta_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "person_704424_value", "value", "", "", "")), "string"),
				Soyadi_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "person_1642114_value", "value", "", "", "")), "string"),
				Telefon_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "person_8847930_value", "value", "", "", "")), "string"),
				Cinsiyet_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "person_15520_value", "value", "", "key", "")), "string"),
				DogumTarihi_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "person_770374_value", "value", "", "", "")), "Date"),
				MevcutKirilim2Id_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "person_360533_value", "value", "AktifKirilim1AltKirilim2leri", "id", "id")), "Guid"),
				SehirId_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "person_460914_value", "value", "SehirAll", "id", "id")), "Guid"),
				CreatedByProfileId_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].lastChosenProfileId : this.state.MyContext?.length > 0 ? this.state.MyContext[0].lastChosenProfileId : null)), "Guid"),
				OkulNumarasi_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "person_385822_value", "value", "", "", "")), "string"),
				Id_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.KisiSaveIdFinder?.length > 0 ? stateVars.KisiSaveIdFinder[0].kisiid : this.state.KisiSaveIdFinder?.length > 0 ? this.state.KisiSaveIdFinder[0].kisiid : null)), "Guid"),
				OkulBrans_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "person_410656_value", "value", "SelectOkulBransAll", "tanim", "tanim")), "string"),
				profilId_4: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].lastChosenProfileId : this.state.MyContext?.length > 0 ? this.state.MyContext[0].lastChosenProfileId : null)), "Guid"),
				kisiid_4: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].kisiId : this.state.MyContext?.length > 0 ? this.state.MyContext[0].kisiId : null)), "Guid"),
				currentrole_4: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].rolename : this.state.MyContext?.length > 0 ? this.state.MyContext[0].rolename : null)), "string"),
				kurumID_4: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].kurumId : this.state.MyContext?.length > 0 ? this.state.MyContext[0].kurumId : null)), "Guid"),
				templateId_5: ReactSystemFunctions.convertToTypeByName("77e1b837-fbfa-41b4-b5a3-baf6dcff9dfb", "string"),
				receivers_5: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "person_704424_value", "value", "", "", "")), "string"),
				subject_5: ReactSystemFunctions.convertToTypeByName("MeMap Fitness Platformuna Davet Edildiniz", "string"),
				sender_5: ReactSystemFunctions.convertToTypeByName("", "string"),
				cc_5: ReactSystemFunctions.convertToTypeByName("", "string"),
				bcc_5: ReactSystemFunctions.convertToTypeByName("", "string"),
				attachmentFileName_5: ReactSystemFunctions.convertToTypeByName("", "string"),
				attachmentBase64_5: ReactSystemFunctions.convertToTypeByName("", "string"),
				language_5: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].lang : this.state.MyContext?.length > 0 ? this.state.MyContext[0].lang : null)), "string"),
				providerEmail_5: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "person_704424_value", "value", "", "", "")), "string"),
				ad_5: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "person_2575579_value", "value", "", "", "")), "string"),
				soyad_5: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "person_1642114_value", "value", "", "", "")), "string")
        }

		KuikaAppManager.showSpinner(this);
		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "Person/PersonComponent_9544_onClick_if5", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.SaveUser = result?.data.saveUser;
			stateVars.SaveRecord = result?.data.saveRecord_10;
			stateVars.TokenOfUser = result?.data.tokenOfUser;
			stateVars.BaseUrl = result?.data.baseUrl;
			stateVars.KullaniciMailLogoUrl = result?.data.kullaniciMailLogoUrl;
			stateVars.SendMail = result?.data.sendMail;
			this.props.form.setFieldsValue(formVars);
			this.setState(stateVars);
			KuikaAppManager.hideSpinner(this);



        this.setPageInitLoaded(true);
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }



    PersonComponent_9544_onClick_else5 = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;

			validations.push({ 
				isValid: (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "person_2575579_value", "value", "", "", "")), null)),
				message: "*",
				formName: "person_2575579_value",
				condition: true
			} as IValidationData);

validations.forEach((validation) => {
            if (validation.message === "Geçerli bir mail adresi giriniz.") {
                validation.message = ReactSystemFunctions.getEmailValidationMessage()
            }
        })
			validations.push({ 
				isValid: (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "person_704424_value", "value", "", "", "")), null)),
				message: "*",
				formName: "person_704424_value",
				condition: (ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "person_409657_value", "value", "", "", "")), true))
			} as IValidationData);

validations.forEach((validation) => {
            if (validation.message === "Geçerli bir mail adresi giriniz.") {
                validation.message = ReactSystemFunctions.getEmailValidationMessage()
            }
        })
			validations.push({ 
				isValid: (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "person_1642114_value", "value", "", "", "")), null)),
				message: "*",
				formName: "person_1642114_value",
				condition: true
			} as IValidationData);

validations.forEach((validation) => {
            if (validation.message === "Geçerli bir mail adresi giriniz.") {
                validation.message = ReactSystemFunctions.getEmailValidationMessage()
            }
        })
			validations.push({ 
				isValid: (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "person_15520_value", "value", "", "key", "")), null)),
				message: "This field is required",
				formName: "person_15520_value",
				condition: true
			} as IValidationData);

validations.forEach((validation) => {
            if (validation.message === "Geçerli bir mail adresi giriniz.") {
                validation.message = ReactSystemFunctions.getEmailValidationMessage()
            }
        })

		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				Id_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.KisiSaveIdFinder?.length > 0 ? stateVars.KisiSaveIdFinder[0].kisiid : this.state.KisiSaveIdFinder?.length > 0 ? this.state.KisiSaveIdFinder[0].kisiid : null)), "Guid"),
				Adi_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "person_2575579_value", "value", "", "", "")), "string"),
				Eposta_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "person_704424_value", "value", "", "", "")), "string"),
				Soyadi_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "person_1642114_value", "value", "", "", "")), "string"),
				Telefon_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "person_8847930_value", "value", "", "", "")), "string"),
				Cinsiyet_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "person_15520_value", "value", "", "key", "")), "string"),
				DogumTarihi_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "person_770374_value", "value", "", "", "")), "Date"),
				MevcutKirilim2Id_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "person_360533_value", "value", "AktifKirilim1AltKirilim2leri", "id", "id")), "Guid"),
				SehirId_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "person_460914_value", "value", "SehirAll", "id", "id")), "Guid"),
				CreatedByProfileId_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].lastChosenProfileId : this.state.MyContext?.length > 0 ? this.state.MyContext[0].lastChosenProfileId : null)), "Guid"),
				OkulNumarasi_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "person_385822_value", "value", "", "", "")), "string"),
				OkulBrans_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "person_410656_value", "value", "SelectOkulBransAll", "tanim", "tanim")), "string")
        }

		KuikaAppManager.showSpinner(this);
		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "Person/PersonComponent_9544_onClick_else5", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.SaveRecord = result?.data.saveRecord_11;
			this.props.form.setFieldsValue(formVars);
			this.setState(stateVars);
			KuikaAppManager.hideSpinner(this);



        this.setPageInitLoaded(true);
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }



		PersonComponent_9544_onClick5_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
if ((ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "person_409657_value", "value", "", "", "")), true))) {
            isErrorOccurred = await this.PersonComponent_9544_onClick_if5();
                if (isErrorOccurred) return true;
        } else {
            isErrorOccurred = await this.PersonComponent_9544_onClick_else5();
                if (isErrorOccurred) return true;
        }


		this.props.form.setFieldsValue(formVars);
		
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.PersonComponent_9544_onClick6_();
                  resolve();
                });
          })

            
            return isErrorOccurred;
        }
    PersonComponent_9544_onClick_if6 = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				Id_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.profilID ?? this.props.screenInputs.profilid, "Guid"),
				KurumId_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.kurumID ?? this.props.screenInputs.kurumid, "Guid"),
				Rolename_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.role ?? this.props.screenInputs.role, "string"),
				KisiId_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.KisiSaveIdFinder?.length > 0 ? stateVars.KisiSaveIdFinder[0].kisiid : this.state.KisiSaveIdFinder?.length > 0 ? this.state.KisiSaveIdFinder[0].kisiid : null)), "Guid"),
				Kirilim2Ids_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "person_360533_value", "value", "AktifKirilim1AltKirilim2leri", "id", "id")), "string")
        }

		KuikaAppManager.showSpinner(this);
		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "Person/PersonComponent_9544_onClick_if6", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.SaveRecord = result?.data.saveRecord_12;
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.PersonComponent_9544_onClick_if61_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
    PersonComponent_9544_onClick_if61_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				profileId_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.profilID ?? this.props.screenInputs.profilid, "Guid"),
				rolename_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.role ?? this.props.screenInputs.role, "string"),
				kurumId_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.kurumID ?? this.props.screenInputs.kurumid, "Guid")
        }

if ((ReactSystemFunctions.isNotEqualTo(ReactSystemFunctions.toString(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].rolename : this.state.MyContext?.length > 0 ? this.state.MyContext[0].rolename : null)), "Rol_MemapAdmin"))) {
            		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "Person/PersonComponent_9544_onClick_if61_", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.ProfileHesapSave = result?.data.profileHesapSave;
			this.props.form.setFieldsValue(formVars);
			this.setState(stateVars);
			KuikaAppManager.hideSpinner(this);

        } else {
            KuikaAppManager.hideSpinner(this);
        }


        this.setPageInitLoaded(true);
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }





    PersonComponent_9544_onClick_else6 = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				Id_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.ProfileIdByRoleAndUser?.length > 0 ? stateVars.ProfileIdByRoleAndUser[0].profilId : this.state.ProfileIdByRoleAndUser?.length > 0 ? this.state.ProfileIdByRoleAndUser[0].profilId : null)), "Guid"),
				KurumId_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.kurumID ?? this.props.screenInputs.kurumid, "Guid"),
				Rolename_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.role ?? this.props.screenInputs.role, "string"),
				KisiId_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.KisiSaveIdFinder?.length > 0 ? stateVars.KisiSaveIdFinder[0].kisiid : this.state.KisiSaveIdFinder?.length > 0 ? this.state.KisiSaveIdFinder[0].kisiid : null)), "Guid"),
				Kirilim2Ids_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "person_360533_value", "value", "AktifKirilim1AltKirilim2leri", "id", "id")), "string")
        }

		KuikaAppManager.showSpinner(this);
		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "Person/PersonComponent_9544_onClick_else6", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.SaveRecord = result?.data.saveRecord_13;
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.PersonComponent_9544_onClick_else61_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
    PersonComponent_9544_onClick_else61_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				profileId_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.profilID ?? this.props.screenInputs.profilid, "Guid"),
				rolename_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.role ?? this.props.screenInputs.role, "string"),
				kurumId_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.kurumID ?? this.props.screenInputs.kurumid, "Guid")
        }

if ((ReactSystemFunctions.isNotEqualTo(ReactSystemFunctions.toString(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].rolename : this.state.MyContext?.length > 0 ? this.state.MyContext[0].rolename : null)), "Rol_MemapAdmin"))) {
            		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "Person/PersonComponent_9544_onClick_else61_", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.ProfileHesapSave = result?.data.profileHesapSave;
			this.props.form.setFieldsValue(formVars);
			this.setState(stateVars);
			KuikaAppManager.hideSpinner(this);

        } else {
            KuikaAppManager.hideSpinner(this);
        }


        this.setPageInitLoaded(true);
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }





		PersonComponent_9544_onClick6_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
if ((ReactSystemFunctions.isEmpty(ReactSystemFunctions.value(this, (stateVars.ProfileIdByRoleAndUser?.length > 0 ? stateVars.ProfileIdByRoleAndUser[0].profilId : this.state.ProfileIdByRoleAndUser?.length > 0 ? this.state.ProfileIdByRoleAndUser[0].profilId : null)), null))) {
            isErrorOccurred = await this.PersonComponent_9544_onClick_if6();
                if (isErrorOccurred) return true;
        } else {
            isErrorOccurred = await this.PersonComponent_9544_onClick_else6();
                if (isErrorOccurred) return true;
        }


		this.props.form.setFieldsValue(formVars);
		
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.PersonComponent_9544_onClick7_();
                  resolve();
                });
          })

            
            return isErrorOccurred;
        }
    PersonComponent_9544_onClick7_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				kurumId_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.kurumID ?? this.props.screenInputs.kurumid, "Guid")
        }

		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "Person/PersonComponent_9544_onClick7_", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.ManageIsOrtagi = result?.data.manageIsOrtagi;
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.PersonComponent_9544_onClick8_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
		PersonComponent_9544_onClick8_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }

				stateVars.GoBack = await ReactSystemFunctions.goBack(this);
				formVars.person_519225_value = ReactSystemFunctions.toString(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].kirilim2Adi : this.state.MyContext?.length > 0 ? this.state.MyContext[0].kirilim2Adi : null));


		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }

















    async callCallbackFunction(diId) {
        let isErrorOccurred = false;

    }
}
