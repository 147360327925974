import { WorkflowWithStepsDto } from "@kuika/kuika-cl-model/src/dtos/workflow.dto";
import Viewer from "bpmn-js/lib/Viewer";
import { Guid } from "guid-typescript";
import _ from "lodash";
import { PickerSharedProps } from "rc-picker/es/Picker";
import React, { PureComponent, ReactNode } from "react";
import ReactDOM from "react-dom";
import { CSSProperties } from "styled-components";
import { WorkflowService } from "../../../services/workflow-service";
import withCommonEvents from "../../../shared/hoc/with-common-events";
import { KuikaAppManager } from "../../../shared/utilty/kuika-app-manager";
import { CommonProps } from "../common-props";

declare let window: any;
interface IProcessAutomationProps extends PickerSharedProps<any> {
  processInstance: string;
  style?: CSSProperties;
}

interface IProcessAutomationState {
  uniqueKey: Guid;
  isOpen: boolean;
  modelerRef: any;
  modeler: any | undefined;
  workflowWithSteps: WorkflowWithStepsDto;
}

class ProcessAutomation extends PureComponent<IProcessAutomationProps & CommonProps, IProcessAutomationState> {
  constructor(props: IProcessAutomationProps) {
    super(props);
    this.state = {
      uniqueKey: Guid.create(),
      isOpen: false,
      modelerRef: React.createRef(),
      modeler: undefined,
      workflowWithSteps: undefined
    };
  }

  componentDidMount = () => {
    const node: any = ReactDOM.findDOMNode(this);
    if (node && node.style) {
      node.style.width = `${this.props.style?.width}` ?? "100%";
      node.style.height = `${this.props.style?.height}` ?? "100%";
    }

    const isDesignTime = window.kuika && window.kuika.isDesignTime;
    if (isDesignTime === true) {
      return;
    }

    this.getWorkflowAndImportXml();
  };

  getProps = () => {
    const props: any = _.clone(this.props);
    const style: any = props.style || {};
    const isDesignTime = window.kuika && window.kuika.isDesignTime;
    props.style = style;
    return props;
  };

  componentDidUpdate(prevProps, prevState) {
    const isDesignTime = window.kuika && window.kuika.isDesignTime;
    if (isDesignTime === true) {
      return;
    }
    if (prevProps.processInstance !== this.props.processInstance) {
      this.getWorkflowAndImportXml();
    }
    this.paintWorkflow();
  }

  getWorkflowAndImportXml = () => {
    KuikaAppManager.showSpinner(this);
    if (!this.state.modeler) {
      let instanceId = this.props.processInstance;
      WorkflowService.GetWorkflowWithSteps(instanceId).then((res) => {
        if (res.data) {
          let modeler: any = new Viewer({
            container: this.state.modelerRef.current ? this.state.modelerRef.current : undefined,
            keyboard: {
              bindTo: document.body
            },
            readOnly: true,
            height: "100%",
            width: "100%"
          });

          modeler.importXML(res.data.kWorkflowRegistry.diagram).then(() => {
            modeler.get("canvas").zoom("fit-viewport", "auto");
            document.querySelector("#modeler-container > div > a").remove();
            this.setState({ ...this.state, modeler, workflowWithSteps: res.data });
          });
        }
      });
    }

    KuikaAppManager.hideSpinner(this);
  };

  paintWorkflow = () => {
    if (this.state.modeler) {
      const rootElements = this.state.modeler.getDefinitions()?.rootElements;
      let processElement = rootElements.find((element: any) => element.$type === "bpmn:Process");
      let startElement = processElement.flowElements.find((element: any) => element.$type === "bpmn:StartEvent");
      this.state.modeler.get("canvas").addMarker(startElement.id, "completed-node-color");

      processElement.flowElements.forEach((element: any) => {
        this.state.modeler.get("canvas").addMarker(element.id, "not-completed-node-color");
      });

      this.findCompletedElementAndPaintGreen(startElement, []);

      // add a tooltip to the elements
      this.state.modeler.get("eventBus").on("element.hover", (event: any) => {
        let element = event.element;
        let executionPointer = this.state.workflowWithSteps.persistedExecutionPointers.find(
          (p) => p.stepName === element.businessObject.name
        );
        if (executionPointer) {
          let completeUsername = JSON.parse(executionPointer.contextItem).ExtraParameters?.CompletedUserFullName;
          let tooltip = document.createElement("div");
          tooltip.id = "step-details-tooltip";
          tooltip.style.position = "absolute";
          tooltip.style.backgroundColor = "black";
          tooltip.style.color = "black";
          tooltip.style.backgroundColor = "white";
          tooltip.style.fontSize = "16px";
          tooltip.style.padding = "5px";
          tooltip.style.borderRadius = "8px";
          tooltip.style.zIndex = "1000";
          tooltip.style.width = "215px";
          tooltip.style.boxShadow = "rgba(0, 0, 0, 0.15) 0px 3px 8px;";
          tooltip.style.top = `${event.originalEvent.clientY + 10}px`;
          tooltip.style.left = `${event.originalEvent.clientX + 10}px`;

          let header = document.createElement("div");
          header.style.fontWeight = "510";
          header.style.fontSize = "18px";
          header.style.lineHeight = "20px";
          header.style.color = "#293F5B";
          header.style.padding = "14px";
          header.style.paddingLeft = "12px";
          header.innerHTML = `${element.businessObject.name}`;

          let completedUserElement = document.createElement("div");
          completedUserElement.style.fontWeight = "510";
          completedUserElement.style.fontSize = "16px";
          completedUserElement.style.lineHeight = "20px";
          completedUserElement.style.color = "#6E7E96";
          completedUserElement.style.padding = "10px";
          completedUserElement.style.borderTop = "1px solid #F1F2F4";
          completedUserElement.innerHTML = completeUsername ? `Completed ${completeUsername}` : "Completed -";

          let startTimeElement = document.createElement("div");
          startTimeElement.style.fontWeight = "510";
          startTimeElement.style.fontSize = "16px";
          startTimeElement.style.lineHeight = "14px";
          startTimeElement.style.color = "#6E7E96";
          startTimeElement.style.padding = "10px";
          startTimeElement.style.borderTop = "1px solid #F1F2F4";

          let startTime = executionPointer.startTime ? new Date(executionPointer.startTime) : null;

          if (executionPointer.stepName === "END") {
            const a = this.state.workflowWithSteps.persistedExecutionPointers.find(
              (p) => p.id === executionPointer.predecessorId
            );

            startTime = a.startTime ? new Date(a.startTime) : null;
          }

          startTimeElement.innerHTML = startTime ? `Start ${this.formatDate(startTime)}` : "Start -";

          let endTimeElement = document.createElement("div");
          endTimeElement.style.fontWeight = "510";
          endTimeElement.style.fontSize = "16px";
          endTimeElement.style.lineHeight = "14px";
          endTimeElement.style.color = "#6E7E96";
          endTimeElement.style.padding = "10px";
          endTimeElement.style.borderTop = "1px solid #F1F2F4";

          let endTime = executionPointer.endTime ? new Date(executionPointer.endTime) : null;

          if (executionPointer.stepName === "END") {
            const a = this.state.workflowWithSteps.persistedExecutionPointers.find(
              (p) => p.id === executionPointer.predecessorId
            );

            endTime = a.endTime ? new Date(a.endTime) : null;
          }

          endTimeElement.innerHTML = endTime ? `End ${this.formatDate(endTime)}` : "End -";

          tooltip.appendChild(header);
          tooltip.appendChild(completedUserElement);
          tooltip.appendChild(startTimeElement);
          tooltip.appendChild(endTimeElement);

          // remove the tooltip if it already exists
          if (event.element.tooltip) {
            event.element.tooltip.remove();
          }

          document.body.appendChild(tooltip);
          event.element.tooltip = tooltip;
        }
      });

      this.state.modeler.get("eventBus").on("element.out", (event: any) => {
        if (event.element.tooltip) {
          event.element.tooltip.remove();
        }
      });
    }
  };

  paintGreen = (bpmnElementIds: string[]) => {
    bpmnElementIds.forEach((elementId) => {
      this.state.modeler.get("canvas").addMarker(elementId, "completed-node-color");
    });
  };

  formatDate(date: Date): string {
    const offset = date.getTimezoneOffset();
    const adjustedDate = new Date(date.getTime() - offset * 60 * 1000);
    const day = String(adjustedDate.getDate()).padStart(2, "0");
    const month = String(adjustedDate.getMonth() + 1).padStart(2, "0");
    const year = adjustedDate.getFullYear();
    const hours = String(adjustedDate.getHours()).padStart(2, "0");
    const minutes = String(adjustedDate.getMinutes()).padStart(2, "0");
    const seconds = String(adjustedDate.getSeconds()).padStart(2, "0");
    return `${day}.${month}.${year} - ${hours}:${minutes}:${seconds}`;
  }

  findCompletedElementAndPaintGreen(bpmnElement: any, completedElementIds: string[]) {
    if (bpmnElement.$type === "bpmn:StartEvent") {
      completedElementIds.push(bpmnElement.id);
      bpmnElement.outgoing.forEach((element) => {
        this.findCompletedElementAndPaintGreen(element, structuredClone(completedElementIds));
      });
    } else if (bpmnElement.$type === "bpmn:EndEvent") {
      let executionPointer = this.state.workflowWithSteps.persistedExecutionPointers.find(
        (p) => p.stepName === bpmnElement.name
      );
      if (executionPointer) {
        completedElementIds.push(bpmnElement.id);
        this.paintGreen(completedElementIds);
      }
    } else if (bpmnElement.$type === "bpmn:SequenceFlow") {
      completedElementIds.push(bpmnElement.id);
      this.findCompletedElementAndPaintGreen(bpmnElement.targetRef, completedElementIds);
    } else if (bpmnElement.$type === "bpmn:ExclusiveGateway") {
      completedElementIds.push(bpmnElement.id);
      bpmnElement.outgoing.forEach((element) => {
        this.findCompletedElementAndPaintGreen(element, structuredClone(completedElementIds));
      });
    } else if (bpmnElement.$type === "bpmn:ParallelGateway") {
      completedElementIds.push(bpmnElement.id);
      bpmnElement.outgoing.forEach((element) => {
        this.findCompletedElementAndPaintGreen(element, structuredClone(completedElementIds));
      });
    } else if (bpmnElement.$type === "bpmn:UserTask") {
      let executionPointer = this.state.workflowWithSteps.persistedExecutionPointers.find(
        (p) => p.stepName === bpmnElement.name
      );
      completedElementIds.push(bpmnElement.id);
      if (executionPointer && executionPointer.endTime) {
        this.paintGreen(completedElementIds);

        bpmnElement.outgoing.forEach((element) => {
          this.findCompletedElementAndPaintGreen(element, []);
        });
      }
    } else if (bpmnElement.$type === "bpmn:Task") {
      let executionPointer = this.state.workflowWithSteps.persistedExecutionPointers.find(
        (p) => p.stepName === bpmnElement.name
      );
      completedElementIds.push(bpmnElement.id);
      if (executionPointer && executionPointer.endTime) {
        this.paintGreen(completedElementIds);

        bpmnElement.outgoing.forEach((element) => {
          this.findCompletedElementAndPaintGreen(element, []);
        });
      }
    }
  }

  render(): ReactNode {
    const isDesignTime = window.kuika?.isDesignTime;

    if (isDesignTime === true) {
      return (
        <div
          {...this.getProps()}
          className="kuika_mfe_container"
          style={{ justifyContent: "center", alignItems: "center" }}
        >
          <span style={{ fontSize: "20px" }}>Process Automation</span>
        </div>
      );
    }
    return (
      <div {...this.getProps()} style={{ height: this.props.style.height ?? "500px" }}>
        <div style={{ width: "100%", height: "100%" }} ref={this.state.modelerRef} id="modeler-container"></div>
      </div>
    );
  }
}
const processAutomation = withCommonEvents(ProcessAutomation);
export { processAutomation as ProcessAutomation };
