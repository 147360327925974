import { Divider as AntdDivider, DividerProps as AntdDividerProps } from "antd/es";
import { Guid } from "guid-typescript";
import _ from "lodash";
import React, { CSSProperties, PureComponent, ReactNode } from "react";
import withCommonEvents from "../../../shared/hoc/with-common-events";
import { CommonProps } from "../common-props";

interface DividerProps extends AntdDividerProps {
  label: string;
  dashed: boolean;
  orientation: "left" | "right" | "center";
  type: "horizontal" | "vertical";
  style: CSSProperties;
}

interface DividerState {
  uniqueKey: Guid;
}

declare let window: any;

class Divider extends PureComponent<DividerProps & CommonProps, DividerState> {
  private memoizedDynamicCssResult = "";

  constructor(props: DividerProps) {
    super(props);
    this.state = {
      uniqueKey: Guid.create()
    };
  }

  componentDidMount = () => {
    this.setDynamicStyle();
  };

  getClassName = () => {
    // if(props.style.borderStyle){
    //   return props.className ? `${props.className} kuika-divider-component__divider-line-${props.style.borderStyle}` : `kuika-divider-component__divider-line-${props.style.borderStyle}`
    // }else{
    let result = "";
    if (!this.state.uniqueKey) {
      return result;
    }
    result = `${result}kdivider_${this.state.uniqueKey.toString().substring(0, 8)}`;
    return result;
    // }
  };

  getProps = () => {
    const props: any = _.clone(this.props);
    const style: any = _.clone(this.props.style);
    if (style.display) {
      delete style.display;
    }
    props.style = style;

    const className = this.getClassName();
    if (className) props.className = className;

    if (props.label) {
      delete props.label;
    }
    return props;
  };

  getDividerBorderWidth = (): CSSProperties["borderWidth"] => {
    const style: React.CSSProperties = _.clone(this.props.style);
    if (!style) return;

    if (style.borderWidth) {
      return style.borderWidth;
    }

    if (style.borderTopWidth) {
      return style.borderTopWidth;
    }

    if (style.borderRightWidth) {
      return style.borderRightWidth;
    }

    if (style.borderBottomWidth) {
      return style.borderBottomWidth;
    }

    if (style.borderLeftWidth) {
      return style.borderLeftWidth;
    }

    return;
  };

  getOrientation = () => {
    const result = "left";
    if (this.props && this.props.style) {
      const style: any = _.clone(this.props.style);
      if (style.textAlign) {
        if (style.textAlign.includes("center")) return "center";
        if (style.textAlign.includes("left")) return "left";
        if (style.textAlign.includes("right")) return "right";
      }
    }
    return result;
  };

  getDynamicCss = (): string => {
    const className: string = this.getClassName();
    if (!className || className.length === 0) {
      return "";
    }
    let result = "";

    const isReportDesigner = window.kuika?.dashboardState === 17;

    const { borderColor, height } = this.props.style;
    const borderWidth = this.getDividerBorderWidth();
    if (borderColor) {
      result += `.${className.trim()} {
          border-width: 0px !important;
        }`;
    }
    if (height || borderWidth) {
      result += `.ant-divider-horizontal.ant-divider-with-text-left:before {
        top: 0% !important;
        transform: unset;
        ${borderWidth ? `border-width: ${borderWidth}px !important;` : ""}
      }`;
      result += `.ant-divider-horizontal.ant-divider-with-text-left:after {
        top: 0% !important;
        transform: unset;
        border-width: ${borderWidth}px !important;
      }`;
    }
    if (isReportDesigner) {
      result += `.${className.trim()} .ant-divider-inner-text {
        display: none !important;
      }`;
      result += `.${className.trim()} {
        margin: 0 !important;
        height: 12px !important;
      }`;
      if (!borderWidth) {
        result += `.${className.trim()} {
          border-width: 1px !important;
        }`;
      }
    }

    return result;
  };

  setDynamicStyle = () => {
    const uniquekey = this.state.uniqueKey?.toString();
    if (!uniquekey) {
      return;
    }
    const isDesignTime = window.kuika?.isDesignTime;
    if (this.memoizedDynamicCssResult !== "" && !isDesignTime) {
      return this.memoizedDynamicCssResult;
    }
    const dynamic_style = document.getElementById("dynamic_style");
    if (dynamic_style && dynamic_style.innerHTML?.indexOf(uniquekey) === -1) {
      const generatedCss = this.getDynamicCss();
      dynamic_style.innerHTML = `${dynamic_style.innerHTML}
        ${generatedCss}`;
      this.memoizedDynamicCssResult = generatedCss;
    }
  };

  render(): ReactNode {
    return (
      <AntdDivider {...this.getProps()} orientation={this.getOrientation()}>
        {this.props.label ? this.props.label : " "}
      </AntdDivider>
    );
  }
}

const kIframe = withCommonEvents(Divider);
export { kIframe as Divider };
