import { Guid } from "guid-typescript";
import { PureComponent } from "react";
import withCommonEvents from "../../../../shared/hoc/with-common-events";
import { CommonProps } from "../../common/common-props";

interface MixedChartLineItemProps {
  type: any;
  dataSource: any[];
  color: any;
  size: number;
  dashLength: number;
  dashSpacing: number;
}

interface MixedChartLineItemState {
  uniqueKey: Guid;
}

class MixedChartLineItem extends PureComponent<MixedChartLineItemProps & CommonProps, MixedChartLineItemState> {
  constructor(props: MixedChartLineItemProps) {
    super(props);
    this.state = {
      uniqueKey: Guid.create()
    };
  }
}

const mixedChartLineItem = withCommonEvents(MixedChartLineItem);
export { mixedChartLineItem as MixedChartLineItem };
