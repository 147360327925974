import _ from "lodash";
import moment from "moment";
import { LabelHTMLAttributes, PureComponent, ReactNode } from "react";
import VMasker from "vanilla-masker";
import { CommonProps } from "../../../components/web-components/common/common-props";
import withCommonEvents from "../../../shared/hoc/with-common-events";

interface IKLabelProps extends LabelHTMLAttributes<any> {
  value?: string;
  defaultText?: string;
  style?: any;
  formatter?:
    | "money"
    | "money-var-1"
    | "phone"
    | "percent"
    | "fractional-2"
    | "fractional-5"
    | "date"
    | "time"
    | "datetime"
    | "date-var-1"
    | "time-ago"
    | "short-date"
    | "round"
    | "money-tl";
  maxLine: number;
}

interface IKLabelState {}

class KLabel extends PureComponent<IKLabelProps & CommonProps, IKLabelState> {
  constructor(props: IKLabelProps) {
    super(props);
    this.state = {};
  }

  getFormattedValue() {
    const { formatter, value, defaultText } = this.props;
    if (
      defaultText != undefined &&
      defaultText != "" &&
      defaultText != null &&
      (value === "" || value === undefined || value === null)
    ) {
      return defaultText;
    }
    if (formatter && value) {
      switch (formatter) {
        case "money":
          return VMasker.toMoney(this.props.value, {
            precision: 2,
            separator: ",",
            delimiter: ".",
            zeroCents: true
          });

        case "money-var-1":
          return VMasker.toMoney(this.props.value, {
            precision: 0,
            separator: ",",
            delimiter: ".",
            zeroCents: true
          });

        case "money-tl":
          return VMasker.toMoney(this.props.value, {
            precision: 0,
            separator: ",",
            delimiter: ".",
            zeroCents: true,
            suffixUnit: "₺"
          });

        case "phone":
          return VMasker.toPattern(this.props.value, "(999) 999-9999");

        case "percent":
          return VMasker.toPattern(this.props.value, "##0,00%");

        case "fractional-2":
          const number2 = Number(value);
          return isNaN(number2) ? value : number2.toFixed(2);

        case "fractional-5":
          const number5 = Number(value);
          return isNaN(number5) ? value : number5.toFixed(5);

        case "datetime":
          const datetimeString = this.parseDateString(value, {
            formatStr: "DD/MM/YYYY HH:mm:ss"
          });
          return datetimeString;

        case "date":
          const dateString = this.parseDateString(value, {
            formatStr: "DD/MM/YYYY"
          });
          return dateString;

        case "date-var-1":
          const dateVar1String = this.parseDateString(value, {
            formatStr: "DD:MM:YYYY"
          });
          return dateVar1String;

        case "time":
          const timeString = this.parseDateString(value, {
            formatStr: "HH:mm:ss"
          });
          return timeString;

        case "short-date":
          const shortDateString = this.parseDateString(value, {
            formatStr: "MMM Do"
          });
          return shortDateString;

        case "time-ago":
          const timeAgoString = this.parseDateString(value, {
            formatStr: "DD/MM/YYYY HH:mm:ss",
            timeAgo: true
          });
          return timeAgoString;

        default:
          return value;
      }
    } else {
      return value;
    }
  }

  parseDateString = (value: any, arg1: { formatStr: string; timeAgo?: boolean }) => {
    const date = moment(value!.replace(/(^")|("$)/g, ""), [
      arg1.formatStr,
      "YYYY.MM.DD hh:mm:ss",
      "YYYY-MM-DD hh:mm:ss",
      "DD/MM/YYYY HH:mm:ss",
      "YYYY.MM.DD",
      "YYYY-MM-DD",
      "DD/MM/YYYY"
    ]);
    try {
      if (arg1.timeAgo) {
        return date.fromNow();
      }
      let result: any = date.format(arg1.formatStr);
      if (result.indexOf("Invalid") > -1) {
        result = value;
      }
      return result;
    } catch (ex: any) {
      return value;
    }
  };

  getStyleProp = () => {
    let style: any = {};

    if (this.props.style) {
      style = _.clone(this.props.style);
    }

    if (this.props.showCursorPointer) {
      style.cursor = "pointer";
    }

    if (this.props.maxLine && this.props.maxLine > 0) {
      style.overflow = "hidden";
      style.display = "-webkit-box";
      style.WebkitLineClamp = this.props.maxLine;
      style.lineClamp = this.props.maxLine;
      style.WebkitBoxOrient = "vertical";
    }

    return style;
  };

  getProps = () => {
    const props: any = _.clone(this.props);
    if (props.style) {
      delete props.style;
    }
    return props;
  };

  render(): ReactNode {
    return (
      <>
        <label {...this.getProps()} style={{ display: "inline-block", ...this.getStyleProp() }}>
          {this.getFormattedValue()}
        </label>
      </>
    );
  }
}

const kLabel = withCommonEvents(KLabel);
export { kLabel as KLabel };
