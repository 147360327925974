import { Button, Result } from "antd/es";
import * as React from "react";
import { KNavigator, withHistory } from "../../shared/hoc/with-history";

export interface ILogoState {}

export interface ILogoProps {
  navigator: KNavigator;
}

class NoMatch404 extends React.PureComponent<ILogoProps, ILogoState> {
  componentDidMount() {
    setTimeout(() => {
      this.props.navigator.gotoRoot();
    }, 3000);
  }

  render() {
    return (
      <>
        <Result
          status="404"
          title="404"
          subTitle="Page not found, redirecting."
          extra={<Button type="primary">Back Home</Button>}
        />
      </>
    );
  }
}

const noMatch404 = withHistory(NoMatch404);
export { noMatch404 as NoMatch404 };
