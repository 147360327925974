import { PureComponent, ReactNode } from "react";
import ReactDOM from "react-dom";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import withCommonEvents from "../../../shared/hoc/with-common-events";
import { CommonProps } from "../common-props";
import { TextInput, TextboxProps } from "../text-input/text-input";

interface PlacesAutoComplateInputProps extends TextboxProps {
  value: any;
  onSelectPlace: () => void;
  onChange?: (value?: any) => void;
}

interface TextboxState {
  address: string;
  lat: string;
  lng: string;
}

class PlacesAutocompleteInput extends PureComponent<PlacesAutoComplateInputProps & CommonProps, TextboxState> {
  addressResult: string = "";

  constructor(props: PlacesAutoComplateInputProps) {
    super(props);
    this.state = props.value || {
      address: "",
      lat: "",
      lng: ""
    };
  }

  componentDidMount = () => {
    this.handleComponentStyling();
  };

  componentDidUpdate = () => {
    this.handleComponentStyling();
  };

  handleComponentStyling = () => {
    const node = ReactDOM.findDOMNode(this) as Element;
    const locationSearchInput = node.querySelector(".location-search-input") as HTMLElement;
    let dropdownContainer: any;
    if (locationSearchInput && locationSearchInput.parentElement) {
      dropdownContainer = locationSearchInput.parentElement.children[1];
      dropdownContainer.style.width = `${locationSearchInput.offsetWidth}px`;
    }
  };

  getLatitude = () => {
    return this.state.lat;
  };

  getLongitude = () => {
    return this.state.lng;
  };

  getAddress = () => {
    return this.state.address;
  };

  handleChange = (address) => {
    this.setState({ address, lat: "", lng: "" });
  };

  handleSelect = async (address) => {
    const results = await geocodeByAddress(address);
    if (results && results.length > 0 && results[0].formatted_address) {
      this.addressResult = results[0].formatted_address;
      if (results) {
        const latLng = await getLatLng(results[0]);
        if (latLng != undefined) {
          this.setState({ address: this.addressResult, lat: latLng.lat, lng: latLng.lng }, () => {
            if (this.props.onSelectPlace) {
              this.props.onSelectPlace();
            }
            if (this.props.onChange) {
              this.props.onChange({ address: this.addressResult, lat: latLng.lat, lng: latLng.lng });
            }
          });
        }
      }
    }
  };

  render(): ReactNode {
    return (
      <>
        <PlacesAutocomplete value={this.state.address} onChange={this.handleChange} onSelect={this.handleSelect}>
          {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
            <div style={{ display: this.props.style?.display === "inline" ? "inline-block" : "block" }}>
              <TextInput
                {...this.props}
                {...getInputProps({
                  placeholder: this.props.placeholder,
                  className: "location-search-input"
                })}
              />
              <div className="autocomplete-dropdown-container" style={{ position: "absolute", zIndex: 9999 }}>
                {loading && <div>Loading...</div>}
                {suggestions?.map((suggestion) => {
                  const className = suggestion.active ? "suggestion-item--active" : "suggestion-item";
                  const style = suggestion.active
                    ? { backgroundColor: "#fafafa", cursor: "pointer" }
                    : { backgroundColor: "#ffffff", cursor: "pointer" };
                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style
                      })}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
      </>
    );
  }
}

const placesAutocompleteInput = withCommonEvents(PlacesAutocompleteInput);
export { placesAutocompleteInput as PlacesAutocompleteInput };
