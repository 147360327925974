import { PureComponent, ReactNode } from "react";
import { CommonProps } from "../../../common-props";

type GoogleMapsMarkerProps = CommonProps;

interface GoogleMapsMarkerState {}

export class GoogleMapsMarker extends PureComponent<GoogleMapsMarkerProps, GoogleMapsMarkerState> {
  constructor(props: GoogleMapsMarkerProps) {
    super(props);
  }

  render(): ReactNode {
    return <div {...this.props} />;
  }
}
