import { Collapse as AntdCollapse } from "antd/es";
import { Guid } from "guid-typescript";
import _ from "lodash";
import React, { PureComponent, ReactNode } from "react";
import ReactDOM from "react-dom";
import withCommonEvents from "../../../shared/hoc/with-common-events";
import { KMainFunctions } from "../../../shared/utilty/main-functions";
import { CommonProps } from "../common-props";

interface CollapsePanelProps {
  children?: ReactNode;
  collapsible: boolean;
  style: React.CSSProperties;
  activePanelColor: string;
  isActive: boolean;
}

interface CollapsePanelState {
  uniqueKey: Guid;
}

declare let window: Window & { kuika: any };

class CollapsePanel extends PureComponent<CollapsePanelProps & CommonProps, CollapsePanelState> {
  private memoizedDynamicCssResult = "";

  constructor(props: CollapsePanelProps) {
    super(props);
    this.state = {
      uniqueKey: Guid.create()
    };
  }

  getProps = () => {
    const props: any = _.clone(this.props);
    if (props.children) {
      delete props.children;
    }
    if (props.collapsible) {
      delete props.collapsible;
    }
    return props;
  };

  isCollapsible = () => {
    if (this.props.collapsible == false) {
      return "disabled";
    }
    return undefined;
  };

  componentDidMount() {
    this.setDynamicStyle();
    if (this.props && this.props.style && this.props.style?.color) {
      const node = ReactDOM.findDOMNode(this) as Element;
      const item = node.querySelector(".ant-collapse-header") as HTMLElement;
      item.style.color = this.props.style?.color;
    }
    const node = ReactDOM.findDOMNode(this) as Element;
    const antCollapseContentBox = node?.querySelector(".ant-collapse-content-box") as HTMLElement;
    if (antCollapseContentBox) {
      const collapsePanel = antCollapseContentBox.children[0] as HTMLElement;
      if (collapsePanel && this.props?.style) {
        KMainFunctions.handleAlignments(collapsePanel, this.props.style);
      }
    }
  }

  getDynamicCss = (): string => {
    const className: string = this.getClassName();
    if (!className || className.length === 0) {
      return "";
    }
    let result = "";
    const { activePanelColor, isActive } = this.props;
    if (isActive) {
      result += `.${className.trim()} .ant-collapse-header {
          color: ${!activePanelColor || activePanelColor === "" ? "#0078FF" : activePanelColor} !important;
        }`;
    }
    if (this.props.style?.backgroundColor) {
      result += `
      .${className.trim()} {
        background: none !important;
      }`;

      result += `.${className.trim()} .ant-collapse-content-box {
          background-color: ${this.props.style?.backgroundColor} !important;
          border-top: none !important;
        }`;
    }
    if (this.props.style?.borderColor) {
      result += `
      .${className.trim()} {
        border: none !important;
      }`;

      result += `.${className.trim()} .ant-collapse-content-box {
          border-top-width: ${this.props.style?.borderTopWidth}px !important;
          border-bottom-width: ${this.props.style?.borderBottomWidth}px !important;
          border-left-width: ${this.props.style?.borderLeftWidth}px !important;
          border-right-width: ${this.props.style?.borderRightWidth}px !important;
          border-top-left-radius: ${this.props.style?.borderTopLeftRadius}px !important;
          border-top-right-radius: ${this.props.style?.borderTopRightRadius}px !important;
          border-bottom-left-radius: ${this.props.style?.borderBottomLeftRadius}px !important;
          border-bottom-right-radius: ${this.props.style?.borderBottomRightRadius}px !important;
          border-style: ${this.props.style?.borderStyle} !important;
          border-color: ${this.props.style?.borderColor} !important;
        }`;
    }
    if (this.props.style?.alignItems) {
      result += `
      .${className.trim()} {
        align-items: unset !important;
      }`;

      // result += `.${className.trim()} .ant-collapse-content-box {
      //     display: flex;
      //     align-items: ${this.props.style?.alignItems};
      //   }`;
    }
    // if (this.props.style?.textAlign) {
    //   result += `
    //   .${className.trim()} {
    //     text-align: unset !important;
    //   }`;

    //   result += `.${className.trim()} .ant-collapse-content-box {
    //       display: flex;
    //       justify-content: ${KMainFunctions.getTextAlignAsJustifyContent(this.props.style?.textAlign)} !important;
    //     }`;
    // }
    if (this.props.style?.fontFamily) {
      result += `
      .${className.trim()} {
        font-family: unset !important;
      }`;
    }
    if (this.props.style?.fontStyle) {
      result += `
      .${className.trim()} {
        font-style: unset !important;
      }`;
    }
    if (this.props.style?.fontWeight) {
      result += `
      .${className.trim()} {
        font-weight: unset !important;
      }`;
    }
    if (this.props.style?.fontSize) {
      result += `
      .${className.trim()} {
        font-size: unset !important;
      }`;
    }
    if (this.props.style?.letterSpacing) {
      result += `
      .${className.trim()} {
        letter-spacing: unset !important;
      }`;
    }
    if (this.props.style?.lineHeight) {
      result += `
      .${className.trim()} {
        line-height: unset !important;
      }`;
    }

    result += `
      .${className.trim()} {
        ${this.props.style?.height && "height: unset !important;"}
        ${this.props.style?.minHeight && "min-height: unset !important;"}
        ${this.props.style?.maxHeight && "max-height: unset !important;"}
        ${this.props.style?.color && "color: unset !important;"}
        ${this.props.style?.textTransform && "text-transform: unset !important;"}
        ${this.props.style?.textDecoration && "text-decoration: unset !important;"}
        ${this.props.style?.paddingTop && "padding-top: 0 !important; padding: 0 !important;"}
        ${this.props.style?.paddingRight && "padding-right: 0 !important;"}
        ${this.props.style?.paddingBottom && "padding-bottom: 0 !important;"}
        ${this.props.style?.paddingLeft && "padding-left: 0 !important;"}
      }`;

    result += `.${className.trim()} .ant-collapse-content-box {
          height: ${this.props.style?.height} !important;
          min-height: ${this.props.style?.minHeight} !important;
          max-height: ${this.props.style?.maxHeight} !important;
          padding-top: ${this.props.style?.paddingTop}px !important;
          padding-right: ${this.props.style?.paddingRight}px !important;
          padding-bottom: ${this.props.style?.paddingBottom}px !important;
          padding-left: ${this.props.style?.paddingLeft}px !important;
        }`;

    result += `.${className.trim()} .ant-collapse-content-box > div {
      width: 100% !important;
    } `;
    result += `.${className.trim()} .ant-collapse-content-box > div {
      height: 100% !important;
    } `;

    return result;
  };

  setDynamicStyle = () => {
    const uniquekey = this.state.uniqueKey?.toString();
    if (!uniquekey) {
      return;
    }
    const isDesignTime = window.kuika?.isDesignTime;
    if (this.memoizedDynamicCssResult !== "" && !isDesignTime) {
      return this.memoizedDynamicCssResult;
    }
    const dynamic_style = document.getElementById("dynamic_style");
    if (dynamic_style && dynamic_style.innerHTML?.indexOf(uniquekey) === -1) {
      const generatedCss = this.getDynamicCss();
      dynamic_style.innerHTML = `${dynamic_style.innerHTML}
        ${generatedCss}`;
      this.memoizedDynamicCssResult = generatedCss;
    }
  };

  getClassName = () => {
    let result = "";
    if (!this.state.uniqueKey) {
      return result;
    }
    result = `${result}kcollapse-panel_${this.state.uniqueKey.toString().substring(0, 8)}`;
    return result;
  };

  render(): ReactNode {
    return (
      <>
        <AntdCollapse.Panel className={this.getClassName()} {...this.getProps()} collapsible={this.isCollapsible()}>
          {this.props.children}
        </AntdCollapse.Panel>
      </>
    );
  }
}

const collapsePanel = withCommonEvents(CollapsePanel);
export { collapsePanel as CollapsePanel };
