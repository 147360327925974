import { Tree as AntdTree } from "antd/es";
import { DataNode } from "antd/es/tree";
import { Guid } from "guid-typescript";
import _ from "lodash";
import { CSSProperties, PureComponent, ReactNode } from "react";
import withCommonEvents from "../../../shared/hoc/with-common-events";

declare let window: Window & { [key: string]: any };

const designTimeoptions = [
  {
    id: 1,
    parentId: null,
    label: "parent1"
  },
  {
    id: 2,
    parentId: 1,
    label: "children1"
  },
  {
    id: 3,
    parentId: 1,
    label: "children2"
  },
  {
    id: 4,
    parentId: 2,
    label: "children3"
  },
  {
    id: 5,
    parentId: 4,
    label: "children4"
  },
  {
    id: 6,
    parentId: null,
    label: "parent2"
  }
];

export interface KTreeProps {
  value?: string | number;
  options?: object[];
  datavaluefield?: string;
  datatextfield?: string;
  dataparentfield?: string;
  className?: string;
  style?: CSSProperties;
  onSelect?: (value: number | string) => void;
  onChange?: (value: number | string) => void;
}

interface TreeState {
  uniqueKey: Guid;
  selectedKeys: (number | string)[];
}

class Tree extends PureComponent<KTreeProps, TreeState> {
  constructor(props: KTreeProps) {
    super(props);
    this.state = { uniqueKey: Guid.create(), selectedKeys: [] };
  }

  private getProps = () => {
    return _.omit(this.props, ["datatextfield", "datavaluefield", "dataparentfield", "options"]);
  };

  private nest = (items: object[], id = null, link: string): DataNode[] => {
    let { datatextfield, datavaluefield } = this.props;

    if (window.kuika?.dashboardState === 1) {
      datatextfield = "label";
      datavaluefield = "id";
    }

    return items
      .filter((item) => item[link] == id || item[link] < 0)
      .map((item) => ({
        ...item,
        children: this.nest(items, item[datavaluefield], link),
        title: item[datatextfield],
        key: item[datavaluefield]
      }));
  };

  private getClassName = (): string => {
    let result = "";

    if (this.props.className) {
      result = `${this.props.className} `;
    }

    if (!this.state.uniqueKey) {
      return result;
    }

    result = `${result}tree_${this.state.uniqueKey.toString()}`;
    return result;
  };

  private getBorderRadius = (): string | null => {
    if (!this.props.style?.borderRadius) return null;

    return `border-radius: ${this.props.style?.borderRadius}px;`;
  };

  private getBorderTopLeftRadius = (): string | null => {
    if (!this.props.style?.borderTopLeftRadius) return null;

    return `border-top-left-radius: ${this.props.style?.borderTopLeftRadius}px;`;
  };

  private getBorderTopRightRadius = (): string | null => {
    if (!this.props.style?.borderTopRightRadius) return null;

    return `border-top-right-radius: ${this.props.style?.borderTopRightRadius}px;`;
  };

  private getBorderBottomLeftRadius = (): string | null => {
    if (!this.props.style?.borderBottomLeftRadius) return null;

    return `border-bottom-left-radius: ${this.props.style?.borderBottomLeftRadius}px;`;
  };

  private getBorderBottomRightRadius = (): string | null => {
    if (!this.props.style?.borderBottomRightRadius) return null;

    return `border-bottom-right-radius: ${this.props.style?.borderBottomRightRadius}px;`;
  };

  private getBorderColor = (): string | null => {
    if (!this.props.style?.borderColor) return null;

    return `border-color: ${this.props.style?.borderColor};`;
  };

  private getBorderWidth = (): string | null => {
    if (!this.props.style?.borderWidth) return null;

    if (typeof this.props.style?.borderWidth === "string") {
      return `border-width: ${this.props.style?.borderWidth};`;
    }

    if (typeof this.props.style?.borderWidth === "number") {
      return `border-width: ${this.props.style?.borderWidth}px;`;
    }

    return null;
  };

  private getBorderTopWidth = (): string | null => {
    if (!this.props.style?.borderTopWidth) return null;

    return `border-top-width: ${this.props.style?.borderTopWidth}px;`;
  };

  private getBorderRightWidth = (): string | null => {
    if (!this.props.style?.borderRightWidth) return null;

    return `border-right-width: ${this.props.style?.borderRightWidth}px;`;
  };

  private getBorderBottomWidth = (): string | null => {
    if (!this.props.style?.borderBottomWidth) return null;

    return `border-bottom-width: ${this.props.style?.borderBottomWidth}px;`;
  };

  private getBorderLeftWidth = (): string | null => {
    if (!this.props.style?.borderLeftWidth) return null;

    return `border-left-width: ${this.props.style?.borderLeftWidth}px;`;
  };

  private getBorderStyle = (): string | null => {
    if (!this.props.style?.borderStyle) return null;

    return `border-style: ${this.props.style?.borderStyle};`;
  };

  private getDynamicCss = (): string => {
    const className: string = this.getClassName();

    if (!className || className.length === 0) {
      return "";
    }

    let result = "";

    const borderColor: string | null = this.getBorderColor();
    const borderStyle: string | null = this.getBorderStyle();

    if (this.getIsAnyBorderRadiusSet()) {
      if (this.props.style?.borderRadius) {
        result += `.${className} { ${this.getBorderRadius()} }`;
      }

      if (this.props.style?.borderTopLeftRadius) {
        result += `.${className} { ${this.getBorderTopLeftRadius()} }`;
      }

      if (this.props.style?.borderTopRightRadius) {
        result += `.${className} { ${this.getBorderTopRightRadius()} }`;
      }

      if (this.props.style?.borderBottomLeftRadius) {
        result += `.${className} { ${this.getBorderBottomLeftRadius()} }`;
      }

      if (this.props.style?.borderBottomRightRadius) {
        result += `.${className} { ${this.getBorderBottomRightRadius()} }`;
      }
    }

    if (this.getIsAnyBorderWidthSet()) {
      if (this.props.style?.borderWidth) {
        result += `.${className} { ${this.getBorderWidth()} }`;
      }

      if (this.props.style?.borderTopWidth) {
        result += `.${className} { ${this.getBorderTopWidth()} }`;
      }

      if (this.props.style?.borderRightWidth) {
        result += `.${className} { ${this.getBorderRightWidth()} }`;
      }

      if (this.props.style?.borderBottomWidth) {
        result += `.${className} { ${this.getBorderBottomWidth()} }`;
      }

      if (this.props.style?.borderLeftWidth) {
        result += `.${className} { ${this.getBorderLeftWidth()} }`;
      }
    }

    if (borderColor) {
      result += `.${className} { ${borderColor} }`;
    }

    if (borderStyle) {
      result += `.${className} { ${borderStyle} }`;
    }

    return result;
  };

  private getStyle = (): CSSProperties => {
    const style: CSSProperties = { ...this.props.style };

    if (style.borderRadius) {
      delete style.borderRadius;
    }

    if (style.borderTopLeftRadius) {
      delete style.borderTopLeftRadius;
    }

    if (style.borderTopRightRadius) {
      delete style.borderTopRightRadius;
    }

    if (style.borderBottomLeftRadius) {
      delete style.borderBottomLeftRadius;
    }

    if (style.borderBottomRightRadius) {
      delete style.borderBottomRightRadius;
    }

    if (style.borderWidth) {
      delete style.borderWidth;
    }

    if (style.borderTopWidth) {
      delete style.borderTopWidth;
    }

    if (style.borderRightWidth) {
      delete style.borderRightWidth;
    }

    if (style.borderBottomWidth) {
      delete style.borderBottomWidth;
    }

    if (style.borderLeftWidth) {
      delete style.borderLeftWidth;
    }

    if (style.borderColor) {
      delete style.borderColor;
    }

    if (style.borderStyle) {
      delete style.borderStyle;
    }

    return style;
  };

  private getIsAnyBorderWidthSet = (): boolean => {
    return Boolean(
      this.props.style?.borderWidth ||
        this.props.style?.borderTopWidth ||
        this.props.style?.borderRightWidth ||
        this.props.style?.borderBottomWidth ||
        this.props.style?.borderLeftWidth
    );
  };

  private getIsAnyBorderRadiusSet = (): boolean => {
    return Boolean(
      this.props.style?.borderRadius ||
        this.props.style?.borderTopLeftRadius ||
        this.props.style?.borderTopRightRadius ||
        this.props.style?.borderBottomLeftRadius ||
        this.props.style?.borderBottomRightRadius
    );
  };

  componentDidMount(): void {
    if (!this.state.uniqueKey) return;

    const uniqueKey: string = this.state.uniqueKey.toString();

    if (
      this.getIsAnyBorderWidthSet() ||
      this.getIsAnyBorderRadiusSet() ||
      this.props.style?.borderColor ||
      this.props.style?.borderStyle
    ) {
      const dynamicStyle = document.getElementById("dynamic_style");

      if (!dynamicStyle) return;

      if (dynamicStyle.innerHTML?.indexOf(uniqueKey) === -1) {
        dynamicStyle.innerHTML = `${dynamicStyle.innerHTML}
        ${this.getDynamicCss()}`;
      }
    }
  }

  private getTreeData = (): DataNode[] => {
    let treeData: DataNode[] = [];

    let options = !_.isEmpty(this.props.options) ? [...this.props.options] : [];

    if (window.kuika && window.kuika?.dashboardState === 1) {
      // Designer
      options = designTimeoptions;
      return this.nest(options, null, "parentId");
    }

    const { datatextfield, datavaluefield, dataparentfield } = this.props;

    options.forEach((element) => {
      if (element[datavaluefield] == element[dataparentfield]) {
        element[dataparentfield] = null;
      }

      if (element[dataparentfield] == undefined || element[dataparentfield] == "") {
        element[dataparentfield] = null;
      }
    });

    if (options && datatextfield && datavaluefield && dataparentfield) {
      treeData = this.nest(options, null, dataparentfield);
    }

    return treeData;
  };

  private onSelect = (keys: (string | number)[]) => {
    if (window.kuika?.dashboardState === 1 || !this.props.datavaluefield || !this.props.options) {
      return;
    }

    this.setState({ selectedKeys: keys }, () => {
      this.props.onChange?.(this.state.selectedKeys[0]);
      this.props.onSelect?.(this.state.selectedKeys[0]);
    });
  };

  render(): ReactNode {
    return (
      <AntdTree
        {...this.getProps()}
        selectedKeys={[]}
        className={this.getClassName()}
        treeData={this.getTreeData()}
        style={this.getStyle()}
        onSelect={this.onSelect}
      />
    );
  }
}

const tree = withCommonEvents(Tree);
export { tree as Tree };
