import _ from "lodash";
import { PureComponent, ReactNode } from "react";
import ReactDOM from "react-dom";
import { CommonProps } from "../../../common/common-props";
import { Row } from "../../../responsive/row";

export interface GalleryBodyWrapperProps {
  style?: any;
  horizontalGutter?: number;
  verticalGutter?: number;
}

interface GalleryBodyWrapperState {}

declare let window: any;

export class GalleryBodyWrapper extends PureComponent<GalleryBodyWrapperProps & CommonProps, GalleryBodyWrapperState> {
  constructor(props: GalleryBodyWrapperProps & CommonProps) {
    super(props);
    this.state = {};
  }

  componentDidMount(): void {
    const node = ReactDOM.findDOMNode(this);
    const parentElement = node?.parentElement;
    if (parentElement && this.props?.horizontalGutter) {
      parentElement.style.overflow = "auto";
    }
  }

  getProps = () => {
    let props: any = {};
    if (this.props) {
      props = _.clone(this.props);
    }
    if (!props.style) props.style = {};

    if (props.horizontalGutter || props.verticalGutter) {
      props.style.gap = `${props.verticalGutter || 0}px ${props.horizontalGutter || 0}px`;
      delete props.horizontalGutter;
      delete props.verticalGutter;
    }

    return props;
  };

  render(): ReactNode {
    return (
      <>
        <Row {...this.getProps()}></Row>
      </>
    );
  }
}
