import { Input } from "antd/es";
import { InputNumberProps } from "antd/es/input-number";
import _ from "lodash";
import { PureComponent, ReactNode } from "react";
import { CommonProps } from "../../../components/web-components/common/common-props";
import withCommonEvents from "../../../shared/hoc/with-common-events";

type IKNumericboxProps = InputNumberProps;

interface IKNumericboxState {}

class KNumericbox extends PureComponent<IKNumericboxProps & CommonProps, IKNumericboxState> {
  constructor(props: IKNumericboxProps) {
    super(props);
    this.state = {};
  }

  getProps = () => {
    const props: any = _.clone(this.props);
    let style: any = _.clone(this.props.style);
    if (!style) {
      style = {
        width: "100%"
      };
      props.style = style;
    } else if (!style.width) {
      style.width = "100%";
      props.style = style;
    }
    return props;
  };

  render(): ReactNode {
    return (
      <>
        <Input {...this.getProps()} type="number"></Input>
      </>
    );
  }
}

const kNumericbox = withCommonEvents(KNumericbox);
export { kNumericbox as KNumericbox };
