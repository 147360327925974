import { PureComponent, ReactNode } from "react";
import { CommonProps } from "../../../components/web-components/common/common-props";
import withCommonEvents from "../../../shared/hoc/with-common-events";

interface IKLocationSelectProps {}

interface IKLocationSelectState {}

class KLocationSelect extends PureComponent<IKLocationSelectProps & CommonProps, IKLocationSelectState> {
  constructor(props: IKLocationSelectProps) {
    super(props);
    this.state = {};
  }

  render(): ReactNode {
    return <>KLocationSelect Not implemented!</>;
  }
}

const kLocationSelect = withCommonEvents(KLocationSelect);
export { kLocationSelect as KLocationSelect };
