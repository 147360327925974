import React from "react";
import Axios, { AxiosResponse } from "axios";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { KNavigator } from "../../shared/hoc/with-history";
import { KContext } from "../../shared/hoc/with-context";
import { useLocation } from "react-router-dom";
import { FormInstance } from "antd/es/form";
import { Guid } from "guid-typescript";
import Swal from "sweetalert2";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import moment from "moment";
import { IValidationData } from "../../models/dto/validation-data";
import { IStripePayment } from "../../models/dto/stripe-payment";
import { IUserInfoDto } from "../../models/dto/user-info.dto";
import { UserService } from "../../services/user-service";
import _ from "lodash";
import mqtt, { MqttClient } from "mqtt";
import { IMqttResult } from "../../models/dto/mqtt.dto";

declare let window: any;

export interface IForgotPassword_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

export interface IForgotPassword_ScreenState {
    isSpinnerVisible: boolean;
    isPageVisible: boolean;
	isLeftMenuVisible: boolean;
    spinnerCount: number;
    selectedLanguage: string;
    isPageInitLoaded: false;
    isPageRecurringLoaded: false;
	ForgotPassword: string;
	ForgotPassword_dummy: string;
	goToVerificationCodeActivity: any;
	goToVerificationCodeActivity_dummy: any;
	NAVIGATE: any;
	NAVIGATE_dummy: any;


}

export class ForgotPassword_ScreenBase extends React.PureComponent<IForgotPassword_ScreenProps, any> {

    ml=[{"Id":"74503767-9450-4cae-9db5-729d038bcb36","Name":"en_US","ShortName":"English","LanguagePhrases":[{"Id":574431,"PropertyName":"value","Value":"Forgot Password?","TagName":"lblTitle_value"},{"Id":900580,"PropertyName":"value","Value":"Please write your e-mail.","TagName":"lblSubTitle_value"},{"Id":363124,"PropertyName":"value","Value":"User E-mail","TagName":"Label4_0_value"},{"Id":405510,"PropertyName":"placeholder","Value":"name@mail.com","TagName":"txtEmail_placeholder"},{"Id":611276,"PropertyName":"label","Value":"Send password","TagName":"btnSend_label"},{"Id":520648,"PropertyName":"value","Value":"Sign In","TagName":"lnkSignIn_value"},{"Id":883899,"PropertyName":"value","Value":"go back to","TagName":"lblBackTo_value"}]},{"Id":"423ed54a-d6f3-fccb-7d3e-72c7b24c8b7a","Name":"tr_TR","ShortName":"Turkish","LanguagePhrases":[]},{"Id":"13b02fcd-004b-4f27-aace-21415c4934b2","Name":"FixedValues","ShortName":"FixedValues","LanguagePhrases":[{"Id":574431,"PropertyName":"value","Value":"Şifrenizi mi unuttunuz?"},{"Id":900580,"PropertyName":"value","Value":"Lütfen E-posta adresinizi yazın."},{"Id":363124,"PropertyName":"value","Value":"Kullanıcı E-posta"},{"Id":405510,"PropertyName":"placeholder","Value":"ali.mavisehir@mail.com"},{"Id":611276,"PropertyName":"label","Value":"Şifre Gönder"},{"Id":520648,"PropertyName":"value","Value":"Giriş Yap"},{"Id":883899,"PropertyName":"value","Value":"'a geri dön"}]}]
    defaultML = "tr_TR"
    
    screenHash = ""
    
    constructor(props) {
        super(props);


        this.state = {
            isSpinnerVisible: false,
            isPageVisible: false,
            isLeftMenuVisible: false,
            spinnerCount: 0,
            selectedLanguage: "",
            isPageInitLoaded: false,
            isPageRecurringLoaded: false,
            	        ForgotPassword: "",
	        goToVerificationCodeActivity: "",
	        NAVIGATE: "",

            
        }

        this.state.isPageVisible = true;
        
    }

    async componentDidMount() {

        if (KuikaAppManager.isPageInitPrevented()) {
            KuikaAppManager.calculateAndSetBodyHeight("forgotpassword", "");
            return;
        }
        
        
        if (true){
            
        }
        
        window.addEventListener("keydown", this.onKeyDown)
        
        KuikaAppManager.calculateAndSetBodyHeight("forgotpassword", "");
    }

    componentWillUnmount() {
        window.removeEventListener("keydown", this.onKeyDown)
        
        
    }

    

        

  onKeyDown = (e: any) => {
    if (e.keyCode === undefined) return;
    const keyCode = e.keyCode;
    if(keyCode === 13){
      var element = document.getElementById("611276");
      if (element && element.click)
            {
                element.click();
            }
        }
    }

    componentDidUpdate= async (prevProps, prevState) => {
		KuikaAppManager.calculateAndSetBodyHeight("forgotpassword", ""    );
        if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
			
		}

        
	}

    fillFormInitValues() {
        this.props.form.setFieldsValue({
});

    }

      setPageInitLoaded = (value: boolean) => {
        this.setState({ isPageInitLoaded: value });
      }

      setPageRecurringLoaded = (value: boolean) => {
        this.setState({ isPageRecurringLoaded: value });
      }

      clearFieldValidation = (changedValues: any) => {
        const fieldName = Object.keys(changedValues)[0];
        if (fieldName) {
          this.props.form.setFields([
            {
              name: fieldName,
              errors: []
            }
          ]);
        }
      };

      

      


		ForgotPasswordComponent_611276_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;

		validations.push({ 
			isValid: ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "forgotpassword_405510_value", "value", "", "", "")), null),
			message: "This field is required",
			formName: "forgotpassword_405510_value",
			condition: true
		} as IValidationData);

validations.forEach((validation) => {
            if (validation.message === "Geçerli bir mail adresi giriniz.") {
                validation.message = ReactSystemFunctions.getEmailValidationMessage()
            }
        })
		validations.push({ 
			isValid: ReactSystemFunctions.isValidEmail(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "forgotpassword_405510_value", "value", "", "", "")), null),
			message: "Geçerli bir mail adresi giriniz.",
			formName: "forgotpassword_405510_value",
			condition: true
		} as IValidationData);

validations.forEach((validation) => {
            if (validation.message === "Geçerli bir mail adresi giriniz.") {
                validation.message = ReactSystemFunctions.getEmailValidationMessage()
            }
        })

		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
KuikaAppManager.showSpinner(this);

				stateVars.ForgotPassword = await ReactSystemFunctions.forgotPassword(this,ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "forgotpassword_405510_value", "value", "", "", "")),false,null);
				stateVars.tmpBoolResult = await ReactSystemFunctions.goToVerificationCodeActivity(this);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }


		ForgotPasswordComponent_520648_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
KuikaAppManager.showSpinner(this);

					KuikaAppManager.prepareForNavigation();

				stateVars.NAVIGATE = await ReactSystemFunctions.navigate(this, undefined, "ForgotPassword", "Signin", "", "currentPage", null, null, null, null, null, null, true);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }




    async callCallbackFunction(diId) {
        let isErrorOccurred = false;
		let pageInitCallerNavDiIDs = [] as number[];

    }
}
