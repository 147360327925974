import _ from "lodash";
import moment from "moment";
import React, { PureComponent, ReactNode } from "react";
import { Calendar, CalendarProps, SlotInfo, View, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { CommonProps } from "../../components/web-components/common/common-props";
import withCommonEvents from "../../shared/hoc/with-common-events";

const defaultStyling = {
  height: 500
};

export interface Event {
  allDay?: boolean | undefined;
  title?: string | undefined;
  start?: Date | undefined;
  end?: Date | undefined;
  id?: string;
}

interface ICalendarProps extends CalendarProps {
  events?: Event[];
  style?: any;
  view?: View;
  locale?: "tr" | "en";
  dataAlldayField?: string;
  dataTitleField?: string;
  dataStartField?: string;
  dataEndField?: string;
  dataIdField?: string;
}

interface ICalendarState {}

class KCalendar extends PureComponent<ICalendarProps & CommonProps, ICalendarState> {
  constructor(props: ICalendarProps) {
    super(props);
    this.state = {};
  }

  getProps = () => {
    const props: any = _.clone(this.props);
    return props;
  };

  onSelectEvent = (event: Event, e: React.SyntheticEvent<HTMLElement>) => {
    if (this.props.onSelectEvent) {
      this.props.onSelectEvent(event, e);
    }
  };

  onSelectSlot = (slotInfo: SlotInfo) => {
    if (this.props.onSelectSlot) {
      this.props.onSelectSlot(slotInfo);
    }
  };

  getEvents = (): Event[] => {
    const { events, dataAlldayField, dataTitleField, dataStartField, dataEndField, dataIdField } = this.props;
    if (
      dataAlldayField &&
      dataTitleField &&
      dataStartField &&
      dataEndField &&
      dataIdField &&
      dataAlldayField.length > 1 &&
      dataTitleField.length > 1 &&
      dataStartField.length > 1 &&
      dataEndField.length > 1 &&
      dataIdField.length > 1
    ) {
      const allEvents: Event[] = [];
      if (this.props.events && this.props.events.length && this.props.events.length > 0) {
        this.props.events.forEach((row) => {
          if (
            row &&
            row[dataAlldayField!] &&
            row[dataTitleField!] &&
            row[dataStartField!] &&
            row[dataEndField!] &&
            row[dataIdField!]
          ) {
            allEvents.push({
              allDay: row[dataAlldayField!],
              title: row[dataTitleField!],
              start: row[dataStartField!],
              end: row[dataEndField!],
              id: row[dataIdField!]
            });
          }
        });
      }
      return allEvents;
    }
    if (_.isUndefined(events)) {
      return [] as Event[];
    }
    return events!;
  };

  render(): ReactNode {
    moment.locale(this.props.locale);
    const localizer = momentLocalizer(moment);
    return (
      <>
        <div style={!_.isEmpty(this.props.style) ? this.props.style : defaultStyling}>
          <Calendar
            className={this.props.className}
            selectable
            onSelectSlot={(slotInfo) => this.onSelectSlot(slotInfo)}
            onSelectEvent={(event, e) => this.onSelectEvent(event, e)}
            view={this.props.view}
            localizer={localizer}
            events={this.getEvents()}
          />
        </div>
      </>
    );
  }
}

const kCalendar = withCommonEvents(KCalendar);
export { kCalendar as KCalendar };
