import { IImportMappedExcelColumnMapDefinition } from "../../models/dto/excel";
import {
  HIDE_IMPORT_MAPPED_EXCEL_MODAL,
  HIDE_IYZICO_DRAWER,
  HIDE_PHOTO_TAKE,
  HIDE_STRIPE_DRAWER,
  ImportMappedExcelModalActionTypes,
  IyzicoDrawerActionTypes,
  PhotoTakeActionTypes,
  PhotoTakeState,
  SHOW_IMPORT_MAPPED_EXCEL_MODAL,
  SHOW_IYZICO_DRAWER,
  SHOW_PHOTO_TAKE,
  SHOW_STRIPE_DRAWER,
  StripeDrawerActionTypes
} from "./types";

const initialState: PhotoTakeState = {
  visible: false
};

const initialStripeState: StripeDrawerState = {
  visible: false,
  payload: undefined,
  callback: undefined,
  screen: undefined,
  futureUse: undefined
};
const initialIyzicoState: IyzicoDrawerState = {
  visible: false,
  payload: undefined,
  callback: undefined,
  screen: undefined
};
const initialImportMappedExcelModalState: ImportMappedExcelModalState = {
  visible: false,
  dsId: null,
  tableName: null,
  primaryKey: null,
  columnMapping: []
};
export interface StripeDrawerState {
  visible: boolean;
  payload: any;
  callback: any;
  screen: any;
  futureUse: any;
}
export interface IyzicoDrawerState {
  visible: boolean;
  payload: any;
  callback: any;
  screen: any;
}
export interface ImportMappedExcelModalState {
  visible: boolean;
  dsId: string | null;
  tableName: string | null;
  primaryKey: string | null;
  columnMapping: IImportMappedExcelColumnMapDefinition[];
}

export const photoTakeReducer = (state = initialState, action: PhotoTakeActionTypes): PhotoTakeState => {
  switch (action.type) {
    case SHOW_PHOTO_TAKE:
      return {
        visible: true
      };
    case HIDE_PHOTO_TAKE:
      return {
        visible: false
      };
    default:
      return state;
  }
};
export const stripeDrawerReducer = (state = initialStripeState, action: StripeDrawerActionTypes): StripeDrawerState => {
  switch (action.type) {
    case SHOW_STRIPE_DRAWER:
      return {
        visible: true,
        payload: action.payload,
        callback: action.callback,
        screen: action.screen,
        futureUse: action.futureUse
      };
    case HIDE_STRIPE_DRAWER:
      return {
        visible: false,
        payload: action.payload,
        callback: action.callback,
        screen: action.screen,
        futureUse: state.futureUse
      };
    default:
      return state;
  }
};

export const iyzicoDrawerReducer = (state = initialIyzicoState, action: IyzicoDrawerActionTypes): IyzicoDrawerState => {
  switch (action.type) {
    case SHOW_IYZICO_DRAWER:
      return {
        visible: true,
        payload: action.payload,
        callback: action.callback,
        screen: action.screen
      };
    case HIDE_IYZICO_DRAWER:
      return {
        visible: false,
        payload: action.payload,
        callback: action.callback,
        screen: action.screen
      };
    default:
      return state;
  }
};

export const importMappedExcelModalReducer = (
  state = initialImportMappedExcelModalState,
  action: ImportMappedExcelModalActionTypes
): ImportMappedExcelModalState => {
  switch (action.type) {
    case SHOW_IMPORT_MAPPED_EXCEL_MODAL:
      return {
        visible: true,
        dsId: action.dsId,
        tableName: action.tableName,
        primaryKey: action.primaryKey,
        columnMapping: action.columnMapping
      };
    case HIDE_IMPORT_MAPPED_EXCEL_MODAL:
      return {
        visible: false,
        dsId: null,
        tableName: null,
        primaryKey: null,
        columnMapping: []
      };
    default:
      return state;
  }
};
