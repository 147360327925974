import React from "react";
import Axios, { AxiosResponse } from "axios";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { KNavigator } from "../../shared/hoc/with-history";
import { KContext } from "../../shared/hoc/with-context";
import { useLocation } from "react-router-dom";
import { FormInstance } from "antd/es/form";
import { Guid } from "guid-typescript";
import Swal from "sweetalert2";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import moment from "moment";
import { IValidationData } from "../../models/dto/validation-data";
import { IStripePayment } from "../../models/dto/stripe-payment";
import { IUserInfoDto } from "../../models/dto/user-info.dto";
import { UserService } from "../../services/user-service";
import _ from "lodash";
import mqtt, { MqttClient } from "mqtt";
import { IMqttResult } from "../../models/dto/mqtt.dto";

declare let window: any;

export interface ICheckupDonemList_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

export interface ICheckupDonemList_ScreenState {
    isSpinnerVisible: boolean;
    isPageVisible: boolean;
	isLeftMenuVisible: boolean;
    spinnerCount: number;
    selectedLanguage: string;
    isPageInitLoaded: false;
    isPageRecurringLoaded: false;
	MyContext: any[];
	MyContext_dummy: any[];
	CheckupDonemiCount: any[];
	CheckupDonemiCount_dummy: any[];
	CheckupDonemiSearch: any[];
	CheckupDonemiSearch_dummy: any[];
	SetValueOf: string;
	SetValueOf_dummy: string;
	NAVIGATE: any;
	NAVIGATE_dummy: any;
	SaveRecord: number;
	SaveRecord_dummy: number;
	CheckupDonemiDeleteById: number;
	CheckupDonemiDeleteById_dummy: number;
	isComp40912Visible: 'visible' | 'hidden';
	isComp766368Visible: 'visible' | 'hidden';
	isComp48020Visible: 'visible' | 'hidden';

isMasterPageInitLoaded?: boolean;
  isMasterPageRecurringLoaded?: boolean;
}

export class CheckupDonemList_ScreenBase extends React.PureComponent<ICheckupDonemList_ScreenProps, any> {
	checkupdonemlist_399769_value_kuikaTableRef: React.RefObject<any>;
	checkupdonemlist_172599_value_kuikaPaginationRef: React.RefObject<any>;
    ml=[{"Id":"74503767-9450-4cae-9db5-729d038bcb36","Name":"en_US","ShortName":"English","LanguagePhrases":[{"Id":923160,"PropertyName":"value","Value":"Check-Up PERIODS","TagName":"lblHead_value"},{"Id":416063,"PropertyName":"placeholder","Value":"Search...","TagName":"txtSearch_placeholder"},{"Id":814413,"PropertyName":"label","Value":"NEW Check-Up PERIOD","TagName":"btnNew_label"},{"Id":399769,"PropertyName":"nodatafoundmessage","Value":"no data found","TagName":"tblCheckupdonemi2_nodatafoundmessage"},{"Id":1042903,"PropertyName":"title","Value":"Period Name","TagName":"clmDonemAdi_title"},{"Id":9091784,"PropertyName":"value","Value":"[datafield:periodname]","TagName":"lblDonemAdi_value"},{"Id":8788723,"PropertyName":"title","Value":"Active Pasif","TagName":"clmAktifPasif_title"},{"Id":4628369,"PropertyName":"fe852656-41cb-0d69-f310-feba936ad060_confirmation","Value":"Are you sure you want to delete this record?","TagName":"CheckupDonemiDeleteById_confirmation","IsConfirmation":true}]},{"Id":"423ed54a-d6f3-fccb-7d3e-72c7b24c8b7a","Name":"tr_TR","ShortName":"Turkish","LanguagePhrases":[]},{"Id":"2e711db1-5ed8-4a83-8128-12c4e508f628","Name":"FixedValues","ShortName":"FixedValues","LanguagePhrases":[{"Id":923160,"PropertyName":"value","Value":"Check-Up Dönemleri"},{"Id":416063,"PropertyName":"placeholder","Value":"Arama..."},{"Id":814413,"PropertyName":"label","Value":"YENİ DÖNEM"},{"Id":399769,"PropertyName":"nodatafoundmessage","Value":"No data found."},{"Id":1042903,"PropertyName":"title","Value":"Dönem Adı"},{"Id":9091784,"PropertyName":"value","Value":"[datafield:donemadi]"},{"Id":8788723,"PropertyName":"title","Value":"Aktif Pasif"},{"Id":766368,"PropertyName":"label","Value":"Aktif Et"}]}]
    defaultML = "tr_TR"
    
    screenHash = ""
    
    constructor(props) {
        super(props);
		this.checkupdonemlist_399769_value_kuikaTableRef = React.createRef();
		this.checkupdonemlist_172599_value_kuikaPaginationRef = React.createRef();

        this.state = {
            isSpinnerVisible: false,
            isPageVisible: false,
            isLeftMenuVisible: false,
            spinnerCount: 0,
            selectedLanguage: "",
            isPageInitLoaded: false,
            isPageRecurringLoaded: false,
            	        MyContext: [],
	        CheckupDonemiCount: [],
	        CheckupDonemiSearch: [],
	        SetValueOf: "",
	        NAVIGATE: "",
	        SaveRecord: false,
	        CheckupDonemiDeleteById: 0,
	        isComp40912Visible: 'hidden',
	        isComp766368Visible: 'hidden',
	        isComp48020Visible: 'hidden',

            isMasterPageInitLoaded: false,
  isMasterPageRecurringLoaded: false,
        }

        this.state.isPageVisible = true;
        
    }

    async componentDidMount() {

        if (KuikaAppManager.isPageInitPrevented()) {
            KuikaAppManager.calculateAndSetBodyHeight("checkupdonemlist", "master_screen");
            return;
        }
        
        ReactSystemFunctions.validateToken()
        if (false){
            await this.CheckupDonemListPageInit();
        }
        
        
        
        KuikaAppManager.calculateAndSetBodyHeight("checkupdonemlist", "master_screen");
    }

    componentWillUnmount() {
        
        
        
    }

    

        
    componentDidUpdate= async (prevProps, prevState) => {
		KuikaAppManager.calculateAndSetBodyHeight("checkupdonemlist", "master_screen"    );
        if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
			await this.CheckupDonemListPageInit();
		}

        		if (prevState.isMasterPageInitLoaded !== this.state.isMasterPageInitLoaded && this.state.isMasterPageInitLoaded === true) {await this.CheckupDonemListPageInit();}

	}

    fillFormInitValues() {
        this.props.form.setFieldsValue({
});

    }

      setPageInitLoaded = (value: boolean) => {
        this.setState({ isPageInitLoaded: value });
      }

      setPageRecurringLoaded = (value: boolean) => {
        this.setState({ isPageRecurringLoaded: value });
      }

      clearFieldValidation = (changedValues: any) => {
        const fieldName = Object.keys(changedValues)[0];
        if (fieldName) {
          this.props.form.setFields([
            {
              name: fieldName,
              errors: []
            }
          ]);
        }
      };

      

        setMasterPageInitLoaded = (value: boolean) => {
    this.setState({ isMasterPageInitLoaded: value });
  }

  setMasterPageRecurringLoaded = (value: boolean) => {
    this.setState({ isMasterPageRecurringLoaded: value });
  }

    CheckupDonemListPageInit = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }

        let localVar = {
			searchFor_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "checkupdonemlist_416063_value", "value", "", "", "")), "string"),
			searchFor_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "checkupdonemlist_416063_value", "value", "", "", "")), "string"),
			currentpage_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "checkupdonemlist_172599_value", "current", "", "", "")), "number"),
			pagesize_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "checkupdonemlist_172599_value", "pageSize", "", "", "")), "number")
        }

	KuikaAppManager.showSpinner(this);
	
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "CheckupDonemList/CheckupDonemListPageInit", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

		stateVars.MyContext = result?.data.myContext;
		stateVars.CheckupDonemiCount = result?.data.checkupDonemiCount;
		stateVars.CheckupDonemiSearch = result?.data.checkupDonemiSearch;
		
		this.props.form.setFieldsValue(formVars);
		
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.CheckupDonemListPageInit1_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
	CheckupDonemListPageInit1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }

			
			stateVars.dataSource_399769 = this.state.CheckupDonemiSearch;
			stateVars.isComp40912Visible = ((ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, "checkupdonemlist_399769_value", "aktifMi"), "1")) === true ? "visible" : "hidden")
			stateVars.isComp766368Visible = ((ReactSystemFunctions.isNotEqualTo(ReactSystemFunctions.value(this, "checkupdonemlist_399769_value", "aktifMi"), "1")) === true ? "visible" : "hidden")
			formVars.checkupdonemlist_172599_total = ReactSystemFunctions.value(this, (stateVars.CheckupDonemiCount?.length > 0 ? stateVars.CheckupDonemiCount[0].count : this.state.CheckupDonemiCount?.length > 0 ? this.state.CheckupDonemiCount[0].count : null));


	this.props.form.setFieldsValue(formVars);
	this.setState(stateVars);
	KuikaAppManager.hideSpinner(this);

            this.setPageInitLoaded(true);
            return isErrorOccurred;
        }




		CheckupDonemListComponent_416063_onPressEnter = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
KuikaAppManager.showSpinner(this);

				stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "checkupdonemlist_172599_value", 1, "current");

		this.props.form.setFieldsValue(formVars);
		
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.CheckupDonemListComponent_416063_onPressEnter1_();
                  resolve();
                });
          })

            
            return isErrorOccurred;
        }
    CheckupDonemListComponent_416063_onPressEnter1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
		KuikaAppManager.increaseServerRequestCount();


        let localVar = {
				searchFor_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "checkupdonemlist_416063_value", "value", "", "", "")), "string"),
				kurumId_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].kurumId : this.state.MyContext?.length > 0 ? this.state.MyContext[0].kurumId : null)), "Guid"),
				kirilim1ID_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].kirilim1Id : this.state.MyContext?.length > 0 ? this.state.MyContext[0].kirilim1Id : null)), "Guid"),
				searchFor_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "checkupdonemlist_416063_value", "value", "", "", "")), "string"),
				currentpage_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "checkupdonemlist_172599_value", "current", "", "", "")), "number"),
				pagesize_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "checkupdonemlist_172599_value", "pageSize", "", "", "")), "number")
        }

		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "CheckupDonemList/CheckupDonemListComponent_416063_onPressEnter1_", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.CheckupDonemiCount = result?.data.checkupDonemiCount;
			stateVars.CheckupDonemiSearch = result?.data.checkupDonemiSearch;
			
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.CheckupDonemListComponent_416063_onPressEnter2_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
		CheckupDonemListComponent_416063_onPressEnter2_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }

				
				stateVars.dataSource_399769 = this.state.CheckupDonemiSearch;
				formVars.checkupdonemlist_172599_total = ReactSystemFunctions.value(this, (stateVars.CheckupDonemiCount?.length > 0 ? stateVars.CheckupDonemiCount[0].count : this.state.CheckupDonemiCount?.length > 0 ? this.state.CheckupDonemiCount[0].count : null));


		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }





		CheckupDonemListComponent_814413_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
KuikaAppManager.showSpinner(this);

					KuikaAppManager.prepareForNavigation();
						KuikaAppManager.addToPageInputVariables("CheckupDonemiForm", "Id", Guid.create().toString());

				stateVars.NAVIGATE = await ReactSystemFunctions.navigate(this, undefined, "CheckupDonemList", "CheckupDonemiForm", "", "7d969ddf-5801-4eb6-b92b-12be35bdd565", "486227", null, "right", null, "450px", "vh", true);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }


    CheckupDonemListComponent_766368_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
		KuikaAppManager.increaseServerRequestCount();


        let localVar = {
				AktifCheckupDonemiId_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, "checkupdonemlist_399769_value", "id"), "Guid"),
				Id_0_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, "checkupdonemlist_399769_value", "kurumId"), "Guid"),
				kurumId_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].kurumId : this.state.MyContext?.length > 0 ? this.state.MyContext[0].kurumId : null)), "Guid"),
				kirilim1ID_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].kirilim1Id : this.state.MyContext?.length > 0 ? this.state.MyContext[0].kirilim1Id : null)), "Guid"),
				searchFor_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "checkupdonemlist_416063_value", "value", "", "", "")), "string"),
				currentpage_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "checkupdonemlist_172599_value", "current", "", "", "")), "number"),
				pagesize_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "checkupdonemlist_172599_value", "pageSize", "", "", "")), "number")
        }

		KuikaAppManager.showSpinner(this);
		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "CheckupDonemList/CheckupDonemListComponent_766368_onClick", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.SaveRecord = result?.data.saveRecord_1;
			stateVars.CheckupDonemiSearch = result?.data.checkupDonemiSearch;
			
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.CheckupDonemListComponent_766368_onClick1_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
		CheckupDonemListComponent_766368_onClick1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }

				
				stateVars.dataSource_399769 = this.state.CheckupDonemiSearch;

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }




    CheckupDonemListComponent_48020_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
		KuikaAppManager.increaseServerRequestCount();


        let localVar = {
				AktifCheckupDonemiId_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, "checkupdonemlist_399769_value", "id"), "Guid"),
				Id_0_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, "checkupdonemlist_399769_value", "kurumId"), "Guid"),
				kurumId_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].kurumId : this.state.MyContext?.length > 0 ? this.state.MyContext[0].kurumId : null)), "Guid"),
				kirilim1ID_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].kirilim1Id : this.state.MyContext?.length > 0 ? this.state.MyContext[0].kirilim1Id : null)), "Guid"),
				searchFor_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "checkupdonemlist_416063_value", "value", "", "", "")), "string"),
				currentpage_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "checkupdonemlist_172599_value", "current", "", "", "")), "number"),
				pagesize_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "checkupdonemlist_172599_value", "pageSize", "", "", "")), "number")
        }

		KuikaAppManager.showSpinner(this);
		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "CheckupDonemList/CheckupDonemListComponent_48020_onClick", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.SaveRecord = result?.data.saveRecord_2;
			stateVars.CheckupDonemiSearch = result?.data.checkupDonemiSearch;
			
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.CheckupDonemListComponent_48020_onClick1_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
		CheckupDonemListComponent_48020_onClick1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }

				
				stateVars.dataSource_399769 = this.state.CheckupDonemiSearch;

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }




		CheckupDonemListComponent_860665_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
KuikaAppManager.showSpinner(this);

					KuikaAppManager.prepareForNavigation();
						KuikaAppManager.addToPageInputVariables("CheckupDonemiForm", "Id", ReactSystemFunctions.value(this, "checkupdonemlist_399769_value", "id"));

				stateVars.NAVIGATE = await ReactSystemFunctions.navigate(this, undefined, "CheckupDonemList", "CheckupDonemiForm", "", "7d969ddf-5801-4eb6-b92b-12be35bdd565", "256479", null, "right", null, "450px", "", true);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }


    CheckupDonemListComponent_505853_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
		KuikaAppManager.increaseServerRequestCount();


        let localVar = {
				Id_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, "checkupdonemlist_399769_value", "id"), "Guid"),
				searchFor_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "checkupdonemlist_416063_value", "value", "", "", "")), "string"),
				kurumId_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].kurumId : this.state.MyContext?.length > 0 ? this.state.MyContext[0].kurumId : null)), "Guid"),
				kirilim1ID_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].kirilim1Id : this.state.MyContext?.length > 0 ? this.state.MyContext[0].kirilim1Id : null)), "Guid"),
				searchFor_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "checkupdonemlist_416063_value", "value", "", "", "")), "string"),
				currentpage_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "checkupdonemlist_172599_value", "current", "", "", "")), "number"),
				pagesize_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "checkupdonemlist_172599_value", "pageSize", "", "", "")), "number")
        }

		KuikaAppManager.showSpinner(this);

            stateVars.Confirm = await Swal.fire({
                title: ReactSystemFunctions.translateCustomActions(this.ml,"fe852656_41cb_0d69_f310_feba936ad060_confirmation",this.defaultML,"Are you sure you want to delete this record?" ),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: localStorage.getItem("ml") === 'tr_TR' ? 'Onayla' : localStorage.getItem("ml") === 'fr_FR' ? 'Confirmer' : 'Confirm',
                cancelButtonText: localStorage.getItem("ml") === 'tr_TR' ? 'Vazgeç' : localStorage.getItem("ml") === 'fr_FR' ? 'Annuler' : 'Cancel',
                customClass: {
                  title: 'swal2-font-poppins',
                }
            })

            if (stateVars.Confirm.isConfirmed)
            {
                		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "CheckupDonemList/CheckupDonemListComponent_505853_onClick", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.CheckupDonemiDeleteById = result?.data.checkupDonemiDeleteById;
			stateVars.CheckupDonemiCount = result?.data.checkupDonemiCount;
			stateVars.CheckupDonemiSearch = result?.data.checkupDonemiSearch;
			
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.CheckupDonemListComponent_505853_onClick1_();
                  resolve();
                });
          })

            }


        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
		CheckupDonemListComponent_505853_onClick1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }

				
				stateVars.dataSource_399769 = this.state.CheckupDonemiSearch;
				formVars.checkupdonemlist_172599_total = ReactSystemFunctions.value(this, (stateVars.CheckupDonemiCount?.length > 0 ? stateVars.CheckupDonemiCount[0].count : this.state.CheckupDonemiCount?.length > 0 ? this.state.CheckupDonemiCount[0].count : null));


		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }




    CheckupDonemListComponent_172599_onChange = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				searchFor_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "checkupdonemlist_416063_value", "value", "", "", "")), "string"),
				kurumId_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].kurumId : this.state.MyContext?.length > 0 ? this.state.MyContext[0].kurumId : null)), "Guid"),
				kirilim1ID_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].kirilim1Id : this.state.MyContext?.length > 0 ? this.state.MyContext[0].kirilim1Id : null)), "Guid"),
				searchFor_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "checkupdonemlist_416063_value", "value", "", "", "")), "string"),
				currentpage_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "checkupdonemlist_172599_value", "current", "", "", "")), "number"),
				pagesize_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "checkupdonemlist_172599_value", "pageSize", "", "", "")), "number")
        }

		KuikaAppManager.showSpinner(this);
		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "CheckupDonemList/CheckupDonemListComponent_172599_onChange", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.CheckupDonemiCount = result?.data.checkupDonemiCount;
			stateVars.CheckupDonemiSearch = result?.data.checkupDonemiSearch;
			
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.CheckupDonemListComponent_172599_onChange1_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
		CheckupDonemListComponent_172599_onChange1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }

				
				stateVars.dataSource_399769 = this.state.CheckupDonemiSearch;
				formVars.checkupdonemlist_172599_total = ReactSystemFunctions.value(this, (stateVars.CheckupDonemiCount?.length > 0 ? stateVars.CheckupDonemiCount[0].count : this.state.CheckupDonemiCount?.length > 0 ? this.state.CheckupDonemiCount[0].count : null));


		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }






    async callCallbackFunction(diId) {
        let isErrorOccurred = false;
		let pageInitCallerNavDiIDs = [486227, 256479] as number[];
		if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
			await this.CheckupDonemListPageInit();
		}

    }
}
