import withCommonEvents from "../../../../shared/hoc/with-common-events";
import { BaseChart, BaseChartProps } from "../base-chart";

class AreaChart extends BaseChart<any, any> {
  constructor(props: BaseChartProps) {
    super(props);
  }

  public static defaultProps = {
    mode: "line",
    fill: true
  };
}

const areaChart = withCommonEvents(AreaChart);
export { areaChart as AreaChart };
