import { Button, Space } from "antd/es";
import { ButtonProps } from "antd/es/button/button";
import _ from "lodash";
import { PureComponent, ReactNode } from "react";
import { KIcon } from "..";
import { CommonProps } from "../../components/web-components/common/common-props";
import withCommonEvents from "../../shared/hoc/with-common-events";

interface IKButtonProps extends ButtonProps {
  label?: string;
  icon?: string;
  style?: any;
  iconColor?: string;
  widthType?: "fill" | "content";
  iconPosition?: "left" | "right";
}

interface IKButtonState {}

class KButton extends PureComponent<IKButtonProps & CommonProps, IKButtonState> {
  constructor(props: IKButtonProps) {
    super(props);
    this.getButtonWidth = this.getButtonWidth.bind(this);
    this.state = {};
  }

  getButtonWidth() {
    if (this.props.widthType && this.props.widthType == "content") {
      return "max-content";
    }
    return "100%";
  }

  getProps = () => {
    const props: any = _.clone(this.props);
    let fontSize: any = "14px";
    let style: any = _.clone(this.props.style);
    if (this.props.style?.fontSize) {
      fontSize = this.props.style?.fontSize;
    }
    if (props.icon && props.icon.length > 0) {
      delete props.icon;
    }
    if (props.label) {
      delete props.label;
    }
    if (props.iconColor) {
      delete props.iconColor;
    }
    if (!style) {
      style = {
        width: this.getButtonWidth()
      };
      props.style = style;
    } else if (!style.width) {
      style.width = this.getButtonWidth();
      props.style = style;
    }
    return props;
  };

  getLeftIcon = () => {
    let fontSize: any = "14px";
    if (this.props.style?.fontSize) {
      fontSize = this.props.style?.fontSize;
    }
    if (this.props.iconPosition !== "right" && this.props.icon && this.props.icon.length > 0) {
      return (
        <KIcon
          style={{
            fontSize,
            position: this.props.label !== undefined ? "relative" : undefined,
            top: this.props.label !== undefined ? "2px" : undefined,
            color: this.props.iconColor
          }}
          iconName={this.props.icon as string}
        ></KIcon>
      );
    }
    return <></>;
  };

  getRightIcon = () => {
    let fontSize: any = "14px";
    if (this.props.style?.fontSize) {
      fontSize = this.props.style?.fontSize;
    }
    if (this.props.iconPosition === "right" && this.props.icon && this.props.icon.length > 0) {
      return (
        <KIcon
          style={{
            fontSize,
            position: this.props.label !== undefined ? "relative" : undefined,
            top: this.props.label !== undefined ? "2px" : undefined,
            color: this.props.iconColor
          }}
          iconName={this.props.icon as string}
        ></KIcon>
      );
    }
    return <></>;
  };

  render(): ReactNode {
    const leftIcon = this.getLeftIcon();
    const rightIcon = this.getRightIcon();
    return (
      <>
        <Button {...this.getProps()}>
          <Space>
            {leftIcon}
            {this.props.label ? this.props.label : " "}
            {rightIcon}
          </Space>
        </Button>
      </>
    );
  }
}

const kButton = withCommonEvents(KButton);
export { kButton as KButton };
