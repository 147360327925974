import moment from "moment";
import { PureComponent, ReactNode } from "react";
import { CommonProps } from "../../../components/web-components/common/common-props";
import withCommonEvents from "../../../shared/hoc/with-common-events";
import { KDatatimePicker } from "../datetime-picker/k-datetime-picker";

interface IKMonthPickerProps {
  value?: string;
  defaultValue?: string;
  format?: string;
  onChange: (value: string) => void;
  widthType?: "fill" | "content";
}

interface IKMonthPickerState {}

class KMonthPicker extends PureComponent<IKMonthPickerProps & CommonProps, IKMonthPickerState> {
  constructor(props: IKMonthPickerProps) {
    super(props);
    this.state = {};
  }

  onChange = (e) => {
    if (this.props.onChange) {
      const m = moment(e, "YYYY/MM/DD");
      this.props.onChange(m.format("DD"));
    }
  };

  render(): ReactNode {
    return (
      <>
        <KDatatimePicker {...this.props} onChange={this.onChange} picker="month" />
      </>
    );
  }
}

const kMonthPicker = withCommonEvents(KMonthPicker);
export { kMonthPicker as KMonthPicker };
