import { Input } from "antd/es";
import _ from "lodash";
import React, { PureComponent } from "react";
import NumberFormat, { NumberFormatProps } from "react-number-format";
import withCommonEvents from "../../../shared/hoc/with-common-events";
import { CommonProps } from "../common-props";

declare let window: any;
interface NumericboxProps extends NumberFormatProps {
  onChange: any;
  isThousandSeperatorOn?: boolean;
  max?: number;
  min?: number;
}

interface NumericboxState {}

class NumberInput extends PureComponent<NumericboxProps & CommonProps, NumericboxState> {
  constructor(props: NumericboxProps) {
    super(props);
    this.state = {};
  }

  getProps = () => {
    const props: any = _.clone(this.props);
    let style: any = _.clone(this.props.style);
    const isDesignTime = window.kuika?.isDesignTime;
    if (!style) {
      style = {
        width: "100%"
      };
      props.style = style;
    } else if (!style.width) {
      style.width = "100%";
      props.style = style;
    }
    if (props.onChange) {
      delete props.onChange;
    }
    if (props.decimalSeparator) {
      delete props.decimalSeparator;
    }
    if (props.format) {
      delete props.format;
    }
    if (props.mask) {
      delete props.mask;
    }
    if (props.max) {
      if (props.value && props.value > props.max && isDesignTime === true) {
        props.value = props.max;
      }
      delete props.max;
    }
    if (props.min) {
      if (props.value && props.value < props.min && isDesignTime === true) {
        props.value = props.min;
      }
      delete props.min;
    }
    if (props.value === null) {
      props.value = "";
    } else if (props.value && props.value.value) {
      const tmp = _.clone(props.value.floatValue);
      delete props.value;
      props.value = tmp;
    }
    return props;
  };

  withValueLimit = (values) => {
    const { formattedValue, floatValue } = values;
    if (floatValue == null) {
      return formattedValue === "";
    }
    if (this.props.max && this.props.min) {
      return floatValue <= this.props.max && floatValue >= this.props.min;
    }
    if (this.props.max) {
      return floatValue <= this.props.max;
    }
    if (this.props.min) {
      return floatValue >= this.props.min;
    }
    return floatValue;
  };

  onChange = (value: number | any) => {
    if (this.props.onChange) {
      const newValue = value.value != undefined ? _.clone(value.floatValue) : value;
      this.props.onChange(newValue);
    }
  };

  onBlur = (e: any) => {
    let newValue = e?.target?.value;
    if (e?.target?.value.includes(",")) {
      newValue = parseInt(newValue.replace(/,/g, ""));
    }
    if (this.props.max !== undefined && this.props.max !== null && parseFloat(newValue) > this.props.max) {
      this.props.onChange(this.props.max);
    }
    if (this.props.min !== undefined && this.props.min !== null && parseFloat(newValue) < this.props.min) {
      this.props.onChange(this.props.min);
    }
    if (this.props.onBlur) {
      this.props.onBlur(e);
    }
  };

  getThousandSeparator = () => {
    if (this.props.decimalSeparator == ",") {
      return ".";
    }
    return ",";
  };

  getMask = () => {
    if (
      this.props.mask &&
      (this.props.mask.toString().includes("0") ||
        this.props.mask.toString().includes("1") ||
        this.props.mask.toString().includes("2") ||
        this.props.mask.toString().includes("3") ||
        this.props.mask.toString().includes("4") ||
        this.props.mask.toString().includes("5") ||
        this.props.mask.toString().includes("6") ||
        this.props.mask.toString().includes("7") ||
        this.props.mask.toString().includes("8") ||
        this.props.mask.toString().includes("9"))
    ) {
      return undefined;
    }
    return this.props.mask && this.props.mask.length > 0 ? this.props.mask : undefined;
  };

  getFormat = () => {
    return this.props.format && this.props.format.length > 0 ? this.props.format : undefined;
  };

  getDecimalSeparator = () => {
    return this.props.decimalSeparator ? this.props.decimalSeparator : ".";
  };

  render(): React.ReactNode {
    // if data binded to number input show value as it is on design time
    if (this.props.value && typeof this.props.value === "string" && window.kuika?.dashboardState === 1) {
      return <Input value={this.props.value} style={this.props.style} />;
    }
    return (
      <NumberFormat
        {...this.getProps()}
        customInput={Input}
        onValueChange={this.onChange}
        decimalSeparator={this.getDecimalSeparator()}
        thousandSeparator={this.props.isThousandSeperatorOn ? this.getThousandSeparator() : false}
        mask={this.getMask()}
        format={this.getFormat()}
        onBlur={this.onBlur}
        // isAllowed={this.withValueLimit}
      />
    );
  }
}

const numberInput = withCommonEvents(NumberInput);
export { numberInput as NumberInput };
