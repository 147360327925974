import { Steps } from "antd/es";
import { PureComponent, ReactNode } from "react";
import { CommonProps } from "../../../components/web-components/common/common-props";
import withCommonEvents from "../../../shared/hoc/with-common-events";

const { Step } = Steps;
interface IKStepProps {}

interface IKSteptate {}

class KStep extends PureComponent<IKStepProps & CommonProps, IKSteptate> {
  constructor(props: IKStepProps) {
    super(props);
    this.state = {};
  }

  render(): ReactNode {
    return <Step {...this.props}></Step>;
  }
}

const kStep = withCommonEvents(KStep);
export { kStep as KStep };
