import { Dropdown, Menu, Space } from "antd/es";
import _ from "lodash";
import React, { PureComponent, ReactNode } from "react";
import withCommonEvents from "../../../../shared/hoc/with-common-events";
import { CommonProps } from "../../common/common-props";

declare let window: any;

interface ContentMenuProps {
  children?: ReactNode;
  onMenuClick?: () => void;
  onButtonClick?: () => void;
  imageValue?: string;
  style: any;
  widthType?: "fill" | "content";
  menuOpeningType?: "click" | "hover";
}

interface ContentMenuState {}

class ContentMenu extends PureComponent<ContentMenuProps & CommonProps, ContentMenuState> {
  constructor(props: ContentMenuProps) {
    super(props);
    this.state = {};
  }

  getWidth() {
    if (this.props.widthType && this.props.widthType == "content") {
      return "max-content";
    }
    return "100%";
  }

  handleButtonClick = (e: any) => {
    if (this.props.onButtonClick) {
      this.props.onButtonClick();
    }
  };

  handleMenuClick = (e: any) => {
    if (this.props.onMenuClick) {
      this.props.onMenuClick();
    }
  };

  getProps = () => {
    const props: any = _.clone(this.props);
    let style: React.CSSProperties = _.clone(this.props.style);
    if (props.children) {
      delete props.children;
    }
    if (!style) {
      style = {
        width: this.getWidth()
      };
    } else if (!style.width) {
      style.width = this.getWidth();
    }
    if (style.display) delete style.display;
    props.style = style;
    return props;
  };

  getOpenMenuTypeProps = () => {
    const props: any = _.clone(this.props);
    let menuOpeningType: string = "click";
    if (props.menuOpeningType == "click") {
      props.menuOpeningType = menuOpeningType;
      return props.menuOpeningType;
    }
    if (props.menuOpeningType == "hover") {
      menuOpeningType = "hover";
      props.menuOpeningType = menuOpeningType;
      return props.menuOpeningType;
    }
  };

  getMenuItems = () => {
    if (Array.isArray(this.props?.children)) {
      const menuItems: any = _.clone(this.props?.children);
      menuItems.shift();
      if (window?.kuika?.isDesignTime) return menuItems;

      return menuItems.filter((i) => i?.props?.visibility !== "hidden");
    }
    return <></>;
  };

  getDropdownOverLayStyle = () => {
    const isDesignTime = window.kuika?.isDesignTime;
    const style = {} as React.CSSProperties;
    if (isDesignTime) {
      style.background = "white";
      style.pointerEvents = "none";
    }
    return style;
  };

  render(): ReactNode {
    const menu = (
      <Menu {...this.getProps()} onClick={this.handleMenuClick}>
        {React.Children.map(this.getMenuItems(), (child: any, i) => {
          return (
            <Menu.Item {...child?.props} key={i}>
              {child?.props.label}
            </Menu.Item>
          );
        })}
      </Menu>
    );

    return (
      <Space>
        <Dropdown overlayStyle={this.getDropdownOverLayStyle()} overlay={menu} trigger={this.getOpenMenuTypeProps()}>
          <div>
            {Array.isArray(this.props?.children) && this.props?.children?.length > 0
              ? _.clone(this.props?.children[0])
              : _.clone(this.props?.children)}
          </div>
        </Dropdown>
      </Space>
    );
  }
}

const contentMenu = withCommonEvents(ContentMenu);
export { contentMenu as ContentMenu };
