import React from "react";
import Axios, { AxiosResponse } from "axios";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { KNavigator } from "../../shared/hoc/with-history";
import { KContext } from "../../shared/hoc/with-context";
import { useLocation } from "react-router-dom";
import { FormInstance } from "antd/es/form";
import { Guid } from "guid-typescript";
import Swal from "sweetalert2";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import moment from "moment";
import { IValidationData } from "../../models/dto/validation-data";
import { IStripePayment } from "../../models/dto/stripe-payment";
import { IUserInfoDto } from "../../models/dto/user-info.dto";
import { UserService } from "../../services/user-service";
import _ from "lodash";
import mqtt, { MqttClient } from "mqtt";
import { IMqttResult } from "../../models/dto/mqtt.dto";

declare let window: any;

export interface IMySchedule2_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

export interface IMySchedule2_ScreenState {
    isSpinnerVisible: boolean;
    isPageVisible: boolean;
	isLeftMenuVisible: boolean;
    spinnerCount: number;
    selectedLanguage: string;
    isPageInitLoaded: false;
    isPageRecurringLoaded: false;
	MyContext: any[];
	MyContext_dummy: any[];
	ParametreDate: any[];
	ParametreDate_dummy: any[];
	MySchedule: any[];
	MySchedule_dummy: any[];
	RandevuByProfilTarihSaat: any[];
	RandevuByProfilTarihSaat_dummy: any[];
	NAVIGATE: any;
	NAVIGATE_dummy: any;
	TakvimKapatByProfilTarihSaat: number;
	TakvimKapatByProfilTarihSaat_dummy: number;
	IfThenElse: boolean;
	IfThenElse_dummy: boolean;
	isComp29908Visible: 'visible' | 'hidden';
	isComp449989Visible: 'visible' | 'hidden';
	isComp969422Visible: 'visible' | 'hidden';
	isComp652058Visible: 'visible' | 'hidden';
	isComp320250Visible: 'visible' | 'hidden';
	isComp788765Visible: 'visible' | 'hidden';
	isComp807622Visible: 'visible' | 'hidden';
	isComp80866Visible: 'visible' | 'hidden';
	isComp48315Visible: 'visible' | 'hidden';
	isComp103613Visible: 'visible' | 'hidden';
	isComp262727Visible: 'visible' | 'hidden';
	isComp946807Visible: 'visible' | 'hidden';
	isComp334802Visible: 'visible' | 'hidden';
	isComp109198Visible: 'visible' | 'hidden';
	isComp530580Visible: 'visible' | 'hidden';
	isComp121787Visible: 'visible' | 'hidden';

isMasterPageInitLoaded?: boolean;
  isMasterPageRecurringLoaded?: boolean;
}

export class MySchedule2_ScreenBase extends React.PureComponent<IMySchedule2_ScreenProps, any> {
	myschedule2_29908_value_kuikaDateRef: React.RefObject<any>;
	myschedule2_182442_value_kuikaTableRef: React.RefObject<any>;
    ml=[{"Id":"423ed54a-d6f3-fccb-7d3e-72c7b24c8b7a","Name":"tr_TR","ShortName":"Turkish","IsDefault":true,"LanguagePhrases":[]},{"Id":"74503767-9450-4cae-9db5-729d038bcb36","Name":"en_US","ShortName":"English","LanguagePhrases":[]},{"Id":"0937f015-6bbc-449d-8523-6ee2400bcbcf","Name":"FixedValues","ShortName":"FixedValues","LanguagePhrases":[{"Id":669371,"PropertyName":"value","Value":"AJANDA"},{"Id":29908,"PropertyName":"placeholder","Value":"Select Date..."},{"Id":182442,"PropertyName":"nodatafoundmessage","Value":"No data found."},{"Id":657477,"PropertyName":"value","Value":"[datafield:saat]"},{"Id":449989,"PropertyName":"title","Value":"Title"},{"Id":969422,"PropertyName":"value","Value":"[datafield:kod1]"},{"Id":251608,"PropertyName":"value","Value":"[datafield:label1]"},{"Id":652058,"PropertyName":"title","Value":"Title"},{"Id":320250,"PropertyName":"value","Value":"[datafield:kod2]"},{"Id":473027,"PropertyName":"value","Value":"[datafield:label2]"},{"Id":788765,"PropertyName":"title","Value":"Title"},{"Id":807622,"PropertyName":"value","Value":"[datafield:kod3]"},{"Id":171435,"PropertyName":"value","Value":"[datafield:label3]"},{"Id":80866,"PropertyName":"title","Value":"Title"},{"Id":48315,"PropertyName":"value","Value":"[datafield:kod4]"},{"Id":628943,"PropertyName":"value","Value":"[datafield:label4]"},{"Id":103613,"PropertyName":"title","Value":"Title"},{"Id":262727,"PropertyName":"value","Value":"[datafield:kod5]"},{"Id":388107,"PropertyName":"value","Value":"[datafield:label5]"},{"Id":946807,"PropertyName":"title","Value":"Title"},{"Id":334802,"PropertyName":"value","Value":"[datafield:kod6]"},{"Id":308118,"PropertyName":"value","Value":"[datafield:label6]"},{"Id":109198,"PropertyName":"title","Value":"Title"},{"Id":530580,"PropertyName":"value","Value":"[datafield:kod7]"},{"Id":727017,"PropertyName":"value","Value":"[datafield:label7]"},{"Id":121787,"PropertyName":"label","Value":"Button text"}]}]
    defaultML = "tr_TR"
    
    screenHash = ""
    
    constructor(props) {
        super(props);
		this.myschedule2_29908_value_kuikaDateRef = React.createRef();
		this.myschedule2_182442_value_kuikaTableRef = React.createRef();

        this.state = {
            isSpinnerVisible: false,
            isPageVisible: false,
            isLeftMenuVisible: false,
            spinnerCount: 0,
            selectedLanguage: "",
            isPageInitLoaded: false,
            isPageRecurringLoaded: false,
            	        MyContext: [],
	        ParametreDate: [],
	        MySchedule: [],
	        IfThenElse: false,
	        isComp29908Visible: 'hidden',
	        isComp449989Visible: 'hidden',
	        isComp969422Visible: 'hidden',
	        isComp652058Visible: 'hidden',
	        isComp320250Visible: 'hidden',
	        isComp788765Visible: 'hidden',
	        isComp807622Visible: 'hidden',
	        isComp80866Visible: 'hidden',
	        isComp48315Visible: 'hidden',
	        isComp103613Visible: 'hidden',
	        isComp262727Visible: 'hidden',
	        isComp946807Visible: 'hidden',
	        isComp334802Visible: 'hidden',
	        isComp109198Visible: 'hidden',
	        isComp530580Visible: 'hidden',
	        isComp121787Visible: 'hidden',

            isMasterPageInitLoaded: false,
  isMasterPageRecurringLoaded: false,
        }

        this.state.isPageVisible = true;
        
    }

    async componentDidMount() {

        if (KuikaAppManager.isPageInitPrevented()) {
            KuikaAppManager.calculateAndSetBodyHeight("myschedule2", "master_screen");
            return;
        }
        
        ReactSystemFunctions.validateToken()
        if (false){
            await this.MySchedule2PageInit();
        }
        
        
        
        KuikaAppManager.calculateAndSetBodyHeight("myschedule2", "master_screen");
    }

    componentWillUnmount() {
        
        
        
    }

    

        
    componentDidUpdate= async (prevProps, prevState) => {
		KuikaAppManager.calculateAndSetBodyHeight("myschedule2", "master_screen"    );
        if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
			await this.MySchedule2PageInit();
		}

        		if (prevState.isMasterPageInitLoaded !== this.state.isMasterPageInitLoaded && this.state.isMasterPageInitLoaded === true) {await this.MySchedule2PageInit();}

	}

    fillFormInitValues() {
        this.props.form.setFieldsValue({
myschedule2_29908_value: this.state.ParametreDate?.at?.(0)?.dateprm ?? undefined
});

    }

      setPageInitLoaded = (value: boolean) => {
        this.setState({ isPageInitLoaded: value });
      }

      setPageRecurringLoaded = (value: boolean) => {
        this.setState({ isPageRecurringLoaded: value });
      }

      clearFieldValidation = (changedValues: any) => {
        const fieldName = Object.keys(changedValues)[0];
        if (fieldName) {
          this.props.form.setFields([
            {
              name: fieldName,
              errors: []
            }
          ]);
        }
      };

      

        setMasterPageInitLoaded = (value: boolean) => {
    this.setState({ isMasterPageInitLoaded: value });
  }

  setMasterPageRecurringLoaded = (value: boolean) => {
    this.setState({ isMasterPageRecurringLoaded: value });
  }

    MySchedule2PageInit = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }

        let localVar = {
			ekGun_1: ReactSystemFunctions.convertToTypeByName(0, "number"),
			date_1: ReactSystemFunctions.convertToTypeByName(moment().format("YYYY-MM-DDTHH:mm:ss"), "Date")
        }

	KuikaAppManager.showSpinner(this);
	
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "MySchedule2/MySchedule2PageInit", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

		stateVars.MyContext = result?.data.myContext;
		stateVars.ParametreDate = result?.data.parametreDate;
		formVars.myschedule2_924363_value = ReactSystemFunctions.toString(this, (stateVars.ParametreDate?.length > 0 ? stateVars.ParametreDate[0].dateprm : this.state.ParametreDate?.length > 0 ? this.state.ParametreDate[0].dateprm : null));
		formVars.myschedule2_29908_value = ReactSystemFunctions.value(this, (stateVars.ParametreDate?.length > 0 ? stateVars.ParametreDate[0].dateprm : this.state.ParametreDate?.length > 0 ? this.state.ParametreDate[0].dateprm : null));
		stateVars.MySchedule = result?.data.mySchedule;
		
		stateVars.isComp449989Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label1 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label1 : null)), null)) === true ? "visible" : "hidden")
		stateVars.isComp652058Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label2 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label2 : null)), null)) === true ? "visible" : "hidden")
		stateVars.isComp788765Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label3 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label3 : null)), null)) === true ? "visible" : "hidden")
		stateVars.isComp80866Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label4 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label4 : null)), null)) === true ? "visible" : "hidden")
		stateVars.isComp103613Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label5 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label5 : null)), null)) === true ? "visible" : "hidden")
		stateVars.isComp946807Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label6 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label6 : null)), null)) === true ? "visible" : "hidden")
		stateVars.isComp109198Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label7 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label7 : null)), null)) === true ? "visible" : "hidden")
		this.props.form.setFieldsValue(formVars);
		
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.MySchedule2PageInit1_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
	MySchedule2PageInit1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }

			formVars.myschedule2_924363_value = ReactSystemFunctions.toString(this, (stateVars.ParametreDate?.length > 0 ? stateVars.ParametreDate[0].dateprm : this.state.ParametreDate?.length > 0 ? this.state.ParametreDate[0].dateprm : null));

			formVars.myschedule2_29908_value = ReactSystemFunctions.value(this, (stateVars.ParametreDate?.length > 0 ? stateVars.ParametreDate[0].dateprm : this.state.ParametreDate?.length > 0 ? this.state.ParametreDate[0].dateprm : null));

			
			stateVars.dataSource_182442 = this.state.MySchedule;
			stateVars.isComp449989Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label1 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label1 : null)), null)) === true ? "visible" : "hidden")
			stateVars.isComp969422Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.value(this, "myschedule2_182442_value", "kod1"), null)) === true ? "visible" : "hidden")
			stateVars.isComp652058Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label2 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label2 : null)), null)) === true ? "visible" : "hidden")
			stateVars.isComp320250Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.value(this, "myschedule2_182442_value", "kod2"), null)) === true ? "visible" : "hidden")
			stateVars.isComp788765Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label3 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label3 : null)), null)) === true ? "visible" : "hidden")
			stateVars.isComp807622Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.value(this, "myschedule2_182442_value", "kod3"), null)) === true ? "visible" : "hidden")
			stateVars.isComp80866Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label4 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label4 : null)), null)) === true ? "visible" : "hidden")
			stateVars.isComp48315Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.value(this, "myschedule2_182442_value", "kod4"), null)) === true ? "visible" : "hidden")
			stateVars.isComp103613Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label5 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label5 : null)), null)) === true ? "visible" : "hidden")
			stateVars.isComp262727Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.value(this, "myschedule2_182442_value", "kod5"), null)) === true ? "visible" : "hidden")
			stateVars.isComp946807Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label6 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label6 : null)), null)) === true ? "visible" : "hidden")
			stateVars.isComp334802Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.value(this, "myschedule2_182442_value", "kod6"), null)) === true ? "visible" : "hidden")
			stateVars.isComp109198Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label7 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label7 : null)), null)) === true ? "visible" : "hidden")
			stateVars.isComp530580Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.value(this, "myschedule2_182442_value", "kod7"), null)) === true ? "visible" : "hidden")

	this.props.form.setFieldsValue(formVars);
	this.setState(stateVars);
	KuikaAppManager.hideSpinner(this);

            this.setPageInitLoaded(true);
            return isErrorOccurred;
        }




    MySchedule2Component_672785_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				ekGun_0: ReactSystemFunctions.convertToTypeByName(-7, "number"),
				date_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "myschedule2_29908_value", "value", "", "", "")), "Date")
        }

		KuikaAppManager.showSpinner(this);
if (ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].rolename : this.state.MyContext?.length > 0 ? this.state.MyContext[0].rolename : null)), "Rol_Instructor")) {
            		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "MySchedule2/MySchedule2Component_672785_onClick", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.ParametreDate = result?.data.parametreDate;
			formVars.myschedule2_924363_value = ReactSystemFunctions.toString(this, (stateVars.ParametreDate?.length > 0 ? stateVars.ParametreDate[0].dateprm : this.state.ParametreDate?.length > 0 ? this.state.ParametreDate[0].dateprm : null));
			formVars.myschedule2_29908_value = ReactSystemFunctions.value(this, (stateVars.ParametreDate?.length > 0 ? stateVars.ParametreDate[0].dateprm : this.state.ParametreDate?.length > 0 ? this.state.ParametreDate[0].dateprm : null));
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.MySchedule2Component_672785_onClick1_();
                  resolve();
                });
          })

        } else {
            
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.MySchedule2Component_672785_onClick1_();
                  resolve();
                });
          })
        }


        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
    MySchedule2Component_672785_onClick1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				ekGun_0: ReactSystemFunctions.convertToTypeByName(-1, "number"),
				date_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "myschedule2_29908_value", "value", "", "", "")), "Date")
        }

if (ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].rolename : this.state.MyContext?.length > 0 ? this.state.MyContext[0].rolename : null)), "Rol_Manager")) {
            		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "MySchedule2/MySchedule2Component_672785_onClick1_", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.ParametreDate = result?.data.parametreDate;
			formVars.myschedule2_924363_value = ReactSystemFunctions.toString(this, (stateVars.ParametreDate?.length > 0 ? stateVars.ParametreDate[0].dateprm : this.state.ParametreDate?.length > 0 ? this.state.ParametreDate[0].dateprm : null));
			formVars.myschedule2_29908_value = ReactSystemFunctions.value(this, (stateVars.ParametreDate?.length > 0 ? stateVars.ParametreDate[0].dateprm : this.state.ParametreDate?.length > 0 ? this.state.ParametreDate[0].dateprm : null));
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.MySchedule2Component_672785_onClick2_();
                  resolve();
                });
          })

        } else {
            
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.MySchedule2Component_672785_onClick2_();
                  resolve();
                });
          })
        }


        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
    MySchedule2Component_672785_onClick2_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				profilId_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].lastChosenProfileId : this.state.MyContext?.length > 0 ? this.state.MyContext[0].lastChosenProfileId : null)), "Guid"),
				date_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.ParametreDate?.length > 0 ? stateVars.ParametreDate[0].dateprm : this.state.ParametreDate?.length > 0 ? this.state.ParametreDate[0].dateprm : null)), "Date")
        }

		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "MySchedule2/MySchedule2Component_672785_onClick2_", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.MySchedule = result?.data.mySchedule;
			
			stateVars.isComp449989Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label1 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label1 : null)), null)) === true ? "visible" : "hidden")
			stateVars.isComp652058Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label2 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label2 : null)), null)) === true ? "visible" : "hidden")
			stateVars.isComp788765Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label3 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label3 : null)), null)) === true ? "visible" : "hidden")
			stateVars.isComp80866Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label4 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label4 : null)), null)) === true ? "visible" : "hidden")
			stateVars.isComp103613Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label5 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label5 : null)), null)) === true ? "visible" : "hidden")
			stateVars.isComp946807Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label6 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label6 : null)), null)) === true ? "visible" : "hidden")
			stateVars.isComp109198Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label7 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label7 : null)), null)) === true ? "visible" : "hidden")
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.MySchedule2Component_672785_onClick3_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
		MySchedule2Component_672785_onClick3_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
if (ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].rolename : this.state.MyContext?.length > 0 ? this.state.MyContext[0].rolename : null)), "Rol_Instructor")) {
            
				formVars.myschedule2_924363_value = ReactSystemFunctions.toString(this, (stateVars.ParametreDate?.length > 0 ? stateVars.ParametreDate[0].dateprm : this.state.ParametreDate?.length > 0 ? this.state.ParametreDate[0].dateprm : null));


		this.props.form.setFieldsValue(formVars);
		
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.MySchedule2Component_672785_onClick4_();
                  resolve();
                });
          })

        } else {
            
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.MySchedule2Component_672785_onClick4_();
                  resolve();
                });
          })
        }

            
            return isErrorOccurred;
        }
		MySchedule2Component_672785_onClick4_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
if (ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].rolename : this.state.MyContext?.length > 0 ? this.state.MyContext[0].rolename : null)), "Rol_Instructor")) {
            
				formVars.myschedule2_29908_value = ReactSystemFunctions.value(this, (stateVars.ParametreDate?.length > 0 ? stateVars.ParametreDate[0].dateprm : this.state.ParametreDate?.length > 0 ? this.state.ParametreDate[0].dateprm : null));


		this.props.form.setFieldsValue(formVars);
		
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.MySchedule2Component_672785_onClick5_();
                  resolve();
                });
          })

        } else {
            
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.MySchedule2Component_672785_onClick5_();
                  resolve();
                });
          })
        }

            
            return isErrorOccurred;
        }
		MySchedule2Component_672785_onClick5_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }

				
				stateVars.dataSource_182442 = this.state.MySchedule;

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }










    MySchedule2Component_381201_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				ekGun_0: ReactSystemFunctions.convertToTypeByName(7, "number"),
				date_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "myschedule2_29908_value", "value", "", "", "")), "Date")
        }

		KuikaAppManager.showSpinner(this);
if (ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].rolename : this.state.MyContext?.length > 0 ? this.state.MyContext[0].rolename : null)), "Rol_Instructor")) {
            		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "MySchedule2/MySchedule2Component_381201_onClick", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.ParametreDate = result?.data.parametreDate;
			formVars.myschedule2_924363_value = ReactSystemFunctions.toString(this, (stateVars.ParametreDate?.length > 0 ? stateVars.ParametreDate[0].dateprm : this.state.ParametreDate?.length > 0 ? this.state.ParametreDate[0].dateprm : null));
			formVars.myschedule2_29908_value = ReactSystemFunctions.value(this, (stateVars.ParametreDate?.length > 0 ? stateVars.ParametreDate[0].dateprm : this.state.ParametreDate?.length > 0 ? this.state.ParametreDate[0].dateprm : null));
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.MySchedule2Component_381201_onClick1_();
                  resolve();
                });
          })

        } else {
            
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.MySchedule2Component_381201_onClick1_();
                  resolve();
                });
          })
        }


        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
    MySchedule2Component_381201_onClick1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				ekGun_0: ReactSystemFunctions.convertToTypeByName(1, "number"),
				date_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "myschedule2_29908_value", "value", "", "", "")), "Date")
        }

if (ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].rolename : this.state.MyContext?.length > 0 ? this.state.MyContext[0].rolename : null)), "Rol_Manager")) {
            		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "MySchedule2/MySchedule2Component_381201_onClick1_", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.ParametreDate = result?.data.parametreDate;
			formVars.myschedule2_924363_value = ReactSystemFunctions.toString(this, (stateVars.ParametreDate?.length > 0 ? stateVars.ParametreDate[0].dateprm : this.state.ParametreDate?.length > 0 ? this.state.ParametreDate[0].dateprm : null));
			formVars.myschedule2_29908_value = ReactSystemFunctions.value(this, (stateVars.ParametreDate?.length > 0 ? stateVars.ParametreDate[0].dateprm : this.state.ParametreDate?.length > 0 ? this.state.ParametreDate[0].dateprm : null));
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.MySchedule2Component_381201_onClick2_();
                  resolve();
                });
          })

        } else {
            
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.MySchedule2Component_381201_onClick2_();
                  resolve();
                });
          })
        }


        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
    MySchedule2Component_381201_onClick2_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				profilId_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].lastChosenProfileId : this.state.MyContext?.length > 0 ? this.state.MyContext[0].lastChosenProfileId : null)), "Guid"),
				date_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.ParametreDate?.length > 0 ? stateVars.ParametreDate[0].dateprm : this.state.ParametreDate?.length > 0 ? this.state.ParametreDate[0].dateprm : null)), "Date")
        }

		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "MySchedule2/MySchedule2Component_381201_onClick2_", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.MySchedule = result?.data.mySchedule;
			
			stateVars.isComp449989Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label1 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label1 : null)), null)) === true ? "visible" : "hidden")
			stateVars.isComp652058Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label2 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label2 : null)), null)) === true ? "visible" : "hidden")
			stateVars.isComp788765Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label3 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label3 : null)), null)) === true ? "visible" : "hidden")
			stateVars.isComp80866Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label4 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label4 : null)), null)) === true ? "visible" : "hidden")
			stateVars.isComp103613Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label5 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label5 : null)), null)) === true ? "visible" : "hidden")
			stateVars.isComp946807Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label6 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label6 : null)), null)) === true ? "visible" : "hidden")
			stateVars.isComp109198Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label7 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label7 : null)), null)) === true ? "visible" : "hidden")
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.MySchedule2Component_381201_onClick3_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
		MySchedule2Component_381201_onClick3_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
if (ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].rolename : this.state.MyContext?.length > 0 ? this.state.MyContext[0].rolename : null)), "Rol_Instructor")) {
            
				formVars.myschedule2_924363_value = ReactSystemFunctions.toString(this, (stateVars.ParametreDate?.length > 0 ? stateVars.ParametreDate[0].dateprm : this.state.ParametreDate?.length > 0 ? this.state.ParametreDate[0].dateprm : null));


		this.props.form.setFieldsValue(formVars);
		
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.MySchedule2Component_381201_onClick4_();
                  resolve();
                });
          })

        } else {
            
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.MySchedule2Component_381201_onClick4_();
                  resolve();
                });
          })
        }

            
            return isErrorOccurred;
        }
		MySchedule2Component_381201_onClick4_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
if (ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].rolename : this.state.MyContext?.length > 0 ? this.state.MyContext[0].rolename : null)), "Rol_Instructor")) {
            
				formVars.myschedule2_29908_value = ReactSystemFunctions.value(this, (stateVars.ParametreDate?.length > 0 ? stateVars.ParametreDate[0].dateprm : this.state.ParametreDate?.length > 0 ? this.state.ParametreDate[0].dateprm : null));


		this.props.form.setFieldsValue(formVars);
		
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.MySchedule2Component_381201_onClick5_();
                  resolve();
                });
          })

        } else {
            
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.MySchedule2Component_381201_onClick5_();
                  resolve();
                });
          })
        }

            
            return isErrorOccurred;
        }
		MySchedule2Component_381201_onClick5_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }

				
				stateVars.dataSource_182442 = this.state.MySchedule;

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }










    MySchedule2Component_856923_onClick_if0 = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				profileId_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].lastChosenProfileId : this.state.MyContext?.length > 0 ? this.state.MyContext[0].lastChosenProfileId : null)), "Guid"),
				date_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "myschedule2_29908_value", "value", "", "", "")), "Date"),
				dateIncrement_0: ReactSystemFunctions.convertToTypeByName(0, "number"),
				hour_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, "myschedule2_182442_value", "saat"), "string")
        }

		KuikaAppManager.showSpinner(this);
		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "MySchedule2/MySchedule2Component_856923_onClick_if0", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.RandevuByProfilTarihSaat = result?.data.randevuByProfilTarihSaat;
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.MySchedule2Component_856923_onClick_if01_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
		MySchedule2Component_856923_onClick_if01_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }

					KuikaAppManager.prepareForNavigation();
						KuikaAppManager.addToPageInputVariables("RandevuSummary", "RandevuId", ReactSystemFunctions.value(this, (stateVars.RandevuByProfilTarihSaat?.length > 0 ? stateVars.RandevuByProfilTarihSaat[0].id : this.state.RandevuByProfilTarihSaat?.length > 0 ? this.state.RandevuByProfilTarihSaat[0].id : null)));

				stateVars.NAVIGATE = await ReactSystemFunctions.navigate(this, undefined, "MySchedule2", "RandevuSummary", "", "7d969ddf-5801-4eb6-b92b-12be35bdd565", "877132", null, "right", null, "380px", "100vh", true);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);

            
            return isErrorOccurred;
        }




		MySchedule2Component_856923_onClick_else0 = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
KuikaAppManager.showSpinner(this);


		this.props.form.setFieldsValue(formVars);
		
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.MySchedule2Component_856923_onClick_else01_();
                  resolve();
                });
          })

            
            return isErrorOccurred;
        }
    MySchedule2Component_856923_onClick_else0f1 = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
        }

		KuikaAppManager.showSpinner(this);

			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.MySchedule2Component_856923_onClick_else0f11_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
    MySchedule2Component_856923_onClick_else0f11_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				profileId_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].lastChosenProfileId : this.state.MyContext?.length > 0 ? this.state.MyContext[0].lastChosenProfileId : null)), "Guid"),
				date_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "myschedule2_29908_value", "value", "", "", "")), "Date"),
				dateIncrement_0: ReactSystemFunctions.convertToTypeByName(0, "number"),
				hour_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, "myschedule2_182442_value", "saat"), "string"),
				profilId_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].lastChosenProfileId : this.state.MyContext?.length > 0 ? this.state.MyContext[0].lastChosenProfileId : null)), "Guid"),
				date_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.ParametreDate?.length > 0 ? stateVars.ParametreDate[0].dateprm : this.state.ParametreDate?.length > 0 ? this.state.ParametreDate[0].dateprm : null)), "Date")
        }


            stateVars.Confirm = await Swal.fire({
                title: ReactSystemFunctions.translateCustomActions(this.ml,"6563f69d_c0bb_98ab_3e39_771a7fa80e8c_confirmation",this.defaultML,"Bu saati kapatmak istediğinize emin misiniz?" ),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: localStorage.getItem("ml") === 'tr_TR' ? 'Onayla' : localStorage.getItem("ml") === 'fr_FR' ? 'Confirmer' : 'Confirm',
                cancelButtonText: localStorage.getItem("ml") === 'tr_TR' ? 'Vazgeç' : localStorage.getItem("ml") === 'fr_FR' ? 'Annuler' : 'Cancel',
                customClass: {
                  title: 'swal2-font-poppins',
                }
            })

            if (stateVars.Confirm.isConfirmed)
            {
                		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "MySchedule2/MySchedule2Component_856923_onClick_else0f11_", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.TakvimKapatByProfilTarihSaat = result?.data.takvimKapatByProfilTarihSaat;
			stateVars.MySchedule = result?.data.mySchedule;
			
			stateVars.isComp449989Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label1 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label1 : null)), null)) === true ? "visible" : "hidden")
			stateVars.isComp652058Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label2 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label2 : null)), null)) === true ? "visible" : "hidden")
			stateVars.isComp788765Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label3 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label3 : null)), null)) === true ? "visible" : "hidden")
			stateVars.isComp80866Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label4 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label4 : null)), null)) === true ? "visible" : "hidden")
			stateVars.isComp103613Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label5 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label5 : null)), null)) === true ? "visible" : "hidden")
			stateVars.isComp946807Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label6 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label6 : null)), null)) === true ? "visible" : "hidden")
			stateVars.isComp109198Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label7 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label7 : null)), null)) === true ? "visible" : "hidden")
			this.props.form.setFieldsValue(formVars);
			this.setState(stateVars);
			KuikaAppManager.hideSpinner(this);

            }


        this.setPageInitLoaded(true);
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }





    MySchedule2Component_856923_onClick_else0e1 = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				profileId_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].lastChosenProfileId : this.state.MyContext?.length > 0 ? this.state.MyContext[0].lastChosenProfileId : null)), "Guid"),
				date_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "myschedule2_29908_value", "value", "", "", "")), "Date"),
				dateIncrement_0: ReactSystemFunctions.convertToTypeByName(0, "number"),
				hour_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, "myschedule2_182442_value", "saat"), "string"),
				profilId_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].lastChosenProfileId : this.state.MyContext?.length > 0 ? this.state.MyContext[0].lastChosenProfileId : null)), "Guid"),
				date_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.ParametreDate?.length > 0 ? stateVars.ParametreDate[0].dateprm : this.state.ParametreDate?.length > 0 ? this.state.ParametreDate[0].dateprm : null)), "Date")
        }

		KuikaAppManager.showSpinner(this);
		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "MySchedule2/MySchedule2Component_856923_onClick_else0e1", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.TakvimKapatByProfilTarihSaat = result?.data.takvimKapatByProfilTarihSaat;
			stateVars.MySchedule = result?.data.mySchedule;
			
			stateVars.isComp449989Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label1 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label1 : null)), null)) === true ? "visible" : "hidden")
			stateVars.isComp652058Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label2 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label2 : null)), null)) === true ? "visible" : "hidden")
			stateVars.isComp788765Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label3 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label3 : null)), null)) === true ? "visible" : "hidden")
			stateVars.isComp80866Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label4 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label4 : null)), null)) === true ? "visible" : "hidden")
			stateVars.isComp103613Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label5 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label5 : null)), null)) === true ? "visible" : "hidden")
			stateVars.isComp946807Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label6 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label6 : null)), null)) === true ? "visible" : "hidden")
			stateVars.isComp109198Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label7 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label7 : null)), null)) === true ? "visible" : "hidden")
			this.props.form.setFieldsValue(formVars);
			this.setState(stateVars);
			KuikaAppManager.hideSpinner(this);



        this.setPageInitLoaded(true);
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }



		MySchedule2Component_856923_onClick_else01_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
if (ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, "myschedule2_182442_value", "color1"), "#FFF")) {
            isErrorOccurred = await this.MySchedule2Component_856923_onClick_else0f1();
                if (isErrorOccurred) return true;
        } else {
            isErrorOccurred = await this.MySchedule2Component_856923_onClick_else0e1();
                if (isErrorOccurred) return true;
        }


		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);

            
            return isErrorOccurred;
        }



		MySchedule2Component_856923_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.value(this, "myschedule2_182442_value", "label1"), null)) {
            isErrorOccurred = await this.MySchedule2Component_856923_onClick_if0();
                if (isErrorOccurred) return true;
        } else {
            isErrorOccurred = await this.MySchedule2Component_856923_onClick_else0();
                if (isErrorOccurred) return true;
        }
KuikaAppManager.showSpinner(this);


		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }


    MySchedule2Component_329739_onClick_if0 = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				profileId_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].lastChosenProfileId : this.state.MyContext?.length > 0 ? this.state.MyContext[0].lastChosenProfileId : null)), "Guid"),
				date_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "myschedule2_29908_value", "value", "", "", "")), "Date"),
				dateIncrement_0: ReactSystemFunctions.convertToTypeByName(-3, "number"),
				hour_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, "myschedule2_182442_value", "saat"), "string")
        }

		KuikaAppManager.showSpinner(this);
		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "MySchedule2/MySchedule2Component_329739_onClick_if0", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.RandevuByProfilTarihSaat = result?.data.randevuByProfilTarihSaat;
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.MySchedule2Component_329739_onClick_if01_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
		MySchedule2Component_329739_onClick_if01_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }

					KuikaAppManager.prepareForNavigation();
						KuikaAppManager.addToPageInputVariables("RandevuSummary", "RandevuId", ReactSystemFunctions.value(this, (stateVars.RandevuByProfilTarihSaat?.length > 0 ? stateVars.RandevuByProfilTarihSaat[0].id : this.state.RandevuByProfilTarihSaat?.length > 0 ? this.state.RandevuByProfilTarihSaat[0].id : null)));

				stateVars.NAVIGATE = await ReactSystemFunctions.navigate(this, undefined, "MySchedule2", "RandevuSummary", "", "7d969ddf-5801-4eb6-b92b-12be35bdd565", "877132", null, "right", null, "380px", "100vh", true);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);

            
            return isErrorOccurred;
        }




		MySchedule2Component_329739_onClick_else0 = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
KuikaAppManager.showSpinner(this);


		this.props.form.setFieldsValue(formVars);
		
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.MySchedule2Component_329739_onClick_else01_();
                  resolve();
                });
          })

            
            return isErrorOccurred;
        }
    MySchedule2Component_329739_onClick_else0f1 = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
        }

		KuikaAppManager.showSpinner(this);

			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.MySchedule2Component_329739_onClick_else0f11_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
    MySchedule2Component_329739_onClick_else0f11_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				profileId_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].lastChosenProfileId : this.state.MyContext?.length > 0 ? this.state.MyContext[0].lastChosenProfileId : null)), "Guid"),
				date_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "myschedule2_29908_value", "value", "", "", "")), "Date"),
				dateIncrement_0: ReactSystemFunctions.convertToTypeByName(1, "number"),
				hour_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, "myschedule2_182442_value", "saat"), "string"),
				profilId_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].lastChosenProfileId : this.state.MyContext?.length > 0 ? this.state.MyContext[0].lastChosenProfileId : null)), "Guid"),
				date_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.ParametreDate?.length > 0 ? stateVars.ParametreDate[0].dateprm : this.state.ParametreDate?.length > 0 ? this.state.ParametreDate[0].dateprm : null)), "Date")
        }


            stateVars.Confirm = await Swal.fire({
                title: ReactSystemFunctions.translateCustomActions(this.ml,"6563f69d_c0bb_98ab_3e39_771a7fa80e8c_confirmation",this.defaultML,"Bu saati kapatmak istediğinize emin misiniz?" ),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: localStorage.getItem("ml") === 'tr_TR' ? 'Onayla' : localStorage.getItem("ml") === 'fr_FR' ? 'Confirmer' : 'Confirm',
                cancelButtonText: localStorage.getItem("ml") === 'tr_TR' ? 'Vazgeç' : localStorage.getItem("ml") === 'fr_FR' ? 'Annuler' : 'Cancel',
                customClass: {
                  title: 'swal2-font-poppins',
                }
            })

            if (stateVars.Confirm.isConfirmed)
            {
                		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "MySchedule2/MySchedule2Component_329739_onClick_else0f11_", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.TakvimKapatByProfilTarihSaat = result?.data.takvimKapatByProfilTarihSaat;
			stateVars.MySchedule = result?.data.mySchedule;
			
			stateVars.isComp449989Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label1 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label1 : null)), null)) === true ? "visible" : "hidden")
			stateVars.isComp652058Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label2 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label2 : null)), null)) === true ? "visible" : "hidden")
			stateVars.isComp788765Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label3 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label3 : null)), null)) === true ? "visible" : "hidden")
			stateVars.isComp80866Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label4 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label4 : null)), null)) === true ? "visible" : "hidden")
			stateVars.isComp103613Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label5 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label5 : null)), null)) === true ? "visible" : "hidden")
			stateVars.isComp946807Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label6 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label6 : null)), null)) === true ? "visible" : "hidden")
			stateVars.isComp109198Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label7 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label7 : null)), null)) === true ? "visible" : "hidden")
			this.props.form.setFieldsValue(formVars);
			this.setState(stateVars);
			KuikaAppManager.hideSpinner(this);

            }


        this.setPageInitLoaded(true);
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }





    MySchedule2Component_329739_onClick_else0e1 = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				profileId_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].lastChosenProfileId : this.state.MyContext?.length > 0 ? this.state.MyContext[0].lastChosenProfileId : null)), "Guid"),
				date_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "myschedule2_29908_value", "value", "", "", "")), "Date"),
				dateIncrement_0: ReactSystemFunctions.convertToTypeByName(1, "number"),
				hour_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, "myschedule2_182442_value", "saat"), "string"),
				profilId_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].lastChosenProfileId : this.state.MyContext?.length > 0 ? this.state.MyContext[0].lastChosenProfileId : null)), "Guid"),
				date_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.ParametreDate?.length > 0 ? stateVars.ParametreDate[0].dateprm : this.state.ParametreDate?.length > 0 ? this.state.ParametreDate[0].dateprm : null)), "Date")
        }

		KuikaAppManager.showSpinner(this);
		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "MySchedule2/MySchedule2Component_329739_onClick_else0e1", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.TakvimKapatByProfilTarihSaat = result?.data.takvimKapatByProfilTarihSaat;
			stateVars.MySchedule = result?.data.mySchedule;
			
			stateVars.isComp449989Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label1 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label1 : null)), null)) === true ? "visible" : "hidden")
			stateVars.isComp652058Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label2 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label2 : null)), null)) === true ? "visible" : "hidden")
			stateVars.isComp788765Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label3 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label3 : null)), null)) === true ? "visible" : "hidden")
			stateVars.isComp80866Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label4 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label4 : null)), null)) === true ? "visible" : "hidden")
			stateVars.isComp103613Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label5 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label5 : null)), null)) === true ? "visible" : "hidden")
			stateVars.isComp946807Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label6 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label6 : null)), null)) === true ? "visible" : "hidden")
			stateVars.isComp109198Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label7 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label7 : null)), null)) === true ? "visible" : "hidden")
			this.props.form.setFieldsValue(formVars);
			this.setState(stateVars);
			KuikaAppManager.hideSpinner(this);



        this.setPageInitLoaded(true);
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }



		MySchedule2Component_329739_onClick_else01_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
if ((ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, "myschedule2_182442_value", "color2"), "#FFF"))) {
            isErrorOccurred = await this.MySchedule2Component_329739_onClick_else0f1();
                if (isErrorOccurred) return true;
        } else {
            isErrorOccurred = await this.MySchedule2Component_329739_onClick_else0e1();
                if (isErrorOccurred) return true;
        }


		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);

            
            return isErrorOccurred;
        }



		MySchedule2Component_329739_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
if ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.value(this, "myschedule2_182442_value", "label2"), null))) {
            isErrorOccurred = await this.MySchedule2Component_329739_onClick_if0();
                if (isErrorOccurred) return true;
        } else {
            isErrorOccurred = await this.MySchedule2Component_329739_onClick_else0();
                if (isErrorOccurred) return true;
        }
KuikaAppManager.showSpinner(this);


		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }


    MySchedule2Component_636122_onClick_if0 = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				profileId_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].lastChosenProfileId : this.state.MyContext?.length > 0 ? this.state.MyContext[0].lastChosenProfileId : null)), "Guid"),
				date_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "myschedule2_29908_value", "value", "", "", "")), "Date"),
				dateIncrement_0: ReactSystemFunctions.convertToTypeByName(2, "number"),
				hour_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, "myschedule2_182442_value", "saat"), "string")
        }

		KuikaAppManager.showSpinner(this);
		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "MySchedule2/MySchedule2Component_636122_onClick_if0", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.RandevuByProfilTarihSaat = result?.data.randevuByProfilTarihSaat;
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.MySchedule2Component_636122_onClick_if01_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
		MySchedule2Component_636122_onClick_if01_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }

					KuikaAppManager.prepareForNavigation();
						KuikaAppManager.addToPageInputVariables("RandevuSummary", "RandevuId", ReactSystemFunctions.value(this, (stateVars.RandevuByProfilTarihSaat?.length > 0 ? stateVars.RandevuByProfilTarihSaat[0].id : this.state.RandevuByProfilTarihSaat?.length > 0 ? this.state.RandevuByProfilTarihSaat[0].id : null)));

				stateVars.NAVIGATE = await ReactSystemFunctions.navigate(this, undefined, "MySchedule2", "RandevuSummary", "", "7d969ddf-5801-4eb6-b92b-12be35bdd565", "877132", null, "right", null, "380px", "100vh", true);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);

            
            return isErrorOccurred;
        }




		MySchedule2Component_636122_onClick_else0 = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
KuikaAppManager.showSpinner(this);


		this.props.form.setFieldsValue(formVars);
		
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.MySchedule2Component_636122_onClick_else01_();
                  resolve();
                });
          })

            
            return isErrorOccurred;
        }
    MySchedule2Component_636122_onClick_else0f1 = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
        }

		KuikaAppManager.showSpinner(this);

			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.MySchedule2Component_636122_onClick_else0f11_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
    MySchedule2Component_636122_onClick_else0f11_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				profileId_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].lastChosenProfileId : this.state.MyContext?.length > 0 ? this.state.MyContext[0].lastChosenProfileId : null)), "Guid"),
				date_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "myschedule2_29908_value", "value", "", "", "")), "Date"),
				dateIncrement_0: ReactSystemFunctions.convertToTypeByName(2, "number"),
				hour_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, "myschedule2_182442_value", "saat"), "string"),
				profilId_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].lastChosenProfileId : this.state.MyContext?.length > 0 ? this.state.MyContext[0].lastChosenProfileId : null)), "Guid"),
				date_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.ParametreDate?.length > 0 ? stateVars.ParametreDate[0].dateprm : this.state.ParametreDate?.length > 0 ? this.state.ParametreDate[0].dateprm : null)), "Date")
        }


            stateVars.Confirm = await Swal.fire({
                title: ReactSystemFunctions.translateCustomActions(this.ml,"6563f69d_c0bb_98ab_3e39_771a7fa80e8c_confirmation",this.defaultML,"Bu saati kapatmak istediğinize emin misiniz?" ),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: localStorage.getItem("ml") === 'tr_TR' ? 'Onayla' : localStorage.getItem("ml") === 'fr_FR' ? 'Confirmer' : 'Confirm',
                cancelButtonText: localStorage.getItem("ml") === 'tr_TR' ? 'Vazgeç' : localStorage.getItem("ml") === 'fr_FR' ? 'Annuler' : 'Cancel',
                customClass: {
                  title: 'swal2-font-poppins',
                }
            })

            if (stateVars.Confirm.isConfirmed)
            {
                		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "MySchedule2/MySchedule2Component_636122_onClick_else0f11_", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.TakvimKapatByProfilTarihSaat = result?.data.takvimKapatByProfilTarihSaat;
			stateVars.MySchedule = result?.data.mySchedule;
			
			stateVars.isComp449989Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label1 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label1 : null)), null)) === true ? "visible" : "hidden")
			stateVars.isComp652058Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label2 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label2 : null)), null)) === true ? "visible" : "hidden")
			stateVars.isComp788765Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label3 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label3 : null)), null)) === true ? "visible" : "hidden")
			stateVars.isComp80866Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label4 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label4 : null)), null)) === true ? "visible" : "hidden")
			stateVars.isComp103613Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label5 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label5 : null)), null)) === true ? "visible" : "hidden")
			stateVars.isComp946807Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label6 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label6 : null)), null)) === true ? "visible" : "hidden")
			stateVars.isComp109198Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label7 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label7 : null)), null)) === true ? "visible" : "hidden")
			this.props.form.setFieldsValue(formVars);
			this.setState(stateVars);
			KuikaAppManager.hideSpinner(this);

            }


        this.setPageInitLoaded(true);
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }





    MySchedule2Component_636122_onClick_else0e1 = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				profileId_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].lastChosenProfileId : this.state.MyContext?.length > 0 ? this.state.MyContext[0].lastChosenProfileId : null)), "Guid"),
				date_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "myschedule2_29908_value", "value", "", "", "")), "Date"),
				dateIncrement_0: ReactSystemFunctions.convertToTypeByName(2, "number"),
				hour_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, "myschedule2_182442_value", "saat"), "string"),
				profilId_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].lastChosenProfileId : this.state.MyContext?.length > 0 ? this.state.MyContext[0].lastChosenProfileId : null)), "Guid"),
				date_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.ParametreDate?.length > 0 ? stateVars.ParametreDate[0].dateprm : this.state.ParametreDate?.length > 0 ? this.state.ParametreDate[0].dateprm : null)), "Date")
        }

		KuikaAppManager.showSpinner(this);
		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "MySchedule2/MySchedule2Component_636122_onClick_else0e1", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.TakvimKapatByProfilTarihSaat = result?.data.takvimKapatByProfilTarihSaat;
			stateVars.MySchedule = result?.data.mySchedule;
			
			stateVars.isComp449989Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label1 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label1 : null)), null)) === true ? "visible" : "hidden")
			stateVars.isComp652058Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label2 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label2 : null)), null)) === true ? "visible" : "hidden")
			stateVars.isComp788765Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label3 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label3 : null)), null)) === true ? "visible" : "hidden")
			stateVars.isComp80866Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label4 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label4 : null)), null)) === true ? "visible" : "hidden")
			stateVars.isComp103613Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label5 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label5 : null)), null)) === true ? "visible" : "hidden")
			stateVars.isComp946807Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label6 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label6 : null)), null)) === true ? "visible" : "hidden")
			stateVars.isComp109198Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label7 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label7 : null)), null)) === true ? "visible" : "hidden")
			this.props.form.setFieldsValue(formVars);
			this.setState(stateVars);
			KuikaAppManager.hideSpinner(this);



        this.setPageInitLoaded(true);
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }



		MySchedule2Component_636122_onClick_else01_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
if (ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, "myschedule2_182442_value", "color3"), "#FFF")) {
            isErrorOccurred = await this.MySchedule2Component_636122_onClick_else0f1();
                if (isErrorOccurred) return true;
        } else {
            isErrorOccurred = await this.MySchedule2Component_636122_onClick_else0e1();
                if (isErrorOccurred) return true;
        }


		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);

            
            return isErrorOccurred;
        }



		MySchedule2Component_636122_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.value(this, "myschedule2_182442_value", "label3"), null)) {
            isErrorOccurred = await this.MySchedule2Component_636122_onClick_if0();
                if (isErrorOccurred) return true;
        } else {
            isErrorOccurred = await this.MySchedule2Component_636122_onClick_else0();
                if (isErrorOccurred) return true;
        }
KuikaAppManager.showSpinner(this);


		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }


    MySchedule2Component_440558_onClick_if0 = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				profileId_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].lastChosenProfileId : this.state.MyContext?.length > 0 ? this.state.MyContext[0].lastChosenProfileId : null)), "Guid"),
				date_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "myschedule2_29908_value", "value", "", "", "")), "Date"),
				dateIncrement_0: ReactSystemFunctions.convertToTypeByName(3, "number"),
				hour_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, "myschedule2_182442_value", "saat"), "string")
        }

		KuikaAppManager.showSpinner(this);
		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "MySchedule2/MySchedule2Component_440558_onClick_if0", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.RandevuByProfilTarihSaat = result?.data.randevuByProfilTarihSaat;
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.MySchedule2Component_440558_onClick_if01_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
		MySchedule2Component_440558_onClick_if01_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }

					KuikaAppManager.prepareForNavigation();
						KuikaAppManager.addToPageInputVariables("RandevuSummary", "RandevuId", ReactSystemFunctions.value(this, (stateVars.RandevuByProfilTarihSaat?.length > 0 ? stateVars.RandevuByProfilTarihSaat[0].id : this.state.RandevuByProfilTarihSaat?.length > 0 ? this.state.RandevuByProfilTarihSaat[0].id : null)));

				stateVars.NAVIGATE = await ReactSystemFunctions.navigate(this, undefined, "MySchedule2", "RandevuSummary", "", "7d969ddf-5801-4eb6-b92b-12be35bdd565", "877132", null, "right", null, "380px", "100vh", true);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);

            
            return isErrorOccurred;
        }




		MySchedule2Component_440558_onClick_else0 = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
KuikaAppManager.showSpinner(this);


		this.props.form.setFieldsValue(formVars);
		
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.MySchedule2Component_440558_onClick_else01_();
                  resolve();
                });
          })

            
            return isErrorOccurred;
        }
    MySchedule2Component_440558_onClick_else0f1 = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
        }

		KuikaAppManager.showSpinner(this);

			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.MySchedule2Component_440558_onClick_else0f11_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
    MySchedule2Component_440558_onClick_else0f11_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				profileId_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].lastChosenProfileId : this.state.MyContext?.length > 0 ? this.state.MyContext[0].lastChosenProfileId : null)), "Guid"),
				date_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "myschedule2_29908_value", "value", "", "", "")), "Date"),
				dateIncrement_0: ReactSystemFunctions.convertToTypeByName(3, "number"),
				hour_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, "myschedule2_182442_value", "saat"), "string"),
				profilId_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].lastChosenProfileId : this.state.MyContext?.length > 0 ? this.state.MyContext[0].lastChosenProfileId : null)), "Guid"),
				date_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.ParametreDate?.length > 0 ? stateVars.ParametreDate[0].dateprm : this.state.ParametreDate?.length > 0 ? this.state.ParametreDate[0].dateprm : null)), "Date")
        }


            stateVars.Confirm = await Swal.fire({
                title: ReactSystemFunctions.translateCustomActions(this.ml,"6563f69d_c0bb_98ab_3e39_771a7fa80e8c_confirmation",this.defaultML,"Bu saati kapatmak istediğinize emin misiniz?" ),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: localStorage.getItem("ml") === 'tr_TR' ? 'Onayla' : localStorage.getItem("ml") === 'fr_FR' ? 'Confirmer' : 'Confirm',
                cancelButtonText: localStorage.getItem("ml") === 'tr_TR' ? 'Vazgeç' : localStorage.getItem("ml") === 'fr_FR' ? 'Annuler' : 'Cancel',
                customClass: {
                  title: 'swal2-font-poppins',
                }
            })

            if (stateVars.Confirm.isConfirmed)
            {
                		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "MySchedule2/MySchedule2Component_440558_onClick_else0f11_", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.TakvimKapatByProfilTarihSaat = result?.data.takvimKapatByProfilTarihSaat;
			stateVars.MySchedule = result?.data.mySchedule;
			
			stateVars.isComp449989Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label1 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label1 : null)), null)) === true ? "visible" : "hidden")
			stateVars.isComp652058Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label2 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label2 : null)), null)) === true ? "visible" : "hidden")
			stateVars.isComp788765Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label3 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label3 : null)), null)) === true ? "visible" : "hidden")
			stateVars.isComp80866Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label4 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label4 : null)), null)) === true ? "visible" : "hidden")
			stateVars.isComp103613Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label5 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label5 : null)), null)) === true ? "visible" : "hidden")
			stateVars.isComp946807Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label6 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label6 : null)), null)) === true ? "visible" : "hidden")
			stateVars.isComp109198Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label7 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label7 : null)), null)) === true ? "visible" : "hidden")
			this.props.form.setFieldsValue(formVars);
			this.setState(stateVars);
			KuikaAppManager.hideSpinner(this);

            }


        this.setPageInitLoaded(true);
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }





    MySchedule2Component_440558_onClick_else0e1 = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				profileId_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].lastChosenProfileId : this.state.MyContext?.length > 0 ? this.state.MyContext[0].lastChosenProfileId : null)), "Guid"),
				date_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "myschedule2_29908_value", "value", "", "", "")), "Date"),
				dateIncrement_0: ReactSystemFunctions.convertToTypeByName(3, "number"),
				hour_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, "myschedule2_182442_value", "saat"), "string"),
				profilId_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].lastChosenProfileId : this.state.MyContext?.length > 0 ? this.state.MyContext[0].lastChosenProfileId : null)), "Guid"),
				date_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.ParametreDate?.length > 0 ? stateVars.ParametreDate[0].dateprm : this.state.ParametreDate?.length > 0 ? this.state.ParametreDate[0].dateprm : null)), "Date")
        }

		KuikaAppManager.showSpinner(this);
		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "MySchedule2/MySchedule2Component_440558_onClick_else0e1", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.TakvimKapatByProfilTarihSaat = result?.data.takvimKapatByProfilTarihSaat;
			stateVars.MySchedule = result?.data.mySchedule;
			
			stateVars.isComp449989Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label1 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label1 : null)), null)) === true ? "visible" : "hidden")
			stateVars.isComp652058Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label2 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label2 : null)), null)) === true ? "visible" : "hidden")
			stateVars.isComp788765Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label3 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label3 : null)), null)) === true ? "visible" : "hidden")
			stateVars.isComp80866Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label4 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label4 : null)), null)) === true ? "visible" : "hidden")
			stateVars.isComp103613Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label5 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label5 : null)), null)) === true ? "visible" : "hidden")
			stateVars.isComp946807Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label6 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label6 : null)), null)) === true ? "visible" : "hidden")
			stateVars.isComp109198Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label7 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label7 : null)), null)) === true ? "visible" : "hidden")
			this.props.form.setFieldsValue(formVars);
			this.setState(stateVars);
			KuikaAppManager.hideSpinner(this);



        this.setPageInitLoaded(true);
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }



		MySchedule2Component_440558_onClick_else01_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
if (ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, "myschedule2_182442_value", "color4"), "#FFF")) {
            isErrorOccurred = await this.MySchedule2Component_440558_onClick_else0f1();
                if (isErrorOccurred) return true;
        } else {
            isErrorOccurred = await this.MySchedule2Component_440558_onClick_else0e1();
                if (isErrorOccurred) return true;
        }


		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);

            
            return isErrorOccurred;
        }



		MySchedule2Component_440558_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.value(this, "myschedule2_182442_value", "label4"), null)) {
            isErrorOccurred = await this.MySchedule2Component_440558_onClick_if0();
                if (isErrorOccurred) return true;
        } else {
            isErrorOccurred = await this.MySchedule2Component_440558_onClick_else0();
                if (isErrorOccurred) return true;
        }
KuikaAppManager.showSpinner(this);


		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }


    MySchedule2Component_469550_onClick_if0 = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				profileId_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].lastChosenProfileId : this.state.MyContext?.length > 0 ? this.state.MyContext[0].lastChosenProfileId : null)), "Guid"),
				date_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "myschedule2_29908_value", "value", "", "", "")), "Date"),
				dateIncrement_0: ReactSystemFunctions.convertToTypeByName(4, "number"),
				hour_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, "myschedule2_182442_value", "saat"), "string")
        }

		KuikaAppManager.showSpinner(this);
		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "MySchedule2/MySchedule2Component_469550_onClick_if0", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.RandevuByProfilTarihSaat = result?.data.randevuByProfilTarihSaat;
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.MySchedule2Component_469550_onClick_if01_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
		MySchedule2Component_469550_onClick_if01_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }

					KuikaAppManager.prepareForNavigation();
						KuikaAppManager.addToPageInputVariables("RandevuSummary", "RandevuId", ReactSystemFunctions.value(this, (stateVars.RandevuByProfilTarihSaat?.length > 0 ? stateVars.RandevuByProfilTarihSaat[0].id : this.state.RandevuByProfilTarihSaat?.length > 0 ? this.state.RandevuByProfilTarihSaat[0].id : null)));

				stateVars.NAVIGATE = await ReactSystemFunctions.navigate(this, undefined, "MySchedule2", "RandevuSummary", "", "7d969ddf-5801-4eb6-b92b-12be35bdd565", "877132", null, "right", null, "380px", "100vh", true);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);

            
            return isErrorOccurred;
        }




		MySchedule2Component_469550_onClick_else0 = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
KuikaAppManager.showSpinner(this);


		this.props.form.setFieldsValue(formVars);
		
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.MySchedule2Component_469550_onClick_else01_();
                  resolve();
                });
          })

            
            return isErrorOccurred;
        }
    MySchedule2Component_469550_onClick_else0f1 = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
        }

		KuikaAppManager.showSpinner(this);

			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.MySchedule2Component_469550_onClick_else0f11_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
    MySchedule2Component_469550_onClick_else0f11_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				profileId_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].lastChosenProfileId : this.state.MyContext?.length > 0 ? this.state.MyContext[0].lastChosenProfileId : null)), "Guid"),
				date_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "myschedule2_29908_value", "value", "", "", "")), "Date"),
				dateIncrement_0: ReactSystemFunctions.convertToTypeByName(4, "number"),
				hour_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, "myschedule2_182442_value", "saat"), "string"),
				profilId_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].lastChosenProfileId : this.state.MyContext?.length > 0 ? this.state.MyContext[0].lastChosenProfileId : null)), "Guid"),
				date_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.ParametreDate?.length > 0 ? stateVars.ParametreDate[0].dateprm : this.state.ParametreDate?.length > 0 ? this.state.ParametreDate[0].dateprm : null)), "Date")
        }


            stateVars.Confirm = await Swal.fire({
                title: ReactSystemFunctions.translateCustomActions(this.ml,"6563f69d_c0bb_98ab_3e39_771a7fa80e8c_confirmation",this.defaultML,"Bu saati kapatmak istediğinize emin misiniz?" ),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: localStorage.getItem("ml") === 'tr_TR' ? 'Onayla' : localStorage.getItem("ml") === 'fr_FR' ? 'Confirmer' : 'Confirm',
                cancelButtonText: localStorage.getItem("ml") === 'tr_TR' ? 'Vazgeç' : localStorage.getItem("ml") === 'fr_FR' ? 'Annuler' : 'Cancel',
                customClass: {
                  title: 'swal2-font-poppins',
                }
            })

            if (stateVars.Confirm.isConfirmed)
            {
                		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "MySchedule2/MySchedule2Component_469550_onClick_else0f11_", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.TakvimKapatByProfilTarihSaat = result?.data.takvimKapatByProfilTarihSaat;
			stateVars.MySchedule = result?.data.mySchedule;
			
			stateVars.isComp449989Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label1 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label1 : null)), null)) === true ? "visible" : "hidden")
			stateVars.isComp652058Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label2 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label2 : null)), null)) === true ? "visible" : "hidden")
			stateVars.isComp788765Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label3 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label3 : null)), null)) === true ? "visible" : "hidden")
			stateVars.isComp80866Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label4 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label4 : null)), null)) === true ? "visible" : "hidden")
			stateVars.isComp103613Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label5 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label5 : null)), null)) === true ? "visible" : "hidden")
			stateVars.isComp946807Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label6 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label6 : null)), null)) === true ? "visible" : "hidden")
			stateVars.isComp109198Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label7 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label7 : null)), null)) === true ? "visible" : "hidden")
			this.props.form.setFieldsValue(formVars);
			this.setState(stateVars);
			KuikaAppManager.hideSpinner(this);

            }


        this.setPageInitLoaded(true);
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }





    MySchedule2Component_469550_onClick_else0e1 = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				profileId_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].lastChosenProfileId : this.state.MyContext?.length > 0 ? this.state.MyContext[0].lastChosenProfileId : null)), "Guid"),
				date_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "myschedule2_29908_value", "value", "", "", "")), "Date"),
				dateIncrement_0: ReactSystemFunctions.convertToTypeByName(4, "number"),
				hour_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, "myschedule2_182442_value", "saat"), "string"),
				profilId_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].lastChosenProfileId : this.state.MyContext?.length > 0 ? this.state.MyContext[0].lastChosenProfileId : null)), "Guid"),
				date_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.ParametreDate?.length > 0 ? stateVars.ParametreDate[0].dateprm : this.state.ParametreDate?.length > 0 ? this.state.ParametreDate[0].dateprm : null)), "Date")
        }

		KuikaAppManager.showSpinner(this);
		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "MySchedule2/MySchedule2Component_469550_onClick_else0e1", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.TakvimKapatByProfilTarihSaat = result?.data.takvimKapatByProfilTarihSaat;
			stateVars.MySchedule = result?.data.mySchedule;
			
			stateVars.isComp449989Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label1 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label1 : null)), null)) === true ? "visible" : "hidden")
			stateVars.isComp652058Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label2 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label2 : null)), null)) === true ? "visible" : "hidden")
			stateVars.isComp788765Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label3 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label3 : null)), null)) === true ? "visible" : "hidden")
			stateVars.isComp80866Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label4 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label4 : null)), null)) === true ? "visible" : "hidden")
			stateVars.isComp103613Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label5 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label5 : null)), null)) === true ? "visible" : "hidden")
			stateVars.isComp946807Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label6 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label6 : null)), null)) === true ? "visible" : "hidden")
			stateVars.isComp109198Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label7 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label7 : null)), null)) === true ? "visible" : "hidden")
			this.props.form.setFieldsValue(formVars);
			this.setState(stateVars);
			KuikaAppManager.hideSpinner(this);



        this.setPageInitLoaded(true);
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }



		MySchedule2Component_469550_onClick_else01_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
if (ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, "myschedule2_182442_value", "color5"), "#FFF")) {
            isErrorOccurred = await this.MySchedule2Component_469550_onClick_else0f1();
                if (isErrorOccurred) return true;
        } else {
            isErrorOccurred = await this.MySchedule2Component_469550_onClick_else0e1();
                if (isErrorOccurred) return true;
        }


		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);

            
            return isErrorOccurred;
        }



		MySchedule2Component_469550_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.value(this, "myschedule2_182442_value", "label4"), null)) {
            isErrorOccurred = await this.MySchedule2Component_469550_onClick_if0();
                if (isErrorOccurred) return true;
        } else {
            isErrorOccurred = await this.MySchedule2Component_469550_onClick_else0();
                if (isErrorOccurred) return true;
        }
KuikaAppManager.showSpinner(this);


		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }


    MySchedule2Component_586943_onClick_if0 = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				profileId_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].lastChosenProfileId : this.state.MyContext?.length > 0 ? this.state.MyContext[0].lastChosenProfileId : null)), "Guid"),
				date_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "myschedule2_29908_value", "value", "", "", "")), "Date"),
				dateIncrement_0: ReactSystemFunctions.convertToTypeByName(5, "number"),
				hour_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, "myschedule2_182442_value", "saat"), "string")
        }

		KuikaAppManager.showSpinner(this);
		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "MySchedule2/MySchedule2Component_586943_onClick_if0", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.RandevuByProfilTarihSaat = result?.data.randevuByProfilTarihSaat;
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.MySchedule2Component_586943_onClick_if01_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
		MySchedule2Component_586943_onClick_if01_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }

					KuikaAppManager.prepareForNavigation();
						KuikaAppManager.addToPageInputVariables("RandevuSummary", "RandevuId", ReactSystemFunctions.value(this, (stateVars.RandevuByProfilTarihSaat?.length > 0 ? stateVars.RandevuByProfilTarihSaat[0].id : this.state.RandevuByProfilTarihSaat?.length > 0 ? this.state.RandevuByProfilTarihSaat[0].id : null)));

				stateVars.NAVIGATE = await ReactSystemFunctions.navigate(this, undefined, "MySchedule2", "RandevuSummary", "", "7d969ddf-5801-4eb6-b92b-12be35bdd565", "877132", null, "right", null, "380px", "100vh", true);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);

            
            return isErrorOccurred;
        }




		MySchedule2Component_586943_onClick_else0 = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
KuikaAppManager.showSpinner(this);


		this.props.form.setFieldsValue(formVars);
		
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.MySchedule2Component_586943_onClick_else01_();
                  resolve();
                });
          })

            
            return isErrorOccurred;
        }
    MySchedule2Component_586943_onClick_else0f1 = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
        }

		KuikaAppManager.showSpinner(this);

			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.MySchedule2Component_586943_onClick_else0f11_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
    MySchedule2Component_586943_onClick_else0f11_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				profileId_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].lastChosenProfileId : this.state.MyContext?.length > 0 ? this.state.MyContext[0].lastChosenProfileId : null)), "Guid"),
				date_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "myschedule2_29908_value", "value", "", "", "")), "Date"),
				dateIncrement_0: ReactSystemFunctions.convertToTypeByName(5, "number"),
				hour_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, "myschedule2_182442_value", "saat"), "string"),
				profilId_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].lastChosenProfileId : this.state.MyContext?.length > 0 ? this.state.MyContext[0].lastChosenProfileId : null)), "Guid"),
				date_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.ParametreDate?.length > 0 ? stateVars.ParametreDate[0].dateprm : this.state.ParametreDate?.length > 0 ? this.state.ParametreDate[0].dateprm : null)), "Date")
        }


            stateVars.Confirm = await Swal.fire({
                title: ReactSystemFunctions.translateCustomActions(this.ml,"6563f69d_c0bb_98ab_3e39_771a7fa80e8c_confirmation",this.defaultML,"Bu saati kapatmak istediğinize emin misiniz?" ),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: localStorage.getItem("ml") === 'tr_TR' ? 'Onayla' : localStorage.getItem("ml") === 'fr_FR' ? 'Confirmer' : 'Confirm',
                cancelButtonText: localStorage.getItem("ml") === 'tr_TR' ? 'Vazgeç' : localStorage.getItem("ml") === 'fr_FR' ? 'Annuler' : 'Cancel',
                customClass: {
                  title: 'swal2-font-poppins',
                }
            })

            if (stateVars.Confirm.isConfirmed)
            {
                		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "MySchedule2/MySchedule2Component_586943_onClick_else0f11_", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.TakvimKapatByProfilTarihSaat = result?.data.takvimKapatByProfilTarihSaat;
			stateVars.MySchedule = result?.data.mySchedule;
			
			stateVars.isComp449989Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label1 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label1 : null)), null)) === true ? "visible" : "hidden")
			stateVars.isComp652058Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label2 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label2 : null)), null)) === true ? "visible" : "hidden")
			stateVars.isComp788765Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label3 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label3 : null)), null)) === true ? "visible" : "hidden")
			stateVars.isComp80866Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label4 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label4 : null)), null)) === true ? "visible" : "hidden")
			stateVars.isComp103613Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label5 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label5 : null)), null)) === true ? "visible" : "hidden")
			stateVars.isComp946807Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label6 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label6 : null)), null)) === true ? "visible" : "hidden")
			stateVars.isComp109198Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label7 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label7 : null)), null)) === true ? "visible" : "hidden")
			this.props.form.setFieldsValue(formVars);
			this.setState(stateVars);
			KuikaAppManager.hideSpinner(this);

            }


        this.setPageInitLoaded(true);
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }





    MySchedule2Component_586943_onClick_else0e1 = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				profileId_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].lastChosenProfileId : this.state.MyContext?.length > 0 ? this.state.MyContext[0].lastChosenProfileId : null)), "Guid"),
				date_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "myschedule2_29908_value", "value", "", "", "")), "Date"),
				dateIncrement_0: ReactSystemFunctions.convertToTypeByName(5, "number"),
				hour_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, "myschedule2_182442_value", "saat"), "string"),
				profilId_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].lastChosenProfileId : this.state.MyContext?.length > 0 ? this.state.MyContext[0].lastChosenProfileId : null)), "Guid"),
				date_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.ParametreDate?.length > 0 ? stateVars.ParametreDate[0].dateprm : this.state.ParametreDate?.length > 0 ? this.state.ParametreDate[0].dateprm : null)), "Date")
        }

		KuikaAppManager.showSpinner(this);
		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "MySchedule2/MySchedule2Component_586943_onClick_else0e1", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.TakvimKapatByProfilTarihSaat = result?.data.takvimKapatByProfilTarihSaat;
			stateVars.MySchedule = result?.data.mySchedule;
			
			stateVars.isComp449989Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label1 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label1 : null)), null)) === true ? "visible" : "hidden")
			stateVars.isComp652058Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label2 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label2 : null)), null)) === true ? "visible" : "hidden")
			stateVars.isComp788765Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label3 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label3 : null)), null)) === true ? "visible" : "hidden")
			stateVars.isComp80866Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label4 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label4 : null)), null)) === true ? "visible" : "hidden")
			stateVars.isComp103613Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label5 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label5 : null)), null)) === true ? "visible" : "hidden")
			stateVars.isComp946807Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label6 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label6 : null)), null)) === true ? "visible" : "hidden")
			stateVars.isComp109198Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label7 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label7 : null)), null)) === true ? "visible" : "hidden")
			this.props.form.setFieldsValue(formVars);
			this.setState(stateVars);
			KuikaAppManager.hideSpinner(this);



        this.setPageInitLoaded(true);
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }



		MySchedule2Component_586943_onClick_else01_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
if (ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, "myschedule2_182442_value", "color6"), "#FFF")) {
            isErrorOccurred = await this.MySchedule2Component_586943_onClick_else0f1();
                if (isErrorOccurred) return true;
        } else {
            isErrorOccurred = await this.MySchedule2Component_586943_onClick_else0e1();
                if (isErrorOccurred) return true;
        }


		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);

            
            return isErrorOccurred;
        }



		MySchedule2Component_586943_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.value(this, "myschedule2_182442_value", "label5"), null)) {
            isErrorOccurred = await this.MySchedule2Component_586943_onClick_if0();
                if (isErrorOccurred) return true;
        } else {
            isErrorOccurred = await this.MySchedule2Component_586943_onClick_else0();
                if (isErrorOccurred) return true;
        }
KuikaAppManager.showSpinner(this);


		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }


    MySchedule2Component_784387_onClick_if0 = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				profileId_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].lastChosenProfileId : this.state.MyContext?.length > 0 ? this.state.MyContext[0].lastChosenProfileId : null)), "Guid"),
				date_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "myschedule2_29908_value", "value", "", "", "")), "Date"),
				dateIncrement_0: ReactSystemFunctions.convertToTypeByName(6, "number"),
				hour_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, "myschedule2_182442_value", "saat"), "string")
        }

		KuikaAppManager.showSpinner(this);
		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "MySchedule2/MySchedule2Component_784387_onClick_if0", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.RandevuByProfilTarihSaat = result?.data.randevuByProfilTarihSaat;
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.MySchedule2Component_784387_onClick_if01_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
		MySchedule2Component_784387_onClick_if01_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }

					KuikaAppManager.prepareForNavigation();
						KuikaAppManager.addToPageInputVariables("RandevuSummary", "RandevuId", ReactSystemFunctions.value(this, (stateVars.RandevuByProfilTarihSaat?.length > 0 ? stateVars.RandevuByProfilTarihSaat[0].id : this.state.RandevuByProfilTarihSaat?.length > 0 ? this.state.RandevuByProfilTarihSaat[0].id : null)));

				stateVars.NAVIGATE = await ReactSystemFunctions.navigate(this, undefined, "MySchedule2", "RandevuSummary", "", "7d969ddf-5801-4eb6-b92b-12be35bdd565", "877132", null, "right", null, "380px", "100vh", true);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);

            
            return isErrorOccurred;
        }




		MySchedule2Component_784387_onClick_else0 = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
KuikaAppManager.showSpinner(this);


		this.props.form.setFieldsValue(formVars);
		
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.MySchedule2Component_784387_onClick_else01_();
                  resolve();
                });
          })

            
            return isErrorOccurred;
        }
    MySchedule2Component_784387_onClick_else0f1 = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
        }

		KuikaAppManager.showSpinner(this);

			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.MySchedule2Component_784387_onClick_else0f11_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
    MySchedule2Component_784387_onClick_else0f11_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				profileId_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].lastChosenProfileId : this.state.MyContext?.length > 0 ? this.state.MyContext[0].lastChosenProfileId : null)), "Guid"),
				date_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "myschedule2_29908_value", "value", "", "", "")), "Date"),
				dateIncrement_0: ReactSystemFunctions.convertToTypeByName(6, "number"),
				hour_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, "myschedule2_182442_value", "saat"), "string"),
				profilId_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].lastChosenProfileId : this.state.MyContext?.length > 0 ? this.state.MyContext[0].lastChosenProfileId : null)), "Guid"),
				date_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.ParametreDate?.length > 0 ? stateVars.ParametreDate[0].dateprm : this.state.ParametreDate?.length > 0 ? this.state.ParametreDate[0].dateprm : null)), "Date")
        }


            stateVars.Confirm = await Swal.fire({
                title: ReactSystemFunctions.translateCustomActions(this.ml,"6563f69d_c0bb_98ab_3e39_771a7fa80e8c_confirmation",this.defaultML,"Bu saati kapatmak istediğinize emin misiniz?" ),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: localStorage.getItem("ml") === 'tr_TR' ? 'Onayla' : localStorage.getItem("ml") === 'fr_FR' ? 'Confirmer' : 'Confirm',
                cancelButtonText: localStorage.getItem("ml") === 'tr_TR' ? 'Vazgeç' : localStorage.getItem("ml") === 'fr_FR' ? 'Annuler' : 'Cancel',
                customClass: {
                  title: 'swal2-font-poppins',
                }
            })

            if (stateVars.Confirm.isConfirmed)
            {
                		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "MySchedule2/MySchedule2Component_784387_onClick_else0f11_", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.TakvimKapatByProfilTarihSaat = result?.data.takvimKapatByProfilTarihSaat;
			stateVars.MySchedule = result?.data.mySchedule;
			
			stateVars.isComp449989Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label1 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label1 : null)), null)) === true ? "visible" : "hidden")
			stateVars.isComp652058Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label2 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label2 : null)), null)) === true ? "visible" : "hidden")
			stateVars.isComp788765Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label3 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label3 : null)), null)) === true ? "visible" : "hidden")
			stateVars.isComp80866Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label4 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label4 : null)), null)) === true ? "visible" : "hidden")
			stateVars.isComp103613Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label5 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label5 : null)), null)) === true ? "visible" : "hidden")
			stateVars.isComp946807Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label6 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label6 : null)), null)) === true ? "visible" : "hidden")
			stateVars.isComp109198Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label7 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label7 : null)), null)) === true ? "visible" : "hidden")
			this.props.form.setFieldsValue(formVars);
			this.setState(stateVars);
			KuikaAppManager.hideSpinner(this);

            }


        this.setPageInitLoaded(true);
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }





    MySchedule2Component_784387_onClick_else0e1 = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				profileId_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].lastChosenProfileId : this.state.MyContext?.length > 0 ? this.state.MyContext[0].lastChosenProfileId : null)), "Guid"),
				date_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "myschedule2_29908_value", "value", "", "", "")), "Date"),
				dateIncrement_0: ReactSystemFunctions.convertToTypeByName(6, "number"),
				hour_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, "myschedule2_182442_value", "saat"), "string"),
				profilId_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].lastChosenProfileId : this.state.MyContext?.length > 0 ? this.state.MyContext[0].lastChosenProfileId : null)), "Guid"),
				date_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.ParametreDate?.length > 0 ? stateVars.ParametreDate[0].dateprm : this.state.ParametreDate?.length > 0 ? this.state.ParametreDate[0].dateprm : null)), "Date")
        }

		KuikaAppManager.showSpinner(this);
		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "MySchedule2/MySchedule2Component_784387_onClick_else0e1", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.TakvimKapatByProfilTarihSaat = result?.data.takvimKapatByProfilTarihSaat;
			stateVars.MySchedule = result?.data.mySchedule;
			
			stateVars.isComp449989Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label1 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label1 : null)), null)) === true ? "visible" : "hidden")
			stateVars.isComp652058Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label2 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label2 : null)), null)) === true ? "visible" : "hidden")
			stateVars.isComp788765Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label3 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label3 : null)), null)) === true ? "visible" : "hidden")
			stateVars.isComp80866Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label4 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label4 : null)), null)) === true ? "visible" : "hidden")
			stateVars.isComp103613Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label5 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label5 : null)), null)) === true ? "visible" : "hidden")
			stateVars.isComp946807Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label6 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label6 : null)), null)) === true ? "visible" : "hidden")
			stateVars.isComp109198Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label7 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label7 : null)), null)) === true ? "visible" : "hidden")
			this.props.form.setFieldsValue(formVars);
			this.setState(stateVars);
			KuikaAppManager.hideSpinner(this);



        this.setPageInitLoaded(true);
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }



		MySchedule2Component_784387_onClick_else01_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
if (ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, "myschedule2_182442_value", "color7"), "#FFF")) {
            isErrorOccurred = await this.MySchedule2Component_784387_onClick_else0f1();
                if (isErrorOccurred) return true;
        } else {
            isErrorOccurred = await this.MySchedule2Component_784387_onClick_else0e1();
                if (isErrorOccurred) return true;
        }


		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);

            
            return isErrorOccurred;
        }



		MySchedule2Component_784387_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.value(this, "myschedule2_182442_value", "label6"), null)) {
            isErrorOccurred = await this.MySchedule2Component_784387_onClick_if0();
                if (isErrorOccurred) return true;
        } else {
            isErrorOccurred = await this.MySchedule2Component_784387_onClick_else0();
                if (isErrorOccurred) return true;
        }
KuikaAppManager.showSpinner(this);


		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }


    MySchedule2Component_121787_onClick_if0 = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				profileId_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].lastChosenProfileId : this.state.MyContext?.length > 0 ? this.state.MyContext[0].lastChosenProfileId : null)), "Guid"),
				date_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "myschedule2_29908_value", "value", "", "", "")), "Date"),
				dateIncrement_0: ReactSystemFunctions.convertToTypeByName(0, "number"),
				hour_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, "myschedule2_182442_value", "saat"), "string")
        }

		KuikaAppManager.showSpinner(this);
		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "MySchedule2/MySchedule2Component_121787_onClick_if0", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.RandevuByProfilTarihSaat = result?.data.randevuByProfilTarihSaat;
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.MySchedule2Component_121787_onClick_if01_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
		MySchedule2Component_121787_onClick_if01_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }

					KuikaAppManager.prepareForNavigation();
						KuikaAppManager.addToPageInputVariables("RandevuSummary", "RandevuId", ReactSystemFunctions.value(this, (stateVars.RandevuByProfilTarihSaat?.length > 0 ? stateVars.RandevuByProfilTarihSaat[0].id : this.state.RandevuByProfilTarihSaat?.length > 0 ? this.state.RandevuByProfilTarihSaat[0].id : null)));

				stateVars.NAVIGATE = await ReactSystemFunctions.navigate(this, undefined, "MySchedule2", "RandevuSummary", "", "7d969ddf-5801-4eb6-b92b-12be35bdd565", "877132", null, "right", null, "380px", "100vh", true);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);

            
            return isErrorOccurred;
        }




    MySchedule2Component_121787_onClick_else0 = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
        }

		KuikaAppManager.showSpinner(this);

			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.MySchedule2Component_121787_onClick_else01_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
    MySchedule2Component_121787_onClick_else01_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				profileId_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].lastChosenProfileId : this.state.MyContext?.length > 0 ? this.state.MyContext[0].lastChosenProfileId : null)), "Guid"),
				date_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "myschedule2_29908_value", "value", "", "", "")), "Date"),
				dateIncrement_0: ReactSystemFunctions.convertToTypeByName(0, "number"),
				hour_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, "myschedule2_182442_value", "saat"), "string"),
				profilId_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].lastChosenProfileId : this.state.MyContext?.length > 0 ? this.state.MyContext[0].lastChosenProfileId : null)), "Guid"),
				date_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.ParametreDate?.length > 0 ? stateVars.ParametreDate[0].dateprm : this.state.ParametreDate?.length > 0 ? this.state.ParametreDate[0].dateprm : null)), "Date")
        }


            stateVars.Confirm = await Swal.fire({
                title: ReactSystemFunctions.translateCustomActions(this.ml,"844c48f4_3c68_33fc_889a_d2ed2d448ac3_confirmation",this.defaultML,"Bu saati kapatmak istediğinize emin misiniz?ilk kolon tkvimkapat" ),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: localStorage.getItem("ml") === 'tr_TR' ? 'Onayla' : localStorage.getItem("ml") === 'fr_FR' ? 'Confirmer' : 'Confirm',
                cancelButtonText: localStorage.getItem("ml") === 'tr_TR' ? 'Vazgeç' : localStorage.getItem("ml") === 'fr_FR' ? 'Annuler' : 'Cancel',
                customClass: {
                  title: 'swal2-font-poppins',
                }
            })

            if (stateVars.Confirm.isConfirmed)
            {
                		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "MySchedule2/MySchedule2Component_121787_onClick_else01_", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.TakvimKapatByProfilTarihSaat = result?.data.takvimKapatByProfilTarihSaat;
			stateVars.MySchedule = result?.data.mySchedule;
			
			stateVars.isComp449989Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label1 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label1 : null)), null)) === true ? "visible" : "hidden")
			stateVars.isComp652058Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label2 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label2 : null)), null)) === true ? "visible" : "hidden")
			stateVars.isComp788765Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label3 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label3 : null)), null)) === true ? "visible" : "hidden")
			stateVars.isComp80866Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label4 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label4 : null)), null)) === true ? "visible" : "hidden")
			stateVars.isComp103613Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label5 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label5 : null)), null)) === true ? "visible" : "hidden")
			stateVars.isComp946807Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label6 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label6 : null)), null)) === true ? "visible" : "hidden")
			stateVars.isComp109198Visible = ((ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, (stateVars.MySchedule?.length > 0 ? stateVars.MySchedule[0].label7 : this.state.MySchedule?.length > 0 ? this.state.MySchedule[0].label7 : null)), null)) === true ? "visible" : "hidden")
			this.props.form.setFieldsValue(formVars);
			this.setState(stateVars);
			KuikaAppManager.hideSpinner(this);

            }


        this.setPageInitLoaded(true);
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }





		MySchedule2Component_121787_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.value(this, "myschedule2_182442_value", "label1"), null)) {
            isErrorOccurred = await this.MySchedule2Component_121787_onClick_if0();
                if (isErrorOccurred) return true;
        } else {
            isErrorOccurred = await this.MySchedule2Component_121787_onClick_else0();
                if (isErrorOccurred) return true;
        }
KuikaAppManager.showSpinner(this);


		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }




    async callCallbackFunction(diId) {
        let isErrorOccurred = false;
		let pageInitCallerNavDiIDs = [877132, 877132, 877132, 877132, 877132, 877132, 877132, 877132] as number[];
		if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
			await this.MySchedule2PageInit();
		}

    }
}
