import * as React from "react";
import { UserService } from "../services/user-service";
import { KNavigator, withHistory } from "../shared/hoc/with-history";
import { KMainFunctions } from "../shared/utilty/main-functions";
import LocalStorageService from "./local-storage-service";

export interface IRedirectProps {
  navigator: KNavigator;
}

declare let window: any;

class Redirect extends React.PureComponent<IRedirectProps> {
  async componentDidMount() {
    // Import anonymous starting screen here, if required
    try {
      if (LocalStorageService.getRefreshToken() === null) {
        this.props.navigator.gotoLogin();
        return;
      }
      if (window.userInfo === undefined) {
        await UserService.GetUserInfo();
      }
      this.props.navigator.gotoStartingScreen();
    } catch (error: any) {
      KMainFunctions.exceptionHandler(error);
    }
  }

  render() {
    return <></>;
  }
}

const redirect = withHistory(Redirect);
export { redirect as Redirect };
