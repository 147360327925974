import withCommonEvents from "../../../shared/hoc/with-common-events";
import { ProgressBase, ProgressBaseProps } from "./base/progress-base";

class ProgressCircle extends ProgressBase<any, any> {
  constructor(props: ProgressBaseProps) {
    super(props);
  }

  public static defaultProps = {
    type: "circle"
  };
}

const progressCircle = withCommonEvents(ProgressCircle);
export { progressCircle as ProgressCircle };
