import withCommonEvents from "../../../shared/hoc/with-common-events";
import { IGoogleMapsValue } from "../kuika-cl-model-runtimes";
import { BaseTable, ITableProps } from "./base-table";

export interface GoogleMapsProps extends ITableProps {
  value?: IGoogleMapsValue;
  onChange?: (value: any) => void;
}

class GoogleMaps extends BaseTable<GoogleMapsProps, any> {
  constructor(props: ITableProps & GoogleMapsProps) {
    super(props);
  }

  public static defaultProps = {
    mode: "google-maps",
    showHeader: false,
    designClicked: true
  };
}

const googleMaps = withCommonEvents(GoogleMaps);
export { googleMaps as GoogleMaps };
