import { Space } from "antd/es";
import { SpaceProps } from "antd/es/space";
import { PureComponent, ReactNode } from "react";

export interface FlexGridBodyWrapperProps extends SpaceProps {
  style?: any;
}

interface FlexGridBodyWrapperState {}

export class FlexGridBodyWrapper extends PureComponent<FlexGridBodyWrapperProps, FlexGridBodyWrapperState> {
  constructor(props: FlexGridBodyWrapperProps) {
    super(props);
    this.state = {};
  }

  render(): ReactNode {
    return (
      <>
        <Space {...this.props} wrap />
      </>
    );
  }
}
