import _ from "lodash";
import { PureComponent, ReactNode } from "react";
import ReactPlayer, { ReactPlayerProps } from "react-player";
import withCommonEvents from "../../../shared/hoc/with-common-events";
import { CommonProps } from "../common/common-props";

declare let window: any;
interface VideoProps extends ReactPlayerProps {
  onClick?: () => void;
}

interface VideoState {}

class Video extends PureComponent<VideoProps & CommonProps, VideoState> {
  constructor(props: VideoProps) {
    super(props);
    this.state = {};
  }

  handleClick = () => {
    if (this.props.onClick) {
      this.props.onClick();
    }
  };

  getProps = () => {
    const props: VideoProps = _.cloneDeep(this.props);
    const style: any = _.cloneDeep(this.props.style);
    const isVimeo = typeof props.url === "string" && props.url?.includes("vimeo");
    if (style.width) {
      props.width = Number(style.width.toString().replace("px", ""));
      delete style.width;
    }
    if (style.height) {
      props.height = Number(style.height.toString().replace("px", ""));
      delete style.height;
    }
    if (isVimeo) {
      props.controls = true;
    }
    style.display = "block";
    props.style = style;
    return props;
  };

  getMockStyle = () => {
    let style: any = {};
    if (this.props.style) {
      style = _.clone(this.props.style);
    }
    style.width = this.props.style?.width
      ? this.props.style?.width === "100px"
        ? "100%"
        : this.props.style.width
      : "100%";
    style.height = this.props.style?.height ?? "200px";
    return style;
  };

  render(): ReactNode {
    const isDesignTime = window.kuika?.isDesignTime;
    if (isDesignTime === true) {
      return (
        <div
          style={{
            paddingLeft: `${this.props.style?.paddingLeft}px`,
            paddingRight: `${this.props.style?.paddingRight}px`,
            paddingTop: `${this.props.style?.paddingTop}px`,
            paddingBottom: `${this.props.style?.paddingBottom}px`
          }}
        >
          <div style={{ ...this.getMockStyle() }} className="videoMock"></div>
        </div>
      );
    }
    return (
      <>
        <ReactPlayer onClickPreview={this.handleClick} {...this.getProps()} />
      </>
    );
  }
}

const video = withCommonEvents(Video);
export { video as Video };
