import _ from "lodash";
import React, { PureComponent, ReactNode } from "react";
import withCommonEvents from "../../../shared/hoc/with-common-events";
import { CommonProps } from "../common-props";

interface CountUpProps {
  style?: React.CSSProperties;
  value?: number;
  onChange?: (value: number) => void;
}

interface CountUpState {
  value: number;
  hours: number;
  minutes: number;
  seconds: number;
}

class CountUp extends PureComponent<CountUpProps & CommonProps, CountUpState> {
  constructor(props: CountUpProps) {
    super(props);
    this.state = {
      value: props.value ?? 0,
      hours: 0,
      minutes: 0,
      seconds: 0
    };
  }

  componentDidMount = () => {
    setInterval(() => {
      if (this.state.seconds < 59) {
        this.setState({ seconds: this.state.seconds + 1 });
      } else if (this.state.seconds === 59) {
        this.setState({ minutes: this.state.minutes + 1, seconds: 0 });
      }
      if (this.state.minutes === 59 && this.state.seconds === 59) {
        setTimeout(() => this.setState({ hours: this.state.hours + 1, minutes: 0, seconds: 0 }), 1000);
      }
    }, 1000);

    setInterval(() => {
      const value = this.state.value + 1;
      this.setState({ value });
      if (this.props?.onChange) this.props.onChange(value);
    }, 1000);
  };

  getProps = () => {
    const props: any = _.clone(this.props);
    if (props.value) {
      delete props.value;
    }
    if (props.onChange) {
      delete props.onChange;
    }
    if (props.style) {
      delete props.style;
    }
    return props;
  };

  getStyleProp = () => {
    let style: any = {};

    if (this.props.style) {
      style = _.clone(this.props.style);
    }

    if (style.display !== "block") {
      style.display = "inline-block";
    }

    return style;
  };

  render(): ReactNode {
    return (
      <>
        <label {...this.getProps()} style={{ ...this.getStyleProp() }}>
          {this.state.hours < 10 ? `0${this.state.hours}` : this.state.hours}:
          {this.state.minutes < 10 ? `0${this.state.minutes}` : this.state.minutes}:
          {this.state.seconds < 10 ? `0${this.state.seconds}` : this.state.seconds}
        </label>
      </>
    );
  }
}

const countUp = withCommonEvents(CountUp);
export { countUp as CountUp };
