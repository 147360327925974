import React from "react";
import Axios, { AxiosResponse } from "axios";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { KNavigator } from "../../shared/hoc/with-history";
import { KContext } from "../../shared/hoc/with-context";
import { useLocation } from "react-router-dom";
import { FormInstance } from "antd/es/form";
import { Guid } from "guid-typescript";
import Swal from "sweetalert2";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import moment from "moment";
import { IValidationData } from "../../models/dto/validation-data";
import { IStripePayment } from "../../models/dto/stripe-payment";
import { IUserInfoDto } from "../../models/dto/user-info.dto";
import { UserService } from "../../services/user-service";
import _ from "lodash";
import mqtt, { MqttClient } from "mqtt";
import { IMqttResult } from "../../models/dto/mqtt.dto";

declare let window: any;

export interface IProtocol3_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

export interface IProtocol3_ScreenState {
    isSpinnerVisible: boolean;
    isPageVisible: boolean;
	isLeftMenuVisible: boolean;
    spinnerCount: number;
    selectedLanguage: string;
    isPageInitLoaded: false;
    isPageRecurringLoaded: false;
	GoBack: any;
	GoBack_dummy: any;
	SiniflandirmaAll: any[];
	SiniflandirmaAll_dummy: any[];
	ProtokolSelectById: any[];
	ProtokolSelectById_dummy: any[];
	ProtokolSiniflandirmaRaporAciklama: any[];
	ProtokolSiniflandirmaRaporAciklama_dummy: any[];
	SiniflandirmaSeviyeleri: any[];
	SiniflandirmaSeviyeleri_dummy: any[];
	SaveRecord: number;
	SaveRecord_dummy: number;
	isComp594938Visible: 'visible' | 'hidden';
	isComp286959Visible: 'visible' | 'hidden';
	isComp154471Visible: 'visible' | 'hidden';
	isComp264710Visible: 'visible' | 'hidden';
	isComp882780Visible: 'visible' | 'hidden';
	isComp910124Visible: 'visible' | 'hidden';
	isComp514465Visible: 'visible' | 'hidden';
	isComp648318Visible: 'visible' | 'hidden';


}

export class Protocol3_ScreenBase extends React.PureComponent<IProtocol3_ScreenProps, any> {
	protocol3_649998_value_kuikaSelectBoxRef: React.RefObject<any>;
    ml=[{"Id":"423ed54a-d6f3-fccb-7d3e-72c7b24c8b7a","Name":"tr_TR","ShortName":"Turkish","IsDefault":true,"LanguagePhrases":[]},{"Id":"74503767-9450-4cae-9db5-729d038bcb36","Name":"en_US","ShortName":"English","LanguagePhrases":[]},{"Id":"40ab187d-8ab9-4d70-acf5-e41ed62b649c","Name":"FixedValues","ShortName":"FixedValues","LanguagePhrases":[{"Id":796540,"PropertyName":"value","Value":"Protokol Sınıflandırma"},{"Id":196500,"PropertyName":"value","Value":"Sınıflandırma"},{"Id":649998,"PropertyName":"placeholder","Value":"Seçiniz..."},{"Id":967318,"PropertyName":"value","Value":"Rapor Açıklaması"},{"Id":52244,"PropertyName":"placeholder","Value":"Type here..."},{"Id":509578,"PropertyName":"value","Value":"Rapor Açıklaması"},{"Id":626565,"PropertyName":"placeholder","Value":"Type here..."},{"Id":253361,"PropertyName":"value","Value":"Rapor Açıklaması"},{"Id":592190,"PropertyName":"placeholder","Value":"Type here..."},{"Id":934805,"PropertyName":"value","Value":"Rapor Açıklaması"},{"Id":308696,"PropertyName":"placeholder","Value":"Type here..."},{"Id":496019,"PropertyName":"value","Value":"Rapor Açıklaması"},{"Id":16146,"PropertyName":"placeholder","Value":"Type here..."},{"Id":114698,"PropertyName":"value","Value":"Rapor Açıklaması"},{"Id":317609,"PropertyName":"placeholder","Value":"Type here..."},{"Id":336078,"PropertyName":"value","Value":"Rapor Açıklaması"},{"Id":379240,"PropertyName":"placeholder","Value":"Type here..."},{"Id":268967,"PropertyName":"value","Value":"Rapor Açıklaması"},{"Id":531390,"PropertyName":"placeholder","Value":"Type here..."},{"Id":9544,"PropertyName":"label","Value":"KAYDET"}]}]
    defaultML = "tr_TR"
    
    screenHash = ""
    
    constructor(props) {
        super(props);
		this.protocol3_649998_value_kuikaSelectBoxRef = React.createRef();

        this.state = {
            isSpinnerVisible: false,
            isPageVisible: false,
            isLeftMenuVisible: false,
            spinnerCount: 0,
            selectedLanguage: "",
            isPageInitLoaded: false,
            isPageRecurringLoaded: false,
            	        GoBack: "",
	        SiniflandirmaAll: [],
	        ProtokolSelectById: [],
	        ProtokolSiniflandirmaRaporAciklama: [],
	        SiniflandirmaSeviyeleri: [],
	        SaveRecord: false,
	        isComp594938Visible: 'hidden',
	        isComp286959Visible: 'hidden',
	        isComp154471Visible: 'hidden',
	        isComp264710Visible: 'hidden',
	        isComp882780Visible: 'hidden',
	        isComp910124Visible: 'hidden',
	        isComp514465Visible: 'hidden',
	        isComp648318Visible: 'hidden',

            
        }

        this.state.isPageVisible = true;
        
    }

    async componentDidMount() {

        if (KuikaAppManager.isPageInitPrevented()) {
            KuikaAppManager.calculateAndSetBodyHeight("protocol3", "");
            return;
        }
        
        ReactSystemFunctions.validateToken()
        if (true){
            await this.Protocol3PageInit();
        }
        
        
        
        KuikaAppManager.calculateAndSetBodyHeight("protocol3", "");
    }

    componentWillUnmount() {
        
        
        
    }

    

        
    componentDidUpdate= async (prevProps, prevState) => {
		KuikaAppManager.calculateAndSetBodyHeight("protocol3", ""    );
        if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
			await this.Protocol3PageInit();
		}

        
	}

    fillFormInitValues() {
        this.props.form.setFieldsValue({
protocol3_649998_value: this.state.ProtokolSelectById?.at?.(0)?.siniflandirmaId ?? undefined,
protocol3_52244_value: this.state.ProtokolSiniflandirmaRaporAciklama?.at?.(0)?.seviye1Aciklama ?? undefined,
protocol3_626565_value: this.state.ProtokolSiniflandirmaRaporAciklama?.at?.(0)?.seviye2Aciklama ?? undefined,
protocol3_592190_value: this.state.ProtokolSiniflandirmaRaporAciklama?.at?.(0)?.seviye3Aciklama ?? undefined,
protocol3_308696_value: this.state.ProtokolSiniflandirmaRaporAciklama?.at?.(0)?.seviye4Aciklama ?? undefined,
protocol3_16146_value: this.state.ProtokolSiniflandirmaRaporAciklama?.at?.(0)?.seviye5Aciklama ?? undefined,
protocol3_317609_value: this.state.ProtokolSiniflandirmaRaporAciklama?.at?.(0)?.seviye6Aciklama ?? undefined,
protocol3_379240_value: this.state.ProtokolSiniflandirmaRaporAciklama?.at?.(0)?.seviye7Aciklama ?? undefined,
protocol3_531390_value: this.state.ProtokolSiniflandirmaRaporAciklama?.at?.(0)?.seviye8Aciklama ?? undefined
});

    }

      setPageInitLoaded = (value: boolean) => {
        this.setState({ isPageInitLoaded: value });
      }

      setPageRecurringLoaded = (value: boolean) => {
        this.setState({ isPageRecurringLoaded: value });
      }

      clearFieldValidation = (changedValues: any) => {
        const fieldName = Object.keys(changedValues)[0];
        if (fieldName) {
          this.props.form.setFields([
            {
              name: fieldName,
              errors: []
            }
          ]);
        }
      };

      

      

    Protocol3PageInit = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }

        let localVar = {
			Id_1: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.Id ?? this.props.screenInputs.id, "Guid"),
			protokolId_2: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.Id ?? this.props.screenInputs.id, "Guid")
        }

	KuikaAppManager.showSpinner(this);
	
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "Protocol3/Protocol3PageInit", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

		stateVars.SiniflandirmaAll = result?.data.siniflandirmaAll;
		
		formVars.protocol3_649998_value = (stateVars.ProtokolSelectById?.length > 0 ? stateVars.ProtokolSelectById[0].siniflandirmaId : this.state.ProtokolSelectById?.length > 0 ? this.state.ProtokolSelectById[0].siniflandirmaId : null);
		formVars.protocol3_649998_options = stateVars.SiniflandirmaAll;
		stateVars.ProtokolSelectById = result?.data.protokolSelectById;
		formVars.protocol3_649998_value = (stateVars.ProtokolSelectById?.length > 0 ? stateVars.ProtokolSelectById[0].siniflandirmaId : this.state.ProtokolSelectById?.length > 0 ? this.state.ProtokolSelectById[0].siniflandirmaId : null);
		formVars.protocol3_649998_options = stateVars.SiniflandirmaAll;
		stateVars.ProtokolSiniflandirmaRaporAciklama = result?.data.protokolSiniflandirmaRaporAciklama;
		formVars.protocol3_963811_value = ReactSystemFunctions.toString(this, (stateVars.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? stateVars.ProtokolSiniflandirmaRaporAciklama[0].seviye1Adi : this.state.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? this.state.ProtokolSiniflandirmaRaporAciklama[0].seviye1Adi : null));
		formVars.protocol3_52244_value = ReactSystemFunctions.toString(this, (stateVars.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? stateVars.ProtokolSiniflandirmaRaporAciklama[0].seviye1Aciklama : this.state.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? this.state.ProtokolSiniflandirmaRaporAciklama[0].seviye1Aciklama : null));
		formVars.protocol3_913903_value = ReactSystemFunctions.toString(this, (stateVars.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? stateVars.ProtokolSiniflandirmaRaporAciklama[0].seviye2Adi : this.state.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? this.state.ProtokolSiniflandirmaRaporAciklama[0].seviye2Adi : null));
		formVars.protocol3_626565_value = ReactSystemFunctions.toString(this, (stateVars.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? stateVars.ProtokolSiniflandirmaRaporAciklama[0].seviye2Aciklama : this.state.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? this.state.ProtokolSiniflandirmaRaporAciklama[0].seviye2Aciklama : null));
		formVars.protocol3_359711_value = ReactSystemFunctions.toString(this, (stateVars.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? stateVars.ProtokolSiniflandirmaRaporAciklama[0].seviye3Adi : this.state.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? this.state.ProtokolSiniflandirmaRaporAciklama[0].seviye3Adi : null));
		formVars.protocol3_592190_value = ReactSystemFunctions.toString(this, (stateVars.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? stateVars.ProtokolSiniflandirmaRaporAciklama[0].seviye3Aciklama : this.state.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? this.state.ProtokolSiniflandirmaRaporAciklama[0].seviye3Aciklama : null));
		formVars.protocol3_582368_value = ReactSystemFunctions.toString(this, (stateVars.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? stateVars.ProtokolSiniflandirmaRaporAciklama[0].seviye4Adi : this.state.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? this.state.ProtokolSiniflandirmaRaporAciklama[0].seviye4Adi : null));
		formVars.protocol3_308696_value = ReactSystemFunctions.toString(this, (stateVars.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? stateVars.ProtokolSiniflandirmaRaporAciklama[0].seviye4Aciklama : this.state.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? this.state.ProtokolSiniflandirmaRaporAciklama[0].seviye4Aciklama : null));
		formVars.protocol3_928483_value = ReactSystemFunctions.toString(this, (stateVars.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? stateVars.ProtokolSiniflandirmaRaporAciklama[0].seviye5Adi : this.state.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? this.state.ProtokolSiniflandirmaRaporAciklama[0].seviye5Adi : null));
		formVars.protocol3_16146_value = ReactSystemFunctions.toString(this, (stateVars.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? stateVars.ProtokolSiniflandirmaRaporAciklama[0].seviye5Aciklama : this.state.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? this.state.ProtokolSiniflandirmaRaporAciklama[0].seviye5Aciklama : null));
		formVars.protocol3_191609_value = ReactSystemFunctions.toString(this, (stateVars.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? stateVars.ProtokolSiniflandirmaRaporAciklama[0].seviye6Adi : this.state.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? this.state.ProtokolSiniflandirmaRaporAciklama[0].seviye6Adi : null));
		formVars.protocol3_317609_value = ReactSystemFunctions.toString(this, (stateVars.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? stateVars.ProtokolSiniflandirmaRaporAciklama[0].seviye6Aciklama : this.state.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? this.state.ProtokolSiniflandirmaRaporAciklama[0].seviye6Aciklama : null));
		formVars.protocol3_525404_value = ReactSystemFunctions.toString(this, (stateVars.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? stateVars.ProtokolSiniflandirmaRaporAciklama[0].seviye7Adi : this.state.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? this.state.ProtokolSiniflandirmaRaporAciklama[0].seviye7Adi : null));
		formVars.protocol3_379240_value = ReactSystemFunctions.toString(this, (stateVars.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? stateVars.ProtokolSiniflandirmaRaporAciklama[0].seviye7Aciklama : this.state.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? this.state.ProtokolSiniflandirmaRaporAciklama[0].seviye7Aciklama : null));
		formVars.protocol3_126655_value = ReactSystemFunctions.toString(this, (stateVars.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? stateVars.ProtokolSiniflandirmaRaporAciklama[0].seviye8Adi : this.state.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? this.state.ProtokolSiniflandirmaRaporAciklama[0].seviye8Adi : null));
		formVars.protocol3_531390_value = ReactSystemFunctions.toString(this, (stateVars.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? stateVars.ProtokolSiniflandirmaRaporAciklama[0].seviye8Aciklama : this.state.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? this.state.ProtokolSiniflandirmaRaporAciklama[0].seviye8Aciklama : null));
		stateVars.isComp594938Visible = ((ReactSystemFunctions.isGreaterThan(ReactSystemFunctions.value(this, (stateVars.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? stateVars.ProtokolSiniflandirmaRaporAciklama[0].seviyeAdedi : this.state.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? this.state.ProtokolSiniflandirmaRaporAciklama[0].seviyeAdedi : null)), "0")) === true ? "visible" : "hidden")
		stateVars.isComp286959Visible = ((ReactSystemFunctions.isGreaterThan(ReactSystemFunctions.value(this, (stateVars.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? stateVars.ProtokolSiniflandirmaRaporAciklama[0].seviyeAdedi : this.state.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? this.state.ProtokolSiniflandirmaRaporAciklama[0].seviyeAdedi : null)), "1")) === true ? "visible" : "hidden")
		stateVars.isComp154471Visible = ((ReactSystemFunctions.isGreaterThan(ReactSystemFunctions.value(this, (stateVars.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? stateVars.ProtokolSiniflandirmaRaporAciklama[0].seviyeAdedi : this.state.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? this.state.ProtokolSiniflandirmaRaporAciklama[0].seviyeAdedi : null)), "2")) === true ? "visible" : "hidden")
		stateVars.isComp264710Visible = ((ReactSystemFunctions.isGreaterThan(ReactSystemFunctions.value(this, (stateVars.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? stateVars.ProtokolSiniflandirmaRaporAciklama[0].seviyeAdedi : this.state.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? this.state.ProtokolSiniflandirmaRaporAciklama[0].seviyeAdedi : null)), "3")) === true ? "visible" : "hidden")
		stateVars.isComp882780Visible = ((ReactSystemFunctions.isGreaterThan(ReactSystemFunctions.value(this, (stateVars.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? stateVars.ProtokolSiniflandirmaRaporAciklama[0].seviyeAdedi : this.state.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? this.state.ProtokolSiniflandirmaRaporAciklama[0].seviyeAdedi : null)), "4")) === true ? "visible" : "hidden")
		stateVars.isComp910124Visible = ((ReactSystemFunctions.isGreaterThan(ReactSystemFunctions.value(this, (stateVars.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? stateVars.ProtokolSiniflandirmaRaporAciklama[0].seviyeAdedi : this.state.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? this.state.ProtokolSiniflandirmaRaporAciklama[0].seviyeAdedi : null)), "5")) === true ? "visible" : "hidden")
		stateVars.isComp514465Visible = ((ReactSystemFunctions.isGreaterThan(ReactSystemFunctions.value(this, (stateVars.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? stateVars.ProtokolSiniflandirmaRaporAciklama[0].seviyeAdedi : this.state.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? this.state.ProtokolSiniflandirmaRaporAciklama[0].seviyeAdedi : null)), "6")) === true ? "visible" : "hidden")
		stateVars.isComp648318Visible = ((ReactSystemFunctions.isGreaterThan(ReactSystemFunctions.value(this, (stateVars.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? stateVars.ProtokolSiniflandirmaRaporAciklama[0].seviyeAdedi : this.state.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? this.state.ProtokolSiniflandirmaRaporAciklama[0].seviyeAdedi : null)), "7")) === true ? "visible" : "hidden")
		stateVars.SiniflandirmaSeviyeleri = result?.data.siniflandirmaSeviyeleri;
		this.props.form.setFieldsValue(formVars);
		
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.Protocol3PageInit1_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
	Protocol3PageInit1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }

			formVars.protocol3_649998_value = ReactSystemFunctions.toString(this, (stateVars.ProtokolSelectById?.length > 0 ? stateVars.ProtokolSelectById[0].siniflandirmaId : this.state.ProtokolSelectById?.length > 0 ? this.state.ProtokolSelectById[0].siniflandirmaId : null));

			
			stateVars.dataSource_649998 = this.state.SiniflandirmaAll;
			stateVars.dataSource_649998 = this.state.SiniflandirmaAll;
			stateVars.isComp594938Visible = ((ReactSystemFunctions.isGreaterThan(ReactSystemFunctions.value(this, (stateVars.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? stateVars.ProtokolSiniflandirmaRaporAciklama[0].seviyeAdedi : this.state.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? this.state.ProtokolSiniflandirmaRaporAciklama[0].seviyeAdedi : null)), "0")) === true ? "visible" : "hidden")
			formVars.protocol3_963811_value = ReactSystemFunctions.toString(this, (stateVars.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? stateVars.ProtokolSiniflandirmaRaporAciklama[0].seviye1Adi : this.state.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? this.state.ProtokolSiniflandirmaRaporAciklama[0].seviye1Adi : null));

			formVars.protocol3_52244_value = ReactSystemFunctions.toString(this, (stateVars.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? stateVars.ProtokolSiniflandirmaRaporAciklama[0].seviye1Aciklama : this.state.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? this.state.ProtokolSiniflandirmaRaporAciklama[0].seviye1Aciklama : null));

			stateVars.isComp286959Visible = ((ReactSystemFunctions.isGreaterThan(ReactSystemFunctions.value(this, (stateVars.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? stateVars.ProtokolSiniflandirmaRaporAciklama[0].seviyeAdedi : this.state.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? this.state.ProtokolSiniflandirmaRaporAciklama[0].seviyeAdedi : null)), "1")) === true ? "visible" : "hidden")
			formVars.protocol3_913903_value = ReactSystemFunctions.toString(this, (stateVars.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? stateVars.ProtokolSiniflandirmaRaporAciklama[0].seviye2Adi : this.state.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? this.state.ProtokolSiniflandirmaRaporAciklama[0].seviye2Adi : null));

			formVars.protocol3_626565_value = ReactSystemFunctions.toString(this, (stateVars.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? stateVars.ProtokolSiniflandirmaRaporAciklama[0].seviye2Aciklama : this.state.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? this.state.ProtokolSiniflandirmaRaporAciklama[0].seviye2Aciklama : null));

			stateVars.isComp154471Visible = ((ReactSystemFunctions.isGreaterThan(ReactSystemFunctions.value(this, (stateVars.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? stateVars.ProtokolSiniflandirmaRaporAciklama[0].seviyeAdedi : this.state.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? this.state.ProtokolSiniflandirmaRaporAciklama[0].seviyeAdedi : null)), "2")) === true ? "visible" : "hidden")
			formVars.protocol3_359711_value = ReactSystemFunctions.toString(this, (stateVars.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? stateVars.ProtokolSiniflandirmaRaporAciklama[0].seviye3Adi : this.state.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? this.state.ProtokolSiniflandirmaRaporAciklama[0].seviye3Adi : null));

			formVars.protocol3_592190_value = ReactSystemFunctions.toString(this, (stateVars.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? stateVars.ProtokolSiniflandirmaRaporAciklama[0].seviye3Aciklama : this.state.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? this.state.ProtokolSiniflandirmaRaporAciklama[0].seviye3Aciklama : null));

			stateVars.isComp264710Visible = ((ReactSystemFunctions.isGreaterThan(ReactSystemFunctions.value(this, (stateVars.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? stateVars.ProtokolSiniflandirmaRaporAciklama[0].seviyeAdedi : this.state.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? this.state.ProtokolSiniflandirmaRaporAciklama[0].seviyeAdedi : null)), "3")) === true ? "visible" : "hidden")
			formVars.protocol3_582368_value = ReactSystemFunctions.toString(this, (stateVars.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? stateVars.ProtokolSiniflandirmaRaporAciklama[0].seviye4Adi : this.state.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? this.state.ProtokolSiniflandirmaRaporAciklama[0].seviye4Adi : null));

			formVars.protocol3_308696_value = ReactSystemFunctions.toString(this, (stateVars.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? stateVars.ProtokolSiniflandirmaRaporAciklama[0].seviye4Aciklama : this.state.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? this.state.ProtokolSiniflandirmaRaporAciklama[0].seviye4Aciklama : null));

			stateVars.isComp882780Visible = ((ReactSystemFunctions.isGreaterThan(ReactSystemFunctions.value(this, (stateVars.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? stateVars.ProtokolSiniflandirmaRaporAciklama[0].seviyeAdedi : this.state.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? this.state.ProtokolSiniflandirmaRaporAciklama[0].seviyeAdedi : null)), "4")) === true ? "visible" : "hidden")
			formVars.protocol3_928483_value = ReactSystemFunctions.toString(this, (stateVars.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? stateVars.ProtokolSiniflandirmaRaporAciklama[0].seviye5Adi : this.state.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? this.state.ProtokolSiniflandirmaRaporAciklama[0].seviye5Adi : null));

			formVars.protocol3_16146_value = ReactSystemFunctions.toString(this, (stateVars.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? stateVars.ProtokolSiniflandirmaRaporAciklama[0].seviye5Aciklama : this.state.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? this.state.ProtokolSiniflandirmaRaporAciklama[0].seviye5Aciklama : null));

			stateVars.isComp910124Visible = ((ReactSystemFunctions.isGreaterThan(ReactSystemFunctions.value(this, (stateVars.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? stateVars.ProtokolSiniflandirmaRaporAciklama[0].seviyeAdedi : this.state.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? this.state.ProtokolSiniflandirmaRaporAciklama[0].seviyeAdedi : null)), "5")) === true ? "visible" : "hidden")
			formVars.protocol3_191609_value = ReactSystemFunctions.toString(this, (stateVars.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? stateVars.ProtokolSiniflandirmaRaporAciklama[0].seviye6Adi : this.state.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? this.state.ProtokolSiniflandirmaRaporAciklama[0].seviye6Adi : null));

			formVars.protocol3_317609_value = ReactSystemFunctions.toString(this, (stateVars.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? stateVars.ProtokolSiniflandirmaRaporAciklama[0].seviye6Aciklama : this.state.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? this.state.ProtokolSiniflandirmaRaporAciklama[0].seviye6Aciklama : null));

			stateVars.isComp514465Visible = ((ReactSystemFunctions.isGreaterThan(ReactSystemFunctions.value(this, (stateVars.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? stateVars.ProtokolSiniflandirmaRaporAciklama[0].seviyeAdedi : this.state.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? this.state.ProtokolSiniflandirmaRaporAciklama[0].seviyeAdedi : null)), "6")) === true ? "visible" : "hidden")
			formVars.protocol3_525404_value = ReactSystemFunctions.toString(this, (stateVars.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? stateVars.ProtokolSiniflandirmaRaporAciklama[0].seviye7Adi : this.state.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? this.state.ProtokolSiniflandirmaRaporAciklama[0].seviye7Adi : null));

			formVars.protocol3_379240_value = ReactSystemFunctions.toString(this, (stateVars.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? stateVars.ProtokolSiniflandirmaRaporAciklama[0].seviye7Aciklama : this.state.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? this.state.ProtokolSiniflandirmaRaporAciklama[0].seviye7Aciklama : null));

			stateVars.isComp648318Visible = ((ReactSystemFunctions.isGreaterThan(ReactSystemFunctions.value(this, (stateVars.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? stateVars.ProtokolSiniflandirmaRaporAciklama[0].seviyeAdedi : this.state.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? this.state.ProtokolSiniflandirmaRaporAciklama[0].seviyeAdedi : null)), "7")) === true ? "visible" : "hidden")
			formVars.protocol3_126655_value = ReactSystemFunctions.toString(this, (stateVars.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? stateVars.ProtokolSiniflandirmaRaporAciklama[0].seviye8Adi : this.state.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? this.state.ProtokolSiniflandirmaRaporAciklama[0].seviye8Adi : null));

			formVars.protocol3_531390_value = ReactSystemFunctions.toString(this, (stateVars.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? stateVars.ProtokolSiniflandirmaRaporAciklama[0].seviye8Aciklama : this.state.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? this.state.ProtokolSiniflandirmaRaporAciklama[0].seviye8Aciklama : null));


	this.props.form.setFieldsValue(formVars);
	this.setState(stateVars);
	KuikaAppManager.hideSpinner(this);

            this.setPageInitLoaded(true);
            return isErrorOccurred;
        }




		Protocol3Component_968274_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
KuikaAppManager.showSpinner(this);

				stateVars.GoBack = await ReactSystemFunctions.goBack(this);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }


    Protocol3Component_649998_onChange = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;

			validations.push({ 
				isValid: ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "protocol3_649998_value", "value", "SiniflandirmaAll", "id", "")), null),
				message: "*",
				formName: "protocol3_649998_value",
				condition: true
			} as IValidationData);

validations.forEach((validation) => {
            if (validation.message === "Geçerli bir mail adresi giriniz.") {
                validation.message = ReactSystemFunctions.getEmailValidationMessage()
            }
        })

		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				sinifId_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "protocol3_649998_value", "value", "SiniflandirmaAll", "id", "id")), "Guid"),
				protokolId_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.Id ?? this.props.screenInputs.id, "Guid")
        }

		KuikaAppManager.showSpinner(this);
		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "Protocol3/Protocol3Component_649998_onChange", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.ProtokolSiniflandirmaRaporAciklama = result?.data.protokolSiniflandirmaRaporAciklama;
			formVars.protocol3_963811_value = ReactSystemFunctions.toString(this, (stateVars.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? stateVars.ProtokolSiniflandirmaRaporAciklama[0].seviye1Adi : this.state.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? this.state.ProtokolSiniflandirmaRaporAciklama[0].seviye1Adi : null));
			formVars.protocol3_52244_value = ReactSystemFunctions.toString(this, (stateVars.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? stateVars.ProtokolSiniflandirmaRaporAciklama[0].seviye1Aciklama : this.state.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? this.state.ProtokolSiniflandirmaRaporAciklama[0].seviye1Aciklama : null));
			formVars.protocol3_913903_value = ReactSystemFunctions.toString(this, (stateVars.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? stateVars.ProtokolSiniflandirmaRaporAciklama[0].seviye2Adi : this.state.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? this.state.ProtokolSiniflandirmaRaporAciklama[0].seviye2Adi : null));
			formVars.protocol3_626565_value = ReactSystemFunctions.toString(this, (stateVars.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? stateVars.ProtokolSiniflandirmaRaporAciklama[0].seviye2Aciklama : this.state.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? this.state.ProtokolSiniflandirmaRaporAciklama[0].seviye2Aciklama : null));
			formVars.protocol3_359711_value = ReactSystemFunctions.toString(this, (stateVars.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? stateVars.ProtokolSiniflandirmaRaporAciklama[0].seviye3Adi : this.state.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? this.state.ProtokolSiniflandirmaRaporAciklama[0].seviye3Adi : null));
			formVars.protocol3_592190_value = ReactSystemFunctions.toString(this, (stateVars.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? stateVars.ProtokolSiniflandirmaRaporAciklama[0].seviye3Aciklama : this.state.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? this.state.ProtokolSiniflandirmaRaporAciklama[0].seviye3Aciklama : null));
			formVars.protocol3_582368_value = ReactSystemFunctions.toString(this, (stateVars.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? stateVars.ProtokolSiniflandirmaRaporAciklama[0].seviye4Adi : this.state.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? this.state.ProtokolSiniflandirmaRaporAciklama[0].seviye4Adi : null));
			formVars.protocol3_308696_value = ReactSystemFunctions.toString(this, (stateVars.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? stateVars.ProtokolSiniflandirmaRaporAciklama[0].seviye4Aciklama : this.state.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? this.state.ProtokolSiniflandirmaRaporAciklama[0].seviye4Aciklama : null));
			formVars.protocol3_928483_value = ReactSystemFunctions.toString(this, (stateVars.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? stateVars.ProtokolSiniflandirmaRaporAciklama[0].seviye5Adi : this.state.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? this.state.ProtokolSiniflandirmaRaporAciklama[0].seviye5Adi : null));
			formVars.protocol3_16146_value = ReactSystemFunctions.toString(this, (stateVars.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? stateVars.ProtokolSiniflandirmaRaporAciklama[0].seviye5Aciklama : this.state.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? this.state.ProtokolSiniflandirmaRaporAciklama[0].seviye5Aciklama : null));
			formVars.protocol3_191609_value = ReactSystemFunctions.toString(this, (stateVars.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? stateVars.ProtokolSiniflandirmaRaporAciklama[0].seviye6Adi : this.state.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? this.state.ProtokolSiniflandirmaRaporAciklama[0].seviye6Adi : null));
			formVars.protocol3_317609_value = ReactSystemFunctions.toString(this, (stateVars.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? stateVars.ProtokolSiniflandirmaRaporAciklama[0].seviye6Aciklama : this.state.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? this.state.ProtokolSiniflandirmaRaporAciklama[0].seviye6Aciklama : null));
			formVars.protocol3_525404_value = ReactSystemFunctions.toString(this, (stateVars.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? stateVars.ProtokolSiniflandirmaRaporAciklama[0].seviye7Adi : this.state.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? this.state.ProtokolSiniflandirmaRaporAciklama[0].seviye7Adi : null));
			formVars.protocol3_379240_value = ReactSystemFunctions.toString(this, (stateVars.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? stateVars.ProtokolSiniflandirmaRaporAciklama[0].seviye7Aciklama : this.state.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? this.state.ProtokolSiniflandirmaRaporAciklama[0].seviye7Aciklama : null));
			formVars.protocol3_126655_value = ReactSystemFunctions.toString(this, (stateVars.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? stateVars.ProtokolSiniflandirmaRaporAciklama[0].seviye8Adi : this.state.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? this.state.ProtokolSiniflandirmaRaporAciklama[0].seviye8Adi : null));
			formVars.protocol3_531390_value = ReactSystemFunctions.toString(this, (stateVars.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? stateVars.ProtokolSiniflandirmaRaporAciklama[0].seviye8Aciklama : this.state.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? this.state.ProtokolSiniflandirmaRaporAciklama[0].seviye8Aciklama : null));
			stateVars.isComp594938Visible = ((ReactSystemFunctions.isGreaterThan(ReactSystemFunctions.value(this, (stateVars.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? stateVars.ProtokolSiniflandirmaRaporAciklama[0].seviyeAdedi : this.state.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? this.state.ProtokolSiniflandirmaRaporAciklama[0].seviyeAdedi : null)), "0")) === true ? "visible" : "hidden")
			stateVars.isComp286959Visible = ((ReactSystemFunctions.isGreaterThan(ReactSystemFunctions.value(this, (stateVars.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? stateVars.ProtokolSiniflandirmaRaporAciklama[0].seviyeAdedi : this.state.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? this.state.ProtokolSiniflandirmaRaporAciklama[0].seviyeAdedi : null)), "1")) === true ? "visible" : "hidden")
			stateVars.isComp154471Visible = ((ReactSystemFunctions.isGreaterThan(ReactSystemFunctions.value(this, (stateVars.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? stateVars.ProtokolSiniflandirmaRaporAciklama[0].seviyeAdedi : this.state.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? this.state.ProtokolSiniflandirmaRaporAciklama[0].seviyeAdedi : null)), "2")) === true ? "visible" : "hidden")
			stateVars.isComp264710Visible = ((ReactSystemFunctions.isGreaterThan(ReactSystemFunctions.value(this, (stateVars.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? stateVars.ProtokolSiniflandirmaRaporAciklama[0].seviyeAdedi : this.state.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? this.state.ProtokolSiniflandirmaRaporAciklama[0].seviyeAdedi : null)), "3")) === true ? "visible" : "hidden")
			stateVars.isComp882780Visible = ((ReactSystemFunctions.isGreaterThan(ReactSystemFunctions.value(this, (stateVars.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? stateVars.ProtokolSiniflandirmaRaporAciklama[0].seviyeAdedi : this.state.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? this.state.ProtokolSiniflandirmaRaporAciklama[0].seviyeAdedi : null)), "4")) === true ? "visible" : "hidden")
			stateVars.isComp910124Visible = ((ReactSystemFunctions.isGreaterThan(ReactSystemFunctions.value(this, (stateVars.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? stateVars.ProtokolSiniflandirmaRaporAciklama[0].seviyeAdedi : this.state.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? this.state.ProtokolSiniflandirmaRaporAciklama[0].seviyeAdedi : null)), "5")) === true ? "visible" : "hidden")
			stateVars.isComp514465Visible = ((ReactSystemFunctions.isGreaterThan(ReactSystemFunctions.value(this, (stateVars.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? stateVars.ProtokolSiniflandirmaRaporAciklama[0].seviyeAdedi : this.state.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? this.state.ProtokolSiniflandirmaRaporAciklama[0].seviyeAdedi : null)), "6")) === true ? "visible" : "hidden")
			stateVars.isComp648318Visible = ((ReactSystemFunctions.isGreaterThan(ReactSystemFunctions.value(this, (stateVars.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? stateVars.ProtokolSiniflandirmaRaporAciklama[0].seviyeAdedi : this.state.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? this.state.ProtokolSiniflandirmaRaporAciklama[0].seviyeAdedi : null)), "7")) === true ? "visible" : "hidden")
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.Protocol3Component_649998_onChange1_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
		Protocol3Component_649998_onChange1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }

				formVars.protocol3_963811_value = ReactSystemFunctions.toString(this, (stateVars.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? stateVars.ProtokolSiniflandirmaRaporAciklama[0].seviye1Adi : this.state.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? this.state.ProtokolSiniflandirmaRaporAciklama[0].seviye1Adi : null));

				formVars.protocol3_52244_value = ReactSystemFunctions.toString(this, (stateVars.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? stateVars.ProtokolSiniflandirmaRaporAciklama[0].seviye1Aciklama : this.state.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? this.state.ProtokolSiniflandirmaRaporAciklama[0].seviye1Aciklama : null));

				formVars.protocol3_913903_value = ReactSystemFunctions.toString(this, (stateVars.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? stateVars.ProtokolSiniflandirmaRaporAciklama[0].seviye2Adi : this.state.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? this.state.ProtokolSiniflandirmaRaporAciklama[0].seviye2Adi : null));

				formVars.protocol3_626565_value = ReactSystemFunctions.toString(this, (stateVars.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? stateVars.ProtokolSiniflandirmaRaporAciklama[0].seviye2Aciklama : this.state.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? this.state.ProtokolSiniflandirmaRaporAciklama[0].seviye2Aciklama : null));

				formVars.protocol3_359711_value = ReactSystemFunctions.toString(this, (stateVars.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? stateVars.ProtokolSiniflandirmaRaporAciklama[0].seviye3Adi : this.state.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? this.state.ProtokolSiniflandirmaRaporAciklama[0].seviye3Adi : null));

				formVars.protocol3_592190_value = ReactSystemFunctions.toString(this, (stateVars.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? stateVars.ProtokolSiniflandirmaRaporAciklama[0].seviye3Aciklama : this.state.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? this.state.ProtokolSiniflandirmaRaporAciklama[0].seviye3Aciklama : null));

				formVars.protocol3_582368_value = ReactSystemFunctions.toString(this, (stateVars.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? stateVars.ProtokolSiniflandirmaRaporAciklama[0].seviye4Adi : this.state.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? this.state.ProtokolSiniflandirmaRaporAciklama[0].seviye4Adi : null));

				formVars.protocol3_308696_value = ReactSystemFunctions.toString(this, (stateVars.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? stateVars.ProtokolSiniflandirmaRaporAciklama[0].seviye4Aciklama : this.state.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? this.state.ProtokolSiniflandirmaRaporAciklama[0].seviye4Aciklama : null));

				formVars.protocol3_928483_value = ReactSystemFunctions.toString(this, (stateVars.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? stateVars.ProtokolSiniflandirmaRaporAciklama[0].seviye5Adi : this.state.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? this.state.ProtokolSiniflandirmaRaporAciklama[0].seviye5Adi : null));

				formVars.protocol3_16146_value = ReactSystemFunctions.toString(this, (stateVars.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? stateVars.ProtokolSiniflandirmaRaporAciklama[0].seviye5Aciklama : this.state.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? this.state.ProtokolSiniflandirmaRaporAciklama[0].seviye5Aciklama : null));

				formVars.protocol3_191609_value = ReactSystemFunctions.toString(this, (stateVars.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? stateVars.ProtokolSiniflandirmaRaporAciklama[0].seviye6Adi : this.state.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? this.state.ProtokolSiniflandirmaRaporAciklama[0].seviye6Adi : null));

				formVars.protocol3_317609_value = ReactSystemFunctions.toString(this, (stateVars.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? stateVars.ProtokolSiniflandirmaRaporAciklama[0].seviye6Aciklama : this.state.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? this.state.ProtokolSiniflandirmaRaporAciklama[0].seviye6Aciklama : null));

				formVars.protocol3_525404_value = ReactSystemFunctions.toString(this, (stateVars.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? stateVars.ProtokolSiniflandirmaRaporAciklama[0].seviye7Adi : this.state.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? this.state.ProtokolSiniflandirmaRaporAciklama[0].seviye7Adi : null));

				formVars.protocol3_379240_value = ReactSystemFunctions.toString(this, (stateVars.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? stateVars.ProtokolSiniflandirmaRaporAciklama[0].seviye7Aciklama : this.state.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? this.state.ProtokolSiniflandirmaRaporAciklama[0].seviye7Aciklama : null));

				formVars.protocol3_126655_value = ReactSystemFunctions.toString(this, (stateVars.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? stateVars.ProtokolSiniflandirmaRaporAciklama[0].seviye8Adi : this.state.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? this.state.ProtokolSiniflandirmaRaporAciklama[0].seviye8Adi : null));

				formVars.protocol3_531390_value = ReactSystemFunctions.toString(this, (stateVars.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? stateVars.ProtokolSiniflandirmaRaporAciklama[0].seviye8Aciklama : this.state.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? this.state.ProtokolSiniflandirmaRaporAciklama[0].seviye8Aciklama : null));


		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }




    Protocol3Component_9544_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;

			validations.push({ 
				isValid: ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "protocol3_649998_value", "value", "SiniflandirmaAll", "id", "")), null),
				message: "*",
				formName: "protocol3_649998_value",
				condition: true
			} as IValidationData);

validations.forEach((validation) => {
            if (validation.message === "Geçerli bir mail adresi giriniz.") {
                validation.message = ReactSystemFunctions.getEmailValidationMessage()
            }
        })

		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				Id_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.Id ?? this.props.screenInputs.id, "Guid"),
				SiniflandirmaId_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "protocol3_649998_value", "value", "SiniflandirmaAll", "id", "id")), "Guid"),
				Id_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? stateVars.ProtokolSiniflandirmaRaporAciklama[0].seviyeAciklama1Id : this.state.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? this.state.ProtokolSiniflandirmaRaporAciklama[0].seviyeAciklama1Id : null)), "Guid"),
				RaporAciklama_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "protocol3_52244_value", "value", "", "", "")), "string"),
				ProtokolId_1: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.Id ?? this.props.screenInputs.id, "Guid"),
				SinifSeviyeId_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? stateVars.ProtokolSiniflandirmaRaporAciklama[0].seviye1Id : this.state.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? this.state.ProtokolSiniflandirmaRaporAciklama[0].seviye1Id : null)), "Guid"),
				Id_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? stateVars.ProtokolSiniflandirmaRaporAciklama[0].seviyeAciklama2Id : this.state.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? this.state.ProtokolSiniflandirmaRaporAciklama[0].seviyeAciklama2Id : null)), "Guid"),
				RaporAciklama_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "protocol3_626565_value", "value", "", "", "")), "string"),
				ProtokolId_2: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.Id ?? this.props.screenInputs.id, "Guid"),
				SinifSeviyeId_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? stateVars.ProtokolSiniflandirmaRaporAciklama[0].seviye2Id : this.state.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? this.state.ProtokolSiniflandirmaRaporAciklama[0].seviye2Id : null)), "Guid"),
				Id_3: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? stateVars.ProtokolSiniflandirmaRaporAciklama[0].seviyeAciklama3Id : this.state.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? this.state.ProtokolSiniflandirmaRaporAciklama[0].seviyeAciklama3Id : null)), "Guid"),
				RaporAciklama_3: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "protocol3_592190_value", "value", "", "", "")), "string"),
				ProtokolId_3: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.Id ?? this.props.screenInputs.id, "Guid"),
				SinifSeviyeId_3: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? stateVars.ProtokolSiniflandirmaRaporAciklama[0].seviye3Id : this.state.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? this.state.ProtokolSiniflandirmaRaporAciklama[0].seviye3Id : null)), "Guid"),
				Id_4: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? stateVars.ProtokolSiniflandirmaRaporAciklama[0].seviyeAciklama4Id : this.state.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? this.state.ProtokolSiniflandirmaRaporAciklama[0].seviyeAciklama4Id : null)), "Guid"),
				RaporAciklama_4: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "protocol3_308696_value", "value", "", "", "")), "string"),
				ProtokolId_4: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.Id ?? this.props.screenInputs.id, "Guid"),
				SinifSeviyeId_4: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? stateVars.ProtokolSiniflandirmaRaporAciklama[0].seviye4Id : this.state.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? this.state.ProtokolSiniflandirmaRaporAciklama[0].seviye4Id : null)), "Guid"),
				Id_5: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? stateVars.ProtokolSiniflandirmaRaporAciklama[0].seviyeAciklama5Id : this.state.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? this.state.ProtokolSiniflandirmaRaporAciklama[0].seviyeAciklama5Id : null)), "Guid"),
				RaporAciklama_5: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "protocol3_16146_value", "value", "", "", "")), "string"),
				ProtokolId_5: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.Id ?? this.props.screenInputs.id, "Guid"),
				SinifSeviyeId_5: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? stateVars.ProtokolSiniflandirmaRaporAciklama[0].seviye5Id : this.state.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? this.state.ProtokolSiniflandirmaRaporAciklama[0].seviye5Id : null)), "Guid"),
				Id_6: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? stateVars.ProtokolSiniflandirmaRaporAciklama[0].seviyeAciklama6Id : this.state.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? this.state.ProtokolSiniflandirmaRaporAciklama[0].seviyeAciklama6Id : null)), "Guid"),
				RaporAciklama_6: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "protocol3_317609_value", "value", "", "", "")), "string"),
				ProtokolId_6: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.Id ?? this.props.screenInputs.id, "Guid"),
				SinifSeviyeId_6: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? stateVars.ProtokolSiniflandirmaRaporAciklama[0].seviye6Id : this.state.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? this.state.ProtokolSiniflandirmaRaporAciklama[0].seviye6Id : null)), "Guid"),
				Id_7: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? stateVars.ProtokolSiniflandirmaRaporAciklama[0].seviyeAciklama7Id : this.state.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? this.state.ProtokolSiniflandirmaRaporAciklama[0].seviyeAciklama7Id : null)), "Guid"),
				RaporAciklama_7: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "protocol3_379240_value", "value", "", "", "")), "string"),
				ProtokolId_7: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.Id ?? this.props.screenInputs.id, "Guid"),
				SinifSeviyeId_7: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? stateVars.ProtokolSiniflandirmaRaporAciklama[0].seviye7Id : this.state.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? this.state.ProtokolSiniflandirmaRaporAciklama[0].seviye7Id : null)), "Guid"),
				Id_8: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? stateVars.ProtokolSiniflandirmaRaporAciklama[0].seviyeAciklama8Id : this.state.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? this.state.ProtokolSiniflandirmaRaporAciklama[0].seviyeAciklama8Id : null)), "Guid"),
				RaporAciklama_8: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "protocol3_531390_value", "value", "", "", "")), "string"),
				ProtokolId_8: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.Id ?? this.props.screenInputs.id, "Guid"),
				SinifSeviyeId_8: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? stateVars.ProtokolSiniflandirmaRaporAciklama[0].seviye8Id : this.state.ProtokolSiniflandirmaRaporAciklama?.length > 0 ? this.state.ProtokolSiniflandirmaRaporAciklama[0].seviye8Id : null)), "Guid")
        }

		KuikaAppManager.showSpinner(this);
		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "Protocol3/Protocol3Component_9544_onClick", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.SaveRecord = result?.data.saveRecord_1;
			stateVars.SaveRecord = result?.data.saveRecord_2;
			stateVars.SaveRecord = result?.data.saveRecord_3;
			stateVars.SaveRecord = result?.data.saveRecord_4;
			stateVars.SaveRecord = result?.data.saveRecord_5;
			stateVars.SaveRecord = result?.data.saveRecord_6;
			stateVars.SaveRecord = result?.data.saveRecord_7;
			stateVars.SaveRecord = result?.data.saveRecord_8;
			stateVars.SaveRecord = result?.data.saveRecord_9;
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.Protocol3Component_9544_onClick1_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
		Protocol3Component_9544_onClick1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }

				stateVars.GoBack = await ReactSystemFunctions.goBack(this);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }






    async callCallbackFunction(diId) {
        let isErrorOccurred = false;

    }
}
