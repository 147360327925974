import { ButtonProps } from "antd/es/button";
import _ from "lodash";
import React, { PureComponent, ReactNode } from "react";
import { Button } from "../../../components/web-components";
import { CommonProps } from "../../../components/web-components/common/common-props";
import withCommonEvents from "../../../shared/hoc/with-common-events";
import { ReactSystemFunctions } from "../../../shared/utilty/react-system-functions";

interface IKFileDownloadProps extends ButtonProps {
  data?: string;
  widthType?: "fill" | "content";
  style?: React.CSSProperties;
  fileName?: string;
  label?: string;
}

interface IKFileDownloadState {}

class KFileDownload extends PureComponent<IKFileDownloadProps & CommonProps, IKFileDownloadState> {
  constructor(props: IKFileDownloadProps) {
    super(props);
    this.state = {};
  }

  handleClick = () => {
    ReactSystemFunctions.downloadFile(this.props.fileName, this.props.data);
  };

  getButtonProps = () => {
    const props = _.clone(this.props) as any;
    delete props.data;
    delete props.fileName;
    return props;
  };

  render(): ReactNode {
    const props = this.getButtonProps();
    return (
      <>
        <Button {...props} onClick={this.handleClick} />
      </>
    );
  }
}

const kFileDownload = withCommonEvents(KFileDownload);
export { kFileDownload as KFileDownload };
