import { setTimeout } from "worker-timers";
import { KuikaAppManager } from "../shared/utilty/kuika-app-manager";
import { ReactSystemFunctions } from "../shared/utilty/react-system-functions";

export interface IRedirectToOptions {
  openInNewTab: boolean;
}

declare let window: any;

class HelperService {
  static getClientSecret(): string {
    return "ac97c249d7b607c6375fadd291413b05246121c154a7412dea43054115129da8";
  }

  static redirectGotoStartingScreen = async (screen?: string) => {
    let screenName: string = ReactSystemFunctions.getScreenNameByActiveUserRoles();

    if (
      (screenName === "" || screenName === undefined) &&
      window.userInfo?.startingScreenId &&
      window.userInfo.startingScreenId.length > 0
    ) {
      screenName = ReactSystemFunctions.getScreenNameById(window.userInfo.startingScreenId);
    }

    if (screenName === "" || screenName === undefined) {
      HelperService.redirectToPath(screen ?? "/Dashboard");
    } else {
      HelperService.redirectToPath(`/${screenName}`);
    }
  };

  static gotoLogin() {
    HelperService.redirectToPath("/signin");
  }

  static redirectToPath(path: string, options?: IRedirectToOptions): void {
    const frontEndUrl = KuikaAppManager.GetFrontEndUrl();
    const isWebComponent = KuikaAppManager.IsWebComponent();
    HelperService.redirectToFullUrl(`${frontEndUrl}${isWebComponent === true ? "#" : ""}${path}`, options);
  }

  static redirectToFullUrl(url: string, options?: IRedirectToOptions): void {
    if (options?.openInNewTab) {
      window.open(url, "_blank", "noopener,noreferrer");
    } else {
      window.location.replace(url);
    }
  }

  static setCorrectTimer(
    timeout: number,
    iterationPerSecond: number,
    onIteration: (steps: number, count: number) => void,
    onCompleted: (steps: number, count: number) => void
  ): void {
    const steps: number = (timeout / 100) * (iterationPerSecond / 10);
    const speed: number = timeout / steps;
    const start: number = new Date().getTime();
    let count: number = 0;

    const instance = () => {
      count += 1;

      if (count === steps) {
        onCompleted(steps, count);
      } else {
        onIteration(steps, count);

        const diff: number = new Date().getTime() - start - count * speed;
        setTimeout(instance, speed - diff);
      }
    };

    setTimeout(instance, speed);
  }
}

export default HelperService;
