import { DateRangeInput, EventClickArg, EventSourceInput } from "@fullcalendar/core";
import allLocales from "@fullcalendar/core/locales-all";
import daygridPlugin from "@fullcalendar/daygrid";
import interactionPlugin, { DateClickArg } from "@fullcalendar/interaction";
import FullCalendar from "@fullcalendar/react";
import { Guid } from "guid-typescript";
import moment from "moment";
import React, { CSSProperties, PureComponent, ReactNode } from "react";
import withCommonEvents from "../../../shared/hoc/with-common-events";
import "./calendar.scss";

declare let window: Window & { [key: string]: any };
export interface ICalendarProps {
  className?: string;
  style?: CSSProperties;
  onClickDay?: () => void;
  onClickEvent?: () => void;
  onPanelChange?: () => void;
  options?: object[];
  rangeStart?: string;
  rangeEnd?: string;
  datavaluefield?: string;
  datatextfield?: string;
  datastartfield?: string;
  dataendfield?: string;
  datacolorfield?: string;
  defaultPanel?: string;
  displayEventTime?: boolean;
  value?: any;
}

interface ICalendarState {
  uniqueKey: Guid;
  currentDate?: string;
  currentEvent?: object;
}

class Calendar extends PureComponent<ICalendarProps, ICalendarState> {
  public calendarRef: React.RefObject<FullCalendar> = React.createRef();

  private memoizedDynamicCssResult = "";

  private panel: string = "month";

  private static readonly dashboardDataIdField?: string = "id";

  private static readonly dashboardDataTitleField?: string = "title";

  private static readonly dashboardDataStartField?: string = "start";

  private static readonly dashboardDataEndField?: string = "end";

  private static readonly dashboardDataColorField?: string = "color";

  private static readonly dashboardData = [
    {
      id: Guid.create().toString(),
      title: "Event 1",
      start: moment().subtract(2, "days").add(2, "hours").format("YYYY-MM-DD HH:mm:ss"),
      end: moment().add(2, "day").format("YYYY-MM-DD HH:mm:ss"),
      color: "#FFC300"
    },
    {
      id: Guid.create().toString(),
      title: "Event 2",
      start: moment().subtract(1, "days").add(7, "hours").format("YYYY-MM-DD HH:mm:ss"),
      end: moment().add(5, "day").format("YYYY-MM-DD HH:mm:ss"),
      color: "#4AA83A"
    }
  ];

  private readonly isDashboard: boolean;

  private dataIdField?: string;

  private dataTitleField?: string;

  private dataStartField?: string;

  private dataEndField?: string;

  private dataColorField?: string;

  constructor(props: ICalendarProps) {
    super(props);
    this.state = {
      uniqueKey: Guid.create(),
      currentDate: props.value?.currentDay ?? moment().format("YYYY-MM-DD"),
      currentEvent: props.value?.currentEvent ?? {}
    };

    this.isDashboard = window.kuika?.dashboardState === 1;

    this.initializePanelType();

    this.initializeFieldKeys();
  }

  private get calendarApi() {
    return this.calendarRef.current?.getApi();
  }

  private initializeFieldKeys = (): void => {
    if (this.isDashboard) {
      this.dataIdField = Calendar.dashboardDataIdField;
      this.dataTitleField = Calendar.dashboardDataTitleField;
      this.dataStartField = Calendar.dashboardDataStartField;
      this.dataEndField = Calendar.dashboardDataEndField;
      this.dataColorField = Calendar.dashboardDataColorField;
    } else {
      this.dataIdField = this.props.datavaluefield.toLowerCase();
      this.dataTitleField = this.props.datatextfield.toLowerCase();
      this.dataStartField = this.props.datastartfield.toLowerCase();
      this.dataEndField = this.props.dataendfield.toLowerCase();
      this.dataColorField = this.props.datacolorfield.toLowerCase();
    }
  };

  private initializePanelType = (): void => {
    const { defaultPanel } = this.props;
    if (defaultPanel !== "month" && defaultPanel !== "week" && defaultPanel !== "day") {
      this.panel = "month";
    } else {
      this.panel = defaultPanel;
    }
  };

  componentDidMount() {
    this.setDynamicStyle();
  }

  private getClassName = (): string => {
    let result = "";

    if (this.props.className) {
      result = `${this.props.className} `;
    }

    if (!this.state.uniqueKey) {
      return result;
    }

    result = `${result}calendar_${this.state.uniqueKey.toString()}`;
    return result;
  };

  getDynamicCss = (): string => {
    const { textDecoration } = this.props.style;
    const className: string = this.getClassName();
    if (!className || className.length === 0) {
      return "";
    }
    let result = `.${className.trim()} .fc-event-time {
      text-decoration: ${textDecoration || "none"};
      flex: none;
    }`;

    result += `.${className.trim()} .fc-h-event .fc-event-title,
    .${className.trim()} .fc-daygrid-dot-event .fc-event-title {
      text-decoration: ${textDecoration || "none"};
      white-space: normal;
      padding: 0 1px !important;
    }`;

    return result;
  };

  setDynamicStyle = () => {
    const uniquekey = this.state.uniqueKey?.toString();
    if (!uniquekey) {
      return;
    }
    const isDesignTime = window.kuika?.isDesignTime;
    if (this.memoizedDynamicCssResult !== "" && !isDesignTime) {
      return this.memoizedDynamicCssResult;
    }
    const dynamic_style = document.getElementById("dynamic_style");
    if (dynamic_style && dynamic_style.innerHTML?.indexOf(uniquekey) === -1) {
      const generatedCss = this.getDynamicCss();
      dynamic_style.innerHTML = `${dynamic_style.innerHTML}
        ${generatedCss}`;
      this.memoizedDynamicCssResult = generatedCss;
    }
  };

  private getStyle = (): CSSProperties => {
    const style = { ...this.props.style };

    if (style.color) delete style.color;
    if (style.fontSize) delete style.fontSize;
    if (style.lineHeight) delete style.lineHeight;

    return style;
  };

  private onClickDay = (dateClickArg: DateClickArg) => {
    this.setState({ currentDate: dateClickArg.dateStr }, () => {
      this.calendarRef.current?.getApi?.()?.select?.(dateClickArg.dateStr);
      this.props.onClickDay?.();
    });
  };

  private onClickEvent = (eventClickArg: EventClickArg) => {
    this.setState({ currentEvent: eventClickArg.event }, () => {
      this.props.onClickEvent?.();
    });
  };

  getCurrentDate = () => {
    return this.state.currentDate;
  };

  getPanel = () => {
    return this.panel;
  };

  setPanel = (panel?: string) => {
    if (this.panel === panel) return;

    this.panel = panel || "month";
    this.calendarApi.changeView(this.getView(panel));
    this.onPanelChange();
  };

  getCurrentDay = () => {
    const currentDate: string = this.getCurrentDate();

    if (currentDate) {
      return moment(currentDate).date();
    }
    return moment().date();
  };

  getCurrentMonth = () => {
    const currentDate: string = this.getCurrentDate();

    if (currentDate) {
      return moment(currentDate).month() + 1;
    }
  };

  getCurrentYear = () => {
    const currentDate: string = this.getCurrentDate();

    if (currentDate) {
      return moment(currentDate).year();
    }
  };

  getCurrentEvent = () => {
    return this.state.currentEvent;
  };

  getCurrentRangeStart = () => {
    if (this.calendarApi) return this.calendarApi.view.currentStart;

    return moment().startOf(this.panel as any);
  };

  getCurrentRangeEnd = () => {
    if (this.calendarApi) return this.calendarApi.view.currentEnd;

    return moment().endOf(this.panel as any);
  };

  private static lowerCaseObjectKeys(obj: object): object {
    return Object.fromEntries(Object.entries(obj).map(([k, v]) => [k.toLowerCase(), v]));
  }

  private getEvents = (): EventSourceInput => {
    const events: object[] = this.isDashboard ? Calendar.dashboardData : this.props.options ?? [];

    return events.map((item: object) => {
      const obj: object = Calendar.lowerCaseObjectKeys(item);
      return {
        id: obj[this.dataIdField.toLowerCase()],
        title: obj[this.dataTitleField.toLowerCase()],
        start: obj[this.dataStartField.toLowerCase()],
        end: obj[this.dataEndField.toLowerCase()],
        color: obj[this.dataColorField.toLowerCase()] ?? "#1677ff"
      };
    });
  };

  private getLocale = () => {
    let lang = localStorage.getItem("ml")?.split("_")[0];

    return lang || "en";
  };

  private onPanelChange = () => {
    this.props.onPanelChange?.();
  };

  private getValidRange = (): DateRangeInput => {
    return {
      start: this.props.rangeStart ? moment(this.props.rangeStart).format("YYYY-MM-DD") : undefined,
      end: this.props.rangeEnd ? moment(this.props.rangeEnd).format("YYYY-MM-DD") : undefined
    };
  };

  private getView = (panel?: string): string => {
    if (panel === "month") {
      return "dayGridMonth";
    }

    if (panel === "week") {
      return "dayGridWeek";
    }

    if (panel === "day") {
      return "dayGridDay";
    }

    return "dayGridMonth";
  };

  render(): ReactNode {
    const calendarLocale = this.getLocale();
    const buttonTexts = allLocales.find((locale) => locale.code === calendarLocale)?.buttonText;

    return (
      <div className={this.getClassName()} style={this.getStyle()}>
        <FullCalendar
          ref={this.calendarRef}
          plugins={[daygridPlugin, interactionPlugin]}
          firstDay={1}
          validRange={this.getValidRange()}
          initialView={this.getView(this.props.defaultPanel)}
          displayEventTime={this.props.displayEventTime}
          eventTimeFormat={{
            hour: "2-digit",
            minute: "2-digit",
            hour12: false
          }}
          locale={calendarLocale}
          locales={allLocales}
          events={this.getEvents()}
          customButtons={{
            prev: {
              click: () => {
                this.calendarApi?.prev();
                this.onPanelChange();
              }
            },
            next: {
              click: () => {
                this.calendarApi?.next();
                this.onPanelChange();
              }
            },
            dayGridMonth: {
              text: buttonTexts?.month || "month",
              click: () => {
                this.setPanel("month");
              }
            },
            dayGridWeek: {
              text: buttonTexts?.week || "week",
              click: () => {
                this.setPanel("week");
              }
            },
            dayGridDay: {
              text: buttonTexts?.day || "day",
              click: () => {
                this.setPanel("day");
              }
            }
          }}
          headerToolbar={{
            left: "prev,next",
            center: "title",
            right: "dayGridMonth,dayGridWeek,dayGridDay"
          }}
          dateClick={this.onClickDay}
          eventClick={this.onClickEvent}
          height={this.props.style?.height || "auto"}
        />
      </div>
    );
  }
}

const calendar = withCommonEvents(Calendar);
export { calendar as Calendar };
