import { Tooltip as AntdTooltip } from "antd/es";
import { Guid } from "guid-typescript";
import _ from "lodash";
import { PureComponent, ReactNode } from "react";
import styled, { css } from "styled-components";
import withCommonEvents from "../../../shared/hoc/with-common-events";
import { CommonProps } from "../common-props";

declare let window: any;

interface TooltipProps {
  children?: ReactNode;
  title: string;
  placement: string;
  style?: any;
}

interface TooltipState {
  uniqueKey: Guid;
}
class Tooltip extends PureComponent<TooltipProps & CommonProps, TooltipState> {
  constructor(props: TooltipProps) {
    super(props);
    this.state = {
      uniqueKey: Guid.create()
    };
  }

  getDynamicCss = (): string => {
    const className: string = this.getClassName();
    if (!className || className.length === 0) {
      return "";
    }
    let result = "";
    const backgroundColor = this.props.style?.backgroundColor;
    const { color, fontFamily, fontSize, fontWeight, letterSpacing, lineHeight, textTransform, textDecoration } =
      this.props.style;
    if (backgroundColor) {
      result += `.ant-tooltip-inner {
           background: ${backgroundColor} !important;
        }`;
      result += ` .ant-tooltip-arrow::before {
        background: ${backgroundColor} !important;
      }`;
    }
    if (
      color ||
      fontFamily ||
      fontSize ||
      fontWeight ||
      letterSpacing ||
      lineHeight ||
      textTransform ||
      textDecoration
    ) {
      result += ` .ant-tooltip-inner {`;
      if (color) result += `color: ${color} !important;`;
      if (fontFamily) result += `font-family: ${fontFamily} !important;`;
      if (fontSize) result += `font-size: ${fontSize} !important;`;
      if (fontWeight) result += `font-weight: ${fontWeight} !important;`;
      if (letterSpacing) result += `letter-spacing: ${letterSpacing} !important;`;
      if (lineHeight) result += `line-height: ${lineHeight} !important;`;
      if (textTransform) result += `text-transform: ${textTransform} !important;`;
      if (textDecoration) result += `text-decoration: ${textDecoration} !important;`;
      result += `}`;
    }

    return result;
  };

  getClassName = () => {
    let result = "";
    if (!this.state.uniqueKey) {
      return result;
    }
    result = `ktooltip_${this.state.uniqueKey.toString().substring(0, 8)}`;
    return result;
  };

  getProps = () => {
    const props: any = _.clone(this.props);
    const isDesignTime = window.kuika && window.kuika.isDesignTime;
    const isActive = window.kuika && window.kuika.activeKuikaComponent?.kuikaId === (this.props as any).kuikaid;
    if (props.className) {
      delete props.className;
    }
    if (isDesignTime && isActive) {
      props.open = isActive;
    }
    return props;
  };

  render(): ReactNode {
    return (
      <StyledTooltip {...this.getProps()} className={this.getClassName()} dynamicStyles={this.getDynamicCss()}>
        {this.props.children}
      </StyledTooltip>
    );
  }
}

const CustomTooltip = ({ className, children, ...restProps }) => (
  <AntdTooltip overlayClassName={className} {...restProps}>
    {children}
  </AntdTooltip>
);

const StyledTooltip = styled(CustomTooltip)`
  ${({ dynamicStyles }) => css`
    ${dynamicStyles}
  `}
`;

const tooltip = withCommonEvents(Tooltip);
export { tooltip as Tooltip };
