import { Col as AntdCol } from "antd/es";
import { ColProps as AntdColProps } from "antd/es/col";
import _ from "lodash";
import { PureComponent, ReactNode } from "react";
import withCommonEvents from "../../../shared/hoc/with-common-events";
import { CommonProps } from "../common/common-props";

declare let window: any;

type ColProps = AntdColProps;

interface ColState {}

class Col extends PureComponent<ColProps & CommonProps, ColState> {
  constructor(props: ColProps) {
    super(props);
    this.state = {};
  }

  // componentDidMount(): void {
  //   const node = ReactDOM.findDOMNode(this) as Element;
  //   if ((this.props.style?.textAlign as string) === "-webkit-right") {
  //     const colWrapper: HTMLElement = node?.querySelector(".kuika_responsiveColWrapper")
  //     const nonResponsiveWrapper: HTMLElement = colWrapper?.querySelector(":first-child")
  //     if (colWrapper && nonResponsiveWrapper?.classList.contains("kuika_nonResponsiveWrapper") && nonResponsiveWrapper?.getAttribute("kuikacomponentname") === "Image") {
  //       colWrapper.style.display = "inline-flex";
  //       colWrapper.style.justifyContent = "flex-end";
  //       colWrapper.style.flexDirection = "column";
  //       colWrapper.style.alignItems = "flex-end";
  //       nonResponsiveWrapper.style.width = "max-content";
  //       if (nonResponsiveWrapper.getAttribute("kuikablockstyle") === "2") {
  //         nonResponsiveWrapper.style.width = "100%";
  //       }
  //     }
  //   } else if ((this.props.style?.textAlign as string) === "-webkit-center") {
  //     const colWrapper: HTMLElement = node?.querySelector(".kuika_responsiveColWrapper")
  //     const nonResponsiveWrapper: HTMLElement = colWrapper?.querySelector(":first-child")
  //     if (colWrapper && nonResponsiveWrapper?.classList.contains("kuika_nonResponsiveWrapper") && nonResponsiveWrapper?.getAttribute("kuikacomponentname") === "Image") {
  //       colWrapper.style.display = "inline-flex";
  //       colWrapper.style.justifyContent = "center";
  //       colWrapper.style.flexDirection = "column";
  //       colWrapper.style.alignItems = "center";
  //       nonResponsiveWrapper.style.width = "max-content"
  //       if (nonResponsiveWrapper.getAttribute("kuikablockstyle") === "2") {
  //         nonResponsiveWrapper.style.width = "100%";
  //       }

  //     }
  //   }
  // }

  componentDidMount(): void {
    const isDesignTime = window.kuika?.isDesignTime;
    // const node = ReactDOM.findDOMNode(this) as Element;
    // if (this.props?.style?.alignItems !== "flex-start" && node && node.children && node.children.length > 0) {
    //   let element = node.children[0];
    //   if (!isDesignTime)
    //     element = node;
    //   KMainFunctions.handleAlignments(element, this.getStyle());
    //   const tabEl: HTMLElement = element?.querySelector("[kuikacomponentname='Tab']");
    //   if (tabEl && tabEl.style) {
    //     tabEl.style.display = "contents";
    //   }
    // }
  }

  getStyle = () => {
    let style: any = {};

    if (this.props.style) {
      style = _.clone(this.props.style);
    }

    if (this.props.showCursorPointer) {
      style.cursor = "pointer";
    }
    if (style.alignItems == "center") {
      style.alignSelf = "center";
    }
    if (style.alignItems == "flex-start") {
      style.alignSelf = "flex-start";
    }
    if (style.alignItems == "flex-end") {
      style.alignSelf = "flex-end";
    }
    // if (style.textAlign == "-webkit-right") {
    //   delete style.textAlign;
    //   style.display = "inline-flex";
    //   style.justifyContent = "right";
    //   style.width = "100%";
    // }
    // if (style.textAlign == "-webkit-center") {
    //   delete style.textAlign;
    //   style.display = "inline-flex";
    //   style.justifyContent = "center";
    //   style.width = "100%";
    // }

    return style;
  };

  render(): ReactNode {
    return (
      <>
        <AntdCol {...this.props} style={this.getStyle()}></AntdCol>
      </>
    );
  }
}

const col = withCommonEvents(Col);
export { col as Col };
