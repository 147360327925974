import { CardElement, Elements, ElementsConsumer } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";
import { PureComponent } from "react";
import { CommonProps } from "../common-props";
import "./styles.css";

interface StripeWrapperProps {
  loadStripeKey?: string;
  clientSecret?: string;
}

interface StripeWrapperState {
  stripePromise: any;
  isLoaded: any;
}

export class StripeCard extends PureComponent<StripeWrapperProps & CommonProps, StripeWrapperState> {
  constructor(props: StripeWrapperProps) {
    super(props);
    this.state = {
      stripePromise: undefined,
      isLoaded: false
    };
  }

  componentDidMount() {
    const stripePromise = loadStripe(this.props.loadStripeKey!);
    this.setState({ stripePromise, isLoaded: true });
  }

  confirmPayment = async (event: any, ctx: any) => {
    event.preventDefault();
    const { stripe } = ctx;
    const { elements } = ctx;
    await stripe
      .confirmPayment({
        elements,
        confirmParams: {
          // Make sure to change this to your payment completion page
          return_url: "http://localhost:3000/confirmed"
        },
        redirect: "if_required"
      })
      .then((result) => {
        if (!result.error) {
        }
      });
  };

  renderCard = (ctx) => {
    return (
      <>
        <form id="payment-form" onSubmit={(e) => this.confirmPayment(e, ctx)}>
          <CardElement />
          <button className="kuika-stripe-payment-button">Buy</button>
        </form>
      </>
    );
  };

  render() {
    if (this.state.stripePromise && this.props.clientSecret) {
      const { stripePromise } = this.state;
      return (
        <Elements stripe={stripePromise} options={{ clientSecret: this.props.clientSecret! }}>
          <ElementsConsumer>{(ctx: any) => this.renderCard(ctx)}</ElementsConsumer>
        </Elements>
      );
    }
    return <></>;
  }
}
