import { Guid } from "guid-typescript";
import _ from "lodash";
import { CSSProperties, PureComponent, ReactNode } from "react";
import withCommonEvents from "../../../shared/hoc/with-common-events";
import { CommonProps } from "../common-props";
import "./radio-group.scss";

interface IRadioGroupProps {
  value?: string;
  space?: number;
  selectedRadioColor?: string;
  unSelectedRadioColor?: string;
  direction?: "horizontal" | "vertical";
  style?: CSSProperties;
  onChange: (e?: string) => void;
  children?: any;
  options?: any[];
  datatextfield?: string;
  datavaluefield?: any;
}

interface IRadioGroupState {
  uniqueKey: Guid;
}

class RadioGroup extends PureComponent<IRadioGroupProps & CommonProps, IRadioGroupState> {
  constructor(props: IRadioGroupProps) {
    super(props);
    this.state = {
      uniqueKey: Guid.create()
    };
  }

  private getStyles = () => {
    const { style, space, direction } = _.cloneDeep(this.props);

    if (style) {
      if (style.display === "block") {
        style.display = "flex";
      } else {
        style.display = "inline-flex";
      }

      style.gap = space ?? "5px";

      if (direction === "horizontal") {
        style.flexDirection = "row";
        if ((style.textAlign as any) === "-webkit-center") {
          style.justifyContent = "center";
        } else if ((style.textAlign as any) === "-webkit-right") {
          style.justifyContent = "right";
        } else {
          style.justifyContent = "left";
        }
      } else {
        style.flexDirection = "column";
        style.justifyContent = style.alignItems;
        if ((style.textAlign as any) === "-webkit-center") {
          style.alignItems = "center";
        } else if ((style.textAlign as any) === "-webkit-right") {
          style.alignItems = "flex-end";
        } else if ((style.textAlign as any) === "-webkit-left") {
          style.alignItems = "flex-start";
        }
      }
    }

    return style;
  };

  private radio = (radioColor: string) => {
    return (
      <div className="kuika-radio-group__radio__svgs">
        <svg
          className="kuika-radio-group__radio__outer-svg"
          focusable="false"
          aria-hidden="true"
          width={20}
          height={20}
          viewBox="0 0 24 24"
          fill={radioColor}
        >
          <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"></path>
        </svg>

        <svg
          className="kuika-radio-group__radio__inner-svg"
          width={20}
          height={20}
          focusable="false"
          aria-hidden="true"
          viewBox="0 0 24 24"
          fill={radioColor}
        >
          <path d="M8.465 8.465C9.37 7.56 10.62 7 12 7C14.76 7 17 9.24 17 12C17 13.38 16.44 14.63 15.535 15.535C14.63 16.44 13.38 17 12 17C9.24 17 7 14.76 7 12C7 10.62 7.56 9.37 8.465 8.465Z"></path>
        </svg>
      </div>
    );
  };

  private getRadioElements = () => {
    return this.props.options?.map((option, i) => {
      const { uniqueKey } = this.state;
      const radioGroupElementId = uniqueKey.toString();
      const radioElementId = `${radioGroupElementId}__radio-${i}`;
      const optionKey = this.props.datavaluefield ? option[this.props.datavaluefield] : option.key;
      const optionText = this.props.datatextfield ? option[this.props.datatextfield] : option.text;

      const isChecked = this.props.value === optionKey;

      return (
        <span className="kuika-radio-group__radio">
          <input id={radioElementId} type="radio" value={optionKey} name={radioGroupElementId} checked={isChecked} />
          <label htmlFor={radioElementId}>
            {this.radio(
              !isChecked ? this.props.unSelectedRadioColor || "#616161" : this.props.selectedRadioColor || "#246eff"
            )}
            {optionText}
          </label>
        </span>
      );
    });
  };

  render(): ReactNode {
    return (
      <div
        className="kuika-radio-group"
        style={this.getStyles()}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          this.props.onChange(e.target.value);
        }}
      >
        {this.getRadioElements()}
      </div>
    );
  }
}

const radioGroup = withCommonEvents(RadioGroup);
export { radioGroup as RadioGroup };
