import React from "react";
import Axios, { AxiosResponse } from "axios";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { KNavigator } from "../../shared/hoc/with-history";
import { KContext } from "../../shared/hoc/with-context";
import { useLocation } from "react-router-dom";
import { FormInstance } from "antd/es/form";
import { Guid } from "guid-typescript";
import Swal from "sweetalert2";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import moment from "moment";
import { IValidationData } from "../../models/dto/validation-data";
import { IStripePayment } from "../../models/dto/stripe-payment";
import { IUserInfoDto } from "../../models/dto/user-info.dto";
import { UserService } from "../../services/user-service";
import _ from "lodash";
import mqtt, { MqttClient } from "mqtt";
import { IMqttResult } from "../../models/dto/mqtt.dto";

declare let window: any;

export interface ISinifYeniOgrenciSezon_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

export interface ISinifYeniOgrenciSezon_ScreenState {
    isSpinnerVisible: boolean;
    isPageVisible: boolean;
	isLeftMenuVisible: boolean;
    spinnerCount: number;
    selectedLanguage: string;
    isPageInitLoaded: false;
    isPageRecurringLoaded: false;
	MyContext: any[];
	MyContext_dummy: any[];
	Kirilim1SelectById: any[];
	Kirilim1SelectById_dummy: any[];
	AktifKirilim1AltKirilim2leri: any[];
	AktifKirilim1AltKirilim2leri_dummy: any[];
	ProfilByKisiIdAndRolename: any[];
	ProfilByKisiIdAndRolename_dummy: any[];
	SetValueOf: string;
	SetValueOf_dummy: string;
	SinifOgrenciEkle: any[];
	SinifOgrenciEkle_dummy: any[];
	RolePerKurumTipi: any[];
	RolePerKurumTipi_dummy: any[];
	Rol: any[];
	Rol_dummy: any[];
	IsSinifExisting: any[];
	IsSinifExisting_dummy: any[];
	GoBack: any;
	GoBack_dummy: any;
	SaveRecord: number;
	SaveRecord_dummy: number;
	isComp331748Visible: 'visible' | 'hidden';
	isComp272749Visible: 'visible' | 'hidden';
	isComp832432Visible: 'visible' | 'hidden';


}

export class SinifYeniOgrenciSezon_ScreenBase extends React.PureComponent<ISinifYeniOgrenciSezon_ScreenProps, any> {
	sinifyeniogrencisezon_975353_value_kuikaSelectBoxRef: React.RefObject<any>;
	sinifyeniogrencisezon_350368_value_kuikaTableRef: React.RefObject<any>;
	sinifyeniogrencisezon_753001_value_kuikaPaginationRef: React.RefObject<any>;
    ml=[{"Id":"423ed54a-d6f3-fccb-7d3e-72c7b24c8b7a","Name":"tr_TR","ShortName":"Turkish","IsDefault":true,"LanguagePhrases":[]},{"Id":"74503767-9450-4cae-9db5-729d038bcb36","Name":"en_US","ShortName":"English","LanguagePhrases":[]},{"Id":"3464cc9f-bf2d-49c0-a456-113bd48c7609","Name":"FixedValues","ShortName":"FixedValues","LanguagePhrases":[{"Id":212739,"PropertyName":"value","Value":"Yeni"},{"Id":925082,"PropertyName":"value","Value":":"},{"Id":975353,"PropertyName":"placeholder","Value":"Sınıf Seçin..."},{"Id":490663,"PropertyName":"value","Value":":"},{"Id":877278,"PropertyName":"value","Value":"Sınıfsız Öğrencileri Göster"},{"Id":710871,"PropertyName":"value","Value":"Sezon Dışı Sınıf Arama"},{"Id":456150,"PropertyName":"placeholder","Value":"Sınıf Arama..."},{"Id":702682,"PropertyName":"value","Value":"label"},{"Id":863981,"PropertyName":"placeholder","Value":"Arama..."},{"Id":350368,"PropertyName":"nodatafoundmessage","Value":"No data found."},{"Id":271780,"PropertyName":"value","Value":"[datafield:kirilim2]"},{"Id":131301,"PropertyName":"value","Value":"[datafield:kirilim1]"},{"Id":832432,"PropertyName":"title","Value":"Okul Numarası"},{"Id":968391,"PropertyName":"value","Value":"[datafield:okulnumarasi]"},{"Id":999517,"PropertyName":"title","Value":"Adı"},{"Id":11801,"PropertyName":"value","Value":"[datafield:adi]"},{"Id":533601,"PropertyName":"title","Value":"Soyadı"},{"Id":568408,"PropertyName":"value","Value":"[datafield:soyadi]"},{"Id":306783,"PropertyName":"title","Value":"Yaş"},{"Id":853913,"PropertyName":"value","Value":"[datafield:yas]"},{"Id":325548,"PropertyName":"title","Value":"Cinsiyet"},{"Id":220207,"PropertyName":"value","Value":"[datafield:cinsiyet]"}]}]
    defaultML = "tr_TR"
    
    screenHash = ""
    
    constructor(props) {
        super(props);
		this.sinifyeniogrencisezon_975353_value_kuikaSelectBoxRef = React.createRef();
		this.sinifyeniogrencisezon_350368_value_kuikaTableRef = React.createRef();
		this.sinifyeniogrencisezon_753001_value_kuikaPaginationRef = React.createRef();

        this.state = {
            isSpinnerVisible: false,
            isPageVisible: false,
            isLeftMenuVisible: false,
            spinnerCount: 0,
            selectedLanguage: "",
            isPageInitLoaded: false,
            isPageRecurringLoaded: false,
            	        MyContext: [],
	        Kirilim1SelectById: [],
	        AktifKirilim1AltKirilim2leri: [],
	        ProfilByKisiIdAndRolename: [],
	        SetValueOf: "",
	        SinifOgrenciEkle: [],
	        RolePerKurumTipi: [],
	        Rol: [],
	        IsSinifExisting: [],
	        GoBack: "",
	        SaveRecord: false,
	        isComp331748Visible: 'hidden',
	        isComp272749Visible: 'hidden',
	        isComp832432Visible: 'hidden',

            
        }

        this.state.isPageVisible = true;
        
    }

    async componentDidMount() {

        if (KuikaAppManager.isPageInitPrevented()) {
            KuikaAppManager.calculateAndSetBodyHeight("sinifyeniogrencisezon", "");
            return;
        }
        
        ReactSystemFunctions.validateToken()
        if (true){
            await this.SinifYeniOgrenciSezonPageInit();
        }
        
        
        
        KuikaAppManager.calculateAndSetBodyHeight("sinifyeniogrencisezon", "");
    }

    componentWillUnmount() {
        
        
        
    }

    

        
    componentDidUpdate= async (prevProps, prevState) => {
		KuikaAppManager.calculateAndSetBodyHeight("sinifyeniogrencisezon", ""    );
        if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
			await this.SinifYeniOgrenciSezonPageInit();
		}

        
	}

    fillFormInitValues() {
        this.props.form.setFieldsValue({
sinifyeniogrencisezon_975353_value: this.state.AktifKirilim1AltKirilim2leri?.at?.(0)?.id ?? undefined,
sinifyeniogrencisezon_20055_value: false
});

    }

      setPageInitLoaded = (value: boolean) => {
        this.setState({ isPageInitLoaded: value });
      }

      setPageRecurringLoaded = (value: boolean) => {
        this.setState({ isPageRecurringLoaded: value });
      }

      clearFieldValidation = (changedValues: any) => {
        const fieldName = Object.keys(changedValues)[0];
        if (fieldName) {
          this.props.form.setFields([
            {
              name: fieldName,
              errors: []
            }
          ]);
        }
      };

      

      

    SinifYeniOgrenciSezonPageInit = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }

        let localVar = {
			Id_1: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.Kirilim1ID ?? this.props.screenInputs.kirilim1id, "Guid"),
			kirilim1_2: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.Kirilim1ID ?? this.props.screenInputs.kirilim1id, "Guid"),
			kisiId_3: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.KisiId ?? this.props.screenInputs.kisiid, "Guid"),
			rolename_3: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.role ?? this.props.screenInputs.role, "string"),
			KurumId_3: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.kurumID ?? this.props.screenInputs.kurumid, "Guid")
        }

	KuikaAppManager.showSpinner(this);
	
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "SinifYeniOgrenciSezon/SinifYeniOgrenciSezonPageInit", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

		stateVars.MyContext = result?.data.myContext;
		formVars.sinifyeniogrencisezon_452234_value = ReactSystemFunctions.toString(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].kirilim1Adi : this.state.MyContext?.length > 0 ? this.state.MyContext[0].kirilim1Adi : null));
		formVars.sinifyeniogrencisezon_679368_value = ReactSystemFunctions.toString(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].kirilim2Adi : this.state.MyContext?.length > 0 ? this.state.MyContext[0].kirilim2Adi : null));
		formVars.sinifyeniogrencisezon_795781_value = ReactSystemFunctions.toString(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].kirilim1Adi : this.state.MyContext?.length > 0 ? this.state.MyContext[0].kirilim1Adi : null));
		stateVars.Kirilim1SelectById = result?.data.kirilim1SelectById;
		formVars.sinifyeniogrencisezon_954196_value = ReactSystemFunctions.toString(this, (stateVars.Kirilim1SelectById?.length > 0 ? stateVars.Kirilim1SelectById[0].tanim : this.state.Kirilim1SelectById?.length > 0 ? this.state.Kirilim1SelectById[0].tanim : null));
		stateVars.AktifKirilim1AltKirilim2leri = result?.data.aktifKirilim1AltKirilim2leri;
		
		formVars.sinifyeniogrencisezon_975353_value = (stateVars.AktifKirilim1AltKirilim2leri?.length > 0 ? stateVars.AktifKirilim1AltKirilim2leri[0].id : this.state.AktifKirilim1AltKirilim2leri?.length > 0 ? this.state.AktifKirilim1AltKirilim2leri[0].id : null);
		formVars.sinifyeniogrencisezon_975353_options = stateVars.AktifKirilim1AltKirilim2leri;
		stateVars.ProfilByKisiIdAndRolename = result?.data.profilByKisiIdAndRolename;
		this.props.form.setFieldsValue(formVars);
		
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.SinifYeniOgrenciSezonPageInit1_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
	SinifYeniOgrenciSezonPageInit1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }

			stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "sinifyeniogrencisezon_975353_value", ReactSystemFunctions.toString(this, (stateVars.AktifKirilim1AltKirilim2leri?.length > 0 ? stateVars.AktifKirilim1AltKirilim2leri[0].id : this.state.AktifKirilim1AltKirilim2leri?.length > 0 ? this.state.AktifKirilim1AltKirilim2leri[0].id : null)), null, "Id");

	this.props.form.setFieldsValue(formVars);
	
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.SinifYeniOgrenciSezonPageInit2_();
                  resolve();
                });
          })

            
            return isErrorOccurred;
        }
    SinifYeniOgrenciSezonPageInit2_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }

        let localVar = {
			currentRole_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].rolename : this.state.MyContext?.length > 0 ? this.state.MyContext[0].rolename : null)), "string"),
			currentProfileId_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].lastChosenProfileId : this.state.MyContext?.length > 0 ? this.state.MyContext[0].lastChosenProfileId : null)), "Guid"),
			roleFilter_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.role ?? this.props.screenInputs.role, "string"),
			kurumId_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].kurumId : this.state.MyContext?.length > 0 ? this.state.MyContext[0].kurumId : null)), "Guid"),
			kirilim2Id_0: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
			searchFor_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "sinifyeniogrencisezon_863981_value", "value", "", "", "")), "string"),
			currentpage_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "sinifyeniogrencisezon_753001_value", "current", "", "", "")), "number"),
			pagesize_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "sinifyeniogrencisezon_753001_value", "pageSize", "", "", "")), "number"),
			currentkirilim2ID_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "sinifyeniogrencisezon_975353_value", "value", "AktifKirilim1AltKirilim2leri", "id", "id")), "Guid"),
			kirilim2adi_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "sinifyeniogrencisezon_456150_value", "value", "", "", "")), "string"),
			sinifsizswitch_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "sinifyeniogrencisezon_20055_value", "value", "", "", "")), "boolean"),
			kirilim1ID_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.Kirilim1ID ?? this.props.screenInputs.kirilim1id, "Guid"),
			role_1: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.role ?? this.props.screenInputs.role, "string"),
			kurumTipi_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].kurumTipiKod : this.state.MyContext?.length > 0 ? this.state.MyContext[0].kurumTipiKod : null)), "string"),
			myRole_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].rolename : this.state.MyContext?.length > 0 ? this.state.MyContext[0].rolename : null)), "string"),
			lang_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].lang : this.state.MyContext?.length > 0 ? this.state.MyContext[0].lang : null)), "string"),
			lang_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].lang : this.state.MyContext?.length > 0 ? this.state.MyContext[0].lang : null)), "string"),
			rolename_3: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.role ?? this.props.screenInputs.role, "string"),
			kirilim2Adi_3: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].kirilim2Adi : this.state.MyContext?.length > 0 ? this.state.MyContext[0].kirilim2Adi : null)), "string")
        }

	
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "SinifYeniOgrenciSezon/SinifYeniOgrenciSezonPageInit2_", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

		stateVars.SinifOgrenciEkle = result?.data.sinifOgrenciEkle;
		
		stateVars.RolePerKurumTipi = result?.data.rolePerKurumTipi;
		formVars.sinifyeniogrencisezon_834413_value = ReactSystemFunctions.toString(this, (stateVars.RolePerKurumTipi?.length > 0 ? stateVars.RolePerKurumTipi[0].label : this.state.RolePerKurumTipi?.length > 0 ? this.state.RolePerKurumTipi[0].label : null));
		stateVars.Rol = result?.data.rol;
		stateVars.IsSinifExisting = result?.data.isSinifExisting;
		this.props.form.setFieldsValue(formVars);
		
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.SinifYeniOgrenciSezonPageInit3_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
	SinifYeniOgrenciSezonPageInit3_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }

			formVars.sinifyeniogrencisezon_834413_value = ReactSystemFunctions.toString(this, (stateVars.RolePerKurumTipi?.length > 0 ? stateVars.RolePerKurumTipi[0].label : this.state.RolePerKurumTipi?.length > 0 ? this.state.RolePerKurumTipi[0].label : null));

			formVars.sinifyeniogrencisezon_452234_value = ReactSystemFunctions.toString(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].kirilim1Adi : this.state.MyContext?.length > 0 ? this.state.MyContext[0].kirilim1Adi : null));

			formVars.sinifyeniogrencisezon_679368_value = ReactSystemFunctions.toString(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].kirilim2Adi : this.state.MyContext?.length > 0 ? this.state.MyContext[0].kirilim2Adi : null));

			formVars.sinifyeniogrencisezon_975353_value = ReactSystemFunctions.toString(this, (stateVars.AktifKirilim1AltKirilim2leri?.length > 0 ? stateVars.AktifKirilim1AltKirilim2leri[0].id : this.state.AktifKirilim1AltKirilim2leri?.length > 0 ? this.state.AktifKirilim1AltKirilim2leri[0].id : null));

			formVars.sinifyeniogrencisezon_975353_value = ReactSystemFunctions.toString(this, (stateVars.AktifKirilim1AltKirilim2leri?.length > 0 ? stateVars.AktifKirilim1AltKirilim2leri[0].id : this.state.AktifKirilim1AltKirilim2leri?.length > 0 ? this.state.AktifKirilim1AltKirilim2leri[0].id : null));

			stateVars.dataSource_975353 = this.state.AktifKirilim1AltKirilim2leri;
			stateVars.dataSource_975353 = this.state.AktifKirilim1AltKirilim2leri;
			formVars.sinifyeniogrencisezon_795781_value = ReactSystemFunctions.toString(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].kirilim1Adi : this.state.MyContext?.length > 0 ? this.state.MyContext[0].kirilim1Adi : null));

			formVars.sinifyeniogrencisezon_954196_value = ReactSystemFunctions.toString(this, (stateVars.Kirilim1SelectById?.length > 0 ? stateVars.Kirilim1SelectById[0].tanim : this.state.Kirilim1SelectById?.length > 0 ? this.state.Kirilim1SelectById[0].tanim : null));

			
			stateVars.dataSource_350368 = this.state.SinifOgrenciEkle;
			formVars.sinifyeniogrencisezon_331748_title = ReactSystemFunctions.toString(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].kirilim2Adi : this.state.MyContext?.length > 0 ? this.state.MyContext[0].kirilim2Adi : null));

			stateVars.isComp331748Visible = ((ReactSystemFunctions.isEqualTo(this.props.screenInputs.role ?? this.props.screenInputs.role, "Rol_Client")) === true ? "visible" : "hidden")
			formVars.sinifyeniogrencisezon_272749_title = ReactSystemFunctions.toString(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].kirilim1Adi : this.state.MyContext?.length > 0 ? this.state.MyContext[0].kirilim1Adi : null));

			stateVars.isComp272749Visible = ((ReactSystemFunctions.isEqualTo(this.props.screenInputs.role ?? this.props.screenInputs.role, "Rol_Client")) === true ? "visible" : "hidden")
			stateVars.isComp832432Visible = ((ReactSystemFunctions.isEqualTo(this.props.screenInputs.role ?? this.props.screenInputs.role, "Rol_Client")) === true ? "visible" : "hidden")
			formVars.sinifyeniogrencisezon_753001_total = ReactSystemFunctions.value(this, (stateVars.SinifOgrenciEkle?.length > 0 ? stateVars.SinifOgrenciEkle[0].personCount : this.state.SinifOgrenciEkle?.length > 0 ? this.state.SinifOgrenciEkle[0].personCount : null));


	this.props.form.setFieldsValue(formVars);
	this.setState(stateVars);
	KuikaAppManager.hideSpinner(this);

            this.setPageInitLoaded(true);
            return isErrorOccurred;
        }







		SinifYeniOgrenciSezonComponent_372921_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
KuikaAppManager.showSpinner(this);

				stateVars.GoBack = await ReactSystemFunctions.goBack(this);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }


    SinifYeniOgrenciSezonComponent_975353_onChange = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
		KuikaAppManager.increaseServerRequestCount();


        let localVar = {
				currentRole_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].rolename : this.state.MyContext?.length > 0 ? this.state.MyContext[0].rolename : null)), "string"),
				currentProfileId_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].lastChosenProfileId : this.state.MyContext?.length > 0 ? this.state.MyContext[0].lastChosenProfileId : null)), "Guid"),
				roleFilter_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.role ?? this.props.screenInputs.role, "string"),
				kurumId_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].kurumId : this.state.MyContext?.length > 0 ? this.state.MyContext[0].kurumId : null)), "Guid"),
				kirilim2Id_0: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
				searchFor_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "sinifyeniogrencisezon_863981_value", "value", "", "", "")), "string"),
				currentpage_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "sinifyeniogrencisezon_753001_value", "current", "", "", "")), "number"),
				pagesize_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "sinifyeniogrencisezon_753001_value", "pageSize", "", "", "")), "number"),
				currentkirilim2ID_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "sinifyeniogrencisezon_975353_value", "value", "AktifKirilim1AltKirilim2leri", "id", "id")), "Guid"),
				kirilim2adi_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "sinifyeniogrencisezon_456150_value", "value", "", "", "")), "string"),
				sinifsizswitch_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "sinifyeniogrencisezon_20055_value", "value", "", "", "")), "boolean"),
				kirilim1ID_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.Kirilim1ID ?? this.props.screenInputs.kirilim1id, "Guid")
        }

		KuikaAppManager.showSpinner(this);
		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "SinifYeniOgrenciSezon/SinifYeniOgrenciSezonComponent_975353_onChange", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.SinifOgrenciEkle = result?.data.sinifOgrenciEkle;
			
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.SinifYeniOgrenciSezonComponent_975353_onChange1_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
		SinifYeniOgrenciSezonComponent_975353_onChange1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }

				
				stateVars.dataSource_350368 = this.state.SinifOgrenciEkle;
				formVars.sinifyeniogrencisezon_753001_total = ReactSystemFunctions.value(this, (stateVars.SinifOgrenciEkle?.length > 0 ? stateVars.SinifOgrenciEkle[0].personCount : this.state.SinifOgrenciEkle?.length > 0 ? this.state.SinifOgrenciEkle[0].personCount : null));


		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }




    SinifYeniOgrenciSezonComponent_20055_onChange = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
		KuikaAppManager.increaseServerRequestCount();


        let localVar = {
				currentRole_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].rolename : this.state.MyContext?.length > 0 ? this.state.MyContext[0].rolename : null)), "string"),
				currentProfileId_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].lastChosenProfileId : this.state.MyContext?.length > 0 ? this.state.MyContext[0].lastChosenProfileId : null)), "Guid"),
				roleFilter_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.role ?? this.props.screenInputs.role, "string"),
				kurumId_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].kurumId : this.state.MyContext?.length > 0 ? this.state.MyContext[0].kurumId : null)), "Guid"),
				kirilim2Id_0: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
				searchFor_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "sinifyeniogrencisezon_863981_value", "value", "", "", "")), "string"),
				currentpage_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "sinifyeniogrencisezon_753001_value", "current", "", "", "")), "number"),
				pagesize_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "sinifyeniogrencisezon_753001_value", "pageSize", "", "", "")), "number"),
				currentkirilim2ID_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "sinifyeniogrencisezon_975353_value", "value", "AktifKirilim1AltKirilim2leri", "id", "id")), "Guid"),
				kirilim2adi_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "sinifyeniogrencisezon_456150_value", "value", "", "", "")), "string"),
				sinifsizswitch_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "sinifyeniogrencisezon_20055_value", "value", "", "", "")), "boolean"),
				kirilim1ID_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.Kirilim1ID ?? this.props.screenInputs.kirilim1id, "Guid")
        }

		KuikaAppManager.showSpinner(this);
		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "SinifYeniOgrenciSezon/SinifYeniOgrenciSezonComponent_20055_onChange", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.SinifOgrenciEkle = result?.data.sinifOgrenciEkle;
			
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.SinifYeniOgrenciSezonComponent_20055_onChange1_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
		SinifYeniOgrenciSezonComponent_20055_onChange1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }

				
				stateVars.dataSource_350368 = this.state.SinifOgrenciEkle;
				formVars.sinifyeniogrencisezon_753001_total = ReactSystemFunctions.value(this, (stateVars.SinifOgrenciEkle?.length > 0 ? stateVars.SinifOgrenciEkle[0].personCount : this.state.SinifOgrenciEkle?.length > 0 ? this.state.SinifOgrenciEkle[0].personCount : null));


		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }




    SinifYeniOgrenciSezonComponent_456150_onChange = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
		KuikaAppManager.increaseServerRequestCount();


        let localVar = {
				currentRole_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].rolename : this.state.MyContext?.length > 0 ? this.state.MyContext[0].rolename : null)), "string"),
				currentProfileId_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].lastChosenProfileId : this.state.MyContext?.length > 0 ? this.state.MyContext[0].lastChosenProfileId : null)), "Guid"),
				roleFilter_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.role ?? this.props.screenInputs.role, "string"),
				kurumId_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].kurumId : this.state.MyContext?.length > 0 ? this.state.MyContext[0].kurumId : null)), "Guid"),
				kirilim2Id_0: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
				searchFor_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "sinifyeniogrencisezon_863981_value", "value", "", "", "")), "string"),
				currentpage_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "sinifyeniogrencisezon_753001_value", "current", "", "", "")), "number"),
				pagesize_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "sinifyeniogrencisezon_753001_value", "pageSize", "", "", "")), "number"),
				currentkirilim2ID_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "sinifyeniogrencisezon_975353_value", "value", "AktifKirilim1AltKirilim2leri", "id", "id")), "Guid"),
				kirilim2adi_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "sinifyeniogrencisezon_456150_value", "value", "", "", "")), "string"),
				sinifsizswitch_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "sinifyeniogrencisezon_20055_value", "value", "", "", "")), "boolean"),
				kirilim1ID_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.Kirilim1ID ?? this.props.screenInputs.kirilim1id, "Guid")
        }

		KuikaAppManager.showSpinner(this);
		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "SinifYeniOgrenciSezon/SinifYeniOgrenciSezonComponent_456150_onChange", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.SinifOgrenciEkle = result?.data.sinifOgrenciEkle;
			
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.SinifYeniOgrenciSezonComponent_456150_onChange1_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
		SinifYeniOgrenciSezonComponent_456150_onChange1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }

				
				stateVars.dataSource_350368 = this.state.SinifOgrenciEkle;
				formVars.sinifyeniogrencisezon_753001_total = ReactSystemFunctions.value(this, (stateVars.SinifOgrenciEkle?.length > 0 ? stateVars.SinifOgrenciEkle[0].personCount : this.state.SinifOgrenciEkle?.length > 0 ? this.state.SinifOgrenciEkle[0].personCount : null));


		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }




    SinifYeniOgrenciSezonComponent_863981_onChange = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
		KuikaAppManager.increaseServerRequestCount();


        let localVar = {
				currentRole_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].rolename : this.state.MyContext?.length > 0 ? this.state.MyContext[0].rolename : null)), "string"),
				currentProfileId_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].lastChosenProfileId : this.state.MyContext?.length > 0 ? this.state.MyContext[0].lastChosenProfileId : null)), "Guid"),
				roleFilter_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.role ?? this.props.screenInputs.role, "string"),
				kurumId_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].kurumId : this.state.MyContext?.length > 0 ? this.state.MyContext[0].kurumId : null)), "Guid"),
				kirilim2Id_0: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
				searchFor_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "sinifyeniogrencisezon_863981_value", "value", "", "", "")), "string"),
				currentpage_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "sinifyeniogrencisezon_753001_value", "current", "", "", "")), "number"),
				pagesize_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "sinifyeniogrencisezon_753001_value", "pageSize", "", "", "")), "number"),
				currentkirilim2ID_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "sinifyeniogrencisezon_975353_value", "value", "AktifKirilim1AltKirilim2leri", "id", "id")), "Guid"),
				kirilim2adi_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "sinifyeniogrencisezon_456150_value", "value", "", "", "")), "string"),
				sinifsizswitch_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "sinifyeniogrencisezon_20055_value", "value", "", "", "")), "boolean"),
				kirilim1ID_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.Kirilim1ID ?? this.props.screenInputs.kirilim1id, "Guid")
        }

		KuikaAppManager.showSpinner(this);
		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "SinifYeniOgrenciSezon/SinifYeniOgrenciSezonComponent_863981_onChange", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.SinifOgrenciEkle = result?.data.sinifOgrenciEkle;
			
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.SinifYeniOgrenciSezonComponent_863981_onChange1_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
		SinifYeniOgrenciSezonComponent_863981_onChange1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }

				
				stateVars.dataSource_350368 = this.state.SinifOgrenciEkle;
				formVars.sinifyeniogrencisezon_753001_total = ReactSystemFunctions.value(this, (stateVars.SinifOgrenciEkle?.length > 0 ? stateVars.SinifOgrenciEkle[0].personCount : this.state.SinifOgrenciEkle?.length > 0 ? this.state.SinifOgrenciEkle[0].personCount : null));


		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }




    SinifYeniOgrenciSezonComponent_511637_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
		KuikaAppManager.increaseServerRequestCount();


        let localVar = {
				MevcutKirilim2Id_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "sinifyeniogrencisezon_975353_value", "value", "AktifKirilim1AltKirilim2leri", "id", "id")), "Guid"),
				Id_0_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, "sinifyeniogrencisezon_350368_value", "id"), "Guid"),
				Kirilim2Ids_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "sinifyeniogrencisezon_975353_value", "value", "AktifKirilim1AltKirilim2leri", "id", "id")), "string"),
				Id_0_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, "sinifyeniogrencisezon_350368_value", "profileId"), "Guid"),
				currentRole_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].rolename : this.state.MyContext?.length > 0 ? this.state.MyContext[0].rolename : null)), "string"),
				currentProfileId_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].lastChosenProfileId : this.state.MyContext?.length > 0 ? this.state.MyContext[0].lastChosenProfileId : null)), "Guid"),
				roleFilter_2: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.role ?? this.props.screenInputs.role, "string"),
				kurumId_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].kurumId : this.state.MyContext?.length > 0 ? this.state.MyContext[0].kurumId : null)), "Guid"),
				kirilim2Id_2: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
				searchFor_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "sinifyeniogrencisezon_863981_value", "value", "", "", "")), "string"),
				currentpage_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "sinifyeniogrencisezon_753001_value", "current", "", "", "")), "number"),
				pagesize_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "sinifyeniogrencisezon_753001_value", "pageSize", "", "", "")), "number"),
				currentkirilim2ID_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "sinifyeniogrencisezon_975353_value", "value", "AktifKirilim1AltKirilim2leri", "id", "id")), "Guid"),
				kirilim2adi_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "sinifyeniogrencisezon_456150_value", "value", "", "", "")), "string"),
				sinifsizswitch_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "sinifyeniogrencisezon_20055_value", "value", "", "", "")), "boolean"),
				kirilim1ID_2: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.Kirilim1ID ?? this.props.screenInputs.kirilim1id, "Guid")
        }

		KuikaAppManager.showSpinner(this);
		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "SinifYeniOgrenciSezon/SinifYeniOgrenciSezonComponent_511637_onClick", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.SaveRecord = result?.data.saveRecord_1;
			stateVars.SaveRecord = result?.data.saveRecord_2;
			stateVars.SinifOgrenciEkle = result?.data.sinifOgrenciEkle;
			
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.SinifYeniOgrenciSezonComponent_511637_onClick1_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
		SinifYeniOgrenciSezonComponent_511637_onClick1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }

				
				stateVars.dataSource_350368 = this.state.SinifOgrenciEkle;
				formVars.sinifyeniogrencisezon_753001_total = ReactSystemFunctions.value(this, (stateVars.SinifOgrenciEkle?.length > 0 ? stateVars.SinifOgrenciEkle[0].personCount : this.state.SinifOgrenciEkle?.length > 0 ? this.state.SinifOgrenciEkle[0].personCount : null));


		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }




    SinifYeniOgrenciSezonComponent_753001_onChange = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				currentRole_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].rolename : this.state.MyContext?.length > 0 ? this.state.MyContext[0].rolename : null)), "string"),
				currentProfileId_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].lastChosenProfileId : this.state.MyContext?.length > 0 ? this.state.MyContext[0].lastChosenProfileId : null)), "Guid"),
				roleFilter_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.role ?? this.props.screenInputs.role, "string"),
				kurumId_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, (stateVars.MyContext?.length > 0 ? stateVars.MyContext[0].kurumId : this.state.MyContext?.length > 0 ? this.state.MyContext[0].kurumId : null)), "Guid"),
				kirilim2Id_0: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
				searchFor_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "sinifyeniogrencisezon_863981_value", "value", "", "", "")), "string"),
				currentpage_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "sinifyeniogrencisezon_753001_value", "current", "", "", "")), "number"),
				pagesize_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "sinifyeniogrencisezon_753001_value", "pageSize", "", "", "")), "number"),
				currentkirilim2ID_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "sinifyeniogrencisezon_975353_value", "value", "AktifKirilim1AltKirilim2leri", "id", "id")), "Guid"),
				kirilim2adi_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "sinifyeniogrencisezon_456150_value", "value", "", "", "")), "string"),
				sinifsizswitch_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "sinifyeniogrencisezon_20055_value", "value", "", "", "")), "boolean"),
				kirilim1ID_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.Kirilim1ID ?? this.props.screenInputs.kirilim1id, "Guid")
        }

		KuikaAppManager.showSpinner(this);
		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "SinifYeniOgrenciSezon/SinifYeniOgrenciSezonComponent_753001_onChange", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.SinifOgrenciEkle = result?.data.sinifOgrenciEkle;
			
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.SinifYeniOgrenciSezonComponent_753001_onChange1_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
		SinifYeniOgrenciSezonComponent_753001_onChange1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }

				
				stateVars.dataSource_350368 = this.state.SinifOgrenciEkle;
				formVars.sinifyeniogrencisezon_753001_total = ReactSystemFunctions.value(this, (stateVars.SinifOgrenciEkle?.length > 0 ? stateVars.SinifOgrenciEkle[0].personCount : this.state.SinifOgrenciEkle?.length > 0 ? this.state.SinifOgrenciEkle[0].personCount : null));


		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }






    async callCallbackFunction(diId) {
        let isErrorOccurred = false;

    }
}
