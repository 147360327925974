import MDEditor from "@uiw/react-md-editor";
import { Guid } from "guid-typescript";
import { PureComponent, ReactNode } from "react";
import { CommonProps } from "../../components/web-components/common/common-props";
import { ImageLibraryService } from "../../services/image-library-service";
import withCommonEvents from "../../shared/hoc/with-common-events";

interface IKMDEditorProps {
  value: string;
  onChange?: (value: string) => void;
}

interface IKMDEditorState {
  content: string;
  tempBase64: ITempBase64[];
}

export interface ITempBase64 {
  id: string;
  content: string;
}

class KMDEditor extends PureComponent<IKMDEditorProps & CommonProps, IKMDEditorState> {
  refKey: Guid = Guid.create();

  constructor(props: IKMDEditorProps) {
    super(props);
    this.state = {
      content: "",
      tempBase64: []
    };
  }

  clickAddImageButton = (e: any) => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = ".png, .jpg, .svg";

    input.onchange = (e: any) => {
      const file = e.target.files[0];

      const reader = new FileReader();

      reader.onload = (readerEvent: any) => {
        const result = reader.result?.toString();
        if (result) {
          this.updateImageBase64(this.state.content, result);
        }
      };
      reader.readAsDataURL(file);
    };

    input.click();
  };

  clickHandleEditOrPreview = (val: string) => {
    if (val === "edit") {
      this.handleEdit();
    } else if (val === "preview") {
      this.handlePreview();
    }
  };

  handleEdit = () => {};

  handlePreview = () => {};

  componentDidMount = () => {
    const addImageButton = window.document.evaluate(
      `//*[@id="${this.refKey}"]/div/div[1]/ul/li[12]/button`,
      document,
      null,
      XPathResult.FIRST_ORDERED_NODE_TYPE,
      null
    ).singleNodeValue;
    const editButton = window.document.evaluate(
      `//*[@id="${this.refKey}"]/div/div[1]/ul/li[18]/button`,
      document,
      null,
      XPathResult.FIRST_ORDERED_NODE_TYPE,
      null
    ).singleNodeValue;
    const previewButton = window.document.evaluate(
      `//*[@id="${this.refKey}"]/div/div[1]/ul/li[20]/button`,
      document,
      null,
      XPathResult.FIRST_ORDERED_NODE_TYPE,
      null
    ).singleNodeValue;

    if (addImageButton) {
      addImageButton.addEventListener("click", this.clickAddImageButton);
    }
    if (editButton) {
      editButton.addEventListener("click", () => this.clickHandleEditOrPreview("edit"));
    }
    if (previewButton) {
      previewButton.addEventListener("click", () => this.clickHandleEditOrPreview("preview"));
    }
  };

  componentWillUnmount = () => {
    const addImageButton = window.document.evaluate(
      `//*[@id="${this.refKey}"]/div/div[1]/ul/li[12]/button`,
      document,
      null,
      XPathResult.FIRST_ORDERED_NODE_TYPE,
      null
    ).singleNodeValue;
    if (addImageButton) {
      addImageButton.removeEventListener("click", this.clickAddImageButton);
    }
  };

  handleChangeEditor = (value?: string) => {
    if (value) {
      this.setState({ content: value }, () => {
        if (this.props.onChange) {
          this.props.onChange(value);
        }
      });
    }
  };

  updateImageBase64 = (value: string, image: string) => {
    if (value) {
      const searchString = "https://example.com/your-image.png";
      if (this.state.content.indexOf(searchString) > -1 && image.length > 0) {
        const tempObject = {} as ITempBase64;
        tempObject.id = Guid.create().toString();
        tempObject.content = image;
        ImageLibraryService.SaveResource(tempObject);
        value = value.replace(searchString, `{KUIKARESOURCES/}${tempObject.id}`);
        this.setState({ content: value, tempBase64: [...this.state.tempBase64, tempObject] });
        if (this.props.onChange) {
          this.props.onChange(value);
        }
      }
    }
  };

  render(): ReactNode {
    return (
      <>
        <div id={this.refKey.toString()}>
          <MDEditor
            {...this.props}
            onChange={this.handleChangeEditor}
            value={this.state.content ? this.state.content : this.props?.value}
          ></MDEditor>
        </div>
      </>
    );
  }
}

const kMDEditor = withCommonEvents(KMDEditor);
export { kMDEditor as KMDEditor };
