import withCommonEvents from "../../../shared/hoc/with-common-events";
import { BaseTable, ITableProps } from "./base-table";
import { GalleryBodyWrapperProps } from "./body/gallery/gallery-body-wrapper";
import { GalleryItemProps } from "./body/gallery/gallery-item";

class Gallery extends BaseTable<GalleryItemProps & GalleryBodyWrapperProps, any> {
  constructor(props: ITableProps & GalleryBodyWrapperProps) {
    super(props);
  }

  public static defaultProps = {
    mode: "gallery",
    showHeader: false,
    designClicked: false
  };
}

const gallery = withCommonEvents(Gallery);
export { gallery as Gallery };
