import { PureComponent, ReactNode } from "react";
import { CommonProps } from "../../../common/common-props";

export interface GoogleMapsMarkerPopupProps extends CommonProps {
  markerDetailHeight?: number;
  markerDetailWidth?: number;
}

interface GoogleMapsMarkerPopupState {}

export class GoogleMapsMarkerPopup extends PureComponent<GoogleMapsMarkerPopupProps, GoogleMapsMarkerPopupState> {
  constructor(props: GoogleMapsMarkerPopupProps) {
    super(props);
  }

  render(): ReactNode {
    const { markerDetailWidth, markerDetailHeight: height } = this.props;
    const width = markerDetailWidth ? markerDetailWidth + 32 : undefined;

    return <div style={{ width, height }} {...this.props} />;
  }
}
