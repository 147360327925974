import * as React from "react";
import { ParQFormcopy_ScreenBase } from "./parqformcopy-base";
import { Spacer, KPassword, KSteps, KStep, KLabel, KNumericbox, KButton, KToggle, KCheckbox, KMonthPicker, KDatatimePicker, KRating, KSelectBox, KMultiSelect, KTextarea, KTextbox, KFileDownload, KFilePicker, KLanguageSelect, KLocationSelect, KLocationDisplay, KLocationPicker, KSignature, KIcon, KMDEditor, KMDViewer, KRadio, KRadioGroup, KCalendar, KIFrame, KAvatarMenu, KAvatarMenuItem, KSpace, KSlider, KRadioButton, KThumbnail } from "../../kuika";
import { MfeContainer, TextArea, WebView, Icon, Label, Signature, HorizontalStack, VerticalStack, LanguageSelector, MDViewer, QRRenderer, BarcodeRenderer, Divider, MDEditor, Button, Rating, Percent, Currency, NumberInput, BarcodeInput, BarcodeInput, Password, TextInput, Image, Email, AreaChart, BarChart, DonutChart, GaugeChart, LineChart, PieChart, Header, Footer, Switch, Url, CountDown, ProgressCircle, ProgressBar, CollapsePanel, Collapse, Slider, KAreaChart, KBarChart, KDonutChart, KGaugeChart, KLineChart, KPieChart, KFooter, KHeader, KRow, KCol, KTab, KTabItem, KTable, KTableHeader, KTableRow, KTableColumn, KGallery, KPagination, KDrawer, KModal, KVideo, KCarousel, KFlexGrid, KPanel, KDropDown, KDropDownItem, KContentMenu, KContentMenuItem, GoogleMaps, GoogleMapsMarker, GoogleMapsMarkerPopup, KContentMenuPanel, KBadge, Badge, RangeSlider, Checkbox, Chat, RangeDate, RangeDateTime, Stopwatch, PlacesAutocompleteInput, CronMaker, SingleSelect, MultiSelect, AutoComplete, StripeCard, PaymentElementStripe, CountUp, Tree, KDatePicker, YearPicker, Tooltip, Calendar, LayoutHeader, LayoutFooter, LayoutLeft, LayoutRight, LocationPicker, Timeline, Stepper, StepperItem, Menu, MenuItem, SubMenu, RadialChart, MixedChart, MixedChartLineItem, MixedChartPointItem, ChartLabel, KanbanBoard, ProcessAutomation, RadioGroup, BigFileUpload, PivotTable, ColorPicker } from "../../components/web-components";
import { Drawer, Form, Spin, Typography } from "antd/es";
import { withGoogleLogin } from "../../shared/hoc/with-google-login";
import { withHistory } from "../../shared/hoc/with-history";
import { withForm } from "../../shared/hoc/with-form";
import { withScreenInput } from "../../shared/hoc/with-screen-input";
import { withContext } from "../../shared/hoc/with-context";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { showPhotoTake, hidePhotoTake, showStripeDrawer, hideStripeDrawer,showIyzicoDrawer, hideIyzicoDrawer, showImportMappedExcelModal, hideImportMappedExcelModal } from "../../redux/modals/actions";
import { AppState} from "../../redux/root-reducer";
import { connect } from "react-redux";
import moment from "moment";




declare let window: any;
const { Title, Paragraph, Text, Link } = Typography;
const menuMap = [

];

class ParQFormcopy_Screen extends ParQFormcopy_ScreenBase
{
    render() {
        if (this.state.isPageVisible === false) {return (<></>);}
        return (
            <>
              
                <Spin spinning={this.state.spinnerCount > 0}>
                    
                    <Form form={this.props.form} name="basic"
                        initialValues={
                            {parqformcopy_768789_value: "cea5d764-20eb-47e9-b3a0-08dc41da3488",
							parqformcopy_839743_value: "6198ce14-f34a-4e34-b3a1-08dc41da3488",
							parqformcopy_68315_value: this.state.FormParQById?.at?.(0)?.s1x1 ?? undefined,
							parqformcopy_183279_value: this.state.FormParQById?.at?.(0)?.s1x2 ?? undefined,
							parqformcopy_790913_value: this.state.FormParQById?.at?.(0)?.s1x3 ?? undefined,
							parqformcopy_43178_value: this.state.FormParQById?.at?.(0)?.s1x3x1 ?? undefined,
							parqformcopy_567403_value: this.state.FormParQById?.at?.(0)?.s1x3x2 ?? undefined,
							parqformcopy_235714_value: this.state.FormParQById?.at?.(-1)?.s1x3x3 ?? undefined,
							parqformcopy_485437_value: this.state.FormParQById?.at?.(0)?.s2x1 ?? undefined,
							parqformcopy_960012_value: this.state.FormParQById?.at?.(0)?.s2x2 ?? undefined,
							parqformcopy_158970_value: this.state.FormParQById?.at?.(0)?.s2x3 ?? undefined,
							parqformcopy_107454_value: this.state.FormParQById?.at?.(0)?.s2x4 ?? undefined,
							parqformcopy_173410_value: this.state.FormParQById?.at?.(0)?.s2x5 ?? undefined,
							parqformcopy_643202_value: this.state.FormParQById?.at?.(0)?.s2x6 ?? undefined,
							parqformcopy_538933_value: this.state.FormParQById?.at?.(0)?.s2x7 ?? undefined,
							parqformcopy_721461_value: this.state.FormParQById?.at?.(0)?.s2x8 ?? undefined,
							parqformcopy_238402_value: this.state.FormParQById?.at?.(0)?.s3 ?? undefined,
							parqformcopy_673903_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x3 ?? undefined,
							parqformcopy_63995_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x1xIlac ?? undefined,
							parqformcopy_146506_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x1x1 ?? undefined,
							parqformcopy_631046_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x3 ?? undefined,
							parqformcopy_355803_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x2xIlac ?? undefined,
							parqformcopy_552938_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x2x1 ?? undefined,
							parqformcopy_815856_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x3 ?? undefined,
							parqformcopy_827266_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x3xIlac ?? undefined,
							parqformcopy_441666_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x3x1 ?? undefined,
							parqformcopy_301053_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x4 ?? undefined,
							parqformcopy_425116_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x4xIlac ?? undefined,
							parqformcopy_366768_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x4x1 ?? undefined,
							parqformcopy_316213_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x5 ?? undefined,
							parqformcopy_360226_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x5xIlac ?? undefined,
							parqformcopy_394297_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x5x1 ?? undefined,
							parqformcopy_395667_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x6 ?? undefined,
							parqformcopy_951810_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x6xIlac ?? undefined,
							parqformcopy_503137_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x6x1 ?? undefined,
							parqformcopy_858125_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x7 ?? undefined,
							parqformcopy_2796_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x7xIlac ?? undefined,
							parqformcopy_875104_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x7x1 ?? undefined,
							parqformcopy_985460_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x8 ?? undefined,
							parqformcopy_875505_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x8xIlac ?? undefined,
							parqformcopy_202020_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x8x1 ?? undefined,
							parqformcopy_113946_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x9 ?? undefined,
							parqformcopy_47848_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x9xIlac ?? undefined,
							parqformcopy_410924_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x9x1 ?? undefined,
							parqformcopy_221532_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x10 ?? undefined,
							parqformcopy_35554_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x10xBoy ?? undefined,
							parqformcopy_84142_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x10xKilo ?? undefined,
							parqformcopy_173392_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x10xIlac ?? undefined,
							parqformcopy_813285_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x10x1 ?? undefined,
							parqformcopy_245992_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x11 ?? undefined,
							parqformcopy_981775_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x11xTK ?? undefined,
							parqformcopy_138481_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x11xHDL ?? undefined,
							parqformcopy_816505_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x11xLDL ?? undefined,
							parqformcopy_337464_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x11xResult ?? undefined,
							parqformcopy_421543_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x11xIlac ?? undefined,
							parqformcopy_751235_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x11x1 ?? undefined,
							parqformcopy_137075_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x12 ?? undefined,
							parqformcopy_763635_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x12xIlac ?? undefined,
							parqformcopy_492488_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x12x1 ?? undefined,
							parqformcopy_683570_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x13 ?? undefined,
							parqformcopy_17076_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x13xIlac ?? undefined,
							parqformcopy_56334_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x13x1 ?? undefined,
							parqformcopy_485590_value: this.state.FormParQById?.at?.(0)?.s4x1 ?? undefined,
							parqformcopy_527506_value: this.state.FormParQById?.at?.(0)?.s4x1x1 ?? undefined,
							parqformcopy_139967_value: this.state.FormParQById?.at?.(0)?.s4x1x2 ?? undefined,
							parqformcopy_426278_value: this.state.FormParQById?.at?.(0)?.s4x1x3 ?? undefined,
							parqformcopy_925296_value: this.state.FormParQById?.at?.(0)?.s4x2 ?? undefined,
							parqformcopy_726714_value: this.state.FormParQById?.at?.(0)?.s4x2x1 ?? undefined,
							parqformcopy_289204_value: this.state.FormParQById?.at?.(0)?.s4x2x2 ?? undefined,
							parqformcopy_752131_value: this.state.FormParQById?.at?.(0)?.s4x2x3 ?? undefined,
							parqformcopy_966189_value: this.state.FormParQById?.at?.(0)?.s4xEfor ?? undefined,
							parqformcopy_135425_value: this.state.FormParQById?.at?.(0)?.s4xEforxProblem ?? undefined,
							parqformcopy_382369_value: this.state.FormParQById?.at?.(0)?.s5 ?? undefined,
							parqformcopy_477451_value: this.state.FormParQById?.at?.(0)?.s5X1Check ?? undefined,
							parqformcopy_103815_value: this.state.FormParQById?.at?.(0)?.s5X1 ?? undefined,
							parqformcopy_75034_value: this.state.FormParQById?.at?.(0)?.s5X2Check ?? undefined,
							parqformcopy_654161_value: this.state.FormParQById?.at?.(0)?.s5x2 ?? undefined,
							parqformcopy_85355_value: this.state.FormParQById?.at?.(0)?.s5X3Check ?? undefined,
							parqformcopy_445930_value: this.state.FormParQById?.at?.(0)?.s5x3 ?? undefined,
							parqformcopy_38196_value: this.state.FormParQById?.at?.(0)?.s5X4Check ?? undefined,
							parqformcopy_342330_value: this.state.FormParQById?.at?.(0)?.s5x4 ?? undefined,
							parqformcopy_918726_value: this.state.FormParQById?.at?.(0)?.s5X5Check ?? undefined,
							parqformcopy_339185_value: this.state.FormParQById?.at?.(0)?.s5x5 ?? undefined,
							parqformcopy_930924_value: this.state.FormParQById?.at?.(0)?.s6 ?? undefined,
							parqformcopy_666388_value: false,
							parqformcopy_713132_value: this.state.FormParQSoru6ById?.at?.(0)?.s6x1x1 ?? undefined,
							parqformcopy_154499_value: this.state.FormParQSoru6ById?.at?.(0)?.s6x1x2 ?? undefined,
							parqformcopy_867208_value: this.state.FormParQSoru6ById?.at?.(0)?.s6x1x3 ?? undefined,
							parqformcopy_622758_value: this.state.FormParQSoru6ById?.at?.(0)?.s6x1x4 ?? undefined,
							parqformcopy_590152_value: this.state.FormParQSoru6ById?.at?.(0)?.s6x1x5 ?? undefined,
							parqformcopy_700493_value: this.state.FormParQSoru6ById?.at?.(0)?.s6x2 ?? undefined,
							parqformcopy_528451_value: this.state.FormParQSoru6ById?.at?.(0)?.s6x2x1 ?? undefined,
							parqformcopy_581396_value: this.state.FormParQSoru6ById?.at?.(0)?.s6x2x2 ?? undefined,
							parqformcopy_165036_value: this.state.FormParQSoru6ById?.at?.(0)?.s6x2x3 ?? undefined,
							parqformcopy_996331_value: this.state.FormParQSoru6ById?.at?.(0)?.s6x2x4 ?? undefined,
							parqformcopy_761897_value: this.state.FormParQSoru6ById?.at?.(0)?.s6x2x5 ?? undefined,
							parqformcopy_782070_value: this.state.FormParQSoru6ById?.at?.(0)?.s6x2x6 ?? undefined,
							parqformcopy_590758_value: this.state.FormParQSoru6ById?.at?.(0)?.s6x2x7 ?? undefined,
							parqformcopy_847131_value: this.state.FormParQSoru6ById?.at?.(0)?.s6x3 ?? undefined,
							parqformcopy_996970_value: this.state.FormParQSoru6ById?.at?.(0)?.s6x3x1 ?? undefined,
							parqformcopy_736647_value: this.state.FormParQSoru6ById?.at?.(0)?.s6x3x2 ?? undefined,
							parqformcopy_718707_value: this.state.FormParQSoru6ById?.at?.(0)?.s6x3x3 ?? undefined,
							parqformcopy_285485_value: this.state.FormParQSoru6ById?.at?.(0)?.s6x3x4 ?? undefined,
							parqformcopy_710319_value: this.state.FormParQSoru6ById?.at?.(0)?.s6x3x5 ?? undefined,
							parqformcopy_659579_value: this.state.FormParQSoru6ById?.at?.(0)?.s6x3x6 ?? undefined,
							parqformcopy_257868_value: this.state.FormParQSoru6ById?.at?.(0)?.s6x3x7 ?? undefined,
							parqformcopy_560619_value: this.state.FormParQSoru6ById?.at?.(0)?.s6x3x8 ?? undefined,
							parqformcopy_275784_value: this.state.FormParQSoru6ById?.at?.(0)?.s6x3x9 ?? undefined,
							parqformcopy_736437_value: this.state.FormParQSoru6ById?.at?.(0)?.s6x4 ?? undefined,
							parqformcopy_883656_value: this.state.FormParQSoru6ById?.at?.(0)?.s6x4x1 ?? undefined,
							parqformcopy_627907_value: this.state.FormParQSoru6ById?.at?.(0)?.s6x4x2 ?? undefined,
							parqformcopy_893733_value: this.state.FormParQSoru6ById?.at?.(0)?.s6x4x3 ?? undefined,
							parqformcopy_786497_value: this.state.FormParQSoru6ById?.at?.(0)?.s6x4x4 ?? undefined,
							parqformcopy_365318_value: this.state.FormParQSoru6ById?.at?.(0)?.s6x4x5 ?? undefined,
							parqformcopy_342249_value: this.state.FormParQSoru6ById?.at?.(0)?.s6x4x6 ?? undefined,
							parqformcopy_486661_value: this.state.FormParQSoru6ById?.at?.(0)?.sx6x5Check ?? undefined,
							parqformcopy_739962_value: this.state.FormParQSoru6ById?.at?.(0)?.s6x5 ?? undefined,
							parqformcopy_926688_value: this.state.FormParQById?.at?.(0)?.s7 ?? undefined,
							parqformcopy_74533_value: this.state.FormParQById?.at?.(0)?.s7x1 ?? undefined,
							parqformcopy_740586_value: this.state.FormParQById?.at?.(0)?.s7x2 ?? undefined,
							parqformcopy_216720_value: this.state.FormParQById?.at?.(0)?.s7x3xCheck ?? undefined,
							parqformcopy_357526_value: this.state.FormParQById?.at?.(0)?.s7x3 ?? undefined,
							parqformcopy_413414_value: this.state.FormParQById?.at?.(0)?.s8 ?? undefined,
							parqformcopy_673600_value: this.state.FormParQById?.at?.(0)?.s8xGunde ?? undefined,
							parqformcopy_161683_value: this.state.FormParQById?.at?.(0)?.s8xHaftada ?? undefined,
							parqformcopy_656623_value: this.state.FormParQById?.at?.(0)?.s8xKacSene ?? undefined,
							parqformcopy_92022_value: undefined,
							parqformcopy_974898_value: undefined
}
                        } onValuesChange={(changedValues, allValues) => {this.clearFieldValidation(changedValues);}}>
                        
<div id="parqformcopy_body" style={{"backgroundColor":"rgba(244, 244, 244, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","display":"block"} as any}>
<KRow id="129983" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="746496" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderTopLeftRadius":8,"borderTopRightRadius":8,"borderBottomRightRadius":8,"borderBottomLeftRadius":8,"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KRow id="358067" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderBottomWidth":1,"borderColor":"rgba(0, 0, 0, 1)","borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="342441" xs={4} sm={4} md={4} lg={4} xl={4} xxl={4} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"center","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="975017" value={ReactSystemFunctions.translate(this.ml, 975017, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":400,"fontSize":"18pt","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</KCol>

<KCol id="702083" xs={17} sm={17} md={17} lg={17} xl={17} xxl={17} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-end","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<HorizontalStack id="201510" direction="horizontal" size={12} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<HorizontalStack id="470865" direction="horizontal" size={12} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Form.Item className='kFormItem' name='parqformcopy_768789_value' >
<Image id="768789" visibility={this.state.isComp768789Visible} condition={(rowData) => ReactSystemFunctions.isEqualTo(this.state.KisiSelectById[0]?.cinsiyet?.toString(), "E")} zoomOnClick={false} imageFit="fit" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"height":"20px","alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Image>
</Form.Item>

<Form.Item className='kFormItem' name='parqformcopy_839743_value' >
<Image id="839743" visibility={this.state.isComp839743Visible} condition={(rowData) => ReactSystemFunctions.isEqualTo(this.state.KisiSelectById[0]?.cinsiyet?.toString(), "K")} zoomOnClick={false} imageFit="fit" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"height":"20px","alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Image>
</Form.Item>

<HorizontalStack id="916162" direction="horizontal" size={4} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":4,"paddingRight":0,"paddingBottom":4,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="791824" value={this.state.KisiSelectById?.at?.(0)?.adi ?? undefined} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(10, 33, 64, 1)"} as any}></Label>

<Label id="735964" value={this.state.KisiSelectById?.at?.(0)?.soyadi ?? undefined} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"14px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</HorizontalStack>

<HorizontalStack id="641407" direction="horizontal" size={2} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="686570" value={ReactSystemFunctions.translate(this.ml, 686570, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"10px","color":"rgba(10, 33, 64, 1)"} as any}></Label>

<Label id="676715" value={this.state.KisiSelectById?.at?.(0)?.yas ?? undefined} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"12px","color":"rgba(10, 33, 64, 1)"} as any}></Label>

<Label id="366411" value={ReactSystemFunctions.translate(this.ml, 366411, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"10px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</HorizontalStack>
</HorizontalStack>
</HorizontalStack>
</KCol>

<KCol id="868256" xs={3} sm={3} md={3} lg={3} xl={3} xxl={3} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"center","textAlign":"-webkit-right","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Icon id="993262" onClick={ (e?: any) => { if(e && e.stopPropagation) e.stopPropagation();this.ParQFormcopyComponent_993262_onClick()} } showCursorPointer iconName="close" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"20px","color":"rgba(155, 155, 155, 1)","letterSpacing":"1px"} as any}></Icon>
</KCol>
</KRow>
</KCol>
</KRow>

<KRow id="321830" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="910625" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderTopLeftRadius":8,"borderTopRightRadius":8,"borderBottomRightRadius":8,"borderBottomLeftRadius":8,"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KRow id="937108" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderBottomWidth":1,"borderColor":"rgba(0, 0, 0, 1)","borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="945783" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-center","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="123660" value={ReactSystemFunctions.translate(this.ml, 123660, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</KCol>
</KRow>

<KRow id="470454" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="292753" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="629566" value={ReactSystemFunctions.translate(this.ml, 629566, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</KCol>
</KRow>
</KCol>
</KRow>

<KRow id="995925" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="902116" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderTopLeftRadius":8,"borderTopRightRadius":8,"borderBottomRightRadius":8,"borderBottomLeftRadius":8,"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KRow id="438232" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderBottomWidth":1,"borderColor":"rgba(0, 0, 0, 1)","borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="225845" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="437761" value={ReactSystemFunctions.translate(this.ml, 437761, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</KCol>
</KRow>

<KRow id="764718" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="72360" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<HorizontalStack id="275007" direction="horizontal" size={12} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":15,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Form.Item className='kFormItem' name='parqformcopy_68315_value' >
<Checkbox id="68315" onChange={ (e?: any) => { if(e && e.stopPropagation) e.stopPropagation();this.ParQFormcopyComponent_68315_onChange()} } children={ReactSystemFunctions.translate(this.ml, 68315, "children",this.defaultML)} disabled={false} style={{"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"16px","color":"rgba(255, 255, 255, 1)","lineHeight":"22px"} as any}></Checkbox>
</Form.Item>

<Label id="762231" value={ReactSystemFunctions.translate(this.ml, 762231, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</HorizontalStack>

<HorizontalStack id="973771" direction="horizontal" size={12} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":15,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Form.Item className='kFormItem' name='parqformcopy_183279_value' >
<Checkbox id="183279" onChange={ (e?: any) => { if(e && e.stopPropagation) e.stopPropagation();this.ParQFormcopyComponent_183279_onChange()} } children={ReactSystemFunctions.translate(this.ml, 183279, "children",this.defaultML)} disabled={false} style={{"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"16px","color":"rgba(255, 255, 255, 1)","lineHeight":"22px"} as any}></Checkbox>
</Form.Item>

<Label id="577778" value={ReactSystemFunctions.translate(this.ml, 577778, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</HorizontalStack>

<VerticalStack id="226154" direction="vertical" size={12} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<HorizontalStack id="64423" direction="horizontal" size={12} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Form.Item className='kFormItem' name='parqformcopy_790913_value' >
<Checkbox id="790913" onChange={ (e?: any) => { if(e && e.stopPropagation) e.stopPropagation();this.ParQFormcopyComponent_790913_onChange()} } children={ReactSystemFunctions.translate(this.ml, 790913, "children",this.defaultML)} disabled={false} style={{"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"16px","color":"rgba(255, 255, 255, 1)","lineHeight":"22px"} as any}></Checkbox>
</Form.Item>

<Label id="399056" value={ReactSystemFunctions.translate(this.ml, 399056, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</HorizontalStack>

<VerticalStack id="26011" visibility={this.state.isComp26011Visible} direction="vertical" size={12} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":10,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<HorizontalStack id="588566" direction="horizontal" size={12} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Form.Item className='kFormItem' name='parqformcopy_43178_value' >
<Checkbox id="43178" onChange={ (e?: any) => { if(e && e.stopPropagation) e.stopPropagation();this.ParQFormcopyComponent_43178_onChange()} } children={ReactSystemFunctions.translate(this.ml, 43178, "children",this.defaultML)} disabled={false} style={{"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"16px","color":"rgba(255, 255, 255, 1)","lineHeight":"22px"} as any}></Checkbox>
</Form.Item>

<Label id="694750" value={ReactSystemFunctions.translate(this.ml, 694750, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"width":"150px","alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</HorizontalStack>

<HorizontalStack id="248654" direction="horizontal" size={12} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Form.Item className='kFormItem' name='parqformcopy_567403_value' >
<Checkbox id="567403" onChange={ (e?: any) => { if(e && e.stopPropagation) e.stopPropagation();this.ParQFormcopyComponent_567403_onChange()} } children={ReactSystemFunctions.translate(this.ml, 567403, "children",this.defaultML)} disabled={false} style={{"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"16px","color":"rgba(255, 255, 255, 1)","lineHeight":"22px"} as any}></Checkbox>
</Form.Item>

<Label id="149168" value={ReactSystemFunctions.translate(this.ml, 149168, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"width":"150px","alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</HorizontalStack>

<HorizontalStack id="368657" direction="horizontal" size={12} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Form.Item className='kFormItem' name='parqformcopy_235714_value' >
<Checkbox id="235714" onChange={ (e?: any) => { if(e && e.stopPropagation) e.stopPropagation();this.ParQFormcopyComponent_235714_onChange()} } children={ReactSystemFunctions.translate(this.ml, 235714, "children",this.defaultML)} disabled={false} style={{"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"16px","color":"rgba(255, 255, 255, 1)","lineHeight":"22px"} as any}></Checkbox>
</Form.Item>

<Label id="654962" value={ReactSystemFunctions.translate(this.ml, 654962, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"width":"150px","alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</HorizontalStack>
</VerticalStack>
</VerticalStack>
</KCol>
</KRow>
</KCol>
</KRow>

<KRow id="240511" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="869975" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderTopLeftRadius":8,"borderTopRightRadius":8,"borderBottomRightRadius":8,"borderBottomLeftRadius":8,"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KRow id="416640" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderBottomWidth":1,"borderColor":"rgba(0, 0, 0, 1)","borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="379388" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="56739" value={ReactSystemFunctions.translate(this.ml, 56739, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</KCol>
</KRow>

<KRow id="497598" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="532585" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KRow id="661329" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="296955" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<VerticalStack id="821103" direction="vertical" size={12} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="190181" value={ReactSystemFunctions.translate(this.ml, 190181, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</VerticalStack>
</KCol>
</KRow>

<KRow id="661284" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="763335" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<HorizontalStack id="930084" direction="horizontal" size={12} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="314974" value={ReactSystemFunctions.translate(this.ml, 314974, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>

<Form.Item className='kFormItem' name='parqformcopy_485437_value' >
<Switch id="485437" disabled={false} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Switch>
</Form.Item>

<Label id="874284" value={ReactSystemFunctions.translate(this.ml, 874284, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</HorizontalStack>
</KCol>
</KRow>

<KRow id="641757" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="899522" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<VerticalStack id="822672" direction="vertical" size={12} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="585217" value={ReactSystemFunctions.translate(this.ml, 585217, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</VerticalStack>
</KCol>
</KRow>

<KRow id="168910" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="915175" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<HorizontalStack id="925530" direction="horizontal" size={12} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="78235" value={ReactSystemFunctions.translate(this.ml, 78235, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>

<Form.Item className='kFormItem' name='parqformcopy_960012_value' >
<Switch id="960012" disabled={false} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Switch>
</Form.Item>

<Label id="160505" value={ReactSystemFunctions.translate(this.ml, 160505, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</HorizontalStack>
</KCol>
</KRow>

<KRow id="13307" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="431209" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<VerticalStack id="825336" direction="vertical" size={12} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="456374" value={ReactSystemFunctions.translate(this.ml, 456374, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</VerticalStack>
</KCol>
</KRow>

<KRow id="559243" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="415432" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<HorizontalStack id="482256" direction="horizontal" size={12} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="93599" value={ReactSystemFunctions.translate(this.ml, 93599, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>

<Form.Item className='kFormItem' name='parqformcopy_158970_value' >
<Switch id="158970" disabled={false} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Switch>
</Form.Item>

<Label id="541327" value={ReactSystemFunctions.translate(this.ml, 541327, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</HorizontalStack>
</KCol>
</KRow>

<KRow id="685000" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="241549" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<VerticalStack id="265848" direction="vertical" size={12} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="663262" value={ReactSystemFunctions.translate(this.ml, 663262, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</VerticalStack>
</KCol>
</KRow>

<KRow id="804443" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="130039" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<HorizontalStack id="241362" direction="horizontal" size={12} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="399875" value={ReactSystemFunctions.translate(this.ml, 399875, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>

<Form.Item className='kFormItem' name='parqformcopy_107454_value' >
<Switch id="107454" disabled={false} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Switch>
</Form.Item>

<Label id="312892" value={ReactSystemFunctions.translate(this.ml, 312892, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</HorizontalStack>
</KCol>
</KRow>

<KRow id="227459" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="429817" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<VerticalStack id="864420" direction="vertical" size={12} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="930564" value={ReactSystemFunctions.translate(this.ml, 930564, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</VerticalStack>
</KCol>
</KRow>

<KRow id="451213" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="58575" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<HorizontalStack id="332941" direction="horizontal" size={12} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="926467" value={ReactSystemFunctions.translate(this.ml, 926467, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>

<Form.Item className='kFormItem' name='parqformcopy_173410_value' >
<Switch id="173410" disabled={false} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Switch>
</Form.Item>

<Label id="214057" value={ReactSystemFunctions.translate(this.ml, 214057, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</HorizontalStack>
</KCol>
</KRow>

<KRow id="676371" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="715717" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<VerticalStack id="699957" direction="vertical" size={12} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="571342" value={ReactSystemFunctions.translate(this.ml, 571342, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</VerticalStack>
</KCol>
</KRow>

<KRow id="9094" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="152415" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<HorizontalStack id="379247" direction="horizontal" size={12} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="84587" value={ReactSystemFunctions.translate(this.ml, 84587, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>

<Form.Item className='kFormItem' name='parqformcopy_643202_value' >
<Switch id="643202" disabled={false} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Switch>
</Form.Item>

<Label id="400813" value={ReactSystemFunctions.translate(this.ml, 400813, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</HorizontalStack>
</KCol>
</KRow>

<KRow id="682436" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="869309" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<VerticalStack id="893031" direction="vertical" size={12} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="130222" value={ReactSystemFunctions.translate(this.ml, 130222, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</VerticalStack>
</KCol>
</KRow>

<KRow id="181406" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="476832" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<HorizontalStack id="831191" direction="horizontal" size={12} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="39079" value={ReactSystemFunctions.translate(this.ml, 39079, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>

<Form.Item className='kFormItem' name='parqformcopy_538933_value' >
<Switch id="538933" disabled={false} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Switch>
</Form.Item>

<Label id="291452" value={ReactSystemFunctions.translate(this.ml, 291452, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</HorizontalStack>
</KCol>
</KRow>

<KRow id="47030" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="164634" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<VerticalStack id="513691" direction="vertical" size={12} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="162989" value={ReactSystemFunctions.translate(this.ml, 162989, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</VerticalStack>
</KCol>
</KRow>

<KRow id="456888" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="763188" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<HorizontalStack id="767779" direction="horizontal" size={12} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="816598" value={ReactSystemFunctions.translate(this.ml, 816598, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>

<Form.Item className='kFormItem' name='parqformcopy_721461_value' >
<Switch id="721461" disabled={false} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Switch>
</Form.Item>

<Label id="238355" value={ReactSystemFunctions.translate(this.ml, 238355, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</HorizontalStack>
</KCol>
</KRow>
</KCol>
</KRow>
</KCol>
</KRow>

<KRow id="269253" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="470169" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderTopLeftRadius":8,"borderTopRightRadius":8,"borderBottomRightRadius":8,"borderBottomLeftRadius":8,"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KRow id="398892" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderBottomWidth":1,"borderColor":"rgba(0, 0, 0, 1)","borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="318479" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="79987" value={ReactSystemFunctions.translate(this.ml, 79987, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</KCol>
</KRow>

<KRow id="896861" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="188567" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<HorizontalStack id="160863" direction="horizontal" size={12} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="87511" value={ReactSystemFunctions.translate(this.ml, 87511, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>

<Form.Item className='kFormItem' name='parqformcopy_238402_value' >
<Switch id="238402" onChange={ (e?: any) => { if(e && e.stopPropagation) e.stopPropagation();this.ParQFormcopyComponent_238402_onChange()} } disabled={false} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Switch>
</Form.Item>

<Label id="345973" value={ReactSystemFunctions.translate(this.ml, 345973, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</HorizontalStack>
</KCol>
</KRow>

<KRow id="329331" visibility={this.state.isComp329331Visible} condition={(rowData) => ReactSystemFunctions.isNotEqualTo(this.state.FormParQById[0]?.s3?.toString(), "false")} align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="911852" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":0,"paddingBottom":8,"paddingLeft":20,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KRow id="904998" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="543996" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":8,"paddingBottom":8,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<HorizontalStack id="63759" direction="horizontal" size={12} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Form.Item className='kFormItem' name='parqformcopy_673903_value' >
<Checkbox id="673903" onChange={ (e?: any) => { if(e && e.stopPropagation) e.stopPropagation();this.ParQFormcopyComponent_673903_onChange()} } children={ReactSystemFunctions.translate(this.ml, 673903, "children",this.defaultML)} disabled={false} style={{"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"16px","color":"rgba(255, 255, 255, 1)","lineHeight":"22px"} as any}></Checkbox>
</Form.Item>

<Label id="663005" value={ReactSystemFunctions.translate(this.ml, 663005, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</HorizontalStack>
</KCol>
</KRow>

<KRow id="851647" visibility={this.state.isComp851647Visible} condition={(rowData) => ReactSystemFunctions.isEqualTo("", "true")} align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="661109" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":8,"paddingBottom":0,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KRow id="260714" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="758824" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<HorizontalStack id="275873" direction="horizontal" size={12} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="814910" value={ReactSystemFunctions.translate(this.ml, 814910, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</HorizontalStack>

<KRow id="167930" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="18402" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<HorizontalStack id="261225" direction="horizontal" size={12} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="874384" value={ReactSystemFunctions.translate(this.ml, 874384, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>

<Form.Item className='kFormItem' name='parqformcopy_63995_value' >
<Switch id="63995" onChange={ (e?: any) => { if(e && e.stopPropagation) e.stopPropagation();this.ParQFormcopyComponent_63995_onChange()} } disabled={false} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Switch>
</Form.Item>

<Label id="840722" value={ReactSystemFunctions.translate(this.ml, 840722, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</HorizontalStack>
</KCol>

<KCol id="319278" visibility={this.state.isComp319278Visible} condition={(rowData) => ReactSystemFunctions.isNotEqualTo("", "false")} xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="724982" value={ReactSystemFunctions.translate(this.ml, 724982, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":400,"fontSize":"12px","color":"rgba(10, 33, 64, 1)","lineHeight":"20px"} as any}></Label>

<Form.Item className='kFormItem' name='parqformcopy_146506_value' >
<TextInput id="146506" placeholder={ReactSystemFunctions.translate(this.ml, 146506, "placeholder",this.defaultML)} allowClear={false} bordered={true} disabled={false} type="text" iconColor="rgba(0, 0, 0, 1)" formatter="None" isCharOnly={false} autoComplete={true} style={{"borderTopLeftRadius":8,"borderTopRightRadius":8,"borderBottomRightRadius":8,"borderBottomLeftRadius":8,"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"center","textAlign":"-webkit-left","display":"block","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></TextInput>
</Form.Item>
</KCol>
</KRow>
</KCol>
</KRow>

<KRow id="599330" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="466748" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KRow id="699575" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></KRow>
</KCol>
</KRow>
</KCol>
</KRow>

<KRow id="426435" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="810765" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":8,"paddingBottom":8,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<HorizontalStack id="879065" direction="horizontal" size={12} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Form.Item className='kFormItem' name='parqformcopy_631046_value' >
<Checkbox id="631046" onChange={ (e?: any) => { if(e && e.stopPropagation) e.stopPropagation();this.ParQFormcopyComponent_631046_onChange()} } children={ReactSystemFunctions.translate(this.ml, 631046, "children",this.defaultML)} disabled={false} style={{"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"16px","color":"rgba(255, 255, 255, 1)","lineHeight":"22px"} as any}></Checkbox>
</Form.Item>

<Label id="574243" value={ReactSystemFunctions.translate(this.ml, 574243, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</HorizontalStack>
</KCol>
</KRow>

<KRow id="127283" visibility={this.state.isComp127283Visible} condition={(rowData) => ReactSystemFunctions.isEqualTo("", "true")} align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="260459" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":8,"paddingBottom":0,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KRow id="691212" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="391003" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<HorizontalStack id="375258" direction="horizontal" size={12} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="600933" value={ReactSystemFunctions.translate(this.ml, 600933, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</HorizontalStack>

<KRow id="448815" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="333722" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<HorizontalStack id="625850" direction="horizontal" size={12} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="338474" value={ReactSystemFunctions.translate(this.ml, 338474, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>

<Form.Item className='kFormItem' name='parqformcopy_355803_value' >
<Switch id="355803" onChange={ (e?: any) => { if(e && e.stopPropagation) e.stopPropagation();this.ParQFormcopyComponent_355803_onChange()} } disabled={false} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Switch>
</Form.Item>

<Label id="389869" value={ReactSystemFunctions.translate(this.ml, 389869, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</HorizontalStack>
</KCol>

<KCol id="80854" visibility={this.state.isComp80854Visible} condition={(rowData) => ReactSystemFunctions.isNotEqualTo("", "false")} xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="562563" value={ReactSystemFunctions.translate(this.ml, 562563, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":400,"fontSize":"12px","color":"rgba(10, 33, 64, 1)","lineHeight":"20px"} as any}></Label>

<Form.Item className='kFormItem' name='parqformcopy_552938_value' >
<TextInput id="552938" placeholder={ReactSystemFunctions.translate(this.ml, 552938, "placeholder",this.defaultML)} allowClear={false} bordered={true} disabled={false} type="text" iconColor="rgba(0, 0, 0, 1)" formatter="None" isCharOnly={false} autoComplete={true} style={{"borderTopLeftRadius":8,"borderTopRightRadius":8,"borderBottomRightRadius":8,"borderBottomLeftRadius":8,"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"center","textAlign":"-webkit-left","display":"block","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></TextInput>
</Form.Item>
</KCol>
</KRow>
</KCol>
</KRow>

<KRow id="361767" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="425340" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KRow id="233652" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></KRow>
</KCol>
</KRow>
</KCol>
</KRow>

<KRow id="171219" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="886325" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":8,"paddingBottom":8,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<HorizontalStack id="913210" direction="horizontal" size={12} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Form.Item className='kFormItem' name='parqformcopy_815856_value' >
<Checkbox id="815856" onChange={ (e?: any) => { if(e && e.stopPropagation) e.stopPropagation();this.ParQFormcopyComponent_815856_onChange()} } children={ReactSystemFunctions.translate(this.ml, 815856, "children",this.defaultML)} disabled={false} style={{"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"16px","color":"rgba(255, 255, 255, 1)","lineHeight":"22px"} as any}></Checkbox>
</Form.Item>

<Label id="131686" value={ReactSystemFunctions.translate(this.ml, 131686, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</HorizontalStack>
</KCol>
</KRow>

<KRow id="217409" visibility={this.state.isComp217409Visible} condition={(rowData) => ReactSystemFunctions.isEqualTo("", "true")} align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="961981" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":8,"paddingBottom":0,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KRow id="577598" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="517329" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<HorizontalStack id="534713" direction="horizontal" size={12} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="804448" value={ReactSystemFunctions.translate(this.ml, 804448, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</HorizontalStack>

<KRow id="950274" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="934337" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<HorizontalStack id="692865" direction="horizontal" size={12} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="190264" value={ReactSystemFunctions.translate(this.ml, 190264, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>

<Form.Item className='kFormItem' name='parqformcopy_827266_value' >
<Switch id="827266" onChange={ (e?: any) => { if(e && e.stopPropagation) e.stopPropagation();this.ParQFormcopyComponent_827266_onChange()} } disabled={false} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Switch>
</Form.Item>

<Label id="392655" value={ReactSystemFunctions.translate(this.ml, 392655, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</HorizontalStack>
</KCol>

<KCol id="210725" visibility={this.state.isComp210725Visible} condition={(rowData) => ReactSystemFunctions.isNotEqualTo("", "false")} xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="428617" value={ReactSystemFunctions.translate(this.ml, 428617, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":400,"fontSize":"12px","color":"rgba(10, 33, 64, 1)","lineHeight":"20px"} as any}></Label>

<Form.Item className='kFormItem' name='parqformcopy_441666_value' >
<TextInput id="441666" placeholder={ReactSystemFunctions.translate(this.ml, 441666, "placeholder",this.defaultML)} allowClear={false} bordered={true} disabled={false} type="text" iconColor="rgba(0, 0, 0, 1)" formatter="None" isCharOnly={false} autoComplete={true} style={{"borderTopLeftRadius":8,"borderTopRightRadius":8,"borderBottomRightRadius":8,"borderBottomLeftRadius":8,"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"center","textAlign":"-webkit-left","display":"block","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></TextInput>
</Form.Item>
</KCol>
</KRow>
</KCol>
</KRow>
</KCol>
</KRow>

<KRow id="433496" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="685509" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":8,"paddingBottom":8,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<HorizontalStack id="637657" direction="horizontal" size={12} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Form.Item className='kFormItem' name='parqformcopy_301053_value' >
<Checkbox id="301053" onChange={ (e?: any) => { if(e && e.stopPropagation) e.stopPropagation();this.ParQFormcopyComponent_301053_onChange()} } children={ReactSystemFunctions.translate(this.ml, 301053, "children",this.defaultML)} disabled={false} style={{"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"16px","color":"rgba(255, 255, 255, 1)","lineHeight":"22px"} as any}></Checkbox>
</Form.Item>

<Label id="774792" value={ReactSystemFunctions.translate(this.ml, 774792, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</HorizontalStack>
</KCol>
</KRow>

<KRow id="673097" visibility={this.state.isComp673097Visible} condition={(rowData) => ReactSystemFunctions.isEqualTo("", "true")} align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="212475" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":8,"paddingBottom":0,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KRow id="188602" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="33869" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<HorizontalStack id="986506" direction="horizontal" size={12} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="446609" value={ReactSystemFunctions.translate(this.ml, 446609, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</HorizontalStack>

<KRow id="348580" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="772622" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<HorizontalStack id="34949" direction="horizontal" size={12} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="711068" value={ReactSystemFunctions.translate(this.ml, 711068, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>

<Form.Item className='kFormItem' name='parqformcopy_425116_value' >
<Switch id="425116" onChange={ (e?: any) => { if(e && e.stopPropagation) e.stopPropagation();this.ParQFormcopyComponent_425116_onChange()} } disabled={false} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Switch>
</Form.Item>

<Label id="173407" value={ReactSystemFunctions.translate(this.ml, 173407, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</HorizontalStack>
</KCol>

<KCol id="23734" visibility={this.state.isComp23734Visible} condition={(rowData) => ReactSystemFunctions.isNotEqualTo("", "false")} xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="940581" value={ReactSystemFunctions.translate(this.ml, 940581, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":400,"fontSize":"12px","color":"rgba(10, 33, 64, 1)","lineHeight":"20px"} as any}></Label>

<Form.Item className='kFormItem' name='parqformcopy_366768_value' >
<TextInput id="366768" placeholder={ReactSystemFunctions.translate(this.ml, 366768, "placeholder",this.defaultML)} allowClear={false} bordered={true} disabled={false} type="text" iconColor="rgba(0, 0, 0, 1)" formatter="None" isCharOnly={false} autoComplete={true} style={{"borderTopLeftRadius":8,"borderTopRightRadius":8,"borderBottomRightRadius":8,"borderBottomLeftRadius":8,"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"center","textAlign":"-webkit-left","display":"block","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></TextInput>
</Form.Item>
</KCol>
</KRow>
</KCol>
</KRow>
</KCol>
</KRow>

<KRow id="289758" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="947669" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":8,"paddingBottom":8,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<HorizontalStack id="429391" direction="horizontal" size={12} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Form.Item className='kFormItem' name='parqformcopy_316213_value' >
<Checkbox id="316213" onChange={ (e?: any) => { if(e && e.stopPropagation) e.stopPropagation();this.ParQFormcopyComponent_316213_onChange()} } children={ReactSystemFunctions.translate(this.ml, 316213, "children",this.defaultML)} disabled={false} style={{"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"16px","color":"rgba(255, 255, 255, 1)","lineHeight":"22px"} as any}></Checkbox>
</Form.Item>

<Label id="452576" value={ReactSystemFunctions.translate(this.ml, 452576, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</HorizontalStack>
</KCol>
</KRow>

<KRow id="707761" visibility={this.state.isComp707761Visible} condition={(rowData) => ReactSystemFunctions.isEqualTo("", "true")} align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="4247" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":8,"paddingBottom":0,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KRow id="503052" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="277616" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<HorizontalStack id="214116" direction="horizontal" size={12} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="299770" value={ReactSystemFunctions.translate(this.ml, 299770, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</HorizontalStack>

<KRow id="136351" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="981009" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<HorizontalStack id="2406" direction="horizontal" size={12} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="515918" value={ReactSystemFunctions.translate(this.ml, 515918, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>

<Form.Item className='kFormItem' name='parqformcopy_360226_value' >
<Switch id="360226" onChange={ (e?: any) => { if(e && e.stopPropagation) e.stopPropagation();this.ParQFormcopyComponent_360226_onChange()} } disabled={false} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Switch>
</Form.Item>

<Label id="699533" value={ReactSystemFunctions.translate(this.ml, 699533, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</HorizontalStack>
</KCol>

<KCol id="177991" visibility={this.state.isComp177991Visible} condition={(rowData) => ReactSystemFunctions.isNotEqualTo("", "false")} xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="757058" value={ReactSystemFunctions.translate(this.ml, 757058, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":400,"fontSize":"12px","color":"rgba(10, 33, 64, 1)","lineHeight":"20px"} as any}></Label>

<Form.Item className='kFormItem' name='parqformcopy_394297_value' >
<TextInput id="394297" placeholder={ReactSystemFunctions.translate(this.ml, 394297, "placeholder",this.defaultML)} allowClear={false} bordered={true} disabled={false} type="text" iconColor="rgba(0, 0, 0, 1)" formatter="None" isCharOnly={false} autoComplete={true} style={{"borderTopLeftRadius":8,"borderTopRightRadius":8,"borderBottomRightRadius":8,"borderBottomLeftRadius":8,"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"center","textAlign":"-webkit-left","display":"block","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></TextInput>
</Form.Item>
</KCol>
</KRow>
</KCol>
</KRow>
</KCol>
</KRow>

<KRow id="829372" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="72653" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":8,"paddingBottom":8,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<HorizontalStack id="33809" direction="horizontal" size={12} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Form.Item className='kFormItem' name='parqformcopy_395667_value' >
<Checkbox id="395667" onChange={ (e?: any) => { if(e && e.stopPropagation) e.stopPropagation();this.ParQFormcopyComponent_395667_onChange()} } children={ReactSystemFunctions.translate(this.ml, 395667, "children",this.defaultML)} disabled={false} style={{"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"16px","color":"rgba(255, 255, 255, 1)","lineHeight":"22px"} as any}></Checkbox>
</Form.Item>

<Label id="514560" value={ReactSystemFunctions.translate(this.ml, 514560, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</HorizontalStack>
</KCol>
</KRow>

<KRow id="911981" visibility={this.state.isComp911981Visible} condition={(rowData) => ReactSystemFunctions.isEqualTo("", "true")} align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="646608" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":8,"paddingBottom":0,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KRow id="282702" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="303573" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<HorizontalStack id="505249" direction="horizontal" size={12} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="712384" value={ReactSystemFunctions.translate(this.ml, 712384, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</HorizontalStack>

<KRow id="932645" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="460626" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<HorizontalStack id="111731" direction="horizontal" size={12} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="974864" value={ReactSystemFunctions.translate(this.ml, 974864, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>

<Form.Item className='kFormItem' name='parqformcopy_951810_value' >
<Switch id="951810" onChange={ (e?: any) => { if(e && e.stopPropagation) e.stopPropagation();this.ParQFormcopyComponent_951810_onChange()} } disabled={false} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Switch>
</Form.Item>

<Label id="285189" value={ReactSystemFunctions.translate(this.ml, 285189, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</HorizontalStack>
</KCol>

<KCol id="710369" visibility={this.state.isComp710369Visible} condition={(rowData) => ReactSystemFunctions.isNotEqualTo("", "false")} xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="249272" value={ReactSystemFunctions.translate(this.ml, 249272, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":400,"fontSize":"12px","color":"rgba(10, 33, 64, 1)","lineHeight":"20px"} as any}></Label>

<Form.Item className='kFormItem' name='parqformcopy_503137_value' >
<TextInput id="503137" placeholder={ReactSystemFunctions.translate(this.ml, 503137, "placeholder",this.defaultML)} allowClear={false} bordered={true} disabled={false} type="text" iconColor="rgba(0, 0, 0, 1)" formatter="None" isCharOnly={false} autoComplete={true} style={{"borderTopLeftRadius":8,"borderTopRightRadius":8,"borderBottomRightRadius":8,"borderBottomLeftRadius":8,"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"center","textAlign":"-webkit-left","display":"block","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></TextInput>
</Form.Item>
</KCol>
</KRow>
</KCol>
</KRow>

<KRow id="719492" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="63392" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KRow id="95574" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></KRow>
</KCol>
</KRow>
</KCol>
</KRow>

<KRow id="439668" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="362896" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":8,"paddingBottom":8,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<HorizontalStack id="649135" direction="horizontal" size={12} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Form.Item className='kFormItem' name='parqformcopy_858125_value' >
<Checkbox id="858125" onChange={ (e?: any) => { if(e && e.stopPropagation) e.stopPropagation();this.ParQFormcopyComponent_858125_onChange()} } children={ReactSystemFunctions.translate(this.ml, 858125, "children",this.defaultML)} disabled={false} style={{"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"16px","color":"rgba(255, 255, 255, 1)","lineHeight":"22px"} as any}></Checkbox>
</Form.Item>

<Label id="390141" value={ReactSystemFunctions.translate(this.ml, 390141, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</HorizontalStack>
</KCol>
</KRow>

<KRow id="714589" visibility={this.state.isComp714589Visible} condition={(rowData) => ReactSystemFunctions.isEqualTo("", "true")} align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="134393" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":8,"paddingBottom":0,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KRow id="375074" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="55160" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<HorizontalStack id="220846" direction="horizontal" size={12} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="861592" value={ReactSystemFunctions.translate(this.ml, 861592, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</HorizontalStack>

<KRow id="677855" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="680184" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<HorizontalStack id="418369" direction="horizontal" size={12} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="693351" value={ReactSystemFunctions.translate(this.ml, 693351, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>

<Form.Item className='kFormItem' name='parqformcopy_2796_value' >
<Switch id="2796" onChange={ (e?: any) => { if(e && e.stopPropagation) e.stopPropagation();this.ParQFormcopyComponent_2796_onChange()} } disabled={false} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Switch>
</Form.Item>

<Label id="759387" value={ReactSystemFunctions.translate(this.ml, 759387, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</HorizontalStack>
</KCol>

<KCol id="118539" visibility={this.state.isComp118539Visible} condition={(rowData) => ReactSystemFunctions.isNotEqualTo("", "false")} xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="730058" value={ReactSystemFunctions.translate(this.ml, 730058, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":400,"fontSize":"12px","color":"rgba(10, 33, 64, 1)","lineHeight":"20px"} as any}></Label>

<Form.Item className='kFormItem' name='parqformcopy_875104_value' >
<TextInput id="875104" placeholder={ReactSystemFunctions.translate(this.ml, 875104, "placeholder",this.defaultML)} allowClear={false} bordered={true} disabled={false} type="text" iconColor="rgba(0, 0, 0, 1)" formatter="None" isCharOnly={false} autoComplete={true} style={{"borderTopLeftRadius":8,"borderTopRightRadius":8,"borderBottomRightRadius":8,"borderBottomLeftRadius":8,"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"center","textAlign":"-webkit-left","display":"block","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></TextInput>
</Form.Item>
</KCol>
</KRow>
</KCol>
</KRow>
</KCol>
</KRow>

<KRow id="192510" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="98174" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":8,"paddingBottom":8,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<HorizontalStack id="277048" direction="horizontal" size={12} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Form.Item className='kFormItem' name='parqformcopy_985460_value' >
<Checkbox id="985460" onChange={ (e?: any) => { if(e && e.stopPropagation) e.stopPropagation();this.ParQFormcopyComponent_985460_onChange()} } children={ReactSystemFunctions.translate(this.ml, 985460, "children",this.defaultML)} disabled={false} style={{"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"16px","color":"rgba(255, 255, 255, 1)","lineHeight":"22px"} as any}></Checkbox>
</Form.Item>

<Label id="688292" value={ReactSystemFunctions.translate(this.ml, 688292, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</HorizontalStack>
</KCol>
</KRow>

<KRow id="696700" visibility={this.state.isComp696700Visible} condition={(rowData) => ReactSystemFunctions.isEqualTo("", "true")} align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="815057" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":8,"paddingBottom":0,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KRow id="243103" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="759939" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<HorizontalStack id="475687" direction="horizontal" size={12} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="712349" value={ReactSystemFunctions.translate(this.ml, 712349, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</HorizontalStack>

<KRow id="228007" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="455432" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<HorizontalStack id="933761" direction="horizontal" size={12} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="973013" value={ReactSystemFunctions.translate(this.ml, 973013, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>

<Form.Item className='kFormItem' name='parqformcopy_875505_value' >
<Switch id="875505" onChange={ (e?: any) => { if(e && e.stopPropagation) e.stopPropagation();this.ParQFormcopyComponent_875505_onChange()} } disabled={false} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Switch>
</Form.Item>

<Label id="190773" value={ReactSystemFunctions.translate(this.ml, 190773, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</HorizontalStack>
</KCol>

<KCol id="821128" visibility={this.state.isComp821128Visible} condition={(rowData) => ReactSystemFunctions.isNotEqualTo("", "false")} xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="759525" value={ReactSystemFunctions.translate(this.ml, 759525, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":400,"fontSize":"12px","color":"rgba(10, 33, 64, 1)","lineHeight":"20px"} as any}></Label>

<Form.Item className='kFormItem' name='parqformcopy_202020_value' >
<TextInput id="202020" placeholder={ReactSystemFunctions.translate(this.ml, 202020, "placeholder",this.defaultML)} allowClear={false} bordered={true} disabled={false} type="text" iconColor="rgba(0, 0, 0, 1)" formatter="None" isCharOnly={false} autoComplete={true} style={{"borderTopLeftRadius":8,"borderTopRightRadius":8,"borderBottomRightRadius":8,"borderBottomLeftRadius":8,"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"center","textAlign":"-webkit-left","display":"block","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></TextInput>
</Form.Item>
</KCol>
</KRow>
</KCol>
</KRow>
</KCol>
</KRow>

<KRow id="972502" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="481658" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":8,"paddingBottom":8,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<HorizontalStack id="778098" direction="horizontal" size={12} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Form.Item className='kFormItem' name='parqformcopy_113946_value' >
<Checkbox id="113946" onChange={ (e?: any) => { if(e && e.stopPropagation) e.stopPropagation();this.ParQFormcopyComponent_113946_onChange()} } children={ReactSystemFunctions.translate(this.ml, 113946, "children",this.defaultML)} disabled={false} style={{"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"16px","color":"rgba(255, 255, 255, 1)","lineHeight":"22px"} as any}></Checkbox>
</Form.Item>

<Label id="122468" value={ReactSystemFunctions.translate(this.ml, 122468, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</HorizontalStack>
</KCol>
</KRow>

<KRow id="532130" visibility={this.state.isComp532130Visible} condition={(rowData) => ReactSystemFunctions.isEqualTo("", "true")} align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="357288" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":8,"paddingBottom":0,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KRow id="303040" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="742508" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<HorizontalStack id="645956" direction="horizontal" size={12} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="504945" value={ReactSystemFunctions.translate(this.ml, 504945, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</HorizontalStack>

<KRow id="374906" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="237902" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<HorizontalStack id="191761" direction="horizontal" size={12} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="880988" value={ReactSystemFunctions.translate(this.ml, 880988, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>

<Form.Item className='kFormItem' name='parqformcopy_47848_value' >
<Switch id="47848" onChange={ (e?: any) => { if(e && e.stopPropagation) e.stopPropagation();this.ParQFormcopyComponent_47848_onChange()} } disabled={false} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Switch>
</Form.Item>

<Label id="78660" value={ReactSystemFunctions.translate(this.ml, 78660, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</HorizontalStack>
</KCol>

<KCol id="882479" visibility={this.state.isComp882479Visible} condition={(rowData) => ReactSystemFunctions.isNotEqualTo("", "false")} xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="327153" value={ReactSystemFunctions.translate(this.ml, 327153, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":400,"fontSize":"12px","color":"rgba(10, 33, 64, 1)","lineHeight":"20px"} as any}></Label>

<Form.Item className='kFormItem' name='parqformcopy_410924_value' >
<TextInput id="410924" placeholder={ReactSystemFunctions.translate(this.ml, 410924, "placeholder",this.defaultML)} allowClear={false} bordered={true} disabled={false} type="text" iconColor="rgba(0, 0, 0, 1)" formatter="None" isCharOnly={false} autoComplete={true} style={{"borderTopLeftRadius":8,"borderTopRightRadius":8,"borderBottomRightRadius":8,"borderBottomLeftRadius":8,"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"center","textAlign":"-webkit-left","display":"block","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></TextInput>
</Form.Item>
</KCol>
</KRow>
</KCol>
</KRow>
</KCol>
</KRow>

<KRow id="443183" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="894744" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":8,"paddingBottom":8,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<HorizontalStack id="15102" direction="horizontal" size={12} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Form.Item className='kFormItem' name='parqformcopy_221532_value' >
<Checkbox id="221532" onChange={ (e?: any) => { if(e && e.stopPropagation) e.stopPropagation();this.ParQFormcopyComponent_221532_onChange()} } children={ReactSystemFunctions.translate(this.ml, 221532, "children",this.defaultML)} disabled={false} style={{"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"16px","color":"rgba(255, 255, 255, 1)","lineHeight":"22px"} as any}></Checkbox>
</Form.Item>

<Label id="134744" value={ReactSystemFunctions.translate(this.ml, 134744, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"width":"100px","alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</HorizontalStack>
</KCol>
</KRow>

<KRow id="503462" visibility={this.state.isComp503462Visible} condition={(rowData) => ReactSystemFunctions.isEqualTo("", "true")} align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="366336" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"center","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<HorizontalStack id="236978" direction="horizontal" size={12} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="218611" value={ReactSystemFunctions.translate(this.ml, 218611, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"width":"30px","alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>

<Form.Item className='kFormItem' name='parqformcopy_35554_value' >
<NumberInput id="35554" decimalSeparator="." decimalScale={2} disabled={false} placeholder={ReactSystemFunctions.translate(this.ml, 35554, "placeholder",this.defaultML)} fixedDecimalScale={false} allowNegative={true} allowEmptyFormatting={false} isThousandSeperatorOn={true} style={{"borderTopLeftRadius":8,"borderTopRightRadius":8,"borderBottomRightRadius":8,"borderBottomLeftRadius":8,"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"width":"100px","alignItems":"center","textAlign":"-webkit-left","display":"block","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></NumberInput>
</Form.Item>
</HorizontalStack>
</KCol>

<KCol id="829084" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"center","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<HorizontalStack id="267993" direction="horizontal" size={12} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="259130" value={ReactSystemFunctions.translate(this.ml, 259130, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"width":"30px","alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>

<Form.Item className='kFormItem' name='parqformcopy_84142_value' >
<NumberInput id="84142" decimalSeparator="." decimalScale={2} disabled={false} placeholder={ReactSystemFunctions.translate(this.ml, 84142, "placeholder",this.defaultML)} fixedDecimalScale={false} allowNegative={true} allowEmptyFormatting={false} isThousandSeperatorOn={true} style={{"borderTopLeftRadius":8,"borderTopRightRadius":8,"borderBottomRightRadius":8,"borderBottomLeftRadius":8,"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"width":"100px","alignItems":"center","textAlign":"-webkit-left","display":"block","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></NumberInput>
</Form.Item>
</HorizontalStack>
</KCol>

<KCol id="157749" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":8,"paddingBottom":0,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KRow id="907404" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="520409" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<HorizontalStack id="495351" direction="horizontal" size={12} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="578084" value={ReactSystemFunctions.translate(this.ml, 578084, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</HorizontalStack>

<KRow id="132595" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="91306" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<HorizontalStack id="982233" direction="horizontal" size={12} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="582048" value={ReactSystemFunctions.translate(this.ml, 582048, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>

<Form.Item className='kFormItem' name='parqformcopy_173392_value' >
<Switch id="173392" onChange={ (e?: any) => { if(e && e.stopPropagation) e.stopPropagation();this.ParQFormcopyComponent_173392_onChange()} } disabled={false} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Switch>
</Form.Item>

<Label id="191046" value={ReactSystemFunctions.translate(this.ml, 191046, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</HorizontalStack>
</KCol>

<KCol id="311406" visibility={this.state.isComp311406Visible} condition={(rowData) => ReactSystemFunctions.isNotEqualTo("", "false")} xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="96979" value={ReactSystemFunctions.translate(this.ml, 96979, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":400,"fontSize":"12px","color":"rgba(10, 33, 64, 1)","lineHeight":"20px"} as any}></Label>

<Form.Item className='kFormItem' name='parqformcopy_813285_value' >
<TextInput id="813285" placeholder={ReactSystemFunctions.translate(this.ml, 813285, "placeholder",this.defaultML)} allowClear={false} bordered={true} disabled={false} type="text" iconColor="rgba(0, 0, 0, 1)" formatter="None" isCharOnly={false} autoComplete={true} style={{"borderTopLeftRadius":8,"borderTopRightRadius":8,"borderBottomRightRadius":8,"borderBottomLeftRadius":8,"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"center","textAlign":"-webkit-left","display":"block","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></TextInput>
</Form.Item>
</KCol>
</KRow>
</KCol>
</KRow>
</KCol>
</KRow>

<KRow id="889699" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="214002" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":8,"paddingBottom":8,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<HorizontalStack id="674783" direction="horizontal" size={12} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Form.Item className='kFormItem' name='parqformcopy_245992_value' >
<Checkbox id="245992" onChange={ (e?: any) => { if(e && e.stopPropagation) e.stopPropagation();this.ParQFormcopyComponent_245992_onChange()} } children={ReactSystemFunctions.translate(this.ml, 245992, "children",this.defaultML)} disabled={false} style={{"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"16px","color":"rgba(255, 255, 255, 1)","lineHeight":"22px"} as any}></Checkbox>
</Form.Item>

<Label id="873189" value={ReactSystemFunctions.translate(this.ml, 873189, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</HorizontalStack>
</KCol>
</KRow>

<KRow id="761377" visibility={this.state.isComp761377Visible} condition={(rowData) => ReactSystemFunctions.isEqualTo("", "true")} align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="231008" xs={12} sm={12} md={12} lg={12} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"center","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<VerticalStack id="574146" direction="vertical" size={3} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="947118" value={ReactSystemFunctions.translate(this.ml, 947118, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>

<Form.Item className='kFormItem' name='parqformcopy_981775_value' >
<NumberInput id="981775" onChange={ (e?: any) => { if(e && e.stopPropagation) e.stopPropagation();this.ParQFormcopyComponent_981775_onChange()} } decimalSeparator="." decimalScale={2} disabled={false} placeholder={ReactSystemFunctions.translate(this.ml, 981775, "placeholder",this.defaultML)} fixedDecimalScale={false} allowNegative={true} allowEmptyFormatting={false} isThousandSeperatorOn={true} style={{"borderTopLeftRadius":8,"borderTopRightRadius":8,"borderBottomRightRadius":8,"borderBottomLeftRadius":8,"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"center","textAlign":"-webkit-left","display":"block","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></NumberInput>
</Form.Item>
</VerticalStack>
</KCol>

<KCol id="233638" xs={12} sm={12} md={12} lg={12} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"center","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<VerticalStack id="732589" direction="vertical" size={3} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="585600" value={ReactSystemFunctions.translate(this.ml, 585600, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>

<Form.Item className='kFormItem' name='parqformcopy_138481_value' >
<NumberInput id="138481" onChange={ (e?: any) => { if(e && e.stopPropagation) e.stopPropagation();this.ParQFormcopyComponent_138481_onChange()} } decimalSeparator="." decimalScale={2} disabled={false} placeholder={ReactSystemFunctions.translate(this.ml, 138481, "placeholder",this.defaultML)} fixedDecimalScale={false} allowNegative={true} allowEmptyFormatting={false} isThousandSeperatorOn={true} style={{"borderTopLeftRadius":8,"borderTopRightRadius":8,"borderBottomRightRadius":8,"borderBottomLeftRadius":8,"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"center","textAlign":"-webkit-left","display":"block","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></NumberInput>
</Form.Item>
</VerticalStack>
</KCol>

<KCol id="602885" xs={12} sm={12} md={12} lg={12} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"center","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<VerticalStack id="573891" direction="vertical" size={3} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":10,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="18997" value={ReactSystemFunctions.translate(this.ml, 18997, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>

<Form.Item className='kFormItem' name='parqformcopy_816505_value' >
<NumberInput id="816505" decimalSeparator="." decimalScale={2} disabled={false} placeholder={ReactSystemFunctions.translate(this.ml, 816505, "placeholder",this.defaultML)} fixedDecimalScale={false} allowNegative={true} allowEmptyFormatting={false} isThousandSeperatorOn={true} style={{"borderTopLeftRadius":8,"borderTopRightRadius":8,"borderBottomRightRadius":8,"borderBottomLeftRadius":8,"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"center","textAlign":"-webkit-left","display":"block","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></NumberInput>
</Form.Item>
</VerticalStack>
</KCol>

<KCol id="96749" xs={12} sm={12} md={12} lg={12} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"center","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<VerticalStack id="867866" direction="vertical" size={3} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":10,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="123296" value={ReactSystemFunctions.translate(this.ml, 123296, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>

<Form.Item className='kFormItem' name='parqformcopy_337464_value' >
<NumberInput id="337464" editability={this.state.isComp337464Enabled} decimalSeparator="." decimalScale={2} disabled={false} placeholder={ReactSystemFunctions.translate(this.ml, 337464, "placeholder",this.defaultML)} fixedDecimalScale={false} allowNegative={true} allowEmptyFormatting={false} isThousandSeperatorOn={true} style={{"borderTopLeftRadius":8,"borderTopRightRadius":8,"borderBottomRightRadius":8,"borderBottomLeftRadius":8,"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"center","textAlign":"-webkit-left","display":"block","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></NumberInput>
</Form.Item>
</VerticalStack>
</KCol>

<KCol id="736661" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":8,"paddingBottom":0,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KRow id="241837" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="252803" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<HorizontalStack id="688089" direction="horizontal" size={12} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="842082" value={ReactSystemFunctions.translate(this.ml, 842082, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</HorizontalStack>

<KRow id="754889" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="537590" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<HorizontalStack id="55352" direction="horizontal" size={12} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="493608" value={ReactSystemFunctions.translate(this.ml, 493608, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>

<Form.Item className='kFormItem' name='parqformcopy_421543_value' >
<Switch id="421543" onChange={ (e?: any) => { if(e && e.stopPropagation) e.stopPropagation();this.ParQFormcopyComponent_421543_onChange()} } disabled={false} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Switch>
</Form.Item>

<Label id="255051" value={ReactSystemFunctions.translate(this.ml, 255051, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</HorizontalStack>
</KCol>

<KCol id="50002" visibility={this.state.isComp50002Visible} condition={(rowData) => ReactSystemFunctions.isNotEqualTo("", "false")} xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="326609" value={ReactSystemFunctions.translate(this.ml, 326609, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":400,"fontSize":"12px","color":"rgba(10, 33, 64, 1)","lineHeight":"20px"} as any}></Label>

<Form.Item className='kFormItem' name='parqformcopy_751235_value' >
<TextInput id="751235" placeholder={ReactSystemFunctions.translate(this.ml, 751235, "placeholder",this.defaultML)} allowClear={false} bordered={true} disabled={false} type="text" iconColor="rgba(0, 0, 0, 1)" formatter="None" isCharOnly={false} autoComplete={true} style={{"borderTopLeftRadius":8,"borderTopRightRadius":8,"borderBottomRightRadius":8,"borderBottomLeftRadius":8,"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"center","textAlign":"-webkit-left","display":"block","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></TextInput>
</Form.Item>
</KCol>
</KRow>
</KCol>
</KRow>
</KCol>
</KRow>

<KRow id="886383" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="378016" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":8,"paddingBottom":8,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<HorizontalStack id="76601" direction="horizontal" size={12} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Form.Item className='kFormItem' name='parqformcopy_137075_value' >
<Checkbox id="137075" onChange={ (e?: any) => { if(e && e.stopPropagation) e.stopPropagation();this.ParQFormcopyComponent_137075_onChange()} } children={ReactSystemFunctions.translate(this.ml, 137075, "children",this.defaultML)} disabled={false} style={{"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"16px","color":"rgba(255, 255, 255, 1)","lineHeight":"22px"} as any}></Checkbox>
</Form.Item>

<Label id="303986" value={ReactSystemFunctions.translate(this.ml, 303986, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</HorizontalStack>
</KCol>
</KRow>

<KRow id="629359" visibility={this.state.isComp629359Visible} condition={(rowData) => ReactSystemFunctions.isEqualTo("", "true")} align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="72897" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":8,"paddingBottom":0,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KRow id="43649" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="416650" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<HorizontalStack id="744968" direction="horizontal" size={12} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="210238" value={ReactSystemFunctions.translate(this.ml, 210238, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</HorizontalStack>

<KRow id="725827" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="878587" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<HorizontalStack id="226284" direction="horizontal" size={12} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="521051" value={ReactSystemFunctions.translate(this.ml, 521051, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>

<Form.Item className='kFormItem' name='parqformcopy_763635_value' >
<Switch id="763635" onChange={ (e?: any) => { if(e && e.stopPropagation) e.stopPropagation();this.ParQFormcopyComponent_763635_onChange()} } disabled={false} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Switch>
</Form.Item>

<Label id="357951" value={ReactSystemFunctions.translate(this.ml, 357951, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</HorizontalStack>
</KCol>

<KCol id="449366" visibility={this.state.isComp449366Visible} condition={(rowData) => ReactSystemFunctions.isNotEqualTo("", "false")} xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="191371" value={ReactSystemFunctions.translate(this.ml, 191371, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":400,"fontSize":"12px","color":"rgba(10, 33, 64, 1)","lineHeight":"20px"} as any}></Label>

<Form.Item className='kFormItem' name='parqformcopy_492488_value' >
<TextInput id="492488" placeholder={ReactSystemFunctions.translate(this.ml, 492488, "placeholder",this.defaultML)} allowClear={false} bordered={true} disabled={false} type="text" iconColor="rgba(0, 0, 0, 1)" formatter="None" isCharOnly={false} autoComplete={true} style={{"borderTopLeftRadius":8,"borderTopRightRadius":8,"borderBottomRightRadius":8,"borderBottomLeftRadius":8,"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"center","textAlign":"-webkit-left","display":"block","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></TextInput>
</Form.Item>
</KCol>
</KRow>
</KCol>
</KRow>
</KCol>
</KRow>

<KRow id="678254" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="155662" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":8,"paddingBottom":8,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<HorizontalStack id="796318" direction="horizontal" size={12} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Form.Item className='kFormItem' name='parqformcopy_683570_value' >
<Checkbox id="683570" onChange={ (e?: any) => { if(e && e.stopPropagation) e.stopPropagation();this.ParQFormcopyComponent_683570_onChange()} } children={ReactSystemFunctions.translate(this.ml, 683570, "children",this.defaultML)} disabled={false} style={{"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"16px","color":"rgba(255, 255, 255, 1)","lineHeight":"22px"} as any}></Checkbox>
</Form.Item>

<Label id="617018" value={ReactSystemFunctions.translate(this.ml, 617018, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</HorizontalStack>
</KCol>
</KRow>

<KRow id="273435" visibility={this.state.isComp273435Visible} condition={(rowData) => ReactSystemFunctions.isEqualTo("", "true")} align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="693116" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":8,"paddingBottom":0,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KRow id="631381" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="899390" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<HorizontalStack id="354456" direction="horizontal" size={12} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="72534" value={ReactSystemFunctions.translate(this.ml, 72534, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</HorizontalStack>

<KRow id="153521" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="756010" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<HorizontalStack id="975368" direction="horizontal" size={12} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="234670" value={ReactSystemFunctions.translate(this.ml, 234670, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>

<Form.Item className='kFormItem' name='parqformcopy_17076_value' >
<Switch id="17076" onChange={ (e?: any) => { if(e && e.stopPropagation) e.stopPropagation();this.ParQFormcopyComponent_17076_onChange()} } disabled={false} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Switch>
</Form.Item>

<Label id="458336" value={ReactSystemFunctions.translate(this.ml, 458336, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</HorizontalStack>
</KCol>

<KCol id="688878" visibility={this.state.isComp688878Visible} condition={(rowData) => ReactSystemFunctions.isNotEqualTo("", "false")} xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="377463" value={ReactSystemFunctions.translate(this.ml, 377463, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":400,"fontSize":"12px","color":"rgba(10, 33, 64, 1)","lineHeight":"20px"} as any}></Label>

<Form.Item className='kFormItem' name='parqformcopy_56334_value' >
<TextInput id="56334" placeholder={ReactSystemFunctions.translate(this.ml, 56334, "placeholder",this.defaultML)} allowClear={false} bordered={true} disabled={false} type="text" iconColor="rgba(0, 0, 0, 1)" formatter="None" isCharOnly={false} autoComplete={true} style={{"borderTopLeftRadius":8,"borderTopRightRadius":8,"borderBottomRightRadius":8,"borderBottomLeftRadius":8,"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"center","textAlign":"-webkit-left","display":"block","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></TextInput>
</Form.Item>
</KCol>
</KRow>
</KCol>
</KRow>

<KRow id="66972" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="477569" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KRow id="611676" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></KRow>
</KCol>
</KRow>
</KCol>
</KRow>
</KCol>
</KRow>
</KCol>
</KRow>

<KRow id="799435" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="460817" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderTopLeftRadius":8,"borderTopRightRadius":8,"borderBottomRightRadius":8,"borderBottomLeftRadius":8,"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KRow id="462617" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderBottomWidth":1,"borderColor":"rgba(0, 0, 0, 1)","borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="971571" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="321877" value={ReactSystemFunctions.translate(this.ml, 321877, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</KCol>
</KRow>

<KRow id="415071" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="494903" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<HorizontalStack id="891517" direction="horizontal" size={12} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Form.Item className='kFormItem' name='parqformcopy_485590_value' >
<Checkbox id="485590" onChange={ (e?: any) => { if(e && e.stopPropagation) e.stopPropagation();this.ParQFormcopyComponent_485590_onChange()} } children={ReactSystemFunctions.translate(this.ml, 485590, "children",this.defaultML)} disabled={false} style={{"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"16px","color":"rgba(255, 255, 255, 1)","lineHeight":"22px"} as any}></Checkbox>
</Form.Item>

<Label id="694573" value={ReactSystemFunctions.translate(this.ml, 694573, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</HorizontalStack>
</KCol>
</KRow>

<KRow id="494450" visibility={this.state.isComp494450Visible} condition={(rowData) => ReactSystemFunctions.isEqualTo("", "true")} align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="892138" xs={22} sm={22} md={22} lg={22} xl={22} xxl={22} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":15,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KRow id="403846" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="399400" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":8,"paddingBottom":8,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<HorizontalStack id="866711" direction="horizontal" size={12} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Form.Item className='kFormItem' name='parqformcopy_527506_value' >
<Checkbox id="527506" children={ReactSystemFunctions.translate(this.ml, 527506, "children",this.defaultML)} disabled={false} style={{"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"16px","color":"rgba(255, 255, 255, 1)","lineHeight":"22px"} as any}></Checkbox>
</Form.Item>

<Label id="50042" value={ReactSystemFunctions.translate(this.ml, 50042, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</HorizontalStack>
</KCol>
</KRow>

<KRow id="878666" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="665756" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":8,"paddingBottom":8,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<HorizontalStack id="760437" direction="horizontal" size={12} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Form.Item className='kFormItem' name='parqformcopy_139967_value' >
<Checkbox id="139967" children={ReactSystemFunctions.translate(this.ml, 139967, "children",this.defaultML)} disabled={false} style={{"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"16px","color":"rgba(255, 255, 255, 1)","lineHeight":"22px"} as any}></Checkbox>
</Form.Item>

<Label id="5157" value={ReactSystemFunctions.translate(this.ml, 5157, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</HorizontalStack>
</KCol>
</KRow>

<KRow id="72176" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="918072" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":8,"paddingBottom":8,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<HorizontalStack id="865062" direction="horizontal" size={12} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Form.Item className='kFormItem' name='parqformcopy_426278_value' >
<Checkbox id="426278" children={ReactSystemFunctions.translate(this.ml, 426278, "children",this.defaultML)} disabled={false} style={{"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"16px","color":"rgba(255, 255, 255, 1)","lineHeight":"22px"} as any}></Checkbox>
</Form.Item>

<Label id="29101" value={ReactSystemFunctions.translate(this.ml, 29101, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</HorizontalStack>
</KCol>
</KRow>
</KCol>
</KRow>

<KRow id="485403" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="71652" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<HorizontalStack id="14543" direction="horizontal" size={12} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Form.Item className='kFormItem' name='parqformcopy_925296_value' >
<Checkbox id="925296" onChange={ (e?: any) => { if(e && e.stopPropagation) e.stopPropagation();this.ParQFormcopyComponent_925296_onChange()} } children={ReactSystemFunctions.translate(this.ml, 925296, "children",this.defaultML)} disabled={false} style={{"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"16px","color":"rgba(255, 255, 255, 1)","lineHeight":"22px"} as any}></Checkbox>
</Form.Item>

<Label id="982948" value={ReactSystemFunctions.translate(this.ml, 982948, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</HorizontalStack>
</KCol>
</KRow>

<KRow id="129797" visibility={this.state.isComp129797Visible} condition={(rowData) => ReactSystemFunctions.isEqualTo("", "true")} align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="502091" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":15,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KRow id="177732" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="105219" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":8,"paddingBottom":8,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<HorizontalStack id="774487" direction="horizontal" size={12} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Form.Item className='kFormItem' name='parqformcopy_726714_value' >
<Checkbox id="726714" children={ReactSystemFunctions.translate(this.ml, 726714, "children",this.defaultML)} disabled={false} style={{"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"16px","color":"rgba(255, 255, 255, 1)","lineHeight":"22px"} as any}></Checkbox>
</Form.Item>

<Label id="866182" value={ReactSystemFunctions.translate(this.ml, 866182, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</HorizontalStack>
</KCol>
</KRow>

<KRow id="969854" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="577975" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":8,"paddingBottom":8,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<HorizontalStack id="971375" direction="horizontal" size={12} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Form.Item className='kFormItem' name='parqformcopy_289204_value' >
<Checkbox id="289204" children={ReactSystemFunctions.translate(this.ml, 289204, "children",this.defaultML)} disabled={false} style={{"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"16px","color":"rgba(255, 255, 255, 1)","lineHeight":"22px"} as any}></Checkbox>
</Form.Item>

<Label id="551692" value={ReactSystemFunctions.translate(this.ml, 551692, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</HorizontalStack>
</KCol>
</KRow>

<KRow id="712305" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="2676" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":8,"paddingBottom":8,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<HorizontalStack id="497863" direction="horizontal" size={12} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Form.Item className='kFormItem' name='parqformcopy_752131_value' >
<Checkbox id="752131" children={ReactSystemFunctions.translate(this.ml, 752131, "children",this.defaultML)} disabled={false} style={{"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"16px","color":"rgba(255, 255, 255, 1)","lineHeight":"22px"} as any}></Checkbox>
</Form.Item>

<Label id="434693" value={ReactSystemFunctions.translate(this.ml, 434693, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</HorizontalStack>
</KCol>
</KRow>
</KCol>
</KRow>

<KRow id="165687" visibility={this.state.isComp165687Visible} align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="238366" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<VerticalStack id="264779" direction="vertical" size={12} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="518513" value={ReactSystemFunctions.translate(this.ml, 518513, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>

<KRow id="172146" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="947316" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<HorizontalStack id="623209" direction="horizontal" size={12} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="752085" value={ReactSystemFunctions.translate(this.ml, 752085, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>

<Form.Item className='kFormItem' name='parqformcopy_966189_value' >
<Switch id="966189" onChange={ (e?: any) => { if(e && e.stopPropagation) e.stopPropagation();this.ParQFormcopyComponent_966189_onChange()} } disabled={false} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Switch>
</Form.Item>

<Label id="514435" value={ReactSystemFunctions.translate(this.ml, 514435, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</HorizontalStack>
</KCol>
</KRow>
</VerticalStack>

<KRow id="164785" visibility={this.state.isComp164785Visible} condition={(rowData) => ReactSystemFunctions.isNotEqualTo("", "false")} align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="861890" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<VerticalStack id="196063" direction="vertical" size={12} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="477169" value={ReactSystemFunctions.translate(this.ml, 477169, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>

<KRow id="880811" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="753981" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<HorizontalStack id="71680" direction="horizontal" size={12} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="888772" value={ReactSystemFunctions.translate(this.ml, 888772, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>

<Form.Item className='kFormItem' name='parqformcopy_135425_value' >
<Switch id="135425" disabled={false} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Switch>
</Form.Item>

<Label id="421553" value={ReactSystemFunctions.translate(this.ml, 421553, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</HorizontalStack>
</KCol>
</KRow>
</VerticalStack>
</KCol>
</KRow>
</KCol>
</KRow>
</KCol>
</KRow>

<KRow id="979027" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="942656" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderTopLeftRadius":8,"borderTopRightRadius":8,"borderBottomRightRadius":8,"borderBottomLeftRadius":8,"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KRow id="350733" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderBottomWidth":1,"borderColor":"rgba(0, 0, 0, 1)","borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="594292" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="861037" value={ReactSystemFunctions.translate(this.ml, 861037, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</KCol>
</KRow>

<KRow id="196475" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="304912" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<HorizontalStack id="767572" direction="horizontal" size={12} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="349331" value={ReactSystemFunctions.translate(this.ml, 349331, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>

<Form.Item className='kFormItem' name='parqformcopy_382369_value' >
<Switch id="382369" onChange={ (e?: any) => { if(e && e.stopPropagation) e.stopPropagation();this.ParQFormcopyComponent_382369_onChange()} } disabled={false} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Switch>
</Form.Item>

<Label id="432985" value={ReactSystemFunctions.translate(this.ml, 432985, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</HorizontalStack>
</KCol>
</KRow>

<KRow id="499458" visibility={this.state.isComp499458Visible} condition={(rowData) => ReactSystemFunctions.isNotEqualTo(this.state.FormParQById[0]?.s5?.toString(), "false")} align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="494078" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":15,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KRow id="86638" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="890242" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":8,"paddingBottom":8,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<HorizontalStack id="602417" direction="horizontal" size={12} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Form.Item className='kFormItem' name='parqformcopy_477451_value' >
<Checkbox id="477451" onChange={ (e?: any) => { if(e && e.stopPropagation) e.stopPropagation();this.ParQFormcopyComponent_477451_onChange()} } children={ReactSystemFunctions.translate(this.ml, 477451, "children",this.defaultML)} disabled={false} style={{"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"16px","color":"rgba(255, 255, 255, 1)","lineHeight":"22px"} as any}></Checkbox>
</Form.Item>

<Label id="47450" value={ReactSystemFunctions.translate(this.ml, 47450, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</HorizontalStack>
</KCol>
</KRow>

<KRow id="629321" visibility={this.state.isComp629321Visible} condition={(rowData) => ReactSystemFunctions.isEqualTo("", "true")} align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="74660" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="332615" value={ReactSystemFunctions.translate(this.ml, 332615, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":400,"fontSize":"12px","color":"rgba(10, 33, 64, 1)","lineHeight":"20px"} as any}></Label>

<Form.Item className='kFormItem' name='parqformcopy_103815_value' >
<TextInput id="103815" placeholder={ReactSystemFunctions.translate(this.ml, 103815, "placeholder",this.defaultML)} allowClear={false} bordered={true} disabled={false} type="text" iconColor="rgba(0, 0, 0, 1)" formatter="None" isCharOnly={false} autoComplete={true} style={{"borderTopLeftRadius":8,"borderTopRightRadius":8,"borderBottomRightRadius":8,"borderBottomLeftRadius":8,"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"center","textAlign":"-webkit-left","display":"block","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></TextInput>
</Form.Item>
</KCol>
</KRow>

<KRow id="307623" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="619147" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":8,"paddingBottom":8,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<HorizontalStack id="173988" direction="horizontal" size={12} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Form.Item className='kFormItem' name='parqformcopy_75034_value' >
<Checkbox id="75034" onChange={ (e?: any) => { if(e && e.stopPropagation) e.stopPropagation();this.ParQFormcopyComponent_75034_onChange()} } children={ReactSystemFunctions.translate(this.ml, 75034, "children",this.defaultML)} disabled={false} style={{"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"16px","color":"rgba(255, 255, 255, 1)","lineHeight":"22px"} as any}></Checkbox>
</Form.Item>

<Label id="810830" value={ReactSystemFunctions.translate(this.ml, 810830, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</HorizontalStack>
</KCol>
</KRow>

<KRow id="908768" visibility={this.state.isComp908768Visible} condition={(rowData) => ReactSystemFunctions.isEqualTo("", "true")} align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="891904" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="689354" value={ReactSystemFunctions.translate(this.ml, 689354, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":400,"fontSize":"12px","color":"rgba(10, 33, 64, 1)","lineHeight":"20px"} as any}></Label>

<Form.Item className='kFormItem' name='parqformcopy_654161_value' >
<TextInput id="654161" placeholder={ReactSystemFunctions.translate(this.ml, 654161, "placeholder",this.defaultML)} allowClear={false} bordered={true} disabled={false} type="text" iconColor="rgba(0, 0, 0, 1)" formatter="None" isCharOnly={false} autoComplete={true} style={{"borderTopLeftRadius":8,"borderTopRightRadius":8,"borderBottomRightRadius":8,"borderBottomLeftRadius":8,"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"center","textAlign":"-webkit-left","display":"block","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></TextInput>
</Form.Item>
</KCol>
</KRow>

<KRow id="173635" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="82629" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":8,"paddingBottom":8,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<HorizontalStack id="304539" direction="horizontal" size={12} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Form.Item className='kFormItem' name='parqformcopy_85355_value' >
<Checkbox id="85355" onChange={ (e?: any) => { if(e && e.stopPropagation) e.stopPropagation();this.ParQFormcopyComponent_85355_onChange()} } children={ReactSystemFunctions.translate(this.ml, 85355, "children",this.defaultML)} disabled={false} style={{"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"16px","color":"rgba(255, 255, 255, 1)","lineHeight":"22px"} as any}></Checkbox>
</Form.Item>

<Label id="783708" value={ReactSystemFunctions.translate(this.ml, 783708, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</HorizontalStack>
</KCol>
</KRow>

<KRow id="661928" visibility={this.state.isComp661928Visible} condition={(rowData) => ReactSystemFunctions.isEqualTo("", "true")} align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="134532" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="877911" value={ReactSystemFunctions.translate(this.ml, 877911, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":400,"fontSize":"12px","color":"rgba(10, 33, 64, 1)","lineHeight":"20px"} as any}></Label>

<Form.Item className='kFormItem' name='parqformcopy_445930_value' >
<TextInput id="445930" placeholder={ReactSystemFunctions.translate(this.ml, 445930, "placeholder",this.defaultML)} allowClear={false} bordered={true} disabled={false} type="text" iconColor="rgba(0, 0, 0, 1)" formatter="None" isCharOnly={false} autoComplete={true} style={{"borderTopLeftRadius":8,"borderTopRightRadius":8,"borderBottomRightRadius":8,"borderBottomLeftRadius":8,"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"center","textAlign":"-webkit-left","display":"block","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></TextInput>
</Form.Item>
</KCol>
</KRow>

<KRow id="253073" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="118696" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":8,"paddingBottom":8,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<HorizontalStack id="98068" direction="horizontal" size={12} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Form.Item className='kFormItem' name='parqformcopy_38196_value' >
<Checkbox id="38196" onChange={ (e?: any) => { if(e && e.stopPropagation) e.stopPropagation();this.ParQFormcopyComponent_38196_onChange()} } children={ReactSystemFunctions.translate(this.ml, 38196, "children",this.defaultML)} disabled={false} style={{"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"16px","color":"rgba(255, 255, 255, 1)","lineHeight":"22px"} as any}></Checkbox>
</Form.Item>

<Label id="363855" value={ReactSystemFunctions.translate(this.ml, 363855, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</HorizontalStack>
</KCol>
</KRow>

<KRow id="33607" visibility={this.state.isComp33607Visible} condition={(rowData) => ReactSystemFunctions.isEqualTo("", "true")} align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="7352" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="243374" value={ReactSystemFunctions.translate(this.ml, 243374, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":400,"fontSize":"12px","color":"rgba(10, 33, 64, 1)","lineHeight":"20px"} as any}></Label>

<Form.Item className='kFormItem' name='parqformcopy_342330_value' >
<TextInput id="342330" placeholder={ReactSystemFunctions.translate(this.ml, 342330, "placeholder",this.defaultML)} allowClear={false} bordered={true} disabled={false} type="text" iconColor="rgba(0, 0, 0, 1)" formatter="None" isCharOnly={false} autoComplete={true} style={{"borderTopLeftRadius":8,"borderTopRightRadius":8,"borderBottomRightRadius":8,"borderBottomLeftRadius":8,"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"center","textAlign":"-webkit-left","display":"block","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></TextInput>
</Form.Item>
</KCol>
</KRow>

<KRow id="119045" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="125804" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":8,"paddingBottom":8,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<HorizontalStack id="959191" direction="horizontal" size={12} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Form.Item className='kFormItem' name='parqformcopy_918726_value' >
<Checkbox id="918726" onChange={ (e?: any) => { if(e && e.stopPropagation) e.stopPropagation();this.ParQFormcopyComponent_918726_onChange()} } children={ReactSystemFunctions.translate(this.ml, 918726, "children",this.defaultML)} disabled={false} style={{"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"16px","color":"rgba(255, 255, 255, 1)","lineHeight":"22px"} as any}></Checkbox>
</Form.Item>

<Label id="58800" value={ReactSystemFunctions.translate(this.ml, 58800, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</HorizontalStack>
</KCol>
</KRow>

<KRow id="388124" visibility={this.state.isComp388124Visible} condition={(rowData) => ReactSystemFunctions.isEqualTo("", "true")} align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="953206" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="522812" value={ReactSystemFunctions.translate(this.ml, 522812, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":400,"fontSize":"12px","color":"rgba(10, 33, 64, 1)","lineHeight":"20px"} as any}></Label>

<Form.Item className='kFormItem' name='parqformcopy_339185_value' >
<TextInput id="339185" placeholder={ReactSystemFunctions.translate(this.ml, 339185, "placeholder",this.defaultML)} allowClear={false} bordered={true} disabled={false} type="text" iconColor="rgba(0, 0, 0, 1)" formatter="None" isCharOnly={false} autoComplete={true} style={{"borderTopLeftRadius":8,"borderTopRightRadius":8,"borderBottomRightRadius":8,"borderBottomLeftRadius":8,"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"center","textAlign":"-webkit-left","display":"block","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></TextInput>
</Form.Item>
</KCol>
</KRow>
</KCol>
</KRow>
</KCol>
</KRow>

<KRow id="585225" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="127099" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderTopLeftRadius":8,"borderTopRightRadius":8,"borderBottomRightRadius":8,"borderBottomLeftRadius":8,"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KRow id="493556" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderBottomWidth":1,"borderColor":"rgba(0, 0, 0, 1)","borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="394283" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="804090" value={ReactSystemFunctions.translate(this.ml, 804090, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</KCol>
</KRow>

<KRow id="619224" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="467076" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<HorizontalStack id="671438" direction="horizontal" size={12} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="666375" value={ReactSystemFunctions.translate(this.ml, 666375, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>

<Form.Item className='kFormItem' name='parqformcopy_930924_value' >
<Switch id="930924" onChange={ (e?: any) => { if(e && e.stopPropagation) e.stopPropagation();this.ParQFormcopyComponent_930924_onChange()} } disabled={false} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Switch>
</Form.Item>

<Label id="79250" value={ReactSystemFunctions.translate(this.ml, 79250, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</HorizontalStack>
</KCol>
</KRow>

<KRow id="215453" visibility={this.state.isComp215453Visible} condition={(rowData) => ReactSystemFunctions.isNotEqualTo(this.state.FormParQById[0]?.s6?.toString(), "false")} align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="679493" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":15,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KRow id="316767" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="330797" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":8,"paddingBottom":8,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<HorizontalStack id="246003" direction="horizontal" size={12} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Form.Item className='kFormItem' name='parqformcopy_666388_value' >
<Checkbox id="666388" onChange={ (e?: any) => { if(e && e.stopPropagation) e.stopPropagation();this.ParQFormcopyComponent_666388_onChange()} } children={ReactSystemFunctions.translate(this.ml, 666388, "children",this.defaultML)} disabled={false} style={{"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"16px","color":"rgba(255, 255, 255, 1)","lineHeight":"22px"} as any}></Checkbox>
</Form.Item>

<Label id="171841" value={ReactSystemFunctions.translate(this.ml, 171841, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</HorizontalStack>
</KCol>
</KRow>

<KRow id="311202" visibility={this.state.isComp311202Visible} condition={(rowData) => ReactSystemFunctions.isEqualTo("", "true")} align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="285324" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KRow id="512901" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="471890" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<VerticalStack id="647216" direction="vertical" size={5} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="734857" value={ReactSystemFunctions.translate(this.ml, 734857, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>

<Form.Item className='kFormItem' name='parqformcopy_713132_value' >
<Checkbox id="713132" children={ReactSystemFunctions.translate(this.ml, 713132, "children",this.defaultML)} disabled={false} style={{"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"16px","color":"rgba(255, 255, 255, 1)","lineHeight":"22px"} as any}></Checkbox>
</Form.Item>
</VerticalStack>
</KCol>
</KRow>

<KRow id="786486" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="165271" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<VerticalStack id="466021" direction="vertical" size={5} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="735732" value={ReactSystemFunctions.translate(this.ml, 735732, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>

<Form.Item className='kFormItem' name='parqformcopy_154499_value' >
<Checkbox id="154499" children={ReactSystemFunctions.translate(this.ml, 154499, "children",this.defaultML)} disabled={false} style={{"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"16px","color":"rgba(255, 255, 255, 1)","lineHeight":"22px"} as any}></Checkbox>
</Form.Item>
</VerticalStack>
</KCol>
</KRow>

<KRow id="914514" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="911937" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<VerticalStack id="545114" direction="vertical" size={5} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="91016" value={ReactSystemFunctions.translate(this.ml, 91016, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>

<Form.Item className='kFormItem' name='parqformcopy_867208_value' >
<Checkbox id="867208" children={ReactSystemFunctions.translate(this.ml, 867208, "children",this.defaultML)} disabled={false} style={{"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"16px","color":"rgba(255, 255, 255, 1)","lineHeight":"22px"} as any}></Checkbox>
</Form.Item>
</VerticalStack>
</KCol>
</KRow>

<KRow id="960956" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="457100" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<VerticalStack id="567796" direction="vertical" size={5} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="130735" value={ReactSystemFunctions.translate(this.ml, 130735, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>

<Form.Item className='kFormItem' name='parqformcopy_622758_value' >
<Checkbox id="622758" children={ReactSystemFunctions.translate(this.ml, 622758, "children",this.defaultML)} disabled={false} style={{"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"16px","color":"rgba(255, 255, 255, 1)","lineHeight":"22px"} as any}></Checkbox>
</Form.Item>
</VerticalStack>
</KCol>
</KRow>

<KRow id="178001" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="478982" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<VerticalStack id="790804" direction="vertical" size={5} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="75184" value={ReactSystemFunctions.translate(this.ml, 75184, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>

<Form.Item className='kFormItem' name='parqformcopy_590152_value' >
<Checkbox id="590152" children={ReactSystemFunctions.translate(this.ml, 590152, "children",this.defaultML)} disabled={false} style={{"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"16px","color":"rgba(255, 255, 255, 1)","lineHeight":"22px"} as any}></Checkbox>
</Form.Item>
</VerticalStack>
</KCol>
</KRow>
</KCol>
</KRow>

<KRow id="791912" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="784037" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":8,"paddingBottom":8,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<HorizontalStack id="64827" direction="horizontal" size={12} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Form.Item className='kFormItem' name='parqformcopy_700493_value' >
<Checkbox id="700493" onChange={ (e?: any) => { if(e && e.stopPropagation) e.stopPropagation();this.ParQFormcopyComponent_700493_onChange()} } children={ReactSystemFunctions.translate(this.ml, 700493, "children",this.defaultML)} disabled={false} style={{"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"16px","color":"rgba(255, 255, 255, 1)","lineHeight":"22px"} as any}></Checkbox>
</Form.Item>

<Label id="271513" value={ReactSystemFunctions.translate(this.ml, 271513, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</HorizontalStack>
</KCol>
</KRow>

<KRow id="389701" visibility={this.state.isComp389701Visible} condition={(rowData) => ReactSystemFunctions.isEqualTo("", "true")} align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="740218" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KRow id="296495" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="188743" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<VerticalStack id="525259" direction="vertical" size={5} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="29143" value={ReactSystemFunctions.translate(this.ml, 29143, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>

<Form.Item className='kFormItem' name='parqformcopy_528451_value' >
<Checkbox id="528451" children={ReactSystemFunctions.translate(this.ml, 528451, "children",this.defaultML)} disabled={false} style={{"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"16px","color":"rgba(255, 255, 255, 1)","lineHeight":"22px"} as any}></Checkbox>
</Form.Item>
</VerticalStack>
</KCol>
</KRow>

<KRow id="378041" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="787765" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<VerticalStack id="487463" direction="vertical" size={5} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="395102" value={ReactSystemFunctions.translate(this.ml, 395102, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>

<Form.Item className='kFormItem' name='parqformcopy_581396_value' >
<Checkbox id="581396" children={ReactSystemFunctions.translate(this.ml, 581396, "children",this.defaultML)} disabled={false} style={{"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"16px","color":"rgba(255, 255, 255, 1)","lineHeight":"22px"} as any}></Checkbox>
</Form.Item>
</VerticalStack>
</KCol>
</KRow>

<KRow id="823456" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="770630" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<VerticalStack id="29968" direction="vertical" size={5} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="578435" value={ReactSystemFunctions.translate(this.ml, 578435, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>

<Form.Item className='kFormItem' name='parqformcopy_165036_value' >
<Checkbox id="165036" children={ReactSystemFunctions.translate(this.ml, 165036, "children",this.defaultML)} disabled={false} style={{"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"16px","color":"rgba(255, 255, 255, 1)","lineHeight":"22px"} as any}></Checkbox>
</Form.Item>
</VerticalStack>
</KCol>
</KRow>

<KRow id="189842" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="905938" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<VerticalStack id="766177" direction="vertical" size={5} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="491809" value={ReactSystemFunctions.translate(this.ml, 491809, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>

<Form.Item className='kFormItem' name='parqformcopy_996331_value' >
<Checkbox id="996331" children={ReactSystemFunctions.translate(this.ml, 996331, "children",this.defaultML)} disabled={false} style={{"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"16px","color":"rgba(255, 255, 255, 1)","lineHeight":"22px"} as any}></Checkbox>
</Form.Item>
</VerticalStack>
</KCol>
</KRow>

<KRow id="947754" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="685039" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<VerticalStack id="623975" direction="vertical" size={5} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="619032" value={ReactSystemFunctions.translate(this.ml, 619032, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>

<Form.Item className='kFormItem' name='parqformcopy_761897_value' >
<Checkbox id="761897" children={ReactSystemFunctions.translate(this.ml, 761897, "children",this.defaultML)} disabled={false} style={{"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"16px","color":"rgba(255, 255, 255, 1)","lineHeight":"22px"} as any}></Checkbox>
</Form.Item>
</VerticalStack>
</KCol>
</KRow>

<KRow id="929223" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="263257" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<VerticalStack id="174566" direction="vertical" size={5} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="541070" value={ReactSystemFunctions.translate(this.ml, 541070, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>

<Form.Item className='kFormItem' name='parqformcopy_782070_value' >
<Checkbox id="782070" children={ReactSystemFunctions.translate(this.ml, 782070, "children",this.defaultML)} disabled={false} style={{"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"16px","color":"rgba(255, 255, 255, 1)","lineHeight":"22px"} as any}></Checkbox>
</Form.Item>
</VerticalStack>
</KCol>
</KRow>

<KRow id="417391" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="419522" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<VerticalStack id="174366" direction="vertical" size={5} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="481603" value={ReactSystemFunctions.translate(this.ml, 481603, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>

<Form.Item className='kFormItem' name='parqformcopy_590758_value' >
<Checkbox id="590758" children={ReactSystemFunctions.translate(this.ml, 590758, "children",this.defaultML)} disabled={false} style={{"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"16px","color":"rgba(255, 255, 255, 1)","lineHeight":"22px"} as any}></Checkbox>
</Form.Item>
</VerticalStack>
</KCol>
</KRow>
</KCol>
</KRow>

<KRow id="832622" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="367595" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":8,"paddingBottom":8,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<HorizontalStack id="14785" direction="horizontal" size={12} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Form.Item className='kFormItem' name='parqformcopy_847131_value' >
<Checkbox id="847131" onChange={ (e?: any) => { if(e && e.stopPropagation) e.stopPropagation();this.ParQFormcopyComponent_847131_onChange()} } children={ReactSystemFunctions.translate(this.ml, 847131, "children",this.defaultML)} disabled={false} style={{"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"16px","color":"rgba(255, 255, 255, 1)","lineHeight":"22px"} as any}></Checkbox>
</Form.Item>

<Label id="543653" value={ReactSystemFunctions.translate(this.ml, 543653, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</HorizontalStack>
</KCol>
</KRow>

<KRow id="346751" visibility={this.state.isComp346751Visible} condition={(rowData) => ReactSystemFunctions.isEqualTo("", "true")} align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="289825" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KRow id="836979" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="212440" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<VerticalStack id="573861" direction="vertical" size={5} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="92447" value={ReactSystemFunctions.translate(this.ml, 92447, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>

<Form.Item className='kFormItem' name='parqformcopy_996970_value' >
<Checkbox id="996970" children={ReactSystemFunctions.translate(this.ml, 996970, "children",this.defaultML)} disabled={false} style={{"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"16px","color":"rgba(255, 255, 255, 1)","lineHeight":"22px"} as any}></Checkbox>
</Form.Item>
</VerticalStack>
</KCol>
</KRow>

<KRow id="756688" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="243126" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<VerticalStack id="11708" direction="vertical" size={5} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="154024" value={ReactSystemFunctions.translate(this.ml, 154024, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>

<Form.Item className='kFormItem' name='parqformcopy_736647_value' >
<Checkbox id="736647" children={ReactSystemFunctions.translate(this.ml, 736647, "children",this.defaultML)} disabled={false} style={{"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"16px","color":"rgba(255, 255, 255, 1)","lineHeight":"22px"} as any}></Checkbox>
</Form.Item>
</VerticalStack>
</KCol>
</KRow>

<KRow id="886304" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="382875" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<VerticalStack id="768894" direction="vertical" size={5} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="537637" value={ReactSystemFunctions.translate(this.ml, 537637, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>

<Form.Item className='kFormItem' name='parqformcopy_718707_value' >
<Checkbox id="718707" children={ReactSystemFunctions.translate(this.ml, 718707, "children",this.defaultML)} disabled={false} style={{"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"16px","color":"rgba(255, 255, 255, 1)","lineHeight":"22px"} as any}></Checkbox>
</Form.Item>
</VerticalStack>
</KCol>
</KRow>

<KRow id="714467" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="586211" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<VerticalStack id="14273" direction="vertical" size={5} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="275155" value={ReactSystemFunctions.translate(this.ml, 275155, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>

<Form.Item className='kFormItem' name='parqformcopy_285485_value' >
<Checkbox id="285485" children={ReactSystemFunctions.translate(this.ml, 285485, "children",this.defaultML)} disabled={false} style={{"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"16px","color":"rgba(255, 255, 255, 1)","lineHeight":"22px"} as any}></Checkbox>
</Form.Item>
</VerticalStack>
</KCol>
</KRow>

<KRow id="812996" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="410934" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<VerticalStack id="584271" direction="vertical" size={5} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="916059" value={ReactSystemFunctions.translate(this.ml, 916059, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>

<Form.Item className='kFormItem' name='parqformcopy_710319_value' >
<Checkbox id="710319" children={ReactSystemFunctions.translate(this.ml, 710319, "children",this.defaultML)} disabled={false} style={{"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"16px","color":"rgba(255, 255, 255, 1)","lineHeight":"22px"} as any}></Checkbox>
</Form.Item>
</VerticalStack>
</KCol>
</KRow>

<KRow id="733426" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="963149" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<VerticalStack id="333021" direction="vertical" size={5} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="24314" value={ReactSystemFunctions.translate(this.ml, 24314, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>

<Form.Item className='kFormItem' name='parqformcopy_659579_value' >
<Checkbox id="659579" children={ReactSystemFunctions.translate(this.ml, 659579, "children",this.defaultML)} disabled={false} style={{"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"16px","color":"rgba(255, 255, 255, 1)","lineHeight":"22px"} as any}></Checkbox>
</Form.Item>
</VerticalStack>
</KCol>
</KRow>

<KRow id="585329" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="827589" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<VerticalStack id="655734" direction="vertical" size={5} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="76225" value={ReactSystemFunctions.translate(this.ml, 76225, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>

<Form.Item className='kFormItem' name='parqformcopy_257868_value' >
<Checkbox id="257868" children={ReactSystemFunctions.translate(this.ml, 257868, "children",this.defaultML)} disabled={false} style={{"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"16px","color":"rgba(255, 255, 255, 1)","lineHeight":"22px"} as any}></Checkbox>
</Form.Item>
</VerticalStack>
</KCol>
</KRow>

<KRow id="846656" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="398511" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<VerticalStack id="617020" direction="vertical" size={5} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="611441" value={ReactSystemFunctions.translate(this.ml, 611441, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>

<Form.Item className='kFormItem' name='parqformcopy_560619_value' >
<Checkbox id="560619" children={ReactSystemFunctions.translate(this.ml, 560619, "children",this.defaultML)} disabled={false} style={{"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"16px","color":"rgba(255, 255, 255, 1)","lineHeight":"22px"} as any}></Checkbox>
</Form.Item>
</VerticalStack>
</KCol>
</KRow>

<KRow id="623054" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="131204" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<VerticalStack id="390697" direction="vertical" size={5} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="998704" value={ReactSystemFunctions.translate(this.ml, 998704, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>

<Form.Item className='kFormItem' name='parqformcopy_275784_value' >
<Checkbox id="275784" children={ReactSystemFunctions.translate(this.ml, 275784, "children",this.defaultML)} disabled={false} style={{"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"16px","color":"rgba(255, 255, 255, 1)","lineHeight":"22px"} as any}></Checkbox>
</Form.Item>
</VerticalStack>
</KCol>
</KRow>
</KCol>
</KRow>

<KRow id="747540" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="898444" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":8,"paddingBottom":8,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<HorizontalStack id="897527" direction="horizontal" size={12} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Form.Item className='kFormItem' name='parqformcopy_736437_value' >
<Checkbox id="736437" onChange={ (e?: any) => { if(e && e.stopPropagation) e.stopPropagation();this.ParQFormcopyComponent_736437_onChange()} } children={ReactSystemFunctions.translate(this.ml, 736437, "children",this.defaultML)} disabled={false} style={{"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"16px","color":"rgba(255, 255, 255, 1)","lineHeight":"22px"} as any}></Checkbox>
</Form.Item>

<Label id="455997" value={ReactSystemFunctions.translate(this.ml, 455997, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</HorizontalStack>
</KCol>
</KRow>

<KRow id="950581" visibility={this.state.isComp950581Visible} condition={(rowData) => ReactSystemFunctions.isEqualTo("", "true")} align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="542579" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KRow id="272279" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="112893" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<VerticalStack id="952126" direction="vertical" size={5} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="427024" value={ReactSystemFunctions.translate(this.ml, 427024, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>

<Form.Item className='kFormItem' name='parqformcopy_883656_value' >
<Checkbox id="883656" children={ReactSystemFunctions.translate(this.ml, 883656, "children",this.defaultML)} disabled={false} style={{"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"16px","color":"rgba(255, 255, 255, 1)","lineHeight":"22px"} as any}></Checkbox>
</Form.Item>
</VerticalStack>
</KCol>
</KRow>

<KRow id="47174" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="903319" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<VerticalStack id="576851" direction="vertical" size={5} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="592129" value={ReactSystemFunctions.translate(this.ml, 592129, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>

<Form.Item className='kFormItem' name='parqformcopy_627907_value' >
<Checkbox id="627907" children={ReactSystemFunctions.translate(this.ml, 627907, "children",this.defaultML)} disabled={false} style={{"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"16px","color":"rgba(255, 255, 255, 1)","lineHeight":"22px"} as any}></Checkbox>
</Form.Item>
</VerticalStack>
</KCol>
</KRow>

<KRow id="160560" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="163405" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<VerticalStack id="20926" direction="vertical" size={5} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="624899" value={ReactSystemFunctions.translate(this.ml, 624899, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>

<Form.Item className='kFormItem' name='parqformcopy_893733_value' >
<Checkbox id="893733" children={ReactSystemFunctions.translate(this.ml, 893733, "children",this.defaultML)} disabled={false} style={{"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"16px","color":"rgba(255, 255, 255, 1)","lineHeight":"22px"} as any}></Checkbox>
</Form.Item>
</VerticalStack>
</KCol>
</KRow>

<KRow id="261898" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="631249" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<VerticalStack id="580323" direction="vertical" size={5} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="634418" value={ReactSystemFunctions.translate(this.ml, 634418, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>

<Form.Item className='kFormItem' name='parqformcopy_786497_value' >
<Checkbox id="786497" children={ReactSystemFunctions.translate(this.ml, 786497, "children",this.defaultML)} disabled={false} style={{"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"16px","color":"rgba(255, 255, 255, 1)","lineHeight":"22px"} as any}></Checkbox>
</Form.Item>
</VerticalStack>
</KCol>
</KRow>

<KRow id="800835" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="419920" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<VerticalStack id="275498" direction="vertical" size={5} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="103752" value={ReactSystemFunctions.translate(this.ml, 103752, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>

<Form.Item className='kFormItem' name='parqformcopy_365318_value' >
<Checkbox id="365318" children={ReactSystemFunctions.translate(this.ml, 365318, "children",this.defaultML)} disabled={false} style={{"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"16px","color":"rgba(255, 255, 255, 1)","lineHeight":"22px"} as any}></Checkbox>
</Form.Item>
</VerticalStack>
</KCol>
</KRow>

<KRow id="208456" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="180083" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<VerticalStack id="238968" direction="vertical" size={5} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="946837" value={ReactSystemFunctions.translate(this.ml, 946837, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>

<Form.Item className='kFormItem' name='parqformcopy_342249_value' >
<Checkbox id="342249" children={ReactSystemFunctions.translate(this.ml, 342249, "children",this.defaultML)} disabled={false} style={{"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"16px","color":"rgba(255, 255, 255, 1)","lineHeight":"22px"} as any}></Checkbox>
</Form.Item>
</VerticalStack>
</KCol>
</KRow>
</KCol>
</KRow>

<KRow id="447478" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="791881" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":8,"paddingBottom":8,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<HorizontalStack id="760875" direction="horizontal" size={12} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Form.Item className='kFormItem' name='parqformcopy_486661_value' >
<Checkbox id="486661" onChange={ (e?: any) => { if(e && e.stopPropagation) e.stopPropagation();this.ParQFormcopyComponent_486661_onChange()} } children={ReactSystemFunctions.translate(this.ml, 486661, "children",this.defaultML)} disabled={false} style={{"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"16px","color":"rgba(255, 255, 255, 1)","lineHeight":"22px"} as any}></Checkbox>
</Form.Item>

<Label id="957270" value={ReactSystemFunctions.translate(this.ml, 957270, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</HorizontalStack>
</KCol>
</KRow>

<KRow id="671904" visibility={this.state.isComp671904Visible} condition={(rowData) => ReactSystemFunctions.isEqualTo("", "true")} align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="376492" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":8,"paddingBottom":8,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KRow id="466273" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="661093" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KRow id="487459" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="184056" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KRow id="322472" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="887315" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="910320" value={ReactSystemFunctions.translate(this.ml, 910320, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":400,"fontSize":"12px","color":"rgba(10, 33, 64, 1)","lineHeight":"20px"} as any}></Label>

<Form.Item className='kFormItem' name='parqformcopy_739962_value' >
<TextInput id="739962" placeholder={ReactSystemFunctions.translate(this.ml, 739962, "placeholder",this.defaultML)} allowClear={false} bordered={true} disabled={false} type="text" iconColor="rgba(0, 0, 0, 1)" formatter="None" isCharOnly={false} autoComplete={true} style={{"borderTopLeftRadius":8,"borderTopRightRadius":8,"borderBottomRightRadius":8,"borderBottomLeftRadius":8,"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"center","textAlign":"-webkit-left","display":"block","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></TextInput>
</Form.Item>
</KCol>
</KRow>
</KCol>
</KRow>
</KCol>
</KRow>
</KCol>
</KRow>
</KCol>
</KRow>
</KCol>
</KRow>

<KRow id="301670" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="616415" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KRow id="206403" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderBottomWidth":1,"borderColor":"rgba(0, 0, 0, 1)","borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="380428" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="295033" value={ReactSystemFunctions.translate(this.ml, 295033, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</KCol>
</KRow>

<KRow id="223578" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="424639" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<HorizontalStack id="251852" direction="horizontal" size={12} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="617328" value={ReactSystemFunctions.translate(this.ml, 617328, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>

<Form.Item className='kFormItem' name='parqformcopy_926688_value' >
<Switch id="926688" onChange={ (e?: any) => { if(e && e.stopPropagation) e.stopPropagation();this.ParQFormcopyComponent_926688_onChange()} } disabled={false} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Switch>
</Form.Item>

<Label id="681680" value={ReactSystemFunctions.translate(this.ml, 681680, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</HorizontalStack>
</KCol>
</KRow>

<KRow id="189397" visibility={this.state.isComp189397Visible} condition={(rowData) => ReactSystemFunctions.isNotEqualTo(this.state.FormParQById[0]?.s7?.toString(), "false")} align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="708061" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KRow id="12956" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="533269" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KRow id="624182" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="214304" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<VerticalStack id="794487" direction="vertical" size={12} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<HorizontalStack id="748012" direction="horizontal" size={12} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<VerticalStack id="242941" direction="vertical" size={12} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Form.Item className='kFormItem' name='parqformcopy_74533_value' >
<Checkbox id="74533" children={ReactSystemFunctions.translate(this.ml, 74533, "children",this.defaultML)} disabled={false} style={{"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"16px","color":"rgba(255, 255, 255, 1)","lineHeight":"22px"} as any}></Checkbox>
</Form.Item>
</VerticalStack>

<Label id="244591" value={ReactSystemFunctions.translate(this.ml, 244591, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</HorizontalStack>
</VerticalStack>
</KCol>
</KRow>

<KRow id="882233" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="932004" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<VerticalStack id="870740" direction="vertical" size={12} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<HorizontalStack id="832369" direction="horizontal" size={12} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<VerticalStack id="919852" direction="vertical" size={12} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Form.Item className='kFormItem' name='parqformcopy_740586_value' >
<Checkbox id="740586" children={ReactSystemFunctions.translate(this.ml, 740586, "children",this.defaultML)} disabled={false} style={{"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"16px","color":"rgba(255, 255, 255, 1)","lineHeight":"22px"} as any}></Checkbox>
</Form.Item>
</VerticalStack>

<Label id="330244" value={ReactSystemFunctions.translate(this.ml, 330244, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</HorizontalStack>
</VerticalStack>
</KCol>
</KRow>

<KRow id="124961" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="52544" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<HorizontalStack id="417819" direction="horizontal" size={12} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Form.Item className='kFormItem' name='parqformcopy_216720_value' >
<Checkbox id="216720" onChange={ (e?: any) => { if(e && e.stopPropagation) e.stopPropagation();this.ParQFormcopyComponent_216720_onChange()} } children={ReactSystemFunctions.translate(this.ml, 216720, "children",this.defaultML)} disabled={false} style={{"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"16px","color":"rgba(255, 255, 255, 1)","lineHeight":"22px"} as any}></Checkbox>
</Form.Item>

<Label id="422118" value={ReactSystemFunctions.translate(this.ml, 422118, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"width":"100px","alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</HorizontalStack>

<KRow id="468286" visibility={this.state.isComp468286Visible} condition={(rowData) => ReactSystemFunctions.isEqualTo("", "true")} align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="336565" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="652993" value={ReactSystemFunctions.translate(this.ml, 652993, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":400,"fontSize":"12px","color":"rgba(10, 33, 64, 1)","lineHeight":"20px"} as any}></Label>

<Form.Item className='kFormItem' name='parqformcopy_357526_value' >
<TextInput id="357526" placeholder={ReactSystemFunctions.translate(this.ml, 357526, "placeholder",this.defaultML)} allowClear={false} bordered={true} disabled={false} type="text" iconColor="rgba(0, 0, 0, 1)" formatter="None" isCharOnly={false} autoComplete={true} style={{"borderTopLeftRadius":8,"borderTopRightRadius":8,"borderBottomRightRadius":8,"borderBottomLeftRadius":8,"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"center","textAlign":"-webkit-left","display":"block","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></TextInput>
</Form.Item>
</KCol>
</KRow>
</KCol>
</KRow>
</KCol>
</KRow>
</KCol>
</KRow>
</KCol>
</KRow>

<KRow id="166802" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="646113" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderTopLeftRadius":8,"borderTopRightRadius":8,"borderBottomRightRadius":8,"borderBottomLeftRadius":8,"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KRow id="851329" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderBottomWidth":1,"borderColor":"rgba(0, 0, 0, 1)","borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="52693" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="358320" value={ReactSystemFunctions.translate(this.ml, 358320, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</KCol>
</KRow>

<KRow id="671417" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="217612" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<HorizontalStack id="981370" direction="horizontal" size={12} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="553821" value={ReactSystemFunctions.translate(this.ml, 553821, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>

<Form.Item className='kFormItem' name='parqformcopy_413414_value' >
<Switch id="413414" onChange={ (e?: any) => { if(e && e.stopPropagation) e.stopPropagation();this.ParQFormcopyComponent_413414_onChange()} } disabled={false} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Switch>
</Form.Item>

<Label id="524338" value={ReactSystemFunctions.translate(this.ml, 524338, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</HorizontalStack>
</KCol>
</KRow>

<KRow id="943000" visibility={this.state.isComp943000Visible} condition={(rowData) => ReactSystemFunctions.isNotEqualTo(this.state.FormParQById[0]?.s8?.toString(), "false")} align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="271162" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KRow id="799339" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="174059" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KRow id="844047" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="894634" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<VerticalStack id="117301" direction="vertical" size={12} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<HorizontalStack id="521571" direction="horizontal" size={12} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="505635" value={ReactSystemFunctions.translate(this.ml, 505635, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</HorizontalStack>
</VerticalStack>

<Form.Item className='kFormItem' name='parqformcopy_673600_value' >
<NumberInput id="673600" decimalSeparator="." decimalScale={2} disabled={false} placeholder={ReactSystemFunctions.translate(this.ml, 673600, "placeholder",this.defaultML)} fixedDecimalScale={false} allowNegative={true} allowEmptyFormatting={false} isThousandSeperatorOn={true} style={{"borderTopLeftRadius":8,"borderTopRightRadius":8,"borderBottomRightRadius":8,"borderBottomLeftRadius":8,"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"center","textAlign":"-webkit-left","display":"block","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></NumberInput>
</Form.Item>
</KCol>
</KRow>

<KRow id="619519" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="733337" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<VerticalStack id="436279" direction="vertical" size={12} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<HorizontalStack id="87972" direction="horizontal" size={12} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="859604" value={ReactSystemFunctions.translate(this.ml, 859604, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</HorizontalStack>
</VerticalStack>

<Form.Item className='kFormItem' name='parqformcopy_161683_value' >
<NumberInput id="161683" decimalSeparator="." decimalScale={2} disabled={false} placeholder={ReactSystemFunctions.translate(this.ml, 161683, "placeholder",this.defaultML)} fixedDecimalScale={false} allowNegative={true} allowEmptyFormatting={false} isThousandSeperatorOn={true} style={{"borderTopLeftRadius":8,"borderTopRightRadius":8,"borderBottomRightRadius":8,"borderBottomLeftRadius":8,"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"center","textAlign":"-webkit-left","display":"block","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></NumberInput>
</Form.Item>
</KCol>
</KRow>

<KRow id="772121" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="692232" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<VerticalStack id="699842" direction="vertical" size={12} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<HorizontalStack id="50390" direction="horizontal" size={12} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="354030" value={ReactSystemFunctions.translate(this.ml, 354030, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</HorizontalStack>
</VerticalStack>

<Form.Item className='kFormItem' name='parqformcopy_656623_value' >
<NumberInput id="656623" decimalSeparator="." decimalScale={2} disabled={false} placeholder={ReactSystemFunctions.translate(this.ml, 656623, "placeholder",this.defaultML)} fixedDecimalScale={false} allowNegative={true} allowEmptyFormatting={false} isThousandSeperatorOn={true} style={{"borderTopLeftRadius":8,"borderTopRightRadius":8,"borderBottomRightRadius":8,"borderBottomLeftRadius":8,"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"center","textAlign":"-webkit-left","display":"block","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></NumberInput>
</Form.Item>
</KCol>
</KRow>
</KCol>
</KRow>
</KCol>
</KRow>
</KCol>
</KRow>

<KRow id="456452" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="951661" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-right","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Button id="74150" onClick={ (e?: any) => { if(e && e.stopPropagation) e.stopPropagation();this.ParQFormcopyComponent_74150_onClick()} } showCursorPointer visibility={this.state.isComp74150Visible} label={ReactSystemFunctions.translate(this.ml, 74150, "label",this.defaultML)} size="middle" loading={false} ghost={false} block={false} htmlType="button" iconPosition="left" danger={false} style={{"borderTopLeftRadius":8,"borderTopRightRadius":8,"borderBottomRightRadius":8,"borderBottomLeftRadius":8,"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"backgroundColor":"rgba(0, 190, 214, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":8,"paddingRight":16,"paddingBottom":8,"paddingLeft":16,"alignItems":"center","textAlign":"-webkit-center","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"16px","color":"rgba(255, 255, 255, 1)","lineHeight":"22px"} as any}></Button>

<Button id="518528" onClick={ (e?: any) => { if(e && e.stopPropagation) e.stopPropagation();this.ParQFormcopyComponent_518528_onClick()} } showCursorPointer visibility={this.state.isComp518528Visible} condition={(rowData) => ReactSystemFunctions.isNotEqualTo(this.props.screenInputs.NewEditView, "V")} label={ReactSystemFunctions.translate(this.ml, 518528, "label",this.defaultML)} size="middle" loading={false} ghost={false} block={false} htmlType="button" iconPosition="left" danger={false} style={{"borderTopLeftRadius":8,"borderTopRightRadius":8,"borderBottomRightRadius":8,"borderBottomLeftRadius":8,"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"backgroundColor":"rgba(0, 190, 214, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":8,"paddingRight":16,"paddingBottom":8,"paddingLeft":16,"alignItems":"center","textAlign":"-webkit-center","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"16px","color":"rgba(255, 255, 255, 1)","lineHeight":"22px"} as any}></Button>
</KCol>
</KRow>

<KRow id="949743" visibility={this.state.isComp949743Visible} align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="18995" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-center","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<VerticalStack id="684108" direction="vertical" size={12} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Form.Item className='kFormItem' name='parqformcopy_92022_value' >
<TextInput id="92022" placeholder={ReactSystemFunctions.translate(this.ml, 92022, "placeholder",this.defaultML)} allowClear={false} bordered={true} disabled={false} type="text" iconColor="rgba(0, 0, 0, 1)" formatter="None" isCharOnly={false} autoComplete={true} style={{"borderTopLeftRadius":8,"borderTopRightRadius":8,"borderBottomRightRadius":8,"borderBottomLeftRadius":8,"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"center","textAlign":"-webkit-left","display":"block","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></TextInput>
</Form.Item>

<Form.Item className='kFormItem' name='parqformcopy_974898_value' >
<TextInput id="974898" placeholder={ReactSystemFunctions.translate(this.ml, 974898, "placeholder",this.defaultML)} allowClear={false} bordered={true} disabled={false} type="text" iconColor="rgba(0, 0, 0, 1)" formatter="None" isCharOnly={false} autoComplete={true} style={{"borderTopLeftRadius":8,"borderTopRightRadius":8,"borderBottomRightRadius":8,"borderBottomLeftRadius":8,"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"center","textAlign":"-webkit-left","display":"block","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></TextInput>
</Form.Item>
</VerticalStack>
</KCol>
</KRow>
</div>

                    </Form>
                </Spin>
              
            </>
        );
    }
}

const mapStateToProps = (state: AppState) => {
    return {
        spinnerCount: state.spinnerReducer.spinnerCount,
        photoTakeVisible: state.photoTakeReducer.visible,
        isImportMappedExcelModalVisible: state.importMappedExcelModalReducer.visible
    };
  }
  const mapDispatchToProps = dispatch => ({
    showPhotoTake: () => dispatch(showPhotoTake()),
    hidePhotoTake: () => dispatch(hidePhotoTake()),
    showStripeDrawer: (data,callback,that,future) => dispatch(showStripeDrawer(data,callback,that,future)),
    hideStripeDrawer: (data,callback,that) => dispatch(hideStripeDrawer(data,callback,that)),
    showIyzicoDrawer: (data,callback,that) => dispatch(showIyzicoDrawer(data,callback,that)),
    hideIyzicoDrawer: (data,callback,that) => dispatch(hideIyzicoDrawer(data,callback,that)),
    showImportMappedExcelModal: (dsId, tableName, primaryKey, columnMapping) => dispatch(showImportMappedExcelModal(dsId, tableName, primaryKey, columnMapping)),
    hideImportMappedExcelModal: () => dispatch(hideImportMappedExcelModal())
  });
const tmp = withGoogleLogin(withContext(withScreenInput(withForm(withHistory(connect(mapStateToProps, mapDispatchToProps)(ParQFormcopy_Screen))))));
export { tmp as ParQFormcopy_Screen };
//export default tmp;
//export { tmp as ParQFormcopy_Screen };

