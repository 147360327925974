import { PureComponent, ReactNode } from "react";
import { CommonProps } from "../../../components/web-components/common/common-props";
import withCommonEvents from "../../../shared/hoc/with-common-events";
import { IKSelectBoxProps, KSelectBox } from "../selectbox/k-selectbox";

interface IKMultiSelectProps extends IKSelectBoxProps {
  widthType?: "fill" | "content";
}

interface IKMultiSelectState {}

class KMultiSelect extends PureComponent<IKMultiSelectProps & CommonProps, IKMultiSelectState> {
  constructor(props: IKMultiSelectProps) {
    super(props);
    this.state = {};
  }

  render(): ReactNode {
    return (
      <>
        <KSelectBox {...this.props} mode="multiple"></KSelectBox>
      </>
    );
  }
}

const kMultiSelect = withCommonEvents(KMultiSelect);
export { kMultiSelect as KMultiSelect };
