import { PureComponent, ReactNode } from "react";

export interface GalleryViewWrapperProps {
  style?: any;
}

interface GalleryViewWrapperState {}

export class GalleryViewWrapper extends PureComponent<GalleryViewWrapperProps, GalleryViewWrapperState> {
  constructor(props: GalleryViewWrapperProps) {
    super(props);
    this.state = {};
  }

  render(): ReactNode {
    return <div {...this.props} />;
  }
}
