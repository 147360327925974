import { IImportMappedExcelColumnMapDefinition } from "../../models/dto/excel";

export const SHOW_PHOTO_TAKE = "SHOW_PHOTO_TAKE";
export const HIDE_PHOTO_TAKE = "HIDE_PHOTO_TAKE";
export const SHOW_STRIPE_DRAWER = "SHOW_STRIPE_DRAWER";
export const HIDE_STRIPE_DRAWER = "HIDE_STRIPE_DRAWER";
export const SHOW_IYZICO_DRAWER = "SHOW_IYZICO_DRAWER";
export const HIDE_IYZICO_DRAWER = "HIDE_IYZICO_DRAWER";
export const SHOW_IMPORT_MAPPED_EXCEL_MODAL = "SHOW_IMPORT_MAPPED_EXCEL_MODAL";
export const HIDE_IMPORT_MAPPED_EXCEL_MODAL = "HIDE_IMPORT_MAPPED_EXCEL_MODAL";

export interface PhotoTakeState {
  visible: boolean;
}

export interface StripeDrawerState {
  visible: boolean;
}

export interface IyzicoDrawerState {
  visible: boolean;
}
interface ShowPhotoTakeAction {
  type: typeof SHOW_PHOTO_TAKE;
  meta: {
    params: {};
  };
}

interface HidePhotoTakeAction {
  type: typeof HIDE_PHOTO_TAKE;
  meta: {
    params: {};
  };
}
interface ShowStripeDrawerAction {
  type: typeof SHOW_STRIPE_DRAWER;
  payload?: any;
  callback?: any;
  screen?: any;
  futureUse?: any;
  meta: {
    params: {};
  };
}
interface HideStripeDrawerAction {
  type: typeof HIDE_STRIPE_DRAWER;
  payload?: any;
  callback?: any;
  screen?: any;

  meta: {
    params: {};
  };
}

interface ShowIyzicoDrawerAction {
  type: typeof SHOW_IYZICO_DRAWER;
  payload?: any;
  callback?: any;
  screen?: any;
  meta: {
    params: {};
  };
}
interface HideIyzicoDrawerAction {
  type: typeof HIDE_IYZICO_DRAWER;
  payload?: any;
  callback?: any;
  screen?: any;

  meta: {
    params: {};
  };
}

interface ShowImportMappedExcelModalAction {
  type: typeof SHOW_IMPORT_MAPPED_EXCEL_MODAL;
  dsId: string;
  tableName: string;
  primaryKey: string;
  columnMapping: IImportMappedExcelColumnMapDefinition[];
}

interface HideImportMappedExcelModalAction {
  type: typeof HIDE_IMPORT_MAPPED_EXCEL_MODAL;
}

export type PhotoTakeActionTypes = ShowPhotoTakeAction | HidePhotoTakeAction;
export type StripeDrawerActionTypes = ShowStripeDrawerAction | HideStripeDrawerAction;
export type IyzicoDrawerActionTypes = ShowIyzicoDrawerAction | HideIyzicoDrawerAction;
export type ImportMappedExcelModalActionTypes = ShowImportMappedExcelModalAction | HideImportMappedExcelModalAction;
