import { PureComponent, ReactNode } from "react";
import { CommonProps } from "../../components/web-components/common/common-props";
import withCommonEvents from "../../shared/hoc/with-common-events";

interface IIFrameProps {
  style?: any;
  url?: string;
}

interface IIFrameState {}

class KIFrame extends PureComponent<IIFrameProps & CommonProps, IIFrameState> {
  constructor(props: IIFrameProps) {
    super(props);
    this.state = {};
  }

  render(): ReactNode {
    return (
      <>
        <iframe src={this.props.url} {...this.props}></iframe>
      </>
    );
  }
}

const kIframe = withCommonEvents(KIFrame);
export { kIframe as KIFrame };
