import { combineReducers, createStore } from "redux";
import {
  importMappedExcelModalReducer,
  iyzicoDrawerReducer,
  photoTakeReducer,
  stripeDrawerReducer
} from "./modals/reducers";
import { spinnerReducer } from "./spinner/reducers";

const rootReducer = combineReducers({
  spinnerReducer,
  photoTakeReducer,
  stripeDrawerReducer,
  iyzicoDrawerReducer,
  importMappedExcelModalReducer
});

export type AppState = ReturnType<typeof rootReducer>;

export const globalStore = createStore<any, any, any, any>(rootReducer);
const configureStore = () => globalStore;
export default configureStore;
