import * as React from "react";
import { Profile_ScreenBase } from "./profile-base";
import { Spacer, KPassword, KSteps, KStep, KLabel, KNumericbox, KButton, KToggle, KCheckbox, KMonthPicker, KDatatimePicker, KRating, KSelectBox, KMultiSelect, KTextarea, KTextbox, KFileDownload, KFilePicker, KLanguageSelect, KLocationSelect, KLocationDisplay, KLocationPicker, KSignature, KIcon, KMDEditor, KMDViewer, KRadio, KRadioGroup, KCalendar, KIFrame, KAvatarMenu, KAvatarMenuItem, KSpace, KSlider, KRadioButton, KThumbnail } from "../../kuika";
import { MfeContainer, TextArea, WebView, Icon, Label, Signature, HorizontalStack, VerticalStack, LanguageSelector, MDViewer, QRRenderer, BarcodeRenderer, Divider, MDEditor, Button, Rating, Percent, Currency, NumberInput, BarcodeInput, BarcodeInput, Password, TextInput, Image, Email, AreaChart, BarChart, DonutChart, GaugeChart, LineChart, PieChart, Header, Footer, Switch, Url, CountDown, ProgressCircle, ProgressBar, CollapsePanel, Collapse, Slider, KAreaChart, KBarChart, KDonutChart, KGaugeChart, KLineChart, KPieChart, KFooter, KHeader, KRow, KCol, KTab, KTabItem, KTable, KTableHeader, KTableRow, KTableColumn, KGallery, KPagination, KDrawer, KModal, KVideo, KCarousel, KFlexGrid, KPanel, KDropDown, KDropDownItem, KContentMenu, KContentMenuItem, GoogleMaps, GoogleMapsMarker, GoogleMapsMarkerPopup, KContentMenuPanel, KBadge, Badge, RangeSlider, Checkbox, Chat, RangeDate, RangeDateTime, Stopwatch, PlacesAutocompleteInput, CronMaker, SingleSelect, MultiSelect, AutoComplete, StripeCard, PaymentElementStripe, CountUp, Tree, KDatePicker, YearPicker, Tooltip, Calendar, LayoutHeader, LayoutFooter, LayoutLeft, LayoutRight, LocationPicker, Timeline, Stepper, StepperItem, Menu, MenuItem, SubMenu, RadialChart, MixedChart, MixedChartLineItem, MixedChartPointItem, ChartLabel, KanbanBoard, ProcessAutomation, RadioGroup, BigFileUpload, PivotTable, ColorPicker } from "../../components/web-components";
import { Drawer, Form, Spin, Typography } from "antd/es";
import { withGoogleLogin } from "../../shared/hoc/with-google-login";
import { withHistory } from "../../shared/hoc/with-history";
import { withForm } from "../../shared/hoc/with-form";
import { withScreenInput } from "../../shared/hoc/with-screen-input";
import { withContext } from "../../shared/hoc/with-context";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { showPhotoTake, hidePhotoTake, showStripeDrawer, hideStripeDrawer,showIyzicoDrawer, hideIyzicoDrawer, showImportMappedExcelModal, hideImportMappedExcelModal } from "../../redux/modals/actions";
import { AppState} from "../../redux/root-reducer";
import { connect } from "react-redux";
import moment from "moment";




declare let window: any;
const { Title, Paragraph, Text, Link } = Typography;
const menuMap = [

];

class Profile_Screen extends Profile_ScreenBase
{
    render() {
        if (this.state.isPageVisible === false) {return (<></>);}
        return (
            <>
              
                <Spin spinning={this.state.spinnerCount > 0}>
                    
                    <Form form={this.props.form} name="basic"
                        initialValues={
                            {profile_8802156_value: this.state.ProfilSelectById?.at?.(0)?.kurumId ?? undefined,
							profile_685451_value: this.state.ProfilSelectById?.at?.(0)?.rolename ?? undefined,
							profile_834708_value: this.state.ProfilSelectById?.at?.(0)?.izledigiKisiId ?? undefined,
							profile_76441_value: this.state.ProfilSelectById?.at?.(0)?.kirilim2Ids ?? undefined,
							profile_391054_value: this.state.ProfilSelectById?.at?.(0)?.expiration ?? undefined,
							profile_792719_value: this.state.ProfilSelectById?.at?.(0)?.isFreelance ?? undefined
}
                        } onValuesChange={(changedValues, allValues) => {this.clearFieldValidation(changedValues);}}>
                        <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
<Header id="profile_header" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left"} as any}>
<KRow id="472882" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="622344" xs={20} sm={20} md={20} lg={20} xl={20} xxl={20} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="796540" value={ReactSystemFunctions.translate(this.ml, 796540, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":400,"fontSize":"18pt","color":"rgba(10, 33, 64, 1)"} as any}></Label>
</KCol>

<KCol id="968985" xs={4} sm={4} md={4} lg={4} xl={4} xxl={4} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-right","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Icon id="968274" onClick={ (e?: any) => { if(e && e.stopPropagation) e.stopPropagation();this.ProfileComponent_968274_onClick()} } showCursorPointer iconName="close" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"20px","color":"rgba(155, 155, 155, 1)","letterSpacing":"1px"} as any}></Icon>
</KCol>
</KRow>
</Header>

<div id="profile_body" style={{"backgroundColor":"rgba(244, 244, 244, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","display":"block"} as any}>
<KRow id="176430" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":16,"paddingRight":16,"paddingBottom":0,"paddingLeft":16,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="302640" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderTopLeftRadius":8,"borderTopRightRadius":8,"borderBottomRightRadius":8,"borderBottomLeftRadius":8,"backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KRow id="5946477" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="9628605" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="4616776" value={ReactSystemFunctions.translate(this.ml, 4616776, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"block","fontFamily":"Poppins","fontWeight":400,"fontSize":"12px","color":"rgba(10, 33, 64, 1)","lineHeight":"20px"} as any}></Label>

<Form.Item className='kFormItem' name='profile_8802156_value' >
<KSelectBox id="8802156" onChange={ (e?: any) => { if(e && e.stopPropagation) e.stopPropagation();this.ProfileComponent_8802156_onChange()} } kuikaRef={this.profile_8802156_value_kuikaSelectBoxRef} options={this.state.KurumSelectAll} placeholder={ReactSystemFunctions.translate(this.ml, 8802156, "placeholder",this.defaultML)} allowClear={false} autoClearSearchValue={true} showSearch={false} widthType="fill" containsNullItem={false} sortBy="none" datavaluefield='id' datatextfield='unvani' style={{"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"center","textAlign":"-webkit-left","display":"block","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></KSelectBox>
</Form.Item>
</KCol>
</KRow>

<KRow id="2469311" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="7471646" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="8163186" value={ReactSystemFunctions.translate(this.ml, 8163186, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"block","fontFamily":"Poppins","fontWeight":400,"fontSize":"12px","color":"rgba(10, 33, 64, 1)","lineHeight":"20px"} as any}></Label>

<Form.Item className='kFormItem' name='profile_685451_value' >
<KSelectBox id="685451" onChange={ (e?: any) => { if(e && e.stopPropagation) e.stopPropagation();this.ProfileComponent_685451_onChange()} } kuikaRef={this.profile_685451_value_kuikaSelectBoxRef} options={this.state.Rol} placeholder={ReactSystemFunctions.translate(this.ml, 685451, "placeholder",this.defaultML)} allowClear={false} autoClearSearchValue={true} showSearch={false} widthType="fill" containsNullItem={false} sortBy="none" datavaluefield='val' datatextfield='txt' style={{"borderTopLeftRadius":8,"borderTopRightRadius":8,"borderBottomRightRadius":8,"borderBottomLeftRadius":8,"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"center","textAlign":"-webkit-left","display":"block","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></KSelectBox>
</Form.Item>
</KCol>
</KRow>

<KRow id="881092" visibility={this.state.isComp881092Visible} condition={(rowData) => ReactSystemFunctions.isEqualTo(this.state.ProfilSelectById[0]?.rolename?.toString(), "Rol_Observer")} align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="656262" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="952323" value={ReactSystemFunctions.translate(this.ml, 952323, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"block","fontFamily":"Poppins","fontWeight":400,"fontSize":"12px","color":"rgba(10, 33, 64, 1)","lineHeight":"20px"} as any}></Label>

<Form.Item className='kFormItem' name='profile_834708_value' >
<KSelectBox id="834708" kuikaRef={this.profile_834708_value_kuikaSelectBoxRef} options={this.state.KisiSearch} placeholder={ReactSystemFunctions.translate(this.ml, 834708, "placeholder",this.defaultML)} allowClear={false} autoClearSearchValue={true} showSearch={false} widthType="fill" containsNullItem={false} sortBy="none" datavaluefield='id' datatextfield='fullname' style={{"borderTopLeftRadius":8,"borderTopRightRadius":8,"borderBottomRightRadius":8,"borderBottomLeftRadius":8,"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"center","textAlign":"-webkit-left","display":"block","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></KSelectBox>
</Form.Item>
</KCol>
</KRow>

<KRow id="202221" visibility={this.state.isComp202221Visible} condition={(rowData) => ReactSystemFunctions.isEqualTo(this.state.ProfilSelectById[0]?.rolename?.toString(), "Rol_Ogretmen")} align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="257930" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="591502" value={ReactSystemFunctions.translate(this.ml, 591502, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"block","fontFamily":"Poppins","fontWeight":400,"fontSize":"12px","color":"rgba(10, 33, 64, 1)","lineHeight":"20px"} as any}></Label>

<Form.Item className='kFormItem' name='profile_76441_value' >
<MultiSelect id="76441" kuikaRef={this.profile_76441_value_kuikaMultiSelectRef} options={this.state.Kirilim2ByKirilim1} placeholder={ReactSystemFunctions.translate(this.ml, 76441, "placeholder",this.defaultML)} allowClear={false} autoClearSearchValue={true} mode="tags" showSearch={true} autoRefresh={true} datavaluefield='id' datatextfield='tanim' style={{"borderTopLeftRadius":8,"borderTopRightRadius":8,"borderBottomRightRadius":8,"borderBottomLeftRadius":8,"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"center","textAlign":"-webkit-left","display":"block","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></MultiSelect>
</Form.Item>
</KCol>
</KRow>

<KRow id="765484" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="451666" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Label id="981243" value={ReactSystemFunctions.translate(this.ml, 981243, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"block","fontFamily":"Poppins","fontWeight":400,"fontSize":"12px","color":"rgba(10, 33, 64, 1)","lineHeight":"20px"} as any}></Label>

<Form.Item className='kFormItem' name='profile_391054_value' >
<KDatePicker id="391054" kuikaRef={this.profile_391054_value_kuikaDateRef} placeholder={ReactSystemFunctions.translate(this.ml, 391054, "placeholder",this.defaultML)} allowClear={true} format="DD/MM/YYYY" style={{"borderTopLeftRadius":8,"borderTopRightRadius":8,"borderBottomRightRadius":8,"borderBottomLeftRadius":8,"borderTopWidth":1,"borderRightWidth":1,"borderBottomWidth":1,"borderLeftWidth":1,"borderColor":"rgba(218, 218, 218, 1)","borderStyle":"solid","backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"center","textAlign":"-webkit-left","display":"block","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></KDatePicker>
</Form.Item>
</KCol>
</KRow>

<KRow id="98582" visibility={this.state.isComp98582Visible} condition={(rowData) => ReactSystemFunctions.isEqualTo(this.state.ProfilSelectById[0]?.isInstructor?.toString(), "1")} align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0,"paddingLeft":0,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="884919" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"flex-start","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<HorizontalStack id="730721" direction="horizontal" size={12} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Form.Item className='kFormItem' name='profile_792719_value' >
<Switch id="792719" disabled={false} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}></Switch>
</Form.Item>

<Label id="683487" value={ReactSystemFunctions.translate(this.ml, 683487, "value",this.defaultML)} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":0,"paddingRight":0,"paddingBottom":5,"paddingLeft":0,"alignItems":"center","textAlign":"-webkit-left","display":"block","fontFamily":"Poppins","fontWeight":400,"fontSize":"12px","color":"rgba(10, 33, 64, 1)","lineHeight":"20px"} as any}></Label>
</HorizontalStack>
</KCol>
</KRow>
</KCol>
</KRow>
</div>

<Footer id="profile_footer" style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left"} as any}>
<KRow id="841691" align="top" justify="start" horizontalGutter={0} verticalGutter={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"center","textAlign":"-webkit-left","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<KCol id="600721" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} order={0} pull={0} push={0} style={{"borderStyle":"solid","borderTopWidth":0,"borderRightWidth":0,"borderBottomWidth":0,"borderLeftWidth":0,"backgroundColor":"rgba(255, 255, 255, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":8,"paddingRight":8,"paddingBottom":8,"paddingLeft":8,"alignItems":"center","textAlign":"-webkit-right","fontFamily":"Poppins","fontWeight":500,"fontSize":"15px","color":"rgba(10, 33, 64, 1)"} as any}>
<Button id="9544" onClick={ (e?: any) => { if(e && e.stopPropagation) e.stopPropagation();this.ProfileComponent_9544_onClick()} } showCursorPointer label={ReactSystemFunctions.translate(this.ml, 9544, "label",this.defaultML)} size="middle" loading={false} ghost={false} block={false} htmlType="button" icon="keyboard_return" iconPosition="right" danger={false} style={{"borderTopLeftRadius":8,"borderTopRightRadius":8,"borderBottomRightRadius":8,"borderBottomLeftRadius":8,"backgroundColor":"rgba(0, 190, 214, 1)","backgroundRepeat":"no-repeat","backgroundSize":"contain","backgroundPosition":"left","paddingTop":8,"paddingRight":16,"paddingBottom":8,"paddingLeft":16,"alignItems":"center","textAlign":"-webkit-center","display":"inline","fontFamily":"Poppins","fontWeight":500,"fontSize":"16px","color":"rgba(255, 255, 255, 1)","lineHeight":"22px"} as any}></Button>
</KCol>
</KRow>
</Footer>
</div>
                    </Form>
                </Spin>
              
            </>
        );
    }
}

const mapStateToProps = (state: AppState) => {
    return {
        spinnerCount: state.spinnerReducer.spinnerCount,
        photoTakeVisible: state.photoTakeReducer.visible,
        isImportMappedExcelModalVisible: state.importMappedExcelModalReducer.visible
    };
  }
  const mapDispatchToProps = dispatch => ({
    showPhotoTake: () => dispatch(showPhotoTake()),
    hidePhotoTake: () => dispatch(hidePhotoTake()),
    showStripeDrawer: (data,callback,that,future) => dispatch(showStripeDrawer(data,callback,that,future)),
    hideStripeDrawer: (data,callback,that) => dispatch(hideStripeDrawer(data,callback,that)),
    showIyzicoDrawer: (data,callback,that) => dispatch(showIyzicoDrawer(data,callback,that)),
    hideIyzicoDrawer: (data,callback,that) => dispatch(hideIyzicoDrawer(data,callback,that)),
    showImportMappedExcelModal: (dsId, tableName, primaryKey, columnMapping) => dispatch(showImportMappedExcelModal(dsId, tableName, primaryKey, columnMapping)),
    hideImportMappedExcelModal: () => dispatch(hideImportMappedExcelModal())
  });
const tmp = withGoogleLogin(withContext(withScreenInput(withForm(withHistory(connect(mapStateToProps, mapDispatchToProps)(Profile_Screen))))));
export { tmp as Profile_Screen };
//export default tmp;
//export { tmp as Profile_Screen };

