import React from "react";
import Axios, { AxiosResponse } from "axios";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { KNavigator } from "../../shared/hoc/with-history";
import { KContext } from "../../shared/hoc/with-context";
import { useLocation } from "react-router-dom";
import { FormInstance } from "antd/es/form";
import { Guid } from "guid-typescript";
import Swal from "sweetalert2";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import moment from "moment";
import { IValidationData } from "../../models/dto/validation-data";
import { IStripePayment } from "../../models/dto/stripe-payment";
import { IUserInfoDto } from "../../models/dto/user-info.dto";
import { UserService } from "../../services/user-service";
import _ from "lodash";
import mqtt, { MqttClient } from "mqtt";
import { IMqttResult } from "../../models/dto/mqtt.dto";

declare let window: any;

export interface IProtocol2_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

export interface IProtocol2_ScreenState {
    isSpinnerVisible: boolean;
    isPageVisible: boolean;
	isLeftMenuVisible: boolean;
    spinnerCount: number;
    selectedLanguage: string;
    isPageInitLoaded: false;
    isPageRecurringLoaded: false;
	GoBack: any;
	GoBack_dummy: any;
	ProtokolSelectById: any[];
	ProtokolSelectById_dummy: any[];
	ProtokolReportContentById: any[];
	ProtokolReportContentById_dummy: any[];
	PhotoTake: string;
	PhotoTake_dummy: string;
	SetValueOf: string;
	SetValueOf_dummy: string;
	PhotoFromGallery: string;
	PhotoFromGallery_dummy: string;
	SaveRecord: number;
	SaveRecord_dummy: number;
	NAVIGATE: any;
	NAVIGATE_dummy: any;


}

export class Protocol2_ScreenBase extends React.PureComponent<IProtocol2_ScreenProps, any> {

    ml=[{"Id":"423ed54a-d6f3-fccb-7d3e-72c7b24c8b7a","Name":"tr_TR","ShortName":"Turkish","IsDefault":true,"LanguagePhrases":[]},{"Id":"74503767-9450-4cae-9db5-729d038bcb36","Name":"en_US","ShortName":"English","LanguagePhrases":[]},{"Id":"4668f91e-a895-4f47-9c51-7fc1918371f0","Name":"FixedValues","ShortName":"FixedValues","LanguagePhrases":[{"Id":796540,"PropertyName":"value","Value":"Protokol - Rapor Ayarları"},{"Id":230299,"PropertyName":"value","Value":"Rapor Üst Başlık"},{"Id":798036,"PropertyName":"placeholder","Value":"http://"},{"Id":672643,"PropertyName":"value","Value":"Rapor Alt Başlık"},{"Id":422169,"PropertyName":"placeholder","Value":"http://"},{"Id":473843,"PropertyName":"value","Value":"Giriş Başlık"},{"Id":922237,"PropertyName":"placeholder","Value":"http://"},{"Id":936776,"PropertyName":"value","Value":"Giriş Açıklama"},{"Id":722284,"PropertyName":"value","Value":"Sonuç Başlık"},{"Id":149304,"PropertyName":"placeholder","Value":"http://"},{"Id":799043,"PropertyName":"value","Value":"Grafik Başlık"},{"Id":875082,"PropertyName":"placeholder","Value":"http://"},{"Id":513660,"PropertyName":"value","Value":"Daha Fazla Bilgi İçerik"},{"Id":274329,"PropertyName":"placeholder","Value":"Type here..."},{"Id":420897,"PropertyName":"value","Value":"Daha Fazla Bilgi URL"},{"Id":353357,"PropertyName":"placeholder","Value":"http://"},{"Id":40501,"PropertyName":"value","Value":"Daha Fazla Bilgi QR"},{"Id":9544,"PropertyName":"label","Value":"İLERİ"}]}]
    defaultML = "tr_TR"
    
    screenHash = ""
    
    constructor(props) {
        super(props);


        this.state = {
            isSpinnerVisible: false,
            isPageVisible: false,
            isLeftMenuVisible: false,
            spinnerCount: 0,
            selectedLanguage: "",
            isPageInitLoaded: false,
            isPageRecurringLoaded: false,
            	        GoBack: "",
	        ProtokolSelectById: [],
	        ProtokolReportContentById: [],
	        PhotoTake: "",
	        SetValueOf: "",
	        PhotoFromGallery: "",
	        SaveRecord: false,
	        NAVIGATE: "",

            
        }

        this.state.isPageVisible = true;
        
    }

    async componentDidMount() {

        if (KuikaAppManager.isPageInitPrevented()) {
            KuikaAppManager.calculateAndSetBodyHeight("protocol2", "");
            return;
        }
        
        ReactSystemFunctions.validateToken()
        if (true){
            await this.Protocol2PageInit();
        }
        
        
        
        KuikaAppManager.calculateAndSetBodyHeight("protocol2", "");
    }

    componentWillUnmount() {
        
        
        
    }

    

        
    componentDidUpdate= async (prevProps, prevState) => {
		KuikaAppManager.calculateAndSetBodyHeight("protocol2", ""    );
        if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
			await this.Protocol2PageInit();
		}

        
	}

    fillFormInitValues() {
        this.props.form.setFieldsValue({
protocol2_798036_value: this.state.ProtokolReportContentById?.at?.(0)?.reportH1 ?? undefined,
protocol2_422169_value: this.state.ProtokolReportContentById?.at?.(0)?.reportH2 ?? undefined,
protocol2_922237_value: this.state.ProtokolReportContentById?.at?.(0)?.introductionHeader ?? undefined,
protocol2_227902_value: this.state.ProtokolReportContentById?.at?.(0)?.introductionContent ?? undefined,
protocol2_149304_value: this.state.ProtokolReportContentById?.at?.(0)?.resultSummaryHeader ?? undefined,
protocol2_875082_value: this.state.ProtokolReportContentById?.at?.(0)?.graphHeader ?? undefined,
protocol2_274329_value: this.state.ProtokolReportContentById?.at?.(0)?.moreInfoContent ?? undefined,
protocol2_353357_value: this.state.ProtokolReportContentById?.at?.(0)?.moreInfoLink ?? undefined,
protocol2_411544_value: this.state.ProtokolReportContentById?.at?.(0)?.moreInfoQR ?? undefined
});

    }

      setPageInitLoaded = (value: boolean) => {
        this.setState({ isPageInitLoaded: value });
      }

      setPageRecurringLoaded = (value: boolean) => {
        this.setState({ isPageRecurringLoaded: value });
      }

      clearFieldValidation = (changedValues: any) => {
        const fieldName = Object.keys(changedValues)[0];
        if (fieldName) {
          this.props.form.setFields([
            {
              name: fieldName,
              errors: []
            }
          ]);
        }
      };

      

      

    Protocol2PageInit = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }

        let localVar = {
			Id_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.Id ?? this.props.screenInputs.id, "Guid"),
			Id_1: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.Id ?? this.props.screenInputs.id, "Guid")
        }

	KuikaAppManager.showSpinner(this);
	
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "Protocol2/Protocol2PageInit", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

		stateVars.ProtokolSelectById = result?.data.protokolSelectById;
		stateVars.ProtokolReportContentById = result?.data.protokolReportContentById;
		formVars.protocol2_798036_value = ReactSystemFunctions.toString(this, (stateVars.ProtokolReportContentById?.length > 0 ? stateVars.ProtokolReportContentById[0].reportH1 : this.state.ProtokolReportContentById?.length > 0 ? this.state.ProtokolReportContentById[0].reportH1 : null));
		formVars.protocol2_422169_value = ReactSystemFunctions.toString(this, (stateVars.ProtokolReportContentById?.length > 0 ? stateVars.ProtokolReportContentById[0].reportH2 : this.state.ProtokolReportContentById?.length > 0 ? this.state.ProtokolReportContentById[0].reportH2 : null));
		formVars.protocol2_922237_value = ReactSystemFunctions.toString(this, (stateVars.ProtokolReportContentById?.length > 0 ? stateVars.ProtokolReportContentById[0].introductionHeader : this.state.ProtokolReportContentById?.length > 0 ? this.state.ProtokolReportContentById[0].introductionHeader : null));
		formVars.protocol2_227902_value = ReactSystemFunctions.toString(this, (stateVars.ProtokolReportContentById?.length > 0 ? stateVars.ProtokolReportContentById[0].introductionContent : this.state.ProtokolReportContentById?.length > 0 ? this.state.ProtokolReportContentById[0].introductionContent : null));
		formVars.protocol2_149304_value = ReactSystemFunctions.toString(this, (stateVars.ProtokolReportContentById?.length > 0 ? stateVars.ProtokolReportContentById[0].resultSummaryHeader : this.state.ProtokolReportContentById?.length > 0 ? this.state.ProtokolReportContentById[0].resultSummaryHeader : null));
		formVars.protocol2_875082_value = ReactSystemFunctions.toString(this, (stateVars.ProtokolReportContentById?.length > 0 ? stateVars.ProtokolReportContentById[0].graphHeader : this.state.ProtokolReportContentById?.length > 0 ? this.state.ProtokolReportContentById[0].graphHeader : null));
		formVars.protocol2_274329_value = ReactSystemFunctions.toString(this, (stateVars.ProtokolReportContentById?.length > 0 ? stateVars.ProtokolReportContentById[0].moreInfoContent : this.state.ProtokolReportContentById?.length > 0 ? this.state.ProtokolReportContentById[0].moreInfoContent : null));
		formVars.protocol2_353357_value = ReactSystemFunctions.toString(this, (stateVars.ProtokolReportContentById?.length > 0 ? stateVars.ProtokolReportContentById[0].moreInfoLink : this.state.ProtokolReportContentById?.length > 0 ? this.state.ProtokolReportContentById[0].moreInfoLink : null));
		formVars.protocol2_411544_value = ReactSystemFunctions.toString(this, (stateVars.ProtokolReportContentById?.length > 0 ? stateVars.ProtokolReportContentById[0].moreInfoQR : this.state.ProtokolReportContentById?.length > 0 ? this.state.ProtokolReportContentById[0].moreInfoQR : null));
		formVars.protocol2_411544_placeholderimage = "";
		this.props.form.setFieldsValue(formVars);
		
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.Protocol2PageInit1_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
	Protocol2PageInit1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


	if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }

			formVars.protocol2_798036_value = ReactSystemFunctions.toString(this, (stateVars.ProtokolReportContentById?.length > 0 ? stateVars.ProtokolReportContentById[0].reportH1 : this.state.ProtokolReportContentById?.length > 0 ? this.state.ProtokolReportContentById[0].reportH1 : null));

			formVars.protocol2_422169_value = ReactSystemFunctions.toString(this, (stateVars.ProtokolReportContentById?.length > 0 ? stateVars.ProtokolReportContentById[0].reportH2 : this.state.ProtokolReportContentById?.length > 0 ? this.state.ProtokolReportContentById[0].reportH2 : null));

			formVars.protocol2_922237_value = ReactSystemFunctions.toString(this, (stateVars.ProtokolReportContentById?.length > 0 ? stateVars.ProtokolReportContentById[0].introductionHeader : this.state.ProtokolReportContentById?.length > 0 ? this.state.ProtokolReportContentById[0].introductionHeader : null));

			formVars.protocol2_227902_value = ReactSystemFunctions.toString(this, (stateVars.ProtokolReportContentById?.length > 0 ? stateVars.ProtokolReportContentById[0].introductionContent : this.state.ProtokolReportContentById?.length > 0 ? this.state.ProtokolReportContentById[0].introductionContent : null));

			formVars.protocol2_149304_value = ReactSystemFunctions.toString(this, (stateVars.ProtokolReportContentById?.length > 0 ? stateVars.ProtokolReportContentById[0].resultSummaryHeader : this.state.ProtokolReportContentById?.length > 0 ? this.state.ProtokolReportContentById[0].resultSummaryHeader : null));

			formVars.protocol2_875082_value = ReactSystemFunctions.toString(this, (stateVars.ProtokolReportContentById?.length > 0 ? stateVars.ProtokolReportContentById[0].graphHeader : this.state.ProtokolReportContentById?.length > 0 ? this.state.ProtokolReportContentById[0].graphHeader : null));

			formVars.protocol2_274329_value = ReactSystemFunctions.toString(this, (stateVars.ProtokolReportContentById?.length > 0 ? stateVars.ProtokolReportContentById[0].moreInfoContent : this.state.ProtokolReportContentById?.length > 0 ? this.state.ProtokolReportContentById[0].moreInfoContent : null));

			formVars.protocol2_353357_value = ReactSystemFunctions.toString(this, (stateVars.ProtokolReportContentById?.length > 0 ? stateVars.ProtokolReportContentById[0].moreInfoLink : this.state.ProtokolReportContentById?.length > 0 ? this.state.ProtokolReportContentById[0].moreInfoLink : null));

			formVars.protocol2_411544_value = ReactSystemFunctions.toString(this, (stateVars.ProtokolReportContentById?.length > 0 ? stateVars.ProtokolReportContentById[0].moreInfoQR : this.state.ProtokolReportContentById?.length > 0 ? this.state.ProtokolReportContentById[0].moreInfoQR : null));


	this.props.form.setFieldsValue(formVars);
	this.setState(stateVars);
	KuikaAppManager.hideSpinner(this);

            this.setPageInitLoaded(true);
            return isErrorOccurred;
        }




		Protocol2Component_968274_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
KuikaAppManager.showSpinner(this);

				stateVars.GoBack = await ReactSystemFunctions.goBack(this);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }


		Protocol2Component_541191_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
KuikaAppManager.showSpinner(this);

				stateVars.PhotoTake = await ReactSystemFunctions.takePhotoKuikaFunction(this,false,"");
				stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "protocol2_411544_value", ReactSystemFunctions.value(this, stateVars.PhotoTake), null);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }


		Protocol2Component_25980_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
KuikaAppManager.showSpinner(this);

				stateVars.PhotoFromGallery = await ReactSystemFunctions.getPhotoFromGallery(false,"");
				stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "protocol2_411544_value", ReactSystemFunctions.value(this, stateVars.PhotoFromGallery), null);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }


    Protocol2Component_9544_onClick = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }
		KuikaAppManager.increaseServerRequestCount();

        let localVar = {
				Id_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.Id ?? this.props.screenInputs.id, "Guid"),
				ResultSummaryHeader_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "protocol2_149304_value", "value", "", "", "")), "string"),
				ReportH2_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "protocol2_422169_value", "value", "", "", "")), "string"),
				ReportH1_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "protocol2_798036_value", "value", "", "", "")), "string"),
				MoreInfoQR_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "protocol2_411544_value", "value", "", "", "")), "string"),
				MoreInfoLink_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "protocol2_353357_value", "value", "", "", "")), "string"),
				ProtokolId_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.Id ?? this.props.screenInputs.id, "Guid"),
				MoreInfoContent_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "protocol2_274329_value", "value", "", "", "")), "string"),
				IntroductionHeader_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "protocol2_922237_value", "value", "", "", "")), "string"),
				IntroductionContent_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "protocol2_227902_value", "value", "", "", "")), "string"),
				GraphHeader_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.toString(this, ReactSystemFunctions.value(this, "protocol2_875082_value", "value", "", "", "")), "string")
        }

		KuikaAppManager.showSpinner(this);
		
        if (true) {
        KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "Protocol2/Protocol2Component_9544_onClick", localVar)
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

			stateVars.SaveRecord = result?.data.saveRecord_1;
			this.props.form.setFieldsValue(formVars);
			
        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
                  isErrorOccurred = await this.Protocol2Component_9544_onClick1_();
                  resolve();
                });
          })



        
        KuikaAppManager.hideSpinner(this);
        return isErrorOccurred;
    }
		Protocol2Component_9544_onClick1_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }

					KuikaAppManager.prepareForNavigation();
						KuikaAppManager.addToPageInputVariables("Protocol3", "Id", this.props.screenInputs.Id ?? this.props.screenInputs.id);

				stateVars.NAVIGATE = await ReactSystemFunctions.navigate(this, this.Protocol2Component_9544_onClick2_, "Protocol2", "Protocol3", "", "7d969ddf-5801-4eb6-b92b-12be35bdd565", "462594", null, "right", null, "380px", "100vh", true);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);

            
            return isErrorOccurred;
        }
		Protocol2Component_9544_onClick2_ = async () => {
        let stateVars = {} as any;
        let formVars = {} as any;
        let validations = [] as IValidationData[];
        let isErrorOccurred = false;


		if (KuikaAppManager.handleValidations(this, validations))
{
                const invalidValidation = validations.find((v) => !v.isValid && v.condition);
                if (invalidValidation) {
                    const fieldIdMatch = invalidValidation.formName.match(/\d+/);
                    if (fieldIdMatch) {
                        const fieldId = fieldIdMatch[0];
                        const fieldElement = document.getElementById(fieldId);
                        if (fieldElement) {
                            fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            (fieldElement as HTMLElement).focus();
                        }
                    }
                }
                return true;
            }

				stateVars.GoBack = await ReactSystemFunctions.goBack(this);

		this.props.form.setFieldsValue(formVars);
		this.setState(stateVars);
		KuikaAppManager.hideSpinner(this);

            
            return isErrorOccurred;
        }







    async callCallbackFunction(diId) {
        let isErrorOccurred = false;
		let pageInitCallerNavDiIDs = [462594] as number[];
		if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
			await this.Protocol2PageInit();
		}
		if (diId == 462594) {
			isErrorOccurred = await this.Protocol2Component_9544_onClick2_();
            if (isErrorOccurred) return true;
		}

    }
}
